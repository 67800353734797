import { reducerError } from './reducerError';
import type { Date } from '../context';

export type SelectedDateAction = {
    type: 'SET_DAY' | 'SET_MONTH' | 'SET_YEAR';
    value?: {
        year?: number;
        month?: number;
        day?: number;
    };
};

export function selectedDateReducer(
    state: Date,
    { type, value }: SelectedDateAction
): Date {
    switch (type) {
        case 'SET_DAY': {
            return {
                day: value?.day as number,
                month: value?.month as number,
                year: value?.year as number,
            };
        }
        case 'SET_MONTH': {
            return {
                ...state,
                month: value?.month as number,
                year: value?.year as number,
            };
        }
        case 'SET_YEAR': {
            return {
                ...state,
                year: value?.year as number,
            };
        }
        default: {
            throw reducerError(type);
        }
    }
}
