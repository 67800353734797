import React from 'react';
import {
    Box,
    SimpleGrid,
    useRadioGroup,
    useRadio,
    UseRadioProps,
} from '@chakra-ui/react';
import type { RadioProps, WidgetProps } from '../types';
import icons from './icons';
import { FormGroup, useInputValidation } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { useController } from 'react-hook-form';

type RadioSatisfactionItemProps = UseRadioProps & {
    label: string | number;
    radioRef: React.Ref<HTMLInputElement>;
};
type RadioSatisfactionProps = WidgetProps & {
    options: RadioProps[];
};

function RadioSatisfactionItem({
    radioRef,
    ...props
}: RadioSatisfactionItemProps) {
    const { getInputProps, getRadioProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getRadioProps();
    const Illustration = icons[props.label];
    const { isMobile } = useWindowBreakpoints();

    return (
        <Box as="label">
            <input {...input} required={false} ref={radioRef} />
            <Box
                {...checkbox}
                p={isMobile ? 2 : 6}
                display="flex"
                alignItems="center"
                justifyContent="center"
                h="100%"
                bg="white"
                cursor="pointer"
                borderRadius="md"
                shadow="sm"
                transition="ease-in-out 0.2s all"
                color="grey.500"
                _checked={{
                    outline: '2px solid',
                    outlineColor: 'primary.main',
                }}
                _focus={{
                    borderColor: 'currentColor',
                }}
                _hover={{
                    shadow: 'lg',
                }}>
                {Illustration}
            </Box>
        </Box>
    );
}

function RadioSatisfaction({
    name,
    id,
    validations,
    options,
    readonly,
    defaultValue,
}: RadioSatisfactionProps) {
    const inputName = name ?? `${id}.value`;
    const { control } = useInputValidation(validations, inputName);
    const { field } = useController({ name: inputName, control, defaultValue });
    const { getRadioProps } = useRadioGroup({ ...field });

    return (
        <FormGroup
            name={inputName}
            isRequired={validations.required}
            isReadOnly={readonly}
            id={id}>
            <SimpleGrid columns={3} gap="4" spacing={4}>
                {options.map((option) => {
                    const radio = getRadioProps({ value: option.id });

                    return (
                        <RadioSatisfactionItem
                            {...radio}
                            {...option}
                            key={option.id}
                            radioRef={field.ref}
                        />
                    );
                })}
            </SimpleGrid>
        </FormGroup>
    );
}

export default RadioSatisfaction;
