import { defineMessages } from 'react-intl';

export const registrationReasonsTitles = defineMessages({
    'registration.request': {
        id: 'components.registration.register.request.title',
        defaultMessage: "Je n'ai pas d'identifiants",
    },
    'registration.eligibility.temporary-credentials': {
        id: 'components.registration.eligibility.temporary-credentials.registration.title',
        defaultMessage: "J'ai reçu des identifiants par courrier",
    },
    'registration.eligibility.primo': {
        id: 'components.registration.eligibility.primo.registration.title',
        defaultMessage:
            "J'ai des identifiants définis lors de mon adhésion en ligne",
    },
});

export const registrationReasonsDescriptions = defineMessages({
    'registration.eligibility.temporary-credentials.description': {
        id: 'components.registration.eligibility.temporary-credentials.description',
        defaultMessage:
            'J’active mon compte {appName} en utilisant mon identifiant et mon mot de passe Mercernet.',
    },
    'registration.eligibility.primo.description': {
        id: 'components.registration.eligibility.primo.description',
        defaultMessage:
            'J’active mon compte {appName} en utilisant mes identifiants provisoires reçus par courrier, par e-mail.',
    },
    'registration.request.description': {
        id: 'components.registration.request.description',
        defaultMessage:
            '<p>Je suis concerné si :</p><p><l>- J’ai effectué une adhésion par bulletin papier,</l><l>- Je n’ai pas d’identifiant, ni de mot de passe.</l></p>',
    },
});

export const registrationReasonsButtons = defineMessages({
    'registration.request': {
        id: 'components.register.request.button',
        defaultMessage: 'Créer mon compte',
    },
    'registration.eligibility.temporary-credentials': {
        id: 'components.registration.eligibility.temporary-credentials.registration.button',
        defaultMessage: 'Activer mon compte',
    },
    'registration.eligibility.primo': {
        id: 'components.registration.eligibility.primo.registration.button',
        defaultMessage: 'Activer mon compte',
    },
});

export const registrationReasonsPictoAlts = defineMessages({
    'registration.request': {
        id: 'components.registration.picto-alt.request',
        defaultMessage: 'Utilisateur',
        description:
            'Texte alternatif pour le picto de la carte "Je n\'ai pas de compte"',
    },
    'registration.eligibility.temporary-credentials': {
        id: 'components.registration.picto-alt.eligibility.temporary-credentials',
        defaultMessage: 'Courrier',
        description:
            'Texte alternatif pour le picto de la carte "J\'ai des identifiants provisoires"',
    },
    'registration.eligibility.primo': {
        id: 'components.registration.picto-alt.eligibility.primo',
        defaultMessage: 'Téléphone affichant un aperçu du portail en ligne',
        description:
            'Texte alternatif pour le picto de la carte "J\'ai un compte"',
    },
});
