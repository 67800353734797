import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Stack, Text } from '@chakra-ui/react';
import { CONTAINER_WIDTHS, Heading, WorkflowTypesEnum } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import {
    SignatureCapitalBeneficiary,
    SignatureErrorStep,
    SignatureFormReason,
    SignatureFormReasonType,
} from 'core/lib/signature';
import { SignatureBeneficiaryModalForm } from './SignatureBeneficiaryModal';
import SignatureBeneficiaryModalReasons from './SignatureBeneficiaryModal/SignatureBeneficiaryModalReasons';
import { DividedCapitalAlert } from './divided-capital/DividedCapitalAlert';
import {
    CoreModal,
    CoreModalContent,
    CoreModalHeader,
    CoreModalBody,
} from 'design-system/components';

type ModalBeneficiaryProps = {
    service: WorkflowTypesEnum;
    isOpen: boolean;
    onClose: () => any;
    onFormError: (
        error: number,
        step?: SignatureErrorStep,
        action?: 'add' | 'edit' | 'delete'
    ) => void;
    selectedBeneficiary: SignatureCapitalBeneficiary;
    type: 'full-capital' | 'divided-capital';
    capital?: number;
};

export default function SignatureModalBeneficiary({
    service,
    isOpen,
    onClose,
    onFormError,
    selectedBeneficiary,
    type,
    capital,
}: ModalBeneficiaryProps) {
    const { isMobile } = useWindowBreakpoints();
    const [selectedReasonType, setSelectedReasonType] =
        React.useState<SignatureFormReasonType>();

    function onReasonClick(reason: SignatureFormReason) {
        setSelectedReasonType(reason.label);
    }

    function onModalFormSubmit() {
        onClose();
        setSelectedReasonType(undefined);
    }

    const step = selectedReasonType || selectedBeneficiary ? 2 : 1;
    return (
        <CoreModal
            size="2xl"
            isOpen={isOpen}
            onClose={() => {
                setSelectedReasonType(undefined);
                onClose();
            }}>
            <CoreModalContent
                maxHeight="100%"
                overflow="auto"
                p="0"
                minW={!isMobile ? CONTAINER_WIDTHS.PRIMARY : '90%'}
                borderRadius={isMobile ? 'lg' : 'xl'}
                mx="4">
                <CoreModalHeader>
                    <Text
                        as="h3"
                        fontWeight="semibold"
                        fontFamily="mono"
                        w="100%"
                        mr="2">
                        <FormattedMessage
                            id="api.messages.reasons.beneficiary.new"
                            defaultMessage="Ajout d'un bénéficiaire"
                        />
                    </Text>
                </CoreModalHeader>

                <CoreModalBody p="4">
                    <Stack py="6" px="0" borderRadius="lg" spacing="4">
                        <Box
                            bg={step === 1 ? 'grey.200' : 'primary.main'}
                            w="fit-content"
                            px="4"
                            py="1"
                            borderRadius="full">
                            <Heading
                                fontWeight="semibold"
                                fontSize="md"
                                color={step === 1 ? 'black' : 'white'}
                                as="h5">
                                {step}/2
                            </Heading>
                        </Box>
                        {capital && <DividedCapitalAlert capital={capital} />}
                        {selectedBeneficiary || selectedReasonType ? (
                            <SignatureBeneficiaryModalForm
                                {...{ service, selectedReasonType }}
                                onModalFormSubmit={onModalFormSubmit}
                                onModalClose={() => {
                                    setSelectedReasonType(undefined);
                                    onClose();
                                }}
                                onFormError={(errorCode, step, action) => {
                                    onFormError(
                                        errorCode,
                                        step as SignatureErrorStep,
                                        action
                                    );
                                    setSelectedReasonType(undefined);
                                }}
                                selectedBeneficiary={selectedBeneficiary}
                                type={type}
                            />
                        ) : (
                            <SignatureBeneficiaryModalReasons
                                service={service}
                                onReasonClick={onReasonClick}
                                onError={onFormError}
                            />
                        )}
                    </Stack>
                </CoreModalBody>
            </CoreModalContent>
        </CoreModal>
    );
}
