import { useRef, useCallback, useEffect, Fragment } from 'react';
import { Box, Checkbox, Collapse, Text, VStack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useSafeIntl, FormLibrarian, Heading } from 'core/components';
import { getExtraLabel } from 'core/lib/forms';
import { CheckboxesProps } from './Checkboxes';
import { CheckboxProps, translateLabel } from '.';
import { ChangeHandler, Controller, useFormContext } from 'react-hook-form';

type CoreCheckboxProps = Omit<CheckboxesProps, 'options' | 'setValue'> & {
    checkbox: CheckboxProps;
    index: number;
    onChange?: ChangeHandler;
    onBlur?: ChangeHandler;
};

const CoreCheckbox = ({
    id,
    value,
    onChange,
    onBlur,
    focusedOption,
    checkboxContainerRef,
    checkbox,
    index,
    name,
    hasExtraInfo,
}: CoreCheckboxProps) => {
    const {
        id: optionId,
        label,
        extra,
        isDefaultValue,
        isTranslatable,
        subWidget = null,
        hidden = false,
    } = checkbox;
    const { safeFormatMessage } = useSafeIntl();
    const subWidgetRef = useRef(null);
    const { control } = useFormContext();

    const isChecked = useCallback((): boolean => {
        if (
            subWidget &&
            !isEmpty(value) &&
            value.some((i) => JSON.parse(i)?.id === optionId)
        ) {
            return true;
        }
        return false;
    }, [value, optionId, subWidget]);

    const processedLabel = translateLabel(
        label,
        isTranslatable,
        safeFormatMessage
    );

    useEffect(() => {
        if (subWidgetRef.current && subWidget && isChecked()) {
            subWidgetRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [subWidget, isChecked]);

    return (
        <Fragment key={optionId}>
            <Box
                ref={focusedOption === index ? checkboxContainerRef : null}
                display={hidden ? 'none' : 'flex'}
                bg={focusedOption === index && 'gray.100'}
                borderRadius={'md'}
                _hover={{ bg: 'gray.100' }}>
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { ref } }) => (
                        <Checkbox
                            px={3}
                            py={2}
                            id={optionId}
                            name={name}
                            defaultChecked={isDefaultValue}
                            value={JSON.stringify({
                                id: optionId,
                                label: processedLabel,
                                extraLabel: getExtraLabel(
                                    extra,
                                    safeFormatMessage
                                ),
                            })}
                            display={hidden ? 'none' : 'flex'}
                            ref={ref}
                            onChange={onChange}
                            onBlur={onBlur}
                            w={'100%'}
                            sx={{
                                'span.chakra-checkbox__label': {
                                    w: '100%',
                                    ml: 4,
                                },
                                'span.chakra-checkbox__control': {
                                    alignSelf: 'start',
                                    mt: hasExtraInfo ? 1.5 : '3px',
                                },
                            }}>
                            {hasExtraInfo ? (
                                <VStack w={'100%'} alignItems={'start'} gap={0}>
                                    <Heading
                                        as="h4"
                                        color={'primary.main'}
                                        maxW={'90%'}
                                        isTruncated>
                                        {processedLabel}
                                    </Heading>
                                    {!!extra && !!extra[0] && (
                                        <Text fontSize="sm">
                                            {getExtraLabel(
                                                extra,
                                                safeFormatMessage
                                            )}
                                        </Text>
                                    )}
                                </VStack>
                            ) : (
                                <Text
                                    fontWeight="normal"
                                    fontSize="sm"
                                    py={0.5}>
                                    {processedLabel}
                                </Text>
                            )}
                        </Checkbox>
                    )}
                />
            </Box>
            {subWidget && isChecked() && (
                <Collapse in={isChecked()} animateOpacity>
                    <Box
                        minW={'90%'}
                        alignItems={'center'}
                        ml={6}
                        mr={4}
                        pl={4}
                        borderLeftColor={'gray'}
                        borderLeftWidth={'2px'}
                        borderLeftStyle={'solid'}
                        ref={subWidgetRef}>
                        <FormLibrarian
                            key={subWidget.id}
                            type={subWidget.type}
                            props={{
                                ...subWidget,
                                id: subWidget.id.includes(`${id}.`)
                                    ? subWidget.id
                                    : `${id}.${subWidget.id}`,
                                validations: subWidget.validations,
                            }}
                        />
                    </Box>
                </Collapse>
            )}
        </Fragment>
    );
};

export default CoreCheckbox;
