import { defineMessages } from 'react-intl';

export const challengeMessages = defineMessages({
    subTitle: {
        id: 'security-code-workflow.strategies.title',
        defaultMessage: 'Recevez votre code de sécurité',
    },
    text: {
        id: 'security-code-workflow.strategies.description',
        defaultMessage:
            '<p>Veuillez sélectionner le canal de réception de votre code de sécurité.</p><p>Pourquoi ? La double authentification permet de renforcer la sécurité de vos données.</p>',
    },
    smsOnly: {
        id: 'components.auth.mfa.sms-only-info',
        defaultMessage:
            "Si votre numéro ne figure pas dans la liste ou qu'il est erroné merci de contacter votre service clientèle au <b>{phoneNumber}</b>",
    },
    noChannelAvailable: {
        id: 'api.auth.mfa.errors.no-contact-sms-only',
        defaultMessage:
            'Aucun numéro de téléphone trouvé pour recevoir le code par SMS. Veuillez contacter votre centre de gestion au <b>{phoneNumber}</b>',
    },
    noChannelForeignNumber: {
        id: 'api.auth.mfa.errors.no-contact-sms-only-foreign-number',
        defaultMessage:
            "<p>La double authentification permet d'améliorer la sécurité de votre compte.</p><p>Si votre e-mail est erroné ou n'apparait pas ci-dessus, veuillez contacter votre équipe Relation Client au <b>{phoneNumber}</b></p>",
    },
});
