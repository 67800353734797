import { Icon, IconProps } from '@chakra-ui/react';

export default function SearchIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M14.568 16.568a8 8 0 1 1 1.488-1.34l4.754 4.753a1 1 0 0 1-1.415 1.414l-4.827-4.827zM16 10a6 6 0 1 1-12 0 6 6 0 0 1 12 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
