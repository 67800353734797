import { usePicker } from '../Picker/context';
import { PickerYear } from './Year';
import { PickerMonth } from './Month';
import { PickerDay } from './Day';

export function Picker() {
    const { picker } = usePicker();
    if (picker === 'month') return <PickerMonth />;
    if (picker === 'year') return <PickerYear />;
    return <PickerDay />;
}
