import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { orderBy } from 'lodash';
import { APIError } from 'core';
import { HealthCareBeneficiary } from 'lib/health-care/types/HealthCareBeneficiary';

export const healthCareBeneficiariesPath = `/api/proxy/healthcare-access/beneficiaries`;

async function fetchHealthCareBeneficiaries() {
    try {
        const response = await axios.get(healthCareBeneficiariesPath);
        const orderedArray = orderBy(
            response?.data?.data,
            ['isSubscriber'],
            ['desc']
        );
        return orderedArray;
    } catch (error) {
        throw { code: error?.response?.status };
    }
}

export function useHealthCareBeneficiaries(enabled: boolean = true) {
    return useQuery<HealthCareBeneficiary[], APIError>({
        queryKey: [healthCareBeneficiariesPath],
        queryFn: fetchHealthCareBeneficiaries,
        enabled,
    });
}
