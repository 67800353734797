import axios from 'axios';
import { FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';
import { GuaranteeSharedCeiling } from '..';

const path = `/api/proxy/guarantees/shared-ceiling/[id]`;

async function fetchGuaranteesSharedCeilings({
    queryKey: [urn, id],
}: FetchQueryParams) {
    try {
        const res = await axios.get(urn.replace('[id]', id));

        return res.data.data.sharedCeiling;
    } catch (error) {
        throw new Error(error);
    }
}

function useGuaranteesSharedCeilings(id: string) {
    return useQuery<GuaranteeSharedCeiling, Error>({
        queryKey: [path, id],
        queryFn: fetchGuaranteesSharedCeilings,
    });
}

export { fetchGuaranteesSharedCeilings, useGuaranteesSharedCeilings };
