/**
 * The following component has been created by the lack of `fullWidth` prop
 * on the [Popover](https://chakra-ui.com/docs/overlay/popover) component.
 *
 * This component is the same as a Popover, but can only be placed at the bottom
 * of the child, and has the same width as its child.
 */

import React from 'react';
import {
    Box,
    BoxProps,
    useDisclosure,
    UseDisclosureProps,
} from '@chakra-ui/react';
import { useModal, contextError } from 'core';
import { ClickOutsideMode } from 'core/hooks/useClickOutside';

type BottomFullWidthPopoverTriggerProps = {
    children: React.ReactElement;
};
type BottomFullWidthPopoverContentProps = React.PropsWithChildren<BoxProps> & {
    useClickOutsideMode: ClickOutsideMode;
};
type BottomFullWidthPopoverProps = React.PropsWithChildren<UseDisclosureProps>;
type BottomFullWidthPopoverContext = {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
};

function BottomFullWidthPopoverTrigger({
    children,
}: BottomFullWidthPopoverTriggerProps) {
    const { onOpen, onClose, isOpen } = useBottomFullWidthPopoverContext();

    return React.cloneElement(children, { onClick: isOpen ? onClose : onOpen });
}

function BottomFullWidthPopoverContent({
    children,
    useClickOutsideMode = 'all',
    ...boxProps
}: BottomFullWidthPopoverContentProps) {
    const { isOpen, onClose } = useBottomFullWidthPopoverContext();
    const modalRef = useModal(onClose, {
        closeOnClickOutside: true,
        clickOutsideMode: useClickOutsideMode,
    });

    if (!isOpen) return null;

    return (
        <Box
            ref={modalRef}
            className="calendar_popover"
            bg="white"
            zIndex={3}
            rounded="md"
            shadow="lg"
            p="4"
            position="absolute"
            right="0"
            left="0"
            top="50px"
            border="1px solid"
            borderColor="strokes.medium"
            {...boxProps}>
            {children}
        </Box>
    );
}

const BottomFullWidthPopoverContext =
    React.createContext<BottomFullWidthPopoverContext | null>(null);

function useBottomFullWidthPopoverContext() {
    const context = React.useContext(BottomFullWidthPopoverContext);
    if (!context) {
        contextError(
            'useBottomFullWidthPopoverContext',
            'BottomFullWidthPopover'
        );
    }
    return context;
}

function BottomFullWidthPopover({
    children,
    ...disclosureProps
}: BottomFullWidthPopoverProps) {
    const { isOpen, onOpen, onClose, onToggle } =
        useDisclosure(disclosureProps);

    return (
        <BottomFullWidthPopoverContext.Provider
            value={{
                isOpen,
                onOpen,
                onClose,
                onToggle,
            }}>
            <Box position="relative">{children}</Box>
        </BottomFullWidthPopoverContext.Provider>
    );
}

export {
    BottomFullWidthPopover,
    BottomFullWidthPopoverContent,
    BottomFullWidthPopoverTrigger,
    useBottomFullWidthPopoverContext,
};
