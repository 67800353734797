import React from 'react';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useFormRedirect, useRedirect } from '../hooks/useRedirect';
import { loginMessages } from '..';
import { MFA_ERROR_CODE, PROMO_ERROR_CODE } from '../consts';
import { MessageDescriptor } from 'react-intl';
import { UtilsContext } from 'core';

type Credentials = {
    username: string;
    password: string;
    client_id: string;
};

function postLogin(credentials: Credentials) {
    return axios.post(`/api/login`, credentials);
}

export function getErrorMessage(error: any): null | MessageDescriptor {
    if (!error || !error?.status) return null;

    const code = error?.data?.error?.errors?.[0]?.code;

    if (code) {
        if (code === MFA_ERROR_CODE.MFA_REDIRECT) {
            return null;
        }

        if (code === MFA_ERROR_CODE.MFA_TOO_MANY_TRIES) {
            return loginMessages.tooManyTries;
        }
    }

    if (code in loginMessages) {
        return loginMessages[code];
    }

    return error.status in loginMessages ? loginMessages[error.status] : null;
}

function useLogin() {
    const {
        siteConfig: { client_id },
    } = React.useContext(UtilsContext);
    const { redirect } = useRedirect();
    const {
        onSuccess,
        onError,
        errorMessage: redirectErrorMessage,
        shouldShowToast: shouldShowRedirectErrorToast,
    } = useFormRedirect();

    const loginQuery = useMutation({
        mutationFn: postLogin,
        onSuccess,
        onError,
    });

    const [errorMessage, setErrorMessage] =
        React.useState<MessageDescriptor | null>(null);
    const [httpStatus, setHttpStatus] = React.useState<number>();

    const login = ({ username, password }) =>
        loginQuery.mutate({
            username: username.value,
            password: password.value,
            client_id,
        });

    React.useEffect(() => {
        const responseStatus =
            loginQuery?.data?.status || loginQuery?.error?.response?.status;

        const scope = loginQuery?.data?.data?.scope;
        const code =
            loginQuery?.error?.response?.data?.error?.errors?.[0]?.code;

        if (scope === 'mfa') {
            redirect('/mfa');
            return;
        }
        if (scope === 'activation') {
            redirect('/activation');
            return;
        }
        if (responseStatus === 503) {
            const message =
                loginQuery?.error?.response?.data?.error?.meta?.message;
            redirect({
                pathname: '/maintenance',
                query: {
                    ...(message ? { message } : {}),
                },
            });
            return;
        }
        if (code === PROMO_ERROR_CODE.PRIMO_REDIRECT) {
            redirect({ pathname: '/primo/waiting-validation' });
            return;
        }
        setHttpStatus(responseStatus);
        setErrorMessage(getErrorMessage(loginQuery?.error?.response));
    }, [loginQuery, redirect]);

    return {
        loginQuery,
        login,
        errorMessage,
        redirectErrorMessage,
        shouldShowRedirectErrorToast,
        httpStatus,
    };
}

export { useLogin };
