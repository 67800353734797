import React from 'react';
import { useIntl } from 'react-intl';
import { useToast } from 'design-system/components/Toast';
import { errors } from 'lib/shared';

function useGuaranteesDocumentsErrorToasts(
    contractsDocumentsTotalErrors: number
) {
    const intl = useIntl();
    const [contractDocumentsOnError, setContractDocumentsOnError] =
        React.useState(false);
    const contractDocumentsOnErrorToast = useToast({
        status: 'error',
        description: intl.formatMessage(errors.counter, {
            nbErrors: contractsDocumentsTotalErrors,
        }),
    });

    React.useEffect(() => {
        if (contractsDocumentsTotalErrors) {
            setContractDocumentsOnError(true);
            contractDocumentsOnErrorToast();
        }
    }, [contractsDocumentsTotalErrors, contractDocumentsOnErrorToast, intl]);

    return {
        contractDocumentsOnError,
        contractDocumentsOnErrorToast,
    };
}

export { useGuaranteesDocumentsErrorToasts };
