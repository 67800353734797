import { Icon, useTheme } from '@chakra-ui/react';

type IllustationProps = {
    w?: string;
    h?: string;
};

export default function DesktopIntroIllustration({
    w = 'auto',
    h = 'auto',
}: IllustationProps) {
    const theme = useTheme();
    const colors = theme.colors;

    return (
        <Icon viewBox="0 0 153 150" width={w} height={h} fill="none">
            <path
                d="M35.5632 126.378C44.7599 128.978 55.559 129.326 67.8542 126.918C75.5128 125.418 81.8129 127.122 88.8461 127.3C104.505 127.696 125.727 118.254 138.984 105.445C155.663 89.3293 149.353 72.4375 140.694 61.8589C131.775 50.9639 123.274 40.2872 116.277 27.9951C108.134 13.6881 92.6683 4.12484 69.7332 12.3284C52.2001 18.6001 41.9354 32.0681 31.5719 46.0008C25.1068 54.6921 14.9344 60.6482 7.70657 68.8195C-0.501636 78.0989 -0.157491 87.4698 2.77978 95.2933C8.54524 110.649 19.6572 121.882 35.5632 126.378Z"
                fill="black"
                fillOpacity="0.04"
            />
            <path
                d="M128.348 33.09H25.9497C24.0008 33.09 22.4209 34.738 22.4209 36.7709V105.203C22.4209 107.236 24.0008 108.884 25.9497 108.884H128.348C130.297 108.884 131.877 107.236 131.877 105.203V36.7709C131.877 34.738 130.297 33.09 128.348 33.09Z"
                fill={colors.primary.dark}
            />
            <path
                d="M125.07 37.0406H29.228C27.4039 37.0406 25.9252 38.5169 25.9252 40.3379V101.636C25.9252 103.457 27.4039 104.933 29.228 104.933H125.07C126.894 104.933 128.372 103.457 128.372 101.636V40.3379C128.372 38.5169 126.894 37.0406 125.07 37.0406Z"
                fill="#FAFAFA"
            />
            <path
                d="M151.981 109.523C151.216 110.078 150.854 110.497 150.733 110.653H3.56541C3.4444 110.497 3.08273 110.078 2.31722 109.523C1.40963 110.56 2.73804 112.216 2.73804 112.216C3.30435 112.999 4.64161 113.781 7.73625 113.781H146.56C149.651 113.781 150.991 112.999 151.558 112.216C151.558 112.216 152.888 110.56 151.981 109.523Z"
                fill="#D1D1D1"
            />
            <path
                d="M151.889 109.649L131.845 107.546C130.91 107.448 129.709 107.394 128.465 107.394H25.8331C24.5891 107.394 23.3886 107.448 22.4522 107.546L2.40845 109.649L3.65512 110.8H150.643L151.889 109.649Z"
                fill="white"
            />
            <path
                d="M19.4734 60.8058C15.0839 62.9305 14.7722 66.065 14.6331 66.6249V66.6327C14.6309 66.6299 14.6309 66.6271 14.6309 66.6249C14.4896 66.065 14.1806 62.9305 9.79053 60.8058C13.9541 59.1739 14.5813 55.288 14.6287 54.9274C14.6309 54.9218 14.6309 54.9168 14.6309 54.9113C14.6331 54.9168 14.6331 54.9224 14.6331 54.9274C14.6352 54.9274 14.6331 54.9301 14.6331 54.9301C14.6875 55.3047 15.3168 59.1767 19.4734 60.8058Z"
                fill="#FFCF33"
            />
            <path
                d="M51.5103 123.673C45.7341 126.352 45.3245 130.304 45.1415 131.01V131.02C45.1385 131.017 45.1385 131.013 45.1385 131.01C44.952 130.304 44.5454 126.352 38.7697 123.673C44.2476 121.616 45.0733 116.716 45.1355 116.261C45.1385 116.255 45.1385 116.248 45.1385 116.241C45.1415 116.248 45.1415 116.255 45.1415 116.261C45.1445 116.261 45.1415 116.265 45.1415 116.265C45.2127 116.737 46.0414 121.619 51.5103 123.673Z"
                fill="#FFCF33"
            />
            <path
                d="M152 67.653C146.686 70.3318 146.309 74.2838 146.141 74.9898V75C146.138 74.9966 146.138 74.9932 146.138 74.9898C145.966 74.2834 145.592 70.3318 140.279 67.653C145.318 65.5955 146.078 60.6962 146.135 60.2413C146.138 60.2344 146.138 60.2276 146.138 60.2208C146.141 60.2276 146.141 60.2344 146.141 60.2413C146.143 60.2413 146.141 60.2447 146.141 60.2447C146.206 60.717 146.969 65.5993 152 67.653Z"
                fill="#FFCF33"
            />
            <circle
                cx="99.2556"
                cy="80.4385"
                r="16.3208"
                fill={colors.primary['100']}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M91.2081 76.1936C91.2043 75.2483 91.9676 74.479 92.9128 74.4753L106.605 74.421C107.55 74.4173 108.32 75.1805 108.323 76.1258L108.357 84.6834C108.361 85.6286 107.598 86.3979 106.652 86.4017L92.9603 86.4559C92.015 86.4597 91.2457 85.6964 91.242 84.7512L91.2081 76.1936ZM105.201 76.1382L94.2985 76.1813L99.7163 81.0053L105.201 76.1382ZM92.9238 77.249L92.9535 84.7444L106.646 84.6902L106.616 77.1704L100.852 82.2854C100.203 82.8613 99.2262 82.8605 98.5782 82.2836L92.9238 77.249Z"
                fill={colors.primary.main}
            />
            <circle
                cx="54.7444"
                cy="79.9324"
                r="16.3208"
                fill={colors.primary['100']}
            />
            <path
                d="M60.4827 77.3818C60.6668 77.8404 60.4443 78.3612 59.9858 78.545C59.5274 78.7289 59.0065 78.5062 58.8224 78.0476C58.6383 77.589 58.8607 77.0682 59.3192 76.8844C59.7777 76.7005 60.2986 76.9232 60.4827 77.3818Z"
                fill={colors.primary.main}
            />
            <path
                d="M50.024 82.5397C50.4825 82.3558 50.7049 81.8351 50.5208 81.3765C50.3368 80.9179 49.8159 80.6952 49.3574 80.879C48.8989 81.0629 48.6765 81.5837 48.8605 82.0422C49.0446 82.5008 49.5655 82.7235 50.024 82.5397Z"
                fill={colors.primary.main}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45.0369 79.7194C44.6687 78.8022 45.1136 77.7607 46.0306 77.393L59.313 72.0669C60.23 71.6992 61.2718 72.1447 61.6399 73.0618L64.3063 79.7044C64.6745 80.6216 64.2296 81.6632 63.3126 82.0308L63.2898 82.04C63.3596 82.1702 63.4229 82.3057 63.4793 82.446C64.3076 84.5096 63.3066 86.8531 61.2434 87.6804C59.1803 88.5077 56.8363 87.5055 56.0079 85.4419C55.9516 85.3016 55.9037 85.1599 55.8641 85.0176L50.0302 87.3569C49.1132 87.7246 48.0714 87.2791 47.7033 86.362L45.0369 79.7194ZM55.8037 83.1139C55.899 82.6627 56.071 82.2309 56.3113 81.8383C56.1203 81.9854 55.9062 82.1089 55.6715 82.203C54.2961 82.7546 52.7334 82.0864 52.1812 80.7107C51.6289 79.335 52.2963 77.7726 53.6717 77.2211C55.0472 76.6695 56.6099 77.3377 57.1621 78.7134C57.4643 79.4662 57.4013 80.2749 57.0569 80.9433C57.3971 80.6387 57.7953 80.3873 58.2437 80.2075C59.5389 79.6881 60.9449 79.8898 62.017 80.6224L62.646 80.3702L59.9796 73.7276L46.6972 79.0536L49.3636 85.6962L55.8037 83.1139ZM55.3382 81.3727C56.2552 81.005 56.7001 79.9635 56.3319 79.0463C55.9638 78.1291 54.922 77.6837 54.005 78.0514C53.0881 78.4191 52.6432 79.4607 53.0113 80.3778C53.3795 81.295 54.4213 81.7404 55.3382 81.3727ZM61.819 83.1117C62.2792 84.2582 61.723 85.5601 60.5768 86.0197C59.4306 86.4794 58.1284 85.9226 57.6682 84.7761C57.208 83.6297 57.7641 82.3277 58.9103 81.8681C60.0565 81.4085 61.3588 81.9653 61.819 83.1117Z"
                fill={colors.primary.main}
            />
            <circle
                cx="77.9905"
                cy="45.1876"
                r="16.3208"
                fill={colors.primary['100']}
            />
            <path
                d="M78.3673 43.4898C78.3673 43.0741 78.7043 42.7371 79.12 42.7371L83.6365 42.7371C84.0522 42.7371 84.3892 43.0741 84.3892 43.4898C84.3892 43.9055 84.0522 44.2425 83.6365 44.2425L79.12 44.2425C78.7043 44.2425 78.3673 43.9055 78.3673 43.4898Z"
                fill={colors.primary.main}
            />
            <path
                d="M82.3819 46.5008C82.3819 46.085 82.0449 45.748 81.6292 45.748L79.12 45.748C78.7043 45.748 78.3673 46.085 78.3673 46.5008C78.3673 46.9165 78.7043 47.2535 79.12 47.2535L81.6292 47.2535C82.0449 47.2535 82.3819 46.9165 82.3819 46.5008Z"
                fill={colors.primary.main}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.8508 46.7517C75.2366 46.7517 76.36 45.6283 76.36 44.2425C76.36 42.8568 75.2366 41.7334 73.8508 41.7334C72.4651 41.7334 71.3417 42.8568 71.3417 44.2425C71.3417 45.6283 72.4651 46.7517 73.8508 46.7517ZM73.8508 45.2462C74.4051 45.2462 74.8545 44.7968 74.8545 44.2425C74.8545 43.6882 74.4051 43.2389 73.8508 43.2389C73.2965 43.2389 72.8472 43.6882 72.8472 44.2425C72.8472 44.7968 73.2965 45.2462 73.8508 45.2462Z"
                fill={colors.primary.main}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M70.338 38.7224C69.2294 38.7224 68.3307 39.6211 68.3307 40.7297V50.7663C68.3307 51.8749 69.2294 52.7737 70.338 52.7737H86.3966C87.5052 52.7737 88.4039 51.8749 88.4039 50.7663V40.7297C88.4039 39.6211 87.5052 38.7224 86.3966 38.7224H70.338ZM86.3966 40.7297H70.338V48.4282C70.5036 48.2208 70.6944 48.0345 70.9084 47.8702C71.7188 47.2478 72.7793 47.0026 73.8508 47.0026C74.9223 47.0026 75.9828 47.2478 76.7932 47.8702C77.6334 48.5154 78.1164 49.4992 78.1164 50.7663H86.3966V40.7297ZM76.6109 50.7663C76.6109 49.917 76.3074 49.3953 75.8763 49.0642C75.4153 48.7102 74.7194 48.5081 73.8508 48.5081C72.9823 48.5081 72.2863 48.7102 71.8254 49.0642C71.3942 49.3953 71.0908 49.917 71.0908 50.7663H76.6109Z"
                fill={colors.primary.main}
            />
        </Icon>
    );
}
