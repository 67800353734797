import {
    Button,
    ButtonProps,
    Heading,
    HStack,
    LinkBox,
    LinkOverlay,
    Stack,
    StackProps,
    Text,
    TextProps,
} from '@chakra-ui/react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import * as icons from 'design-system/icons';
import { LinkIntl } from '..';
import { UrlObject } from 'url';

type FeatureLinkCardProps = {
    title?: MessageDescriptor;
    description?: MessageDescriptor;
    link?: MessageDescriptor;
    linkHref?: string | UrlObject;
    linkIcon?: string;
    linkColor?: string;
    isButton?: boolean;
    illustration?: JSX.Element;
    onClick?: () => void;
    bg?: string;
};

const buttonStyle: StackProps & ButtonProps = {
    as: Button,
    color: 'primary',
    colorScheme: 'primary',
    w: 'fit-content',
    borderRadius: 'full',
};

const textStyle: TextProps = {
    fontSize: 'sm',
    whiteSpace: 'nowrap',
    overflow: 'ellipsis',
    fontWeight: 'medium',
    _groupHover: {
        textDecoration: 'underline',
    },
};

export function FeatureLinkCard({
    title,
    description,
    link,
    linkHref,
    linkIcon,
    illustration,
    isButton,
    onClick,
    linkColor = 'primary.dark',
    bg = 'primary.50',
}: FeatureLinkCardProps) {
    const Icon = icons[linkIcon];
    const buttonStyleLoaded = {
        ...buttonStyle,
        onClick,
    };

    return (
        <Stack bg={bg} borderRadius="lg" w="full" display={'flex'}>
            <LinkBox
                as={HStack}
                alignItems="center"
                p="5"
                data-group
                _hover={{
                    backdropFilter: 'saturate(300%) hue-rotate(30deg)',
                }}>
                <Stack
                    spacing="3"
                    pr="4"
                    alignSelf="start"
                    h="full"
                    justifyContent="space-between">
                    <Heading
                        fontSize="lg"
                        color="texts.main"
                        fontWeight="semibold">
                        <FormattedMessage {...title} />
                    </Heading>
                    <Text fontSize="sm" fontWeight="medium">
                        <FormattedMessage {...description} />
                    </Text>
                    <HStack
                        color={linkColor}
                        spacing="1"
                        {...(isButton && buttonStyleLoaded)}>
                        {Icon && <Icon h="3" w="3" />}
                        {linkHref ? (
                            <LinkOverlay
                                as={LinkIntl}
                                // @ts-expect-error
                                href={linkHref}
                                passHref>
                                <Text {...textStyle}>
                                    <FormattedMessage {...link} />
                                </Text>
                            </LinkOverlay>
                        ) : (
                            <Text {...textStyle}>
                                <FormattedMessage {...link} />
                            </Text>
                        )}
                        <icons.ArrowRightIcon
                            h="3"
                            w="3"
                            _groupHover={{
                                transform: 'translateX(4px) scale(1.2)',
                                transition: 'transform 0.2s ease-in-out',
                            }}
                        />
                    </HStack>
                </Stack>
                {illustration}
            </LinkBox>
        </Stack>
    );
}
