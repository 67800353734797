import { MutableRefObject, useMemo } from 'react';
import { setRef } from 'core';
import { RefNode, CallbackRef } from './types';

type AnyRef = CallbackRef | MutableRefObject<RefNode> | null;

/**
 * A hook that can combine two refs(mutable or callbackRefs) into a single
 * callbackRef
 */
function useForkRef(refA: AnyRef, refB: AnyRef): CallbackRef | null {
    /**
     * This will create a new function if the ref props change and are defined.
     * This means react will call the old forkRef with `null` and the new
     * forkRef with the ref.
     * Cleanup naturally emerges from this behavior
     */
    return useMemo(() => {
        if (refA === null && refB === null) return null;
        return (refValue: RefNode) => {
            setRef(refA, refValue);
            setRef(refB, refValue);
        };
    }, [refA, refB]);
}

export default useForkRef;
