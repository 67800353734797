import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
    'test-link.description': {
        id: 'widgets.test-link.description',
        defaultMessage: 'Hello {link1} Sorry but {link2}',
    },
    'test-link.description-link1': {
        id: 'widgets.test-link.description-link1',
        defaultMessage: "you're Zelda ?",
    },
    'test-link.description-link2': {
        id: 'widgets.test-link.description-link2',
        defaultMessage: "no I'm Link",
    },
});
