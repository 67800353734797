export default function useNativeInfos() {
    if (typeof window !== 'undefined') {
        const nativeBuildNumber =
            window.localStorage.getItem('nativeBuildNumber');
        const nativeVersionName =
            window.localStorage.getItem('nativeVersionName');

        return nativeBuildNumber || nativeVersionName
            ? {
                  'Native-Build-Number': nativeBuildNumber,
                  'Native-Version-Name': nativeVersionName,
              }
            : {};
    }

    return {};
}
