import React, { useContext, useMemo } from 'react';
import { Badge, BadgeProps } from '@chakra-ui/react';
import { capitalize } from 'lodash';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TooltipLayout } from 'core';
import { useWindowBreakpoints } from '../../hooks';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import useIsOverflow from 'core/lib/shared/helpers/isOverflow';
import { ThemeContext } from 'themes';

export type TagProps = BadgeProps & {
    formattedText: string;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    preventCapitalize?: boolean;
    icon?: React.ReactNode;
};

export function getSpecificSchemeProps(
    cssVarPrefix: string,
    colorScheme: BadgeProps['colorScheme'],
    variant: BadgeProps['variant']
) {
    if (colorScheme === 'grey') {
        switch (variant) {
            case 'solid':
                return {
                    bg: 'grey.900',
                };
            case 'outline':
                return {
                    color: 'grey.900',
                    borderColor: 'grey.900',
                    '--badge-color': `var(--${cssVarPrefix}-colors-grey-900);`,
                };
            case 'subtle':
                return {
                    bg: 'grey.100',
                };
            default:
                return {};
        }
    }

    return {};
}

const paddingIconOnly = {
    xs: '1',
    sm: '1',
    md: '2',
    '3xl': '3',
};

export function Tag({
    formattedText,
    leftIcon,
    rightIcon,
    rounded = 'sm',
    preventCapitalize = false,
    icon,
    colorScheme,
    variant,
    ...props
}: TagProps) {
    const { isSmallDevice } = useWindowBreakpoints();
    const formattedTextRef = React.useRef();
    const formattedTextIsOverflow = useIsOverflow(formattedTextRef);
    const { cssVarPrefix } = useContext(ThemeContext);
    const textToDisplay = useMemo(
        () => (preventCapitalize ? formattedText : capitalize(formattedText)),

        [formattedText, preventCapitalize]
    );

    return (
        <TooltipLayout
            isOverflow={formattedTextIsOverflow}
            text={textToDisplay}>
            <Badge
                display={(leftIcon || rightIcon) && 'flex'}
                gap={1}
                alignItems="center"
                ref={formattedTextRef}
                alignSelf={!isSmallDevice ? 'center' : undefined}
                rounded={rounded}
                py={icon ? paddingIconOnly[String(props?.fontSize)] : '1'}
                px={icon ? paddingIconOnly[String(props?.fontSize)] : '2'}
                fontSize={'xs'}
                fontWeight={'bold'}
                marginInlineStart={'0px'}
                textTransform="none"
                overflow="hidden"
                maxW={isSmallDevice ? 'fit-content' : '100%'}
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                variant={variant}
                colorScheme={colorScheme}
                sx={getSpecificSchemeProps(cssVarPrefix, colorScheme, variant)}
                {...props}>
                {leftIcon || icon}
                {!!textToDisplay && textToDisplay}
                {!icon && rightIcon}
            </Badge>
        </TooltipLayout>
    );
}
