import React, { ComponentType } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import { MessageDescriptor } from 'react-intl';
import {
    GlobalLayoutType,
    PEWorkflowTypesEnum,
    LayoutBoundaryType,
    HeaderBoxType,
    HeaderSecondaryType,
    RelatedActionsCategories,
    WorkflowTypesEnum,
    useRedirect,
    FormWorkflow,
    ViolationMessage,
    flattenViolations,
} from '../../../lib';
import {
    SignatureErrorStep,
    useSignatureBeneficiariesToasts,
    signatureMessages,
    serviceTypes,
} from '../../../lib/signature';
import { BeneficiariesHeader } from './BeneficiariesHeader';
import BeneficiaryForm from './BeneficiariesForm';
import { BeneficiariesSignatureFooter } from './BeneficiariesSignatureFooter';
import SignatureError from '../../signature/SignatureError';
import { handleNextStepFnType } from '../../beneficiary-designations/CardBeneficiaryOption';

type GlobalLayoutProps = GlobalLayoutType & {
    footerSticky?: boolean;
    disableFooter?: boolean;
    type?: PEWorkflowTypesEnum;
};

type LayoutBoundaryProps = LayoutBoundaryType &
    React.PropsWithChildren<{
        EmptyComponent?: React.ComponentType<{
            message?: MessageDescriptor;
        }>;
    }>;

export type HeaderBoxProps = HeaderBoxType;

export type HeaderSecondaryProps = HeaderSecondaryType & {
    breadcrumbsIconColor?: string;
} & React.PropsWithChildren<{
        relatedActions?: RelatedActionsCategories;
    }>;

type AddBeneficiariesProps = {
    service: WorkflowTypesEnum;
    GlobalLayout: ComponentType<GlobalLayoutProps>;
    LayoutBoundary: ComponentType<LayoutBoundaryProps>;
    HeaderBox: ComponentType<HeaderBoxProps>;
    HeaderSecondary: ComponentType<HeaderSecondaryProps>;
    handleNextStep: handleNextStepFnType;
    workflowSteps: string[];
};

export function AddBeneficiaries({
    service,
    GlobalLayout,
    LayoutBoundary,
    HeaderBox,
    HeaderSecondary,
    handleNextStep,
    workflowSteps,
}: AddBeneficiariesProps) {
    const { redirect } = useRedirect();
    const router = useRouter();
    const type = router.query?.type as 'full-capital' | 'divided-capital';
    const procedureId = router.query?.procedureId as string;
    const workflow = router.query?.workflow as FormWorkflow;
    const [errorStep, setErrorStep] = React.useState<SignatureErrorStep>();
    const [submitError, setSubmitError] = React.useState<number>();
    const [formErrors, setFormErrors] = React.useState<ViolationMessage[]>([]);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [cumulatedCapital, setCumulatedCapital] = React.useState<number>();
    const [
        fullCapitalBeneficiariesErrorType,
        setFullCapitalBeneficiariesErrorType,
    ] = React.useState<'rank' | 'main' | null>(null);
    const {
        cumulatedCapitalErrorToast,
        noMainBeneficiaryToast,
        beneficiariesRanksToast,
        addBeneficiaryErrorToast,
        editBeneficiaryErrorToast,
        deleteBeneficiaryErrorToast,
    } = useSignatureBeneficiariesToasts();

    function isSubmitEnabled() {
        if (type === 'divided-capital') {
            return cumulatedCapital === 100;
        }

        if (type === 'full-capital') {
            return fullCapitalBeneficiariesErrorType === null;
        }
    }

    async function onSubmit() {
        try {
            setIsSubmitting(true);
            const result = await axios.post(
                `/api/proxy/${serviceTypes[service]}/beneficiaries-designation/${type}/${procedureId}/beneficiaries`
            );

            handleNextStep(result?.data?.meta?.next_step, redirect, {
                workflow,
                type,
            });
        } catch (err) {
            if (err?.response?.status === 412) {
                window.scrollTo(0, 0);
                const resultData =
                    err?.response?.data?.data || err?.response?.data;
                if (resultData?.violations) {
                    const violations = flattenViolations(
                        resultData?.violations
                    );
                    setFormErrors(violations);
                }
            } else {
                setSubmitError(err?.response?.status);
            }
        }
        setIsSubmitting(false);
    }

    function onFormError(
        errorCode: number,
        step?: SignatureErrorStep,
        action?: 'add' | 'edit' | 'delete',
        type?: 'toast'
    ) {
        if (errorCode === 403 || type === 'toast') {
            switch (action) {
                case 'add':
                    addBeneficiaryErrorToast();
                    break;
                case 'edit':
                    editBeneficiaryErrorToast();
                    break;
                case 'delete':
                    deleteBeneficiaryErrorToast();
                    break;
            }
        } else {
            setSubmitError(errorCode);
            step ? setErrorStep(step) : setErrorStep(undefined);
        }
    }

    return (
        <GlobalLayout
            title={signatureMessages.beneficiariesDesignation}
            margin="auto"
            {...(service === WorkflowTypesEnum.PE
                ? { type: PEWorkflowTypesEnum.BD }
                : {})}>
            <BeneficiariesHeader
                {...{
                    HeaderBox,
                    HeaderSecondary,
                    service,
                    workflowSteps,
                    type,
                    submitError,
                }}
            />

            <LayoutBoundary
                status={submitError ? 'error' : 'success'}
                ErrorComponent={() => (
                    <SignatureError
                        errorCode={submitError}
                        // @ts-ignore
                        step={errorStep ?? `${type}-beneficiaries`}
                        service={service}
                    />
                )}
                pt="3"
                pb="5"
                as="main">
                <BeneficiaryForm
                    type={type}
                    service={service}
                    onCumulatedCapitalChange={setCumulatedCapital}
                    setSubmitError={setSubmitError}
                    setErrorStep={setErrorStep}
                    onFormError={onFormError}
                    formErrors={formErrors}
                    setFullCapitalBeneficiariesErrorType={
                        setFullCapitalBeneficiariesErrorType
                    }
                />

                <BeneficiariesSignatureFooter
                    workflow={workflow}
                    type={type}
                    service={service}
                    isSubmitEnabled={isSubmitEnabled}
                    onSubmit={onSubmit}
                    isSubmitting={isSubmitting}
                    cumulatedCapitalErrorToast={cumulatedCapitalErrorToast}
                    fullCapitalBeneficiariesErrorType={
                        fullCapitalBeneficiariesErrorType
                    }
                    noMainBeneficiaryToast={noMainBeneficiaryToast}
                    beneficiariesRanksToast={beneficiariesRanksToast}
                />
            </LayoutBoundary>
        </GlobalLayout>
    );
}
