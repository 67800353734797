import { parseDate, type FormValues } from 'core';

import { formatFormFinalObject } from './handlers';

function transformDate(inputId: string, data: FormValues) {
    const parsedData = { value: parseDate(data.value) };

    return formatFormFinalObject(inputId, parsedData);
}

function transformDateRange(inputId: string, data: FormValues) {
    const parsedStartDate = { startDate: parseDate(data.startDate) };
    const parsedEndDate = { endDate: parseDate(data.endDate) };

    return formatFormFinalObject(inputId, {
        ...parsedStartDate,
        ...parsedEndDate,
    });
}

export { transformDate, transformDateRange };
