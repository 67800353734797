import React from 'react';

type UseIntersectionObserverParams = {
    onIntersect: () => void;
    root?: React.MutableRefObject<any>;
    threshold?: number;
    rootMargin?: string;
    enabled?: boolean;
};

function useIntersectionObserver({
    root,
    onIntersect,
    threshold = 1.0,
    rootMargin = '0px',
    enabled = true,
}: UseIntersectionObserverParams) {
    const target = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (!enabled) return;

        const el = target?.current;
        if (!el) return;

        const observer = new IntersectionObserver(
            (entries) =>
                entries.forEach(
                    (entry) => entry.isIntersecting && onIntersect()
                ),
            {
                root: root?.current,
                rootMargin,
                threshold,
            }
        );

        observer.observe(el);

        return () => observer.unobserve(el);
    }, [enabled, root, rootMargin, threshold, target, onIntersect]);

    return target;
}

export type { UseIntersectionObserverParams };
export { useIntersectionObserver };
