import { Icon, IconProps } from '@chakra-ui/react';

export default function SettingsIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M11.874 9a4.002 4.002 0 0 1-7.748 0H3a1 1 0 0 1 0-2h1.126a4.002 4.002 0 0 1 7.748 0H21a1 1 0 1 1 0 2h-9.126zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM19.874 18a4.002 4.002 0 0 1-7.748 0H3a1 1 0 1 1 0-2h9.126a4.002 4.002 0 0 1 7.748 0H21a1 1 0 1 1 0 2h-1.126zM16 19a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
