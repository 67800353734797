import { validationFormsMessages, Validations, FormatMessageFn } from 'core';

function validateMaxSize(
    maxSize: Validations['maxSize'],
    formatMessage: FormatMessageFn
) {
    return (value: FileList) => {
        if (!maxSize) return undefined;
        if (!value || value.length === 0) return undefined;

        return value[0].size >= maxSize
            ? formatMessage(validationFormsMessages.maxSize, { maxSize })
            : undefined;
    };
}

export { validateMaxSize };
