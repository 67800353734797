import chalk from 'chalk';

export class Logger {
    static loading(message: string) {
        // eslint-disable-next-line no-console
        console.log(chalk.yellowBright(`🛠  ${message}`));
    }

    static error(message: string, exitCode: number = 1) {
        // eslint-disable-next-line no-console
        console.log(chalk.red(`🚨 ${message}`));
        process.exit?.(exitCode);
    }

    static success(message: string) {
        // eslint-disable-next-line no-console
        console.log(chalk.green(`✅ ${message}`));
    }

    static info(message: string) {
        // eslint-disable-next-line no-console
        console.log(chalk.yellow(message));
    }
}
