import React from 'react';
import { useToast } from 'design-system/components/Toast';

type DownloadStartingToastProps = {
    message: string;
};

export default function DownloadStartingToast({
    message,
}: DownloadStartingToastProps) {
    const toast = useToast({
        description: message,
        status: 'info',
        duration: 10000,
    });

    React.useEffect(() => {
        toast();
        return () => toast.closeAll({ positions: ['bottom'] });
    }, [toast]);

    return null;
}
