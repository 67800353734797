import React from 'react';
import { Badge, HStack, Text } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import {
    DashboardContext,
    SelectedFilterDate,
    SelectedFilterText,
} from 'core/lib/shared/contexts/DashboardContext';
import { formats, formMessages, useSafeIntl } from 'core';

type FiltersBadgeProps = React.PropsWithChildren<{
    id?: string;
    isSelected: boolean;
}>;

export function FilterBadge({ children, id, isSelected }: FiltersBadgeProps) {
    const { isFiltersOpened, setIsFiltersOpened, setOpenedFilterCategory } =
        React.useContext(DashboardContext);

    function handleOnClick(e) {
        if (!['path', 'svg'].includes(e.target.nodeName)) {
            setIsFiltersOpened(!isFiltersOpened);
            setOpenedFilterCategory(id);
        }
    }

    return (
        <Badge
            cursor="pointer"
            borderColor="grey.600"
            border="1px solid"
            rounded="full"
            py="1"
            px="3"
            fontSize="sm"
            fontWeight="semibold"
            bg={isSelected ? 'primary.main' : 'white'}
            color={isSelected ? 'white' : 'grey.600'}
            _selection={{ bg: 'transparent' }}
            textTransform="none"
            onClick={handleOnClick}>
            {children}
        </Badge>
    );
}

export function DateFilterContent({
    name,
    values,
}: Pick<SelectedFilterDate, 'name' | 'values'>) {
    const { formatDate } = useIntl();
    const formattedValues = {
        startDate:
            values?.startDate &&
            formatDate(
                dayjs(values?.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                formats.date
            ),
        endDate:
            values?.endDate &&
            formatDate(
                dayjs(values?.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                formats.date
            ),
    };

    return (
        <Text key={name} _selection={{ bg: 'transparent' }}>
            {values?.startDate && !values?.endDate && (
                <FormattedMessage
                    id="filters.selected.date-before"
                    defaultMessage="À partir du {startDate}"
                    values={formattedValues}
                />
            )}
            {values?.startDate && values?.endDate && (
                <FormattedMessage
                    id="filters.selected.date-between"
                    defaultMessage="Du {startDate} au {endDate}"
                    values={formattedValues}
                />
            )}
            {!values?.startDate && values?.endDate && (
                <FormattedMessage
                    id="filters.selected.date-after"
                    defaultMessage="Jusqu'au {endDate}"
                    values={formattedValues}
                />
            )}
        </Text>
    );
}

export function TextFilterContent({
    name,
    values,
    isFullWidth = false,
}: Pick<SelectedFilterText, 'name' | 'values'> & {
    isFullWidth?: boolean;
}) {
    const { safeFormatMessage } = useSafeIntl();

    return (
        <HStack
            divider={
                <Text mr="1" _selection={{ bg: 'transparent' }}>
                    ,
                </Text>
            }>
            {values?.slice(0, 1)?.map((value) => {
                const label = typeof value === 'string' ? value : value?.label;
                const translatedLabel =
                    label in formMessages
                        ? safeFormatMessage(
                              formMessages[label],
                              {
                                  month: 'X',
                              },
                              `SelectedFilters label: ${label}`
                          )
                        : label;

                return (
                    <Text
                        key={`${name}-${label}`}
                        _selection={{ bg: 'transparent' }}
                        maxW={isFullWidth ? 'unset' : '160px'}
                        noOfLines={isFullWidth ? 1 : undefined}
                        overflow="hidden"
                        textOverflow="ellipsis">
                        {translatedLabel}
                    </Text>
                );
            })}
            {values?.length > 1 && (
                <Text _selection={{ bg: 'transparent' }}>
                    {`+ ${values?.length - 1}`}
                </Text>
            )}
        </HStack>
    );
}
