import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { get } from 'lodash';
import {
    Checkbox as ChakraCheckbox,
    CheckboxProps as ChakraCheckboxProps,
    FormHelperText,
    FormErrorMessage,
    Button,
} from '@chakra-ui/react';
import Fieldset from '../Fieldset';
import { WidgetProps } from './types';
import {
    SafeFormattedMessage,
    formMessages,
    useInputValidation,
    UtilsContext,
} from 'core';
import { useController } from 'react-hook-form';

export type CheckboxProps = Omit<WidgetProps, 'defaultValue'> &
    ChakraCheckboxProps & {
        modalLink: {
            text: string;
            onClick?: () => void;
        };
    };

function Checkbox({
    name,
    id,
    validations,
    title,
    readonly,
    description,
    defaultValue,
    defaultChecked,
    modalLink,
    size,
    isChecked,
    onChange,
}: CheckboxProps) {
    const {
        siteConfig: { appName },
    } = React.useContext(UtilsContext);
    const inputName = name ?? `${id}.value`;
    const {
        registerValues,
        formState: { errors },
        control,
    } = useInputValidation(validations, inputName);
    const { field } = useController({ name: inputName, control });
    const isInvalid = Boolean(get(errors, inputName));

    return (
        <Fieldset
            legend={title}
            isRequired={validations.required}
            isInvalid={isInvalid}>
            <ChakraCheckbox
                isChecked={isChecked !== undefined ? isChecked : field.value}
                {...registerValues}
                onChange={(e) => {
                    if (onChange) onChange(e);
                    registerValues.onChange(e);
                }}
                defaultChecked={defaultChecked ?? Boolean(defaultValue)}
                isRequired={validations.required}
                isReadOnly={readonly}
                id={id}
                isInvalid={isInvalid}
                size={size}>
                {description && (
                    <FormHelperText mb="0">
                        <SafeFormattedMessage
                            {...formMessages[description]}
                            values={{ appName }}
                            debugKey={description}
                        />
                        {modalLink && (
                            <Button
                                ml="1"
                                variant="link"
                                onClick={modalLink.onClick}
                                color="black"
                                textDecor="underline"
                                fontSize="sm">
                                <SafeFormattedMessage
                                    {...formMessages[modalLink.text]}
                                    debugKey={modalLink.text}
                                />
                            </Button>
                        )}
                    </FormHelperText>
                )}
            </ChakraCheckbox>

            <ErrorMessage
                name={inputName}
                errors={errors}
                render={({ message }) => (
                    <FormErrorMessage data-testid="error">
                        {message}
                    </FormErrorMessage>
                )}
            />
        </Fieldset>
    );
}

export default Checkbox;
