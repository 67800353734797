import { defineMessages } from 'react-intl';

export const oAuthMessages = defineMessages({
    defaultTitle: {
        id: 'components.oauth.error.title',
        defaultMessage: 'Votre demande n’a pas pu aboutir',
    },
    '400_BAD_REQUEST': {
        id: 'components.oauth.error.400-bad-request.description',
        defaultMessage:
            '<p>Merci de bien vouloir réessayer plus tard.</p><p>Pour plus d’informations, nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 020</b></l>',
    },
    '404_NOT_FOUND': {
        id: 'components.oauth.error.404-not-found.description',
        defaultMessage:
            '<p>Merci de bien vouloir réessayer plus tard.</p><p>Pour plus d’informations, nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 021</b></l>',
    },
    '500_INTERNAL_SERVER_ERROR': {
        id: 'components.oauth.error.500-internal-server-error.description',
        defaultMessage:
            '<p>Merci de bien vouloir réessayer plus tard.</p><p>Pour plus d’informations, nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 022</b></l>',
    },
    INVALID_STATE: {
        id: 'components.oauth.error.invalid-state.description',
        defaultMessage: '<p>Merci de bien vouloir réessayer plus tard.</p>',
    },
    AZURE_ERROR: {
        id: 'components.oauth.error.azure-error.description',
        defaultMessage:
            '<p>Merci de bien vouloir réessayer plus tard.</p><p>Pour plus d’informations, nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 023</b></l>',
    },
});
