import React, { ComponentType, MouseEvent } from 'react';
import {
    Box,
    Center,
    Skeleton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    VStack,
} from '@chakra-ui/react';
import {
    ColumnDef,
    HeaderGroup,
    PaginationState,
    Row,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { useWindowBreakpoints } from 'design-system/hooks';
import { MobileRow } from './MobileRow';
import { DesktopRow } from './DesktopRow';
import { Feed } from '..';
import { Pagination } from './Pagination';

export const NB_RESULTS_PER_PAGE = 10;

export type EnhancedTableProps<T> = {
    cols: ColumnDef<T>[];
    data: T[];
    columnsConfig: number[];
    pagination: PaginationState;
    onPaginationChange: React.Dispatch<React.SetStateAction<PaginationState>>;
    fetchNextPage: () => void;
    onClickHandler: (event: MouseEvent<unknown>, row: T) => void;
    totalCount: number;
    isLoading: boolean;
    hasNextPage: boolean;
    EmptyComponent: ComponentType | null;
    ErrorComponent: ComponentType | null;
};

export function EnhancedTable<T>({
    cols,
    data,
    pagination,
    columnsConfig,
    onPaginationChange,
    fetchNextPage,
    onClickHandler,
    totalCount,
    isLoading,
    hasNextPage,
    EmptyComponent,
    ErrorComponent,
}: EnhancedTableProps<T>) {
    const { isSmallDevice } = useWindowBreakpoints();

    const table = useReactTable({
        data: data,
        columns: cols,
        rowCount: totalCount,

        state: {
            pagination,
        },
        getCoreRowModel: getCoreRowModel(),
        onPaginationChange: onPaginationChange,
        initialState: {
            pagination: {
                pageIndex: 1,
                pageSize: 10,
            },
        },
        manualPagination: true,
    });

    const _renderTableBody = (rows: Row<T>[]): JSX.Element => {
        if (isSmallDevice) {
            return (
                <Tr>
                    <Td p={0}>
                        <VStack gap={3}>
                            {rows.map((row) => (
                                <MobileRow<T>
                                    key={row.id}
                                    row={row}
                                    columnConfig={columnsConfig}
                                    onClickHandler={onClickHandler}
                                />
                            ))}
                        </VStack>
                    </Td>
                </Tr>
            );
        } else {
            return (
                <>
                    {rows.map((row) => (
                        <DesktopRow<T>
                            key={row.id}
                            row={row}
                            onClickHandler={onClickHandler}
                        />
                    ))}
                </>
            );
        }
    };

    const _renderTHeaders = (headerGroup: HeaderGroup<T>): JSX.Element => {
        return (
            <>
                {headerGroup.headers.map((header) => (
                    <Th
                        key={header.id}
                        position={'relative'}
                        color={'gray.800'}
                        textTransform={'none'}
                        fontSize={'md'}
                        fontWeight={'medium'}
                        lineHeight={5}>
                        <Box>
                            {header.isPlaceholder
                                ? null
                                : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                  )}
                        </Box>
                    </Th>
                ))}
            </>
        );
    };

    const _renderSkeleton = (): JSX.Element => {
        return (
            <>
                {Array.from({ length: NB_RESULTS_PER_PAGE }).map((_, idxTr) => (
                    <Tr key={idxTr} data-testid={`skeleton-${idxTr}`}>
                        {cols.map((_, idxTd) => (
                            <Td key={idxTd}>
                                <Skeleton h={4} />
                            </Td>
                        ))}
                    </Tr>
                ))}
            </>
        );
    };

    if (!!ErrorComponent)
        return (
            <Center mt={5} w={'100%'} bg={'white'} borderRadius={'lg'}>
                <ErrorComponent />
            </Center>
        );

    if (!!EmptyComponent)
        return (
            <Center mt={5} w={'100%'} bg={'white'} borderRadius={'lg'}>
                <EmptyComponent />
            </Center>
        );

    return (
        <VStack width={'100%'} mb={3}>
            <Feed
                onIntersect={fetchNextPage}
                isLoading={isLoading}
                isEnd={Boolean(hasNextPage)}
                isEnabled={isSmallDevice}
                w={'100%'}>
                <TableContainer
                    overflowX={'auto'}
                    bg={isSmallDevice ? 'gray.50' : 'white'}
                    w={'100%'}
                    mt={5}
                    {...(!isSmallDevice && {
                        border: '1.5px solid',
                        borderColor: 'gray.200',
                        borderRadius: 'lg',
                        minH: '400px',
                    })}>
                    <Table variant="simple">
                        {!isSmallDevice && (
                            <Thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <Tr
                                        key={headerGroup.id}
                                        bg={'gray.50'}
                                        borderBottom={'1.5px solid'}
                                        borderColor={'gray.200'}
                                        h={14}>
                                        {_renderTHeaders(headerGroup)}
                                    </Tr>
                                ))}
                            </Thead>
                        )}
                        <Tbody>
                            {isLoading && !isSmallDevice
                                ? _renderSkeleton()
                                : _renderTableBody(table.getRowModel().rows)}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Feed>
            {!isSmallDevice && (
                <Pagination<T> table={table} isLoading={isLoading} />
            )}
        </VStack>
    );
}
