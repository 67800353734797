/**
 * Ce composant écrase `Toast` et `useToast` de `@chakra-ui/react`, pour avoir
 * des icônes spécifiques par clients et correspondantes au design-system.
 */
import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/toast';
import {
    Flex,
    CloseButton,
    HStack,
    AlertStatus,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useWindowBreakpoints } from '../../hooks';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Alert, AlertIcon } from 'core';

const toastBgColor = {
    success: 'green.50',
    error: 'red.50',
    warning: 'orange.50',
    info: 'blue.50',
    infoFlash: 'primary.50',
};

const toastIconColor = {
    success: 'green.500',
    error: 'red.500',
    warning: 'orange.500',
    info: 'blue.500',
    infoFlash: 'primary.main',
};

function CustomToast({
    title,
    description,
    status = 'success',
    isInfoFlash,
    onClose,
    isClosable,
}: UseToastOptions & {
    status?: AlertStatus | 'infoFlash';
    onClose: () => void;
    isInfoFlash?: boolean;
}) {
    const { isMobile } = useWindowBreakpoints();
    const statusModified = isInfoFlash ? 'infoFlash' : status;

    return (
        <Alert
            as={HStack}
            status={status}
            color="white"
            display={'flex'}
            bgColor={toastBgColor[statusModified]}
            borderRadius="lg"
            gap="3"
            bottom={isMobile ? 16 : 2}
            boxShadow="0px 1px 5px -2px rgba(0, 0, 0, 0.35)"
            data-testid={`toast-${status}`}>
            <AlertIcon
                statusModified={statusModified}
                color={toastIconColor[statusModified]}
            />
            <Flex
                justifyContent="space-between"
                flex={1}
                alignItems="center"
                minH="8">
                <Flex flexDirection="column" fontSize="sm">
                    {title && (
                        <AlertTitle fontSize="md" color="texts.main">
                            {title}
                        </AlertTitle>
                    )}
                    {description && (
                        <AlertDescription color="texts.main">
                            {description}
                        </AlertDescription>
                    )}
                </Flex>
                {isClosable && (
                    <CloseButton
                        ml="4"
                        size="md"
                        alignSelf="flex-start"
                        color={toastIconColor[statusModified]}
                        onClick={onClose}
                    />
                )}
            </Flex>
        </Alert>
    );
}
export interface CustomUseToastOptionsProps
    extends Omit<UseToastOptions, 'status'> {
    status: AlertStatus | 'infoFlash';
}

const useToast = (options?: CustomUseToastOptionsProps) => {
    const { isMobile } = useWindowBreakpoints();
    const isInfoFlash = options?.status === 'infoFlash';
    const statusModified: AlertStatus = isInfoFlash
        ? 'info'
        : (options?.status as AlertStatus);

    return useChakraToast({
        render(props) {
            return (
                <CustomToast
                    {...props}
                    {...options}
                    status={statusModified}
                    isInfoFlash={isInfoFlash}
                />
            );
        },
        position: 'bottom',
        duration: statusModified === 'error' ? 1000 * 10 : 5000,
        isClosable: true,
        containerStyle: {
            width: isMobile ? '90%' : 'auto',
            height: 'auto',
            mx: 4,
            mb: 6,
        },
        ...options,
        status: statusModified,
    });
};

export { useToast };
export { createStandaloneToast } from '@chakra-ui/toast';
export type {
    UseToastOptions,
    CreateStandAloneToastParam,
    ToastPosition,
    ToastMessage,
    ToastId,
    ToastOptions,
    ToastState,
} from '@chakra-ui/toast';
