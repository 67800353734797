import { Icon, IconProps } from '@chakra-ui/react';

export default function RestaurantIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M19.03 12.582V3.028C19.03 2.462 18.566 2 18 2c-2.948 0-5.382 2.402-5.382 5.382v5.647c0 .568.462 1.03 1.029 1.03h.515L14 19.276c-.035.723.256 1.393.736 1.907l.01.011a2.645 2.645 0 0 0 3.742 0c.506-.506.747-1.183.747-1.9l-.206-6.713zm-3.089 7.477c-.176-.206-.294-.441-.294-.735l.176-5.706h1.648l.147 5.735c0 .265-.089.53-.265.706-.177.206-.441.294-.706.294-.265 0-.53-.118-.706-.294zm-5.03-7.625.207 6.89c0 .717-.241 1.394-.747 1.9a2.645 2.645 0 0 1-3.742 0l-.01-.011c-.48-.514-.77-1.184-.736-1.906l.176-6.872c.004-.55.479-.994 1.03-.994h.352V9.823A2.487 2.487 0 0 1 5 7.323v-3.94c0-.568.462-1.03 1.03-1.03.567 0 1.029.462 1.029 1.03v3.94c0 .228.179.425.412.44v-4.38c0-.568.462-1.03 1.029-1.03s1.03.462 1.03 1.03V7.76a.444.444 0 0 0 .382-.437V3.382c0-.567.462-1.029 1.03-1.029.6 0 .981.49 1.027.99l.002.02v3.96a2.487 2.487 0 0 1-2.442 2.5v1.618h.353c.535 0 1.026.4 1.03.993zm-1.735 7.654c.206-.206.295-.47.295-.735l-.206-6.324H7.647l-.176 6.324c0 .294.117.53.294.735.176.177.44.294.706.294.264 0 .529-.088.705-.294zm5.53-12.706v4.647H17v-7.8a3.306 3.306 0 0 0-2.294 3.153z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
