import { Icon, IconProps } from '@chakra-ui/react';

export default function FileDoneIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M15 13.06A.75.75 0 0 0 13.94 12L11 14.94l-.94-.94A.75.75 0 0 0 9 15.06l1.47 1.47a.75.75 0 0 0 1.06 0L15 13.06z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M4 19a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8.92a3 3 0 0 0-1.048-2.278l-4.573-3.92A3 3 0 0 0 12.427 2H7a3 3 0 0 0-3 3v14zm3 1a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5.427a1 1 0 0 1 .65.24l4.574 3.92a1 1 0 0 1 .349.76V19a1 1 0 0 1-1 1H7z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
