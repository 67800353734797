import axios from 'axios';
import { ResponseViolation, WidgetObject, BaseIri } from 'core';

export const postForm = async (
    iri: string,
    values: WidgetObject,
    type?: BaseIri
) => {
    try {
        return await axios.post<ResponseViolation>(
            `/api/proxy/${type}${iri}`,
            { values },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
    } catch (err) {
        return err.response;
    }
};
