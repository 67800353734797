import { FormWorkflow, WorkflowTypesEnum } from 'core';

export function getSignatureCancelButtonHref(
    workflow: FormWorkflow,
    levelId?: string,
    service = WorkflowTypesEnum.PA
) {
    const tunnelType = window.sessionStorage.getItem('tunnelType') || '';

    switch (workflow) {
        case 'signature-health-subscription':
            return {
                pathname: '/account/products/levels/[id]',
                query: {
                    id: levelId,
                },
            };
        case 'signature-beneficiaries-designation.standard':
        case 'signature-beneficiaries-designation.specific-guided':
            return {
                pathname:
                    service === WorkflowTypesEnum.PA
                        ? '/account/beneficiaries-designation'
                        : '/beneficiary-designations',
            };
        case 'signature_exemption':
            return '/exemptions';
        case 'legal-documents':
            return '/legal-documents/form';
        default:
            return service === WorkflowTypesEnum.PA
                ? '/messages/new'
                : `/${tunnelType}`;
    }
}
