import { Icon, IconProps } from '@chakra-ui/react';

export default function PenIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M15.586 3.414a2 2 0 0 1 2.828 0l2.09 2.09a2 2 0 0 1 .01 2.82L8.797 20.186a1 1 0 0 1-.527.28l-3.01.565a2 2 0 0 1-2.322-2.4l.672-3.02a1 1 0 0 1 .269-.49L15.586 3.413zM5.458 16.518l-.566 2.547 2.621-.491-2.055-2.056zm.986-1.134 2.157 2.156 8.429-8.535-2.103-2.103-8.482 8.482zM19.09 6.919l-1.006 1.018-2.097-2.096L17 4.828l2.09 2.09z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
