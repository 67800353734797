import { Icon, IconProps } from '@chakra-ui/react';

export default function EuroIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M15.571 4c-3.15 0-5.893 2.05-7.022 5H13a1 1 0 1 1 0 2H8.058a8.57 8.57 0 0 0 0 2H13a1 1 0 1 1 0 2H8.549c1.13 2.95 3.873 5 7.022 5 .721 0 1.418-.106 2.077-.305.53-.159 1.122.054 1.352.556.23.502.011 1.101-.513 1.276-.92.307-1.9.473-2.916.473-4.316 0-7.917-2.965-9.132-7H5a1 1 0 1 1 0-2h1.048a10.523 10.523 0 0 1 0-2H5a1 1 0 1 1 0-2h1.44c1.214-4.035 4.815-7 9.131-7 1.017 0 1.997.166 2.916.473.524.175.743.774.513 1.276-.23.502-.823.715-1.352.556A7.196 7.196 0 0 0 15.571 4z"
                fill="currentColor"
            />
        </Icon>
    );
}
