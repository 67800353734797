import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import type { FormObject, FetchQueryParams } from 'core';

export type AccountType = 'beneficiary' | 'main';

const paths = {
    beneficiary:
        '/api/proxy/security/public/user/beneficiary-accounts/password/reset/validation/[token]',
    main: '/api/proxy/security/public/user/password/reset/[clientId]',
};

export async function fetchResetPassword({
    queryKey: [urn, clientId, token],
}: FetchQueryParams) {
    try {
        const response = await axios.options(
            urn.replace('[clientId]', clientId).replace('[token]', token)
        );
        return response.data.data as FormObject;
    } catch (error) {
        throw { code: error.response.status };
    }
}

export function useResetPassword(
    clientId: string,
    accountType: AccountType,
    token?: string
) {
    return useQuery<FormObject, Error>({
        queryKey: [paths[accountType], clientId, token],
        queryFn: fetchResetPassword,
    });
}
