import { Icon, IconProps } from '@chakra-ui/react';

export default function TouchIdIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M6 4.546c.296.466.915.603 1.42.379 1.912-.85 5.434-1.592 9.207.063.506.222 1.111.044 1.373-.442s.08-1.097-.423-1.325c-4.598-2.082-8.92-1.105-11.238.002-.499.237-.636.857-.339 1.323zM14.74 17.821c.685.385 1.486.582 2.2.656.55.056 1.026.472 1.06 1.023.034.551-.387 1.03-.938.994-1.014-.066-2.223-.324-3.301-.928-1.314-.737-2.406-1.983-2.69-3.885-.08-.546.377-.997.929-.997s.982.454 1.104.993c.242 1.065.878 1.72 1.635 2.144z"
                fill="currentColor"
            />
            <path
                d="M15.23 6.527c-2.288-.543-4.829-.424-7 .552-2.202.989-3.993 2.85-4.7 5.679-.29 1.162-.28 2.544-.149 3.727.08.721.213 1.432.38 2.025.15.532.745.737 1.239.49.494-.247.717-.86.592-1.398a11.826 11.826 0 0 1-.223-1.337c-.119-1.067-.108-2.185.101-3.023.549-2.195 1.899-3.584 3.579-4.339 1.71-.768 3.79-.887 5.72-.43 1.583.375 2.748 1.51 3.414 2.823.332.654.525 1.324.584 1.917.061.606-.022 1.062-.161 1.34-.336.67-.78.787-1.05.763-.32-.028-.529-.234-.57-.48-.27-1.62-.817-2.865-1.72-3.697C14.345 10.292 13.195 10 12 10c-3.132 0-5 2.531-5 5-.053 1.098.423 3.715 2.616 6.047.363.387.97.367 1.345-.008L11 21c.39-.39.386-1.02.006-1.42C9.854 18.364 9 17.143 9 15c0-1.531 1.132-3 3-3 .872 0 1.473.208 1.91.61.455.419.874 1.175 1.104 2.554.209 1.254 1.25 2.048 2.368 2.145 1.167.101 2.348-.532 3.012-1.862.361-.722.448-1.589.363-2.434a7.544 7.544 0 0 0-.79-2.622c-.868-1.708-2.454-3.323-4.736-3.864z"
                fill="currentColor"
            />
        </Icon>
    );
}
