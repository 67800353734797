import axios from 'axios';
import { APIError } from 'core';
import { useQuery } from '@tanstack/react-query';
import { ProfileReasons } from '../types';

export const profileReasonsPath = `/api/proxy/selfcare/profile/reasons`;

export async function fetchProfileReasons() {
    try {
        const response = await axios.get(profileReasonsPath);
        return response.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useProfileReasons() {
    return useQuery<ProfileReasons, APIError>({
        queryKey: [profileReasonsPath],
        queryFn: fetchProfileReasons,
    });
}
