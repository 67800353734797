import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { coreSharedMessages } from 'core';
import {
    CoreModal,
    CoreModalContent,
    CoreModalHeader,
    CoreModalBody,
    CoreModalFooter,
} from 'design-system/components';
import React, { ReactElement } from 'react';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';

const intlMessages = {
    warning: defineMessage({
        id: 'components.confirmation-modal.description',
        defaultMessage: "Cette action n'est pas réversible.",
    }),
};

type DeleteConfirmationModalProps = {
    isOpen: boolean;
    message?: string;
    messageComponent?: ReactElement;
    isLoading?: boolean;
    onClose: () => void;
    onConfirm: () => void;
    showWarning?: boolean;
};

export default function DeleteConfirmationModal({
    isOpen,
    message,
    messageComponent,
    isLoading = false,
    onClose,
    onConfirm,
    showWarning = true,
}: DeleteConfirmationModalProps) {
    const { formatMessage } = useIntl();

    const _renderButtons = () => {
        return (
            <HStack w={'100%'}>
                <Button onClick={onClose} colorScheme="gray" w={'full'}>
                    <FormattedMessage {...coreSharedMessages.cancel} />
                </Button>
                <Button
                    colorScheme={'primary'}
                    onClick={onConfirm}
                    w={'100%'}
                    isLoading={isLoading}
                    loadingText={formatMessage(coreSharedMessages.submitting)}>
                    <FormattedMessage {...coreSharedMessages.confirm} />
                </Button>
            </HStack>
        );
    };

    return (
        <CoreModal isOpen={isOpen} onClose={onClose} size={'md'}>
            <CoreModalContent>
                <CoreModalHeader>
                    <FormattedMessage {...coreSharedMessages.confirmation} />
                </CoreModalHeader>
                <CoreModalBody>
                    <Box textAlign={'center'} mt={4}>
                        {message && <Text as="b">{message}</Text>}
                        {messageComponent && messageComponent}
                        {showWarning && (
                            <Text mt={3} fontSize={'sm'}>
                                <FormattedMessage {...intlMessages.warning} />
                            </Text>
                        )}
                    </Box>
                </CoreModalBody>
                <CoreModalFooter w={'100%'}>{_renderButtons()}</CoreModalFooter>
            </CoreModalContent>
        </CoreModal>
    );
}
