import { defineMessage, defineMessages } from 'react-intl';

export const defaultErrorMessages = defineMessages({
    serviceUnavailable: {
        id: 'errors.unavailable-service.text',
        defaultMessage:
            'Ce service est momentanément indisponible. Veuillez réessayer plus tard.',
    },
    serviceNotFound: {
        id: 'errors.not-found-service.text',
        defaultMessage: 'Ce service est indisponible.',
    },
});

const sharedApi400 = {
    default: defaultErrorMessages.serviceUnavailable,
    ...defineMessages({
        '/user/account': {
            id: 'api.shared.400.delete-user-account',
            defaultMessage:
                "La suppression de compte n'a pas pu être effectuée",
        },
        '/account/contributions': {
            id: 'api.shared.400.contributions',
            defaultMessage:
                'Les informations relatives à vos cotisations sont momentanément indisponibles, veuillez réessayer plus tard.',
        },
        '/refunds': {
            id: 'api.shared.400.refunds',
            defaultMessage:
                'L’accès à vos remboursements est momentanément indisponible, veuillez réessayer plus tard.',
        },
        '/messages': {
            id: 'api.shared.400.messages',
            defaultMessage:
                'L’accès à la messagerie est momentanément indisponible, veuillez réessayer plus tard.',
        },
        '/refunds/e-statements': {
            id: 'api.shared.400.statements',
            defaultMessage:
                'Votre décompte de remboursement est momentanément indisponible, veuillez réessayer plus tard.',
        },
        '/contracts/details': {
            id: 'api.shared.400.contract-details',
            defaultMessage:
                'Vos informations sont momentanément indisponibles, veuillez réessayer plus tard.',
        },
    }),
};

const sharedApi401 = defineMessages({
    default: {
        id: 'errors.timeout-session.text',
        defaultMessage: 'Vous avez été déconnecté.',
    },
});

const sharedApi403 = {
    default: defaultErrorMessages.serviceNotFound,
};

const sharedApi404 = {
    default: defaultErrorMessages.serviceNotFound,
};

const sharedApi500 = {
    default: defaultErrorMessages.serviceUnavailable,
    ...defineMessages({
        '/account/contributions': {
            id: 'api.shared.500.contributions',
            defaultMessage:
                'Les informations relatives à vos cotisations sont momentanément indisponibles, veuillez réessayer plus tard.',
        },
        '/contracts/details': {
            id: 'api.shared.500.contract-details',
            description:
                "Message d'erreurs en cas de 500 sur /contracts/details",
            defaultMessage:
                'Vos informations sont momentanément indisponibles, veuillez réessayer plus tard.',
        },
    }),
};

export const interceptorMessages = {
    default: defineMessage({
        id: 'api.shared.default',
        description: "Message d'erreur de retour api par défaut",
        defaultMessage: 'Une erreur est survenue. Veuillez réessayer.',
    }),
    400: sharedApi400,
    401: sharedApi401,
    403: sharedApi403,
    404: sharedApi404,
    500: sharedApi500,
    502: sharedApi500,
    503: sharedApi500,
    504: sharedApi500,
};
