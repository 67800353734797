/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { size, omit } from 'lodash';
import {
    FieldValues,
    useController,
    useFormContext,
    UseFormReturn,
} from 'react-hook-form';
import { Text, Box, useRadioGroup, SimpleGrid } from '@chakra-ui/react';
import {
    SafeFormattedMessage,
    formMessages,
    FormLibrarian,
    FormGroup,
    useInputValidation,
    usePrevious,
} from 'core';
import type { RadioProps, WidgetProps } from '../types';
import RadioCard from '../InputRadio/RadioCard';

type InputRadiosProps = WidgetProps & {
    options: RadioProps[];
    methods: Pick<UseFormReturn<FieldValues>, 'setError' | 'clearErrors'> & {
        errors: Pick<UseFormReturn<FieldValues>['formState'], 'errors'>;
    };
    nbDisplayColumns?: number;
    oneColumn: boolean;
    subDefaultValue: string | number;
};

const getSubwidgetId = (inputId: string, subwidgetId: string): string =>
    subwidgetId.includes(`${inputId}.`)
        ? subwidgetId
        : `${inputId}.${subwidgetId}`;

export default function InputConditionalRadio({
    name,
    id,
    validations,
    title,
    links,
    readonly,
    description,
    help,
    options,
    defaultValue,
    subDefaultValue,
    methods,
    nbDisplayColumns,
    oneColumn = false,
}: InputRadiosProps) {
    const [desc, setDesc] = React.useState<string | undefined>();
    const [subWidgets, setSubWidgets] = React.useState<any | any[] | undefined>(
        []
    );
    const inputName = name ?? `${id}.value`;
    const { registerValues } = useInputValidation(validations, inputName);
    const {
        unregister,
        formState: { errors },
        control,
    } = useFormContext();
    const { field } = useController({ name: inputName, control, defaultValue });
    const { getRootProps, getRadioProps, setValue, value } = useRadioGroup({
        ...field,
    });
    const previousValue = usePrevious(value);

    const group = getRootProps();

    React.useEffect(() => {
        const value = defaultValue as string;
        const selectedOption = options.find((option) => option.id === value);
        setValue(selectedOption?.id || value);
        setSubWidgets(selectedOption?.subWidgets);
        setDesc(options.find((option) => option.id === value)?.description);
    }, [defaultValue]);

    const onClick = (option) => {
        setDesc(option.description);
        setSubWidgets(option.subWidgets);
    };

    useEffect(() => {
        if (previousValue) {
            const previousOption = options.find(
                (option) => option.id === previousValue
            );
            const previousSubwidgets = previousOption?.subWidgets || [];
            // Autocompletecheckboxlist subwidget doesn't have 'value' prop
            const subwidgetsToUnregister = previousSubwidgets.map((subwidget) =>
                subwidget.type === 'autocompleteCheckboxList'
                    ? getSubwidgetId(id, subwidget.id)
                    : `${getSubwidgetId(id, subwidget.id)}.value`
            );
            if (subwidgetsToUnregister.length)
                unregister(subwidgetsToUnregister);
        }
    }, [subWidgets]);

    return (
        <FormGroup
            label={title}
            name={inputName}
            isRequired={validations.required}
            isReadOnly={readonly}
            {...{ id, description, help, links }}>
            <SimpleGrid
                my="2"
                {...group}
                columns={{
                    base: 1,
                    md: nbDisplayColumns || (oneColumn ? 1 : 2),
                }}
                gap="4"
                spacing={4}>
                {options.map((option) => {
                    const radio = getRadioProps({ value: option.id });
                    return (
                        <Box key={option.id} onClick={() => onClick(option)}>
                            <RadioCard
                                errors={errors}
                                optionId={option.id}
                                widgetId={id}
                                radioRef={registerValues.ref}
                                {...omit(registerValues, 'ref')}
                                {...omit(radio, 'ref')}>
                                <SafeFormattedMessage
                                    {...formMessages[option.label]}
                                    debugKey={option.label}
                                />
                            </RadioCard>
                        </Box>
                    );
                })}
            </SimpleGrid>
            {!size(errors) && desc && (
                <Text mt="10">
                    <SafeFormattedMessage
                        {...formMessages[desc]}
                        debugKey={desc}
                    />
                </Text>
            )}
            {subWidgets?.map((subWidget) => (
                <Box key={subWidget.id} pt={3}>
                    <FormLibrarian
                        type={subWidget.type}
                        props={{
                            ...subWidget,
                            id: getSubwidgetId(id, subWidget.id),
                            methods: {
                                setError: methods.setError,
                                errors: methods.errors,
                                clearErrors: methods.clearErrors,
                            },
                            defaultValue:
                                subWidget.defaultValue || subDefaultValue,
                        }}
                    />
                </Box>
            ))}
        </FormGroup>
    );
}
