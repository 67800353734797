import React from 'react';
import axios, { AxiosResponse } from 'axios';
import type { MutationStatus } from '@tanstack/react-query';
import { Box } from '@chakra-ui/react';
import DocumentPreviewHeader from '../DocumentPreviewHeader';
import FileViewer from '../FileViewer';
import { useUserAgent, UtilsContext } from 'core';

type DocumentPreviewProps = {
    data: AxiosResponse<any> | undefined;
    status: MutationStatus;
    errorCode?: number;
};

export default function DocumentPreview({
    data,
    status,
    errorCode,
}: DocumentPreviewProps) {
    const {
        layouts: { LayoutBoundary },
    } = React.useContext(UtilsContext);
    const { isIOS } = useUserAgent();
    const [base64EncodedFile, setBase64EncodedFile] = React.useState<
        string | null
    >('');
    const isFileReady = !isIOS || (isIOS && base64EncodedFile !== '');
    const fileLink = isFileReady
        ? `${data?.config.url}${isIOS ? '#download' : ''}`
        : undefined;

    React.useEffect(() => {
        if (isIOS && data?.config.url && !isFileReady)
            axios
                .get(data.config.url, { responseType: 'blob' })
                .then(async (res) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(res.data);
                    reader.onloadend = () => {
                        setBase64EncodedFile(reader.result as string);
                    };
                });
    }, [data?.config?.url, isFileReady, isIOS]);

    return (
        <Box h="calc(100vh - 56px)">
            {isIOS && (
                <input
                    id="base64File"
                    type="hidden"
                    value={base64EncodedFile || undefined}
                />
            )}

            <DocumentPreviewHeader
                status={!isFileReady ? 'pending' : status}
                fileLink={fileLink}
            />

            <LayoutBoundary
                height="100%"
                w="100%"
                status={status}
                py={status !== 'success' ? 8 : 0}
                errorCode={errorCode}
                mt="14"
                placeContent="center">
                {data && <FileViewer data={data} />}
            </LayoutBoundary>
        </Box>
    );
}
