import { FormValues } from 'core';
import { PostBankDetails } from '../types';

export const formatBeneficiaryBankDetails = (
    data: FormValues
): PostBankDetails => ({
    iban: data?.['bank-details']?.iban,
    bic: data?.['bank-details']?.bic,
    bankName: data?.['bank-details']?.bank,
    owner: data?.['bank-details']?.owner,
    signatureLocation: data?.['signature-location']?.value || null,
});
