import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { TPCardDetails } from '../types';
import { APIError } from 'core';

const path = `/api/proxy/selfcare/health/tp-card/details`;

export async function fetchTPCardDetails({ queryKey: [urn] }) {
    const { data: response } = await axios.get(urn);
    const { data }: Api<{ tpCardInformations: TPCardDetails }> = response;
    return data?.tpCardInformations;
}

function getStatusCode(error: any): number | undefined {
    return error?.status || error?.response?.status;
}

export function useTPCardDetails() {
    const queryProps = useQuery<TPCardDetails, APIError>({
        queryKey: [path],
        queryFn: async ({ queryKey }) => {
            const [urn] = queryKey;
            return await fetchTPCardDetails({ queryKey: [urn] });
        },
    });

    return {
        errorStatusCode: getStatusCode(queryProps.error),
        ...queryProps,
    };
}
