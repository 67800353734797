import {
    validationFormsMessages,
    FormatMessageFn,
    WatchFn,
    ValidationOptions,
} from 'core';

function validateRangeBetween(
    options: ValidationOptions | undefined,
    watch: WatchFn,
    formatMessage: FormatMessageFn
) {
    return (value: string) => {
        if (!options?.rangeBetween) return undefined;

        const comparedInputValue = watch(options?.rangeBetween?.id);

        if (options?.rangeBetween?.minRangeValue) {
            if (
                parseInt(value) - parseInt(comparedInputValue) <
                options?.rangeBetween?.minRangeValue
            ) {
                return formatMessage(validationFormsMessages.rangeMin, {
                    range: options?.rangeBetween?.minRangeValue,
                });
            }
        }

        if (options?.rangeBetween?.maxRangeValue) {
            if (
                parseInt(value) - parseInt(comparedInputValue) >
                options?.rangeBetween?.maxRangeValue
            ) {
                return formatMessage(validationFormsMessages.rangeMax, {
                    range: options?.rangeBetween?.maxRangeValue,
                });
            }
        }

        return undefined;
    };
}

export { validateRangeBetween };
