import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
    maxW: '892px',
    px: '4',
});

const sizes = {
    full: defineStyle({
        maxW: '100%',
    }),
};

// export the component theme
export const containerTheme = defineStyleConfig({ baseStyle, sizes });
