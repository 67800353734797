import { Icon, IconProps } from '@chakra-ui/react';

export default function MortarIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M18.503 9H21c.552 0 1.005.449.95.999C21.45 15.053 17.187 19 12 19c-5.186 0-9.45-3.947-9.95-9.001C1.994 9.449 2.447 9 3 9h7.035l5.782-5.85a3.006 3.006 0 0 1 4.285 4.218L18.502 9zm-5.656 0h2.856l2.97-3.032a1.006 1.006 0 0 0-1.434-1.411L12.847 9zm6.901 2a8.004 8.004 0 0 1-15.496 0h15.496z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                d="M6 21a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1z"
                fill="currentColor"
            />
        </Icon>
    );
}
