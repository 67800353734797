import { validationFormsMessages, FormatMessageFn } from 'core';

export function validateRegex(
    regexPattern: string,
    regexFlags: string[],
    formatMessage: FormatMessageFn
) {
    return (value: string) => {
        const formattedRegex = new RegExp(
            regexPattern || '',
            regexFlags?.join() || ''
        );

        return formattedRegex.test(value)
            ? undefined
            : formatMessage(validationFormsMessages['regexPattern']);
    };
}
