import { useTheme } from '@chakra-ui/react';
import { useSafeIntl } from 'core';
import { Amounts, manageDoughnutDatas } from 'lib/refunds';
import { useUserContext } from 'lib/user';
import { useIntl } from 'react-intl';

export function useDoughnut(amounts: Amounts) {
    const { userInfos } = useUserContext();
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { safeFormatNumber } = useSafeIntl();

    const data = manageDoughnutDatas({
        amounts,
        hasCoInsurer: userInfos?.coInsurer !== null,
        theme,
    }).map((detail) => ({
        ...detail,
        name: formatMessage(detail.name),
        value: safeFormatNumber(
            +detail.value,
            {
                currency: 'eur',
                style: 'currency',
            },
            'useDoughnut#data'
        ),
    }));

    const total = safeFormatNumber(
        +amounts.totalAmount,
        {
            currency: 'eur',
            style: 'currency',
        },
        'useDoughnut#total'
    );

    return { data, total };
}
