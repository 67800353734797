import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateNodesFromDOM } from '@lexical/html';
import { $getRoot, $insertNodes } from 'lexical';

type LoadInitialContentProps = { initialContent: string };

export const LoadInitialContent = ({
    initialContent,
}: LoadInitialContentProps) => {
    const [editor] = useLexicalComposerContext();

    React.useEffect(() => {
        editor.update(() => {
            const root = $getRoot();
            const parser = new DOMParser();
            const dom = parser.parseFromString(initialContent, 'text/html');
            const nodes = $generateNodesFromDOM(editor, dom);
            root.select();
            root.clear(); // Otherwise the initial content is rendered twice due to strictMode
            $insertNodes(nodes);
        });
    }, [editor, initialContent]);
    return null;
};
