import { ParsedUrlQueryInput } from 'querystring';
import * as Sentry from '@sentry/nextjs';
import { UrlObject } from 'url';
import { getIntlPath } from './getIntlPath';

type Queries = string | ParsedUrlQueryInput;

function getPathHash(pathname: string, hash?: string) {
    return hash ? `${pathname}#${hash}` : pathname;
}

function getTranslatedObjectPath(
    routes: { [x: string]: { [x: string]: string } },
    pathname: string,
    queries: Queries,
    locale: string
) {
    const slugRegex = new RegExp(
        `\\[\\.*(${Object.keys(queries).join('|')})\\]`,
        'gi'
    );
    const routingPath = pathname.replace(slugRegex, (matched) => {
        const [, queryKey] = matched.split(/\[\.*|]/);
        return `:${queryKey}`;
    });

    return getIntlPath(routes, routingPath, locale);
}

function getPathQueries(pathname: string, queries: Queries) {
    const predefinedQueries = Object.keys(queries)
        .filter((query) => !pathname.includes(`:${query}`))
        .map((query) => `${query}=${queries[query]}`);
    const routeSlugRegex = new RegExp(
        `:(${Object.keys(queries).join('|')})`,
        'gi'
    );
    const pathWithDynamicQueries = pathname.replace(
        routeSlugRegex,
        (matched) => {
            const queryKey = matched.replace(':', '');
            return queries[queryKey];
        }
    );

    return predefinedQueries.length
        ? `${pathWithDynamicQueries}?${predefinedQueries.join('&')}`
        : pathWithDynamicQueries;
}

function redirectToParentPage(
    routes: { [x: string]: { [x: string]: string } },
    href: string | UrlObject,
    locale: string,
    homepagePath: string
) {
    try {
        const parentPath = (typeof href === 'string' ? href : href.pathname)
            .split('/')
            .slice(0, -1)
            .join('/');
        return getTranslatedObjectPath(
            routes,
            parentPath,
            typeof href === 'string' || !href.query ? {} : href.query,
            locale
        );
    } catch (err) {
        return homepagePath || '/';
    }
}

function translatePathWithParam(
    routes: { [x: string]: { [x: string]: string } },
    href: string | UrlObject,
    locale: string,
    homepagePath: string
) {
    try {
        const pathname = typeof href === 'string' ? href : href.pathname;
        const { hash, query: queries } = typeof href !== 'string' && href;

        if (typeof href === 'string' || !queries) {
            const translatedPath = getIntlPath(routes, pathname, locale);

            return getPathHash(translatedPath, hash);
        }

        const translatedPath = getTranslatedObjectPath(
            routes,
            pathname,
            queries,
            locale
        );
        const pathWithQueries = getPathQueries(translatedPath, queries);

        return getPathHash(pathWithQueries, hash);
    } catch (err) {
        Sentry.captureException(
            new Error(
                `Une erreur est survenue dans #translatePathWithParam avec l'url ${
                    typeof href === 'string' ? href : href.pathname
                }. ${err.message}`
            )
        );
        return redirectToParentPage(routes, href, locale, homepagePath);
    }
}

export { translatePathWithParam };
