import { useEffect, useState, useCallback, useRef } from 'react';
import { useCallbackRef } from '@chakra-ui/react';
import { CallbackRef, RefNode } from './types';
import { useFocusTrap, useClickOutside } from '.';
import { ClickOutsideMode } from './useClickOutside';

type Options = {
    trapFocus?: boolean;
    closeOnClickOutside?: boolean;
    clickOutsideMode: ClickOutsideMode;
};

const CLOSABLE_KEYS = ['Backspace', 'Escape'];

/**
 * TODO: focused last element when click
 * @param onClose
 * @param options
 */
function useModal(onClose: () => void, options?: Options): CallbackRef {
    const callbackRef = useCallbackRef(onClose);
    const [modalRef, setModalRef] = useState<RefNode>(null);
    const lastFocusedElement = useRef<RefNode>(null);
    // Store the options in a ref, for performance optimization
    const optionsRef = useRef<Options>({
        trapFocus: true,
        closeOnClickOutside: true,
        ...options,
    });
    const [focusTrapRef] = useFocusTrap();
    const clickOutsideRef = useClickOutside(onClose, options.clickOutsideMode);

    const ref = useCallback(
        (node: RefNode) => {
            optionsRef.current.trapFocus && focusTrapRef(node);
            optionsRef.current.closeOnClickOutside && clickOutsideRef(node);
            setModalRef(node);
        },
        [clickOutsideRef, focusTrapRef, optionsRef]
    );

    useEffect(() => {
        function handleKeydown(evt: KeyboardEvent): void {
            if (!modalRef) {
                lastFocusedElement.current =
                    document.activeElement as HTMLElement;
            }

            if (CLOSABLE_KEYS.includes(evt.key)) {
                callbackRef?.();
                lastFocusedElement.current?.focus();
            }
        }

        document.addEventListener('keydown', handleKeydown, true);
        return (): void => {
            document.removeEventListener('keydown', handleKeydown, true);
        };
    }, [modalRef, callbackRef]);

    return ref;
}

export default useModal;
