import React from 'react';
import { uniqueId } from 'lodash';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import {
    Box,
    Heading,
    HStack,
    SimpleGrid,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import {
    UtilsContext,
    FormErrors,
    ViolationMessage,
    WorkflowTypesEnum,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import {
    SignatureCapitalBeneficiary,
    signatureCapitalPath,
    SignatureErrorStep,
    useSignatureCapitalBeneficiaries,
} from 'core/lib/signature';
import SignatureAddBeneficiary from '../SignatureAddBeneficiary';
import SignatureModalBeneficiary from '../SignatureModalBeneficiary';
import SignatureSelectedBeneficiary from '../SignatureSelectedBeneficiary';

type FullCapitalProps = {
    service: WorkflowTypesEnum;
    onBeneficiariesUpdate: (errorType: 'rank' | 'main' | null) => void;
    onError: (errorCode: number) => void;
    onFormError: (
        error: number,
        step?: SignatureErrorStep,
        action?: 'add' | 'edit' | 'delete',
        type?: 'toast'
    ) => void;
    formErrors: ViolationMessage[];
};

export default function FullCapital({
    service,
    onBeneficiariesUpdate,
    onError,
    onFormError,
    formErrors,
}: FullCapitalProps) {
    const {
        layouts: { LayoutBoundary },
    } = React.useContext(UtilsContext);
    const router = useRouter();
    const type = router.query?.type as 'full-capital' | 'divided-capital';
    const procedureId = router.query?.procedureId as string;
    const queryClient = useQueryClient();
    const { data, status, error } = useSignatureCapitalBeneficiaries(
        service,
        procedureId,
        type
    );
    const { beneficiaries, beneficiariesMaxNumber } = data || {};
    const { isMobile } = useWindowBreakpoints();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedBeneficiary, setSelectedBeneficiary] =
        React.useState<SignatureCapitalBeneficiary>();

    const mainBeneficiary = React.useMemo(
        () => beneficiaries?.find((beneficiary) => beneficiary?.rank === 1),
        [beneficiaries]
    );

    const otherBeneficiaries = React.useMemo(
        () =>
            beneficiaries
                ?.filter((beneficiary) => beneficiary.rank !== 1)
                ?.sort((a, b) =>
                    a.rank < b.rank ? -1 : a.rank === b.rank ? 0 : 1
                ),
        [beneficiaries]
    );

    React.useEffect(() => {
        if (!mainBeneficiary) return onBeneficiariesUpdate('main');

        if (
            otherBeneficiaries &&
            otherBeneficiaries?.find(
                (beneficiary) => beneficiary?.rank === 3
            ) &&
            !otherBeneficiaries?.find((beneficiary) => beneficiary?.rank === 2)
        ) {
            return onBeneficiariesUpdate('rank');
        }

        onBeneficiariesUpdate(null);
    }, [mainBeneficiary, otherBeneficiaries, onBeneficiariesUpdate]);

    const otherBeneficiariesTable = React.useMemo(
        () =>
            Array.from({
                length: beneficiariesMaxNumber - 1,
            })?.map(
                (_, index) =>
                    beneficiaries?.find(
                        (beneficiary) => beneficiary.rank === index + 2
                    ) || null
            ),
        [beneficiaries, beneficiariesMaxNumber]
    );

    React.useEffect(() => {
        if (error) {
            onError(error?.code);
        }
    }, [error, onError]);

    return (
        <LayoutBoundary status={status} p="0">
            <Stack spacing="4">
                <Text>
                    <FormattedMessage
                        id="workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiary.description"
                        defaultMessage="Vous pouvez ajouter jusqu’à deux bénéficiaires à défaut. En cas de décès du bénéficiaire principal, le premier bénéficiaire à défaut percevra la totalité du capital et en cas de décès de celui-ci, c’est le second bénéficiaire à défaut qui percevra la totalité du capital."
                    />
                </Text>

                <Stack spacing="4">
                    <Box mb="-3">
                        {formErrors.length > 0 && (
                            <FormErrors errors={formErrors} />
                        )}
                    </Box>
                    <Heading as="h3" fontSize="xl">
                        <FormattedMessage
                            id="beneficiary.main"
                            defaultMessage="Bénéficiaire principal"
                        />
                    </Heading>
                    <SimpleGrid columns={isMobile ? 1 : 2} spacing="3">
                        {mainBeneficiary ? (
                            <SignatureSelectedBeneficiary
                                service={service}
                                beneficiary={mainBeneficiary}
                                id={1}
                                onError={(
                                    errorCode: number,
                                    type?: 'toast'
                                ) => {
                                    if (type === 'toast')
                                        onFormError(
                                            errorCode,
                                            undefined,
                                            'delete',
                                            'toast'
                                        );
                                    else onError(errorCode);
                                }}
                                onEdit={(beneficiary) => {
                                    setSelectedBeneficiary(beneficiary);
                                    onOpen();
                                }}
                                type="full-capital"
                            />
                        ) : (
                            <SignatureAddBeneficiary
                                onClick={onOpen}
                                id={0}
                                isEnabled
                            />
                        )}
                    </SimpleGrid>
                </Stack>
                <Stack spacing="4" pt="1">
                    <HStack>
                        <Heading as="h3" fontSize="xl">
                            <FormattedMessage
                                id="beneficiary.defaulting-beneficiaries"
                                defaultMessage="Bénéficiaires à défaut"
                            />
                        </Heading>
                        <Text as="i" fontWeight="thin" color="gray.700">
                            (
                            <FormattedMessage
                                id="components.optional"
                                defaultMessage="optionnel"
                            />
                            )
                        </Text>
                    </HStack>
                    <SimpleGrid columns={isMobile ? 1 : 2} spacing="3">
                        {otherBeneficiariesTable?.map((beneficiary, index) =>
                            beneficiary ? (
                                <Box key={uniqueId()}>
                                    <SignatureSelectedBeneficiary
                                        service={service}
                                        beneficiary={beneficiary}
                                        id={beneficiary?.rank}
                                        onError={(errorCode: number) => {
                                            if (errorCode === 403)
                                                onFormError(
                                                    errorCode,
                                                    undefined,
                                                    'delete'
                                                );
                                            else onError(errorCode);
                                        }}
                                        onEdit={(beneficiary) => {
                                            setSelectedBeneficiary(beneficiary);
                                            onOpen();
                                        }}
                                        type="full-capital"
                                    />
                                </Box>
                            ) : (
                                <SignatureAddBeneficiary
                                    key={uniqueId()}
                                    id={index + 1}
                                    onClick={onOpen}
                                    isEnabled={
                                        !!mainBeneficiary &&
                                        (index === 0 ||
                                            !!otherBeneficiaries[index - 1])
                                    }
                                />
                            )
                        )}
                    </SimpleGrid>
                </Stack>
            </Stack>
            <SignatureModalBeneficiary
                service={service}
                isOpen={isOpen}
                onClose={async () => {
                    onClose();
                    setSelectedBeneficiary(undefined);
                    await queryClient.invalidateQueries({
                        queryKey: [
                            signatureCapitalPath,
                            service,
                            procedureId,
                            type,
                        ],
                        exact: true,
                        refetchType: 'active',
                    });
                }}
                onFormError={(errorCode, step, action) => {
                    onFormError(errorCode, step, action);
                    setSelectedBeneficiary(undefined);
                    onClose();
                }}
                selectedBeneficiary={selectedBeneficiary}
                type={type}
            />
        </LayoutBoundary>
    );
}
