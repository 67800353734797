import axios from 'axios';
import { WorkflowType } from '../types';
import { APIError, FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';

const path = `/api/proxy/europ-assistance/teleconsultations/written-teleconsultation-availability`;

async function fetchWrittenTeleconsultationAvailability({
    queryKey: [urn, type],
}: FetchQueryParams) {
    if (!['text-message-exchange', 'echange-par-sms'].includes(type)) {
        return true;
    }

    try {
        const response = await axios.get(urn);
        return response.data.data?.isAvailable;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useWrittenTeleconsultationAvailability(type: WorkflowType) {
    return useQuery<boolean, APIError>({
        queryKey: [path, type],
        queryFn: fetchWrittenTeleconsultationAvailability,
    });
}
