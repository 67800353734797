import { oAuthMessages } from './common';

export const oAuthPasswordChangeMessages = {
    authorize: {
        title: {
            '400': oAuthMessages.defaultTitle,
            '401': oAuthMessages.defaultTitle,
            '404': oAuthMessages.defaultTitle,
            '500': oAuthMessages.defaultTitle,
        },
        description: {
            '400': oAuthMessages['400_BAD_REQUEST'],
            '401': oAuthMessages.defaultTitle,
            '404': oAuthMessages['404_NOT_FOUND'],
            '500': oAuthMessages['500_INTERNAL_SERVER_ERROR'],
        },
    },
    check: {
        title: {
            '400_INVALID_STATE': oAuthMessages.defaultTitle,
            '400_AZURE_ERROR': oAuthMessages.defaultTitle,
        },
        description: {
            '400_INVALID_STATE': oAuthMessages['INVALID_STATE'],
            '400_AZURE_ERROR': oAuthMessages['AZURE_ERROR'],
        },
    },
};
