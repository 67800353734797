import React from 'react';
import { Box, SimpleGrid, Stack } from '@chakra-ui/react';
import { omit } from 'lodash';
import { useInputValidation } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';

import Fieldset from '../Fieldset';
import InputBase from '../InputBase';
import Select from './Select';
import type { WidgetInputProps, WidgetProps } from './types';

type AddressProps = WidgetProps & {
    streetNumber?: WidgetInputProps;
    streetName?: WidgetInputProps;
    additionalAddressOne?: WidgetInputProps;
    additionalAddressTwo?: WidgetInputProps;
    postalCode?: WidgetInputProps;
    city?: WidgetInputProps;
    country?: WidgetInputProps & {
        options: {
            id: string;
            isTranslatable: boolean;
            label: string;
        }[];
    };
};

type StreetInputsProps = Pick<
    AddressProps,
    'streetName' | 'streetNumber' | 'id'
>;

type AdditionalAddressInputsProps = Pick<
    AddressProps,
    'additionalAddressOne' | 'additionalAddressTwo' | 'id'
>;

type LocationInputsProps = Pick<
    AddressProps,
    'postalCode' | 'city' | 'country' | 'id'
>;

const DEFAULT_VALIDATION_OBJECT = { required: false };

function StreetInputs({ streetName, streetNumber, id }: StreetInputsProps) {
    const { registerValues: registerValuesStreetNumber } = useInputValidation(
        streetNumber?.validations ?? DEFAULT_VALIDATION_OBJECT,
        `${id}.streetNumber`
    );
    const { registerValues: registerValuesStreetName } = useInputValidation(
        streetName?.validations ?? DEFAULT_VALIDATION_OBJECT,
        `${id}.streetName`
    );
    const { isMobile } = useWindowBreakpoints();

    return (
        <>
            {streetNumber?.show && (
                <Box
                    w={isMobile ? '100%' : '240px'}
                    mr={streetName?.show ? 4 : 0}>
                    <InputBase
                        autoComplete="address-line1"
                        id={`${id}StreetNumber`}
                        {...registerValuesStreetNumber}
                        {...omit(streetNumber, 'show')}
                    />
                </Box>
            )}
            {streetName?.show && (
                <InputBase
                    autoComplete="address-line1"
                    id={`${id}StreetName`}
                    {...registerValuesStreetName}
                    {...omit(streetName, 'show')}
                />
            )}
        </>
    );
}

function AdditionalAddressInputs({
    additionalAddressOne,
    additionalAddressTwo,
    id,
}: AdditionalAddressInputsProps) {
    const { registerValues: registerValuesAdditionalAddressOne } =
        useInputValidation(
            additionalAddressOne?.validations ?? DEFAULT_VALIDATION_OBJECT,
            `${id}.additionalAddressOne`
        );
    const { registerValues: registerValuesAdditionalAddressTwo } =
        useInputValidation(
            additionalAddressTwo?.validations ?? DEFAULT_VALIDATION_OBJECT,
            `${id}.additionalAddressTwo`
        );

    return (
        <>
            {additionalAddressOne?.show && (
                <InputBase
                    autoComplete="address-line2"
                    id={`${id}AdditionalAddressOne`}
                    {...registerValuesAdditionalAddressOne}
                    {...omit(additionalAddressOne, 'show')}
                />
            )}
            {additionalAddressTwo?.show && (
                <InputBase
                    autoComplete="address-line3"
                    id={`${id}AdditionalAddressTwo`}
                    name={`${id}.additionalAddressTwo`}
                    {...registerValuesAdditionalAddressTwo}
                    {...omit(additionalAddressTwo, 'show')}
                />
            )}
        </>
    );
}

function LocationSelects({
    postalCode,
    city,
    country,
    id,
}: LocationInputsProps) {
    const { isMobile } = useWindowBreakpoints();

    const { registerValues: registerValuesPostalCode } = useInputValidation(
        postalCode?.validations ?? DEFAULT_VALIDATION_OBJECT,
        `${id}.postalCode`
    );
    const { registerValues: registerValuesCity } = useInputValidation(
        city?.validations ?? DEFAULT_VALIDATION_OBJECT,
        `${id}.city`
    );

    return (
        <SimpleGrid
            columns={isMobile ? 1 : 2}
            spacing={4}
            alignItems="flex-start">
            {postalCode?.show && (
                <InputBase
                    autoComplete="postal-code"
                    id={`${id}PostalCode`}
                    {...registerValuesPostalCode}
                    {...omit(postalCode, 'show')}
                />
            )}
            {city?.show && (
                <InputBase
                    autoComplete="address-level2"
                    id={`${id}City`}
                    {...registerValuesCity}
                    {...omit(city, 'show')}
                />
            )}
            {country?.show && (
                <Select
                    id={`${id}Country`}
                    name={`${id}.country`}
                    {...omit(country, 'show')}
                    validations={
                        country.validations || DEFAULT_VALIDATION_OBJECT
                    }
                    isSearchable={false}
                />
            )}
        </SimpleGrid>
    );
}

function Address({
    streetNumber,
    streetName,
    additionalAddressOne,
    additionalAddressTwo,
    postalCode,
    city,
    country,
    ...props
}: AddressProps) {
    const { id, title, description, help } = props;

    return (
        <Fieldset legend={title} {...{ description, help }}>
            <Stack spacing={4}>
                <StreetInputs {...{ streetName, streetNumber, id }} />
                <AdditionalAddressInputs
                    {...{ additionalAddressOne, additionalAddressTwo, id }}
                />
                <LocationSelects {...{ postalCode, city, country, id }} />
            </Stack>
        </Fieldset>
    );
}

export default Address;
