import { ErrorBoundary } from '@sentry/nextjs';
import { SkipNavContent, SkipNavLink } from '@reach/skip-nav';
import { FormattedMessage } from 'react-intl';
import '@reach/skip-nav/styles.css';
import { Box } from '@chakra-ui/react';
import NavBar from './NavBar';
import { HeadTitle, ErrorPage, GlobalLayoutType } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { SatisfactionApp } from 'components/SatisfactionApp';
import { useSatisfactionVisibility } from 'lib/satisfaction';
import TermsFooter from 'lib/shared/components/TermsFooter';
import { useEligibility } from 'lib/shared';

export type GlobalLayoutProps = GlobalLayoutType & {
    footerSticky?: boolean;
    disableFooter?: boolean;
    navBarBackgroundColor?: string;
};

export default function GlobalLayout({
    children,
    title,
    footerSticky = false,
    disableFooter = false,
    hasPaddingBottom = true,
    navBarBackgroundColor,
    ...boxProps
}: GlobalLayoutProps) {
    const { isMobile } = useWindowBreakpoints();
    const isVisible = useSatisfactionVisibility();
    const { data: eligibility } = useEligibility();

    return (
        <Box
            minHeight="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between">
            <Box>
                <HeadTitle title={title} />
                {/* @TODO: To refacto, see: https://gitlab.com/optisantis/front/web/-/merge_requests/5848#note_1895637019 */}
                <SkipNavLink contentId="content">
                    <FormattedMessage
                        id="components.layouts.switch-content"
                        defaultMessage="Passer au contenu"
                        description="Texte de l'action pour passer au contenu principal"
                    />
                </SkipNavLink>
                <NavBar backgroundColor={navBarBackgroundColor} />
                <ErrorBoundary fallback={ErrorPage}>
                    <SkipNavContent id="content" />
                    <Box
                        pb={hasPaddingBottom || isMobile ? '60px' : '0'}
                        {...boxProps}>
                        {children}
                    </Box>
                    {isVisible && <SatisfactionApp />}
                </ErrorBoundary>
            </Box>
            {!isMobile && !disableFooter && eligibility?.displayFooterCgu && (
                <TermsFooter
                    textColor={'black'}
                    stackProps={{
                        position: footerSticky ? 'sticky' : 'relative',
                        bottom: '0',
                        paddingY: isMobile ? '2' : '6',
                        justifyContent: isMobile ? 'space-between' : 'center',
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'row',
                        zIndex: 2,
                    }}
                />
            )}
        </Box>
    );
}
