import { Icon, IconProps } from '@chakra-ui/react';

export default function ExitIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M7 18a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h5a1 1 0 1 0 0-2H7a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h5a1 1 0 1 0 0-2H7zm10.4-9.8a1 1 0 0 0-1.6 1.2L17 11h-6a1 1 0 1 0 0 2h6l-1.2 1.6a1 1 0 0 0 1.6 1.2l2.4-3.2a1 1 0 0 0 0-1.2l-2.4-3.2z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
