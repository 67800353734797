import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams } from 'core';
import { Refunds } from './types';

const path = '/api/proxy/selfcare/health/refunds/[refundId]';

export async function fetchRefund({
    queryKey: [urn, refundId],
}: FetchQueryParams) {
    try {
        const { data: response } = await axios.get(
            urn.replace('[refundId]', refundId)
        );
        const { data }: Api<{ refund: Refunds }> = await response;
        return data?.refund;
    } catch (error) {
        throw { code: error.response?.status };
    }
}

export function useRefund(refundId: string) {
    return useQuery<Refunds, APIError>({
        queryKey: [path, refundId],
        queryFn: fetchRefund,
    });
}
