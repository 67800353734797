import { Mode } from 'react-hook-form';

type DefaultFormValidationModes = {
    mode: Mode;
    reValidateMode: Exclude<Mode, 'onTouched' | 'all'>;
};

export const defaultFormValidationModes: DefaultFormValidationModes = {
    mode: 'onTouched',
    reValidateMode: 'onChange',
};
