import { reducerError } from './reducerError';
import { MONTH_IDS, YEARS_RANGE } from '../../consts';
import type { Date } from '../context';

export type CurrentDateAction = {
    type:
        | 'NEXT_MONTH'
        | 'PREV_MONTH'
        | 'NEXT_YEAR'
        | 'PREV_YEAR'
        | 'NEXT_DECADE'
        | 'PREV_DECADE'
        | 'SET_MONTH'
        | 'SET_YEAR';
    value?: number;
};

export function currentDateReducer(
    state: Date,
    { type, value }: CurrentDateAction
): Date {
    switch (type) {
        case 'NEXT_MONTH': {
            const { year, month } = state;
            return {
                ...state,
                year: month === MONTH_IDS.DECEMBER ? year + 1 : year,
                month:
                    month === MONTH_IDS.DECEMBER
                        ? MONTH_IDS.JANUARY
                        : month + 1,
            };
        }
        case 'PREV_MONTH': {
            const { year, month } = state;
            return {
                ...state,
                year: month === MONTH_IDS.JANUARY ? year - 1 : year,
                month:
                    month === MONTH_IDS.JANUARY
                        ? MONTH_IDS.DECEMBER
                        : month - 1,
            };
        }
        case 'NEXT_YEAR': {
            return {
                ...state,
                year: state.year + 1,
            };
        }
        case 'PREV_YEAR': {
            return {
                ...state,
                year: state.year - 1,
            };
        }
        case 'NEXT_DECADE': {
            return {
                ...state,
                year: state.year + YEARS_RANGE,
            };
        }
        case 'PREV_DECADE': {
            return {
                ...state,
                year: state.year - YEARS_RANGE,
            };
        }
        case 'SET_MONTH': {
            return { ...state, month: value as number };
        }
        case 'SET_YEAR': {
            return {
                ...state,
                year: value as number,
            };
        }
        default: {
            throw reducerError(type);
        }
    }
}
