import { Icon, IconProps } from '@chakra-ui/react';

export default function PhoneIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M2 3a1 1 0 0 1 1-1h3.79c1.075 0 1.947.872 1.947 1.947v5.685a1.947 1.947 0 0 1-1.948 1.947H4.974c1.383 3.323 4.124 6.064 7.447 7.447V17.21c0-1.075.872-1.947 1.947-1.947h5.685c1.075 0 1.947.872 1.947 1.947V21a1 1 0 0 1-1 1h-3.79c-1.4 0-2.77-.21-4.074-.599C8.158 19.92 4.08 15.842 2.599 10.864A14.274 14.274 0 0 1 2 6.79V3zm12.421 16.674c.907.213 1.84.326 2.79.326H20v-2.737h-5.579v2.411zM4.325 9.58h2.412V4H4v2.79c0 .948.113 1.882.325 2.789z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
