import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { FetchQueryParams, FormObject } from 'core';

const path = `/api/proxy/selfcare/forms/satisfaction`;

export async function fetchSatisfactionForm({
    queryKey: [urn],
}: FetchQueryParams) {
    try {
        const response = await axios.options(urn);
        return response.data.data as FormObject;
    } catch (error) {
        throw new Error(error);
    }
}

export function useSatisfaction() {
    return useQuery<FormObject, Error>({
        queryKey: [path],
        queryFn: fetchSatisfactionForm,
    });
}
