import { Icon, IconProps } from '@chakra-ui/react';

export default function FullscreenIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M4 2a2 2 0 0 0-2 2v3a1 1 0 0 0 2 0V4h3a1 1 0 0 0 0-2H4zM22 4a2 2 0 0 0-2-2h-3a1 1 0 1 0 0 2h3v3a1 1 0 1 0 2 0V4zM4 22a2 2 0 0 1-2-2v-3a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2H4zM20 22a2 2 0 0 0 2-2v-3a1 1 0 1 0-2 0v3h-3a1 1 0 1 0 0 2h3z"
                fill="currentColor"
            />
        </Icon>
    );
}
