export const guaranteesUnitDefinitions = [
    {
        units: ['%BR', '%BR+%BR', '%BR/équipement'],
        content: `La BR (base de remboursement) correspond au tarif fixé par l'Assurance Maladie pour le remboursement d'un soin. 
          Pour un soin facturé 50 € avec une BR à 25 €, et un remboursement par votre complémentaire prévu à hauteur de 125% BR (sécurité sociale inclus), votre reste à charge s'élèverait à 18,75 € calculé comme suit : 50 € - (25€ x 125%).
          Pour certains soins, une participation forfaitaire fixée par l'assurance maladie restera à votre charge (1 € pour les consultations, 0,50 € par boîte de médicament...).`,
    },
    {
        units: [
            '%BR+€',
            '%BR+€/an',
            '%BR+€/verre',
            '%BR/an+€/an',
            '%BR+€/semestre',
        ],
        content: `La BR (base de remboursement) correspond au tarif fixé par l'Assurance Maladie pour le remboursement d'un soin. 
          Pour un soin facturé 50 € avec une BR à 25 €, et un remboursement par votre complémentaire prévu à hauteur de 125% BR (sécurité sociale inclus), votre reste à charge s'élèverait à 18,75 € calculé comme suit : 50 € - (25€ x 125%).
          Pour certains soins, une participation forfaitaire fixée par l'assurance maladie restera à votre charge (1 € pour les consultations, 0,50 € par boîte de médicament...).
          Lorsqu'un montant en euros s'ajoute à votre pourcentage BR, il suffit de l'additionner au montant calculé à partir du calcul décrit ci-dessus.`,
    },
    {
        units: ['%BR+%PMSS/an', '%BR+%PMSS', '%PMSS+%BR'],
        content: `La BR (base de remboursement) correspond au tarif fixé par l'Assurance Maladie pour le remboursement d'un soin. 
          Pour un soin facturé 50 € avec une BR à 25 €, et un remboursement par votre complémentaire prévu à hauteur de 125% BR (sécurité sociale inclus), votre reste à charge s'élèverait à 18,75 € calculé comme suit : 50 € - (25€ x 125%).
          Pour certains soins, une participation forfaitaire fixée par l'assurance maladie restera à votre charge (1 € pour les consultations, 0,50 € par boîte de médicament...).
          
          Le PMSS (Plafond mensuel de la Sécurité sociale), correspond à un plafond fixé par la Sécurité sociale chaque année. Pour calculer le remboursement d'une garantie exprimée en PMSS, il vous suffit de multiplier le pourcentage de votre garantie par le montant du PMSS en vigueur (exemple : si PMSS = 3666 €, alors 40% PMSS = 40% * 3666 €, soit 1371,20 €).`,
    },
    {
        units: ['%BR+RSS'],
        content: `La BR (base de remboursement) correspond au tarif fixé par l'Assurance Maladie pour le remboursement d'un soin. 
          Pour un soin facturé 50 € avec une BR à 25 €, et un remboursement par votre complémentaire prévu à hauteur de 125% BR + RSS.
          Le RSS correspond au remboursement de la Sécurité sociale, par exemple 70% BR, soit 17,50 €.
          Le remboursement total serait donc calculé comme suit : (125% x 25 €) + 17,50 € = 48,75 €, soit un reste à charge de 2,25 €.
          
          Pour certains soins, une participation forfaitaire fixée par l'assurance maladie et non remboursée par votre complémentaire santé restera à votre charge (1 € pour les consultations, 0,50 € par boîte de médicament...).`,
    },
    {
        units: ['%BR+%TM', '%TM+%BR', 'TM+%BR'],
        content: `La BR (Base de Remboursement) correspond au tarif fixé par l'Assurance Maladie pour le remboursement d'un soin. 
          Le TM (Ticket Modérateur) est la différence entre la BR (base de remboursement) et le montant remboursé par la Sécurité Sociale.`,
    },
    {
        units: ['%FR'],
        content: `L'expression FR (Frais réels) correspond au montant total facturé par le professionnel de santé. Lorsqu'il est mentionné 100% FR cela signifie que vous serez intégralement remboursé.`,
    },
    {
        units: ['%FR-RSS'],
        content: `L'expression FR (Frais réels) correspond au montant total facturé par le professionnel de santé. Lorsqu'il est mentionné 100% FR cela signifie que vous serez intégralement remboursé.
          Le RSS correspond au remboursement de la Sécurité sociale.`,
    },
    {
        units: ['%FR+%PMSS'],
        content: `L'expression "Frais réels", correspond au montant de votre soin, autrement à la somme engagée. Lorsqu'il est mentionné 100% FR cela signifie que vous serez intégralement remboursé.
          Le PMSS (Plafond mensuel de la Sécurité sociale), correspond à un plafond fixé par la Sécurité sociale chaque année. Pour calculer le remboursement d'une garantie exprimée en PMSS, il vous suffit de multiplier le pourcentage de votre garantie par le montant du PMSS en vigueur (exemple : si PMSS = 3666 €, alors 40% PMSS = 40% * 3666 €, soit 1371,20 €).`,
    },
    {
        units: ['%PASS', '%PASS/an'],
        content: `Le PASS (Plafond annuel de la Sécurité sociale), correspond à un plafond fixé chaque année par la Sécurité sociale.
          Pour calculer le remboursement d'une garantie exprimée en PASS, il vous suffit de multiplier le pourcentage de votre garantie par le montant du PASS fixé par les pouvoirs publics (exemple 3% PASS en 2022 : 3% * 41 136 € = 1234,08 €), le montant pris en charge par votre complémentaire santé dans cet exemple est donc égale à 1234,08 €.`,
    },
    {
        units: ['%PMSS', '%PMSS/an', '%PMSS/jour', '%PMSS/oeil/an'],
        content: `Le PMSS (Plafond mensuel de la Sécurité sociale), correspond à un plafond fixé par la Sécurité sociale chaque année. Pour calculer le remboursement d'une garantie exprimée en PMSS, il vous suffit de multiplier le pourcentage de votre garantie par le montant du PMSS en vigueur (exemple : si PMSS = 3666 €, alors 40% PMSS = 40% * 3666 €, soit 1371,20 €).`,
    },
    {
        units: ['%BRR'],
        content: `L'acronyme BRR signifie Base de Remboursement Reconstituée. Pour certains actes non remboursés par la Sécurité sociale, votre complémentaire santé calcule ses remboursements à partir d'une estimation de la base de remboursement qu’aurait retenue la Sécurité sociale si elle était intervenue.`,
    },
    {
        units: ['%PMSS/implant'],
        content: `Le PMSS (Plafond mensuel de la Sécurité sociale), correspond à un plafond fixé par la Sécurité sociale chaque année. Pour calculer le remboursement d'une garantie exprimée en PMSS, il vous suffit de multiplier le pourcentage de votre garantie par le montant du PMSS en vigueur (exemple : si PMSS = 3666 €, alors 40% PMSS = 40% * 3666 €, soit 1371,20 €). La mention /implant, signifie que vous bénéficiez du montant remboursé par implant, donc si vous avez deux implants vous serez remboursé 1371,20 € *2, soit 2742,40 €.`,
    },
    {
        units: ['%PMSS/séance'],
        content: `Le PMSS (Plafond mensuel de la Sécurité sociale), correspond à un plafond fixé par la Sécurité sociale chaque année. Pour calculer le remboursement d'une garantie exprimée en PMSS, il vous suffit de multiplier le pourcentage de votre garantie par le montant du PMSS en vigueur (exemple : si PMSS = 3666 €, alors 40% PMSS = 40% * 3666 €, soit 1371,20 €). La mention /séance, signifie que vous bénéficiez du montant remboursé par séance réalisée, donc si vous avez fait deux séances vous serez remboursé 1371,20 € *2, soit 2742,40 €.`,
    },
    {
        units: ['%PMSS/semestre'],
        content: `Le PMSS (Plafond mensuel de la Sécurité sociale), correspond à un plafond fixé par la Sécurité sociale chaque année. Pour calculer le remboursement d'une garantie exprimée en PMSS, il vous suffit de multiplier le pourcentage de votre garantie par le montant du PMSS en vigueur (exemple : si PMSS = 3666 €, alors 40% PMSS = 40% * 3666 €, soit 1371,20 €). La mention /semestre, signifie que vous bénéficier du montant remboursé une fois par semestre.`,
    },
    {
        units: ['%RSS'],
        content: `Le RSS correspond au remboursement de la Sécurité sociale. Pour connaitre le montant de votre remboursement, vous devez vous référer au montant pris en charge par votre régime de Sécurité sociale, auquel va s'ajouter le pourcentage de ce montant pris en charge par votre complémentaire santé.`,
    },
    {
        units: ['%BR-RSS', '%BR-%RSS'],
        content: `La BR (Base de Remboursement) correspond au tarif fixé par l'Assurance Maladie pour le remboursement d'un soin. 
          Le RSS correspond au remboursement de la Sécurité sociale.`,
    },
    {
        units: ['%RSS+%BR'],
        content: `La BR (base de remboursement) correspond au tarif fixé par l'Assurance Maladie pour le remboursement d'un soin. 
          Le RSS correspond au remboursement de la Sécurité sociale.`,
    },
    {
        units: ['%RSS+€/an'],
        content: `Le RSS correspond au remboursement de la Sécurité sociale. Pour connaitre le montant de votre remboursement, vous devez vous référer au montant pris en charge par votre régime de Sécurité sociale, auquel va s'ajouter le pourcentage de ce montant pris en charge par votre complémentaire santé.
          Lorsqu'un montant en euros s'ajoute à votre pourcentage RSS, il suffit de l'additionner au montant calculé à partir du calcul décrit ci-dessus.`,
    },
    {
        units: ['%RSS+%PMSS/an'],
        content: `Le RSS correspond au remboursement de la Sécurité sociale. Pour connaitre le montant de votre remboursement, vous devez vous référer au montant pris en charge par votre régime de Sécurité sociale, auquel va s'ajouter le pourcentage de ce montant pris en charge par votre complémentaire santé.
          Le PMSS (Plafond mensuel de la Sécurité sociale), correspond à un plafond fixé par la Sécurité sociale chaque année. Pour calculer le remboursement d'une garantie exprimée en PMSS, il vous suffit de multiplier le pourcentage de votre garantie par le montant du PMSS en vigueur (exemple : si PMSS = 3666 €, alors 40% PMSS = 40% * 3666 €, soit 1371,20 €).`,
    },
    {
        units: ['%TM', '%TM+€', '%TM+€/an'],
        content: `Le TM (Ticket Modérateur) est la différence entre la BR (Base de Remboursement) et le montant remboursé par la Sécurité Sociale.`,
    },
    {
        units: [
            'RSS/verre+€/verre',
            'RSS+€/monture',
            'RSS+€/an',
            'RSS+€/équipement',
            'RSS+€/verre',
            'RSS+€',
        ],
        content: `Le RSS correspond au remboursement de la Sécurité sociale. Pour connaitre le montant de votre remboursement, vous devez vous référer au montant pris en charge par votre régime de Sécurité sociale, auquel va s'ajouter le montant en euros pris en charge par votre complémentaire santé.`,
    },
    {
        units: ['RSS+%BR'],
        content: `Le RSS correspond au remboursement de la Sécurité sociale.
          La BR (base de remboursement) correspond au tarif fixé par l'Assurance Maladie pour le remboursement d'un soin.`,
    },
    {
        units: ['RSS'],
        content: `Le RSS correspond au remboursement de la Sécurité sociale.`,
    },
    {
        units: ['RSS+%PMSS'],
        content: `Le RSS correspond au remboursement de la Sécurité sociale.
          Le PMSS (Plafond mensuel de la Sécurité sociale), correspond à un plafond fixé par la Sécurité sociale chaque année. Pour calculer le remboursement d'une garantie exprimée en PMSS, il vous suffit de multiplier le pourcentage de votre garantie par le montant du PMSS en vigueur (exemple : si PMSS = 3666 €, alors 40% PMSS = 40% * 3666 €, soit 1371,20 €).`,
    },
];
