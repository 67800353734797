import { Box } from '@chakra-ui/react';

export function ChallengeErrorDefaultIllustration() {
    return (
        <Box
            as="svg"
            width="133"
            height="122"
            viewBox="0 0 133 122"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
                '> .bg': {
                    fill: 'grey.100',
                },
                '> .cross-error': {
                    fill: 'error.main',
                },
                '> :not(.bg, .cross-error)': {
                    fill: 'white',
                    filter: 'drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1))',
                },
            }}>
            <path
                className="bg"
                d="M121.546 61C121.546 94.6894 94.3373 122 60.7732 122C27.2091 122 0 94.6894 0 61C0 27.3106 27.2091 0 60.7732 0C94.3373 0 121.546 27.3106 121.546 61Z"
            />
            <path d="M16.2061 27.2289C16.2061 25.6665 17.4625 24.3999 19.0125 24.3999H109.286C110.836 24.3999 112.093 25.6665 112.093 27.2289V43.2598C112.093 44.8222 110.836 46.0888 109.286 46.0888H19.0125C17.4625 46.0888 16.2061 44.8222 16.2061 43.2598V27.2289Z" />
            <path d="M16.2061 55.6957C16.2061 54.1333 17.4625 52.8667 19.0125 52.8667H109.286C110.836 52.8667 112.093 54.1333 112.093 55.6957V71.7266C112.093 73.289 110.836 74.5556 109.286 74.5556H19.0125C17.4625 74.5556 16.2061 73.289 16.2061 71.7266V55.6957Z" />
            <path d="M35.1133 82.8068C35.1133 81.2444 36.3576 79.9778 37.8926 79.9778H128.221C129.756 79.9778 131 81.2444 131 82.8068V98.8377C131 100.4 129.756 101.667 128.221 101.667H37.8926C36.3576 101.667 35.1133 100.4 35.1133 98.8377V82.8068Z" />
            <path
                className="cross-error"
                d="M41.8896 33.8889L36.4639 39.3349L57.3732 60.3223L36.4639 81.3096L41.8896 86.7556L62.7989 65.7682L83.7082 86.7556L89.134 81.3096L68.2247 60.3223L89.134 39.3349L83.7082 33.8889L62.7989 54.8763L41.8896 33.8889Z"
            />
        </Box>
    );
}

export function ChallengeErrorTooManyTriesIllustration() {
    return (
        <Box
            as="svg"
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            sx={{
                circle: {
                    fill: 'grey.50',
                },
                '#lock': {
                    fill: 'secondary.main',
                },
            }}>
            <circle cx="45" cy="45" r="45" />
            <path
                opacity=".1"
                d="M58.45 37.896h-2.686v-5.218c0-5.888-4.694-10.782-10.582-10.871A10.76 10.76 0 0 0 34.31 32.533v5.363h-2.68a4.484 4.484 0 0 0-4.47 4.47v21.453a4.484 4.484 0 0 0 4.47 4.47H58.45a4.482 4.482 0 0 0 4.469-4.47V42.366a4.483 4.483 0 0 0-4.469-4.47ZM46.606 51.842v7.684a1.604 1.604 0 0 1-1.487 1.61 1.57 1.57 0 0 1-1.642-1.567v-7.731a3.568 3.568 0 1 1 3.129 0v.004Zm6.034-13.946H37.437v-5.363a7.598 7.598 0 1 1 15.197 0l.006 5.363Z"
                fill="white"
            />
            <path
                id="lock"
                d="M58.45 37.5h-2.686v-5.218c0-5.888-4.694-10.782-10.582-10.872A10.758 10.758 0 0 0 34.31 32.137v5.362h-2.68a4.484 4.484 0 0 0-4.47 4.47v21.454a4.484 4.484 0 0 0 4.47 4.47H58.45a4.482 4.482 0 0 0 4.469-4.47V41.969a4.483 4.483 0 0 0-4.469-4.47ZM46.606 51.444v7.684a1.604 1.604 0 0 1-1.487 1.61 1.57 1.57 0 0 1-1.642-1.566V51.44a3.568 3.568 0 1 1 3.129 0v.004ZM52.64 37.5H37.437v-5.362a7.598 7.598 0 1 1 15.197 0l.006 5.362Z"
            />
        </Box>
    );
}
