import { Icon, IconProps } from '@chakra-ui/react';

export default function MessageIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M2 7a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7zm16.35 0H5.612l6.309 5.662L18.35 7zM4 8.241V17h16V8.213l-6.758 5.95a2 2 0 0 1-2.658-.013L4 8.241z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
