import { formMessages as adherentFormMessages } from './adherent';
import { formMessages as entrepriseFormMessages } from './enterprise-admin';
import { formMessages as selfcareFormMessages } from './selfcare-rh';
import { formMessages as exemptionsFormMessages } from './enterprise-exemptions';
import { formMessages as testFormMessages } from './test';

export const formMessages = {
    ...adherentFormMessages,
    ...entrepriseFormMessages,
    ...selfcareFormMessages,
    ...exemptionsFormMessages,
    ...testFormMessages,
};
