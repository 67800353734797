export const CONTAINER_WIDTH = 'md';

export const CONTAINER_WIDTHS = {
    PRIMARY: 'lg',
    SECONDARY: 'md',
};

export const customizedScroll = {
    '::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
        borderRadius: 'lg',
    },
    '::-webkit-scrollbar-track': {
        width: '6px',
        height: '6px',
        background: 'grey.400',
        borderRadius: 'lg',
    },
    '::-webkit-scrollbar-thumb': {
        background: 'primary.main',
        borderRadius: 'lg',
    },
};

export enum KeyBoardEventsEnum {
    ARROWDOWN = 'ArrowDown',
    ARROWUP = 'ArrowUp',
    ENTER = 'Enter',
}
