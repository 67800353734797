import { Icon, IconProps } from '@chakra-ui/react';

export default function TeddyBearIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M9.106 12.162a.7.7 0 1 0-.7-1.212.7.7 0 0 0 .7 1.212zM16.363 11.206a.7.7 0 1 1-1.213.7.7.7 0 0 1 1.213-.7zM12.395 14.59c.414-.247 1.005-.688 1.005-1.19 0-.71-.53-.706-1.215-.7h-.37c-.686-.006-1.215-.01-1.215.7 0 .502.591.943 1.005 1.19a.767.767 0 0 0 .79 0z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M19 12v-.048A3.5 3.5 0 1 0 15.83 6.14 6.967 6.967 0 0 0 12 5c-1.414 0-2.73.42-3.831 1.14A3.5 3.5 0 1 0 5 11.95l-.001.05a7 7 0 1 0 14 0zM6.168 10.12a1 1 0 0 1 .835.99L7 12.002a5 5 0 0 0 10 0V12l-.003-.89a1 1 0 0 1 .835-.99l.837-.14a1.501 1.501 0 1 0-1.36-2.49l-.575.628a1 1 0 0 1-1.286.162l-.713-.466A4.967 4.967 0 0 0 12 7c-1.013 0-1.95.3-2.735.814l-.713.466a1 1 0 0 1-1.286-.162l-.574-.629a1.5 1.5 0 1 0-1.36 2.49l.836.14z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
