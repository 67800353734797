import { Icon, IconProps } from '@chakra-ui/react';

export default function UmbrellaIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M13 3a1 1 0 1 0-2 0v.044c-4.791.429-8.588 3.9-8.969 8.237a.887.887 0 0 0 .658.926A36.025 36.025 0 0 0 11 13.484V19a3 3 0 1 0 6 0v-1.6a1 1 0 1 0-2 0V19a1 1 0 1 1-2 0v-5.516a36.025 36.025 0 0 0 8.311-1.277.887.887 0 0 0 .657-.926c-.38-4.337-4.177-7.808-8.968-8.237V3zm-1 8.5c2.588 0 4.947-.352 6.665-.708.429-.09.816-.178 1.154-.26C19.06 7.484 15.995 5 12 5s-7.06 2.485-7.819 5.531c.338.083.725.172 1.154.26 1.718.357 4.077.709 6.665.709z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
