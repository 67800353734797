import { HStack, Skeleton } from '@chakra-ui/react';
import { useWindowBreakpoints } from 'design-system/hooks';

const NavbarSkeleton = () => {
    const { isMobile } = useWindowBreakpoints();

    if (isMobile) {
        return (
            <HStack py={3} w={'full'} justifyContent="space-around">
                {Array.from({ length: 5 }).map((_, i) => (
                    <Skeleton
                        key={i}
                        rounded="lg"
                        height={7}
                        w={10}
                        borderRadius="md"
                    />
                ))}
            </HStack>
        );
    }

    return (
        <HStack w="full" py={3} justifyContent="space-between">
            <Skeleton rounded="lg" height={8} w={24} />

            <HStack>
                {Array.from({ length: 4 }).map((skeletonTile, i) => (
                    <Skeleton
                        key={i}
                        rounded="lg"
                        height={8}
                        w={20}
                        borderRadius="md"
                    />
                ))}
            </HStack>

            <Skeleton rounded="lg" height={12} w={24} />
        </HStack>
    );
};

export default NavbarSkeleton;
