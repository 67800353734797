import { useEffect, useState, Ref } from 'react';
import { Input, InputGroup, InputRightElement, Button } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useController } from 'react-hook-form';
import { defineMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import {
    setRef,
    DatePicker,
    BottomFullWidthPopover,
    BottomFullWidthPopoverContent,
    BottomFullWidthPopoverTrigger,
    useBottomFullWidthPopoverContext,
    useSafeIntl,
    formMessages,
    Validations,
    FormGroup,
    useInputValidation,
} from 'core';
import { CalendarIcon } from 'design-system/icons';
import type { WidgetProps } from './types';
import { ClickOutsideMode } from 'core/hooks/useClickOutside';
import { omit } from 'lodash';

type InputDatePickerProps = {
    dateMin: Validations['dateMin'];
    dateMax: Validations['dateMax'];
    openDays: Validations['openDays'];
    onChange: (value: string) => void;
    useClickOutsideMode?: ClickOutsideMode;
    defaultDate: string;
};

type ControlledInputProps = Omit<
    WidgetProps,
    'id' | 'help' | 'description' | 'title'
> & {
    name: string;
};

type InputDateProps = WidgetProps & {
    isInvalid?: boolean;
    useClickOutsideMode?: ClickOutsideMode;
};

const buttonAriaLabel = defineMessage({
    id: 'components.forms.widgets.get-to-date-selector',
    defaultMessage: 'Accéder au sélecteur de date',
    description:
        "Message pour les screen-readers du bouton de l'input date qui affiche le Date Picker",
});

const calendarAriaLabel = defineMessage({
    id: 'components.forms.widgets.calendar-icon',
    defaultMessage: "Icone d'un calendrier",
    description:
        "Message pour les screen-readers de l'icone dans le bouton du datepicker",
});

function InputDateRightElements() {
    const { formatMessage } = useIntl();
    return (
        <BottomFullWidthPopoverTrigger>
            <InputRightElement h="full">
                <Button
                    variant="inputRight"
                    aria-label={formatMessage(buttonAriaLabel)}>
                    <CalendarIcon
                        w={5}
                        h={5}
                        aria-label={formatMessage(calendarAriaLabel)}
                    />
                </Button>
            </InputRightElement>
        </BottomFullWidthPopoverTrigger>
    );
}

function InputDatePicker({
    dateMin,
    dateMax,
    openDays,
    onChange,
    useClickOutsideMode,
    defaultDate,
}: InputDatePickerProps) {
    const { onClose } = useBottomFullWidthPopoverContext();
    const [hasBeenClicked, setHasBeenClicked] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(onClose, 100);
        return () => clearTimeout(timeoutId);
    }, [hasBeenClicked, onClose]);

    return (
        <BottomFullWidthPopoverContent
            useClickOutsideMode={useClickOutsideMode}>
            <DatePicker
                defaultDate={defaultDate}
                onChange={onChange}
                onClickDay={() => setHasBeenClicked(!hasBeenClicked)}
                options={{
                    format: 'DD/MM/YYYY',
                    dateRange: {
                        min: dateMin ?? '',
                        max: dateMax ?? '',
                    },
                    openDays,
                }}
            />
        </BottomFullWidthPopoverContent>
    );
}

function ControlledInputDate({
    name,
    validations,
    useClickOutsideMode,
    placeholder,
    defaultValue: propsDefaultValue,
    ...props
}: ControlledInputProps) {
    const { safeFormatMessage } = useSafeIntl();
    const { rules, control, setValue } = useInputValidation(validations, name, {
        type: 'date',
    });
    const defaultValue = propsDefaultValue
        ? dayjs(propsDefaultValue, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : '';
    const { field } = useController({ name, control, rules, defaultValue });
    const { dateMin, dateMax, openDays } = validations;
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (defaultValue && isMounted) setValue(name, defaultValue);
    }, [defaultValue, name, setValue, isMounted]);

    return (
        <BottomFullWidthPopover>
            <InputGroup>
                <Input
                    {...props}
                    as={NumberFormat}
                    type="text"
                    inputMode="numeric"
                    format="##/##/####"
                    placeholder={
                        placeholder &&
                        safeFormatMessage(
                            formMessages[placeholder],
                            null,
                            placeholder
                        )
                    }
                    mask="_"
                    getInputRef={(inputRef: Ref<HTMLInputElement>) =>
                        setRef(field.ref, inputRef)
                    }
                    {...omit(field, 'ref')}
                    // Value shouldn't be `undefined` of React will consider the component passing from
                    // controlled to uncontrolled
                    value={field.value ?? ''}
                />
                <InputDateRightElements />
            </InputGroup>

            <InputDatePicker
                dateMin={dateMin}
                dateMax={dateMax}
                openDays={openDays}
                useClickOutsideMode={useClickOutsideMode}
                onChange={(value: string) => {
                    setValue(name, value, { shouldValidate: true });
                }}
                defaultDate={field.value}
            />
        </BottomFullWidthPopover>
    );
}

function InputDate({
    validations,
    id,
    title,
    description,
    readonly,
    name,
    isInvalid,
    hideLabel,
    help,
    useClickOutsideMode = 'all',
    ...props
}: InputDateProps) {
    const inputName = name ?? `${id}.value`;

    return (
        <FormGroup
            label={title}
            name={inputName}
            isRequired={validations.required}
            isReadOnly={readonly}
            {...{ id, description, isInvalid, hideLabel, help }}>
            <ControlledInputDate
                {...props}
                useClickOutsideMode={useClickOutsideMode}
                name={inputName}
                validations={validations}
            />
        </FormGroup>
    );
}

export default InputDate;
