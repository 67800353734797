import { defineMessages } from 'react-intl';
import { mfaErrorMessages } from '.';

export const registrationChallengeErrorMessages = {
    ...{
        REGISTRATION_CHALLENGE_INVALID_STRATEGY:
            mfaErrorMessages.MFA_INVALID_STRATEGY,
        REGISTRATION_CHALLENGE_UNKNOWN_ERROR:
            mfaErrorMessages.MFA_INVALID_STRATEGY,

        REGISTRATION_CHALLENGE_BAD_CODE: mfaErrorMessages.MFA_BAD_CODE,
    },
    ...defineMessages({
        REGISTRATION_CHALLENGE_MISSING_CONTACT: {
            id: 'api.auth.registration-challenge.errors.missing-contact',
            defaultMessage:
                'Aucun canal d’envoi trouvé. Pour plus d’informations, nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant : <b>Code 120</b>',
        },
        REGISTRATION_CHALLENGE_TOO_MANY_TRIES: {
            id: 'api.auth.registration-challenge.errors.too-many-tries',
            defaultMessage:
                "Votre demande n'a pas pu aboutir en raison d’un trop grand nombre de tentatives erronées. Veuillez réessayer dans 1 heure.",
        },
        REGISTRATION_CHALLENGE_TOO_MANY_REQUESTS: {
            id: 'api.auth.registration-challenge.errors.too-many-requests',
            defaultMessage:
                "Votre demande n'a pas pu aboutir en raison d’un trop grand nombre de codes que nous vous avons déjà envoyés. Pour plus d'informations, nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant : <b>Code 123</b>",
        },
        REGISTRATION_CHALLENGE_NO_PENDING_VALIDATION: {
            id: 'api.auth.registration-challenge.errors.no-pending-validation',
            defaultMessage:
                'Votre demande n’a pas pu aboutir. Merci de bien vouloir réessayer plus tard. Pour plus d’informations, nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant : <b>Code 124</b>',
        },
        REGISTRATION_CHALLENGE_MISSING_USER: {
            id: 'api.auth.registration-challenge.errors.missing-user',
            defaultMessage:
                'Votre demande n’a pas pu aboutir. Pour plus d’informations, nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant : <b>Code 121</b>',
        },
        REGISTRATION_CHALLENGE_RECIPIENT_NOT_FOUND: {
            id: 'api.auth.registration-challenge.errors.recipient-not-found',
            defaultMessage:
                'Votre demande n’a pas pu aboutir. Pour plus d’informations, nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant : <b>Code 122</b>',
        },
    }),
};
