import React from 'react';
import { MessageDescriptor } from 'react-intl';
import {
    ViolationMessage,
    useRedirect,
    handleSubmitResponseErrorMessages,
} from 'core';
import { interceptorMessages } from 'lib/shared';
import { handleNextStep } from '../helpers';

type ErrorMessage = {
    toastError?: MessageDescriptor;
    toastTitleError?: MessageDescriptor;
    message?: MessageDescriptor;
};

function useHandleSubmitResponse() {
    const { redirect } = useRedirect();
    const [shouldShowToast, setShouldShowToast] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<ErrorMessage | null>(
        null
    );

    React.useEffect(() => {
        resetSubmitResponseStates();
    }, []);

    React.useEffect(() => {
        setShouldShowToast(!!errorMessage?.toastError);
    }, [errorMessage]);

    /**
     * Reset les states lors du submit d'un formulaire, pour éviter
     * d'avoir des toasts qui réapparaissent à l'infini avec les données
     * d'un state plus à jour.
     *
     * NOTE: Ceci est fix qui devrait être refactor, car le problème viens plus
     * de la mise en place des states de gestion d'erreur et/ou d'affichage des
     * toasts d'un formulaire.
     */
    function resetSubmitResponseStates() {
        setShouldShowToast(false);
        setErrorMessage(null);
    }

    function handleSubmitResponse(
        result: { [key: string]: any },
        referer?: 'sessionCanExpire' | 'linkCanExpire'
    ): boolean | ViolationMessage {
        if (!result) return false;

        switch (result.status) {
            case 201: {
                if (result?.data?.meta?.next_step?.iri) {
                    redirect({
                        pathname: '/register/forms',
                        query: { iri: result.data.meta.next_step.iri },
                    });
                    return true;
                }

                return false;
            }
            case 200: {
                if (result?.data?.data?.registration_token) {
                    try {
                        handleNextStep(
                            result.data.meta.next_step,
                            redirect,
                            result.data.data
                        );
                    } catch (err) {
                        redirect({
                            pathname: `/register/challenge`,
                        });
                        return true;
                    }
                }

                if (result?.data?.data?.redirectUrl) {
                    const descriptionKey =
                        result.data.data?.redirectTranslationKey;

                    redirect(
                        descriptionKey
                            ? {
                                  pathname: '/redirect-portal',
                                  query: {
                                      redirectUrl: result.data.data.redirectUrl,
                                      descriptionKey,
                                  },
                              }
                            : {
                                  pathname: '/redirect-portal',
                                  query: {
                                      redirectUrl: result.data.data.redirectUrl,
                                  },
                              }
                    );

                    return true;
                }

                if (result?.data?.data?.receiveChannel) {
                    redirect({
                        pathname: '/register/success/[status]',
                        query: { status: result.data.data.receiveChannel },
                    });
                    return true;
                }

                if (result?.data?.meta?.next_step?.id) {
                    redirect({
                        pathname: '/register/forms',
                        query: { iri: result.data.meta.next_step.id },
                    });
                    return true;
                }

                if (result?.data?.data?.chosenLogin) {
                    redirect({
                        pathname: '/register/account-validated/[chosenLogin]',
                        query: { chosenLogin: result.data.data.chosenLogin },
                    });
                    return true;
                }

                if (result?.data?.data?.access_token) {
                    const { iri, workflow } = result.data.meta.next_step;

                    if (workflow === 'user_registration') {
                        redirect({
                            pathname: '/register/forms',
                            query: { iri },
                        });
                        return true;
                    }
                }

                return false;
            }
            case 403: {
                if (
                    [
                        'unauthorized',
                        'unauthorizedEnvironment',
                        'deregistered',
                        'unauthorizedEnvironmentUserCaaCaa',
                    ].includes(result?.data?.errors[0]?.message)
                ) {
                    redirect({
                        pathname: '/register/responses/[status]',
                        query: { status: result?.data?.errors[0]?.message },
                    });
                    return true;
                }

                return false;
            }
            case 400: {
                if (
                    ['invalidParameters', 'memberCreationFailed'].includes(
                        result?.data?.errors?.[0]?.message
                    )
                ) {
                    redirect({
                        pathname: '/register/responses/[status]',
                        query: { status: result?.data?.errors?.[0]?.message },
                    });
                    return true;
                }

                return false;
            }
            case 401: {
                redirect({
                    pathname: '/register/responses/[status]',
                    query: {
                        status:
                            referer === 'sessionCanExpire'
                                ? 'sessionExpired'
                                : 'linkExpired',
                        ...(result.data?.meta?.next_step?.id && {
                            formId: result.data.meta.next_step.id,
                        }),
                    },
                });
                return true;
            }
            case 404: {
                if (
                    result?.data?.errors[0]?.message === 'providerEmailUnknown'
                ) {
                    redirect({
                        pathname: '/register/responses/[status]',
                        query: { status: result.data.errors[0].message },
                    });
                    return true;
                }

                if (result?.data?.errors[0]?.message === 'deregistered') {
                    redirect({
                        pathname: '/register/responses/[status]',
                        query: { status: 'deregistered' },
                    });
                    return true;
                }

                return {
                    code: 404,
                    ...handleSubmitResponseErrorMessages[404],
                };
            }
            case 415: {
                const hasMembershipNumber =
                    result?.data?.errors[0]?.message !== 'noMembershipNumber';

                redirect({
                    pathname: '/register/responses/[status]',
                    query: {
                        status: hasMembershipNumber
                            ? result.data.errors[0].message
                            : 'badChannel',
                    },
                });
                return true;
            }
            case 409: {
                if (result?.data?.errors[0]?.message === 'alreadySignedUp') {
                    redirect({
                        pathname: '/register/responses/[status]',
                        query: { status: 'alreadySignedUp' },
                    });
                    return true;
                }

                if (result.data?.errors[0]?.message === 'known') {
                    redirect({
                        pathname: '/register/known',
                        query: { iri: result.data?.meta?.next_step?.iri },
                    });
                    return true;
                }

                return false;
            }
            case 429: {
                return {
                    code: 429,
                    ...handleSubmitResponseErrorMessages[429],
                };
            }
            case 500: {
                setErrorMessage(
                    referer === 'sessionCanExpire'
                        ? { toastError: interceptorMessages.default }
                        : handleSubmitResponseErrorMessages[500]
                );
                return true;
            }
            default: {
                return false;
            }
        }
    }

    return {
        handleSubmitResponse,
        resetSubmitResponseStates,
        shouldShowToast,
        errorMessage,
    };
}

export { useHandleSubmitResponse };
