import { BoxProps, Icon, IconProps, useTheme } from '@chakra-ui/react';
import { Illustration } from '.';

type Props = React.PropsWithChildren<BoxProps> & {
    illustrationHeight?: string;
    illustrationWidth?: string;
};

function ErrorIcon(props: IconProps) {
    const { colors } = useTheme();

    return (
        <Icon viewBox="0 0 187 117" fill="none" {...props}>
            <path
                d="M48.8725 71.3047C68.5205 71.3047 84.4483 55.3426 84.4483 35.6524C84.4483 15.9621 68.5205 0 48.8725 0C29.2245 0 13.2967 15.9621 13.2967 35.6524C13.2967 55.3426 29.2245 71.3047 48.8725 71.3047Z"
                fill={colors.bg.light}
            />
            <path
                d="M51.8788 111.916C67.5602 111.916 80.2726 110.183 80.2726 108.045C80.2726 105.907 67.5602 104.174 51.8788 104.174C36.1973 104.174 23.4849 105.907 23.4849 108.045C23.4849 110.183 36.1973 111.916 51.8788 111.916Z"
                fill={colors.bg.light}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M105.564 103.413C95.981 106.431 84.7912 107.741 77.018 105.75L77.5142 103.812C84.7729 105.671 95.5467 104.471 104.963 101.505C109.654 100.028 113.945 98.1308 117.241 96.0081C120.573 93.8617 122.727 91.586 123.413 89.4154L125.32 90.018C124.419 92.8689 121.772 95.4682 118.324 97.6895C114.839 99.9344 110.372 101.898 105.564 103.413Z"
                fill={colors.tintedgray[600]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.9786 44.3157C51.9788 44.3156 51.9791 44.3155 51.7117 43.352C51.4443 42.3884 51.4448 42.3882 51.4454 42.3881L51.4469 42.3877L51.4515 42.3864L51.4665 42.3823L51.5198 42.3681C51.5655 42.3561 51.6313 42.3392 51.7159 42.3182C51.8851 42.2764 52.1296 42.2187 52.4393 42.1528C53.0581 42.0212 53.9391 41.8566 54.9991 41.7218C57.1115 41.4532 59.9715 41.298 62.8976 41.7794C65.8242 42.2609 68.8781 43.3902 71.2934 45.7426C73.7234 48.1092 75.4107 51.62 75.7585 56.6739C76.4116 66.1651 72.313 72.4801 69.5446 76.7455C69.3729 77.0099 69.2064 77.2664 69.0465 77.5154C68.3546 78.5922 67.8157 79.479 67.4845 80.2468C67.1515 81.0187 67.0916 81.5306 67.1826 81.902C67.2658 82.2413 67.5246 82.6588 68.3022 83.1319C69.0937 83.6135 70.3265 84.0914 72.1695 84.5635L71.6734 86.5009C69.7584 86.0105 68.3032 85.4737 67.2626 84.8405C66.2082 84.1989 65.491 83.4015 65.2401 82.3782C64.9971 81.387 65.2452 80.3885 65.6481 79.4546C66.0528 78.5165 66.6778 77.502 67.3639 76.4342C67.52 76.1912 67.6809 75.9429 67.8455 75.6891C70.6011 71.4369 74.3695 65.6218 73.7632 56.8112C73.4429 52.1568 71.915 49.1397 69.898 47.1754C67.8664 45.1967 65.2415 44.1919 62.5729 43.7528C59.9036 43.3137 57.252 43.4514 55.2514 43.7058C54.2549 43.8325 53.4292 43.987 52.8554 44.1091C52.5687 44.1701 52.3454 44.2228 52.1959 44.2598C52.1211 44.2783 52.0648 44.2928 52.0282 44.3024L51.9884 44.313L51.9799 44.3153L51.9786 44.3157Z"
                fill={colors.tintedgray[600]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.0006 90.8953C31.001 90.8952 31.0013 90.8951 30.7339 89.9315C30.4665 88.9679 30.4669 88.9678 30.4674 88.9677L30.4684 88.9674L30.4709 88.9667L30.4777 88.9648L30.4985 88.9593C30.5154 88.9549 30.5385 88.9489 30.5673 88.9418C30.625 88.9275 30.706 88.9085 30.8071 88.887C31.0088 88.844 31.2929 88.791 31.6337 88.7477C32.3077 88.662 33.2402 88.6092 34.2095 88.7687C35.1793 88.9283 36.2472 89.3112 37.1073 90.1489C37.9821 91.0009 38.545 92.2288 38.6591 93.8875C38.8719 96.9787 37.5241 99.0516 36.6866 100.34C36.6329 100.422 36.5814 100.501 36.5325 100.578C36.3225 100.904 36.1906 101.126 36.1168 101.298C36.1154 101.301 36.114 101.304 36.1127 101.307C36.2708 101.397 36.5592 101.513 37.0562 101.64L36.56 103.578C35.9593 103.424 35.4442 103.242 35.0443 102.999C34.6305 102.747 34.2547 102.374 34.1207 101.828C33.9945 101.313 34.1348 100.843 34.2804 100.505C34.4277 100.164 34.6457 99.8143 34.8498 99.4965C34.8948 99.4265 34.9406 99.3557 34.9871 99.2839C35.8122 98.0084 36.8297 96.4355 36.6639 94.0248C36.5772 92.7656 36.1737 92.0314 35.7119 91.5817C35.2355 91.1177 34.5966 90.8593 33.8848 90.7421C33.1723 90.6249 32.4482 90.6602 31.8861 90.7317C31.6088 90.767 31.3799 90.8099 31.2232 90.8432C31.145 90.8598 31.0854 90.8739 31.0473 90.8834C31.0283 90.8881 31.0147 90.8916 31.0069 90.8936L31.0006 90.8953ZM30.9996 90.8956C30.9991 90.8957 30.9993 90.8956 30.9996 90.8956Z"
                fill={colors.tintedgray[600]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M99.6255 35.6003H179.322C180.987 35.6003 182.336 36.9526 182.336 38.6208V92.4224C182.336 92.4462 182.336 92.4699 182.335 92.4936C182.322 93.0889 182.136 93.6417 181.826 94.104C181.717 94.2668 181.593 94.4191 181.454 94.5584C181.292 94.7199 181.114 94.8622 180.922 94.9828C180.566 95.2066 180.16 95.3578 179.724 95.416C179.591 95.4339 179.457 95.443 179.322 95.443H149.338L150.404 102.593C156.983 106.245 152.834 106.245 152.834 106.245C152.834 106.245 131.377 106.346 126.823 106.245C122.268 106.143 128.746 102.695 128.746 102.695L129.879 95.443H99.6255C99.491 95.443 99.357 95.434 99.2243 95.4161C98.7886 95.3581 98.3824 95.2067 98.0262 94.9828C97.8338 94.8622 97.6553 94.7199 97.4941 94.5584C97.3553 94.4193 97.2307 94.2673 97.1219 94.1046C96.8315 93.6715 96.6501 93.1589 96.6169 92.6063C96.6147 92.5689 96.6131 92.5313 96.6122 92.4936C96.6119 92.4794 96.6117 92.4652 96.6115 92.451L96.6114 92.4221V38.6208C96.6114 36.9526 97.9608 35.6003 99.6255 35.6003ZM141.397 91.3351C141.397 92.3994 140.536 93.2622 139.474 93.2622C138.412 93.2622 137.551 92.3994 137.551 91.3351C137.551 90.2708 138.412 89.408 139.474 89.408C140.536 89.408 141.397 90.2708 141.397 91.3351Z"
                fill={colors.tintedgray[800]}
            />
            <path
                d="M161.841 112.736V111.722L160.728 108.07H108.807L107.465 111.722L107.39 111.924V112.736H161.841Z"
                fill={colors.tintedgray[800]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M181.476 112.026C182.322 111.602 182.697 111.154 182.796 110.729C182.909 110.247 182.671 109.795 182.367 109.438C181.987 109 181.493 108.675 180.94 108.501C173.435 106.084 169.203 107.754 167.926 108.431C167.782 108.504 167.643 108.587 167.509 108.679C167.509 110.809 172.266 111.621 172.266 111.621C172.266 111.621 178.44 113.548 181.476 112.026ZM178.136 109.896C178.863 109.896 179.452 109.714 179.452 109.49C179.452 109.266 178.863 109.085 178.136 109.085C177.409 109.085 176.82 109.266 176.82 109.49C176.82 109.714 177.409 109.896 178.136 109.896Z"
                fill={colors.tintedgray[800]}
            />
            <path
                d="M179.452 38.7953H99.4958V83.6263H179.452V38.7953Z"
                fill={colors.tintedgray[600]}
            />
            <path
                d="M52.8808 26.6137H18.1401V67.1202H52.8808V26.6137Z"
                fill={colors.tintedgray[800]}
            />
            <path
                d="M56.3882 22.5965H14.6326V36.9914H56.3882V22.5965Z"
                fill={colors.tintedgray[600]}
            />
            <path
                d="M56.3882 39.6695H14.6326V54.0643H56.3882V39.6695Z"
                fill={colors.tintedgray[600]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6326 67.6266C6.93252 68.4021 1.9389 69.6485 1.9389 71.0538C1.9389 73.4056 15.9225 75.312 33.1721 75.312C50.4217 75.312 64.4053 73.4056 64.4053 71.0538C64.4053 69.9585 61.3721 68.9598 56.3883 68.2053V71.1433H14.6326V67.6266Z"
                fill={colors.tintedgray[700]}
            />
            <path
                d="M56.3883 56.7484H14.6326V67.6266V71.1433H56.3883V68.2053V56.7484Z"
                fill={colors.tintedgray[600]}
            />
            <path
                d="M48.0373 25.1073H45.3649V27.7854H48.0373V25.1073Z"
                fill={colors.primary[300]}
            />
            <path
                d="M51.8787 25.1073H49.2064V27.7854H51.8787V25.1073Z"
                fill={colors.primary[300]}
            />
            <path
                d="M55.7203 25.1073H53.0479V27.7854H55.7203V25.1073Z"
                fill={colors.primary[300]}
            />
            <path
                d="M48.0373 42.0127H45.3649V44.6908H48.0373V42.0127Z"
                fill={colors.primary[300]}
            />
            <path
                d="M51.8787 42.0127H49.2064V44.6908H51.8787V42.0127Z"
                fill={colors.primary[300]}
            />
            <path
                d="M55.7203 42.0127H53.0479V44.6908H55.7203V42.0127Z"
                fill={colors.primary[300]}
            />
            <path
                d="M48.0373 58.9186H45.3649V61.5967H48.0373V58.9186Z"
                fill={colors.primary[300]}
            />
            <path
                d="M51.8787 58.9186H49.2064V61.5967H51.8787V58.9186Z"
                fill={colors.primary[300]}
            />
            <path
                d="M55.7203 58.9186H53.0479V61.5967H55.7203V58.9186Z"
                fill={colors.primary[300]}
            />
            <path
                d="M40.0815 77.7544C39.0161 72.9727 42.4218 68.1409 47.6884 66.9624C52.9549 65.7839 58.088 68.7049 59.1535 73.4866C60.2189 78.2683 56.7285 80.0319 51.4619 81.2104C46.1954 82.3889 41.1469 82.5361 40.0815 77.7544Z"
                fill={colors.primary[500]}
            />
            <path
                d="M54.7181 86.7038C54.7181 87.5358 54.0451 88.2102 53.2149 88.2102C52.3847 88.2102 51.7117 87.5358 51.7117 86.7038C51.7117 85.8718 52.3847 85.1973 53.2149 85.1973C54.0451 85.1973 54.7181 85.8718 54.7181 86.7038Z"
                fill={colors.tintedgray[800]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.0757 90.0514C66.0757 95.184 63.1577 99.6336 58.8936 101.825V106.313C60.4184 106.429 61.566 106.936 61.566 107.543C61.566 108.236 60.0704 108.798 58.2255 108.798C56.4553 108.798 55.0066 108.281 54.8924 107.627H54.8851V103.123C54.2316 103.223 53.5623 103.275 52.8809 103.275C52.1995 103.275 51.5302 103.223 50.8766 103.123V106.48C52.4014 106.596 53.549 107.103 53.549 107.71C53.549 108.404 52.0534 108.966 50.2085 108.966C48.3637 108.966 46.8681 108.404 46.8681 107.71C46.8681 107.682 46.8706 107.654 46.8754 107.627H46.8681V101.825C44.3916 100.552 42.3692 98.5178 41.1088 96.0303C40.9218 96.1171 40.7311 96.2023 40.537 96.2856C37.1873 97.7224 34.1069 98.0328 33.6568 96.9789C33.2067 95.9251 35.5574 93.906 38.9071 92.4693C39.222 92.3342 39.5345 92.2091 39.8426 92.0941C39.7396 91.4283 39.6861 90.7461 39.6861 90.0514C39.6861 82.7485 45.5936 76.8282 52.8809 76.8282C57.7239 76.8282 61.9575 79.443 64.2526 83.3407C67.352 82.1272 70.0846 81.9165 70.5065 82.9042C70.9334 83.9038 68.8405 85.7718 65.7654 87.1876C65.9686 88.1097 66.0757 89.068 66.0757 90.0514ZM53.5489 97.7507C57.2387 97.7507 58.885 95.995 58.885 94.7008C58.885 93.4066 57.2387 92.1955 53.5489 92.1955C49.8591 92.1955 48.385 93.4066 48.385 94.7008C48.385 95.995 49.8591 97.7507 53.5489 97.7507ZM53.215 91.223C55.7056 91.223 57.7246 89.1997 57.7246 86.7037C57.7246 84.2078 55.7056 82.1844 53.215 82.1844C50.7244 82.1844 48.7054 84.2078 48.7054 86.7037C48.7054 89.1997 50.7244 91.223 53.215 91.223Z"
                fill={colors.tintedgray[800]}
            />
        </Icon>
    );
}

export function ErrorIllustration({
    children,
    illustrationHeight = '200px',
    illustrationWidth = '265px',
    ...boxProps
}: Props) {
    return (
        <Illustration caption={children} {...boxProps}>
            <ErrorIcon w={illustrationWidth} h={illustrationHeight} />
        </Illustration>
    );
}
