import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ContractWithMenu } from '..';
import { APIError } from 'core';

const path = `/api/proxy/selfcare/contracts`;

type UseContractsType = {
    contracts: ContractWithMenu[];
    contractsTotalErrors: number;
};

export async function fetchContracts() {
    try {
        const { data } = await axios.get(path);

        return data.data as UseContractsType;
    } catch (err) {
        throw new Error(err);
    }
}

export function useContracts() {
    return useQuery<UseContractsType, APIError>({
        queryKey: [path],
        queryFn: fetchContracts,
    });
}
