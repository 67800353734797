import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams } from 'core';
import { TPCardData } from '../types';

const path = `/api/proxy/selfcare/health/tp-card`;

export async function fetchTPCard({ queryKey: [urn] }: FetchQueryParams) {
    try {
        const { data: response } = await axios.get(urn);
        const { data }: Api<{ tpCardsInformations: TPCardData[] }> = response;
        return data?.tpCardsInformations;
    } catch (error) {
        throw { code: error.response?.status };
    }
}

export function useTpCard() {
    return useQuery<TPCardData[], APIError>({
        queryKey: [path],
        queryFn: fetchTPCard,
    });
}
