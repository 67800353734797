import { Img, LinkBox, LinkBoxProps, LinkOverlay } from '@chakra-ui/react';

import { useIntl } from 'react-intl';

import { LinkIntl } from 'core';
import siteConfig from 'lib/site/config.json';
import { sharedMessages } from 'lib/shared';
import { useUserContext } from 'lib/user';
import CoInsurer from 'components/Logos/CoInsurer';

type NavbarLogoProps = LinkBoxProps;

const NavbarLogo = (props: NavbarLogoProps) => {
    const { userInfos } = useUserContext();
    const { menu, client, client_id } = siteConfig;
    const { formatMessage } = useIntl();
    const coInsurer = userInfos?.coInsurer;
    return (
        <LinkBox {...props}>
            <LinkOverlay as={LinkIntl} href="/news" passHref>
                {!coInsurer ||
                coInsurer === 'FNMF' ||
                (client_id === 'caa_caa' &&
                    coInsurer === 'la-mutuelle-verte') ? (
                    <Img
                        htmlWidth={menu.mainLogo.width}
                        maxH="70px"
                        src={`/images/${menu.mainLogo?.path}`}
                        alt={formatMessage(sharedMessages.logoImageAlt, {
                            name: client.name,
                        })}
                        py={menu.mainLogo.paddingVertical}
                    />
                ) : (
                    <CoInsurer coInsurer={coInsurer} />
                )}
            </LinkOverlay>
        </LinkBox>
    );
};

export default NavbarLogo;
