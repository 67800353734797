import { Icon, IconProps } from '@chakra-ui/react';

export default function HeartOutlineIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M10.497 4.083C9.435 3.405 8.175 3 7 3a5 5 0 0 0-5 5c0 5 5 9 10 13 5-4 10-8 10-13a5 5 0 0 0-5-5c-1.175 0-2.435.405-3.497 1.083A6.34 6.34 0 0 0 12 5.403a6.34 6.34 0 0 0-1.503-1.32zM12 18.435c2.052-1.656 3.927-3.242 5.375-4.883C19.107 11.589 20 9.797 20 8a3 3 0 0 0-3-3c-1.198 0-2.649.683-3.449 1.667L12 8.572l-1.551-1.905C9.649 5.683 8.199 5 7 5a3 3 0 0 0-3 3c0 1.797.893 3.589 2.625 5.552 1.448 1.641 3.323 3.227 5.375 4.884z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
