import { Icon, IconProps } from '@chakra-ui/react';

export default function GroupIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M5 5C5 2.23858 7.23858 0 10 0C12.7614 0 15 2.23858 15 5C15 7.41896 13.2822 9.43671 11 9.89998V11.382L13.6959 12.7299C14.3472 12.2701 15.1421 12 16 12C18.2091 12 20 13.7909 20 16C20 18.2091 18.2091 20 16 20C13.7909 20 12 18.2091 12 16C12 15.3944 12.1346 14.8202 12.3755 14.3058L10 13.118L7.62453 14.3058C7.86542 14.8202 8 15.3944 8 16C8 18.2091 6.20914 20 4 20C1.79086 20 0 18.2091 0 16C0 13.7909 1.79086 12 4 12C4.85793 12 5.65277 12.2701 6.30414 12.7299L9 11.382V9.89998C6.71776 9.43671 5 7.41896 5 5ZM10 2C8.34315 2 7 3.34315 7 5C7 6.65685 8.34315 8 10 8C11.6569 8 13 6.65685 13 5C13 3.34315 11.6569 2 10 2ZM4 14C2.89543 14 2 14.8954 2 16C2 17.1046 2.89543 18 4 18C5.10457 18 6 17.1046 6 16C6 14.8954 5.10457 14 4 14ZM16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14Z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
