import React from 'react';
import { HStack, Text, Stack, Box } from '@chakra-ui/react';
import { useWindowBreakpoints } from 'design-system/hooks';
import { uniqueId } from 'lodash';

export type StepType = {
    status?: 'completed' | 'inprogess' | 'pending';
    title: string;
    action?: () => void;
};

type StepperProps = {
    steps: StepType[];
};

const getDottStatusTheme = (status) => {
    const themes = {
        completed: {
            backgroundColor: 'green.500',
        },
        inprogess: {
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: 'primary.main',
        },
        pending: {
            backgroundColor: 'grey.300',
        },
        default: {
            backgroundColor: 'green.100',
        },
    };

    return themes[status] || themes.default;
};

export const Stepper = ({ steps }: StepperProps) => {
    const { isMobile } = useWindowBreakpoints();

    return (
        <Stack
            p={isMobile ? 5 : 6}
            borderRadius={8}
            spacing={4}
            border="1px solid"
            borderColor="strokes.medium">
            {steps?.map((step) => (
                <HStack gap={4} key={uniqueId()}>
                    <Box
                        as="span"
                        height="10px"
                        width="10px"
                        rounded="full"
                        sx={getDottStatusTheme(step?.status)}
                    />
                    <Text fontSize="md" fontWeight="semibold" fontFamily="mono">
                        {step.title}
                    </Text>
                </HStack>
            ))}
        </Stack>
    );
};
