import { Icon, IconProps } from '@chakra-ui/react';

export default function BasketIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M5.04 4.542A2.56 2.56 0 0 1 10 3.645a2.56 2.56 0 0 1 3.282 2.457v.63a3 3 0 0 1 5.704.97h.536v9.482a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V7.703h1.04v-3.16zm5.12 1.553v1.608h1.12v-1.6a.56.56 0 0 0-1.12-.008zM6 17.184V9.703h11.521v7.48a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3zM8.16 4.542v3.16H7.04v-3.16a.56.56 0 1 1 1.12 0zM16 7a1 1 0 0 1 .866.5h-1.732A1 1 0 0 1 16 7z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
