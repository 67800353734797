import { Flex, Text, VStack } from '@chakra-ui/react';
import { Card, SafeFormattedMessage } from 'core';
import { PlusIcon } from 'design-system/icons';
import { defineMessage } from 'react-intl';

const signatureAddBeneficiaryMessages = {
    0: defineMessage({
        id: 'buttons.add-main-beneficiary',
        defaultMessage: 'Ajouter le bénéficiaire principal',
    }),
    1: defineMessage({
        id: 'buttons.add-first-defaulting-beneficiary',
        defaultMessage: 'Ajouter un 1er bénéficiaire à défaut',
    }),
    2: defineMessage({
        id: 'buttons.add-second-defaulting-beneficiary',
        defaultMessage: 'Ajouter un 2nd bénéficiaire à défaut',
    }),
    undefined: defineMessage({
        id: 'buttons.add-beneficiary',
        defaultMessage: 'Ajouter un bénéficiaire',
    }),
};

type SignatureAddBeneficiaryProps = {
    id?: number;
    onClick: () => void;
    isEnabled: boolean;
};

// @TODO: Use design system's AddInput
export default function SignatureAddBeneficiary({
    id,
    onClick,
    isEnabled,
}: SignatureAddBeneficiaryProps) {
    return (
        <VStack
            {...(isEnabled && {
                as: Card,
                onClick,
                cursor: 'pointer',
            })}
            border="2px"
            borderStyle="dashed"
            borderColor="grey.300"
            py="4"
            borderRadius="md"
            boxShadow="none">
            <Flex
                bg={isEnabled ? 'primary.main' : 'grey.500'}
                rounded="full"
                color="white"
                p="3">
                <PlusIcon />
            </Flex>

            <Text
                fontWeight="bold"
                color={isEnabled ? 'inherit' : 'texts.medium'}>
                <SafeFormattedMessage
                    {...signatureAddBeneficiaryMessages[id]}
                    debugKey={`signatureAddBeneficiaryMessages - ${id}`}
                />
            </Text>
        </VStack>
    );
}
