import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FetchQueryParams } from 'core';
import { SatisfactionFormData } from '..';

async function fetchSatisfactionForm({ queryKey: [urn] }: FetchQueryParams) {
    try {
        const response = await axios.options<Api<SatisfactionFormData>>(urn);

        return response.data.data;
    } catch (err) {
        throw new Error(err);
    }
}

export function useSatisfactionForm(iri: string | null) {
    const path = iri ?? '/satisfaction/app/rating';

    return useQuery<SatisfactionFormData, Error>({
        queryKey: [`/api/proxy/security${path}`],
        queryFn: fetchSatisfactionForm,
    });
}
