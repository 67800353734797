/* eslint-disable @typescript-eslint/no-namespace */

export namespace AdherentUser {
    export type UserInfos = {
        user: User;
        beneficiarySubscription: BeneficiarySubscription;
        coInsurer: CoInsurer;
        healthSubscription: ContractSubcription;
        providentSubscription: ContractSubcription;
        honorarySubscription: ContractSubcription;
        features: Features;
        selfcareCampaigns?: SelfcareCampaign[];
        mainSubscription: MainSubscription;
    };

    export type MainSubscription =
        | 'health'
        | 'provident'
        | 'honorary'
        | 'beneficiary';

    export type CoInsurer =
        | 'la-mutuelle-verte'
        | 'FNMF'
        | 'AGRICA'
        | 'AGRI-prevoyance'
        | 'CCPMA-prevoyance'
        | 'CPCEA'
        | 'AG2R'
        | null;

    export type User = {
        lastName: string | null;
        firstName: string | null;
        gender: string | null;
        language: string;
        previousLoginDate: string | null;
        pendingAccount: boolean;
        username: string;
        creationDate: string;
    };

    export type TeletransmissionBeneficiary = {
        firstname: string;
        lastname: string;
        status: string;
        teletransmissionStatus: string;
    };

    export type ContractSubcription = {
        status: 'portability' | 'ongoing' | 'future' | 'closed' | 'nonExisting';
    };

    export type BeneficiarySubscription = {
        status: 'ongoing' | 'nonExisting';
    };

    type Features = {
        selfcare: Statements;
    };

    type Statements = {
        statements: boolean;
    };

    export enum CampaignTypeEnum {
        SCHOOLING = 'schooling',
        TELETRANSMISSION = 'teletransmission',
        PORTABILITY = 'portability',
    }

    export type SelfcareCampaign = {
        campaignType: CampaignTypeEnum;
        campaignId: string | null;
        formId: string | null;
        beneficiaries?: TeletransmissionBeneficiary[];
    };
}

export namespace EnterpriseUser {
    export type UserInfos = {
        user: User;
        theme: Theme;
        permissions: string[];
    };

    export type User = {
        lastName: string | null;
        firstName: string | null;
        gender: string | null;
        previousLoginDate: string | null;
        username: string;
    };

    type Theme = {
        primaryColor: string;
        logo: string;
    };
}

export namespace SRHUser {
    export type UserInfos = {
        userId: string;
        username: string;
        firstName: string;
        lastName: string;
        accountType: string;
        jobTitle: string | null;
        lastConnection: string | null;
        previousConnection: string | null;
    };
}
