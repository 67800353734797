import { defineMessages, defineMessage } from 'react-intl';

export const productsLevel = defineMessages({
    base: {
        id: 'subscriptions.product-level.base',
        defaultMessage: 'Base',
    },
    supplementary: {
        id: 'subscriptions.product-level.supplementary',
        defaultMessage: 'Surcomplémentaire',
    },
    option: {
        id: 'subscriptions.product-level.option',
        defaultMessage: 'Option',
    },
});

export const contractNumberPrefix = defineMessage({
    id: 'components.account.guarantees.contract-number-prefix',
    defaultMessage: 'N°',
});

export const productLabels = defineMessages({
    'subscriptions.product-name.base': {
        id: 'subscriptions.product-name.base',
        defaultMessage: 'Base obligatoire',
    },
    'subscriptions.product-name.option': {
        id: 'subscriptions.product-name.option',
        defaultMessage: 'Option facultative',
    },
    'subscriptions.product-name.supplementary': {
        id: 'subscriptions.product-name.supplementary',
        defaultMessage: 'Surcomplémentaire facultative',
    },
    'subscriptions.product-name.baseSupp': {
        id: 'subscriptions.product-name.base-supp',
        defaultMessage: 'Base et surcomplémentaires obligatoires',
    },
});

export const subscriptionType = defineMessages({
    individual: {
        id: 'api.health.contract.subscription-type.individual',
        defaultMessage: 'Individuel',
    },
    collective: {
        id: 'api.health.contract.subscription-type.collective',
        defaultMessage: 'Collectif',
    },
});

export const clauseProductsLevel = defineMessages({
    base: {
        id: 'components.account.guarantees.products-level.clause.base',
        defaultMessage: 'Sur le contrat de base',
    },
    option: {
        id: 'components.account.guarantees.products-level.clause.option',
        defaultMessage: "Sur l'option",
    },
    supplementary: {
        id: 'components.account.guarantees.products-level.clause.supplementary',
        defaultMessage: 'Sur la surcomplémentaire',
    },
    'base-option': {
        id: 'components.account.guarantees.products-level.clause.base-option',
        defaultMessage: "Sur le contrat de base et l'option",
    },
    'base-supplementary': {
        id: 'components.account.guarantees.products-level.clause.base-supplementary',
        defaultMessage: 'Sur le contrat de base et la surcomplémentaire',
    },
    'option-supplementary': {
        id: 'components.account.guarantees.products-level.clause.option-supplementary',
        defaultMessage: "Sur l'option et la surcomplémentaire",
    },
    'base-option-supplementary': {
        id: 'components.account.guarantees.products-level.clause.base-option-supplementary',
        defaultMessage:
            "Sur le contrat de base, l'option et la surcomplémentaire",
    },
});

export const termsTypeMessages = defineMessages({
    limit: {
        id: 'components.account.guarantees.terms-type.limit',
        defaultMessage: 'Dans la limite de',
    },
    floor: {
        id: 'components.account.guarantees.terms-type.floor',
        defaultMessage: 'Remboursement minimum :',
    },
    ceiling: {
        id: 'components.account.guarantees.terms-type.ceiling',
        defaultMessage: 'Remboursement maximum : ',
    },
    exceededCeiling: {
        id: 'components.account.guarantees.terms-type.exceeded-ceiling',
        defaultMessage:
            'En cas de dépassement du plafond, prise en charge à hauteur de',
    },
});

export const infoCardDescription = {
    health: defineMessages({
        collective: {
            id: 'components.account.guarantees.health.collective-description',
            defaultMessage:
                'Votre contrat santé collectif souscrit par votre employeur vous accompagne, vous et votre famille, à chaque étape de votre vie pour un accès aux soins de qualité à tarifs maîtrisés.',
        },
        individual: {
            id: 'components.account.guarantees.health.individual-description',
            defaultMessage:
                "Votre complémentaire santé vous accompagne sur l'ensemble de vos besoins en santé à tous les moments clés de votre vie.",
        },
        territorial: {
            id: 'components.account.guarantees.health.territorial-description',
            defaultMessage:
                "Les + Mutuelle c'est le maintien de salaire assuré, un capital décès inclus.",
        },
    }),
    provident: defineMessages({
        collective: {
            id: 'components.account.guarantees.provident.collective-description',
            defaultMessage:
                "Votre contrat de prévoyance collective souscrit par votre employeur vous aide, vous et votre famille, à faire face aux conséquences financières liées à quatre risques majeurs de la vie : l'incapacité de travail, l'invalidité, un décès, la dépendance.",
        },
        individual: {
            id: 'components.account.guarantees.provident.individual-description',
            defaultMessage:
                'Vos solutions prévoyance pour vous protéger, vous et votre famille, face aux aléas de la vie.',
        },
        territorial: {
            id: 'components.account.guarantees.provident.territorial-description',
            defaultMessage:
                'Votre couverture prévoyance adaptée à votre statut, pour vous protéger, vous et votre famille, face aux aléas de la vie.',
        },
    }),
    'casuality.carInsurance': defineMessage({
        id: 'contract.casuality.car-insurance.description',
        defaultMessage:
            '<l>Les + Mutuelle de votre assurance :</l><l>- Prêt d’un véhicule, assistance 0 km en cas de panne, accident, incendie et vol, 24 h/24, 7 j/7. </l><l>- Un accompagnement personnalisé : un conseiller mutualiste vous est dédié tout au long de la gestion du sinistre.</l>',
    }),
    'casuality.legalProtection': defineMessage({
        id: 'contract.casuality.legal-protection.description',
        defaultMessage:
            '<l>Les + Mutuelle de votre assurance :</l><l>- Assistance 24h/24, 7j/7 pour toutes vos questions juridiques</l><l>- Prise en charge des honoraires d’avocat, des frais d’expertise et de procédure</l><l>- Jusqu’à 15 000 € par litige</l>',
    }),
    'casuality.homeInsurance': defineMessage({
        id: 'contract.casuality.home-insurance.description',
        defaultMessage:
            "<l>Les + Mutuelle de votre assurance :</l><l>- Assistance 24 h/24 et 7 j/7 en cas de sinistre ou pour tout événement (panne de chauffage, plomberie, perte de clés...) : intervention d'un plombier, gardiennage du domicile sinistré, services à la personne...</l><l>- Secours mutualiste : prise en charge de votre cotisation d'assurance habitation suite à un licenciement économique ou à une invalidité</l>",
    }),
    'casuality.financialLost': defineMessage({
        id: 'contract.casuality.financial-lost.description',
        defaultMessage:
            '<l>Les + Mutuelle de votre assurance :</l><l>- Prise en charge des franchises jusqu’à 1 000 € par an</l><l>- Un stage de récupération de points financé tous les deux ans</l><l>- Assistance 24 h/24, 7 j/7</l><l>- Bonus «Retour au domicile»</l>',
    }),
    'casuality.accident': defineMessage({
        id: 'contract.casuality.accident.description',
        defaultMessage:
            '<l>Les + Mutuelle de votre assurance :</l><l>- Couverture 24h/24, dans toutes les activités</l><l>- Prise en charge des frais de soins, lunettes, prothèses ou appareils dentaires</l><l>- Protection de votre enfant qu’il soit victime ou responsable d’un accident</l>',
    }),
    'casuality.civilLiability': defineMessage({
        id: 'contract.casuality.civil-liability.description',
        defaultMessage:
            'Couvrir les conséquences pécuniaires de dommages corporels, matériels et immatériels causés à un tiers dans le cadre de la vie privée.',
    }),
    'casuality.realEstateSecurityPackageAlurLaw': defineMessage({
        id: 'contract.casuality.real-estate-security-package-alur-law.description',
        defaultMessage:
            '<l>En qualité de propriétaire bailleur individuel :</l><l>- Protéger les locaux d’habitation que vous possédez</l><l>- Couvrir la responsabilité civile pour les dommages causés aux tiers dont vous pouvez être responsable</l>',
    }),
    'casuality.professionalMultiriskInsurance': defineMessage({
        id: 'contract.casuality.professional-multirisk-insurance.description',
        defaultMessage:
            "Une couverture complète pour les biens, l'activité et les responsabilités de l'entreprise.",
    }),
    'casuality.professionalLegalProtection': defineMessage({
        id: 'contract.casuality.professional-legal-protection.description',
        defaultMessage:
            '<l>Du simple conflit à la procédure judiciaire, vous disposez d’un maximum de garanties : </l><l>- La protection pénale et administrative, sociale et fiscale</l><l>- Le conflit individuel du travail</l><l>- Les litiges avec vos fournisseurs et vos clients</l><l>- Les locaux professionnels, la construction et les travaux immobiliers</l><l>- Le recouvrement de créances.</l>',
    }),
    'provident-guarantee': defineMessage({
        id: 'contract.provident-guarantee.description',
        defaultMessage: 'Détail de votre garantie prévoyance',
    }),
};
