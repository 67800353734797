import { Box } from '@chakra-ui/react';
import {
    coreEmptyStates,
    coreSharedMessages,
    DashboardContext,
    LayoutButtonType,
} from 'core';
import { useContext } from 'react';
import { MessageDescriptor } from 'react-intl';

type EmptyProps = {
    buttons: LayoutButtonType[];
    message?: MessageDescriptor;
};

type EmptyComponentProps = {
    Empty: (props: EmptyProps) => JSX.Element;
};

export function EmptyComponent({ Empty }: EmptyComponentProps) {
    const { selectedFilters, setShouldFiltersReset, setSelectedFilters } =
        useContext(DashboardContext);

    return (
        <Box sx={{ '> div ': { py: 0, pb: 6 } }}>
            <Empty
                message={
                    !!selectedFilters.length
                        ? coreEmptyStates.filters
                        : coreEmptyStates.default
                }
                buttons={
                    !!selectedFilters.length
                        ? [
                              {
                                  text: coreSharedMessages.buttonClearFilters,
                                  onClick: () => {
                                      setShouldFiltersReset(true);
                                      setSelectedFilters([]);
                                  },
                              },
                          ]
                        : []
                }
            />
        </Box>
    );
}
