type AddDocumentViewerScrollListenerProps = {
    viewerContainerId?: string;
    hasScrolledDocumentCallback: () => void;
    hasReachedBottomCallback: () => void;
};

export const addDocumentViewerScrollListener = ({
    viewerContainerId = '#pdf-viewer-container',
    hasScrolledDocumentCallback,
    hasReachedBottomCallback,
}: AddDocumentViewerScrollListenerProps) => {
    const viewerContainer = document.querySelector(viewerContainerId);
    const viewer = viewerContainer?.firstChild?.firstChild
        ?.firstChild as HTMLDivElement;

    if (!viewer) return;

    if (viewer.scrollHeight === viewer.offsetHeight) {
        hasScrolledDocumentCallback();
        return;
    }

    viewer.addEventListener('scroll', () => {
        if (
            viewer.scrollHeight === viewer.offsetHeight ||
            viewer.offsetHeight + viewer.scrollTop >= viewer.scrollHeight * 0.98
        ) {
            hasReachedBottomCallback();
        }
    });
};
