import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { UrlObject } from 'url';
import { useRedirect } from './useRedirect';

const path = '/api/register';

function readableAzureError(azureError?: string) {
    return azureError?.split(':')?.[0];
}

async function register(
    state: string,
    code: string,
    azureError: string,
    hasRedirectError: boolean,
    redirect: (path: string | UrlObject) => Promise<boolean>,
    authorizationUrl: string
) {
    if ((!authorizationUrl && !state) || hasRedirectError) return;

    try {
        const hasRegistrationBeenCanceled =
            readableAzureError(azureError) === 'AADB2C90091';

        if (hasRegistrationBeenCanceled) {
            redirect('/register');
            return;
        }

        const res = await axios.post(path, {
            azureError,
            state: !code ? undefined : state,
            code,
            authorizationUrl,
        });

        return {
            statusCode: res.status,
            errorCode: undefined,
        };
    } catch (err) {
        const { data: responseData, status: responseStatus } =
            err?.response || {};
        const {
            code,
            errors,
            error: responseDataError,
            redirect: redirectUrl,
        } = responseData || {};
        const errorCodeNumber = errors?.[0]?.code;

        if (responseStatus === 503) {
            redirect({
                pathname: '/maintenance',
                query: {
                    message: responseData?.meta?.message,
                },
            });
            return;
        }

        if (redirectUrl) {
            window.location.href = redirectUrl;
            return;
        }

        return {
            statusCode: responseStatus as number,
            errorCode:
                code ||
                (typeof responseDataError === 'string'
                    ? responseDataError
                    : (responseDataError?.response as AxiosResponse)),
            errorCodeNumber,
        };
    }
}

export function useOAuth2Registration(
    state?: string,
    code?: string,
    azureError?: string,
    hasRedirectError?: boolean,
    authorizationUrl?: string
) {
    const { redirect } = useRedirect();

    return useQuery({
        queryKey: [path, state, code],

        queryFn: () =>
            register(
                state,
                code,
                azureError,
                hasRedirectError,
                redirect,
                authorizationUrl
            ),
    });
}
