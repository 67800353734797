import { Icon, IconProps } from '@chakra-ui/react';

export default function CrutchIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M12.335 2.327c-.517.194-.519.912-.14 1.314.318.34.63.653.94.945l-2.693 4.665a1 1 0 0 0-.132.44l-.254 4.228-2.691 4.661c-.403.085-.77.334-.992.719a.5.5 0 0 0 .183.683l1.732 1a.5.5 0 0 0 .683-.183c.222-.385.254-.827.126-1.219l2.69-4.66 3.536-2.335a1 1 0 0 0 .315-.334l2.695-4.668c.407.123.834.236 1.285.343.538.127 1.182-.232 1.08-.774-.071-.381-.38-.68-1.265-.818-1.255-.328-2.247-.711-3.143-1.228-.894-.516-1.718-1.18-2.622-2.097-.502-.729-.936-.831-1.333-.682zm1.248 9.01-1.323-.764-.102 1.706 1.425-.942zm.955-1.18-1.732-1 1.489-2.579 1.731 1-1.488 2.578z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
