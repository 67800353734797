export type FileFormat = 'pdf' | 'jpeg' | 'png' | 'heic' | 'heif';

const formatColors = {
    pdf: 'files.pdf',
    jpeg: 'files.jpeg',
    png: 'files.png',
    heic: 'files.heic',
    heif: 'files.heif',
};

export function getFileType(filename: string) {
    const type = filename.split('.')[filename.split('.').length - 1];

    return type === 'jpg' ? 'jpeg' : type.toLowerCase();
}

type FormatFileExtension = {
    text: string;
    color: string;
};

export function formatFileExtension(
    filename: string = '',
    type?: FileFormat
): FormatFileExtension {
    try {
        if (!type) {
            if (filename.indexOf('.') === -1) {
                return { color: 'white', text: '' };
            }

            const fileType = getFileType(filename);
            return {
                color: formatColors[fileType] ?? 'files.pdf',
                text: fileType.toUpperCase(),
            };
        }

        return {
            color: formatColors[type] ?? 'files.pdf',
            text: type.includes('/')
                ? type.split('/')[1].toUpperCase()
                : type.toUpperCase(),
        };
    } catch {
        throw new Error(`Unknown type "${type}" or filename "${filename}".`);
    }
}
