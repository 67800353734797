import {
    LinkBox,
    HStack,
    Text,
    VStack,
    Flex,
    LinkOverlay,
    IconButton,
} from '@chakra-ui/react';
import { LinkIntl, Card, FileFormatIcon, FileFormat } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { PropsWithChildren } from 'react';
// @TODO will be replaced once Icon component is moved to design-system
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import * as icons from 'design-system/icons';

type DownloadableProps = {
    type: FileFormat;
    name: string;
    source: string;
    id?: number | string;
    date?: string;
    queryParams?: any;
    isDownloadable?: boolean;
    iconProps?: {
        name: string;
        ariaLabel: string;
        onClick: () => void;
    };
};

type UnDownloadableProps = Partial<DownloadableProps> & {
    type: FileFormat;
    name: string;
    source?: string;
    isDownloadable: false;
};

type FileDownloadCardProps = DownloadableProps | UnDownloadableProps;

const unsetFocusAnimations = { transform: 'unset', boxShadow: 'none' };

type DownloadOverlayProps = Pick<
    FileDownloadCardProps,
    'isDownloadable' | 'source' | 'id' | 'queryParams'
>;

function DownloadOverlay({
    isDownloadable,
    source,
    id,
    queryParams,
    children,
}: PropsWithChildren<DownloadOverlayProps>) {
    if (!isDownloadable) {
        return <Flex>{children}</Flex>;
    }

    return (
        <LinkOverlay
            as={LinkIntl}
            // @ts-ignore
            href={{
                pathname: '/document/[source]',
                query: {
                    source: source,
                    ...(id && { id }),
                    ...queryParams,
                },
            }}
            _focusVisible={{
                outline: '0px',
            }}
            passHref>
            {children}
        </LinkOverlay>
    );
}

export function FileDownloadCard({
    type,
    name,
    source,
    id,
    date,
    queryParams,
    isDownloadable = true,
    iconProps,
}: FileDownloadCardProps) {
    const { isMobile } = useWindowBreakpoints();

    const Icon =
        iconProps !== undefined &&
        icons[
            iconProps?.name?.charAt(0)?.toUpperCase() +
                iconProps?.name?.slice(1)
        ];

    return (
        <Card
            listStyleType="none"
            rounded="md"
            borderRadius="lg"
            borderStyle="solid"
            borderColor="strokes.medium"
            borderWidth="1px"
            boxShadow="none"
            p="4"
            cursor={isDownloadable && 'pointer'}
            _focusWithin={!isDownloadable && unsetFocusAnimations}
            _hover={!isDownloadable && unsetFocusAnimations}
            mb={isMobile ? '1' : '0'}
            display="flex"
            justifyContent="space-between"
            data-testid="file-download-card">
            <LinkBox flexGrow={1}>
                <DownloadOverlay
                    {...{ isDownloadable, source, id, queryParams }}>
                    <HStack
                        fontWeight="bold"
                        spacing="2.5"
                        overflow="hidden"
                        w="100%">
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            rounded="md">
                            <FileFormatIcon type={type} />
                        </Flex>
                        <VStack
                            alignItems="flex-start"
                            spacing="0"
                            flexGrow={1}>
                            <Text
                                as="h4"
                                wordBreak="break-word"
                                fontSize="md"
                                fontWeight="semibold"
                                fontFamily="mono">
                                {name}
                            </Text>
                            {date && (
                                <Text
                                    fontWeight="normal"
                                    fontSize="xs"
                                    color="grey.700">
                                    {date}
                                </Text>
                            )}
                        </VStack>
                    </HStack>
                </DownloadOverlay>
            </LinkBox>

            {!!Icon && (
                <IconButton
                    aria-label={iconProps.ariaLabel}
                    icon={<Icon w={6} h={6} />}
                    onClick={iconProps.onClick}
                />
            )}
        </Card>
    );
}
