import { Icon, IconProps } from '@chakra-ui/react';

export default function LightningIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M9.944 3.404a1 1 0 0 1 .94-.658h4.678a1 1 0 0 1 .946 1.325l-1.689 4.913h2.42a1 1 0 0 1 .738 1.675l-9.864 10.8a1 1 0 0 1-1.688-.988l2.296-6.953H7.687a1 1 0 0 1-.94-1.342l3.197-8.772zm1.64 1.342-2.469 6.772h.989a1 1 0 0 1 .95 1.314L9.79 16.656l5.18-5.672h-1.553a1 1 0 0 1-.946-1.325l1.69-4.913h-2.578z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
