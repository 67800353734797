import { Icon, IconProps } from '@chakra-ui/react';

export default function AcrobatIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M13.332 5.163c-.077 1.135-.42 2.573-1.13 4.286l-.09.217c.773 1.159 1.887 2.335 3.239 3.528.761-.098 1.46-.153 2.093-.17 1.184-.03 2.195.073 2.949.32.65.213 1.607.72 1.607 1.789 0 .728-.38 1.323-.95 1.683-.538.34-1.21.46-1.897.424-1.27-.068-2.816-.674-4.405-1.936-1.698.28-3.721.783-6.01 1.576a28.257 28.257 0 0 1-2.696 4.008c-.628.791-1.507 1.22-2.384 1.09-.903-.135-1.597-.853-1.654-1.829-.054-.915.453-1.842 1.343-2.655.852-.779 2.147-1.547 4-2.24.727-1.369 1.537-3.094 2.528-5.43-.749-1.406-1.124-2.912-.861-4.488.205-1.23.598-2.215 1.16-2.816.285-.304.688-.585 1.196-.63.55-.048 1.014.199 1.327.555.555.631.707 1.647.635 2.718zm-2.13 6.637c-.417.953-.803 1.8-1.168 2.561 1.001-.298 1.95-.54 2.842-.734a20.859 20.859 0 0 1-1.674-1.827zm-5.803 6.626a5.959 5.959 0 0 0-.702.544c-.584.534-.684.897-.696 1.026.082-.014.258-.078.475-.351.316-.4.621-.797.923-1.22zm12.833-3.4c.394.135.74.201 1.027.216.18.01.325 0 .438-.02-.336-.1-.823-.175-1.465-.196zm1.86.368s-.01-.005-.022-.02c.018.013.022.02.022.02zM11.338 4.385c-.127.293-.257.71-.352 1.28-.07.418-.074.842-.014 1.274.22-.762.33-1.4.364-1.911a4.53 4.53 0 0 0 .002-.643z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
