function getIntlPath(
    routes: { [x: string]: { [x: string]: string } },
    href: string,
    locale?: string
): string {
    if (!locale) return href;

    return routes[href][locale];
}

export { getIntlPath };
