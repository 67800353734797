import { Flex, LinkBox, LinkOverlay, Stack, Text } from '@chakra-ui/react';
import { coreSharedMessages, LinkIntl } from 'core';
import { ArrowRightIcon } from 'design-system/icons';
import { FormattedMessage } from 'react-intl';
import { UrlObject } from 'url';

type ProfileLinkType = {
    text: string;
    subTitle?: string;
    link: string | UrlObject;
    isAvailable: boolean;
};

export function ProfileLink({
    text,
    subTitle,
    link,
    isAvailable,
}: ProfileLinkType) {
    return (
        <LinkBox
            p="2"
            rounded="md"
            _hover={{
                bg: 'grey.50',
                '.arrow-icon': {
                    transform: 'translateX(-10px)',
                    transition: 'ease-in-out all 0.3s',
                },
            }}
            _focusWithin={{
                '.arrow-icon': {
                    transform: 'translateX(-10px)',
                    transition: 'ease-in-out all 0.3s',
                },
            }}>
            <LinkOverlay
                as={LinkIntl}
                // @ts-expect-error
                href={link}
                passHref>
                <Flex
                    w="full"
                    alignItems="center"
                    justifyContent="space-between">
                    <Stack spacing="0">
                        <Text fontWeight="semibold">
                            <FormattedMessage {...coreSharedMessages[text]} />
                        </Text>
                        {subTitle && (
                            <Text color="text.medium" fontFamily="mono">
                                {subTitle}
                            </Text>
                        )}
                    </Stack>
                    <ArrowRightIcon
                        className={isAvailable && 'arrow-icon'}
                        transition="ease-in-out all 0.3s"
                        color={isAvailable ? 'inherit' : 'texts.light'}
                        w="5"
                        h="5"
                    />
                </Flex>
            </LinkOverlay>
        </LinkBox>
    );
}
