import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useWindowBreakpoints } from 'design-system/hooks';
import { useFormContext } from 'react-hook-form';
import {
    FilterType,
    FormLibrarian,
    useAutoFilterSelection,
    useFiltersForm,
} from 'core';

type FiltersFormInputProps = {
    name: string;
    type: FilterType;
    resourceId?: string;
};

export default function FiltersFormInput({
    name,
    type,
    resourceId,
}: FiltersFormInputProps) {
    const { data: filtersForm } = useFiltersForm(type, resourceId);
    const { isMobile } = useWindowBreakpoints();
    const methods = useFormContext();
    const {
        setError,
        formState: { errors },
        clearErrors,
    } = methods;
    const [isFormRendered, setIsFormRendered] = React.useState(false);
    const formData = useAutoFilterSelection(filtersForm, name, isFormRendered);

    React.useEffect(() => setIsFormRendered(true), []);

    return (
        <Stack
            sx={{
                '.calendar_popover': {
                    position: 'relative',
                    top: 2,
                    left: 'unset',
                    right: 'unset',
                    w: '100%',
                    mb: 4,
                },
                '.calendar_popover > div': {
                    mt: 1,
                },
                '.dateRange': {
                    flexDirection: 'column',
                },
                '.dateRange > div': {
                    w: '100%',
                },
            }}>
            <Stack spacing="6" alignItems={isMobile ? 'stretch' : 'flex-end'}>
                {formData?.widgets
                    ?.filter((widget) => widget?.id === name)
                    .map(({ type, id, ...props }) => (
                        <FormLibrarian
                            key={id}
                            type={type}
                            props={{
                                id,
                                methods: {
                                    setError,
                                    errors,
                                    clearErrors,
                                },
                                ...props,
                            }}
                        />
                    ))}
            </Stack>
        </Stack>
    );
}
