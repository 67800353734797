import { type ReactNode, useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { uniqueId } from 'lodash';
import { Button } from '@chakra-ui/react';
import { ChevronTopIcon, ChevronBottomIcon } from 'design-system/icons';

type CollapseProps<T> = {
    max?: number;
    items: T[];
    renderItem: (item: T, key: string) => ReactNode;
    showLabel: MessageDescriptor;
    hideLabel: MessageDescriptor;
};

function Collapse<T>({
    items,
    renderItem,
    max = 2,
    showLabel,
    hideLabel,
}: CollapseProps<T>) {
    const [isCollapsed, setIsCollapsed] = useState(true);

    return (
        <>
            {items
                .slice(0, isCollapsed ? max : undefined)
                .map((item) => renderItem(item, uniqueId()))}
            {items.length > max && (
                <Button
                    variant="link"
                    size={'sm'}
                    color="brand.secondary.ultradark"
                    alignSelf="flex-start"
                    rightIcon={
                        isCollapsed ? <ChevronBottomIcon /> : <ChevronTopIcon />
                    }
                    onClick={() =>
                        setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed)
                    }>
                    <FormattedMessage
                        {...(isCollapsed ? showLabel : hideLabel)}
                    />
                </Button>
            )}
        </>
    );
}

export default Collapse;
