import { Icon, IconProps } from '@chakra-ui/react';

export default function PlayOutlineIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="m7 18.127 10.5-6.062L7 6.003v12.124zm11.5-4.33c1.333-.77 1.333-2.694 0-3.464L8 4.271c-1.333-.77-3 .192-3 1.732v12.124c0 1.54 1.667 2.502 3 1.732l10.5-6.062z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
