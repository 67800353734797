import React, { useContext } from 'react';
import axios from 'axios';
import { QueryStatus } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import {
    ChallengeError,
    useRedirect,
    useFormRedirect,
    ChallengeWorkflow,
    UtilsContext,
} from 'core';

export const MFA_NUMBER_OF_PIN = 8;

export function useMFAPost(workflow: ChallengeWorkflow) {
    const router = useRouter();
    const { redirect } = useRedirect();
    const { redirectAfterLogin } = useFormRedirect();
    const token = router.query?.token as string;
    const [status, setStatus] = React.useState<QueryStatus>(null);
    const [error, setError] = React.useState<ChallengeError>();
    const [code, setCode] = React.useState('');
    const [isCodeValid, setIsCodeValid] = React.useState<boolean>();
    const { siteConfig } = useContext(UtilsContext);

    function handleSubmit(evt: React.FormEvent) {
        evt.preventDefault();
        setIsCodeValid(code.length === MFA_NUMBER_OF_PIN);
    }

    function handleInput(value: string) {
        setCode(value);
        setIsCodeValid(value.length === MFA_NUMBER_OF_PIN ? true : undefined);
        setStatus(null);
    }

    function redirectPasswordUpgrade({
        token,
        iri,
    }: {
        token: string;
        iri: string;
    }) {
        redirect({
            pathname: '/password-upgrade/[token]',
            query: { token, iri },
        });
    }

    function redirectCGUsAcceptances({ token }: { token: string }) {
        redirect({
            pathname: '/auth/general-conditions',
            query: {
                token,
            },
        });
    }

    function redirectPhoneUpgrade({ token }: { token: string }) {
        redirect({
            pathname: '/auth/phone-upgrade',
            query: {
                token,
            },
        });
    }

    React.useEffect(() => {
        async function postCode() {
            try {
                setStatus('pending');
                const { data } = await axios.post(`/api/mfa`, { token, code });

                setStatus('success');

                if (data?.scope === 'activation') {
                    redirect('/activation');
                    return;
                }

                redirectAfterLogin();
            } catch (err) {
                const { data } = err.response;

                setStatus('error');
                setError(data);

                if (data.code === 'MFA_REDIRECT_CGU') {
                    redirectCGUsAcceptances(data.data);
                    return;
                }

                if (data.code === 'MFA_REDIRECT_PHONE') {
                    redirectPhoneUpgrade(data.data);
                    return;
                }

                if (data.code === 'MFA_REDIRECT_PASSWORD') {
                    redirectPasswordUpgrade(data.data);
                    return;
                }

                if (data.code === 'MFA_BAD_CODE') {
                    setIsCodeValid(false);
                }

                if (data.code === 'MFA_REDIRECT') {
                    if (siteConfig.packageName === 'adherent') {
                        redirect({
                            pathname: '/mfa/[token]',
                            query: { token },
                        });
                    } else {
                        redirect('/mfa');
                    }
                }
            }
        }

        if (isCodeValid && workflow === 'mfa') {
            postCode();
        }

        // Nous savons que `useRedirect#redirect` ne peut pas changer
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, isCodeValid, token, workflow]);

    return {
        error,
        status,
        handleInput,
        handleSubmit,
        isCodeValid,
        isEnabled: workflow === 'mfa',
    };
}
