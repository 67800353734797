import React from 'react';
import NativeTextarea from './NativeTextarea';
import { WidgetProps } from '../types';
import RichTextarea from './RichTextarea';

export type TextAreaProps = WidgetProps & {
    isRichText: boolean;
};

export default function TextArea(props: TextAreaProps) {
    const { isRichText, ...rest } = props;

    return isRichText ? (
        <RichTextarea {...rest} />
    ) : (
        <NativeTextarea {...rest} />
    );
}
