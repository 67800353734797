import { Box } from '@chakra-ui/react';
import { useLegendRowContext } from './LegendRow';

export function LegendIndicator() {
    const { color } = useLegendRowContext();

    return (
        <Box w="20px" h="20px" bgColor={color} rounded="sm" flexShrink={0} />
    );
}
