import { KeyboardEvent } from 'react';
import { KeyBoardEventsEnum } from 'core';

export const handleKeyBoardEvents = (
    e: KeyboardEvent<HTMLInputElement>,
    items: unknown[],
    focusedOption: number,
    onClickCallback,
    updateCallback
): void => {
    const { key } = e;
    let nextFocusedOption = focusedOption;

    if (key === KeyBoardEventsEnum.ARROWDOWN) {
        e.preventDefault();
        nextFocusedOption = (focusedOption + 1) % items.length;
    }
    if (key === KeyBoardEventsEnum.ARROWUP) {
        e.preventDefault();
        nextFocusedOption = (focusedOption + items.length - 1) % items.length;
    }

    if (key === KeyBoardEventsEnum.ENTER) {
        e.preventDefault();
        if (focusedOption !== -1) onClickCallback(items[focusedOption]);
    }

    updateCallback(nextFocusedOption);
};
