import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { FetchQueryParams, UtilsContext } from 'core';
import { RegistrationReason } from '../types';
import React from 'react';

export const path =
    '/api/proxy/security/public/user/registration/reasons/[clientId]';

export async function fetchRegistrationReasons({
    queryKey: [urn, clientId],
}: FetchQueryParams) {
    try {
        const { data: response } = await axios.get(
            urn.replace('[clientId]', clientId)
        );
        const { data }: Api<{ reasons: RegistrationReason[] }> = await response;

        return data?.reasons;
    } catch (error) {
        throw new Error(error);
    }
}

export function useRegistrationReasons() {
    const {
        siteConfig: { client_id },
    } = React.useContext(UtilsContext);

    return useQuery<RegistrationReason[], Error>({
        queryKey: [path, client_id],
        queryFn: fetchRegistrationReasons,
    });
}
