import { FormObject, WidgetObject } from '../types';

export function getSubWidgetsTypes(
    widgets: FormObject['widgets']
): { optionId: string; subWidget: WidgetObject }[] {
    const subWidgets = widgets?.filter((widget) =>
        widget.options?.some((option) => option.subWidget || option.subWidgets)
    );

    const subSubWidgets = subWidgets?.map((widget) => {
        return widget.options?.map((option) => {
            if (option?.subWidget?.id)
                return {
                    optionId: option.id,
                    subWidget: {
                        id: option.subWidget.id,
                        type: option.subWidget.type,
                    },
                };
            if (option?.subWidgets)
                return option.subWidgets.map((subWidget) => {
                    return {
                        optionId: option.id,
                        subWidget: {
                            id: subWidget.id,
                            type: subWidget.type,
                        },
                    };
                });
        });
    });
    return subSubWidgets.flat(2).filter((widget) => widget);
}
