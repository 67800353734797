import { Icon, IconProps } from '@chakra-ui/react';

export default function ArrowUpLeftIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="m9.922 8.508 7.028 7.028a1 1 0 0 1-1.414 1.414L8.508 9.922l-.54 4.545a1 1 0 1 1-1.986-.235l.782-6.592a1 1 0 0 1 .876-.876l6.592-.782a1 1 0 1 1 .236 1.986l-4.546.54z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
