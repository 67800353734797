import React, { useEffect, useState } from 'react';
import { HStack, Text, VStack, IconButton } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { Heading, coreSharedMessages } from 'core';
import { CrossIcon } from 'design-system/icons';
import { getMagicScrollbarStyle } from 'design-system/lib';
import { PAGINATION_FIRST_PAGE, PAGINATION_PAGE_SIZE } from './Checkboxes';
import { Pagination } from './Pagination';

type SelectedBadgesProps = {
    value: string[];
    hasExtraInfo: boolean;
    setValue: (value: string[]) => void;
};

export default function SelectedBadges({
    value,
    hasExtraInfo,
    setValue,
}: SelectedBadgesProps) {
    const [paginatedValue, setPaginatedValue] = useState(
        value?.slice(0, PAGINATION_FIRST_PAGE)
    );
    const { formatMessage } = useIntl();

    function onSelectedBadgeClick(nextValue: string) {
        setValue(value.filter((filteredValue) => filteredValue !== nextValue));
    }

    const handleShowMoreClick = (): void => {
        setPaginatedValue(
            value.slice(0, paginatedValue.length + PAGINATION_PAGE_SIZE)
        );
    };

    useEffect(() => {
        setPaginatedValue(
            value.slice(
                0,
                paginatedValue.length > PAGINATION_FIRST_PAGE
                    ? paginatedValue.length
                    : PAGINATION_FIRST_PAGE
            )
        );
    }, [value]);

    return (
        <VStack
            maxH="400px"
            pr={3}
            overflowY={'auto'}
            sx={getMagicScrollbarStyle('1.5', 'primary.main', 'gray.200')}>
            {paginatedValue.map((value) => {
                const {
                    id: checkboxValueId,
                    label: checkboxValueLabel,
                    extraLabel,
                } = JSON.parse(value);

                return (
                    <HStack
                        key={checkboxValueId}
                        p={4}
                        bg={'gray.100'}
                        w={'100%'}
                        justifyContent={'space-between'}
                        borderRadius={'lg'}
                        {...(!hasExtraInfo && {
                            px: 3,
                            py: 1,
                            borderRadius: 'md',
                        })}>
                        <VStack maxW={'85%'} alignItems={'start'} gap={0}>
                            <Heading
                                as={hasExtraInfo ? 'h4' : 'h6'}
                                color={'primary.main'}
                                w={'100%'}
                                isTruncated>
                                {checkboxValueLabel}
                            </Heading>
                            {!!extraLabel && (
                                <Text fontSize="sm">{extraLabel}</Text>
                            )}
                        </VStack>
                        <IconButton
                            w={8}
                            h={8}
                            p={2}
                            bg={'bg.light'}
                            title={formatMessage(coreSharedMessages.deselect)}
                            aria-label={formatMessage(
                                coreSharedMessages.deselect
                            )}
                            icon={<CrossIcon w={4} h={4} />}
                            onClick={() => onSelectedBadgeClick(value)}
                            data-testid="deselect-option"
                            isRound
                        />
                    </HStack>
                );
            })}
            <Pagination
                items={value}
                paginatedItems={paginatedValue}
                pageSize={PAGINATION_PAGE_SIZE}
                handleShowMoreClick={handleShowMoreClick}
            />
        </VStack>
    );
}
