import React from 'react';
import { useRouter } from 'next/router';
import { interceptorMessages } from 'lib/shared';
import { UseInterceptorObject } from 'core';

//TEMPORARY FIXE.
const EXCLUDED_MESSAGES_ROUTES = [
    '/api/proxy/contents/campaigns',
    '/api/proxy/services/campaigns',
    '/api/proxy/contents/alerts',
    '/api/proxy/selfcare/health/tp-card/details',
    '/api/proxy/selfcare/health/tp-card',
    '/api/proxy/selfcare/notification-token',
    '/api/proxy/selfcare/subscription/bank-details/contributions',
    '/api/proxy/security/user/account/eligibility',
    '/api/proxy/security/user/notifications/token',
    '/api/proxy/security/statistics/visit',
];

const EXCLUDED_MESSAGES_PATTERNS = [
    /\/api\/proxy\/selfcare\/signature\/.*\/.*/g,
    /\/api\/proxy\/provident\/.*\/.*/g,
    /\/api\/proxy\/security\/user\/account\/selfcare-campaigns\/.*/g,
    /\/api\/proxy\/guarantees\/levels\/subscription\/.*/g,
    /\/api\/proxy\/services\/articles\/.*/g,
    /\/api\/proxy\/services\/sections\/.*/g,
    /\/api\/proxy\/selfcare\/health\/refunds?.*/g,
    /\/api\/proxy\/selfcare\/messages?.*/g,
    /\/api\/proxy\/europ-assistance.*/g,
    /\/api\/proxy\/healthcare-access\/appointments\/notifications-count.*/g,
];

function useErrorInterceptor() {
    const router = useRouter();
    const [state, setState] = React.useState<UseInterceptorObject>(null);
    function onError(err: any) {
        const { status, config } = err.response;
        const urlOnError = config.url;

        if (status === 401) {
            setState((state: UseInterceptorObject) => ({
                ...state,
                status,
            }));
        }

        if (
            EXCLUDED_MESSAGES_ROUTES.includes(urlOnError) ||
            EXCLUDED_MESSAGES_PATTERNS.some((pattern) =>
                urlOnError.match(pattern)
            )
        ) {
            return;
        }

        setState((state: UseInterceptorObject) => {
            const hasErrorTraduction =
                interceptorMessages.hasOwnProperty(status);
            const message = hasErrorTraduction
                ? interceptorMessages[status][state.route] ||
                  interceptorMessages[status].default
                : interceptorMessages.default;

            return {
                ...state,
                status,
                message,
            };
        });

        throw err;
    }

    // Au changement de route, on reset les erreurs
    React.useEffect(() => {
        setState({
            route: router.route,
            status: null,
        });
    }, [router.route]);

    return { onError, state };
}

export { useErrorInterceptor };
