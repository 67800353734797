import { Icon, IconProps } from '@chakra-ui/react';

export default function ChevronRightIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="m14.31 11.83-6.387 5.588a1 1 0 1 0 1.317 1.505l7.248-6.341a1 1 0 0 0 0-1.505L9.24 4.735A1 1 0 1 0 7.923 6.24l6.388 5.59z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
