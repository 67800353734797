import {
    Badge,
    BadgeProps,
    Button,
    ButtonProps,
    Flex,
    Heading,
    HStack,
    Stack,
    Text,
    TextProps,
} from '@chakra-ui/react';
import { Tag, TagProps } from '../Tag';
import * as icons from 'design-system/icons';
import { ReactNode } from 'react';

type BadgeMiniCTA = BadgeProps & {
    iconAfter?: string;
    iconBefore?: string;
};

type MiniCTAProps = {
    icon?: string;
    title?: ReactNode;
    titleProps?: TextProps;
    extraDescription?: ReactNode;
    description?: ReactNode;
    redirectLinkProps?: ButtonProps;
    redirectLinkText?: string;
    showChevron?: boolean;
    buttonText?: string;
    buttonLinkProps?: ButtonProps;
    tags?: TagProps[];
    badge?: BadgeMiniCTA;
    onClick?: () => void;
};

export default function MiniCTA({
    icon,
    title,
    titleProps,
    extraDescription,
    description,
    redirectLinkProps,
    redirectLinkText,
    showChevron = true,
    onClick,
    buttonText,
    buttonLinkProps,
    tags,
    badge,
}: MiniCTAProps) {
    const Icon = icons[icon];
    const IconAfterBadge = badge?.iconAfter && icons[badge.iconAfter];
    const IconBeforeBadge = badge?.iconBefore && icons[badge.iconBefore];

    return (
        <HStack
            alignItems="flex-start"
            border="1px solid"
            borderColor="strokes.medium"
            cursor={onClick || showChevron ? 'pointer' : 'default'}
            data-testid="MiniCTA"
            placeItems="center"
            justifyContent="space-between"
            spacing="6"
            borderRadius="md"
            py="4"
            px="6"
            onClick={onClick}
            _hover={{
                bg: 'gray.100',
            }}
            transition="ease-in-out all 0.3s">
            <HStack gap="4">
                {Icon && (
                    <Flex bg="primary.50" rounded="full" p="2">
                        <Icon h="7" w="7" color="primary.main" />
                    </Flex>
                )}
                <Stack spacing="2">
                    {(badge || extraDescription) && (
                        <HStack>
                            {badge && (
                                <Badge
                                    variant="subtle"
                                    rounded="full"
                                    {...badge}>
                                    <HStack gap="1">
                                        {badge.iconBefore && (
                                            <IconBeforeBadge />
                                        )}
                                        <Text>{badge.defaultValue}</Text>
                                        {badge.iconAfter && <IconAfterBadge />}
                                    </HStack>
                                </Badge>
                            )}
                            {extraDescription && (
                                <Text
                                    color="texts.medium"
                                    fontSize="sm"
                                    fontWeight="bold">
                                    {extraDescription}
                                </Text>
                            )}
                        </HStack>
                    )}

                    {(title ||
                        description ||
                        buttonText ||
                        redirectLinkText ||
                        tags) && (
                        <Stack gap="1" alignItems="baseline">
                            {title && (
                                <Heading
                                    fontSize="lg"
                                    fontWeight="semibold"
                                    {...titleProps}>
                                    {title}
                                </Heading>
                            )}
                            {description && <Text>{description} </Text>}
                            {buttonText && (
                                <Button
                                    {...buttonLinkProps}
                                    colorScheme="primary"
                                    size="sm"
                                    w="fit-content">
                                    {buttonText}
                                </Button>
                            )}
                            {redirectLinkText && (
                                <Button
                                    {...redirectLinkProps}
                                    variant="link"
                                    colorScheme={'primary'}
                                    fontSize="sm"
                                    rightIcon={<icons.ArrowRightIcon />}
                                    fontWeight="normal">
                                    {redirectLinkText}
                                </Button>
                            )}
                            {tags && (
                                <HStack data-testid="tag-container">
                                    {tags.map((tag) => (
                                        <Tag key={tag.formattedText} {...tag} />
                                    ))}
                                </HStack>
                            )}
                        </Stack>
                    )}
                </Stack>
            </HStack>
            {showChevron && (
                <icons.ChevronRightIcon data-testid="chevron-icon" />
            )}
        </HStack>
    );
}
