import { Icon, IconProps } from '@chakra-ui/react';

export default function ProfesionnalLegalProtectionIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 4H4C4 2.78945 4.37378 1.7004 5.33891 0.95755C6.24711 0.258525 7.51407 0 9 0C10.4859 0 11.7529 0.258525 12.6611 0.95755C13.6262 1.7004 14 2.78945 14 4H17C17.5523 4 18 4.44772 18 5V17C18 17.5523 17.5523 18 17 18H1C0.447716 18 0 17.5523 0 17V5C0 4.44772 0.447715 4 1 4ZM6.55879 2.54245C6.22468 2.7996 6 3.21055 6 4H12C12 3.21055 11.7753 2.7996 11.4412 2.54245C11.0502 2.24147 10.3171 2 9 2C7.68286 2 6.94982 2.24147 6.55879 2.54245ZM2 8V6H16V8H2ZM2 10V16H16V10H14V11C14 11.5523 13.5523 12 13 12C12.4477 12 12 11.5523 12 11V10H6V11C6 11.5523 5.55228 12 5 12C4.44772 12 4 11.5523 4 11V10H2Z"
                fill="currentColor"
            />
        </Icon>
    );
}
