import { Flex, Button, Stack } from '@chakra-ui/react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { coreSharedMessages, Heading, LinkIntl } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ArrowRightIcon } from 'design-system/icons';
import { SuccessBalloon } from 'core/components/register/icons';

const intlMessages = defineMessages({
    title: {
        id: 'components.reset-password.success.title',
        defaultMessage: 'Votre mot de passe a bien été réinitialisé',
    },
    description: {
        id: 'components.reset-password.success.description',
        defaultMessage:
            'Vous pouvez dès à présent vous connecter avec votre nouveau mot de passe.',
    },
});

type ResetPasswordSuccessProps = {
    loginPath: string;
};

export function ResetPasswordSuccess({ loginPath }: ResetPasswordSuccessProps) {
    const { isMobile } = useWindowBreakpoints();

    return (
        <Stack spacing="8" alignItems="center" maxW="full">
            <Heading as="h1">
                <FormattedMessage {...intlMessages.title} />
            </Heading>

            <SuccessBalloon
                w={56}
                h={56}
                my="8"
                data-testid="success-balloon-icon"
            />

            <FormattedMessage {...intlMessages.description} />

            <Flex w="full" justifyContent="center">
                <LinkIntl href={loginPath} passHref>
                    <Button
                        as="a"
                        width={isMobile && 'full'}
                        type="submit"
                        colorScheme="primary"
                        rightIcon={<ArrowRightIcon w="4" h="4" />}>
                        <FormattedMessage {...coreSharedMessages.backToLogin} />
                    </Button>
                </LinkIntl>
            </Flex>
        </Stack>
    );
}
