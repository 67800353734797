import { Flex, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import { uniqueId } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';

export type TabsProps = {
    tabs: TabProps[];
};

export default function Tabs({ tabs }: TabsProps) {
    const [activeTab, setActiveTab] = React.useState<TabProps>(tabs?.[0]);

    return (
        <Stack>
            <HStack data-testid="Tabs-container">
                {tabs.map((tab) =>
                    tabs.length > 1 ? (
                        <Tab
                            key={uniqueId()}
                            tab={tab}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                    ) : (
                        <Heading as="h3" key={uniqueId()} fontSize="xl">
                            {tab.label}
                        </Heading>
                    )
                )}
            </HStack>
            {activeTab.component}
        </Stack>
    );
}

type TabProps = {
    label: string;
    component: React.ReactElement;
};

type TabContainerProps = {
    tab: TabProps;
    activeTab: TabProps;
    setActiveTab: Dispatch<SetStateAction<TabProps>>;
};

function Tab({ tab, activeTab, setActiveTab }: TabContainerProps) {
    const isActive = tab === activeTab;
    const focusStyles = {
        bg: 'bg.colorextralight',
        borderColor: 'transparent',
    };

    return (
        <Flex
            data-testid="Tab"
            role="tab"
            bg={isActive && 'grey.200'}
            rounded="full"
            border="1px solid"
            borderColor={isActive ? 'transparent' : 'strokes.medium'}
            py="3"
            px="5"
            cursor="pointer"
            onClick={() => setActiveTab(tab)}
            _hover={focusStyles}>
            <Text
                fontWeight="semibold"
                color={isActive ? 'texts.main' : 'gray.800'}>
                {tab.label}
            </Text>
        </Flex>
    );
}
