import { Icon, IconProps } from '@chakra-ui/react';

export default function InfoIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M12 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM12 11a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm2 0a8 8 0 1 1 16 0 8 8 0 0 1-16 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
