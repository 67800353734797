export const SIGNATURE_STEPS: { [x: string]: string[] } = {
    signature_cotisation: [
        'document-preview',
        'send-verification-code',
        'verification-code',
        'confirmation',
    ],
    'signature-health-subscription': [
        'summary',
        'document-preview',
        'send-verification-code',
        'verification-code',
        'confirmation',
    ],
    'signature-beneficiaries-designation.standard': [
        'document-preview',
        'send-verification-code',
        'verification-code',
        'confirmation',
    ],
    'signature-beneficiaries-designation.specific-guided': [
        'capital-repartition',
        'add-beneficiaries',
        'document-preview',
        'send-verification-code',
        'verification-code',
        'confirmation',
    ],
    signature_exemption: [
        'welcome',
        'exemption-reasons',
        'exemption-form',
        'document-preview',
        'send-verification-code',
        'verification-code',
        'confirmation',
    ],
    'legal-documents': ['welcome', 'form', 'document-preview', 'confirmation'],
};
