import React, { useCallback } from 'react';
import {
    translatePathWithParam,
    getPreviousUrlParams,
    UtilsContext,
    fetchUser,
    userPaths,
    Site,
    postNotificationToken,
} from 'core';
import { useRouter } from 'next/router';
import { QueryFunctionContext, useQueryClient } from '@tanstack/react-query';
import { UrlObject } from 'url';
import { AdherentUser } from '../types/user';
import { Logger } from '../../../../scripts';

function useRedirect() {
    const { routes, siteConfig } = React.useContext(UtilsContext);
    const router = useRouter();

    const redirect = useCallback(
        (
            path: string | UrlObject,
            redirectAction: 'push' | 'replace' = 'push'
        ) => {
            return router[redirectAction](
                translatePathWithParam(
                    routes,
                    path,
                    router.locale,
                    siteConfig.homePagePath
                )
            );
        },
        []
    );

    return {
        redirect,
    };
}

function useMFARedirect() {
    const { redirect } = useRedirect();
    const {
        siteConfig: { packageName, client_id },
    } = React.useContext(UtilsContext);

    function shouldRedirect(res: any) {
        const params = res?.data?.error?.errors?.[0]?.parameters;

        if (!params || !params.hasOwnProperty('mfa_token')) return false;

        return client_id.includes('mercer') && res.status === 403;
    }

    function onError(res: any) {
        if (shouldRedirect(res)) {
            const { mfa_token } = res.data.error.errors[0].parameters;

            if (packageName === 'adherent') {
                return redirect({
                    pathname: '/mfa/[token]',
                    query: { token: mfa_token },
                });
            } else {
                return redirect('/mfa');
            }
        }
    }

    return { onMFAError: onError };
}

function usePasswordUpgradeRedirect() {
    const { redirect } = useRedirect();

    function onError(res: any) {
        if (res?.status === 426) {
            const { next_step, upgrade_token } =
                res.data.error?.meta || res.data.meta;
            const { iri, workflow, username } = next_step;

            if (!upgrade_token) {
                Logger.error('`upgrade_token` is missing.');
                return;
            }

            switch (workflow) {
                case 'username_upgrade':
                    redirect({
                        pathname: '/username-upgrade/[username]/[token]',
                        query: {
                            username: username,
                            token: upgrade_token,
                        },
                    });
                    break;

                case 'password_upgrade':
                    if (!iri) {
                        Logger.error('`iri` is missing.');
                        return;
                    }

                    redirect({
                        pathname: '/password-upgrade/[token]',
                        query: { iri, token: upgrade_token },
                    });
                    break;
            }

            return;
        }
    }

    return { onPasswordError: onError };
}

function useGeneralConditionsRedirect() {
    const { redirect } = useRedirect();

    function onError(res: any) {
        if (res?.status === 426) {
            const {
                next_step: { workflow },
                gcu_acceptance_renewal_token: token,
            } = res.data.error?.meta || res.data.meta;

            if (!token) {
                Logger.error('`gcu_acceptance_renewal_token` is missing.');
                return;
            }

            if (workflow === 'renew_gcu_acceptance')
                redirect({
                    pathname: '/auth/general-conditions',
                    query: {
                        token,
                    },
                });
        }
    }

    return { onGeneralConditionsError: onError };
}

function usePhoneUpgradeRedirect() {
    const { redirect } = useRedirect();

    function onError(res: any) {
        if (res?.status === 426) {
            const {
                next_step: { workflow },
                upgrade_token: token,
            } = res.data.error?.meta || res.data.meta;

            if (!token) {
                Logger.error('`upgrade_token` is missing.');
                return;
            }

            if (workflow === 'phone_upgrade')
                redirect({
                    pathname: '/auth/phone-upgrade',
                    query: {
                        token,
                    },
                });
        }
    }

    return { onPhoneUpgradeError: onError };
}

function useFormRedirect() {
    const router = useRouter();
    const { redirect } = useRedirect();
    const { onMFAError } = useMFARedirect();
    const { onPasswordError } = usePasswordUpgradeRedirect();
    const { onGeneralConditionsError } = useGeneralConditionsRedirect();
    const { onPhoneUpgradeError } = usePhoneUpgradeRedirect();
    const queryClient = useQueryClient();
    const previousUrlParams = getPreviousUrlParams(
        router.query?.redirect as string
    );
    const {
        siteConfig: { packageName, homePagePath, onboardingVersion },
        hooks: { useHandleSubmitResponse },
    } = React.useContext(UtilsContext);
    const {
        handleSubmitResponse,
        resetSubmitResponseStates,
        errorMessage,
        shouldShowToast,
    } = useHandleSubmitResponse();

    async function redirectAfterLogin(
        redirectAction: 'push' | 'replace' = 'push',
        scope?: string
    ) {
        if (packageName === 'adherent') {
            if (scope === 'primo') {
                redirect('/primo', redirectAction);
                return;
            } else {
                if (onboardingVersion === 1) {
                    const user: AdherentUser.UserInfos = (await fetchUserQuery(
                        packageName
                    )) as AdherentUser.UserInfos;

                    const { previousLoginDate } = user?.user || {};

                    if (user?.user && !previousLoginDate) {
                        redirect('/onboarding', redirectAction);
                        return;
                    }
                } else {
                    fetchUserQuery(packageName);
                }

                redirect(previousUrlParams ?? homePagePath, redirectAction);
                return;
            }
        } else if (
            packageName === 'enterprise-admin' ||
            packageName === 'selfcare-rh'
        ) {
            fetchUserQuery(packageName);
            redirect(previousUrlParams ?? homePagePath, redirectAction);
            return;
        }
    }

    async function fetchUserQuery(packageName: Site.Package) {
        try {
            queryClient.clear();
            return await queryClient.fetchQuery({
                queryKey: [userPaths[packageName], true],
                queryFn: () =>
                    fetchUser({
                        queryKey: [userPaths[packageName], true],
                    } as QueryFunctionContext<string[]>),
            });
        } catch (err) {
            return null;
        }
    }

    async function onSuccess(data: { [key: string]: any }) {
        resetSubmitResponseStates();
        const hasAction = handleSubmitResponse(data, 'sessionCanExpire');
        const scope = data?.data?.scope;

        if (!hasAction) {
            switch (true) {
                case scope === 'default':
                case scope === 'primo':
                case !scope: {
                    if (packageName === 'adherent') {
                        postNotificationToken();
                    }
                    redirectAfterLogin('push', scope);
                    break;
                }
                default:
                    break;
            }
        }
    }

    function onError(err: any) {
        const res = err.response ?? err;
        onMFAError(res);
        onGeneralConditionsError(res);
        onPasswordError(res);
        onPhoneUpgradeError(res);
    }

    return {
        redirect,
        redirectAfterLogin,
        errorMessage,
        shouldShowToast,
        onSuccess,
        onError,
    };
}

export { useRedirect, useFormRedirect };
