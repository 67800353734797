import React from 'react';
import { useIntl } from 'react-intl';
import {
    Box,
    Flex,
    Grid,
    GridItem,
    HStack,
    Img,
    SimpleGrid,
    Stack,
    keyframes,
} from '@chakra-ui/react';
import { coreSharedMessages } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { uniqueId } from 'lodash';
import { CrossIcon, GridIcon } from 'design-system/icons';

export type MenuLinksLayoutProps = {
    isExpanded: boolean;
    setIsExpanded: (value: boolean) => void;
    showMenuButton: boolean;
    logoPath?: string;
};

export function MenuLinksLayout({
    children,
    isExpanded = false,
    setIsExpanded,
    showMenuButton,
    logoPath,
}: React.PropsWithChildren & MenuLinksLayoutProps) {
    const { isSmallDevice } = useWindowBreakpoints();

    return isSmallDevice ? (
        <MobileMenuLinksLayout
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            showMenuButton={showMenuButton}>
            {children}
        </MobileMenuLinksLayout>
    ) : (
        <DesktopMenuLinksLayout logoPath={logoPath}>
            {children}
        </DesktopMenuLinksLayout>
    );
}

function DesktopMenuLinksLayout({
    children,
    logoPath,
}: React.PropsWithChildren & Pick<MenuLinksLayoutProps, 'logoPath'>) {
    const { formatMessage } = useIntl();

    function _renderLogo() {
        return (
            <Box alignSelf="center">
                <Img
                    data-testid="menu-logo"
                    color="white"
                    src={logoPath}
                    alt={formatMessage(coreSharedMessages.logoAlt)}
                    h="50px"
                />
            </Box>
        );
    }

    return (
        <Stack spacing="12" h="100%">
            {_renderLogo()}
            <Stack
                py="1"
                overflow="hidden"
                direction="column"
                spacing="3"
                flex="1"
                alignItems="center"
                h="full">
                {children}
            </Stack>
        </Stack>
    );
}

const revealAnimation = keyframes({
    from: { opacity: 0 },
    to: { opacity: 1 },
});

function MobileMenuLinksLayout({
    children,
    isExpanded,
    setIsExpanded,
    showMenuButton,
}: React.PropsWithChildren & Omit<MenuLinksLayoutProps, 'logoPath'>) {
    const _renderExpandedMobileMenu = () => {
        const menuEntries = React.Children.toArray(children);
        const nbEntriesOnLastRow =
            (menuEntries.filter((entry) => !!entry).length - 1) % 3;

        return (
            <Stack
                h="full"
                w="full"
                spacing="4"
                justifyContent="space-between"
                animation={`${revealAnimation} 0.5s ease-in`}>
                <Grid
                    templateColumns="repeat(3, 1fr)"
                    w="full"
                    gap="4"
                    alignItems="flex-start"
                    justifyContent="space-around">
                    {menuEntries.slice(0, -1).map((menuEntry) => (
                        <GridItem key={uniqueId()} justifySelf="center">
                            {menuEntry}
                        </GridItem>
                    ))}
                    {Array.from({ length: 2 - nbEntriesOnLastRow })?.map(() => (
                        <GridItem key={uniqueId()} />
                    ))}
                    <GridItem justifySelf="center">
                        {menuEntries[menuEntries.length - 1]}
                    </GridItem>
                </Grid>

                <SimpleGrid columns={3} py="4">
                    <GridItem />
                    <GridItem
                        onClick={() => setIsExpanded(false)}
                        justifySelf="center">
                        <CrossIcon color="white" w="8" h="8" />
                    </GridItem>
                </SimpleGrid>
            </Stack>
        );
    };

    const _renderMobileMenuButton = () => {
        return (
            <Flex
                alignItems="center"
                justifyContent="center"
                onClick={() => setIsExpanded(!isExpanded)}
                rounded="full"
                w="70px"
                h="10"
                px="4">
                <GridIcon color="white" w="6" h="6" />
            </Flex>
        );
    };

    const _renderCollapsedMobileMenu = () => {
        const menuEntries = React.Children.toArray(children);

        return (
            <HStack
                w="full"
                spacing="2"
                alignItems="center"
                justifyContent="space-around">
                {menuEntries.map((menuEntry, idx) => (
                    <React.Fragment key={uniqueId()}>
                        {showMenuButton &&
                            idx === 2 &&
                            _renderMobileMenuButton()}
                        {menuEntry}
                    </React.Fragment>
                ))}
            </HStack>
        );
    };

    return isExpanded
        ? _renderExpandedMobileMenu()
        : _renderCollapsedMobileMenu();
}
