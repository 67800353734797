import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import {
    ArrowDownRightIcon,
    ArrowRightIcon,
    ArrowUpRightIcon,
} from 'design-system/icons';

type ArrowIndicatorState = 'up' | 'down' | 'equal';
type ArrowIndicatorProps = FlexProps & {
    state: ArrowIndicatorState;
    size?: 'small' | 'normal' | 'large';
};
type ArrowIconProps = {
    state: ArrowIndicatorState;
    size: string;
};

const ARROW_INDICATOR_SIZES = {
    small: 16,
    normal: 20,
    large: 24,
};

function ArrowIcon({ state, size }: ArrowIconProps) {
    switch (state) {
        case 'up':
            return <ArrowUpRightIcon w={size} h={size} color="white" />;
        case 'down':
            return <ArrowDownRightIcon w={size} h={size} />;
        case 'equal':
            return <ArrowRightIcon w={size} h={size} />;
        default:
            return <>No Icon found</>;
    }
}

function ArrowIndicator({
    state = 'equal',
    size = 'normal',
    ...boxProps
}: ArrowIndicatorProps) {
    const computedSize = `${ARROW_INDICATOR_SIZES[size]}px`;
    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="sm"
            color="white"
            w={computedSize}
            h={computedSize}
            bg={
                state === 'equal'
                    ? 'secondary.500'
                    : state === 'down'
                    ? 'orange.500'
                    : 'tertiary.main'
            }
            {...boxProps}>
            <ArrowIcon state={state} size={computedSize} />
        </Flex>
    );
}

export { ArrowIndicator };
export type { ArrowIndicatorProps };
