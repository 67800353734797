import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import type { FormObject, FetchQueryParams, APIError } from 'core';

const path = `/api/proxy/employee-beneficiary-designation/[workflow]/employee-information`;

async function fetchForm({ queryKey: [urn, workflow] }: FetchQueryParams) {
    try {
        const response = await axios.options(
            `${urn.replace('[workflow]', workflow)}`
        );
        return response.data.data as FormObject;
    } catch (error) {
        throw { code: error?.response?.status };
    }
}

export function useFormBeneficiaryDesignation(workflow: string) {
    return useQuery<FormObject, APIError>({
        queryKey: [path, workflow],
        queryFn: fetchForm,
    });
}
