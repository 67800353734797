import { defineMessages } from 'react-intl';

export const healthCareMessages = defineMessages({
    title: {
        id: 'home.healthcare-services.title',
        defaultMessage: 'Ma sphère médicale dédiée',
    },
    appointmentTitle: {
        id: 'home.healthcare-services.teleconsultation-appointment-workflow.title',
        defaultMessage: 'Échanger avec un médecin ou un psychologue',
    },
    appointmentDescription: {
        id: 'home.healthcare-services.teleconsultation-appointment-workflow.description',
        defaultMessage:
            'Simple et rapide, je réalise une consultation à distance.',
    },
    preventionTitle: {
        id: 'home.healthcare-services.prevention-workflow.title',
        defaultMessage: 'Réaliser un bilan de prévention',
    },
    preventionDescription: {
        id: 'home.healthcare-services.prevention-workflow.description',
        defaultMessage:
            'Je prends rendez-vous pour faire le point sur ma santé.',
    },
    homeCareTitle: {
        id: 'home.healthcare-services.home-care-workflow.title',
        defaultMessage: 'Trouver une infirmière à domicile',
    },
    homeCareDescription: {
        id: 'home.healthcare-services.home-care-workflow.description',
        defaultMessage:
            'Je suis mis en relation avec une infirmière près de chez moi.',
    },
});
