import { SatisfactionProvider } from 'lib/satisfaction';
import { SatisfactionModal } from './SatisfactionModal';

export function SatisfactionApp() {
    return (
        <SatisfactionProvider>
            <SatisfactionModal />
        </SatisfactionProvider>
    );
}
