import { Icon, IconProps } from '@chakra-ui/react';

export default function TrashIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M9.74 1.952a.983.983 0 0 0-.503.137.944.944 0 0 0-.357.375l-.984 1.897h-4.14a.976.976 0 0 0-.675.27.918.918 0 0 0-.285.662c0 .25.104.489.285.663.18.173.424.269.675.269h.762V19.07c0 .793.328 1.552.909 2.11.58.556 1.366.868 2.183.868h8.782c.817 0 1.602-.311 2.183-.869.58-.557.909-1.316.909-2.108V6.224h.761a.976.976 0 0 0 .676-.27.918.918 0 0 0 .285-.662.918.918 0 0 0-.285-.662.976.976 0 0 0-.676-.27h-4.142l-.34-.112-.91-1.785a.944.944 0 0 0-.357-.375.983.983 0 0 0-.502-.137H9.739zm.59 1.864-.285.545h3.911l-.285-.545h-3.34zM6.44 6.225V19.07c0 .292.12.575.339.784.219.21.518.33.832.33h8.782c.314 0 .613-.12.832-.33.218-.21.339-.492.339-.784V6.225H6.439z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                d="M9.05 19.161a.635.635 0 0 0 .46-.2.7.7 0 0 0 .19-.481V7.957a.7.7 0 0 0-.19-.482.635.635 0 0 0-.46-.2.635.635 0 0 0-.46.2.7.7 0 0 0-.19.482V18.48a.711.711 0 0 0 .19.482.65.65 0 0 0 .46.2zM12.075 19.161a.635.635 0 0 0 .46-.2.699.699 0 0 0 .19-.481V7.957a.7.7 0 0 0-.19-.482.635.635 0 0 0-.46-.2.635.635 0 0 0-.46.2.7.7 0 0 0-.19.482V18.48a.71.71 0 0 0 .19.482.646.646 0 0 0 .46.2zM15.559 18.962a.635.635 0 0 1-.709.148.65.65 0 0 1-.21-.148.685.685 0 0 1-.191-.482V7.957a.7.7 0 0 1 .19-.482.635.635 0 0 1 .46-.2c.172 0 .338.072.46.2a.7.7 0 0 1 .19.482V18.48a.7.7 0 0 1-.19.482z"
                fill="currentColor"
            />
        </Icon>
    );
}
