import { Icon, IconProps } from '@chakra-ui/react';

export default function StethoscopeIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M6 3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v4a6.002 6.002 0 0 0 5 5.917V16c0 3.401 3.002 6 6.5 6s6.5-2.599 6.5-6v-.17a3.001 3.001 0 1 0-2 0V16c0 2.122-1.923 4-4.5 4S9 18.122 9 16v-3.083A6.002 6.002 0 0 0 14 7V3a1 1 0 0 0-1-1h-2a1 1 0 1 0 0 2h1v3a4 4 0 0 1-8 0V4h1a1 1 0 0 0 1-1zm13 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
