import { Icon, IconProps } from '@chakra-ui/react';

export default function ChevronBottomIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M12 14.481 6.411 8.094a1 1 0 1 0-1.505 1.317l6.341 7.248a1 1 0 0 0 1.506 0l6.341-7.248a1 1 0 1 0-1.505-1.317L12 14.481z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
