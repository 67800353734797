import merged from 'deepmerge';

export async function getTheme(client: string, variant?: string) {
    const baseTheme = (await import(`./base/index`)).default;

    if (client === 'optisantis') return baseTheme;

    try {
        const siteThemeWithVariant = (
            await import(`./${client}/${variant}/index`)
        ).default;
        return merged(baseTheme, siteThemeWithVariant);
    } catch (e) {
        throw new Error(
            `You must provide a  valid \`variant\` name: ${client}/${variant}`
        );
    }
}
