import axios from 'axios';
import { FormNextStep, FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';
import { GuaranteesLevels } from '../types';

const path = `/api/proxy/guarantees/levels/subscription/[id]`;

async function fetchGuaranteesLevel({ queryKey: [urn, id] }: FetchQueryParams) {
    try {
        const res = await axios.get(urn.replace('[id]', id));
        return res.data;
    } catch (error) {
        throw new Error(error);
    }
}

function useGuaranteesLevels(id: string) {
    return useQuery<
        {
            data: GuaranteesLevels;
            meta: { next_step: FormNextStep };
        },
        Error
    >({
        queryKey: [path, id],
        queryFn: main.fetchGuaranteesLevel,
    });
}

const main = {
    useGuaranteesLevels,
    fetchGuaranteesLevel,
};

export { fetchGuaranteesLevel, useGuaranteesLevels };
export default main;
