import {
    Button,
    SimpleGrid,
    HStack,
    LinkBox,
    LinkOverlay,
    BoxProps,
} from '@chakra-ui/react';
import { LinkIntl, SafeFormattedMessage, FormWorkflow } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { min } from 'lodash';
import { useRouter } from 'next/router';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { UrlObject } from 'url';

type FooterButton = {
    href?: string | UrlObject;
    labelTranslationKey: MessageDescriptor;
    disabled?: boolean;
    onClick?: (data?: any) => any;
    isLoading?: boolean;
    loadingText?: string;
    leftIcon?: React.ReactElement;
    type?: 'button' | 'submit' | 'reset';
};

type SignatureFooterButtonsProps = {
    cancelButton: FooterButton;
    submitButton: FooterButton;
    containerProps?: BoxProps;
};

const baseContainerProps = {
    spacing: 4,
    my: 4,
};

export default function SignatureFooterButtons({
    cancelButton,
    submitButton,
    containerProps,
}: SignatureFooterButtonsProps) {
    const { isMobile } = useWindowBreakpoints();
    const nbButtons = [cancelButton, submitButton].filter(
        (btn) => !!btn
    ).length;

    return isMobile ? (
        <SimpleGrid
            {...baseContainerProps}
            {...containerProps}
            columns={min([nbButtons, 2])}
            dir="horizontal">
            <Buttons {...{ cancelButton, submitButton }} />
        </SimpleGrid>
    ) : (
        <HStack
            {...baseContainerProps}
            {...containerProps}
            justifyContent="flex-end"
            mx="0">
            <Buttons {...{ cancelButton, submitButton }} />
        </HStack>
    );
}

type ButtonsProps = {
    cancelButton: FooterButton;
    submitButton: FooterButton;
};

function Buttons({ submitButton, cancelButton }: ButtonsProps) {
    const router = useRouter();
    const procedureId = router.query?.procedureId as string;
    const workflow = router.query?.workflow as FormWorkflow;

    return (
        <>
            {cancelButton &&
                (cancelButton?.href ? (
                    <Button
                        as={LinkIntl}
                        href={cancelButton.href}
                        passHref
                        bg="grey.200"
                        variant="ghost"
                        px="8">
                        <FormattedMessage
                            {...cancelButton.labelTranslationKey}
                        />
                    </Button>
                ) : (
                    <Button
                        bg="grey.200"
                        variant="ghost"
                        px="8"
                        onClick={cancelButton?.onClick}>
                        <FormattedMessage
                            {...cancelButton.labelTranslationKey}
                        />
                    </Button>
                ))}

            {submitButton?.disabled || !submitButton?.href ? (
                <SubmitButton submitButton={submitButton} />
            ) : (
                <LinkBox>
                    <LinkOverlay
                        as={LinkIntl}
                        //@ts-ignore
                        href={
                            typeof submitButton.href === 'string'
                                ? {
                                      pathname: submitButton.href,
                                      query: procedureId
                                          ? { procedureId, workflow }
                                          : { workflow },
                                  }
                                : submitButton.href
                        }
                        passHref>
                        <SubmitButton submitButton={submitButton} />
                    </LinkOverlay>
                </LinkBox>
            )}
        </>
    );
}

type SubmitButtonProps = {
    submitButton: FooterButton;
};

function SubmitButton({ submitButton }: SubmitButtonProps) {
    const { isMobile } = useWindowBreakpoints();

    return (
        <Button
            as={submitButton?.href || submitButton.disabled ? 'a' : 'button'}
            type={submitButton?.type}
            isLoading={submitButton?.isLoading}
            loadingText={submitButton?.loadingText}
            w={isMobile && 'full'}
            colorScheme="primary"
            px="8"
            leftIcon={submitButton.leftIcon}
            disabled={submitButton.disabled}
            onClick={submitButton.onClick}
            cursor="pointer">
            <SafeFormattedMessage
                {...submitButton.labelTranslationKey}
                debugKey={`Signature submitButton: ${submitButton.labelTranslationKey}`}
            />
        </Button>
    );
}
