import React from 'react';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import { Button, Flex, HStack } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from 'design-system/icons';
import { useDate } from './Date/context';
import { usePicker } from './Picker/context';
import { getDecade } from './utils';
import { YEARS_RANGE } from './consts';

function SwitcherMonth() {
    const { picker, setPicker, options: pickerOptions } = usePicker();
    const { current } = useDate();

    return (
        <Button
            type="button"
            color="texts.main"
            disabled={pickerOptions.max === picker}
            onClick={() => setPicker('month')}>
            {capitalize(dayjs().month(current.month).format('MMMM'))}
        </Button>
    );
}

function SwitcherYear() {
    const { picker, setPicker, options: pickerOptions } = usePicker();
    const { current } = useDate();

    return (
        <Button
            type="button"
            color="texts.main"
            disabled={pickerOptions.max === picker}
            onClick={() => setPicker('year')}>
            {dayjs().year(current.year).format('YYYY')}
        </Button>
    );
}

function SwitcherDecade() {
    const { current } = useDate();
    const decade = React.useMemo(() => getDecade(current.year), [current.year]);

    return <div>{`${decade} - ${decade + (YEARS_RANGE - 1)}`}</div>;
}

export function Switcher() {
    const { picker } = usePicker();
    const { setCurrent } = useDate();
    const ICON_SIZE = 4;

    function handlePrev() {
        switch (picker) {
            case 'date': {
                setCurrent({ type: 'PREV_MONTH' });
                break;
            }
            case 'month': {
                setCurrent({ type: 'PREV_YEAR' });
                break;
            }
            case 'year': {
                setCurrent({ type: 'PREV_DECADE' });
                break;
            }
            default: {
                throw new Error(`Unhandle picker type: ${picker}`);
            }
        }
    }

    function handleNext() {
        switch (picker) {
            case 'date': {
                setCurrent({ type: 'NEXT_MONTH' });
                break;
            }
            case 'month': {
                setCurrent({ type: 'NEXT_YEAR' });
                break;
            }
            case 'year': {
                setCurrent({ type: 'NEXT_DECADE' });
                break;
            }
            default: {
                throw new Error(`Unhandle picker type: ${picker}`);
            }
        }
    }

    return (
        <Flex
            justifyContent="space-between"
            alignItems="center"
            h="36px"
            mb="5">
            <Button
                variant="outline"
                p="0"
                onClick={handlePrev}
                type="button"
                minW="28px"
                h="28px">
                <ChevronLeftIcon w={ICON_SIZE} h={ICON_SIZE} />
            </Button>
            <HStack>
                {picker === 'date' && <SwitcherMonth />}
                {picker !== 'year' && <SwitcherYear />}
                {picker === 'year' && <SwitcherDecade />}
            </HStack>
            <Button
                variant="outline"
                p="0"
                onClick={handleNext}
                type="button"
                minW="28px"
                h="28px">
                <ChevronRightIcon w={ICON_SIZE} h={ICON_SIZE} />
            </Button>
        </Flex>
    );
}
