import React from 'react';
import { WorkflowTypesEnum } from '../../../lib/shared';
import { SignatureErrorStep } from '../../../lib/signature';
import { ViolationMessage } from '../../../lib/forms';
import FullCapital from '../../signature/full-capital/FullCapital';
import DividedCapital from '../../signature/divided-capital/DividedCapital';

type BeneficiaryFormProps = {
    type: 'full-capital' | 'divided-capital';
    service: WorkflowTypesEnum;
    onCumulatedCapitalChange: (cumulatedCapital: number) => void;
    setSubmitError: (errorCode: number) => void;
    setErrorStep: React.Dispatch<React.SetStateAction<SignatureErrorStep>>;
    onFormError: (
        errorCode: number,
        step?: SignatureErrorStep,
        action?: 'add' | 'edit' | 'delete',
        type?: 'toast'
    ) => void;
    formErrors: ViolationMessage[];
    setFullCapitalBeneficiariesErrorType: React.Dispatch<
        React.SetStateAction<'main' | 'rank'>
    >;
};

const BeneficiaryForm = ({
    type,
    service,
    onCumulatedCapitalChange,
    setSubmitError,
    setErrorStep,
    onFormError,
    formErrors,
    setFullCapitalBeneficiariesErrorType,
}: BeneficiaryFormProps) => (
    <>
        {type === 'full-capital' ? (
            <FullCapital
                service={service}
                onError={setSubmitError}
                onBeneficiariesUpdate={setFullCapitalBeneficiariesErrorType}
                onFormError={onFormError}
                formErrors={formErrors}
            />
        ) : (
            <DividedCapital
                service={service}
                onCumulatedCapitalChange={onCumulatedCapitalChange}
                onError={(errorCode, step) => {
                    setSubmitError(errorCode);
                    step ? setErrorStep(step) : setErrorStep(undefined);
                }}
                onFormError={onFormError}
                formErrors={formErrors}
            />
        )}
    </>
);

export default BeneficiaryForm;
