import { Icon, IconProps } from '@chakra-ui/react';

export default function MailboxIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M5 5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v2h1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h1V5zm-1 5.241V19h16v-8.787l-6.758 5.95a2 2 0 0 1-2.658-.012L4 10.24zM17 5v5.19l-5.08 4.472L7 10.246V5h10z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
