import { PatternValidations } from '../types';
import { isEmpty } from 'lodash';

function formatQuantifier(number: number) {
    return number !== undefined ? `{${number},}` : '*';
}

const specialCharsPattern =
    /["«»‹›“”„'‘’‚…!¡?¿()\[\]{}¨´`\^ˆ~˜.#*,.:;·•¯‾\-–—_\|¦†‡§¶©®™&@\/\\◊♠♣♥♦←↑→↓↔¥\$€¢£¤ƒαΑβΒγΓδΔεΕζΖηΗθΘιΙκΚλΛμΜνΝξΞοΟπΠρΡσςΣτΤυΥφΦχΧψΨωΩ°µ<>≤≥=≈≠≡±−\+×÷⁄%‰¼½¾¹²³ºªƒ′″∂∏∑√∞¬∩∫]/g;

function createPasswordPatternWithParams(params: PatternValidations) {
    if (isEmpty(params)) return;

    if (
        params.hasOwnProperty('numberOfRequiredCapitalLetters') ||
        params.hasOwnProperty('numberOfRequiredNumbers') ||
        params.hasOwnProperty('numberOfRequiredSpecialCharacters')
    ) {
        return new RegExp(
            `^(?=.*[a-z])(?=.*[A-Z]${formatQuantifier(
                params.numberOfRequiredCapitalLetters
            )})(?=.*[0-9]${formatQuantifier(
                params.numberOfRequiredNumbers
            )})(?=.*${specialCharsPattern.source}${formatQuantifier(
                params.numberOfRequiredSpecialCharacters
            )})(?=.${formatQuantifier(params.lengthMin)})`
        );
    }
}

const dateWithLeapYearRegex =
    /(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})/i;

const regExs = {
    password: createPasswordPatternWithParams,
    phone: () => /^\d{2} \d{2} \d{2} \d{2} \d{2}$/,
    internationalPhone: () => /^\+{0,1}[0-9]{1,4}[0-9]{4,14}$/,
    ssn: () => /^[1-9] \d{2} \d{2} (\d{2}|\d[A-B]) \d{3} \d{3} \d{2}$/,
    ssnWith0: () => /^\d{1} \d{2} \d{2} (\d{2}|\d[A-B]) \d{3} \d{3} \d{2}$/,
    email: () => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    date: () => dateWithLeapYearRegex,
};

function patternExists(patternId: string) {
    return Boolean(regExs[patternId]);
}

function formPattern(patternId: string, params: PatternValidations = {}) {
    if (!patternExists(patternId)) return undefined;

    return regExs[patternId](params);
}

export type Pattern = keyof typeof regExs;
export { formPattern, regExs, patternExists, specialCharsPattern };
