import { BoxProps, Icon, IconProps, useTheme } from '@chakra-ui/react';
import { useWindowBreakpoints } from 'design-system/hooks';
import { Illustration } from './Illustration';

type Props = React.PropsWithChildren<BoxProps>;

function EmptyIcon(props: IconProps) {
    const { colors } = useTheme();

    return (
        <Icon viewBox="0 0 187 85" fill="none" {...props}>
            <g clipPath="url(#EmptyIcon.ClipPath)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M83.0586 37.7351L78.7934 32.6298V17.2448H71.2411V23.5901L65.4124 16.6135L28.5415 16.9525L6.17505 38.8717L6.62687 39.164H6.46552V84.7558L172.759 84.7558V50.6363L172.796 50.2142L169.064 45.7466V34.2328H163.412V38.9815L159.05 33.7603L131.457 34.0141L124.462 40.8687V34.2328H118.811V38.9815L114.448 33.7603L86.8556 34.0141L83.0586 37.7351ZM132.57 66.2889H126.266V71.8951H132.57V66.2889ZM126.266 56.5851H132.57V62.1024H126.266V56.5851ZM87.969 66.2889H81.6649V71.8951H87.969V66.2889ZM81.6649 56.5851H87.969V62.1024H81.6649V56.5851Z"
                    fill={colors.bg.light}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.4841 43.7288L65.4315 16.6603L83.7314 38.5795V84.7558H42.4841V43.7288ZM59.0412 62.0673H67.465V69.5586H59.0412V62.0673ZM67.465 49.1006H59.0412V56.4731H67.465V49.1006Z"
                    fill={colors.bg.medium}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M114.463 33.7954L97.2899 54.0525V84.7558H128.158V50.1989L114.463 33.7954ZM115.985 67.7764H109.681V73.3827H115.985V67.7764ZM109.681 58.0727H115.985V63.59H109.681V58.0727Z"
                    fill={colors.bg.medium}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M159.064 33.7954L141.891 54.0525V84.7558H172.759V50.1989L159.064 33.7954ZM160.586 67.7764H154.282V73.3827H160.586V67.7764ZM154.282 58.0727H160.586V63.59H154.282V58.0727Z"
                    fill={colors.bg.medium}
                />
                <path
                    d="M180.645 84.5874H0V84.9645H180.645V84.5874Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M28.461 77.6538C31.4727 77.6538 33.9142 72.6416 33.9142 66.4587C33.9142 60.2758 31.4727 55.2636 28.461 55.2636C25.4492 55.2636 23.0078 60.2758 23.0078 66.4587C23.0078 72.6416 25.4492 77.6538 28.461 77.6538Z"
                    fill={colors.primary.main}
                />
                <path
                    d="M28.0758 67.9367C28.3125 62.2909 28.9527 58.4019 28.9647 58.3309L29.3895 58.4033C29.3786 58.468 28.8441 61.7166 28.5733 66.5747L33.262 64.0514L33.4653 64.4338L28.5463 67.081C28.2992 71.9557 28.3322 78.3288 29.3896 84.7121L28.9646 84.7832C27.9972 78.9436 27.8824 73.1133 28.0567 68.4183L23.1263 65.7631L23.3297 65.3808L28.0758 67.9367Z"
                    fill={colors.white}
                />
                <path
                    d="M15.3585 71.0896C21.2491 71.0896 26.0243 61.2864 26.0243 49.1934C26.0243 37.1005 21.2491 27.2972 15.3585 27.2972C9.46785 27.2972 4.69257 37.1005 4.69257 49.1934C4.69257 61.2864 9.46785 71.0896 15.3585 71.0896Z"
                    fill={colors.primary[500]}
                />
                <path
                    d="M14.7968 52.4224C15.2479 41.2172 16.523 33.4714 16.5466 33.3312L16.9714 33.4036C16.9498 33.532 15.8758 40.0575 15.3523 49.7768L24.846 44.6677L25.0493 45.05L15.4042 50.2407L15.3346 50.1098C14.8347 59.6928 14.8795 72.3001 16.9715 84.9288L16.5465 85C14.6277 73.4169 14.4262 61.8529 14.7894 52.6106L14.6654 52.8435L5.02179 47.6502L5.22516 47.2679L14.7968 52.4224Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M40.6198 65.9779C48.6807 65.9779 55.2153 52.5629 55.2153 36.0146C55.2153 19.4664 48.6807 6.05139 40.6198 6.05139C32.559 6.05139 26.0244 19.4664 26.0244 36.0146C26.0244 52.5629 32.559 65.9779 40.6198 65.9779Z"
                    fill={colors.primary[500]}
                />
                <path
                    d="M42.3239 85C36.4882 49.7718 42.2649 14.6722 42.324 14.3218L42.7488 14.3942C42.6899 14.744 36.9256 49.7746 42.7489 84.9289L42.3239 85Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M53.6403 29.8919L40.4418 36.9948L40.645 37.3772L53.8435 30.2742L53.6403 29.8919Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M26.7157 33.4501L26.5123 33.8324L39.709 40.9389L39.9123 40.5566L26.7157 33.4501Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M163.232 77.6538C166.243 77.6538 168.685 72.6416 168.685 66.4587C168.685 60.2758 166.243 55.2636 163.232 55.2636C160.22 55.2636 157.778 60.2758 157.778 66.4587C157.778 72.6416 160.22 77.6538 163.232 77.6538Z"
                    fill={colors.primary.main}
                />
                <path
                    d="M163.617 67.9367C163.38 62.2908 162.74 58.4019 162.728 58.3309L162.303 58.4033C162.314 58.468 162.849 61.7166 163.119 66.5747L158.431 64.0514L158.227 64.4338L163.146 67.081C163.393 71.9557 163.36 78.3288 162.303 84.7121L162.728 84.7832C163.695 78.9436 163.81 73.1133 163.636 68.4182L168.566 65.7631L168.363 65.3808L163.617 67.9367Z"
                    fill={colors.white}
                />
                <path
                    d="M176.334 71.0896C182.225 71.0896 187 61.2864 187 49.1934C187 37.1005 182.225 27.2972 176.334 27.2972C170.444 27.2972 165.668 37.1005 165.668 49.1934C165.668 61.2864 170.444 71.0896 176.334 71.0896Z"
                    fill={colors.primary[500]}
                />
                <path
                    d="M176.896 52.4225C176.445 41.2172 175.17 33.4714 175.146 33.3312L174.721 33.4036C174.743 33.532 175.817 40.0575 176.34 49.7768L166.847 44.6676L166.643 45.05L176.288 50.2406L176.358 50.1099C176.858 59.6929 176.813 72.3001 174.721 84.9288L175.146 85C177.065 73.4169 177.266 61.8528 176.903 52.6105L177.027 52.8436L186.671 47.6502L186.467 47.2679L176.896 52.4225Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M151.073 65.9779C159.134 65.9779 165.668 52.5629 165.668 36.0146C165.668 19.4664 159.134 6.05139 151.073 6.05139C143.012 6.05139 136.477 19.4664 136.477 36.0146C136.477 52.5629 143.012 65.9779 151.073 65.9779Z"
                    fill={colors.primary[500]}
                />
                <path
                    d="M149.369 85C155.204 49.7718 149.428 14.6722 149.369 14.3218L148.944 14.3942C148.974 14.5706 150.454 23.5665 151.168 36.9503L138.052 29.8919L137.849 30.2743L151.048 37.3772L151.178 37.1325C151.869 50.2668 151.817 67.5828 148.944 84.9289L149.369 85Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M151.78 40.5565L164.977 33.4501L165.18 33.8324L151.984 40.9388L151.78 40.5565Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M63.6136 69.7734H88.457V70.5797H63.6136V69.7734Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M88.457 71.7892H63.6136V72.5955H88.457V71.7892Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M63.6136 73.805H88.457V74.6113H63.6136V73.805Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M63.5646 76.2173H88.408V77.0235H84.1328V84.6836H83.3313V77.0235H68.2405V84.6836H67.4391V77.0235H63.5646V76.2173Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M102.406 69.7734H127.249V70.5797H102.406V69.7734Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M127.249 71.7892H102.406V72.5955H127.249V71.7892Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M102.406 73.805H127.249V74.6113H102.406V73.805Z"
                    fill={colors.tintedgray[700]}
                />
                <path
                    d="M102.357 76.2173H127.2V77.0235H122.925V84.6836H122.123V77.0235H107.033V84.6836H106.231V77.0235H102.357V76.2173Z"
                    fill={colors.tintedgray[700]}
                />
            </g>
            <defs>
                <clipPath id="EmptyIcon.ClipPath">
                    <rect width="187" height="85" fill={colors.white} />
                </clipPath>
            </defs>
        </Icon>
    );
}

export function EmptyIllustration({
    children,
    height = 200,
    width = 300,
    ...boxProps
}: Props) {
    const { isMobile } = useWindowBreakpoints();
    return (
        <Illustration
            caption={children}
            gap={isMobile ? '0' : '6'}
            p="0"
            {...boxProps}>
            <EmptyIcon
                w={isMobile ? 'full' : width}
                h={height}
                p={isMobile && '6'}
            />
        </Illustration>
    );
}
