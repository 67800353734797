import { defineMessage, defineMessages } from 'react-intl';
import { oAuthMessages } from './common';

export const oAuthRegistrationMessages = {
    authorize: {
        title: {
            '400_BAD_REQUEST': oAuthMessages.defaultTitle,
            '403_WRONG_SITE': oAuthMessages.defaultTitle,
            '404_NOT_FOUND': oAuthMessages.defaultTitle,
            '500_INTERNAL_SERVER_ERROR': oAuthMessages.defaultTitle,
        },
        description: {
            '400_BAD_REQUEST': oAuthMessages['400_BAD_REQUEST'],
            '403_WRONG_SITE': defineMessage({
                id: 'components.oauth.error.403-wrong-site.description',
                defaultMessage:
                    'Ce service est momentanément indisponible. Veuillez réessayer plus tard.',
            }),
            '404_NOT_FOUND': oAuthMessages['404_NOT_FOUND'],
            '500_INTERNAL_SERVER_ERROR':
                oAuthMessages['500_INTERNAL_SERVER_ERROR'],
        },
    },
    activation: {
        title: {
            400: oAuthMessages.defaultTitle,
            401: oAuthMessages.defaultTitle,
            500: oAuthMessages.defaultTitle,
        },
        description: {
            400: defineMessage({
                id: 'components.oauth.error.registration.400-bad-request.description',
                defaultMessage:
                    'Nous vous invitons à contacter votre centre de gestion à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant : Code 125',
            }),
            401: defineMessage({
                id: 'components.oauth.error.registration.401-unauthorized.description',
                defaultMessage:
                    'Nous vous invitons à contacter votre centre de gestion à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant : Code 126',
            }),
            500: defineMessage({
                id: 'components.oauth.error.registration.500-unauthorized.description',
                defaultMessage:
                    'Nous vous invitons à contacter votre centre de gestion à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant : Code 127',
            }),
        },
    },
};

export const registrationSuccessMessages = {
    email: defineMessages({
        title: {
            id: 'components.register.success.email.title',
            defaultMessage: 'E-mail envoyé',
        },
        description: {
            id: 'components.register.success.email.description',
            defaultMessage:
                "<l>Un e-mail vous a été envoyé à l’adresse indiquée sur votre bulletin d’adhésion papier.</l><l>Cliquez sur le lien présent dans l’e-mail pour finaliser votre création de compte afin d'accéder à {appName}.</l>",
        },
    }),
    paper: defineMessages({
        title: {
            id: 'components.register.success.paper.title',
            defaultMessage: 'Courrier en cours d’envoi',
        },
        description: {
            id: 'components.register.success.paper.description',
            defaultMessage:
                '<l>Un courrier contenant des identifiants provisoires va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion papier.</l><l>Vous pourrez ensuite activer votre compte sur {appName} <i>via</i> le bouton "Première connexion", puis “J\'ai reçu des identifiants par courrier”.</l>',
        },
    }),
    'paper.email': defineMessages({
        title: {
            id: 'components.register.success.paper-email.title',
            defaultMessage: 'Courrier et e-mail envoyés',
        },
        description: {
            id: 'components.register.success.paper-email.description',
            defaultMessage:
                '<p>Vous êtes éligible à la création de deux comptes.</p><p>Pour l’un des comptes, un courrier contenant des identifiants provisoires va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion papier. Vous pourrez ensuite activer votre compte sur {appName} <i>via</i> le bouton "Première connexion", puis “J\'ai reçu des identifiants par courrier”.</p><p>Pour l’autre compte, un e-mail vous a été envoyé à l’adresse indiquée sur votre bulletin d’adhésion papier. Cliquez sur le lien présent dans l’e-mail pour finaliser la création de ce compte.</p><p>Vous pourrez ainsi accéder à vos deux espaces {appName}.</p>',
        },
    }),
    'email.email': defineMessages({
        title: {
            id: 'components.register.success.email-email.title',
            defaultMessage: 'E-mails envoyés',
        },
        description: {
            id: 'components.register.success.email-email.description',
            defaultMessage:
                '<p>Vous êtes éligible à la création de deux comptes.</p><p>Deux e-mails vous ont été envoyés à l’adresse indiquée sur vos bulletins d’adhésion papier. Cliquez sur les liens présents dans les e-mails pour finaliser la création de vos comptes.</p><p>Vous pourrez ainsi accéder à vos deux espaces {appName}.</p>',
        },
    }),
    'paper.paper': defineMessages({
        title: {
            id: 'components.register.success.paper-paper.title',
            defaultMessage: 'Courriers en cours d’envoi',
        },
        description: {
            id: 'components.register.success.paper-paper.description',
            defaultMessage:
                '<p>Vous êtes éligible à la création de deux comptes.</p><p><l>Deux courriers contenant des identifiants provisoires vont vous être envoyés à l’adresse postale indiquée sur vos bulletins d’adhésion papier.</l><l> Vous pourrez ensuite activer vos deux comptes sur {appName} <i>via</i> le bouton "Première connexion", puis “J\'ai reçu des identifiants par courrier”.</l></p><p>Vous pourrez ainsi accéder à vos deux espaces {appName}.</p>',
        },
    }),
};
