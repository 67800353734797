import type { InfiniteData } from '@tanstack/react-query';
import type { UseMessages } from 'lib/messages';
import { isStrictObject } from 'core/lib/shared/helpers/typeCheckers';

function groupInfiniteQuery<Data = any>(
    key: string,
    data?: InfiniteData<UseMessages>
) {
    if (!data?.pages) return;

    const group = data.pages.reduce((acc, curr) => {
        const value = curr[key];

        if (!value) return acc;

        // If the value has no length, this means that we are dealing
        // with an object. Therefore we have one element and don't need
        // to merge the accumulator with the value.
        if (isStrictObject(value) && !value.length) return [value];

        return [...acc, ...value];
    }, [] as Data[]);

    return group as Data[];
}

export { groupInfiniteQuery };
