import axios from 'axios';
import { APIError } from 'core';
import { useQuery } from '@tanstack/react-query';
import { BeneficiaryAccount } from '../types';

const path = `/api/proxy/security/user/beneficiary-accounts`;

async function fetchBeneficiaryAccount() {
    try {
        const response = await axios.get(path);
        return response.data.data;
    } catch (error) {
        throw { code: error?.response?.status };
    }
}

function useBeneficiaryAccount() {
    return useQuery<BeneficiaryAccount[], APIError>({
        queryKey: [path],
        queryFn: fetchBeneficiaryAccount,
    });
}

export {
    path as beneficiaryAccountPath,
    fetchBeneficiaryAccount,
    useBeneficiaryAccount,
};
