import { FormObject, WidgetKeys, WidgetProps } from 'core';
import dayjs from 'dayjs';
import { WizardType } from 'lib/form-wizard/types';
import { Care, WorkflowMedicalibData } from 'lib/home-consultation';
import { TeleconsultationCountry } from 'lib/teleconsultation';
import {
    prefillMedicalibWidget,
    prefillPreventionForm,
    prefillTeleconsultationForm,
} from 'lib/home-consultation/helpers';
import { WorkflowPreventionData } from 'lib/preventions/types';
import { WorkflowTeleconsultationData } from 'lib/teleconsultation/types';
import { WorkflowPrimoData } from 'lib/primo/types';
import { AccountContact } from 'lib/user';
import { set } from 'lodash/fp';

export const prefillContactWidgets = (
    widget: WidgetProps,
    contact: AccountContact
) => {
    const { type, defaultValue, defaultCountry } = widget;

    const { mobilePhone, email } = contact || {};

    return {
        ...widget,
        defaultValue:
            defaultValue ||
            (type === 'internationalPhoneNumber' ? mobilePhone?.value : email),
        ...(type === 'internationalPhoneNumber' && {
            defaultCountry: defaultCountry || mobilePhone?.codeNumber,
        }),
    };
};

export const prefillLocationWidgets = (
    widget: WidgetKeys,
    countries: TeleconsultationCountry[]
) => {
    const isOutsideFranceOption = (option) => option.id === '2';

    const outsideFranceOption = widget?.options?.find(isOutsideFranceOption);

    if (!outsideFranceOption) {
        return widget;
    }

    if (countries && outsideFranceOption) {
        const countriesOptions = countries.map((country) => {
            return {
                id: country.id,
                label: country.name,
                value: country.id,
            };
        });

        widget.options.find(isOutsideFranceOption).subWidget.options =
            countriesOptions;

        return widget;
    }

    return widget;
};

function formatDefaultValue(widget: any, value: any) {
    switch (true) {
        // The type need to be checked for checkboxes
        // otherwise with a `false` default value, the checkbox will be checked
        // (because its a string)
        case value === true && widget.type !== 'checkbox':
            return 'true';
        case value === false && widget.type !== 'checkbox':
            return 'false';
        case widget.type === 'date':
            return dayjs(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        // `frequency` value can be string or object
        case widget.id === 'frequency' && !!value.value:
            return value.value;
        default:
            return value;
    }
}

function formatOptions(widget: any, care: Care) {
    const value = care.options[widget.id];
    switch (widget.id) {
        //subwidget
        case 'receivedBooster':
            if (value === true) {
                return widget?.options?.map((option) => {
                    if (option.id === 'true') {
                        return set(
                            'subWidget.defaultValue',
                            care.options[option.subWidget.id],
                            option
                        );
                    }
                    return option;
                });
            }
            return widget.options;
        case 'firstPregnancy':
            if (value === false) {
                return widget?.options?.map((option) => {
                    if (option.id === 'false' && option.subWidget) {
                        return set(
                            'subWidget.defaultValue',
                            care.options[option.subWidget.id],
                            option
                        );
                    }
                    return option;
                });
            }
            return widget.options;
        case 'bandageCause':
            if (value === 'otherWound') {
                return widget?.options?.map((option) => {
                    if (option.id === value) {
                        return set(
                            'subWidget.defaultValue',
                            care.options.other,
                            option
                        );
                    }
                    return option;
                });
            }
            return widget.options;
        case 'injectionProduct':
            if (value === 'otherInjection') {
                return widget?.options?.map((option) => {
                    if (option.id === value) {
                        return set(
                            'subWidget.defaultValue',
                            care.options.other,
                            option
                        );
                    }
                    return option;
                });
            }
            return widget.options;
        case 'patientPathology':
        case 'pathology':
        case 'babyPathology':
            if (value === 'other') {
                return widget?.options?.map((option) => {
                    if (option.id === value) {
                        return set(
                            'subWidget.defaultValue',
                            care.options.other,
                            option
                        );
                    }
                    return option;
                });
            }
            return widget.options;
        case 'stoma':
            if (['urinaryTube', 'nasogastricTube'].includes(value)) {
                return widget?.options?.map((option) => {
                    if (option.id === value) {
                        return set(
                            'subWidget.defaultValue',
                            care.options[option.subWidget.id],
                            option
                        );
                    }
                    return option;
                });
            }
            return widget.options;
        case 'frequency':
            // `frequency` value can be string or object
            if (typeof value === 'object') {
                return widget?.options?.map((option) => {
                    if (option.id === value.value) {
                        return set(
                            'subWidgets',
                            option.subWidgets.map((subWidget) => {
                                return set(
                                    'defaultValue',
                                    value[subWidget.id],
                                    subWidget
                                );
                            }),
                            option
                        );
                    }
                    return option;
                });
            }
            return widget.options;
        //checkbox
        case 'selectedCares':
        case 'contact':
        case 'comment':
            return widget?.options?.map((option) => ({
                ...option,
                isDefaultValue: value.includes(option.id),
            }));
        default:
            return widget.options;
    }
}

export const prefillCaresWidgets = (
    form: FormObject,
    data: WorkflowMedicalibData
): FormObject => {
    const care = data?.cares?.find((care) => form?.id === care.careName);

    if (!care) return form;

    const widgets = form.widgets.map((widget) => ({
        ...widget,
        defaultValue: formatDefaultValue(widget, care.options[widget.id]),
        options: formatOptions(widget, care),
    }));

    return { ...form, widgets };
};

export default function prefillForm(
    widgets: WidgetKeys[],
    data:
        | WorkflowPreventionData
        | WorkflowMedicalibData
        | WorkflowTeleconsultationData
        | WorkflowPrimoData,
    workflow: WizardType
) {
    return widgets?.map((item) => {
        switch (workflow) {
            case WizardType.PREVENTION:
                prefillPreventionForm(item, data as WorkflowPreventionData);
                break;
            case WizardType.MEDICALIB:
                prefillMedicalibWidget(item, data as WorkflowMedicalibData);
                break;
            case WizardType.TELECONSULTATION_APPOINTMENT:
            case WizardType.TELECONSULTATION_TEXT_MESSAGE_EXCHANGE:
            case WizardType.TELECONSULTATION_IMMEDIATE_EXCHANGE:
                prefillTeleconsultationForm(
                    item,
                    data as WorkflowTeleconsultationData
                );
                break;
        }
        return item;
    });
}
