import { Icon, IconProps } from '@chakra-ui/react';

export default function ShareIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 25" {...props}>
            <path
                fillRule="evenodd"
                d="M18 10.5a4 4 0 1 0-3.85-2.913l-4.971 2.485a4 4 0 1 0-.011 4.87l4.934 2.657a4 4 0 1 0 .609-1.376l-4.865-2.62a4.002 4.002 0 0 0 .005-2.19l4.97-2.485A3.993 3.993 0 0 0 18 10.5zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-12 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm14 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
