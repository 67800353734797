import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { FormErrorMessage, Input, VStack } from '@chakra-ui/react';
import GooglePlacesInput from './GooglePlacesInput';
import { FormGroup } from 'core';
import { Address } from 'core/lib/forms/types/address';
import { useFormContext } from 'react-hook-form';

type ManualAddressProps = {
    id: string;
    isRequired: boolean;
    restrictions: string[];
    onChange: (address: Address | null) => void;
    defaultValue?: Address;
};

const ManualAddress = ({
    id,
    isRequired,
    restrictions,
    onChange,
    defaultValue,
}: ManualAddressProps): JSX.Element => {
    const streetNameRef = useRef(null);
    const [streetNumber, setStreetNumber] = useState<string>(
        defaultValue?.streetNumber || ''
    );
    const [streetName, setStreetName] = useState<string>(
        defaultValue?.street || ''
    );

    const manualAddressValue = useRef<Address | null>(defaultValue || null);

    const {
        formState: { errors },
    } = useFormContext();

    // Focus on the input when there's an error on the invisible input
    useEffect(() => {
        if (errors[id]) {
            if (!streetName) streetNameRef.current.focus();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    const updateManualAddressValue = (address: Partial<Address>) => {
        manualAddressValue.current = {
            ...(manualAddressValue?.current || {}),
            ...address,
        } as Address;
        if (!!streetName && !!manualAddressValue?.current?.coordinates) {
            onChange(manualAddressValue.current);
        } else {
            onChange(null);
        }
    };

    const handleInputOnChange = (
        event: ChangeEvent<HTMLInputElement>,
        callback
    ): void => {
        const value = event.target.value;

        callback(value.trimStart());
    };

    const handleInputOnBlur = (): void => {
        updateManualAddressValue({
            street: streetName,
            streetNumber: streetNumber,
        });
    };

    const handleGooglePlacesInputChange = (address: Address): void => {
        updateManualAddressValue({
            ...address,
            street: manualAddressValue?.current?.street || '',
            streetNumber: manualAddressValue?.current?.streetNumber || '',
        });
    };

    return (
        <VStack>
            <FormGroup
                id={'streetNumber'}
                label={'address.street-number.title'}
                name="streetNumber"
                isRequired={false}>
                <Input
                    type="text"
                    value={streetNumber}
                    onChange={(e) => handleInputOnChange(e, setStreetNumber)}
                    onBlur={handleInputOnBlur}
                />
            </FormGroup>
            <FormGroup
                id={'streetName'}
                label={'address.street-name.title'}
                name="streetName"
                isRequired={isRequired}
                isInvalid={!!errors[id] && !streetName}>
                <>
                    <Input
                        ref={streetNameRef}
                        type="text"
                        value={streetName}
                        onChange={(e) => handleInputOnChange(e, setStreetName)}
                        onBlur={handleInputOnBlur}
                    />

                    {errors[id] && (
                        <FormErrorMessage>
                            {errors[id].message as string}
                        </FormErrorMessage>
                    )}
                </>
            </FormGroup>
            <GooglePlacesInput
                {...{
                    id,
                    title: 'address.postal-code-country.title',
                    placeholder: '',
                    isRequired,
                    helper: '',
                    restrictions,
                    types: ['postal_code', 'locality', 'political'],
                    minChar: 1,
                    onChange: handleGooglePlacesInputChange,
                    defaultValue,
                    isManualAddress: true,
                }}
            />
        </VStack>
    );
};

export default ManualAddress;
