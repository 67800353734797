import { Icon, IconProps } from '@chakra-ui/react';

export default function PlaneRightUpIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M21.296 5.146c.812-1.707-.979-3.483-2.68-2.658L3.465 9.843c-1.457.707-1.513 2.763-.096 3.548l4.698 2.605L10.5 20.46c.782 1.435 2.86 1.377 3.562-.098l7.234-15.215zm-12.252 8.79-4.03-2.259L17.21 5.771l-8.166 8.166zM10.104 15l2.121 3.889L18.261 6.84l-8.157 8.158z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
