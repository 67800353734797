import { CountryCode } from 'libphonenumber-js';
import { WidgetKeys, prefillWidget } from 'core';
import { WorkflowPreventionData } from 'lib/preventions/types';

function getWidgetDefaultValue(item: WidgetKeys, data: WorkflowPreventionData) {
    switch (item.type) {
        case 'text':
        case 'email':
        case 'beneficiarySelectTeleconsultation':
        case 'radio':
        case 'phoneNumber':
            return data[item.id]?.value || data[item.id];
        case 'internationalPhoneNumber':
            return {
                defaultValue: data?.phone?.value,
                defaultCountry: data?.phone?.country as CountryCode,
            };
        case 'conditionalRadio':
            if (item.id === 'preferredCanal') {
                return {
                    defaultValue: data[item?.id] === 'Video' ? '2' : '1',
                    subDefaultValue: data.recallPhoneNumber?.value,
                };
            }

            return data[item.id]?.value;
        default:
            break;
    }
}

export function prefillPreventionForm(
    item: WidgetKeys,
    data: WorkflowPreventionData
) {
    prefillWidget(item, getWidgetDefaultValue(item, data));
}
