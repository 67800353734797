import React, { ReactElement } from 'react';
import {
    FilterType,
    FormValues,
    useFiltersForm,
    useFormValidation,
    WidgetProps,
    DashboardContext,
    SelectedFilterText,
    BaseIri,
} from 'core';
import { HStack, Skeleton, Stack, StackProps, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useWindowBreakpoints } from 'design-system/hooks';
import FiltersBadges from './FiltersBadges';
import FiltersModal from './FiltersModal';
import SearchFilter from './SearchFilter';

const filterType: { [key: string]: BaseIri } = {
    exemptions: 'employer-exemption',
    companies: 'pe-admin',
    groups: 'pe-admin',
    users: 'pe-admin',
    roles: 'pe-admin',
    structures: 'pe-admin',
    environments: 'pe-admin',
    clauses: 'pe-admin',
    beneficiaryDesignation: 'pe-admin',
    beneficiaryDesignationCompanies: 'pe-admin',
    employees: 'pe-admin',
    'legal-documents': 'pe-admin',
    'srh-contracts': 'srh-admin',
    'srh-users': 'srh-admin',
};

type FiltersProps = StackProps & {
    type: FilterType;
    showFilters: boolean;
    id?: string;
    searchWidgetId?: string;
    children?: ReactElement;
};

export function Filters({
    type,
    showFilters = true,
    id,
    searchWidgetId = 'search',
    children = null,
    ...props
}: FiltersProps) {
    const { isSmallDevice } = useWindowBreakpoints();
    const { data: filtersForms, isFetching } = useFiltersForm(type, id);
    const { methods } = useFormValidation(
        filterType[type],
        filtersForms?.iri,
        filtersForms?.widgets
    );
    const { isFiltersOpened, setSelectedFilters } =
        React.useContext(DashboardContext);
    const searchWidget = filtersForms?.widgets?.find(
        (widget) => widget.id === searchWidgetId
    );

    React.useEffect(() => {
        return () => setSelectedFilters([]);
    }, [setSelectedFilters]);

    const handleSearch = (formData: FormValues) => {
        if (!formData?.[searchWidgetId]?.value) return;

        const searchFilter: SelectedFilterText = {
            name: searchWidget.id,
            type: 'text',
            values: [formData[searchWidgetId]?.value],
        };

        setSelectedFilters((prev) => [
            ...prev.filter((filter) => filter.name !== searchFilter.name),
            searchFilter,
        ]);
    };

    const _renderSkeletons = (): JSX.Element => (
        <VStack alignItems={'start'}>
            <Skeleton
                h={6}
                w={isSmallDevice ? '60%' : '448px'}
                borderRadius={'full'}
            />
            <HStack>
                {Array.from({ length: 5 }).map((_, idx) => (
                    <Skeleton
                        key={idx}
                        h={5}
                        w={isSmallDevice ? 16 : 56}
                        borderRadius={'full'}
                    />
                ))}
            </HStack>
        </VStack>
    );

    const handleKeyUp = (event): void => {
        // remove the focus when the user hits enter
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    };

    return (
        <>
            <HStack
                w={'100%'}
                py="5"
                px={isSmallDevice ? '6' : '4'}
                bg={!isSmallDevice && 'white'}
                justifyContent={'space-between'}>
                {showFilters && (
                    <Stack w={'100%'} {...props}>
                        {isFetching ? (
                            _renderSkeletons()
                        ) : (
                            <FormProvider {...methods}>
                                <Stack
                                    as="form"
                                    spacing="4"
                                    noValidate
                                    onKeyUp={handleKeyUp}
                                    onSubmit={methods.handleSubmit(
                                        handleSearch
                                    )}>
                                    {searchWidget && (
                                        <SearchFilter
                                            {...(searchWidget as WidgetProps)}
                                        />
                                    )}
                                    <FiltersBadges
                                        {...{
                                            type,
                                            methods,
                                            id,
                                            searchWidgetId,
                                        }}
                                    />
                                </Stack>
                            </FormProvider>
                        )}
                    </Stack>
                )}
                {!!children && !isSmallDevice && <Stack>{children}</Stack>}
            </HStack>
            <FiltersModal
                isOpen={isFiltersOpened && showFilters}
                {...{ type, methods, id, searchWidgetId }}
            />
        </>
    );
}
