import { Box } from '@chakra-ui/react';
import {
    SafeFormattedMessage,
    ViolationMessage,
    formErrorMessages,
} from 'core';
import Alert from 'design-system/components/Alert';
import { uniqueId } from 'lodash';
import { FormattedMessage } from 'react-intl';

type Props = React.PropsWithChildren<{
    errors?: ViolationMessage[];
}>;

type ErrorProps = { error: ViolationMessage };

function FormErrorMessage({ error }: ErrorProps) {
    if (error?.code && error.code in formErrorMessages) {
        return (
            <SafeFormattedMessage
                {...formErrorMessages[error.code]}
                debugKey={error.code}
                values={{
                    validationValue: undefined, // Fallback
                    ...error.parameters,
                }}
            />
        );
    }

    if (!error?.message || typeof error.message === 'string') {
        return (
            <FormattedMessage
                id="components.forms.error"
                defaultMessage="Le format d'un champ du formulaire n'est pas valide ; veuillez vérifier votre saisie."
            />
        );
    }

    return <FormattedMessage {...error.message} />;
}

function FormErrorTitle({ title }: Pick<ViolationMessage, 'title'>) {
    if (typeof title === 'string') {
        return <>{title}</>;
    }

    return <FormattedMessage {...title} />;
}

export function FormErrors({ errors }: Props) {
    return (
        <Box mb={6}>
            {errors?.map((error) => (
                <Alert
                    key={uniqueId()}
                    color="error"
                    icon="AlertIcon"
                    displayedTitle={
                        error.title && <FormErrorTitle title={error.title} />
                    }
                    description={<FormErrorMessage error={error} />}
                />
            ))}
        </Box>
    );
}
