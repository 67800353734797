import { FormValues } from 'core';
import { PersonalData } from '../types';

export const formatPersonalData = (
    data: FormValues
): Pick<PersonalData, 'address' | 'contact'> => ({
    address: {
        streetNumberAndName: data.address.streetNumberAndName.value,
        city: data.address.city.value,
        postalCode: data.address.postalCode.value,
        additionalAddress: data.address?.additionalAddress?.value,
        additionalAddress2: data.address?.additionalAddress2?.value,
    },
    contact: {
        email: data.contact.email.value,
        mobilePhoneNumber: data.contact?.mobilePhoneNumber?.value
            ? JSON.parse(data.contact.mobilePhoneNumber.value)?.value
            : undefined,
        secondPhoneNumber: data.contact?.secondPhoneNumber?.value
            ? JSON.parse(data.contact.secondPhoneNumber.value)?.value
            : undefined,
    },
});
