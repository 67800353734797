import React from 'react';
import { uniqueId } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
    Badge,
    Box,
    Flex,
    HStack,
    Stack,
    Text,
    useRadio,
} from '@chakra-ui/react';
import {
    SafeFormattedMessage,
    capitalizeHyphenatedName,
    formatFrenchIban,
} from 'core';
import { beneficiariesStatusMessages } from 'lib/contracts';
import { BeneficiaryOption } from 'lib/forms';
import { BeneficiarySelectProps, MainBeneficiaryType } from '.';

type BeneficiarySelectRadioButtonProps = React.PropsWithChildren<
    BeneficiaryOption & {
        radioRef: React.Ref<any> | null;
        value?: string;
        name?: string;
        onChange?:
            | undefined
            | ((e: React.ChangeEvent<HTMLInputElement>) => void);
        onBlur?: undefined | ((e: React.ChangeEvent<HTMLInputElement>) => void);
        checkedOption?: string;
        error?: Record<string, any>;
        beneficiaries: (BeneficiaryOption | MainBeneficiaryType)[];
    } & Pick<BeneficiarySelectProps, 'type'>
>;

type RadioCircleProps = {
    isChecked: boolean;
};

type BeneficiarySelectInfosProps = Pick<
    BeneficiaryOption,
    'status' | 'firstName' | 'lastName' | 'gender' | 'isSubscriber'
> & {
    getRadioProps: () => any;
    isAnotherOptionChecked?: boolean;
    type: BeneficiarySelectProps['type'];
};

const SIZE = 4;

const intlMessages = defineMessages({
    rib: {
        id: 'bank-details.current-rib',
        defaultMessage: '<b>RIB actuel :</b> {iban}',
    },
    ribConnection: {
        id: 'bank-details.connection-status.current-connection',
        defaultMessage:
            "Les remboursements s'effectuent actuellement sur le compte bancaire de <b>{linkedToBeneficiaryFirstName}</b>.",
    },
});

function getStatusColor(status: string) {
    return ['main', 'partner', 'ascendant', 'child'].includes(status)
        ? `user.${status}`
        : 'user.other';
}

function RadioCircle({ isChecked }: RadioCircleProps) {
    return (
        <Box
            flexShrink={0}
            borderRadius="100%"
            w={SIZE}
            h={SIZE}
            borderColor="strokes.medium"
            borderWidth={1}
            bg="white"
            alignItems="center"
            justifyContent="center"
            position="relative">
            <Box
                position="absolute"
                borderRadius="50%"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                w={SIZE - 2}
                h={SIZE - 2}
                bg={isChecked ? 'black' : 'white'}
            />
        </Box>
    );
}

function BeneficiarySelectInfos({
    isSubscriber,
    status,
    firstName,
    lastName,
    isAnotherOptionChecked,
    getRadioProps,
    gender,
    type,
}: BeneficiarySelectInfosProps) {
    const checkbox = getRadioProps();

    return (
        <Flex
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            {...checkbox}
            color={
                isAnotherOptionChecked
                    ? 'grey.700'
                    : isSubscriber
                    ? 'user.main'
                    : getStatusColor(status)
            }
            fontWeight="bold">
            <span>{capitalizeHyphenatedName(`${firstName} ${lastName}`)}</span>
            {type !== 'teleconsultation' && (
                <Badge
                    bg={
                        isAnotherOptionChecked
                            ? 'grey.600'
                            : getStatusColor(status)
                    }>
                    <SafeFormattedMessage
                        {...beneficiariesStatusMessages[status]}
                        values={{ gender }}
                        debugKey={`beneficiariesStatusMessages[${status}]`}
                    />
                </Badge>
            )}
        </Flex>
    );
}

function BeneficiarySelectRadioButton(
    props: BeneficiarySelectRadioButtonProps
) {
    const {
        id,
        value,
        status,
        checkedOption,
        iban,
        refundRibConnection,
        beneficiaries,
        type,
        radioRef,
        onBlur,
    } = props;
    const {
        formState: { errors },
    } = useFormContext();
    const { getInputProps, getRadioProps } = useRadio(props);
    const input = getInputProps({ ref: radioRef });
    const isChecked = checkedOption === value;
    const isAnotherOptionChecked = checkedOption
        ? checkedOption !== value
        : false;

    const borderColor = errors[id]
        ? 'red.500'
        : isChecked
        ? getStatusColor(status)
        : 'strokes.medium';

    return (
        <Stack
            as="label"
            cursor="pointer"
            bg="white"
            p={
                type === 'bankDetails'
                    ? '5'
                    : type === 'teleconsultation'
                    ? '2'
                    : '3'
            }
            borderWidth="1px"
            borderRadius="md"
            overflow="hidden"
            borderColor={borderColor}>
            <HStack>
                <input
                    {...input}
                    onBlur={onBlur}
                    required={false}
                    id={uniqueId()}
                />
                <RadioCircle isChecked={isChecked} />
                <BeneficiarySelectInfos
                    isSubscriber={props.isSubscriber}
                    firstName={props.firstName}
                    lastName={props.lastName}
                    gender={props.gender}
                    type={type}
                    {...{ status, isAnotherOptionChecked, getRadioProps }}
                />
            </HStack>
            {iban && (
                <Text>
                    <FormattedMessage
                        {...intlMessages.rib}
                        values={{ iban: formatFrenchIban(iban) }}
                    />
                </Text>
            )}
            {refundRibConnection && (
                <Text>
                    <FormattedMessage
                        {...intlMessages.ribConnection}
                        values={{
                            linkedToBeneficiaryFirstName:
                                capitalizeHyphenatedName(
                                    beneficiaries?.find(
                                        ({ status }) =>
                                            status === refundRibConnection
                                    )?.firstName
                                ),
                        }}
                    />
                </Text>
            )}
        </Stack>
    );
}

export default BeneficiarySelectRadioButton;
