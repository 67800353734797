import React from 'react';
import { __DEV__, contextError } from 'core/utils';

type PickerType = 'date' | 'year' | 'month';

export type PickerOptions = {
    min: PickerType;
    max: PickerType;
};

type PickerContextProps = {
    picker: PickerType;
    setPicker: React.Dispatch<React.SetStateAction<PickerType>>;
    options: PickerOptions;
};

const PickerContext = React.createContext<PickerContextProps | null>(null);

if (__DEV__) {
    PickerContext.displayName = 'PickerContext';
}

export function PickerProvider({
    children,
    options,
}: React.PropsWithChildren<{
    options?: PickerOptions;
}>) {
    const mergedOptions = React.useMemo(
        () => ({
            min: 'date' as PickerOptions['min'],
            max: 'year' as PickerOptions['max'],
            ...options,
        }),
        [options]
    );
    const [picker, setPicker] = React.useState<PickerType>(mergedOptions.min);
    const value = React.useMemo<PickerContextProps>(
        () => ({
            picker,
            setPicker,
            options: mergedOptions,
        }),
        [picker, mergedOptions]
    );
    return (
        <PickerContext.Provider value={value}>
            {children}
        </PickerContext.Provider>
    );
}

export function usePicker(): PickerContextProps {
    const context = React.useContext(PickerContext);
    if (!context) {
        throw contextError('usePicker', 'PickerProvider');
    }
    return context;
}
