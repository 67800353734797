const displayableMimeTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'text/csv',
];

export default function getDisplayableMimeTypes(mimeTypes: string[]) {
    const mimeTypesCopy = [...mimeTypes];

    return mimeTypesCopy
        .filter((type) => displayableMimeTypes.includes(type))
        .map((type) => `.${type.split('/')[1]}`.replace('jpeg', 'jpg'));
}
