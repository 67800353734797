import { Icon, IconProps } from '@chakra-ui/react';

export default function QrCodeIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 20 22" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.66667 3.33329C1.66667 2.41282 2.41286 1.66663 3.33334 1.66663H5.00001C5.92048 1.66663 6.66667 2.41282 6.66667 3.33329V4.99996C6.66667 5.92043 5.92048 6.66663 5.00001 6.66663H3.33334C2.41286 6.66663 1.66667 5.92043 1.66667 4.99996V3.33329ZM3.33334 3.33329H5.00001V4.99996H3.33334L3.33334 3.33329Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.66667 15C1.66667 14.0795 2.41286 13.3333 3.33334 13.3333H5.00001C5.92048 13.3333 6.66667 14.0795 6.66667 15V16.6666C6.66667 17.5871 5.92048 18.3333 5.00001 18.3333H3.33334C2.41286 18.3333 1.66667 17.5871 1.66667 16.6666V15ZM3.33334 15H5.00001V16.6666H3.33334L3.33334 15Z"
                fill="currentColor"
            />
            <path
                d="M17.5 12.5C17.9602 12.5 18.3333 12.8731 18.3333 13.3333V16.6666C18.3333 17.5871 17.5871 18.3333 16.6667 18.3333H13.3333C12.8731 18.3333 12.5 17.9602 12.5 17.5C12.5 17.0397 12.8731 16.6666 13.3333 16.6666H16.6667V13.3333C16.6667 12.8731 17.0398 12.5 17.5 12.5Z"
                fill="currentColor"
            />
            <path
                d="M6.66667 8.33329C6.66667 7.87306 6.29358 7.49996 5.83334 7.49996C5.3731 7.49996 5.00001 7.87306 5.00001 8.33329V9.99996H2.50001C2.03977 9.99996 1.66667 10.3731 1.66667 10.8333C1.66667 11.2935 2.03977 11.6666 2.50001 11.6666H5.00001C5.92048 11.6666 6.66667 10.9204 6.66667 9.99996V8.33329Z"
                fill="currentColor"
            />
            <path
                d="M9.16667 2.49996C9.16667 2.03972 9.53977 1.66663 10 1.66663H11.6667C12.1269 1.66663 12.5 2.03972 12.5 2.49996V5.83329C12.5 6.29353 12.1269 6.66663 11.6667 6.66663H8.33334C7.8731 6.66663 7.50001 6.29353 7.50001 5.83329C7.50001 5.37306 7.8731 4.99996 8.33334 4.99996H10.8333V3.33329H10C9.53977 3.33329 9.16667 2.9602 9.16667 2.49996Z"
                fill="currentColor"
            />
            <path
                d="M10 17.5C10 17.9602 10.3731 18.3333 10.8333 18.3333C11.2936 18.3333 11.6667 17.9602 11.6667 17.5L11.6667 11.6666H17.5C17.9602 11.6666 18.3333 11.2935 18.3333 10.8333C18.3333 10.3731 17.9602 9.99996 17.5 9.99996L10.8333 9.99996C10.3731 9.99996 10 10.3731 10 10.8333L10 17.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 1.66663C14.0795 1.66663 13.3333 2.41282 13.3333 3.33329V4.99996C13.3333 5.92043 14.0795 6.66663 15 6.66663H16.6667C17.5871 6.66663 18.3333 5.92043 18.3333 4.99996V3.33329C18.3333 2.41282 17.5871 1.66663 16.6667 1.66663H15ZM16.6667 3.33329H15V4.99996H16.6667V3.33329Z"
                fill="currentColor"
            />
            <path
                d="M14.1667 7.49996C13.7064 7.49996 13.3333 7.87306 13.3333 8.33329C13.3333 8.79353 13.7064 9.16663 14.1667 9.16663H17.5C17.9602 9.16663 18.3333 8.79353 18.3333 8.33329C18.3333 7.87306 17.9602 7.49996 17.5 7.49996H14.1667Z"
                fill="currentColor"
            />
            <path
                d="M8.33334 7.49996C8.79358 7.49996 9.16667 7.87306 9.16667 8.33329V10.8333C9.16667 11.2935 8.79358 11.6666 8.33334 11.6666C7.8731 11.6666 7.50001 11.2935 7.50001 10.8333V8.33329C7.50001 7.87306 7.8731 7.49996 8.33334 7.49996Z"
                fill="currentColor"
            />
            <path
                d="M9.16667 13.3333C9.16667 12.8731 8.79358 12.5 8.33334 12.5C7.8731 12.5 7.50001 12.8731 7.50001 13.3333V17.5C7.5 17.9602 7.8731 18.3333 8.33334 18.3333C8.79358 18.3333 9.16667 17.9602 9.16667 17.5V13.3333Z"
                fill="currentColor"
            />
        </Icon>
    );
}
