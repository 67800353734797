import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams, FormObject } from 'core';
import { FilterType } from '../types/common';

const paths = {
    exemptions: '/api/proxy/employer-exemption/exemptions',
    companies: '/api/proxy/pe-admin/companies/filters',
    groups: '/api/proxy/pe-admin/groups/filters',
    users: '/api/proxy/pe-admin/users/filters',
    roles: '/api/proxy/pe-admin/roles/filters',
    structures: '/api/proxy/employer-exemption/admin/structures/filters',
    environments: '/api/proxy/pe-admin/environments/filters',
    clauses:
        '/api/proxy/employer-beneficiary-designation/admin/clauses/filters',
    beneficiaryDesignation:
        '/api/proxy/employer-beneficiary-designation/beneficiary-designations/filters',
    beneficiaryDesignationCompanies:
        '/api/proxy/employer-beneficiary-designation/admin/companies/filters',
    employees:
        '/api/proxy/employer-legal-documents/documents/[id]/employees/filters',
    'legal-documents': '/api/proxy/employer-legal-documents/documents/filters',
    'srh-contracts': '/api/proxy/srh-employees/contracts/filters',
    'srh-users': '/api/proxy/srh-admin/users/filters',
};

async function fetchFiltersForm({ queryKey: [urn, _, id] }: FetchQueryParams) {
    try {
        const response = await axios.options(
            id ? urn.replace('[id]', id) : urn
        );

        const { data } = response.data;
        return {
            ...data,
            widgets: data?.widgets?.map((widget) => ({
                ...widget,
                title: null,
                ...(['dateRange', 'date'].includes(widget?.type) && {
                    useClickOutsideMode: 'form',
                }),
            })),
        };
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useFiltersForm(type: FilterType, id?: string) {
    return useQuery<FormObject, APIError>({
        queryKey: id ? [paths[type], 'OPTIONS', id] : [paths[type], 'OPTIONS'],
        queryFn: fetchFiltersForm,
    });
}
