import axios from 'axios';
import { APIError } from 'core';
import { ClauseType } from 'core/lib/signature';
import { useQuery } from '@tanstack/react-query';

const path = `/api/proxy/provident/clause`;

export async function fetchClause() {
    try {
        const response = await axios.get(path);
        const { data } = response?.data as Api<ClauseType>;

        return data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useClause() {
    return useQuery<ClauseType, APIError>({
        queryKey: [path],
        queryFn: fetchClause,
    });
}
