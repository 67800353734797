import { Dispatch, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPhoneNumber, setRef, useInputValidation } from 'core';
import InputBase from '../InputBase';
import type { WidgetProps } from './types';
import Cleave from 'cleave.js/react';
import { Select } from '@chakra-ui/react';
import { countryPhoneCodes } from 'core/lib/forms/consts/countryPhoneCodes';
import { omit } from 'lodash';
import getInputName from 'core/lib/forms/helpers/getInputName';

const MAX_INTERNATIONAL_PHONE_LENGTH = 14;
const DEFAULT_INDICATOR = '+33';

function replaceIndicator(
    currentValue: string,
    indicator: string,
    setFormattedValue: Dispatch<React.SetStateAction<string>>
) {
    const nextValue = getNextValue();
    setFormattedValue(nextValue);

    function getNextValue() {
        if (
            !currentValue ||
            (currentValue?.startsWith('+') &&
                !currentValue?.startsWith(indicator))
        ) {
            return indicator;
        }

        if (currentValue.startsWith(indicator)) {
            return currentValue;
        }

        if (
            indicator === DEFAULT_INDICATOR &&
            currentValue?.length === 10 &&
            currentValue?.charAt(0) === '0'
        ) {
            return `${indicator}${currentValue.slice(1)}`;
        }

        return currentValue.startsWith(indicator)
            ? currentValue
            : `${indicator}${currentValue}`;
    }
}

function InputPhoneNumber({
    allowInternationalPhoneNumber = false,
    ...props
}: WidgetProps & { allowInternationalPhoneNumber?: boolean }) {
    const name = getInputName(props.id, props.name);
    const { registerValues, getValues, setValue } = useInputValidation(
        props.validations,
        name,
        {
            type: allowInternationalPhoneNumber
                ? 'internationalPhone'
                : 'phone',
        }
    );
    const [formattedValue, setFormattedValue] = useState<string>(
        formatPhoneNumber.loose(props?.defaultValue, ' ') || ''
    );
    const [isDefaultValueSet, setIsDefaultValueSet] = useState(
        !!props?.defaultValue
    );
    const [indicator, setIndicator] = useState<string>(
        !props?.defaultValue ||
            props?.defaultValue.startsWith(DEFAULT_INDICATOR)
            ? DEFAULT_INDICATOR
            : ''
    );
    const blocks = useMemo(
        () => (!allowInternationalPhoneNumber ? [2, 2, 2, 2, 2] : undefined),
        [allowInternationalPhoneNumber]
    );
    const maxLength = useMemo(
        () =>
            !allowInternationalPhoneNumber
                ? blocks.reduce((acc, curr) => acc + curr, 0) + blocks.length
                : MAX_INTERNATIONAL_PHONE_LENGTH,
        [blocks, allowInternationalPhoneNumber]
    );

    const currentValue = getValues()[props?.id]?.value;

    useEffect(() => {
        if (allowInternationalPhoneNumber) {
            replaceIndicator(
                isDefaultValueSet ? currentValue : formattedValue,
                indicator,
                setFormattedValue
            );
        }
    }, [
        allowInternationalPhoneNumber,
        indicator,
        currentValue,
        name,
        setFormattedValue,
        props?.defaultValue,
        isDefaultValueSet,
        setIsDefaultValueSet,
        formattedValue,
    ]);

    useEffect(() => {
        if (!props?.defaultValue) return;

        const defaultValue = formatPhoneNumber.loose(props?.defaultValue, ' ');
        setFormattedValue(defaultValue);
        setValue(name, defaultValue);
    }, [props?.defaultValue, name, setValue]);

    function _renderInternationalPhoneCodes() {
        return (
            <Select
                onChange={(e) => setIndicator(e.target.value)}
                w="48"
                defaultValue={indicator}>
                <option value="">
                    <FormattedMessage
                        id="widgets.phone-number.country.undefined"
                        defaultMessage="Pays"
                    />
                </option>
                {countryPhoneCodes?.map((countryPhoneCode) => (
                    <option
                        key={countryPhoneCode?.code}
                        value={countryPhoneCode?.dial_code}>
                        {countryPhoneCode?.name}
                    </option>
                ))}
            </Select>
        );
    }

    function updateState(e) {
        registerValues.onChange(e);

        if (!allowInternationalPhoneNumber) {
            return setFormattedValue(e.target.value);
        }

        replaceIndicator(e.target.value, indicator, setFormattedValue);
    }

    return (
        <InputBase
            {...omit(props, 'defaultValue')}
            name={name}
            as={Cleave}
            value={
                allowInternationalPhoneNumber
                    ? formattedValue
                    : !!currentValue || currentValue === ''
                      ? currentValue
                      : props.defaultValue
            }
            onChange={updateState}
            onBlur={updateState}
            onFocus={updateState}
            maxLength={maxLength}
            type="tel"
            inputMode="tel"
            autoComplete="tel-national"
            htmlRef={(input: HTMLInputElement) =>
                setRef(registerValues.ref, input)
            }
            options={{
                blocks: blocks,
                numericOnly: allowInternationalPhoneNumber ? false : true,
            }}>
            {allowInternationalPhoneNumber && _renderInternationalPhoneCodes()}
        </InputBase>
    );
}

export default InputPhoneNumber;
