import { Icon, IconProps } from '@chakra-ui/react';

export default function DoubleCheckIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="m11.82 14.387 4.285-4.977a1 1 0 1 0-1.515-1.305l-6.46 7.503-3.723-3.052a1 1 0 1 0-1.268 1.547l4.477 3.67a1 1 0 0 0 1.392-.12l1.507-1.75 2.138 1.853a1 1 0 0 0 1.415-.106l7.042-8.24a1 1 0 1 0-1.52-1.3l-6.388 7.475-1.382-1.198z"
                fill="currentColor"
            />
        </Icon>
    );
}
