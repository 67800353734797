import {
    validationFormsMessages,
    Validations,
    FormatMessageFn,
    specialCharsPattern,
} from 'core/lib/forms';

const ALLOW_TYPES = {
    numbers: {
        regex: /\d/,
        intlKey: 'allowNumbers',
    },
    letters: {
        regex: /[a-zA-ZáÁâÂàÀåÅãÃäÄæÆçÇéÉêÊèÈëËíÍîÎìÌïÏñÑóÓôÔòÒøØõÕöÖœŒšŠßðÐþÞúÚûÛùÙüÜýÝÿŸ]/,
        intlKey: 'allowLetters',
    },
    specialCharacters: {
        regex: specialCharsPattern,
        intlKey: 'allowSpecialCharacters',
    },
};

type AllowType = keyof typeof ALLOW_TYPES;

type Allow =
    | Validations['allowNumbers']
    | Validations['allowLetters']
    | Validations['allowSpecialCharacters'];

type ValidateValueFn = (value: string) => undefined | string;

type ValidateSubFn = (
    allow: Allow,
    formatMessage: FormatMessageFn
) => ValidateValueFn;

type ValidateAllowFn = (type: AllowType) => ValidateSubFn;

const validateAllow: ValidateAllowFn =
    (type) =>
    (allow, formatMessage) =>
    (value: string = '') => {
        if (allow !== false) return undefined;

        const { regex, intlKey } = ALLOW_TYPES[type];

        // `regexp.test` return can change even is the params are same (when using `g` flag)
        // see: https://stackoverflow.com/a/13587801
        return value.match(regex)
            ? formatMessage(validationFormsMessages[intlKey])
            : undefined;
    };

const validateAllowNumbers = validateAllow('numbers');
const validateAllowLetters = validateAllow('letters');
const validateAllowSpecialCharacters = validateAllow('specialCharacters');

export {
    validateAllow,
    validateAllowNumbers,
    validateAllowLetters,
    validateAllowSpecialCharacters,
};
