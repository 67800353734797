import { Icon, IconProps } from '@chakra-ui/react';

export default function ArrowDownLeftIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="m8.508 14.078 7.028-7.028a1 1 0 1 1 1.414 1.414l-7.028 7.028 4.545.54a1 1 0 0 1-.235 1.986l-6.593-.782a1 1 0 0 1-.875-.876l-.782-6.592a1 1 0 0 1 1.986-.235l.54 4.545z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
