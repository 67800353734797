import { WorkflowTypesEnum } from '../../../lib/shared';

export type SignatureRouterBaseIri =
    | '/exemptions'
    | '/beneficiary-designations'
    | '/legal-documents'
    | '';

export const serviceTypes = {
    [WorkflowTypesEnum.PA]: 'provident',
    [WorkflowTypesEnum.PE]: 'employee-beneficiary-designation',
};
