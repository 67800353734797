import React, { useMemo } from 'react';
import { debounce } from 'lodash';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $getRoot, EditorState } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';

const debounceTime = 300;

type onChangeType = (htmlContent: string, contentSize: number) => void;

type CustomOnChangePluginType = {
    onChange: onChangeType;
};

const removeStyle = (htmlContent: string): string => {
    const attributeRegex = /(?:style|class)="[^"]*"/g; // Regular expression to match both style and class attributes
    return htmlContent.replace(attributeRegex, '');
};

export const CustomOnChangePlugin = ({
    onChange,
}: CustomOnChangePluginType) => {
    const [editor] = useLexicalComposerContext();
    const customOnChange = useMemo(() => {
        return debounce(
            (editorState: EditorState) =>
                editorState.read(() => {
                    const root = $getRoot();
                    onChange(
                        removeStyle($generateHtmlFromNodes(editor)),
                        root.getTextContentSize()
                    );
                }),

            debounceTime
        );
    }, [onChange, editor]);

    return <OnChangePlugin onChange={customOnChange} />;
};
