import axios from 'axios';
import { APIError } from 'core';
import { useQuery } from '@tanstack/react-query';
import { Risk } from '../types';

const path = `/api/proxy/provident/risks`;

export async function fetchRisks() {
    try {
        const response = await axios.get(path);
        const { data } = response?.data as Api<Risk>;

        return data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useRisks() {
    return useQuery<Risk, APIError>({ queryKey: [path], queryFn: fetchRisks });
}
