import { Box } from '@chakra-ui/react';

const paragraph = (chunks) => (
    <Box as="p" pb="2">
        {chunks}
    </Box>
);

const line = (chunks) => (
    <>
        {chunks}
        <br />
    </>
);

const tab = (chunks) => (
    <>
        <Box ml={5} pb={2}>
            {chunks}
        </Box>
    </>
);
const breakline = () => <br />;
const italic = (chunks) => <i>{chunks}</i>;
const bold = (chunks) => <strong>{chunks}</strong>;
const superscript = (chunks) => <sup>{chunks}</sup>;
const subscript = (chunks) => <sub>{chunks}</sub>;
const nbsp = () => String.fromCharCode(160);

export const richTextElements = {
    p: paragraph,
    br: breakline,
    l: line,
    b: bold,
    i: italic,
    sup: superscript,
    sub: subscript,
    nbsp,
    tab,
};
