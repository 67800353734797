import {
    Flex,
    HStack,
    Popover,
    PopoverTrigger,
    PopoverBody,
    PopoverContent,
    Box,
    Container,
    PopoverAnchor,
} from '@chakra-ui/react';
import { get, uniqueId } from 'lodash';
import { useRouter } from 'next/router';
import { defineMessage, useIntl } from 'react-intl';

import { useRoving } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import siteConfig from 'lib/site/config.json';
import { useEligibility, sharedMessages } from 'lib/shared';
import {
    CalendarIcon,
    EuroIcon,
    MessageIcon,
    MenuIcon,
    CrossIcon,
    HomeIcon,
} from 'design-system/icons';
import UserInfo from './UserInfo';
import MenuItem from './MenuItem';
import { pageTitles } from 'lib/site';
import NavbarSkeleton from './Skeleton';
import Logo from './Logo';
import { useEffect, useRef } from 'react';
import { useHealthCareAppointmentsNotifications } from 'lib/health-care/api';

type MenuLinksProps = {
    isPopoverOpen: boolean;
    onClosePopover: () => void;
};

type NarBarContainerProps = {
    backgroundColor?: string;
};

type NavBarProps = {
    backgroundColor?: string;
};

const intlMessage = defineMessage({
    id: 'components.layouts.navbar.role',
    defaultMessage: 'Menu principal',
});

const labelMessages = {
    news: sharedMessages.news,
    agenda: sharedMessages.agenda,
    refunds: sharedMessages.myRefunds,
    contract: sharedMessages.contracts,
    messages: pageTitles.messages,
    services: sharedMessages.services,
    account: sharedMessages.myAccount,
};

const icons = {
    HomeIcon,
    CalendarIcon,
    EuroIcon,
    MessageIcon,
    MenuIcon,
};

const MENU_HEIGHT_DESKTOP_PX = '68px';
const MENU_HEIGHT_MOBILE_PX = '60px';

const MAX_BADGE_COUNT = 9;

const parseBadgeCount = (count: number | undefined) =>
    count > 0
        ? String(count > MAX_BADGE_COUNT ? `${MAX_BADGE_COUNT}+` : count)
        : undefined;

const MenuLinks = ({ isPopoverOpen, onClosePopover }: MenuLinksProps) => {
    const { formatMessage } = useIntl();
    const router = useRouter();
    const { isMobile, isDesktop } = useWindowBreakpoints();
    const { data: eligibility } = useEligibility();
    const { menu } = siteConfig;
    const ref = useRef(null);
    const links = menu.links.filter((link) => {
        const isShown =
            link.show === 'always' ||
            link.show === (isMobile ? 'mobile' : 'desktop');

        const isEligible = link.eligibilities?.length
            ? link.eligibilities.every((linkEligibility) =>
                  get(eligibility, linkEligibility)
              )
            : true;
        return isShown && isEligible;
    });

    const agendaEligibilities = links.find(
        (link) => link.to === '/agenda'
    )?.eligibilities;
    const enableAppointementsNotificationsQuery = agendaEligibilities
        ? agendaEligibilities.every((linkEligibility) =>
              get(eligibility, linkEligibility)
          )
        : false;
    const { data: appointmentsNotificationCount } =
        useHealthCareAppointmentsNotifications(
            enableAppointementsNotificationsQuery
        );

    const BADGES_PER_LINK = {
        ['/agenda']: parseBadgeCount(appointmentsNotificationCount),
    };

    const menuItemCount = links.length + 1; // Links menu + user info submenu
    const defaultFocus = links.map((link) => link.to).indexOf(router.pathname);
    const rovingGroup = Array.from({ length: menuItemCount }, (_, i) => i);
    const { currentFocus, setCurrentFocus, handleKeyDown } = useRoving(
        rovingGroup,
        'horizontal',
        defaultFocus || 0
    );

    useEffect(() => {
        // On small devices, the UserInfo component has scroll
        // On this case we need to scroll to the top of the element
        if (isMobile && isPopoverOpen && ref.current)
            ref.current.scrollTop = -100;
    }, [isMobile, isPopoverOpen]);

    useEffect(() => {
        router.events.on('routeChangeStart', onClosePopover);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeStart', onClosePopover);
        };
    }, [router, onClosePopover]);

    return (
        <HStack
            spacing={0}
            align="center"
            h="full"
            {...(isMobile ? { w: 'full' } : { justify: 'center' })}>
            {links?.map((link, idx) => (
                <MenuItem
                    setCurrentFocus={setCurrentFocus}
                    handleKeyDown={handleKeyDown}
                    isFocused={currentFocus === idx}
                    id={idx}
                    to={link.to}
                    Icon={icons[link.icon] || CalendarIcon}
                    ariaLabel={formatMessage(labelMessages[link.label])}
                    noLeftBorder={isMobile || idx !== 0}
                    noRightBorder={isMobile}
                    badge={BADGES_PER_LINK[link.to]}
                    {...(isMobile ? { flex: '1 1 0' } : {})}
                    key={uniqueId()}
                />
            ))}
            <PopoverTrigger>
                <Box h="full" {...(isMobile ? { flex: '1 1 0' } : {})}>
                    <MenuItem
                        setCurrentFocus={setCurrentFocus}
                        handleKeyDown={handleKeyDown}
                        isFocused={currentFocus === links.length}
                        id={links.length}
                        Icon={isPopoverOpen ? CrossIcon : MenuIcon}
                        ariaLabel={formatMessage(labelMessages.account)}
                        noLeftBorder={isMobile}
                        noRightBorder
                    />
                </Box>
            </PopoverTrigger>
            <PopoverContent
                w="full"
                ml={isDesktop && '4'}
                maxW="861px"
                borderRadius={0}
                boxShadow="md">
                <PopoverBody p={0}>
                    <UserInfo
                        {...(isMobile && isPopoverOpen
                            ? {
                                  h: `calc(100vh - ${MENU_HEIGHT_MOBILE_PX})`,
                              }
                            : {})}
                        ref={ref}
                    />
                </PopoverBody>
            </PopoverContent>
        </HStack>
    );
};

const NavBarContainer = ({
    children,
    backgroundColor = 'white',
}: React.PropsWithChildren<NarBarContainerProps>) => {
    const { isMobile } = useWindowBreakpoints();
    const { formatMessage } = useIntl();

    return (
        <Flex
            as="nav"
            aria-label={formatMessage(intlMessage)}
            role="navigation"
            w="100%"
            bottom={isMobile ? '0' : 'none'}
            top={!isMobile ? '0' : 'none'}
            position={isMobile ? 'fixed' : 'sticky'}
            zIndex={11}
            bg={isMobile ? 'white' : backgroundColor}
            borderBottomColor={!isMobile ? 'strokes.medium' : 'none'}
            borderBottomWidth={!isMobile ? '1px' : '0px'}
            filter={isMobile && 'drop-shadow(0px -2px 16px rgba(0, 0, 0, 0.1))'}
            h={isMobile ? MENU_HEIGHT_MOBILE_PX : MENU_HEIGHT_DESKTOP_PX}>
            {children}
        </Flex>
    );
};

const NavBar = ({ backgroundColor }: NavBarProps) => {
    const { isMobile } = useWindowBreakpoints();
    const { status } = useEligibility();

    return (
        <NavBarContainer backgroundColor={backgroundColor}>
            <Popover
                placement={isMobile ? 'top' : 'bottom-end'}
                gutter={0}
                matchWidth>
                {({ isOpen, onClose }) => (
                    <PopoverAnchor>
                        <Container
                            py={0}
                            px={isMobile ? '0' : '4'}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            position="relative">
                            {status === 'pending' ? (
                                <NavbarSkeleton />
                            ) : (
                                <>
                                    {!isMobile && <Logo />}
                                    <MenuLinks
                                        isPopoverOpen={isOpen}
                                        onClosePopover={onClose}
                                    />
                                </>
                            )}
                        </Container>
                    </PopoverAnchor>
                )}
            </Popover>
        </NavBarContainer>
    );
};

export default NavBar;
