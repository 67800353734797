import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { debounce, uniqBy } from 'lodash';
import {
    Box,
    Flex,
    Input,
    InputGroup,
    InputRightElement,
    Spinner,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { CrossIcon, SearchIcon } from 'design-system/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    coreSharedMessages,
    useSafeIntl,
    formMessages,
    useClickOutside,
    errorToastsMessages,
    SafeFormattedMessage,
    getExtraLabel,
} from 'core';
import { CheckboxListProps, CheckboxProps } from '.';
import SelectedBadges from './SelectedBadges';
import { KeyBoardEventsEnum } from '../../../consts';
import { Checkboxes } from './Checkboxes';
import { ChangeHandler } from 'react-hook-form';

type SearchDropDownProps = Pick<
    CheckboxListProps,
    'options' | 'id' | 'validations' | 'queryParameters' | 'allowAllCheck'
> & {
    onChange: ChangeHandler;
    onBlur: ChangeHandler;
    name: string;
    autoCompleteUri: string | null;
    value: string[];
    hasExtraInfo: boolean;
    setValue: (value: string[]) => void;
};

export function SearchDropdown({
    options,
    id,
    validations,
    name,
    autoCompleteUri,
    queryParameters,
    value,
    setValue,
    allowAllCheck,
    hasExtraInfo,
}: SearchDropDownProps) {
    const checkboxContainerRef = useRef<HTMLDivElement>(null);
    const [searchValue, setSearchValue] = useState('');
    const [isAutoComplete] = useState(!!autoCompleteUri);
    const [filteredOptions, setFilteredOptions] = useState<CheckboxProps[]>([]);
    const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(false);
    const [focusedOption, setFocusedOption] = useState<number>(-1);
    const [hasError, setHasError] = useState<number | null>(null);
    const { formatMessage } = useIntl();
    const { safeFormatMessage } = useSafeIntl();
    const { isOpen, onOpen, onClose, getDisclosureProps } = useDisclosure();
    const disclosureProps = getDisclosureProps();
    const clickOutsideRef = useClickOutside(onClose, 'form');

    useEffect(() => {
        setFocusedOption(-1);
    }, [filteredOptions]);

    useEffect(() => {
        if (!value?.length) setSearchValue('');
    }, [value]);

    useEffect(() => {
        const cleanedSearchValue = searchValue.trim();

        const filteredOptions = options.map((option) => {
            const searchedLabel = option.isTranslatable
                ? safeFormatMessage(
                      formMessages[option.label],
                      undefined,
                      `CheckboxList - SearchDropdown - ${option.label}`
                  )
                : option.label;

            const extraLabel = getExtraLabel(option.extra, safeFormatMessage);

            return {
                ...option,
                hidden:
                    cleanedSearchValue.length > 0 &&
                    !`${searchedLabel} ${extraLabel}`
                        .toLowerCase()
                        .includes(cleanedSearchValue.toLowerCase()),
            };
        });

        setFilteredOptions(() => uniqBy([...filteredOptions], 'id'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, options]);

    useEffect(() => {
        const cleanedSearchValue = searchValue.trim();
        if (
            !isAutoComplete ||
            cleanedSearchValue.length < validations.lengthMin
        )
            return;
        const fetchOptions = async () => {
            setIsOptionsLoading(true);
            try {
                const queryParams = queryParameters.reduce((acc, filter) => {
                    acc[filter.key] = filter.value;
                    return acc;
                }, {});
                const { data } = await axios.get(
                    `/api/proxy${autoCompleteUri.replace(
                        '{searchText}',
                        cleanedSearchValue
                    )}`,
                    { params: queryParams }
                );
                setFilteredOptions((prev) =>
                    uniqBy(
                        [
                            ...prev.map((prevOption) => ({
                                ...prevOption,
                                hidden: data?.data.indexOf(prevOption) === -1,
                            })),
                            ...(data?.data || []),
                        ],
                        'id'
                    )
                );
                setIsOptionsLoading(false);
                setHasError(null);
            } catch (error) {
                setIsOptionsLoading(false);
                setHasError(error.response?.status);
            }
        };

        const debouncedFetch = debounce(fetchOptions, 300);
        debouncedFetch();

        return () => {
            debouncedFetch.cancel();
        };
        // The other suggested props/states are not really need as dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    //AutoScroll when the user uses the up/down keyboard
    useEffect(() => {
        if (!checkboxContainerRef.current) return;

        checkboxContainerRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        });
    }, [focusedOption]);

    const handleOnSelect = (option: CheckboxProps): void => {
        const transformedOption = JSON.stringify({
            id: option.id,
            label: option.label,
            extraLabel: getExtraLabel(option.extra, safeFormatMessage),
        });

        const foundIndex = value.findIndex(
            (option) => option === transformedOption
        );

        const nextValue = [...value];

        if (foundIndex === -1) nextValue.unshift(transformedOption);
        else nextValue.splice(foundIndex, 1);

        setValue(nextValue);
    };

    const handleKeyBoardEvents = (e: KeyboardEvent<HTMLInputElement>): void => {
        const { key } = e;

        if (!(Object.values(KeyBoardEventsEnum) as string[]).includes(key))
            return;

        const displayedOptions = filteredOptions.filter(
            (option) => !option.hidden
        );

        const currentFocusedOption = displayedOptions.findIndex(
            (option) => option.id === filteredOptions[focusedOption]?.id
        );

        let nextFocusedOption = currentFocusedOption;

        if (key === KeyBoardEventsEnum.ARROWDOWN) {
            nextFocusedOption =
                (nextFocusedOption + 1) % displayedOptions.length;
        }
        if (key === KeyBoardEventsEnum.ARROWUP) {
            nextFocusedOption =
                (nextFocusedOption + displayedOptions.length - 1) %
                displayedOptions.length;
        }
        if (key === KeyBoardEventsEnum.ENTER) {
            handleOnSelect(displayedOptions[currentFocusedOption]);
        }

        e.preventDefault();

        setFocusedOption(
            filteredOptions.findIndex(
                (option) =>
                    option.id === displayedOptions[nextFocusedOption]?.id
            )
        );
    };

    const displayedOptions = filteredOptions.map((option) => ({
        ...option,
        hidden: option?.hidden || isOptionsLoading,
    }));

    return (
        <Stack spacing="4" ref={clickOutsideRef}>
            <Stack spacing="1" bg="white" pb={isOpen && '1'}>
                <InputGroup>
                    <Input
                        placeholder={formatMessage(coreSharedMessages.search)}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyDown={handleKeyBoardEvents}
                        value={searchValue}
                        onFocus={onOpen}
                        autoComplete="off"
                    />
                    <InputRightElement>
                        {isAutoComplete && isOptionsLoading ? (
                            <Spinner color="primary.main" />
                        ) : !searchValue.length ? (
                            <SearchIcon data-testid="search-icon" />
                        ) : (
                            <Flex
                                px="2"
                                h="full"
                                alignItems="center"
                                cursor="pointer"
                                onClick={() => setSearchValue('')}>
                                <CrossIcon data-testid="cross-icon" />
                            </Flex>
                        )}
                    </InputRightElement>
                </InputGroup>
                <Flex
                    {...disclosureProps}
                    py={2}
                    pr={3}
                    bg="white"
                    border={'1px solid'}
                    borderColor={'strokes.medium'}
                    borderRadius={'md'}>
                    <>
                        {isAutoComplete && isOptionsLoading ? (
                            <Text color="gray.600" pl={4}>
                                <FormattedMessage
                                    {...coreSharedMessages.inProgress}
                                />
                            </Text>
                        ) : (
                            hasError && (
                                <Text color="red.600" pl={4}>
                                    <SafeFormattedMessage
                                        {...errorToastsMessages[hasError]}
                                        debugKey={`<SearchDropdown />: ${hasError}`}
                                    />
                                </Text>
                            )
                        )}

                        {displayedOptions.filter((option) => !option.hidden)
                            .length === 0 &&
                            !isOptionsLoading && (
                                <Text color="gray.600" pl={4} w={'100%'}>
                                    <FormattedMessage
                                        {...coreSharedMessages.noResult}
                                    />
                                </Text>
                            )}

                        <Box
                            w={'100%'}
                            visibility={
                                displayedOptions.length > 0
                                    ? 'visible'
                                    : 'hidden'
                            }>
                            <Checkboxes
                                options={displayedOptions.filter(
                                    (option) => !option.hidden
                                )}
                                {...{
                                    id,
                                    name,
                                    focusedOption,
                                    checkboxContainerRef,
                                    value,
                                    setValue,
                                    hasExtraInfo,
                                    allowAllCheck: !!searchValue
                                        ? false
                                        : allowAllCheck,
                                }}
                            />
                        </Box>
                    </>
                </Flex>
            </Stack>

            {!!value?.length && (
                <SelectedBadges {...{ value, setValue, hasExtraInfo }} />
            )}
        </Stack>
    );
}
