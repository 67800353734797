import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Button, Text, TextProps } from '@chakra-ui/react';
import { useWindowBreakpoints } from 'design-system/hooks';

const intlMessages = defineMessages({
    seeMore: {
        id: 'components.see-more',
        defaultMessage: 'voir plus',
    },
    seeLess: {
        id: 'components.see-less',
        defaultMessage: 'voir moins',
    },
});

export const LongText = ({ children, noOfLines, ...props }: TextProps) => {
    const { isMobile } = useWindowBreakpoints();
    const textRef = useRef<HTMLParagraphElement>();
    const [isEllipsisActive, setIsEllipsisActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const { current } = textRef;
        setIsEllipsisActive(
            !!current && current.offsetHeight < current.scrollHeight
        );
    }, []);

    useEffect(() => {
        Array.from(textRef.current?.querySelectorAll('a')).forEach((child) => {
            if (isOpen) {
                child.removeAttribute('tabIndex');
            } else {
                child.setAttribute('tabIndex', '-1');
            }
        });
    }, [isOpen]);

    return (
        <>
            <Text
                as="div"
                ref={textRef}
                {...(!isOpen && { noOfLines })}
                {...props}>
                {children}
            </Text>

            {isEllipsisActive && (
                <Button
                    size="xs"
                    mb={3}
                    onClick={() => setIsOpen(!isOpen)}
                    w={isMobile ? 'full' : 'fit-content'}>
                    {isOpen ? (
                        <FormattedMessage {...intlMessages.seeLess} />
                    ) : (
                        <FormattedMessage {...intlMessages.seeMore} />
                    )}
                </Button>
            )}
        </>
    );
};
