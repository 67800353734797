import React from 'react';
import { contextError } from 'core/utils';
import { Site } from '../../site';

type UtilsContextType =
    | {
          routes: any;
          siteConfig: Site.Config;
          hooks: { [x: string]: Function };
          layouts: {
              [x: string]: React.ElementType;
          };
      }
    | null
    | undefined;

type InitialsProps = {
    children: React.ReactNode;
    routes?: Site.RoutesConfig;
    siteConfig?: Site.Config;
    hooks?: { [x: string]: Function };
    layouts?: {
        [x: string]: React.ElementType;
    };
};

const UtilsContext = React.createContext<UtilsContextType | null>(null);

function useUtilsContext() {
    const context = React.useContext(UtilsContext);
    if (!context) {
        contextError('useUtilsContext', 'UtilsProvider');
    }
    return context;
}

function UtilsProvider({
    children,
    routes,
    siteConfig,
    hooks,
    layouts,
}: InitialsProps) {
    return (
        <UtilsContext.Provider value={{ routes, siteConfig, hooks, layouts }}>
            {children}
        </UtilsContext.Provider>
    );
}

export type { UtilsContextType };
export { UtilsContext, UtilsProvider, useUtilsContext };
