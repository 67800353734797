import { Icon, IconProps } from '@chakra-ui/react';

export default function SimulationIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 16 16" {...props}>
            <path
                fillRule="evenodd"
                d="M2.05 13.25c0 .414.336.75.75.75h10.5a.75.75 0 0 0 .75-.75V2.75A.75.75 0 0 0 13.3 2H2.8a.75.75 0 0 0-.75.75v10.5zm.75 2.25a2.25 2.25 0 0 1-2.25-2.25V2.75A2.25 2.25 0 0 1 2.8.5h10.5a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25H2.8zM7.3 11H3.55v-.75H7.3V11zM5.05 7.25v-1.5h-1.5V5h1.5V3.5h.75V5h1.5v.75H5.8v1.5h-.75zm5.095 3.375-1.06 1.06.53.531 1.06-1.06 1.061 1.06.53-.53-1.06-1.061 1.06-1.06-.53-.531-1.06 1.06-1.061-1.06-.53.53 1.06 1.061zm.906-6.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0zM8.8 5.75V5h3.75v.75H8.8zm2.25 1.125a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
