import { Box, HeadingProps } from '@chakra-ui/react';

function Heading({ children, as = 'h1', ...boxProps }: HeadingProps) {
    return (
        <Box
            as={as}
            textStyle={
                boxProps.textStyle || (typeof as === 'string' ? as : 'h1')
            }
            {...boxProps}>
            {children}
        </Box>
    );
}

export { Heading };
