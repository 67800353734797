import { Icon, IconProps } from '@chakra-ui/react';

export default function ArchiveIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M7.112 5a2 2 0 0 0-1.623.831l-3.294 4.576A.996.996 0 0 0 2 11v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6a.996.996 0 0 0-.195-.594L18.51 5.831A2 2 0 0 0 16.888 5H7.112zm11.936 5-2.16-3H7.112l-2.16 3H9a3 3 0 1 0 6 0h4.048zM12 15a5.001 5.001 0 0 0 4.584-3H20v5H4v-5h3.416A5.001 5.001 0 0 0 12 15z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
