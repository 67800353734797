import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { APIError, WorkflowKeysEnum } from 'core';

export const updateTimeSlotspaths = {
    [WorkflowKeysEnum.TELECONSULTATION]: `/api/proxy/europ-assistance/teleconsultations/[id]`,
    [WorkflowKeysEnum.PREVENTION]: `/api/proxy/europ-assistance/preventions/[id]`,
};

type PatchTeleconsultationTimeSlotResponse = {
    code: string;
    message: string;
};
type PatchTeleconsultationTimeSlotProps = {
    id: string;
    freeTimeSlotId: string;
    pathType: keyof typeof updateTimeSlotspaths;
};

async function patchTeleconsultationTimeSlot({
    id,
    freeTimeSlotId,
    pathType,
}: PatchTeleconsultationTimeSlotProps) {
    try {
        const payload = {
            action: 'RescheduleAppointment',
            freeTimeSlotId,
        };
        const response = await axios.patch(
            updateTimeSlotspaths[pathType].replace('[id]', id),
            pathType === WorkflowKeysEnum.TELECONSULTATION
                ? payload
                : { freeTimeSlotId }
        );
        return response.data as PatchTeleconsultationTimeSlotResponse;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useUpdateTimeSlot() {
    return useMutation<
        PatchTeleconsultationTimeSlotResponse,
        APIError,
        unknown
    >({
        mutationFn: patchTeleconsultationTimeSlot,
    });
}
