import { Icon, IconProps } from '@chakra-ui/react';

export default function SmileIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props} fill="none">
            <path
                d="M9.88424 5C10.2885 5 10.6927 5.19753 11.0161 5.51358C11.7437 6.22469 12.7463 6.22469 13.4901 5.51358C14.0237 5 14.7351 4.86173 15.3657 5.15802C17.7912 6.2642 20.0549 7.88395 22.0761 9.91852C20.5885 13.7901 17.9852 16.7531 14.8321 18.1753C13.1505 18.9259 11.3395 18.9259 9.65787 18.1753C6.52096 16.7728 3.91766 13.8099 2.43005 9.91852C4.45125 7.88395 6.715 6.2642 9.14044 5.15802C9.38298 5.03951 9.62553 5 9.88424 5Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M4.95247 10.6396C4.92362 10.5449 4.98132 10.4312 5.05345 10.4123L9.75596 8.87781C11.2994 9.80606 13.117 9.80606 14.6604 8.87781L19.3485 10.4312C19.3629 10.4312 19.3918 10.4691 19.4062 10.4691C19.4639 10.5259 19.4783 10.6396 19.4206 10.7154C19.0889 11.17 18.7427 11.6057 18.3676 11.9846C14.3719 16.0765 8.38559 15.5082 4.98132 10.7154C4.9669 10.6964 4.95247 10.6585 4.95247 10.6396Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M6.29395 12.0592C9.20777 12.4949 14.8623 12.4759 18.1656 11.9834"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
        </Icon>
    );
}
