import React from 'react';
import { Box, useStyleConfig } from '@chakra-ui/react';
import { contextError } from 'core/utils';

type ListProps = {
    type?: 'number' | 'line' | 'bullet';
    color?: string;
};

type ListPropsWithChildren = ListProps & {
    children: React.ReactNode;
};

const ListContext = React.createContext<ListProps>({
    type: 'bullet',
    color: 'primary.main',
});
ListContext.displayName = 'ListContext';

function useList(): ListProps {
    const context = React.useContext(ListContext);
    if (!context) {
        contextError('useList', 'ListContext.Provider');
    }
    return context;
}

function List({
    children,
    type = 'bullet',
    color = 'primary.main',
}: ListPropsWithChildren) {
    // @ts-ignore: `list` exists in config
    const { list } = useStyleConfig('List');

    return (
        <ListContext.Provider value={{ type, color }}>
            <Box
                as={type === 'number' ? 'ol' : 'ul'}
                sx={{ counterReset: 'orderedList' }}
                __css={list}>
                {children}
            </Box>
        </ListContext.Provider>
    );
}

function ListItem({ children }: ListPropsWithChildren) {
    const { type, color } = useList();
    // @ts-ignore: `item` exists in config
    const { item } = useStyleConfig('List', { variant: type });

    return (
        <Box
            as="li"
            __css={item}
            _before={{
                color: color ?? 'black',
                bgColor: type !== 'number' && (color ?? 'black'),
            }}
            listStyleType="none">
            {children}
        </Box>
    );
}

export type { ListProps, ListPropsWithChildren };
export { useList, List, ListItem };
