import { useGetDocument } from 'lib/shared';

const path =
    '/api/proxy/selfcare/health/contributions/cheque-slips/[chequeSlipId]/file';

export function useChequeSlipFile(chequeSlipId: string) {
    return useGetDocument(
        path.replace('[chequeSlipId]', chequeSlipId as string)
    );
}
