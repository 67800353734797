import React, { MutableRefObject, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Box,
    Button,
    CheckboxGroup,
    HStack,
    SimpleGrid,
    Text,
} from '@chakra-ui/react';
import { CheckboxListProps, formatCheckboxesValues, translateLabel } from '.';
import CoreCheckbox from './CoreCheckbox';
import { customizedScroll } from '../../../consts';
import { coreSharedMessages } from 'core/lib/shared';
import { useSafeIntl, getExtraLabel } from 'core';
import { Pagination } from './Pagination';

export const PAGINATION_FIRST_PAGE = 50;
export const PAGINATION_PAGE_SIZE = 10;

export type CheckboxesProps = Pick<
    CheckboxListProps,
    'options' | 'id' | 'allowAllCheck'
> & {
    focusedOption?: number;
    checkboxContainerRef?: MutableRefObject<HTMLDivElement> | null;
    name: string;
    value: string[];
    hasExtraInfo: boolean;
    setValue: (value: string[]) => void;
    nbDisplayColumns?: number;
};

export function Checkboxes({
    options,
    id,
    value,
    setValue,
    focusedOption = -1,
    checkboxContainerRef = null,
    allowAllCheck,
    name,
    hasExtraInfo,
    nbDisplayColumns = 1,
}: CheckboxesProps) {
    const { safeFormatMessage } = useSafeIntl();

    const [paginatedOptions, setPaginatedOptions] = useState(
        options?.slice(0, PAGINATION_FIRST_PAGE)
    );

    const isParentChecked = value.length === options.length;

    // Handle change for parent checkbox
    const handleParentCheckboxChange = () => {
        if (isParentChecked) {
            setValue([]);
        } else {
            const values = options.map((option) =>
                JSON.stringify({
                    id: option.id,
                    label: translateLabel(
                        option.label,
                        option.isTranslatable,
                        safeFormatMessage
                    ),
                    extraLabel: getExtraLabel(option.extra, safeFormatMessage),
                })
            );
            setValue(formatCheckboxesValues(values));
        }
    };

    const handleOnChange = (nextValue: string[]) => {
        const lastChecked = nextValue.find((option) => !value.includes(option));
        const newValue = lastChecked
            ? [
                  lastChecked,
                  ...nextValue.filter((value) => value !== lastChecked),
              ]
            : nextValue;
        setValue(formatCheckboxesValues(newValue));
    };

    const handleShowMoreClick = (): void => {
        setPaginatedOptions(
            options.slice(0, paginatedOptions.length + PAGINATION_PAGE_SIZE)
        );
    };

    useEffect(() => {
        setPaginatedOptions(
            options.slice(
                0,
                paginatedOptions.length > PAGINATION_FIRST_PAGE
                    ? paginatedOptions.length
                    : PAGINATION_FIRST_PAGE
            )
        );
    }, [options]);

    return (
        <Box>
            {allowAllCheck && (
                <HStack pl={3} pb={2}>
                    <Button
                        variant="link"
                        color={'blue.700'}
                        onClick={handleParentCheckboxChange}>
                        <Text fontSize={'sm'} fontWeight={'medium'}>
                            <FormattedMessage
                                {...(isParentChecked
                                    ? coreSharedMessages.buttonDeselectAll
                                    : coreSharedMessages.buttonSelectAll)}
                            />
                        </Text>
                    </Button>
                    <Text
                        textAlign={'center'}
                        color="texts.medium"
                        fontSize="xs">
                        <FormattedMessage
                            {...coreSharedMessages.selectedElements}
                            values={{
                                number: value.length,
                                total: options.length,
                            }}
                        />
                    </Text>
                </HStack>
            )}
            <Box
                overflowY="auto"
                overflowX="hidden"
                maxH="200px"
                sx={{ ...customizedScroll }}>
                <CheckboxGroup value={value} onChange={handleOnChange}>
                    <SimpleGrid
                        my="2"
                        columns={{
                            base: 1,
                            md: nbDisplayColumns,
                        }}>
                        {paginatedOptions.map((checkbox, index) => (
                            <CoreCheckbox
                                key={index}
                                {...{
                                    id,
                                    value,
                                    focusedOption,
                                    checkboxContainerRef,
                                    checkbox,
                                    index,
                                    name,
                                    hasExtraInfo,
                                }}
                            />
                        ))}
                    </SimpleGrid>
                </CheckboxGroup>
                <Pagination
                    items={options}
                    paginatedItems={paginatedOptions}
                    pageSize={PAGINATION_FIRST_PAGE}
                    handleShowMoreClick={handleShowMoreClick}
                />
            </Box>
        </Box>
    );
}
