export const NOT_AUTH_ROUTES = [
    '/login',
    '/auth/general-conditions',
    '/auth/phone-upgrade',
    '/auth/confirmation',
    '/oauth2-redirect',
    '/register',
    '/redirect',
    '/oauth2-registration-redirect',
    '/reset-password/[providerKey]',
    '/register/forms',
    '/register/success/[status]',
    '/register/responses/[status]',
    '/register/account-validated/[chosenLogin]',
    '/register/known',
    '/register/activation/[providerKey]',
    '/register/challenge',
    '/register/challenge/[strategy]',
    '/redirect-portal',
    '/forgot-password',
    '/forgot-username',
    '/lost-credentials/error/[status]',
    '/mfa',
    '/mfa/[strategy]/[id]',
    '/link-builder',
    '/privacy-policy',
    '/404',
    '/password-upgrade/[token]',
    '/username-upgrade/[username]/[token]',
    '/maintenance',
    '/saml/metadata',
    '/saml/sso',
    '/public/preview',
    '/magic-link-mercer',
    '/account-activation/[token]',
    '/SAMLPost',
    '/permissions-test',
    '/primo',
    '/primo/waiting-validation',
];
