import { DateProvider, DateProviderProps } from './Date/context';
import { PickerOptions, PickerProvider } from './Picker/context';
import { Picker } from './Picker';
import { Switcher } from './Switcher';

export type DatePickerOptions = {
    format?: DateProviderProps['format'];
    dateRange?: DateProviderProps['range'];
    pickerRange?: PickerOptions;
    openDays: DateProviderProps['openDays'];
};

export type DatePickerProps = {
    onChange: (date: string) => void;
    onClickDay?: () => void;
    defaultDate?: DateProviderProps['defaultDate'];
    options?: DatePickerOptions;
};

export function DatePicker({
    onChange,
    onClickDay,
    options,
    defaultDate,
}: DatePickerProps) {
    return (
        <DateProvider
            onChange={onChange}
            onClickDay={onClickDay}
            format={options?.format}
            range={options?.dateRange}
            openDays={options?.openDays}
            defaultDate={defaultDate}>
            <PickerProvider options={options?.pickerRange}>
                <Switcher />
                <Picker />
            </PickerProvider>
        </DateProvider>
    );
}
