import React from 'react';
import { Stack } from '@chakra-ui/react';
import { FormGroup } from 'core';
import type { WidgetProps } from '../../types';
import FileUpload from '..';
import { FileObject } from '../useFileUpload';

type MultipleFileUploadProps = WidgetProps & {
    fileCategories: WidgetProps[];
};

function MultipleFileUpload({
    validations,
    ...props
}: MultipleFileUploadProps) {
    const { totalMaxSize } = validations;
    const { id, title, help, links, description, fileCategories } = props;
    const [files, setFiles] = React.useState<
        {
            categoryId: string;
            files: FileObject[];
        }[]
    >([]);
    const [availableFileSize, setAvailableFileSize] = React.useState(0);

    React.useEffect(() => {
        setAvailableFileSize(
            totalMaxSize -
                files.reduce((acc, curr) => {
                    const categoryFilesSize = curr.files?.reduce(
                        (categoryAcc, categoryCurr) =>
                            categoryAcc + categoryCurr.size,
                        0
                    );
                    return acc + categoryFilesSize;
                }, 0)
        );
    }, [files, totalMaxSize]);

    function onFilesListChange(
        categoryId: string,
        categoryFiles: FileObject[]
    ) {
        setFiles((currentFiles) => [
            ...currentFiles.filter((files) => files?.categoryId !== categoryId),
            { categoryId, files: categoryFiles },
        ]);
    }

    return (
        <FormGroup
            label={title}
            name={id}
            {...{ id, description, help, links }}
            // If we have only 1 category with no title and no required attribute, add optional label to parent title
            isRequired={
                !(
                    fileCategories.length === 1 &&
                    !fileCategories[0].title &&
                    fileCategories[0].validations.required === false
                )
            }>
            <Stack spacing="4">
                {fileCategories?.map((fileCategory) => (
                    <FileUpload
                        key={fileCategory.id}
                        onFilesListChange={onFilesListChange}
                        {...fileCategory}
                        categoryId={fileCategory.id}
                        id={id}
                        links={links}
                        validations={{
                            acceptedMimeTypes: validations?.acceptedMimeTypes,
                            maxSize: validations?.maxSize,
                            multipleTotalMaxSize: validations.totalMaxSize,
                            ...fileCategory?.validations,
                        }}
                        availableFileSize={availableFileSize}
                    />
                ))}
            </Stack>
        </FormGroup>
    );
}

export default MultipleFileUpload;
