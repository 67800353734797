import { Icon, IconProps } from '@chakra-ui/react';

export default function CheckIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M20 6a1 1 0 0 1 .098 1.41l-9.343 10.746a1 1 0 0 1-1.43.081L4.06 13.413a1 1 0 0 1 1.352-1.474l4.506 4.13 8.67-9.97A1 1 0 0 1 20 6z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
