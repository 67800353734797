import React from 'react';

type SelectedRows = {
    allRows: boolean;
    includedRows: string[];
    excludedRows: string[];
};

export type SelectedFilterText = {
    name: string;
    type: 'text';
    values: ({ id: string; label: string } | string)[];
};

export type SelectedFilterDate = {
    name: string;
    type: 'date';
    values: {
        startDate?: string;
        endDate?: string;
    };
};

export type SelectedFilter = SelectedFilterText | SelectedFilterDate;

const initialSelectedRows: SelectedRows = {
    allRows: false,
    includedRows: [],
    excludedRows: [],
};

type DashboardContextType = {
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    pages: {
        pagination: Pagination;
    }[];
    setPages: React.Dispatch<
        React.SetStateAction<
            {
                pagination: Pagination;
            }[]
        >
    >;
    isFiltersOpened: boolean;
    setIsFiltersOpened: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFilter[]>>;
    selectedFilters: SelectedFilter[];
    shouldFiltersReset: boolean;
    setShouldFiltersReset: React.Dispatch<React.SetStateAction<boolean>>;
    isExportOpened: boolean;
    setIsExportOpened: React.Dispatch<React.SetStateAction<boolean>>;
    openedFilterCategory: string;
    setOpenedFilterCategory: React.Dispatch<React.SetStateAction<string>>;
    selectedRows: SelectedRows;
    setSelectedRows: React.Dispatch<React.SetStateAction<SelectedRows>>;
};

const DashboardContext = React.createContext<DashboardContextType>({
    currentPage: 1,
    setCurrentPage: null,
    pages: [],
    setPages: null,
    isFiltersOpened: false,
    setSelectedFilters: null,
    selectedFilters: [],
    setIsFiltersOpened: null,
    shouldFiltersReset: false,
    setShouldFiltersReset: null,
    isExportOpened: false,
    setIsExportOpened: null,
    openedFilterCategory: null,
    setOpenedFilterCategory: null,
    selectedRows: null,
    setSelectedRows: null,
});

const DashboardProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pages, setPages] = React.useState([]);
    const [isFiltersOpened, setIsFiltersOpened] = React.useState(false);
    const [selectedFilters, setSelectedFilters] = React.useState<
        SelectedFilter[]
    >([]);
    const [shouldFiltersReset, setShouldFiltersReset] = React.useState(false);
    const [isExportOpened, setIsExportOpened] = React.useState(false);
    const [openedFilterCategory, setOpenedFilterCategory] =
        React.useState<string>();
    const [selectedRows, setSelectedRows] =
        React.useState<SelectedRows>(initialSelectedRows);

    return (
        <DashboardContext.Provider
            value={{
                currentPage,
                setCurrentPage,
                pages,
                setPages,
                isFiltersOpened,
                setIsFiltersOpened,
                selectedFilters,
                setSelectedFilters,
                shouldFiltersReset,
                setShouldFiltersReset,
                isExportOpened,
                setIsExportOpened,
                openedFilterCategory,
                setOpenedFilterCategory,
                selectedRows,
                setSelectedRows,
            }}>
            {children}
        </DashboardContext.Provider>
    );
};

export { DashboardContext, DashboardProvider };
