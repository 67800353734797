import { useGetDocument } from 'lib/shared';

const path =
    '/api/proxy/selfcare/health/contributions/payment-schedules/[paymentScheduleId]/file';

export function usePaymentScheduleFile(paymentScheduleId: string) {
    return useGetDocument(
        path.replace('[paymentScheduleId]', paymentScheduleId)
    );
}
