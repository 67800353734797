import axios from 'axios';
import cookie from 'cookie';
import type { NextApiRequest, NextApiResponse } from 'next';
import { getDefaultNextHeaders } from 'core';
import { IS_DEV_ENV } from 'core/consts';
import * as Sentry from '@sentry/nextjs';

const { API_URL } = process.env;

export const logoutHandler = async (
    req: NextApiRequest,
    res: NextApiResponse
) => {
    const { MICRO_SERVICE, COOKIE_NAME } = req.body;
    const cookieName = COOKIE_NAME || 'auth-token';
    const authToken = cookie.parse(req.headers.cookie || '')[cookieName];
    if (authToken) {
        try {
            await axios.delete(`${API_URL}${MICRO_SERVICE}/user/logout`, {
                headers: {
                    ...getDefaultNextHeaders(req),
                    Authorization: `Bearer ${authToken}`,
                },
            });
        } catch (e) {
            Sentry.captureMessage(`Error on logout: ${e.message}`);
        }

        res.setHeader(
            'Set-Cookie',
            cookie.serialize(cookieName, '', {
                httpOnly: true,
                sameSite: 'lax',
                expires: new Date(0),
                secure: !IS_DEV_ENV,
                path: '/',
            })
        );
    }

    return res.status(200).json({ logout: true });
};
