import { Button, ButtonProps } from '@chakra-ui/react';
import { coreSharedMessages } from 'core';
import { PenIcon } from 'design-system/icons';
import React from 'react';
import { useIntl } from 'react-intl';

type EditButtonProps = ButtonProps & {
    onEdit: () => void;
};

function EditButton({ onEdit, ...props }: EditButtonProps) {
    const intl = useIntl();

    return (
        <Button
            minW="38px"
            h="38px"
            variant={'ghost'}
            color={'primary.main'}
            _hover={{ bg: 'gray.200' }}
            rounded="full"
            border={'2px solid'}
            borderColor={'grey.300'}
            padding={'0'}
            {...props}
            onClick={onEdit}>
            <PenIcon
                boxSize="4"
                aria-label={intl.formatMessage(coreSharedMessages.edit)}
            />
        </Button>
    );
}

export default EditButton;
