import { Icon, IconProps } from '@chakra-ui/react';

export default function DiplomaIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="m6 11.044-1-.33V15a1 1 0 1 1-2 0v-4.947l-.17-.056c-.96-.317-.96-1.677 0-1.994l8.626-2.848a2 2 0 0 1 1.265.004l8.316 2.798c1 .337 1 1.75 0 2.086L18 11.065v6.029a1 1 0 0 1-.365.772c-.64.526-1.635.791-2.575.94-.994.156-2.14.211-3.25.19a20.65 20.65 0 0 1-3.148-.289 9.167 9.167 0 0 1-1.271-.312c-.353-.119-.742-.286-1.04-.54A1 1 0 0 1 6 17.093v-6.05zm5.456 1.801a2 2 0 0 0 1.265-.004L16 11.738v4.78c-.279.11-.691.223-1.25.312-.848.133-1.871.186-2.9.166a18.673 18.673 0 0 1-2.833-.257 7.224 7.224 0 0 1-.99-.24L8 16.489v-4.785l3.456 1.141zm.627-1.9L6.19 9l5.893-1.946L17.865 9l-5.782 1.946z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
