import { Icon, IconProps } from '@chakra-ui/react';

export default function EyeOpenIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="currentColor" />
            <path
                fillRule="evenodd"
                d="M12 5c-1.93 0-3.821.907-5.418 2.015-1.616 1.122-3.045 2.53-4.072 3.713a1.937 1.937 0 0 0 0 2.544c1.027 1.183 2.456 2.591 4.072 3.713C8.179 18.093 10.07 19 12 19c1.93 0 3.821-.907 5.418-2.015 1.616-1.122 3.045-2.53 4.072-3.713a1.937 1.937 0 0 0 0-2.544c-1.027-1.183-2.456-2.591-4.072-3.713C15.821 5.907 13.93 5 12 5zM7.722 15.342C6.298 14.353 5.005 13.089 4.055 12c.95-1.09 2.243-2.354 3.667-3.342C9.182 7.645 10.667 7 12 7c1.333 0 2.818.645 4.278 1.658 1.424.988 2.717 2.253 3.667 3.342-.95 1.09-2.243 2.354-3.667 3.342C14.818 16.355 13.333 17 12 17c-1.333 0-2.818-.645-4.278-1.658z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
