import React, { useContext } from 'react';
import { AxiosResponse } from 'axios';
import { QueryStatus } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { HStack, Button, Text } from '@chakra-ui/react';
import { APIError, coreSharedMessages, formMessages } from '../../..';
import { DownloadIcon, CrossIcon } from 'design-system/icons';
import { UtilsContext } from '../../../lib';
import FileViewer from '../../document-preview/FileViewer';
import {
    CoreModal,
    CoreModalContent,
    CoreModalHeader,
    CoreModalBody,
} from 'design-system/components';

type DocumentViewerModalProps = {
    isOpen: boolean;
    data: AxiosResponse<unknown>;
    fileName: string;
    status: QueryStatus;
    error: APIError;
    onClose: () => void;
};

export function DocumentViewerModal({
    isOpen,
    data,
    fileName,
    status,
    error,
    onClose,
}: DocumentViewerModalProps) {
    const { formatMessage } = useIntl();
    const {
        layouts: { LayoutBoundary },
    } = useContext(UtilsContext);

    return (
        <CoreModal {...{ isOpen, onClose }} scrollBehavior="inside" size="6xl">
            <CoreModalContent>
                <CoreModalHeader bg="grey.900" p={0} withCloseButton={false}>
                    <HStack
                        bg="grey.900"
                        justifyContent="space-between"
                        py="3"
                        px="4"
                        roundedTop="md">
                        <Button
                            as="a"
                            px="2"
                            colorScheme="gray"
                            rounded="full"
                            rel="nofollow noreferrer noopener"
                            target="_blank"
                            href={data?.config.url || ''}
                            rightIcon={<DownloadIcon />}
                            isLoading={status === 'pending'}
                            isDisabled={status === 'error'}>
                            <FormattedMessage
                                {...coreSharedMessages.buttonDownload}
                            />
                        </Button>
                        <Text
                            color="white"
                            fontSize={'medium'}
                            fontWeight={500}>
                            {fileName in formMessages ? (
                                <FormattedMessage {...formMessages[fileName]} />
                            ) : (
                                fileName
                            )}
                        </Text>
                        <Button
                            p="0"
                            colorScheme="gray"
                            rounded="full"
                            onClick={onClose}
                            title={formatMessage(coreSharedMessages.close)}>
                            <CrossIcon
                                w="4"
                                h="4"
                                aria-label={formatMessage(
                                    coreSharedMessages.close
                                )}
                            />
                        </Button>
                    </HStack>
                </CoreModalHeader>
                <CoreModalBody py={10}>
                    <LayoutBoundary
                        status={status}
                        px="0"
                        py={status === 'pending' ? 4 : 0}
                        errorCode={error?.code}>
                        {data && <FileViewer data={data} />}
                    </LayoutBoundary>
                </CoreModalBody>
            </CoreModalContent>
        </CoreModal>
    );
}
