import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';

const path = `/api/proxy/europ-assistance/teleconsultations/exclusions`;

async function fetchTeleconsultationExclusions({
    queryKey: [urn],
}: FetchQueryParams) {
    try {
        const response = await axios.get(urn);
        return response.data.data;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useTeleconsultationExclusions(enabled: boolean) {
    return useQuery<string[], APIError>({
        queryKey: [path],
        queryFn: fetchTeleconsultationExclusions,
        enabled,
    });
}
