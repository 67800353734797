import { Icon, IconProps } from '@chakra-ui/react';

export default function ImageIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M4 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H4zm16 2H4v7.35l2.597-2.412a2 2 0 0 1 2.807.085l4.438 4.65 2.612-2.939a2 2 0 0 1 3.073.101l.473.608V5zm0 10.7-2.051-2.637-2.721 3.06L17.973 19H20v-3.3zM4 19v-3.921l3.958-3.675L15.208 19H4z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
