import { capitalize, startCase } from 'lodash';

function capitalizeEachWord(value: string) {
    if (typeof value === 'string') {
        return startCase(capitalize(value));
    }

    throw new Error('value must be a string');
}

export { capitalizeEachWord };
