import React from 'react';
import { LinkBox, LinkOverlay } from '@chakra-ui/react';
import {
    LinkIntl,
    getChallengeType,
    ChallengeCanal,
    ChallengeWorkflow,
} from 'core';
import { MessageIcon, AdviceIcon, EmailIcon } from 'design-system/icons';
import { useRouter } from 'next/router';

const paths = {
    mfa: '/mfa/[strategy]/[id]',
    registration: '/register/challenge/[strategy]',
};

type Props = ChallengeCanal & {
    workflow: ChallengeWorkflow;
};

const strategyIcons = {
    email: EmailIcon,
    paper: MessageIcon,
    sms: AdviceIcon,
};

function getPhoneNumber(contact: string) {
    const formattedString = contact.split('*');
    const phoneNumberCode = formattedString?.[0]?.slice(0, -1);
    return `${phoneNumberCode} ${contact.replace(phoneNumberCode, '')}`;
}

export default function ChallengeChannelButton({
    workflow,
    strategy,
    contact,
    id,
}: Props) {
    const { query } = useRouter();
    const type = getChallengeType(strategy);
    const Icon = strategyIcons?.[type];

    const iconSize = 6;

    return (
        <LinkBox
            key={contact}
            bg="grey.50"
            border="1px solid"
            borderColor="strokes.medium"
            py="4"
            px="6"
            rounded="md"
            transition="ease-in-out 0.25s all"
            _hover={{
                borderColor: 'strokes.medium',
            }}
            _focusWithin={{
                borderColor: 'primary.main',
            }}>
            {Icon && <Icon w={iconSize} h={iconSize} mr="3" />}
            <LinkOverlay
                as={LinkIntl}
                passHref
                // @ts-ignore
                href={{
                    pathname: paths[workflow],
                    query: query?.token
                        ? {
                              token: query.token,
                              strategy,
                              contact,
                          }
                        : {
                              strategy,
                              contact,
                              id,
                          },
                }}>
                {strategy === 'sms' ? getPhoneNumber(contact) : contact}
            </LinkOverlay>
        </LinkBox>
    );
}
