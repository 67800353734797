import React, { ComponentType } from 'react';
import { FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import {
    defineMessages,
    FormattedMessage,
    MessageDescriptor,
    useIntl,
} from 'react-intl';
import { Stack, Button, Link, VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import {
    useLogin,
    useRememberUsername,
    LinkIntl,
    useFormValidation,
    coreSharedMessages,
    __DEV__,
    isNative,
    MaintenanceType,
    LayoutBoundaryType,
    WidgetKeys,
    FormLibrarian,
    Site,
    useSafeIntl,
    BlockText,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import Alert from 'design-system/components/Alert';

type Values<V> = {
    value: V;
};

type LoginFormValues = {
    username: Values<string>;
    password: Values<string>;
    shouldRememberUsername: Values<boolean>;
};

const intlMessages = defineMessages({
    toastError: {
        id: 'components.login.error-toast',
        defaultMessage: 'Une erreur est survenue lors de la connexion.',
    },
});

type footerLink = {
    href: string;
    message: MessageDescriptor;
};

type LoginFormProps = {
    config: Pick<
        Site.Config,
        'features' | 'appName' | 'managementCenterPhoneNumber'
    >;
    widgets: WidgetKeys[];
    LayoutBoundary: ComponentType<LayoutBoundaryType>;
    footerLinks: footerLink[];
    AdditionalAction?: ComponentType | null;
    maintenance?: MaintenanceType | null;
};

export function LoginForm({
    config,
    LayoutBoundary,
    widgets,
    footerLinks,
    AdditionalAction = null,
    maintenance = null,
}: LoginFormProps) {
    const { features, appName, managementCenterPhoneNumber } = config;
    const { isMobile } = useWindowBreakpoints();
    const { methods } = useFormValidation('selfcare', null, widgets);
    const {
        loginQuery,
        login,
        errorMessage,
        redirectErrorMessage,
        shouldShowRedirectErrorToast,
        httpStatus,
    } = useLogin();
    const router = useRouter();
    const authUsername = useRememberUsername(methods);
    const { safeFormatMessage } = useSafeIntl();
    const { formatMessage } = useIntl();
    const submitForm = ({
        shouldRememberUsername,
        ...credentials
    }: LoginFormValues) => {
        if (shouldRememberUsername) {
            authUsername.update(
                credentials.username.value,
                shouldRememberUsername.value
            );
        }
        login(credentials);
    };

    const handleKeychainInjection = () => {
        if (!isNative()) return;

        const inputs = Array.from(
            document.getElementsByClassName(
                'chakra-input'
            ) as HTMLCollectionOf<HTMLInputElement>
        );

        inputs.forEach((input) => {
            methods.setValue(input.name, input.value);
        });

        methods.trigger();
        submitForm(methods.getValues() as LoginFormValues);
    };

    const onSubmit = methods.handleSubmit(submitForm, handleKeychainInjection);

    const _renderCardMaintenanceMessage = () => {
        return (
            <Alert
                color="warning"
                icon="AlertIcon"
                description={
                    <BlockText
                        wysiwyg={
                            typeof maintenance.message === 'string'
                                ? {
                                      value: maintenance?.message,
                                      format: 'html',
                                  }
                                : maintenance?.message
                        }
                        containerProps={{ fontSize: 'md', mt: '1' }}
                    />
                }
            />
        );
    };

    return (
        <LayoutBoundary
            p="0"
            toastError={shouldShowRedirectErrorToast}
            messages={intlMessages}>
            {!!maintenance && (
                <VStack mb={'6'}>{_renderCardMaintenanceMessage()}</VStack>
            )}
            {!shouldShowRedirectErrorToast &&
                (errorMessage || redirectErrorMessage) && (
                    <Alert
                        color="error"
                        icon="AlertIcon"
                        title={
                            !errorMessage
                                ? redirectErrorMessage &&
                                  redirectErrorMessage.toastTitleError &&
                                  formatMessage(
                                      redirectErrorMessage.toastTitleError
                                  )
                                : null
                        }
                        description={
                            errorMessage
                                ? safeFormatMessage(
                                      errorMessage,
                                      {
                                          appName,
                                          managementCenterPhoneNumber,
                                      },
                                      `status : ${httpStatus.toString()}`
                                  )
                                : redirectErrorMessage &&
                                  formatMessage(redirectErrorMessage.toastError)
                        }
                    />
                )}

            <FormProvider {...methods}>
                <Stack
                    px="1"
                    spacing="8"
                    id="login-form"
                    as="form"
                    onSubmit={onSubmit}>
                    <Stack spacing="5">
                        {widgets?.map(({ type, id, ...props }) => {
                            if (
                                type === 'checkbox' &&
                                !features.rememberUsername
                            ) {
                                return null;
                            }

                            return (
                                <FormLibrarian
                                    key={id}
                                    type={type}
                                    props={{
                                        id,
                                        methods: {
                                            setError: methods.setError,
                                            errors: methods.formState.errors,
                                            clearErrors: methods.clearErrors,
                                        },
                                        ...props,
                                        ...(router.query.username &&
                                            type === 'text' && {
                                                defaultValue: router.query
                                                    .username as string,
                                            }),
                                    }}
                                />
                            );
                        })}
                    </Stack>

                    <Stack spacing="4" zIndex={1}>
                        <Stack gap="2">
                            <Button
                                colorScheme="primary"
                                w="full"
                                isLoading={loginQuery.isPending}
                                type="submit"
                                size={isMobile ? 'lg' : 'md'}>
                                <FormattedMessage
                                    {...coreSharedMessages.loginButton}
                                />
                            </Button>
                            {AdditionalAction && <AdditionalAction />}
                        </Stack>

                        <Stack textAlign="center" color="texts.medium">
                            {footerLinks.map(({ href, message }) => (
                                <Link
                                    key={href}
                                    as={LinkIntl}
                                    href={href}
                                    fontSize="sm"
                                    textDecor="underline">
                                    <FormattedMessage {...message} />
                                </Link>
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
            </FormProvider>
            {__DEV__ && <DevTool control={methods.control} />}
        </LayoutBoundary>
    );
}
