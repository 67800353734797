import { useToast } from 'design-system/components/Toast';
import { useIntl } from 'react-intl';
import { signatureErrorMessages } from '../i18n';

export function useSignatureBeneficiariesToasts() {
    const { formatMessage } = useIntl();

    const cumulatedCapitalErrorToast = useToast({
        status: 'error',
        description: formatMessage({
            id: 'errors.workflow-signature.workflow-beneficiaries-designation.adding-beneficiaries.alert',
            defaultMessage:
                'Merci de répartir 100% du capital avant de valider.',
        }),
    });

    const noMainBeneficiaryToast = useToast({
        status: 'error',
        description: formatMessage({
            id: 'errors.workflow-signature.workflow-beneficiaries-designation.adding-beneficiary.alert-main-beneficiary',
            defaultMessage:
                'Merci de renseigner le bénéficiaire principal avant de valider.',
        }),
    });

    const beneficiariesRanksToast = useToast({
        status: 'error',
        description: formatMessage({
            id: 'errors.workflow-signature.workflow-beneficiaries-designation.adding-beneficiary.alert-ranks',
            defaultMessage:
                'Les rangs de vos bénéficiaires à défaut doivent être consécutifs.',
        }),
    });

    const addBeneficiaryErrorToast = useToast({
        status: 'error',
        description: formatMessage(
            signatureErrorMessages['add-beneficiary-form'][403]
        ),
    });

    const editBeneficiaryErrorToast = useToast({
        status: 'error',
        description: formatMessage({
            id: 'errors.workflow-signature.workflow-beneficiaries-designation.edit-beneficiary.403',
            defaultMessage:
                'Le bénéficiaire n’a pas pu être modifié, veuillez réessayer.',
        }),
    });

    const deleteBeneficiaryErrorToast = useToast({
        status: 'error',
        description: formatMessage({
            id: 'errors.workflow-signature.workflow-beneficiaries-designation.delete-beneficiary.404-500',
            defaultMessage:
                'Le bénéficiaire n’a pas pu être supprimé, veuillez réessayer.',
        }),
    });

    return {
        cumulatedCapitalErrorToast,
        noMainBeneficiaryToast,
        beneficiariesRanksToast,
        addBeneficiaryErrorToast,
        editBeneficiaryErrorToast,
        deleteBeneficiaryErrorToast,
    };
}
