import { FormValues } from 'core';

export function getFilledFilters(filters: FormValues[] | undefined) {
    if (!filters) return [];

    return filters.filter(
        (widgetEntry) =>
            !!widgetEntry.value ||
            !!widgetEntry.startDate ||
            !!widgetEntry.endDate
    );
}

export function getFiltersQueryString(filters?: FormValues[] | undefined) {
    return getFilledFilters(filters).reduce((aggr, curr) => {
        const subKeys = Object.entries(curr).filter(
            ([key, value]) => ['startDate', 'endDate'].includes(key) && !!value
        );

        if (!subKeys.length) {
            return `${aggr}&${curr.id}=${curr.value}`;
        }

        const subQuery = subKeys
            .map(([subKey, value]) => `${curr.id}[${subKey}]=${value}`)
            .join('&');

        return `${aggr}&${subQuery}`;
    }, '');
}

export function getNumberOfFilters(filters: FormValues[] | undefined) {
    return getFilledFilters(filters).length;
}
