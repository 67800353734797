import { Icon, IconProps } from '@chakra-ui/react';

export default function StarOutlineIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M13.958 10.305 12 5.912l-1.958 4.393-4.783.505 3.573 3.22-.998 4.704L12 16.331l4.166 2.403-.998-4.705 3.573-3.22-4.783-.504zM8.685 8.437l-5.867.62c-.86.09-1.207 1.157-.564 1.737l4.382 3.949-1.224 5.77c-.18.847.728 1.507 1.478 1.074L12 18.64l5.11 2.947c.75.433 1.657-.227 1.478-1.073l-1.224-5.771 4.382-3.95c.643-.579.296-1.646-.564-1.737l-5.867-.619-2.402-5.388c-.352-.79-1.474-.79-1.826 0L8.685 8.437z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
