import axios from 'axios';
import { APIError } from 'core';
import { useQuery } from '@tanstack/react-query';
import { Beneficiary } from '../types';

type UseBeneficiaries = {
    beneficiaries?: Beneficiary[];
    activeBeneficiariesOnly: boolean;
    beneficiariesTotalErrors: number;
};

const path = `/api/proxy/selfcare/health/beneficiaries`;

async function fetchBeneficiaries() {
    try {
        const response = await axios.get(path);
        return response.data.data;
    } catch (error) {
        throw { code: error?.response?.status };
    }
}

function useBeneficiaries() {
    return useQuery<UseBeneficiaries, APIError>({
        queryKey: [path],
        queryFn: main.fetchBeneficiaries,
    });
}

const main = {
    useBeneficiaries,
    fetchBeneficiaries,
};

export type { UseBeneficiaries };
export { path as beneficiariesPath, fetchBeneficiaries, useBeneficiaries };
export default main;
