import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { Button, Stack } from '@chakra-ui/react';
import { Validations, WidgetProps, useInputValidation } from 'core';
import { InfoIcon, UpdateIcon } from 'design-system/icons';
import GooglePlacesInput from './GooglePlacesInput';
import ManualAddress from './ManualAddress';
import { Address } from 'core/lib/forms/types/address';

const intlMessages = {
    btnPartialAddress: defineMessage({
        id: 'widgets.address.partial-autocomplete',
        defaultMessage: 'Je ne trouve pas mon adresse',
    }),
    btnAutoAddress: defineMessage({
        id: 'widgets.address.autocomplete',
        defaultMessage: 'Saisir mon adresse automatiquement',
    }),
};

export type AddressAutocompleteWidgetProps = Omit<
    WidgetProps,
    'validations' | 'defaultValue'
> & {
    validations: Validations & {
        restrictedToCountries: string[];
    };
    defaultValue?: Address;
};

const AddressAutocomplete = ({
    id,
    title,
    placeholder,
    help,
    validations,
    description,
    defaultValue,
}: AddressAutocompleteWidgetProps) => {
    const inputName = `${id}.value`;

    const { registerValues, setValue, resetField } = useInputValidation(
        validations,
        inputName
    );

    const [widgetValue, setWidgetValue] = useState<Address | null>(
        defaultValue
    );
    const [isAuto, setIsAuto] = useState<boolean>(
        defaultValue?.isManualAddress === false || true
    );

    const updateWidgetValue = useCallback(
        (value, shouldValidate = false) => {
            setWidgetValue(value);
            setValue(inputName, value ? JSON.stringify(value) : '', {
                shouldValidate,
            });
        },
        [inputName, setValue]
    );

    useEffect(() => {
        if (defaultValue) updateWidgetValue(defaultValue, true);
        if (defaultValue?.isManualAddress) setIsAuto(false);
    }, [defaultValue, updateWidgetValue]);

    const _renderHiddenInput = () => (
        <input
            {...registerValues}
            value={widgetValue ? JSON.stringify(widgetValue) : ''}
            readOnly
            hidden
        />
    );

    const handleInputTypeOnClick = () => {
        setIsAuto((prevIsAuto) => !prevIsAuto);
        resetField(`${id}.place`);
    };

    return (
        <Stack
            spacing="1"
            bg="white"
            pb={'1'}
            rounded="md"
            position="relative"
            width={'full'}>
            {isAuto ? (
                <>
                    <GooglePlacesInput
                        restrictions={validations.restrictedToCountries}
                        types={['address']}
                        minChar={3}
                        onChange={updateWidgetValue}
                        defaultValue={
                            defaultValue?.isManualAddress === true
                                ? null
                                : defaultValue
                        }
                        {...{
                            id,
                            placeholder,
                            title,
                            isRequired: validations.required,
                            helper: help,
                            description,
                        }}
                    />
                    <Button
                        mt={4}
                        leftIcon={<InfoIcon />}
                        onClick={handleInputTypeOnClick}>
                        <FormattedMessage {...intlMessages.btnPartialAddress} />
                    </Button>
                </>
            ) : (
                <>
                    <ManualAddress
                        id={id}
                        isRequired={validations.required}
                        restrictions={validations.restrictedToCountries}
                        onChange={updateWidgetValue}
                        defaultValue={
                            defaultValue?.isManualAddress === true
                                ? defaultValue
                                : null
                        }
                    />
                    <Button
                        mt={4}
                        leftIcon={<UpdateIcon />}
                        onClick={handleInputTypeOnClick}>
                        <FormattedMessage {...intlMessages.btnAutoAddress} />
                    </Button>
                </>
            )}
            {_renderHiddenInput()}
        </Stack>
    );
};

export default AddressAutocomplete;
