type UseSessionStorageApi = {
    load(key: string, defaultValue?: unknown): unknown | null;
    save(key: string, value: unknown): void;
    clear(): void;
    remove(key: string): void;
};

export function useSessionStorage(): UseSessionStorageApi {
    const isObject = (o) =>
        Object.prototype.toString.call(o) === '[object Object]';

    const stringify = (data: unknown): string => {
        if (isObject(data)) return JSON.stringify(data);
        if (!!data?.toString) return data?.toString();
        return data as string;
    };

    const save = (key: string, value: unknown): void => {
        sessionStorage.setItem(key, stringify(value));
    };

    const load = (key: string, defaultValue?: unknown): unknown | null => {
        const value = sessionStorage.getItem(key);
        const isValueDefined =
            !!value && value !== 'null' && value !== 'undefined';

        if (!isValueDefined) {
            save(key, defaultValue);
            return defaultValue;
        }

        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    };

    const remove = (key: string): void => {
        sessionStorage.removeItem(key);
    };

    const clear = (): void => {
        sessionStorage.clear();
    };

    return {
        load,
        save,
        clear,
        remove,
    };
}
