import axios from 'axios';

export async function validateCaptcha(token: string, requiredScore: number) {
    if (!token) {
        return {
            success: false,
            message: 'Missing Captcha',
        };
    }

    const captchaResponse = await axios.post(
        `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.RECAPTCHA_SECRET_KEY}&response=${token}`,
        {
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=utf-8',
            },
        }
    );

    if (!captchaResponse?.data.success) {
        return {
            success: false,
            message: 'Invalid Captcha',
        };
    }

    if (captchaResponse?.data?.score < requiredScore) {
        return {
            success: false,
            score: 'Verification score is too low',
        };
    }

    return {
        success: true,
        score: captchaResponse.data?.score,
    };
}
