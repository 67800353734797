import {
    validationPatternTypes,
    formPattern,
    FormatMessageFn,
    ValidationOptions,
} from 'core';

function validatePattern(
    patternId: ValidationOptions['type'],
    formatMessage: FormatMessageFn,
    patternValidations?: ValidationOptions['patternValidations']
) {
    if (!patternId) return undefined;

    const pattern = formPattern(patternId, patternValidations);

    if (!pattern) return undefined;

    return {
        value: pattern,
        message: formatMessage(
            validationPatternTypes[patternId],
            patternValidations
        ),
    };
}

export { validatePattern };
