import React from 'react';
import { MessageDescriptor } from 'react-intl';
import { Box, Text, BoxProps } from '@chakra-ui/react';
import {
    Heading,
    ErrorIllustration,
    SafeFormattedMessage,
    useSafeIntl,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import {
    coreSharedMessages,
    coreEmptyStates,
    coreErrors,
    UtilsContext,
    LayoutButtonType,
} from 'core/lib/shared';
import { LayoutButtons } from './LayoutButton';
import { useRouter } from 'next/router';
import Alert from 'design-system/components/Alert';

const MESSAGES = {
    loading: coreSharedMessages.loading,
    error: coreErrors.error,
    empty: coreEmptyStates.default,
    maintenanceTitle: coreErrors.maintenanceTitle,
    maintenanceDescription: coreErrors.maintenanceDescription,
};

type ComponentProps = {
    message?: MessageDescriptor;
};

export type ErrorProps = ComponentProps &
    BoxProps & {
        description?: MessageDescriptor;
        errorButtons?: LayoutButtonType[];
        errorCode?: number;
        isSmallDisplay?: boolean;
        showButtonReloadPage?: boolean;
    };

export function Error({
    message = MESSAGES.error,
    description,
    errorButtons,
    errorCode,
    showButtonReloadPage = true,
    isSmallDisplay = false,
    ...boxProps
}: ErrorProps) {
    const router = useRouter();
    const { isMobile } = useWindowBreakpoints();
    const {
        siteConfig: { homePagePath, packageName },
    } = React.useContext(UtilsContext);
    const defaultErrorButtons = [
        ...(showButtonReloadPage && [400, 500].includes(errorCode)
            ? [
                  {
                      text: coreSharedMessages.reloadPage,
                      onClick: router.reload,
                  },
              ]
            : []),
        {
            text: coreSharedMessages.buttonBackHome,
            href: homePagePath,
        },
    ];

    return (
        <ErrorIllustration
            borderRadius={!isMobile && '3xl'}
            p={!isMobile && !isSmallDisplay && '12'}
            py={isMobile && !isSmallDisplay && '6'}
            bg={!isMobile && packageName === 'adherent' && 'primary.50'}
            flexDirection={isMobile || isSmallDisplay ? 'column' : 'row'}
            alignItems={isMobile || isSmallDisplay ? 'center' : 'center'}
            gap={isMobile || isSmallDisplay ? '4' : '12'}
            flexFlow={!isMobile && !isSmallDisplay && 'row-reverse'}
            sx={{
                '> * + *': {
                    mt: '4',
                },
            }}
            {...boxProps}>
            <Heading as="h1" textStyle="h3" color="primary.main" fontSize="2xl">
                <SafeFormattedMessage
                    {...message}
                    debugKey={`ErrorComponent heading ${message}`}
                />
            </Heading>
            {(description || errorButtons?.length !== 0) && (
                <Box
                    height="6px"
                    bg="primary.200"
                    width="50px"
                    margin="auto"
                    borderRadius="xl"
                    my="4"
                />
            )}
            {description && (
                <Text>
                    <SafeFormattedMessage
                        {...description}
                        debugKey={`ErrorComponent description ${description}`}
                    />
                </Text>
            )}

            {(errorButtons || defaultErrorButtons) && (
                <LayoutButtons buttons={errorButtons || defaultErrorButtons} />
            )}
        </ErrorIllustration>
    );
}

export function AlertError({
    message = MESSAGES.error,
    errorValues,
}: ComponentProps & {
    errorValues: Record<string, string | number> | undefined;
}) {
    const { safeFormatMessage } = useSafeIntl();
    return (
        <Alert
            color="error"
            data-testid="alert-error"
            icon="InfoIcon"
            description={safeFormatMessage(
                message,
                errorValues,
                `ErrorComponent description errorValues: ${errorValues}`
            )}
        />
    );
}
