import { HStack } from '@chakra-ui/react';
import { useInputValidation } from 'core';
import { StarFillIcon, StarOutlineIcon } from 'design-system/icons';
import Alert from 'design-system/components/Alert';
import React from 'react';
import { useWatch } from 'react-hook-form';

type StarProps = {
    isSelected: boolean;
    onClick: () => void;
};

function Star({ isSelected, onClick }: StarProps) {
    const props = { w: 10, h: 10 };

    return (
        <button type="button" onClick={onClick}>
            {isSelected ? (
                <StarFillIcon {...props} />
            ) : (
                <StarOutlineIcon {...props} />
            )}
        </button>
    );
}

export default function StarRating(props) {
    const name = `${props.id}.value`;
    const {
        registerValues,
        setValue,
        formState: { errors },
    } = useInputValidation(props.validations, name);
    const value: string = useWatch({
        name: name,
    });

    //@TODO test
    return (
        <>
            {!value && errors[props.id] && (
                <Alert
                    color="error"
                    description={errors[props.id].message as string}
                    icon="AlertIcon"
                />
            )}

            <HStack w="100%" justifyContent="space-around">
                {props?.options?.map(({ id }) => (
                    <Star
                        key={id}
                        isSelected={value ? +id <= +value : false}
                        onClick={() => setValue(name, id)}
                    />
                ))}

                <input
                    type="hidden"
                    value={value}
                    required={!!props.validations.required}
                    {...registerValues}
                />
            </HStack>
        </>
    );
}
