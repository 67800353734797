import { defineMessages } from 'react-intl';

export const coreErrors = defineMessages({
    error: {
        id: 'components.layouts.error',
        defaultMessage: 'Une erreur est survenue.',
    },
    counter: {
        id: 'errors.counter.default',
        defaultMessage:
            'Nous rencontrons des difficultés à vous afficher certains contenus.',
    },
    notFoundTitle: {
        id: 'errors.not-found.title',
        defaultMessage: 'Page non trouvée',
    },
    notFoundText: {
        id: 'errors.not-found.text',
        defaultMessage: 'La page que vous cherchez n’existe pas.',
    },
    errorInvalidProviderData: {
        id: 'errors.invalid-provider-data.default',
        defaultMessage:
            'Une erreur est survenue lors de la récupération de ce contenu.',
    },
    unavailableContentTitle: {
        id: 'errors.unavailable-content.title',
        defaultMessage: 'Contenu indisponible',
    },
    unavailableContentText: {
        id: 'errors.unavailable-content.text',
        defaultMessage:
            'Le contenu que vous cherchez est momentanément indisponible. Veuillez recharger la page ou réessayer plus tard.',
    },
    closeAlert: {
        id: 'errors.alerts.default',
        defaultMessage:
            'Une erreur est survenue lors de la fermeture de cette alerte. Veuillez réessayer plus tard.',
    },
    maintenanceTitle: {
        id: 'errors.maintenance.title',
        defaultMessage: 'Page en maintenance',
    },
    maintenanceDescription: {
        id: 'errors.maintenance.text',
        defaultMessage:
            'Le contenu que vous cherchez est momentanément indisponible. Veuillez réessayer plus tard.',
    },
    unavailableFeature: {
        id: 'errors.unavailable-feature.default',
        defaultMessage:
            'Une erreur est survenue : des contenus ou fonctionnalités sont momentanément indisponibles.',
    },
    serviceUnavailableDescription: {
        id: 'errors.unavailable-service.title',
        defaultMessage: "Votre demande n'a pas pu aboutir",
    },
    serviceUnavailable: {
        id: 'errors.unavailable-service.text',
        defaultMessage:
            'Ce service est momentanément indisponible. Veuillez réessayer plus tard.',
    },
    serviceNotFound: {
        id: 'errors.not-found-service.text',
        defaultMessage: 'Ce service est indisponible.',
    },
    sessionExpired: {
        id: 'errors.timeout-session.text',
        defaultMessage: 'Vous avez été déconnecté.',
    },
    existingUsername: {
        id: 'errors.existing-username',
        defaultMessage: 'Cet identifiant de connexion est déjà utilisé',
    },
});
