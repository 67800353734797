import React from 'react';
import { capitalize } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Flex, FormErrorMessage } from '@chakra-ui/react';
import {
    SafeFormattedMessage,
    validationFormsMessages,
    validateDateRangeMin,
    validateDateRangeMax,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import Fieldset from '../Fieldset';
import InputDate from './InputDate';
import type { WidgetInputProps, WidgetProps } from './types';

type InputDateRangeProps = WidgetProps & {
    dateStart: WidgetInputProps;
    dateEnd: WidgetInputProps;
};

function useDateRangeValidation(
    id: string,
    range: { min?: number; max?: number }
) {
    const [errorStatus, setErrorStatus] = React.useState<null | 'min' | 'max'>(
        null
    );
    const { min, max } = range;
    const startDateId = `${id}.startDate`;
    const endDateId = `${id}.endDate`;
    const watchedValues = useWatch({ name: [startDateId, endDateId] });
    const startDate = watchedValues[startDateId];
    const endDate = watchedValues[endDateId];

    React.useEffect(() => {
        if (startDate && endDate) {
            const values = { startDate, endDate };
            const isRangeMin = validateDateRangeMin(values, min);
            const isRangeMax = validateDateRangeMax(values, max);
            const errorEnum = !isRangeMin ? 'min' : !isRangeMax ? 'max' : null;
            setErrorStatus(errorEnum);
        }
    }, [max, min, watchedValues, startDate, endDate]);

    return {
        errorStatus,
    };
}

export default function InputDateRange({
    validations,
    dateEnd,
    dateStart,
    title,
    id,
    help,
    description,
    useClickOutsideMode = 'all',
}: InputDateRangeProps) {
    const { dateRangeMax, dateRangeMin, ...restValidation } = validations;
    const { isMobile } = useWindowBreakpoints();
    const { errorStatus } = useDateRangeValidation(id, {
        min: dateRangeMin,
        max: dateRangeMax,
    });
    const isInvalid = errorStatus ? true : undefined;
    const {
        formState: { errors },
        setError,
        clearErrors,
    } = useFormContext();

    React.useEffect(() => {
        const inputName = `${id}`;
        if (isInvalid && !(inputName in errors)) {
            setError(inputName, { type: 'range' });
        }

        if (!isInvalid) {
            clearErrors(inputName);
        }
    }, [isInvalid, id, setError, clearErrors, errors]);

    return (
        <Fieldset
            legend={title}
            isRequired={validations.required}
            {...{ help, description, isInvalid }}>
            <Flex
                gap="3"
                flexDirection={isMobile ? 'column' : 'row'}
                className="dateRange">
                <Box flex="1" mr={isMobile ? 0 : 2} mb={isMobile ? 2 : 0}>
                    <InputDate
                        isInvalid={isInvalid}
                        validations={restValidation}
                        id={`${id}StartDate`}
                        name={`${id}.startDate`}
                        useClickOutsideMode={useClickOutsideMode}
                        {...dateStart}
                    />
                </Box>
                <Box flex="1" mr={isMobile ? 0 : 1}>
                    <InputDate
                        isInvalid={isInvalid}
                        validations={restValidation}
                        id={`${id}EndDate`}
                        name={`${id}.endDate`}
                        useClickOutsideMode={useClickOutsideMode}
                        {...dateEnd}
                    />
                </Box>
            </Flex>

            {errorStatus && (
                <FormErrorMessage>
                    <SafeFormattedMessage
                        {...validationFormsMessages[
                            `dateRange${capitalize(errorStatus)}`
                        ]}
                        values={{
                            range:
                                errorStatus === 'min'
                                    ? dateRangeMin
                                    : dateRangeMax,
                        }}
                        debugKey={errorStatus}
                    />
                </FormErrorMessage>
            )}
        </Fieldset>
    );
}
