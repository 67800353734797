import { Icon, IconProps } from '@chakra-ui/react';

export default function KeyIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M7.125 13.45a1.375 1.375 0 1 0 0-2.75 1.375 1.375 0 0 0 0 2.75z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="m14.77 15.012.239-.288.405.38a1 1 0 0 0 1.39-.022l.475-.474.677.545a1 1 0 0 0 1.283-.024l1.957-1.701a2 2 0 0 0 .13-2.896l-1.564-1.625a1 1 0 0 0-.72-.307h-7.017a5.583 5.583 0 1 0-.001 6.768l1.973.006a1 1 0 0 0 .773-.362zM4 11.983a3.583 3.583 0 0 1 6.645-1.862l.293.479h7.678l1.268 1.319-1.325 1.151-.723-.582a1 1 0 0 0-1.334.07l-.426.425-.475-.446a1 1 0 0 0-1.455.092l-.615.744-2.592-.008-.294.48A3.583 3.583 0 0 1 4 11.983z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
