import { Box, Text, HStack } from '@chakra-ui/react';
import { useSafeIntl, Validations } from 'core';
import { defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    numberOfRequiredLowerLetters: {
        id: 'components.forms.widgets.password-indications.numberOfRequiredLowerLetters',
        defaultMessage:
            '{noOf, plural, one {# minuscule} other {# minuscules}}',
    },
    lengthMin: {
        id: 'components.forms.widgets.password-indications.lengthMin',
        defaultMessage:
            '{noOf, plural, one {# caractère} other {# caractères}} minimum',
    },
    lengthMax: {
        id: 'components.forms.widgets.password-indications.lengthMax',
        defaultMessage:
            '{noOf, plural, one {# caractère} other {# caractères}} maximum',
    },
    numberOfRequiredCapitalLetters: {
        id: 'components.forms.widgets.password-indications.numberOfRequiredCapitalLetters',
        defaultMessage:
            '{noOf, plural, one {# majuscule} other {# majuscules}}',
    },
    numberOfRequiredNumbers: {
        id: 'components.forms.widgets.password-indications.numberOfRequiredNumbers',
        defaultMessage: '{noOf, plural, one {# chiffre} other {# chiffres}}',
    },
    numberOfRequiredSpecialCharacters: {
        id: 'components.forms.widgets.password-indications.numberOfRequiredSpecialCharacters',
        defaultMessage:
            '{noOf, plural, one {# caractère spécial} other {# caractères spéciaux}}',
    },
    numberOfRequiredLowercaseLetters: {
        id: 'components.forms.widgets.password-indications.numberOfRequiredLowercaseLetters',
        defaultMessage:
            '{noOf, plural, one {# minuscule} other {# minuscules}}',
    },
});

function Validation({
    name,
    isValid,
    noOf,
}: {
    name: keyof Validations;
    isValid: boolean;
    noOf: string | number | boolean | string[];
}) {
    const { safeFormatMessage } = useSafeIntl();
    return (
        <HStack alignItems="center">
            <Box
                w="8px"
                h="8px"
                rounded="full"
                bgColor={isValid ? 'primary.main' : 'grey.200'}
            />
            <Text
                data-testid="validatorIndication"
                aria-invalid={!isValid}
                color={isValid ? 'black' : 'grey.200'}>
                {safeFormatMessage(intlMessages[name], { noOf }, name)}
            </Text>
        </HStack>
    );
}

export { Validation };
