import { preventionMessages } from 'lib/preventions';
import { defineMessages } from 'react-intl';
import { ConsentModalEnum } from './types';

export const consentModalMessages = {
    [ConsentModalEnum.HOME_CONSULTATION]: defineMessages({
        title: {
            id: 'workflow-home-care.foreword.title',
            defaultMessage: 'Soins à domicile',
        },
        description: {
            id: 'workflow-home-care.foreword.description',
            defaultMessage:
                "Je cherche une infirmière, un kinésithérapeute, une sage-femme pour bénéficier d'un soin à domicile.",
        },
        partnership: {
            id: 'workflow-home-care.foreword.partnership',
            defaultMessage:
                'Ce service de soin à domicile est en partenariat avec Médicalib.',
        },
        agreement: {
            id: 'workflow-home-care.foreword.agreements',
            defaultMessage:
                'Je consens au recueil, par Predica, de mes données à caractère personnel et données de santé et à leur transmission pour traitement à Medicalib, partenaire responsable de la réalisation et du traitement de la mise en relation pour soins à domicile. Vos données sont obligatoires pour vous permettre d’accéder au service. Predica effectue uniquement la transmission de vos données de santé sans aucun stockage de sa part. Vous pouvez à tout moment exercer vos droits auprès de dpo@medicalib.fr. Pour en savoir plus sur le traitement des données vous pouvez consulter la politique en ligne https://www.medicalib.fr/donnees-personnelles/',
        },
    }),
    [ConsentModalEnum.IMMEDIATE_EXCHANGE]: defineMessages({
        title: {
            id: 'workflow-teleconsultation.foreword.fast-workflow.title',
            defaultMessage: 'Échanger avec un médecin généraliste',
        },
        description: {
            id: 'workflow-teleconsultation.foreword.fast-workflow.description',
            defaultMessage:
                'Être rappelé sous 30 min pour échanger avec un professionnel de santé qualifié pour obtenir un diagnostic personnalisé.',
        },
    }),
    [ConsentModalEnum.IMMEDIATE_EXCHANGE_V2]: defineMessages({
        title: {
            id: 'workflow-teleconsultation.foreword.fast-workflow.title',
            defaultMessage: 'Échanger avec un médecin généraliste',
        },
        description: {
            id: 'workflow-teleconsultation.foreword.fast-workflow.description',
            defaultMessage:
                'Être rappelé sous 30 min pour échanger avec un professionnel de santé qualifié pour obtenir un diagnostic personnalisé.',
        },
    }),
    [ConsentModalEnum.APPOINTMENT]: defineMessages({
        title: {
            id: 'workflow-teleconsultation.foreword.appointment-workflow.title',
            defaultMessage:
                'Prendre un rendez-vous avec un professionnel de santé',
        },
        description: {
            id: 'workflow-teleconsultation.foreword.appointment-workflow.description',
            defaultMessage:
                'Prendre rendez-vous pour une consultation médicale à distance et obtenir un diagnostic personnalisé.',
        },
    }),
    [ConsentModalEnum.WRITTEN_EXCHANGE]: defineMessages({
        title: {
            id: 'workflow-teleconsultation.foreword.written-workflow-title',
            defaultMessage: 'Écrire à un professionnel de santé',
        },
        description: {
            id: 'workflow-teleconsultation.foreword.written-workflow.description',
            defaultMessage:
                'Envoyer un message à un professionnel de santé et obtenir une réponse personnalisée en fonction de mon besoin.',
        },
        agreement: {
            id: 'workflow-teleconsultation.foreword.agreements.do-written-teleconsultation',
            defaultMessage:
                "Je demande la réalisation d'une consultation médicale par écrit.",
        },
    }),
    [ConsentModalEnum.WRITTEN_EXCHANGE_V2]: defineMessages({
        title: {
            id: 'workflow-teleconsultation.foreword.written-workflow-title',
            defaultMessage: 'Écrire à un professionnel de santé',
        },
        description: {
            id: 'workflow-teleconsultation.foreword.written-workflow.description',
            defaultMessage:
                'Envoyer un message à un professionnel de santé et obtenir une réponse personnalisée en fonction de mon besoin.',
        },
        agreement: {
            id: 'workflow-teleconsultation.foreword.agreements.do-written-teleconsultation',
            defaultMessage:
                "Je demande la réalisation d'une consultation médicale par écrit.",
        },
    }),
    [ConsentModalEnum.PREVENTION]: {
        title: preventionMessages.title,
        ...defineMessages({
            description: {
                id: 'workflow-teleconsultation.foreword.prevention-workflow.description',
                defaultMessage:
                    'Être mis en relation avec un professionnel qualifié pour répondre à vos questions sur votre santé.',
            },
        }),
    },
    shared: defineMessages({
        partnership: {
            id: 'workflow-teleconsultation.foreword.partnership',
            defaultMessage:
                "Les médecins d'Europ Assistance répondront à vos questions.",
        },
        partnershipPreventions: {
            id: 'workflow-teleconsultation.foreword.partnership-preventions',
            defaultMessage:
                "Les infirmiers d'Europ Assistance répondront à vos questions.",
        },
        agreement: {
            id: 'workflow-teleconsultation.foreword.agreements.do-teleconsultation',
            defaultMessage:
                "Je demande la réalisation d'une consultation à distance.",
        },
        dataShare: {
            id: 'workflow-teleconsultation.foreword.agreements.share-data',
            defaultMessage:
                'Je consens au recueil, par Predica, de mes données à caractère personnel et données de santé et à leur transmission pour traitement à Europ Assistance, partenaire responsable de la réalisation et du traitement de téléconsultation à distance. Vos données sont obligatoires pour vous permettre d’accéder au service. Predica effectue uniquement la transmission de vos données de santé sans aucun stockage de sa part. Vous pouvez à tout moment exercer votre droit de retrait du consentement à la collecte et au traitement des données de santé par Europ Assistance auprès de protectiondesdonnees@europ-assistance.fr',
        },
        exclusionsTitle: {
            id: 'workflow-teleconsultation.foreword.exclusions-tile',
            defaultMessage:
                "Ce service n'est pas un service d'urgence et il ne peut pas être utilisé pour les raisons suivantes :",
        },
        consentLabel: {
            id: 'workflow-teleconsultation.foreword.agreements.label',
            defaultMessage:
                'Pour utiliser ce service, nous avons besoin de votre consentement :',
        },
    }),
};

export const benefitsMessages = {
    [ConsentModalEnum.HOME_CONSULTATION]: defineMessages({
        specifiedRequest: {
            id: 'workflow-home-care.foreword.benefits.request-specified',
            defaultMessage: 'Je précise ma demande en ligne',
        },
        fastService: {
            id: 'workflow-home-care.foreword.benefits.fast-service',
            defaultMessage: "J'obtiens une réponse en moins de deux heures",
        },
        quickAccessService: {
            id: 'workflow-home-care.foreword.benefits.quick-access-service',
            defaultMessage: 'Service rapide d’accès',
        },
    }),
    [ConsentModalEnum.IMMEDIATE_EXCHANGE]: defineMessages({
        diagnosis: {
            id: 'workflow-teleconsultation.foreword.benefits.medical-diagnosis',
            defaultMessage: 'Obtenir un diagnostic médical',
        },
        recall: {
            id: 'workflow-teleconsultation.foreword.benefits.quick-recall',
            defaultMessage: 'Être contacté en moins de 30 minutes',
        },
        remote: {
            id: 'workflow-teleconsultation.foreword.benefits.remote',
            defaultMessage: 'Ne pas avoir à me déplacer',
        },
    }),
    [ConsentModalEnum.APPOINTMENT]: defineMessages({
        diagnosis: {
            id: 'workflow-teleconsultation.foreword.benefits.diagnosis-from-specialist',
            defaultMessage: "Obtenir le diagnostic d'un spécialiste",
        },
        fastAndFreeService: {
            id: 'workflow-teleconsultation.foreword.benefits.fast-and-free-service',
            defaultMessage: 'Service rapide et sans frais',
        },
        remote: {
            id: 'workflow-teleconsultation.foreword.benefits.remote',
            defaultMessage: 'Ne pas avoir à vous déplacer',
        },
    }),
    [ConsentModalEnum.WRITTEN_EXCHANGE]: defineMessages({
        specifiedRequest: {
            id: 'workflow-teleconsultation.foreword.benefits.fast-diagnosis',
            defaultMessage: 'Obtenir un avis médical (ordonnance possible)',
        },
        fastService: {
            id: 'workflow-teleconsultation.foreword.benefits.written-exchange',
            defaultMessage: 'Service rapide et sans frais',
        },
        remote: {
            id: 'workflow-teleconsultation.foreword.benefits.remote',
            defaultMessage: 'Ne pas avoir à vous déplacer',
        },
    }),
    [ConsentModalEnum.PREVENTION]: defineMessages({
        diagnosis: {
            id: 'workflow-teleconsultation.foreword.benefits.customed-advice',
            defaultMessage: 'Obtenir des conseils personnalisés',
        },
        fastAndFreeService: {
            id: 'workflow-teleconsultation.foreword.benefits.fast-and-free-service',
            defaultMessage: 'Service rapide et sans frais',
        },
        remote: {
            id: 'workflow-teleconsultation.foreword.benefits.remote',
            defaultMessage: 'Ne pas avoir à vous déplacer',
        },
    }),
};

export const alertMessages = defineMessages({
    alertTitle: {
        id: 'workflow-teleconsultation.beneficiary.notifications-contact-title',
        defaultMessage: 'Coordonnées de notifications',
    },
    alertDescription: {
        id: 'workflow-teleconsultation.beneficiary.notifications-contact-description',
        defaultMessage:
            'En tant que souscripteur du contrat, des notifications concernant vos consultations vous seront envoyées par SMS et par mail. Merci de mettre à jour vos coordonnées.',
    },
    alertInvitedBeneficiaryDescription: {
        id: 'workflow-teleconsultation.beneficiary.notifications-contact-description-beneficiairy',
        defaultMessage:
            'Vous allez recevoir des notifications concernant votre consultation par SMS et/ou par mail, merci de mettre à jour vos coordonnées :',
    },
});
