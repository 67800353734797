import React from 'react';
import Head from 'next/head';
import { MessageDescriptor, useIntl } from 'react-intl';
import { UtilsContext } from 'core';

export type HeadTitleProps = {
    title?: MessageDescriptor | string;
};

export function HeadTitle({ title }: HeadTitleProps) {
    const {
        siteConfig: { appName },
    } = React.useContext(UtilsContext);
    const intl = useIntl();

    if (!title)
        return (
            <Head>
                <title key="title">{appName}</title>
            </Head>
        );

    return (
        <Head>
            <title key="title">
                {typeof title === 'string'
                    ? title
                    : intl.formatMessage(title, { appName })}{' '}
                | {appName}
            </title>
        </Head>
    );
}
