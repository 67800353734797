function getUserInitials(
    lastName: string | null | undefined,
    firstName: string | null | undefined
) {
    if (!firstName && !lastName) {
        return;
    }

    if (!firstName) {
        return `${lastName?.substring(0, 1)}`;
    }

    return `${firstName.substr(0, 1)}${lastName?.substring(0, 1)}`;
}

export { getUserInitials };
