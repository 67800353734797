import { Country } from '.';

const countryPhoneCodesFR: Country[] = [
    {
        name: 'France',
        dial_code: '+33',
        code: 'FR',
    },
    {
        name: 'Afghanistan',
        dial_code: '+93',
        code: 'AF',
    },
    {
        name: 'Afrique du Sud',
        dial_code: '+27',
        code: 'ZA',
    },
    {
        name: 'Albanie',
        dial_code: '+355',
        code: 'AL',
    },
    {
        name: 'Algérie',
        dial_code: '+213',
        code: 'DZ',
    },
    {
        name: 'Allemagne',
        dial_code: '+49',
        code: 'DE',
    },
    {
        name: 'Andorre',
        dial_code: '+376',
        code: 'AD',
    },
    {
        name: 'Angola',
        dial_code: '+244',
        code: 'AO',
    },
    {
        name: 'Anguilla',
        dial_code: '+1264',
        code: 'AI',
    },
    {
        name: 'Antigua-et-Barbuda',
        dial_code: '+1268',
        code: 'AG',
    },
    {
        name: 'Arabie saoudite',
        dial_code: '+966',
        code: 'SA',
    },
    {
        name: 'Argentine',
        dial_code: '+54',
        code: 'AR',
    },
    {
        name: 'Arménie',
        dial_code: '+374',
        code: 'AM',
    },
    {
        name: 'Aruba',
        dial_code: '+297',
        code: 'AW',
    },
    {
        name: 'Australie',
        dial_code: '+61',
        code: 'AU',
    },
    {
        name: 'Autriche',
        dial_code: '+43',
        code: 'AT',
    },
    {
        name: 'Azerbaïdjan',
        dial_code: '+994',
        code: 'AZ',
    },
    {
        name: 'Bahamas',
        dial_code: '+1242',
        code: 'BS',
    },
    {
        name: 'Bahreïn',
        dial_code: '+973',
        code: 'BH',
    },
    {
        name: 'Bangladesh',
        dial_code: '+880',
        code: 'BD',
    },
    {
        name: 'Barbade',
        dial_code: '+1246',
        code: 'BB',
    },
    {
        name: 'Bélarus',
        dial_code: '+375',
        code: 'BY',
    },
    {
        name: 'Belgique',
        dial_code: '+32',
        code: 'BE',
    },
    {
        name: 'Belize',
        dial_code: '+501',
        code: 'BZ',
    },
    {
        name: 'Bénin',
        dial_code: '+229',
        code: 'BJ',
    },
    {
        name: 'Bermudes',
        dial_code: '+1441',
        code: 'BM',
    },
    {
        name: 'Bhoutan',
        dial_code: '+975',
        code: 'BT',
    },
    {
        name: 'Bolivie',
        dial_code: '+591',
        code: 'BO',
    },
    {
        name: 'Bosnie-Herzégovine',
        dial_code: '+387',
        code: 'BA',
    },
    {
        name: 'Botswana',
        dial_code: '+267',
        code: 'BW',
    },
    {
        name: 'Brésil',
        dial_code: '+55',
        code: 'BR',
    },
    {
        name: 'Brunéi Darussalam',
        dial_code: '+673',
        code: 'BN',
    },
    {
        name: 'Bulgarie',
        dial_code: '+359',
        code: 'BG',
    },
    {
        name: 'Burkina Faso',
        dial_code: '+226',
        code: 'BF',
    },
    {
        name: 'Burundi',
        dial_code: '+257',
        code: 'BI',
    },
    {
        name: 'Cambodge',
        dial_code: '+855',
        code: 'KH',
    },
    {
        name: 'Cameroun',
        dial_code: '+237',
        code: 'CM',
    },
    {
        name: 'Canada',
        dial_code: '+1',
        code: 'CA',
    },
    {
        name: 'Cap-Vert',
        dial_code: '+238',
        code: 'CV',
    },
    {
        name: 'Chili',
        dial_code: '+56',
        code: 'CL',
    },
    {
        name: 'Chine',
        dial_code: '+86',
        code: 'CN',
    },
    {
        name: 'Chypre',
        dial_code: '+357',
        code: 'CY',
    },
    {
        name: 'Colombie',
        dial_code: '+57',
        code: 'CO',
    },
    {
        name: 'Comores',
        dial_code: '+269',
        code: 'KM',
    },
    {
        name: 'Congo',
        dial_code: '+242',
        code: 'CG',
    },
    {
        name: 'Congo',
        dial_code: '+243',
        code: 'CD',
    },
    {
        name: 'Corée, République de Corée',
        dial_code: '+82',
        code: 'KR',
    },
    {
        name: 'Corée, République populaire démocratique de Corée',
        dial_code: '+850',
        code: 'KP',
    },
    {
        name: 'Costa Rica',
        dial_code: '+506',
        code: 'CR',
    },
    {
        name: "Côte d'Ivoire",
        dial_code: '+225',
        code: 'CI',
    },
    {
        name: 'Croatie',
        dial_code: '+385',
        code: 'HR',
    },
    {
        name: 'Cuba',
        dial_code: '+53',
        code: 'CU',
    },
    {
        name: 'Danemark',
        dial_code: '+45',
        code: 'DK',
    },
    {
        name: 'Djibouti',
        dial_code: '+253',
        code: 'DJ',
    },
    {
        name: 'Dominique',
        dial_code: '+1767',
        code: 'DM',
    },
    {
        name: 'Égypte',
        dial_code: '+20',
        code: 'EG',
    },
    {
        name: 'Émirats arabes unis',
        dial_code: '+971',
        code: 'AE',
    },
    {
        name: 'Équateur',
        dial_code: '+593',
        code: 'EC',
    },
    {
        name: 'Érythrée',
        dial_code: '+291',
        code: 'ER',
    },
    {
        name: 'Espagne',
        dial_code: '+34',
        code: 'ES',
    },
    {
        name: 'Estonie',
        dial_code: '+372',
        code: 'EE',
    },
    {
        name: 'États-Unis',
        dial_code: '+1',
        code: 'US',
    },
    {
        name: 'Éthiopie',
        dial_code: '+251',
        code: 'ET',
    },
    {
        name: 'Fidji',
        dial_code: '+679',
        code: 'FJ',
    },
    {
        name: 'Finlande',
        dial_code: '+358',
        code: 'FI',
    },
    {
        name: 'Gabon',
        dial_code: '+241',
        code: 'GA',
    },
    {
        name: 'Gambie',
        dial_code: '+220',
        code: 'GM',
    },
    {
        name: 'Géorgie',
        dial_code: '+995',
        code: 'GE',
    },
    {
        name: 'Ghana',
        dial_code: '+233',
        code: 'GH',
    },
    {
        name: 'Gibraltar',
        dial_code: '+350',
        code: 'GI',
    },
    {
        name: 'Grèce',
        dial_code: '+30',
        code: 'GR',
    },
    {
        name: 'Grenade',
        dial_code: '+1473',
        code: 'GD',
    },
    {
        name: 'Groenland',
        dial_code: '+299',
        code: 'GL',
    },
    {
        name: 'Guadeloupe',
        dial_code: '+590',
        code: 'GP',
    },
    {
        name: 'Guam',
        dial_code: '+1671',
        code: 'GU',
    },
    {
        name: 'Guatemala',
        dial_code: '+502',
        code: 'GT',
    },
    {
        name: 'Guernesey',
        dial_code: '+44',
        code: 'GG',
    },
    {
        name: 'Guinée',
        dial_code: '+224',
        code: 'GN',
    },
    {
        name: 'Guinée équatoriale',
        dial_code: '+240',
        code: 'GQ',
    },
    {
        name: 'Guinée-Bissau',
        dial_code: '+245',
        code: 'GW',
    },
    {
        name: 'Guyane',
        dial_code: '+592',
        code: 'GY',
    },
    {
        name: 'Haïti',
        dial_code: '+509',
        code: 'HT',
    },
    {
        name: 'Honduras',
        dial_code: '+504',
        code: 'HN',
    },
    {
        name: 'Hong Kong',
        dial_code: '+852',
        code: 'HK',
    },
    {
        name: 'Hongrie',
        dial_code: '+36',
        code: 'HU',
    },
    {
        name: 'Île Christmas',
        dial_code: '+61',
        code: 'CX',
    },
    {
        name: 'Île de Man',
        dial_code: '+44',
        code: 'IM',
    },
    {
        name: 'Île Norfolk',
        dial_code: '+672',
        code: 'NF',
    },
    {
        name: 'Îles Åland',
        dial_code: '+358',
        code: 'AX',
    },
    {
        name: 'Îles Caïmans',
        dial_code: '+345',
        code: 'KY',
    },
    {
        name: 'Îles Cocos (Keeling)',
        dial_code: '+61',
        code: 'CC',
    },
    {
        name: 'Îles Cook',
        dial_code: '+682',
        code: 'CK',
    },
    {
        name: 'Îles Falkland (Malvinas)',
        dial_code: '+500',
        code: 'FK',
    },
    {
        name: 'Îles Féroé',
        dial_code: '+298',
        code: 'FO',
    },
    {
        name: 'Îles Mariannes du Nord',
        dial_code: '+1670',
        code: 'MP',
    },
    {
        name: 'Îles Marshall',
        dial_code: '+692',
        code: 'MH',
    },
    {
        name: 'Îles Salomon',
        dial_code: '+677',
        code: 'SB',
    },
    {
        name: 'Îles Turques-et-Caïques',
        dial_code: '+1649',
        code: 'TC',
    },
    {
        name: 'Îles Vierges américaines',
        dial_code: '+1340',
        code: 'VI',
    },
    {
        name: 'Îles Vierges britanniques',
        dial_code: '+1284',
        code: 'VG',
    },
    {
        name: 'Inde',
        dial_code: '+91',
        code: 'IN',
    },
    {
        name: 'Indonésie',
        dial_code: '+62',
        code: 'ID',
    },
    {
        name: 'Irak',
        dial_code: '+964',
        code: 'IQ',
    },
    {
        name: 'Iran',
        dial_code: '+98',
        code: 'IR',
    },
    {
        name: 'Irlande',
        dial_code: '+353',
        code: 'IE',
    },
    {
        name: 'Islande',
        dial_code: '+354',
        code: 'IS',
    },
    {
        name: 'Israël',
        dial_code: '+972',
        code: 'IL',
    },
    {
        name: 'Italie',
        dial_code: '+39',
        code: 'IT',
    },
    {
        name: 'Jamahiriya arabe libyenne',
        dial_code: '+218',
        code: 'LY',
    },
    {
        name: 'Jamaïque',
        dial_code: '+1876',
        code: 'JM',
    },
    {
        name: 'Japon',
        dial_code: '+81',
        code: 'JP',
    },
    {
        name: 'Jersey',
        dial_code: '+44',
        code: 'JE',
    },
    {
        name: 'Jordanie',
        dial_code: '+962',
        code: 'JO',
    },
    {
        name: 'Kazakhstan',
        dial_code: '+7',
        code: 'KZ',
    },
    {
        name: 'Kenya',
        dial_code: '+254',
        code: 'KE',
    },
    {
        name: 'Kirghizistan',
        dial_code: '+996',
        code: 'KG',
    },
    {
        name: 'Kiribati',
        dial_code: '+686',
        code: 'KI',
    },
    {
        name: 'Koweït',
        dial_code: '+965',
        code: 'KW',
    },
    {
        name: 'Lesotho',
        dial_code: '+266',
        code: 'LS',
    },
    {
        name: 'Lettonie',
        dial_code: '+371',
        code: 'LV',
    },
    {
        name: 'Liban',
        dial_code: '+961',
        code: 'LB',
    },
    {
        name: 'Libéria',
        dial_code: '+231',
        code: 'LR',
    },
    {
        name: 'Liechtenstein',
        dial_code: '+423',
        code: 'LI',
    },
    {
        name: 'Lituanie',
        dial_code: '+370',
        code: 'LT',
    },
    {
        name: 'Luxembourg',
        dial_code: '+352',
        code: 'LU',
    },
    {
        name: 'Macao',
        dial_code: '+853',
        code: 'MO',
    },
    {
        name: 'Macédoine',
        dial_code: '+389',
        code: 'MK',
    },
    {
        name: 'Madagascar',
        dial_code: '+261',
        code: 'MG',
    },
    {
        name: 'Malaisie',
        dial_code: '+60',
        code: 'MY',
    },
    {
        name: 'Malawi',
        dial_code: '+265',
        code: 'MW',
    },
    {
        name: 'Maldives',
        dial_code: '+960',
        code: 'MV',
    },
    {
        name: 'Mali',
        dial_code: '+223',
        code: 'ML',
    },
    {
        name: 'Malte',
        dial_code: '+356',
        code: 'MT',
    },
    {
        name: 'Maroc',
        dial_code: '+212',
        code: 'MA',
    },
    {
        name: 'Martinique',
        dial_code: '+596',
        code: 'MQ',
    },
    {
        name: 'Maurice',
        dial_code: '+230',
        code: 'MU',
    },
    {
        name: 'Mauritanie',
        dial_code: '+222',
        code: 'MR',
    },
    {
        name: 'Mayotte',
        dial_code: '+262',
        code: 'YT',
    },
    {
        name: 'Mexique',
        dial_code: '+52',
        code: 'MX',
    },
    {
        name: 'Micronésie, États fédérés de Micronésie',
        dial_code: '+691',
        code: 'FM',
    },
    {
        name: 'Moldavie',
        dial_code: '+373',
        code: 'MD',
    },
    {
        name: 'Monaco',
        dial_code: '+377',
        code: 'MC',
    },
    {
        name: 'Mongolie',
        dial_code: '+976',
        code: 'MN',
    },
    {
        name: 'Monténégro',
        dial_code: '+382',
        code: 'ME',
    },
    {
        name: 'Montserrat',
        dial_code: '+1664',
        code: 'MS',
    },
    {
        name: 'Mozambique',
        dial_code: '+258',
        code: 'MZ',
    },
    {
        name: 'Myanmar',
        dial_code: '+95',
        code: 'MM',
    },
    {
        name: 'Namibie',
        dial_code: '+264',
        code: 'NA',
    },
    {
        name: 'Nauru',
        dial_code: '+674',
        code: 'NR',
    },
    {
        name: 'Népal',
        dial_code: '+977',
        code: 'NP',
    },
    {
        name: 'Nicaragua',
        dial_code: '+505',
        code: 'NI',
    },
    {
        name: 'Niger',
        dial_code: '+227',
        code: 'NE',
    },
    {
        name: 'Nigeria',
        dial_code: '+234',
        code: 'NG',
    },
    {
        name: 'Niue',
        dial_code: '+683',
        code: 'NU',
    },
    {
        name: 'Norvège',
        dial_code: '+47',
        code: 'NO',
    },
    {
        name: 'Nouvelle-Calédonie',
        dial_code: '+687',
        code: 'NC',
    },
    {
        name: 'Nouvelle-Zélande',
        dial_code: '+64',
        code: 'NZ',
    },
    {
        name: 'Oman',
        dial_code: '+968',
        code: 'OM',
    },
    {
        name: 'Ouganda',
        dial_code: '+256',
        code: 'UG',
    },
    {
        name: 'Ouzbékistan',
        dial_code: '+998',
        code: 'UZ',
    },
    {
        name: 'Pakistan',
        dial_code: '+92',
        code: 'PK',
    },
    {
        name: 'Palaos',
        dial_code: '+680',
        code: 'PW',
    },
    {
        name: 'Panama',
        dial_code: '+507',
        code: 'PA',
    },
    {
        name: 'Papouasie-Nouvelle-Guinée',
        dial_code: '+675',
        code: 'PG',
    },
    {
        name: 'Paraguay',
        dial_code: '+595',
        code: 'PY',
    },
    {
        name: 'Pays-Bas',
        dial_code: '+31',
        code: 'NL',
    },
    {
        name: 'Pérou',
        dial_code: '+51',
        code: 'PE',
    },
    {
        name: 'Philippines',
        dial_code: '+63',
        code: 'PH',
    },
    {
        name: 'Pologne',
        dial_code: '+48',
        code: 'PL',
    },
    {
        name: 'Porto Rico',
        dial_code: '+1939',
        code: 'PR',
    },
    {
        name: 'Portugal',
        dial_code: '+351',
        code: 'PT',
    },
    {
        name: 'Qatar',
        dial_code: '+974',
        code: 'QA',
    },
    {
        name: 'République arabe syrienne',
        dial_code: '+963',
        code: 'SY',
    },
    {
        name: 'République centrafricaine',
        dial_code: '+236',
        code: 'CF',
    },
    {
        name: 'Laos',
        dial_code: '+856',
        code: 'LA',
    },
    {
        name: 'République dominicaine',
        dial_code: '+1849',
        code: 'DO',
    },
    {
        name: 'République tchèque',
        dial_code: '+420',
        code: 'CZ',
    },
    {
        name: 'Réunion',
        dial_code: '+262',
        code: 'RE',
    },
    {
        name: 'Roumanie',
        dial_code: '+40',
        code: 'RO',
    },
    {
        name: 'Royaume-Uni',
        dial_code: '+44',
        code: 'GB',
    },
    {
        name: 'Russie',
        dial_code: '+7',
        code: 'RU',
    },
    {
        name: 'Rwanda',
        dial_code: '+250',
        code: 'RW',
    },
    {
        name: 'Saint-Barthélemy',
        dial_code: '+590',
        code: 'BL',
    },
    {
        name: 'Saint-Kitts-et-Nevis',
        dial_code: '+1869',
        code: 'KN',
    },
    {
        name: 'Saint-Marin',
        dial_code: '+378',
        code: 'SM',
    },
    {
        name: 'Saint-Martin',
        dial_code: '+590',
        code: 'MF',
    },
    {
        name: 'Saint-Pierre-et-Miquelon',
        dial_code: '+508',
        code: 'PM',
    },
    {
        name: 'Saint-Vincent-et-les Grenadines',
        dial_code: '+1784',
        code: 'VC',
    },
    {
        name: 'Sainte-Hélène, Ascension et Tristan da Cunha',
        dial_code: '+290',
        code: 'SH',
    },
    {
        name: 'Sainte-Lucie',
        dial_code: '+1758',
        code: 'LC',
    },
    {
        name: 'Salvador',
        dial_code: '+503',
        code: 'SV',
    },
    {
        name: 'Samoa',
        dial_code: '+685',
        code: 'WS',
    },
    {
        name: 'Samoa américaines',
        dial_code: '+1684',
        code: 'AS',
    },
    {
        name: 'Sao Tomé-et-Principe',
        dial_code: '+239',
        code: 'ST',
    },
    {
        name: 'Sénégal',
        dial_code: '+221',
        code: 'SN',
    },
    {
        name: 'Serbie',
        dial_code: '+381',
        code: 'RS',
    },
    {
        name: 'Seychelles',
        dial_code: '+248',
        code: 'SC',
    },
    {
        name: 'Sierra Leone',
        dial_code: '+232',
        code: 'SL',
    },
    {
        name: 'Singapour',
        dial_code: '+65',
        code: 'SG',
    },
    {
        name: 'Slovaquie',
        dial_code: '+421',
        code: 'SK',
    },
    {
        name: 'Slovénie',
        dial_code: '+386',
        code: 'SI',
    },
    {
        name: 'Somalie',
        dial_code: '+252',
        code: 'SO',
    },
    {
        name: 'Soudan',
        dial_code: '+249',
        code: 'SD',
    },
    {
        name: 'Suède',
        dial_code: '+46',
        code: 'SE',
    },
    {
        name: 'Suisse',
        dial_code: '+41',
        code: 'CH',
    },
    {
        name: 'Suriname',
        dial_code: '+597',
        code: 'SR',
    },
    {
        name: 'Svalbard et Jan Mayen',
        dial_code: '+47',
        code: 'SJ',
    },
    {
        name: 'Swaziland',
        dial_code: '+268',
        code: 'SZ',
    },
    {
        name: 'Tadjikistan',
        dial_code: '+992',
        code: 'TJ',
    },
    {
        name: 'Taïwan',
        dial_code: '+886',
        code: 'TW',
    },
    {
        name: 'Tanzanie',
        dial_code: '+255',
        code: 'TZ',
    },
    {
        name: 'Tchad',
        dial_code: '+235',
        code: 'TD',
    },
    {
        name: "Territoire britannique de l'océan Indien",
        dial_code: '+246',
        code: 'IO',
    },
    {
        name: 'Territoire palestinien occupé',
        dial_code: '+970',
        code: 'PS',
    },
    {
        name: 'Thaïlande',
        dial_code: '+66',
        code: 'TH',
    },
    {
        name: 'Timor-Leste',
        dial_code: '+670',
        code: 'TL',
    },
    {
        name: 'Togo',
        dial_code: '+228',
        code: 'TG',
    },
    {
        name: 'Tokelau',
        dial_code: '+690',
        code: 'TK',
    },
    {
        name: 'Tonga',
        dial_code: '+676',
        code: 'TO',
    },
    {
        name: 'Trinité-et-Tobago',
        dial_code: '+1868',
        code: 'TT',
    },
    {
        name: 'Tunisie',
        dial_code: '+216',
        code: 'TN',
    },
    {
        name: 'Turkménistan',
        dial_code: '+993',
        code: 'TM',
    },
    {
        name: 'Turquie',
        dial_code: '+90',
        code: 'TR',
    },
    {
        name: 'Tuvalu',
        dial_code: '+688',
        code: 'TV',
    },
    {
        name: 'Ukraine',
        dial_code: '+380',
        code: 'UA',
    },
    {
        name: 'Uruguay',
        dial_code: '+598',
        code: 'UY',
    },
    {
        name: 'Vanuatu',
        dial_code: '+678',
        code: 'VU',
    },
    {
        name: 'Venezuela',
        dial_code: '+58',
        code: 'VE',
    },
    {
        name: 'Vietnam',
        dial_code: '+84',
        code: 'VN',
    },
    {
        name: 'Wallis-et-Futuna',
        dial_code: '+681',
        code: 'WF',
    },
    {
        name: 'Yémen',
        dial_code: '+967',
        code: 'YE',
    },
    {
        name: 'Zambie',
        dial_code: '+260',
        code: 'ZM',
    },
    {
        name: 'Zimbabwe',
        dial_code: '+263',
        code: 'ZW',
    },
];

export default countryPhoneCodesFR;
