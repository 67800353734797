import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import { Box, Button } from '@chakra-ui/react';
import { coreSharedMessages, ErrorIllustration } from 'core';
import ErrorAccordion from 'core/components/errors/ErrorAccordion';

export function ErrorPage({ error }: { error: Error }) {
    const router = useRouter();

    return (
        <ErrorIllustration
            width={400}
            height={300}
            px="4"
            sx={{
                '> * + *': {
                    mt: '10',
                },
            }}>
            <ErrorAccordion message={error.message} textAlign="center" />

            <Box textAlign="center" mt="6">
                <Button variant="outline" onClick={router.back}>
                    <FormattedMessage {...coreSharedMessages.back} />
                </Button>
            </Box>
        </ErrorIllustration>
    );
}
