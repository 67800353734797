const filteredKeys = ['username', 'password'];

const filterRequestData = (data) => {
    const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
        if (typeof value === 'object') {
            return {
                ...acc,
                [key]: filterRequestData(data[key]),
            };
        }

        return {
            ...acc,
            [key]: filteredKeys.includes(key) ? 'SDKFilter' : value,
        };
    }, {});

    return JSON.stringify(filteredData);
};

const trimUrlWithParams = (url) => {
    return url.replace(/&+$/, '');
};

const replaceUrl = (url) => {
    filteredKeys.forEach((filteredKey) => {
        const regex = new RegExp(`[?|&]${filteredKey}=[^&]*`);
        const match = url.match(regex);

        if (match?.[0]?.includes(filteredKey)) {
            url = url?.replace(regex, `?${filteredKey}=SDKFilter&`);
        }
    });

    return trimUrlWithParams(url);
};

const filterUrlAndReferer = (event) => {
    if (event.request?.headers || event.request?.url) {
        filteredKeys.forEach((filteredKey) => {
            const regex = new RegExp(`[?|&]${filteredKey}=[^&]*`);
            const match =
                event.request?.url?.match(regex) ||
                event.request.headers?.referer?.match(regex);

            if (match?.[0]?.includes(filteredKey)) {
                const replacedReferer = (
                    event.request.headers.Referer ||
                    event.request.headers.referer
                )?.replace(regex, `?${filteredKey}=SDKFilter&`);

                if (event.request.headers.Referer) {
                    event.request.headers.Referer =
                        trimUrlWithParams(replacedReferer);
                }
                if (event.request.headers.referer) {
                    event.request.headers.referer =
                        trimUrlWithParams(replacedReferer);
                }

                const replacedUrl = event.request.url?.replace(
                    regex,
                    `?${filteredKey}=SDKFilter&`
                );
                event.request.url = trimUrlWithParams(replacedUrl);
            }
        });
    }
};

const filterBreadcrumbs = (event) => {
    if (event?.breadcrumbs) {
        event.breadcrumbs.forEach((breadcrumb, idx) => {
            switch (breadcrumb.category) {
                case 'navigation':
                    event.breadcrumbs[idx].data = {
                        ...event.breadcrumbs[idx]?.data,
                        from: replaceUrl(event.breadcrumbs[idx]?.data?.from),
                        to: replaceUrl(event.breadcrumbs[idx]?.data?.to),
                    };
                    break;
            }
        });
    }
};

const filterSensitiveData = (event) => {
    if (!event) return event;

    if (event?.request?.data) {
        try {
            const data = JSON.parse(event.request.data);
            event.request.data = filterRequestData(data);
        } catch (err) {
            event.request.data = null;
        }
    }

    filterUrlAndReferer(event);
    filterBreadcrumbs(event);

    return event;
};

module.exports = { filterSensitiveData };
