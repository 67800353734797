import axios from 'axios';
import { omit } from 'lodash';
import { WorkflowTeleconsultationData } from 'lib/teleconsultation';

const path = '/api/proxy/europ-assistance/teleconsultations';

export const TIME_SLOT_ERROR = {
    value: 'time-slot',
    code: 409,
};

export function postTeleconsultation(
    data: WorkflowTeleconsultationData,
    keysToOmit = []
) {
    const formattedData = {
        ...omit(data, [...keysToOmit, 'currentStep', 'themeId']),
        specialityId: data?.themeId,
        phone: data?.phone?.value,
        recallPhoneNumber: data?.recallPhoneNumber?.value,
    };
    return axios.post(path, formattedData);
}
