import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useToast } from 'design-system/components/Toast';

export function useDisabledOptionalContentToast(
    showToast: boolean,
    toastMessage: MessageDescriptor
) {
    const { formatMessage } = useIntl();

    const errorToast = useToast({
        status: 'error',
        description: formatMessage(toastMessage),
    });

    React.useEffect(() => {
        if (showToast) {
            errorToast();
        }
    }, [showToast, errorToast]);
}
