import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { validationFormsMessages, Validations, FormatMessageFn } from 'core';
import { isNumber } from 'lodash';

type DateRangeObject = { startDate: string; endDate: string };

dayjs.extend(customParseFormat);

function validateDateMax(
    ISODateMax: Validations['dateMax'],
    formatMessage: FormatMessageFn
) {
    return (value: string) => {
        if (!ISODateMax) return undefined;

        const ISOUserDate = dayjs(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const isAfter = dayjs(ISOUserDate).isAfter(ISODateMax);
        return isAfter
            ? formatMessage(validationFormsMessages.dateMax)
            : undefined;
    };
}

function validateDateMin(
    ISODateMin: Validations['dateMin'],
    formatMessage: FormatMessageFn
) {
    return (value: string) => {
        if (!ISODateMin) return undefined;

        const ISOUserDate = dayjs(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const isBefore = dayjs(ISOUserDate).isBefore(ISODateMin);
        return isBefore
            ? formatMessage(validationFormsMessages.dateMin)
            : undefined;
    };
}

function validateDateRangeMin(values: DateRangeObject, dateRangeMin?: number) {
    if (!isNumber(dateRangeMin)) return true;

    const ISOstartDate = dayjs(values.startDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
    );
    const ISOEndDate = dayjs(values.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const dateDiff = dayjs(ISOEndDate).diff(ISOstartDate, 'day');
    return dateDiff >= dateRangeMin;
}

function validateDateRangeMax(values: DateRangeObject, dateRangeMax?: number) {
    if (!isNumber(dateRangeMax)) return true;

    const ISOstartDate = dayjs(values.startDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
    );
    const ISOEndDate = dayjs(values.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const dateDiff = dayjs(ISOEndDate).diff(ISOstartDate, 'day');
    return dateDiff <= dateRangeMax;
}

function validateOpenDays(openDays: boolean, formatMessage: FormatMessageFn) {
    return (value: string) => {
        if (!openDays) return undefined;

        const ISOUserDate = dayjs(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const isOpenDay = ![0, 6].includes(dayjs(ISOUserDate).day());
        return !isOpenDay
            ? formatMessage(validationFormsMessages.openDays)
            : undefined;
    };
}

export {
    validateDateMax,
    validateDateMin,
    validateOpenDays,
    validateDateRangeMax,
    validateDateRangeMin,
};
