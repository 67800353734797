import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { FetchQueryParams, FormObject } from 'core';

const path = `/api/proxy/provident/death-benefit/simulation`;

export async function fetchDeathCapitalForm({
    queryKey: [urn],
}: FetchQueryParams) {
    try {
        const response = await axios.options(urn);
        return response.data.data as FormObject;
    } catch (error) {
        throw new Error(error);
    }
}

export function useDeathCapitalForm() {
    return useQuery<FormObject, Error>({
        queryKey: [path, 'OPTIONS'],
        queryFn: main.fetchDeathCapitalForm,
    });
}

const main = {
    useDeathCapitalForm,
    fetchDeathCapitalForm,
};

export { path as formsPath };
