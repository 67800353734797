import React from 'react';
import { useRouter } from 'next/router';
import { InfiniteData } from '@tanstack/react-query';

import {
    UseRefunds,
    GroupedRefunds,
    Refunds as RefundsType,
    groupRefunds,
} from 'lib/refunds';

function useRefundsGroupingByMonth(data?: InfiniteData<UseRefunds>) {
    const { locale } = useRouter();
    const [refunds, setRefunds] = React.useState<GroupedRefunds[] | null>(null);

    React.useEffect(() => {
        async function extractAndGroupRefunds(pages: UseRefunds[]) {
            setRefunds(
                await groupRefunds(
                    pages.reduce((prev, curr) => {
                        if (!curr.refunds) {
                            return prev;
                        }
                        return [...prev, ...curr.refunds];
                    }, [] as RefundsType[])
                )
            );
        }

        if (data?.pages) extractAndGroupRefunds(data.pages);
    }, [data?.pages, locale]);

    return refunds;
}

export { useRefundsGroupingByMonth };
