import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import type { Alert } from '../types';

const path = `/api/proxy/contents/alerts`;

export async function fetchAlerts() {
    try {
        const response = await axios.get(path);
        return response.data.data as Alert[];
    } catch (error) {
        throw new Error(error);
    }
}

export function useAlerts() {
    return useQuery({ queryKey: [path], queryFn: fetchAlerts });
}
