import React from 'react';
import { contextError } from 'core';
import { Step, useSatisfactionForm } from '.';

type Props = React.PropsWithChildren<unknown>;
type Context = {
    step: Step;
    setStep: React.Dispatch<React.SetStateAction<Step>>;
    nextStepIri: string | null;
    setNextStepIri: React.Dispatch<React.SetStateAction<string | null>>;
    data: any;
};

const SatisfactionContext = React.createContext<Context | null>(null);

export function useSatisfaction() {
    const context = React.useContext(SatisfactionContext);

    if (!context) {
        throw contextError('useSatisfaction', 'SatisfactionProvider');
    }

    return context;
}

export function SatisfactionProvider({ children }: Props) {
    const [step, setStep] = React.useState<Step>('rating');
    const [nextStepIri, setNextStepIri] = React.useState<string>(null);
    const { data } = useSatisfactionForm(nextStepIri);

    return (
        <SatisfactionContext.Provider
            value={{
                step,
                setStep,
                nextStepIri,
                setNextStepIri,
                data,
            }}>
            {children}
        </SatisfactionContext.Provider>
    );
}
