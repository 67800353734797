import { Icon, IconProps } from '@chakra-ui/react';

export default function ReplyIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="m3.226 10.133 3.867 4.726a1 1 0 0 0 1.548-1.266L6.11 10.5H14c2.24 0 3.428.768 4.086 1.614.694.893 1.096 2.462.914 3.304-.153.708-.754 1.694-1.534 2.24-.214.15-.378.274-.916.56-.335.177-.55.521-.55.9v.162c0 .631.582 1.102 1.158.845C19.218 19.205 21 17.058 21 15c0-1.138-.28-2.757-1.336-4.114C18.572 9.482 16.761 8.5 14 8.5H6.11l2.53-3.093a1 1 0 1 0-1.547-1.266L3.226 8.867a1 1 0 0 0 0 1.266z"
                fill="currentColor"
            />
        </Icon>
    );
}
