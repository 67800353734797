import React from 'react';
import axios from 'axios';
import { QueryStatus } from '@tanstack/react-query';
import { useRedirect, FormWorkflow } from 'core';
import { SignatureRouterBaseIri } from '../types';

export const NUMBER_OF_PIN = 6;

export function useVerificationCode(
    procedureId: string,
    workflow: FormWorkflow,
    service,
    routerBaseIri: SignatureRouterBaseIri = ''
) {
    const [status, setStatus] = React.useState<QueryStatus>(null);
    const [statusCode, setStatusCode] = React.useState<number>();
    const [code, setCode] = React.useState('');
    const [isCodeValid, setIsCodeValid] = React.useState<boolean>();
    const [redirectFormId, setRedirectFormId] = React.useState<string>();
    const { redirect } = useRedirect();

    function handleSubmit(evt: React.FormEvent) {
        evt.preventDefault();
        setIsCodeValid(code.length === NUMBER_OF_PIN);
        if (isCodeValid) {
            postCode();
        }
    }

    function handleInput(value: string) {
        setCode(value);
        setIsCodeValid(value.length === NUMBER_OF_PIN ? true : undefined);
        setStatus(null);
    }

    async function postCode() {
        try {
            setStatus('pending');
            await axios.post(
                `/api/proxy/${service}/signature/${procedureId}/code`,
                { code },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setStatusCode(200);
            setStatus('success');
            redirect({
                pathname: `${routerBaseIri}/signature/[procedureId]/confirmation`,
                query: { procedureId, workflow },
            });
        } catch (err) {
            setStatusCode(err.response?.status);
            setRedirectFormId(err.response?.data?.meta?.redirect?.id);
            setStatus('error');
        }
    }

    return {
        status,
        handleInput,
        handleSubmit,
        isCodeValid,
        statusCode,
        redirectFormId,
    };
}
