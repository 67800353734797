import React from 'react';
import {
    useForm,
    ValidationMode,
    FieldValues,
    Resolver,
} from 'react-hook-form';
import { FormValues, FormObject, WidgetObject, postForm, BaseIri } from 'core';
import { handleDataTransformations } from './transformations/handlers';
import { getSubWidgetsTypes } from '../helpers/getSubWidgetsTypes';

type Options = Partial<{
    mode: keyof ValidationMode;
    reValidateMode: 'onBlur' | 'onChange' | 'onSubmit';
    defaultValues: { [x: string]: any };
    resolver: Resolver<FieldValues, any>;
    context: any;
    shouldFocusError: boolean;
    shouldUnregister: boolean;
    criteriaMode: 'all' | 'firstError';
}>;

function useFormValidation(
    baseIri: BaseIri | null,
    iri: string | undefined | null,
    widgets?: FormObject['widgets'],
    options: Options = {
        mode: 'onTouched',
        reValidateMode: 'onBlur',
    }
) {
    const methods = useForm(options);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [widgetTypes, setWidgetTypes] = React.useState<WidgetObject[]>();

    React.useEffect(() => {
        setWidgetTypes(
            widgets?.map((widget) => ({
                type: widget.type,
                id: widget.id,
            }))
        );
    }, [widgets]);

    async function onSubmit(data: FormValues) {
        const subWidgetsType = getSubWidgetsTypes(widgets);
        // Force validation to prevent some cases in which the form would submit with invalid inputs
        const isFormValid = await methods.trigger();
        if (!isFormValid) return false;
        const transformedData = widgetTypes
            ? handleDataTransformations(data, widgetTypes, subWidgetsType)
            : data;

        if (!iri) return transformedData as FormValues[];

        setIsSubmitting(true);
        const result = await postForm(
            iri,
            transformedData as WidgetObject,
            baseIri
        );

        window.scrollTo(0, 0);
        setIsSubmitting(false);

        return result;
    }

    return {
        methods,
        onSubmit,
        isSubmitting,
    };
}

export { useFormValidation };
