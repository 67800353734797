/**
 * Ces composants écrase ceux de Chakra (Alert, AlertIcon), pour ajouter des
 * icones custom, qui ne peuvent pas être écrasées via le thème.
 */
import { createContext } from '@chakra-ui/react-utils';
import {
    Alert as ChakraAlert,
    AlertTitle,
    AlertDescription,
    AlertDescriptionProps,
    AlertTitleProps,
    AlertProps,
    AlertIconProps,
    AlertStatus,
} from '@chakra-ui/alert';
import { Flex } from '@chakra-ui/react';
import {
    WarningIcon,
    CheckCircleIcon,
    AttentionIcon,
    InfoFlashIcon,
    InfoIcon,
} from 'design-system/icons';

const STATUS = {
    error: { icon: WarningIcon },
    warning: { icon: AttentionIcon },
    info: { icon: InfoIcon },
    infoFlash: { icon: InfoFlashIcon },
    success: { icon: CheckCircleIcon },
};

type CustomAlertContext = {
    status: AlertStatus;
};

const [CustomAlertProvider, useCustomAlertProvider] =
    createContext<CustomAlertContext>({
        name: 'CustomAlertContext',
        errorMessage:
            'useCustomAlertContext: `context` is undefined. Seems you forgot to wrap alert components in `<Alert />`',
    });

export function Alert(props: AlertProps) {
    return (
        <CustomAlertProvider value={{ status: props.status || 'warning' }}>
            <ChakraAlert {...props} />
        </CustomAlertProvider>
    );
}

interface AlertIconExtendedProps extends AlertIconProps {
    statusModified?: AlertStatus | 'infoFlash';
}

export function AlertIcon({
    statusModified,
    ...props
}: AlertIconExtendedProps) {
    const { status } = useCustomAlertProvider();
    const { icon: Icon } = STATUS[statusModified || status];

    return (
        <Flex as="span" mt="3px" alignSelf="flex-start" {...props}>
            <Icon w="6" h="6" />
        </Flex>
    );
}

export { AlertTitle, AlertDescription };
export type {
    AlertDescriptionProps,
    AlertTitleProps,
    AlertProps,
    AlertIconProps,
    AlertStatus,
};
