import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { SATISFACTION_SESSION_KEY } from 'lib/satisfaction';
import { useRedirect } from 'core';
import { TELECONSULTATION_DATA_STORAGE_KEY } from 'lib/teleconsultation/consts/teleconsultationFormConfig';
import { ONBOARDING_V2_SESSION_KEY } from 'components/onboarding/v2/consts';
import { useUserContext } from 'lib/user';

const { API_URL } = process.env;

function useLogout() {
    const { redirect } = useRedirect();
    const queryClient = useQueryClient();
    const { clearUserInfos } = useUserContext();

    const logout = async (
        microService: string = 'security',
        cookieName: string = 'auth-token'
    ) => {
        try {
            const res = await axios.post(`/api/logout`, {
                API_URL,
                MICRO_SERVICE: microService,
                COOKIE_NAME: cookieName,
            });

            if (res.status === 200) {
                queryClient.clear();
                window.sessionStorage.removeItem(
                    TELECONSULTATION_DATA_STORAGE_KEY
                );
                window.sessionStorage.removeItem(SATISFACTION_SESSION_KEY);
                window.sessionStorage.removeItem(ONBOARDING_V2_SESSION_KEY);
                await redirect('/login');
                clearUserInfos();
            }
        } catch (err) {
            throw new Error(err.message);
        }
    };

    return { logout };
}

export { useLogout };
