import { validationFormsMessages, FormatMessageFn } from 'core';

export function validateMaxSelectedOptions(
    maxSelectedOptions: number,
    formatMessage: FormatMessageFn
) {
    return (value: string) => {
        const convertedValue = Array.isArray(value) ? value : [value];
        return maxSelectedOptions && convertedValue.length > maxSelectedOptions
            ? formatMessage(validationFormsMessages.maxSelectedOptions, {
                  maxSelectedOptions,
              })
            : undefined;
    };
}
