import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import type { Contribution } from '../types';

export const path = '/api/proxy/selfcare/health/contributions';

async function fetchContributions() {
    try {
        const { data: response } = await axios.get(path);
        const { data }: Api<Contribution> = response;
        return data || null;
    } catch (error) {
        throw new Error(error);
    }
}

function useContributions() {
    return useQuery<Contribution | undefined, Error>({
        queryKey: [path],
        queryFn: main.fetchContributions,
    });
}

const main = {
    useContributions,
    fetchContributions,
};

export { path as contributionsPath, fetchContributions, useContributions };
export default main;
