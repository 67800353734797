import { Icon, IconProps } from '@chakra-ui/react';

export default function TeletransmissionIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M19.84 14.5c.054.33.094.663.12 1.001.044.55-.408.999-.96.999s-.995-.45-1.047-.999A10.503 10.503 0 0 0 8.5 6.047C7.949 5.995 7.5 5.552 7.5 5c0-.552.448-1.004.999-.96A12.51 12.51 0 0 1 19.841 14.5zM9.5 8.237a8.47 8.47 0 0 0-1.002-.18c-.549-.063-.998.39-.998.943 0 .552.45.992.996 1.076a6.505 6.505 0 0 1 5.428 5.428c.084.546.524.996 1.076.996s1.006-.45.942-.998A8.513 8.513 0 0 0 9.5 8.236zM11 16.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0zM7.5 18a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
