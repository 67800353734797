import { Icon, IconProps } from '@chakra-ui/react';

export default function BabysittingIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M9.304 8.5a3.5 3.5 0 1 0-3.542.038 2 2 0 0 0-1.603 2.11l.703 9.5A2 2 0 0 0 6.857 22h2.142a2 2 0 0 0 2-2v-2.99c-.001-.942.813-1.679 1.75-1.585l.215.021.045.004a1.4 1.4 0 0 0 .257.25l.934.7A2 2 0 0 1 15 18v2a2 2 0 0 0 2 2h.21a2 2 0 0 0 1.987-1.78l.554-5A2 2 0 0 0 17.763 13H17.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5h-2.335c-.11 0-.22.01-.328.027l-.959.16a1.438 1.438 0 0 0-.129.027l-2.175-3.723A2 2 0 0 0 9.848 8.5h-.543zM9 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-2.846 5h3.693l1.78 3.047a3.595 3.595 0 0 0-2.629 3.464L9 20H6.857l-.703-9.5zm9.492 4.5A4 4 0 0 1 17 18v2h.21l.553-5h-2.117zM18 10.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
