import { Icon, IconProps } from '@chakra-ui/icon';
import { useTheme } from '@chakra-ui/react';
import { ChallengeType } from 'core';

type Props = IconProps & {
    type: ChallengeType;
};

const VIEWBOX = '0 0 100 100';

const strategyIcons = {
    email: EmailIcon,
    paper: PaperIcon,
    sms: SmsIcon,
};

function EmailIcon(props: IconProps) {
    const { colors } = useTheme();
    return (
        <Icon
            {...props}
            viewBox={VIEWBOX}
            fill="none"
            sx={{
                '#envelope-side-left, #envelope-side-right': {
                    fill: 'primary.800',
                },
                '#envelope-interne, #message-box': {
                    fill: 'primary.main',
                },
            }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none">
                <g clipPath="url(#clip0_1329_26026)">
                    <path
                        d="M31.4399 56.96L96.2899 6L35.5699 77.06L31.4399 56.96Z"
                        fill={colors.primary[300]}
                    />
                    <path
                        id="envelope-interne"
                        d="M35.5699 77.06L41.4399 61.62L96.2899 6L35.5699 77.06Z"
                    />
                    <path
                        id="envelope-side-right"
                        d="M96.2899 6L41.4399 61.62L80.6399 90L96.2899 6Z"
                    />
                    <path
                        d="M7.87 73.98L20.35 60.84"
                        stroke={colors.primary[600]}
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M46.28 91.93L56.08 81.6"
                        stroke={colors.primary[600]}
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M58.98 89.96L62.35 86.42"
                        stroke={colors.primary[600]}
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M46.28 81.25L49.64 77.71"
                        stroke={colors.primary[600]}
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M22.52 68.08L25.89 64.54"
                        stroke={colors.primary[600]}
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M5.5 65.31L13.14 57.27"
                        stroke={colors.primary[600]}
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        id="envelope-side-left"
                        d="M7.29004 47L31.44 56.96L96.29 6L7.29004 47Z"
                    />
                    <path
                        id="message-box"
                        d="M52.29 43.29C50.97 43.29 49.72 42.77 48.79 41.84L46.01 39.06C45.87 38.92 45.68 38.84 45.48 38.84H25.49C22.03 38.84 19.21 36.02 19.21 32.56V19.84C19.21 16.38 22.03 13.56 25.49 13.56H79.08C82.54 13.56 85.36 16.38 85.36 19.84V32.56C85.36 36.02 82.54 38.84 79.08 38.84H59.1C58.9 38.84 58.71 38.92 58.57 39.06L55.79 41.84C54.86 42.78 53.61 43.29 52.29 43.29Z"
                    />
                    <path
                        d="M79.08 15.06C81.72 15.06 83.86 17.2 83.86 19.84V32.56C83.86 35.2 81.72 37.34 79.08 37.34H59.1C58.5 37.34 57.93 37.58 57.51 38L54.73 40.78C54.06 41.45 53.17 41.79 52.29 41.79C51.41 41.79 50.52 41.45 49.85 40.78L47.07 38C46.65 37.58 46.08 37.34 45.48 37.34H25.49C22.85 37.34 20.71 35.2 20.71 32.56V19.84C20.71 17.2 22.85 15.06 25.49 15.06H79.08ZM79.08 12.06H25.49C21.2 12.06 17.71 15.55 17.71 19.84V32.56C17.71 36.85 21.2 40.34 25.49 40.34H45.16L47.72 42.9C48.94 44.12 50.56 44.79 52.28 44.79C54 44.79 55.62 44.12 56.84 42.9L59.4 40.34H79.07C83.36 40.34 86.85 36.85 86.85 32.56V19.84C86.85 15.55 83.36 12.06 79.07 12.06H79.08Z"
                        fill="white"
                    />
                    <path
                        d="M75.7807 21.2728H28.7891C27.5634 21.2728 26.5698 22.2664 26.5698 23.492V28.8979C26.5698 30.1236 27.5634 31.1172 28.7891 31.1172H75.7807C77.0063 31.1172 77.9999 30.1236 77.9999 28.8979V23.492C77.9999 22.2664 77.0063 21.2728 75.7807 21.2728Z"
                        fill="white"
                    />
                    <path
                        d="M32.1123 28.0444C33.1305 28.0444 33.956 27.2189 33.956 26.2007C33.956 25.1824 33.1305 24.357 32.1123 24.357C31.094 24.357 30.2686 25.1824 30.2686 26.2007C30.2686 27.2189 31.094 28.0444 32.1123 28.0444Z"
                        fill={colors.secondary.main}
                    />
                    <path
                        d="M37.154 28.0444C38.1723 28.0444 38.9977 27.2189 38.9977 26.2007C38.9977 25.1824 38.1723 24.357 37.154 24.357C36.1358 24.357 35.3103 25.1824 35.3103 26.2007C35.3103 27.2189 36.1358 28.0444 37.154 28.0444Z"
                        fill={colors.secondary.main}
                    />
                    <path
                        d="M42.1956 28.0444C43.2139 28.0444 44.0393 27.2189 44.0393 26.2007C44.0393 25.1824 43.2139 24.357 42.1956 24.357C41.1774 24.357 40.3519 25.1824 40.3519 26.2007C40.3519 27.2189 41.1774 28.0444 42.1956 28.0444Z"
                        fill={colors.secondary.main}
                    />
                    <path
                        d="M47.2375 28.0444C48.2557 28.0444 49.0812 27.2189 49.0812 26.2007C49.0812 25.1824 48.2557 24.357 47.2375 24.357C46.2193 24.357 45.3938 25.1824 45.3938 26.2007C45.3938 27.2189 46.2193 28.0444 47.2375 28.0444Z"
                        fill={colors.secondary.main}
                    />
                    <path
                        d="M52.2905 28.0444C53.3087 28.0444 54.1342 27.2189 54.1342 26.2007C54.1342 25.1824 53.3087 24.357 52.2905 24.357C51.2722 24.357 50.4468 25.1824 50.4468 26.2007C50.4468 27.2189 51.2722 28.0444 52.2905 28.0444Z"
                        fill={colors.secondary.main}
                    />
                    <path
                        d="M57.3322 28.0444C58.3505 28.0444 59.1759 27.2189 59.1759 26.2007C59.1759 25.1824 58.3505 24.357 57.3322 24.357C56.314 24.357 55.4885 25.1824 55.4885 26.2007C55.4885 27.2189 56.314 28.0444 57.3322 28.0444Z"
                        fill={colors.secondary.main}
                    />
                    <path
                        d="M62.374 28.0444C63.3922 28.0444 64.2177 27.2189 64.2177 26.2007C64.2177 25.1824 63.3922 24.357 62.374 24.357C61.3557 24.357 60.5303 25.1824 60.5303 26.2007C60.5303 27.2189 61.3557 28.0444 62.374 28.0444Z"
                        fill={colors.secondary.main}
                    />
                    <path
                        d="M67.4157 28.0444C68.434 28.0444 69.2594 27.2189 69.2594 26.2007C69.2594 25.1824 68.434 24.357 67.4157 24.357C66.3975 24.357 65.572 25.1824 65.572 26.2007C65.572 27.2189 66.3975 28.0444 67.4157 28.0444Z"
                        fill={colors.secondary.main}
                    />
                    <path
                        d="M72.4574 28.0444C73.4756 28.0444 74.301 27.2189 74.301 26.2007C74.301 25.1824 73.4756 24.357 72.4574 24.357C71.4391 24.357 70.6136 25.1824 70.6136 26.2007C70.6136 27.2189 71.4391 28.0444 72.4574 28.0444Z"
                        fill={colors.secondary.main}
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1329_26026">
                        <rect
                            width="92.29"
                            height="87.43"
                            fill="white"
                            transform="translate(4 6)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </Icon>
    );
}

function SmsIcon(props: IconProps) {
    return (
        <Icon
            {...props}
            viewBox={VIEWBOX}
            fill="none"
            sx={{
                '> circle': {
                    fill: 'secondary.main',
                },
                '#phone-outline': {
                    fill: 'primary.800',
                },
                '#code-card': {
                    fill: 'primary.700',
                },
            }}>
            <path
                id="phone-outline"
                d="M84.537 50.737h.942v18.368h-.943V95.429H0V24.934A14.934 14.934 0 0114.934 10h54.669a14.934 14.934 0 0114.934 14.934v25.803z"
                fill="red"
            />
            <path
                d="M63.069 13.885h7.136-.002a11.153 11.153 0 0111.153 11.153V95.5H4.119V25.039a11.153 11.153 0 0111.153-11.153h6.666a5.298 5.298 0 004.906 7.3h31.319a5.299 5.299 0 004.906-7.3z"
                fill="white"
            />
            <path
                id="code-card"
                d="M96.512 69.047H14.917a3.436 3.436 0 01-2.466-1.056 3.67 3.67 0 01-1.022-2.547V38.365a3.669 3.669 0 011.022-2.547 3.436 3.436 0 012.466-1.056h81.595a3.436 3.436 0 012.465 1.056A3.668 3.668 0 01100 38.365v27.08c0 .955-.369 1.87-1.023 2.546a3.436 3.436 0 01-2.465 1.056z"
            />
            <rect
                x="23.81"
                y="44.286"
                width="64.4"
                height="15.4"
                rx="2"
                fill="white"
            />
            <circle cx="31.511" cy="51.986" r="2.1" />
            <circle cx="38.51" cy="51.986" r="2.1" />
            <circle cx="45.51" cy="51.986" r="2.1" />
            <circle cx="59.51" cy="51.986" r="2.1" />
            <circle cx="73.511" cy="51.986" r="2.1" />
            <circle cx="52.51" cy="51.986" r="2.1" />
            <circle cx="66.509" cy="51.986" r="2.1" />
            <circle cx="80.51" cy="51.986" r="2.1" />
        </Icon>
    );
}

function PaperIcon(props: IconProps) {
    return (
        <Icon
            {...props}
            viewBox={VIEWBOX}
            fill="none"
            sx={{
                '#envelope-back, #envelope-front': {
                    fill: 'primary.800',
                },
                '#card': {
                    fill: 'primary.700',
                },
                '> circle': {
                    fill: 'secondary.main',
                },
            }}>
            <path
                id="envelope-back"
                d="M88.85 95H10.667A9.666 9.666 0 011 85.331V49.505a3.831 3.831 0 011.195-2.78l41.43-39.28a8.916 8.916 0 0112.268 0l39.5 37.451a10.01 10.01 0 013.124 7.267V85.33A9.67 9.67 0 0188.85 95z"
            />
            <path
                opacity=".1"
                d="M1.194 49.897h96.704v39.06a5.837 5.837 0 01-5.837 5.838H7.03a5.837 5.837 0 01-5.837-5.837V49.897z"
                fill="black"
            />
            <path
                id="card"
                d="M80.4 19.221H19.117a5.319 5.319 0 00-5.319 5.32v61.28a5.319 5.319 0 005.32 5.319h61.28a5.319 5.319 0 005.319-5.319v-61.28a5.319 5.319 0 00-5.319-5.32z"
            />
            <rect
                x="26.395"
                y="31.817"
                width="46.727"
                height="11.174"
                rx="2"
                fill="white"
            />
            <circle cx="31.982" cy="37.404" r="1.524" />
            <circle cx="37.061" cy="37.404" r="1.524" />
            <circle cx="42.14" cy="37.404" r="1.524" />
            <circle cx="52.298" cy="37.404" r="1.524" />
            <circle cx="62.456" cy="37.404" r="1.524" />
            <circle cx="47.219" cy="37.404" r="1.524" />
            <circle cx="57.377" cy="37.404" r="1.524" />
            <circle cx="67.535" cy="37.404" r="1.524" />
            <path
                opacity=".1"
                d="M85.81 51.512v39.525H13.89V51.512l35.96 17.38 35.959-17.38z"
                fill="black"
            />
            <path
                id="envelope-front"
                d="M49.758 71.281L3.683 48.867a1.86 1.86 0 00-2.45.784 1.891 1.891 0 00-.233.91v38.564a5.908 5.908 0 001.71 4.154A5.835 5.835 0 006.837 95H92.68a5.804 5.804 0 004.127-1.72 5.878 5.878 0 001.71-4.155V51.222a2.307 2.307 0 00-1.069-1.945 2.27 2.27 0 00-2.205-.122L49.759 71.28z"
            />
        </Icon>
    );
}

export default function MFAValidationIcons({ type, ...iconProps }: Props) {
    const Icon = strategyIcons[type];
    return <Icon {...iconProps} />;
}
