import React from 'react';
import { Select } from '@chakra-ui/react';
import { useSafeIntl } from '../../../SafeIntl';
import { FormGroup } from '../../FormGroup';
import { SelectProps } from '.';
import { useInputValidation, formMessages } from '../../../..';

function NativeSelect(props: Omit<SelectProps, 'isSearchable'>) {
    const {
        id,
        options,
        placeholder,
        hideLabel,
        defaultValue,
        validations,
        links,
        validationOptions,
        title,
        name,
        readonly,
        help,
        description,
    } = props;
    const { safeFormatMessage } = useSafeIntl();
    const inputName = name ?? `${id}.value`;
    const { registerValues } = useInputValidation(
        validations,
        inputName,
        validationOptions
    );

    const _renderOptions = () => {
        return options.map((option) => (
            <option key={option.id} value={option.id}>
                {option.isTranslatable
                    ? safeFormatMessage(
                          formMessages[option.label],
                          null,
                          `<SelectInput /> ${option.label}`
                      )
                    : option.label}
            </option>
        ));
    };

    return (
        <FormGroup
            label={title}
            name={inputName}
            isRequired={validations.required}
            isReadOnly={readonly}
            {...{ id, description, hideLabel, help, links }}>
            <Select
                {...registerValues}
                height="44px"
                borderColor="strokes.medium"
                _focus={{ borderColor: 'primary.main' }}
                _hover={{ borderColor: 'strokes.dark' }}
                placeholder={
                    placeholder &&
                    safeFormatMessage(
                        formMessages[placeholder],
                        null,
                        placeholder
                    )
                }
                defaultValue={defaultValue}>
                {_renderOptions()}
            </Select>
        </FormGroup>
    );
}

export default NativeSelect;
