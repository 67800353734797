import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useDate, Date as ContextDate } from '../Date/context';
import {
    CalendarButton,
    CalendarLabels,
    CalendarRow,
    CalendarLabel,
} from '../Calendar';
import { MONTH_IDS } from '../consts';

const DAY_IDS = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
};

function computeDaysInMonth(year: number, month: number): ContextDate[] {
    const currentMonth = dayjs(new Date(year, month, 1));
    const getPreviousMonth = (day = 1): Dayjs =>
        dayjs(
            new Date(
                month === MONTH_IDS.JANUARY ? year - 1 : year,
                month === MONTH_IDS.JANUARY ? MONTH_IDS.DECEMBER : month - 1,
                day
            )
        );

    /**
     * Get the first day of the current month as an index.
     * 0 (Sunday) to 6 (Saturday)
     * @see https://day.js.org/docs/en/get-set/day
     */
    const currentMonthStartDay = currentMonth.startOf('month').day();

    /**
     * A number to know how many days to show before the beginning of the month
     */
    const daysInPreviousMonth =
        currentMonthStartDay === DAY_IDS.MONDAY
            ? DAY_IDS.SUNDAY
            : currentMonthStartDay === DAY_IDS.SUNDAY
            ? DAY_IDS.SATURDAY
            : currentMonthStartDay - 1;

    /**
     * Get the index of the last day of the previous month
     *
     * If the current month is 0 (january) then we remove a year, and set the
     * month to 11 (december)
     */
    const previousMonthEndDayDate = getPreviousMonth().endOf('month').date();
    const visibleDyasPreviousMonth = Array.from(Array(daysInPreviousMonth)).map(
        (_, idx) => ({
            year,
            month: month === MONTH_IDS.JANUARY ? MONTH_IDS.DECEMBER : month - 1,
            day: previousMonthEndDayDate - idx,
        })
    );
    const noOfDaysInCurrentMonth = currentMonth.daysInMonth();
    const currrentMonthDays = Array.from(Array(noOfDaysInCurrentMonth)).map(
        (_, idx) => ({
            year,
            month,
            day: idx + 1,
        })
    );

    /**
     * A number to know how many days of the next month to show
     */
    const daysInNextMonth = 7 - currentMonth.endOf('month').day();
    const nextMonthVisibleDays = daysInNextMonth === 7 ? 0 : daysInNextMonth;
    const nextMonthDays = Array.from(Array(nextMonthVisibleDays)).map(
        (_, idx) => ({
            // Increment 1 year if current month is december
            year: month === MONTH_IDS.DECEMBER ? year + 1 : year,
            month: month === MONTH_IDS.DECEMBER ? MONTH_IDS.JANUARY : month + 1,
            day: idx + 1,
        })
    );

    return [
        ...visibleDyasPreviousMonth.reverse(),
        ...currrentMonthDays,
        ...nextMonthDays,
    ];
}

export function PickerDay() {
    const {
        today,
        current,
        selected,
        setSelected,
        range,
        onClickDay,
        openDays,
    } = useDate();
    const { year, month } = current;
    const label = Array.from(Array(7)).map((_, idx) =>
        dayjs()
            .day(idx + 1)
            .format('dddd')
    );
    const weeks = React.useMemo(() => {
        const days = computeDaysInMonth(year, month);
        return Array.from(Array(days.length / 7)).map((_, idx) =>
            days.slice(idx * 7, (idx + 1) * 7)
        );
    }, [year, month]);

    return (
        <>
            <CalendarLabels>
                <>
                    {label.map((day) => (
                        <CalendarLabel key={day}>
                            {day[0].toUpperCase()}
                        </CalendarLabel>
                    ))}
                </>
            </CalendarLabels>

            {weeks.map((days, weekId) => (
                <CalendarRow col={7} key={weekId}>
                    {days.map((day) => {
                        const formattedDate = dayjs(
                            new Date(day.year, day.month, day.day)
                        );
                        const isDisabled =
                            month !== day.month ||
                            formattedDate.isBefore(range.min) ||
                            formattedDate.isAfter(range.max) ||
                            (openDays && [0, 6].includes(formattedDate.day()));

                        return (
                            <CalendarButton
                                key={formattedDate.format('YYYY-MM-DD')}
                                onClick={() => {
                                    setSelected({
                                        type: 'SET_DAY',
                                        value: {
                                            year: day.year,
                                            month: day.month,
                                            day: day.day,
                                        },
                                    });
                                    onClickDay?.();
                                }}
                                disabled={isDisabled}
                                current={
                                    today.year === year &&
                                    today.month === month &&
                                    today.day === day.day
                                }
                                selected={
                                    !isDisabled &&
                                    selected.day === day.day &&
                                    selected.month === day.month &&
                                    selected.year === day.year
                                }>
                                {day.day.toString()}
                            </CalendarButton>
                        );
                    })}
                </CalendarRow>
            ))}
        </>
    );
}
