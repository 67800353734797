import { BadgeProps, Box, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { Tag } from 'design-system/components';
import { uniqueId } from 'lodash';
import React from 'react';

type TileTagsProps = BadgeProps & {
    label: string;
    tagLabels: string[];
    preventCapitalize?: boolean;
};

export default function TileTags({
    label,
    tagLabels,
    preventCapitalize = true,
    ...props
}: TileTagsProps) {
    return (
        <Box width={'full'}>
            <Text fontWeight="bold" pb="3">
                {label}
            </Text>
            {!tagLabels?.filter((label) => !!label).length ? (
                <Text fontSize="md" fontWeight={400}>
                    -
                </Text>
            ) : (
                <Wrap spacingX={1} spacingY={2} pt={1}>
                    {tagLabels
                        ?.filter((label) => !!label)
                        ?.map((label) => (
                            <WrapItem key={uniqueId()}>
                                <Tag
                                    formattedText={label}
                                    fontWeight={700}
                                    preventCapitalize={preventCapitalize}
                                    {...props}
                                />
                            </WrapItem>
                        ))}
                </Wrap>
            )}
        </Box>
    );
}
