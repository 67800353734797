import { Icon, IconProps } from '@chakra-ui/react';

export default function TrophyIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M6.294 2A.294.294 0 0 0 6 2.294V3H3.137C2.51 3 2 3.51 2 4.137V7a5.002 5.002 0 0 0 4.07 4.914A6.005 6.005 0 0 0 11 16.916V20H8a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-3v-3.083a6.005 6.005 0 0 0 4.93-5.004A5.002 5.002 0 0 0 22 7V4.137C22 3.51 21.49 3 20.863 3H18v-.706A.294.294 0 0 0 17.706 2H6.294zM8 4v7a4 4 0 0 0 8 0V4H8zM6 9.83V5H4v2c0 1.306.835 2.417 2 2.83zM18 5v4.83A3.001 3.001 0 0 0 20 7V5h-2z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
