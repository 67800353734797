import { validationFormsMessages, FormatMessageFn } from 'core';
import { richTextareaRootElement as rootElement } from '../../../../components/forms/widgets/TextArea/RichTextarea';

export function validateRichTextareaRequired(formatMessage: FormatMessageFn) {
    return (value: string) => {
        return value === rootElement ||
            value ===
                rootElement
                    .replace(/ dir="ltr"/g, '')
                    .replace('</p>', '<br></p>')
            ? formatMessage(validationFormsMessages.required)
            : undefined;
    };
}
