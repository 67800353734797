import React from 'react';
import {
    Box,
    Heading,
    SimpleGrid,
    Stack,
    useDisclosure,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import {
    SignatureCapitalBeneficiary,
    SignatureErrorStep,
    signatureCapitalPath,
    useSignatureCapitalBeneficiaries,
    signatureSubtitles,
} from 'core/lib/signature';
import { FormattedMessage } from 'react-intl';
import SignatureSelectedBeneficiary from '../SignatureSelectedBeneficiary';
import SignatureAddBeneficiary from '../SignatureAddBeneficiary';
import { DividedCapitalAlert } from './DividedCapitalAlert';
import { useRouter } from 'next/router';
import SignatureModalBeneficiary from '../SignatureModalBeneficiary';
import {
    UtilsContext,
    FormErrors,
    ViolationMessage,
    WorkflowTypesEnum,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';

function getCumulatedCapitalPercentage(
    dividedCapitalBeneficiaries: SignatureCapitalBeneficiary[]
) {
    return dividedCapitalBeneficiaries
        ?.filter((beneficiary) => beneficiary?.capitalPercentage)
        ?.reduce(
            (acc, beneficiary) =>
                acc + Number.parseFloat(beneficiary.capitalPercentage),
            0
        );
}

type DividedCapitalProps = {
    service: WorkflowTypesEnum;
    onCumulatedCapitalChange: (cumulatedCapital: number) => void;
    onError: (error: number, step?: SignatureErrorStep) => void;
    onFormError: (
        error: number,
        step?: SignatureErrorStep,
        action?: 'add' | 'edit' | 'delete'
    ) => void;
    formErrors: ViolationMessage[];
};

export default function DividedCapital({
    service,
    onCumulatedCapitalChange,
    onError,
    onFormError,
    formErrors,
}: DividedCapitalProps) {
    const {
        layouts: { LayoutBoundary },
    } = React.useContext(UtilsContext);
    const router = useRouter();
    const procedureId = router.query?.procedureId as string;
    const type = router.query?.type as 'full-capital' | 'divided-capital';
    const { data, status, error } = useSignatureCapitalBeneficiaries(
        service,
        procedureId,
        type
    );
    const { beneficiaries, beneficiariesMaxNumber } = data || {};
    const [selectedBeneficiary, setSelectedBeneficiary] =
        React.useState<SignatureCapitalBeneficiary>();
    const capital = getCumulatedCapitalPercentage(beneficiaries);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const queryClient = useQueryClient();

    React.useEffect(() => {
        if (error) {
            onError(error?.code);
        }
    }, [error, onError]);

    React.useEffect(() => {
        onCumulatedCapitalChange(capital);
    }, [capital, onCumulatedCapitalChange]);

    const { isMobile } = useWindowBreakpoints();
    const isMaxCapital = capital >= 100;
    return (
        <>
            <LayoutBoundary status={status} p="0">
                <Stack spacing="4">
                    <Heading as="h3" fontSize="xl">
                        <FormattedMessage
                            {...signatureSubtitles['capital-repartition']}
                        />
                    </Heading>

                    {capital && <DividedCapitalAlert capital={capital} />}
                    <Box mb="-3">
                        {formErrors.length > 0 && (
                            <FormErrors errors={formErrors} />
                        )}
                    </Box>
                    <SimpleGrid
                        columns={isMobile ? 1 : 2}
                        spacing={isMobile ? '2' : '4'}>
                        {beneficiaries
                            ?.filter((beneficiary) => beneficiary)
                            ?.map((beneficiary, idx) => (
                                <SignatureSelectedBeneficiary
                                    service={service}
                                    key={beneficiary?.id}
                                    beneficiary={beneficiaries[idx]}
                                    id={1}
                                    onError={(errorCode: number) => {
                                        if (errorCode === 403)
                                            onFormError(
                                                errorCode,
                                                undefined,
                                                'delete'
                                            );
                                        else onError(errorCode);
                                    }}
                                    onEdit={async (beneficiary) => {
                                        setSelectedBeneficiary(beneficiary);
                                        onOpen();
                                        await queryClient.invalidateQueries({
                                            queryKey: [
                                                signatureCapitalPath,
                                                service,
                                                procedureId,
                                                type,
                                            ],

                                            exact: true,
                                        });
                                    }}
                                    type="divided-capital"
                                />
                            ))}
                        {beneficiaries?.length < beneficiariesMaxNumber && (
                            <SignatureAddBeneficiary
                                onClick={onOpen}
                                isEnabled={!isMaxCapital}
                            />
                        )}
                    </SimpleGrid>
                </Stack>
            </LayoutBoundary>
            <SignatureModalBeneficiary
                service={service}
                isOpen={isOpen}
                onClose={async () => {
                    onClose();
                    setSelectedBeneficiary(undefined);
                    await queryClient.invalidateQueries({
                        queryKey: [
                            signatureCapitalPath,
                            service,
                            procedureId,
                            type,
                        ],
                        exact: true,
                    });
                }}
                onFormError={(errorCode, step, action) => {
                    setSelectedBeneficiary(undefined);
                    onFormError(errorCode, step, action);
                    onClose();
                }}
                selectedBeneficiary={selectedBeneficiary}
                type={type}
                capital={capital}
            />
        </>
    );
}
