import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import { UrlObject } from 'url';
import { FormNextStep } from 'core';

export function handleNextStep(
    nextStep: FormNextStep,
    redirect: (path: string | UrlObject) => Promise<boolean>,
    additionalData?: any
) {
    if (!nextStep) return;

    if (additionalData?.registration_token) {
        axios.post('/api/set-cookie', {
            value: additionalData?.registration_token,
            name: 'register-token',
            maxAge: 86400, // 1 day
        });
    }

    try {
        switch (nextStep?.workflow) {
            case 'signature_provident_beneficiaries_designation':
            case 'signature_provident_standard_beneficiaries_designation':
            case 'signature_cotisation':
                redirect({
                    pathname: '/signature/[procedureId]/document-preview',
                    query: {
                        procedureId: nextStep?.procedureId,
                        workflow:
                            additionalData?.workflow || nextStep?.workflow,
                    },
                });
                break;
            case 'signature-health-subscription':
                redirect({
                    pathname: '/signature/summary',
                    query: {
                        procedureId: nextStep?.procedureId,
                        workflow: nextStep?.workflow,
                        levelId: additionalData?.levelId,
                    },
                });
                break;
            case 'user_registration_challenge':
                redirect({
                    pathname: '/register/challenge',
                    query: { iri: nextStep?.iri },
                });
                break;
            case 'user_registration_challenge_paper_code': {
                redirect({
                    pathname: '/register/challenge/[strategy]',
                    query: {
                        strategy: 'registration_strategy_paper',
                    },
                });
                break;
            }
            case 'signature_provident_full_capital_beneficiaries_designation':
            case 'signature_provident_divided_capital_beneficiaries_designation':
                redirect({
                    pathname: '/signature/[procedureId]/[type]/beneficiaries',
                    query: {
                        procedureId: nextStep?.procedureId,
                        type: additionalData.type,
                        workflow: additionalData.workflow,
                    },
                });
                break;
            default:
                throw new Error(
                    `Unknown nextStep workflow: ${nextStep?.workflow}`
                );
        }
    } catch (err) {
        Sentry.captureException(new Error(err));
        throw err;
    }
}
