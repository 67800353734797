import { useTheme } from '@chakra-ui/react';

function SatisfactionSadIcon() {
    const theme = useTheme();
    const colors = theme.colors;

    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
            <path
                d="M72.9058 60.7235C73.9584 69.2988 76.994 75.9825 79.686 75.6521C82.3781 75.3216 83.7071 68.1021 82.6545 59.5269C81.6018 50.9516 78.5662 44.2679 75.8742 44.5983C73.1822 44.9288 71.8532 52.1483 72.9058 60.7235Z"
                fill={colors.red[700]}
            />
            <path
                d="M49.4777 86.5354C66.7569 86.5354 80.7645 72.5278 80.7645 55.2486C80.7645 37.9694 66.7569 23.9619 49.4777 23.9619C32.1985 23.9619 18.1909 37.9694 18.1909 55.2486C18.1909 72.5278 32.1985 86.5354 49.4777 86.5354Z"
                fill={colors.red[700]}
            />
            <path
                d="M44.7255 79.8027H35.2207V96.8322H44.7255V79.8027Z"
                fill={colors.red[700]}
            />
            <path
                d="M63.7348 79.8027H54.23V96.8322H63.7348V79.8027Z"
                fill={colors.red[700]}
            />
            <path
                d="M43.1414 100C47.5159 100 51.0621 98.6703 51.0621 97.0298C51.0621 95.3894 47.5159 94.0596 43.1414 94.0596C38.7669 94.0596 35.2207 95.3894 35.2207 97.0298C35.2207 98.6703 38.7669 100 43.1414 100Z"
                fill={colors.red[700]}
            />
            <path
                d="M62.1507 99.6046C66.5252 99.6046 70.0714 98.2748 70.0714 96.6343C70.0714 94.9939 66.5252 93.6641 62.1507 93.6641C57.7762 93.6641 54.23 94.9939 54.23 96.6343C54.23 98.2748 57.7762 99.6046 62.1507 99.6046Z"
                fill={colors.red[700]}
            />
            <path
                d="M50.2696 58.0206C56.1751 58.0206 60.9625 53.2332 60.9625 47.3277C60.9625 41.4221 56.1751 36.6348 50.2696 36.6348C44.364 36.6348 39.5767 41.4221 39.5767 47.3277C39.5767 53.2332 44.364 58.0206 50.2696 58.0206Z"
                fill="white"
            />
            <path
                d="M50.2699 50.8914C52.2384 50.8914 53.8342 49.2956 53.8342 47.3271C53.8342 45.3585 52.2384 43.7627 50.2699 43.7627C48.3014 43.7627 46.7056 45.3585 46.7056 47.3271C46.7056 49.2956 48.3014 50.8914 50.2699 50.8914Z"
                fill={colors.red[800]}
            />
            <path
                d="M19.129 26.1521C16.6028 14.8384 24.6782 3.40636 37.1661 0.617945C49.6539 -2.17047 61.8252 4.74067 64.3515 16.0543C66.8777 27.368 58.6016 31.5407 46.1138 34.3291C33.6259 37.1176 21.6553 37.4659 19.129 26.1521Z"
                fill={colors.red[800]}
            />
            <path
                d="M21.4613 45.2439C18.7525 45.109 16.2081 51.9947 15.7782 60.6236C15.3484 69.2525 17.1959 76.357 19.9047 76.492C22.6136 76.6269 25.158 69.7412 25.5879 61.1123C26.0177 52.4834 24.1702 45.3789 21.4613 45.2439Z"
                fill={colors.red[700]}
            />
            <path
                d="M67 73.0002C67 70.1089 62 61.0001 50.9521 61.0001C41.0001 61.0001 36.0001 69.9381 36.0001 73.0002C36.0001 76.0623 41.548 68.3913 52 69C62.5 69 67 76.225 67 73.0002Z"
                fill="white"
            />
        </svg>
    );
}

export default SatisfactionSadIcon;
