import React from 'react';
import { useRouter } from 'next/router';
import { IntlProvider } from 'react-intl';
import { UtilsContext, richTextElements } from 'core';
import { useQueryClient } from '@tanstack/react-query';

import 'dayjs/locale/fr';
import dayjs from 'dayjs';

type LanguageContextType = {
    locale: string;
    setLocale: React.Dispatch<React.SetStateAction<string>> | null;
    showSwitch: boolean;
};

const LanguageContext = React.createContext<LanguageContextType>({
    locale: 'fr_FR',
    setLocale: null,
    showSwitch: false,
});

type LanguageProviderType = {
    locales: { fr_FR: any; en_GB: any };
    notAuthRoutes: string[];
    children: JSX.Element;
};

const LanguageProvider = ({
    children,
    locales,
    notAuthRoutes,
}: LanguageProviderType) => {
    const router = useRouter();
    const [locale, setLocale] = React.useState<string>('fr-FR');
    const [enabled, setEnabled] = React.useState(false);
    const [messages, setMessages] = React.useState(
        locales[locale || router.locale]
    );
    const queryClient = useQueryClient();
    const { siteConfig } = React.useContext(UtilsContext);

    React.useEffect(() => {
        const storedLocale = window.localStorage.getItem('locale') as
            | 'fr-FR'
            | 'en-GB';
        if (storedLocale) setLocale(storedLocale);
    }, []);

    React.useEffect(() => {
        if (siteConfig.locales.length < 2) return setEnabled(false);
        setEnabled(notAuthRoutes?.includes(router?.pathname));
    }, [router?.pathname, notAuthRoutes]);

    React.useEffect(() => {
        async function clearCache() {
            await queryClient.cancelQueries();
            await queryClient.removeQueries({ type: 'inactive' });
            await queryClient.refetchQueries({ type: 'active' });
        }

        setMessages(locales[locale]);
        window.localStorage.setItem('locale', locale);
        dayjs.locale(locale.split('-')[0]);
        clearCache();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    return (
        <LanguageContext.Provider
            value={{ locale, setLocale, showSwitch: enabled }}>
            <IntlProvider
                locale={locale}
                onError={() => null}
                messages={messages}
                defaultRichTextElements={richTextElements}>
                {children}
            </IntlProvider>
        </LanguageContext.Provider>
    );
};

export { LanguageContext, LanguageProvider };
