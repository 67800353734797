import { FormValues } from 'core';
import { WizardFormContainerAdditionalData } from 'lib/form-wizard/types';

export const CommunicationTypes = {
    '1': 'Phone',
    '2': 'Video',
};

export function formatPreventionData(
    data: FormValues,
    additionalData?: WizardFormContainerAdditionalData
) {
    const activeBeneficiary = additionalData?.beneficiaries?.find(
        (beneficiary) =>
            beneficiary.identifier === data.beneficiaryIdentifier?.value
    );

    return Object.entries({
        beneficiaryIdentifier: activeBeneficiary?.identifier,
        email: data?.email?.value,
        ...(!!data?.phone?.value && {
            phone: JSON.parse(data?.phone?.value),
        }),
        ...((!!data?.preferredCanal?.phoneNumber?.value ||
            data?.phone?.value) && {
            recallPhoneNumber: JSON.parse(
                data?.preferredCanal?.phoneNumber?.value || data?.phone?.value
            ),
        }),
        preferredCanal: CommunicationTypes[data?.preferredCanal?.value],
        freeTimeSlotId: data?.id,
    })
        .filter(([_, value]) => value !== undefined)
        ?.reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {});
}
