import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';

export const healthCareAppointmentsNotificationsPath = `/api/proxy/healthcare-access/appointments/notifications-count`;

async function fetchAppointmentsNotifications({
    queryKey: [urn],
}: FetchQueryParams) {
    try {
        const response = await axios.get(urn);
        return response?.data?.data?.number;
    } catch (error) {
        throw { code: error?.response?.status };
    }
}

export function useHealthCareAppointmentsNotifications(
    enabled: boolean = true
) {
    return useQuery<number, APIError>({
        queryKey: [healthCareAppointmentsNotificationsPath],
        queryFn: fetchAppointmentsNotifications,
        enabled,
    });
}
