import { Text, Stack, Img } from '@chakra-ui/react';
import { defineMessage, useIntl } from 'react-intl';
import { getBase64MimeType } from 'core';

type TileImageProps = {
    image: string | null;
    label: string;
};

const intlMessage = defineMessage({
    id: 'components.theme.logo',
    defaultMessage: 'Logo',
});

export default function ImageTile({ image, label }: TileImageProps) {
    const intl = useIntl();
    const src = `data:${getBase64MimeType(image || '')};base64, ${image}`;

    return (
        <Stack>
            <Text as="b" fontSize="md">
                {label}
            </Text>
            {image ? (
                <Img
                    alt={intl.formatMessage(intlMessage)}
                    border="1px solid"
                    borderColor="strokes.medium"
                    borderRadius="md"
                    src={src}
                    p="2"
                    width={180}
                    height={100}
                    objectFit="contain"
                />
            ) : (
                <Text>-</Text>
            )}
            ;
        </Stack>
    );
}
