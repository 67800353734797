const { shouldFilterAPIError } = require('./shouldFilterAPIError');
const { filteredErrors } = require('./filteredErrors');
const { filterSensitiveData } = require('./filterSensitiveData');

const beforeSend = (event, hint) => {
    const errorMessage = hint?.originalException;

    if (event) {
        event = filterSensitiveData(event);
    }

    if (event?.transaction === 'POST /api/csp-report') {
        // Send 1% of CSP errors
        return Math.floor(Math.random() * 100) <= 1 ? event : null;
    } else if (typeof errorMessage === 'object') {
        const shouldFilter = filteredErrors.some((params) => {
            return shouldFilterAPIError(params, errorMessage);
        });

        return shouldFilter ? null : event;
    }

    return event;
};
// Doc for options: https://github.com/getsentry/sentry-webpack-plugin/blob/master/README.md
const SentryConfig = {
    url: 'https://sentry.io', // to match with the encoded one in the authToken
    org: process.env.SENTRY_ORG,
    enabled: process.env.NEXT_PUBLIC_SENTRY_ENV !== 'development',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,
    autoSessionTracking: false,
    sendClientReports: false, // https://github.com/getsentry/sentry-javascript/issues/4404#issuecomment-1013146388
    sampleRate: 1,
    tracesSampleRate: 0.001,
    beforeSend,
    authToken: process.env.SENTRY_AUTH_TOKEN,
    // Upload a larger set of source maps for prettier stack traces (increases build time)
    widenClientFileUpload: true,
    // Hides source maps from generated client bundles
    hideSourceMaps: true,
    // Automatically tree-shake Sentry logger statements to reduce bundle size
    disableLogger: true,
    // Remove all the artifacts in the release before the upload. Defaults to false.
    cleanArtifacts: false, // Apparently it's been deprecated - https://github.com/getsentry/sentry-javascript-bundler-plugins/issues/481
};

module.exports = { SentryConfig, beforeSend };
