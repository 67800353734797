import type { QueryStatus } from '@tanstack/react-query';

type EnhancedQueryStatus = QueryStatus | 'empty' | 'maintenance';

function getQueryStatus(data: any, status: QueryStatus, errorCode?: number) {
    const isEmpty = status === 'success' && (!data || data?.length === 0);
    const enhancedStatus: EnhancedQueryStatus =
        errorCode === 503 ? 'maintenance' : isEmpty ? 'empty' : status;

    return enhancedStatus;
}

export type { EnhancedQueryStatus };
export { getQueryStatus };
