import { Icon, IconProps } from '@chakra-ui/react';

export default function CrossCircleExtendedIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 27 22.5" {...props}>
            <circle
                cx="10.5"
                cy="12.5"
                r="9"
                fill="white"
                stroke="#D41112"
                strokeWidth="3"
            />
            <path
                d="M3.53361 5.50055L18.0003 19.4995"
                stroke="#D41112"
                strokeWidth="3"
            />
            <path
                d="M3 19.3743L17.3365 5.49995"
                stroke="#D41112"
                strokeWidth="3"
            />
        </Icon>
    );
}
