import { Icon, IconProps } from '@chakra-ui/react';

export default function WorldIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm6.905-5.957A7.963 7.963 0 0 0 20 12a7.99 7.99 0 0 0-3.423-6.562l-.233 1.164a1.785 1.785 0 0 1-1.074 1.294c-.649.268-1.298.606-1.714 1.029-.2.203-.325.404-.383.604-.055.191-.064.427.038.734.257.77.57 1.165.856 1.391.29.23.62.34 1.024.424.136.029.23.046.33.063.089.016.183.033.317.06.225.045.479.105.726.207.546.223 1.006.63 1.248 1.355.228.685.73 1.562 1.193 2.28zm-.943 1.292a8.003 8.003 0 0 1-13.618-3.006l2.448-2.832c1.162-1.281 1.862-2.429 1.983-3.608.103-1.01-.228-1.916-.8-2.804A7.963 7.963 0 0 1 12 4c1.139 0 2.221.238 3.202.666l-.328 1.642a.286.286 0 0 1-.178.202c-.685.283-1.57.714-2.209 1.362-.327.332-.61.742-.754 1.24-.147.505-.136 1.052.056 1.625.326.98.783 1.647 1.347 2.093.559.443 1.156.614 1.648.717.114.023.28.054.422.08l.237.045c.195.039.338.077.452.123.178.074.302.167.393.442.34 1.018 1.108 2.269 1.674 3.098zM4 12a7.981 7.981 0 0 1 2.754-6.04c.432.689.584 1.24.529 1.776-.072.694-.504 1.544-1.608 2.76l-.006.007L4.01 12.42C4.004 12.28 4 12.14 4 12z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
