import { ReactNode } from 'react';
import { Flex, VStack, Text } from '@chakra-ui/react';
import { PlusIcon } from 'design-system/icons';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Card } from 'core';

type AddInputProps = {
    onClick: () => void;
    isEnabled: boolean;
    title: ReactNode;
};

export const AddInput = ({ onClick, isEnabled, title }: AddInputProps) => {
    return (
        <VStack
            {...(isEnabled && {
                as: Card,
                onClick,
                cursor: 'pointer',
            })}
            border="2px"
            borderStyle="dashed"
            borderColor="grey.300"
            py="4"
            borderRadius="md"
            boxShadow="none"
            data-testid="add-input">
            <Flex
                bg={isEnabled ? 'primary.main' : 'grey.500'}
                rounded="full"
                color="white"
                p="1.5">
                <PlusIcon w={6} h={6} />
            </Flex>

            <Text
                fontFamily={'heading'}
                fontSize="sm"
                fontWeight="semibold"
                color={isEnabled ? 'inherit' : 'texts.medium'}>
                {title}
            </Text>
        </VStack>
    );
};
