import { Icon, IconProps } from '@chakra-ui/react';

export default function ChevronTopIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="m12 9.519 5.589 6.387a1 1 0 1 0 1.505-1.317L12.753 7.34a1 1 0 0 0-1.506 0L4.906 14.59a1 1 0 0 0 1.505 1.317L12 9.519z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
