import React, { forwardRef } from 'react';
import {
    Modal,
    ModalProps,
    ModalContent,
    ModalContentProps,
    HStack,
    Flex,
    FlexProps,
    ModalBody,
    ModalBodyProps,
    ModalFooter,
    ModalFooterProps,
    ModalCloseButton,
    ModalOverlay,
    Heading,
} from '@chakra-ui/react';

import { useWindowBreakpoints } from 'design-system/hooks';
import { getMagicScrollbarStyle } from 'design-system/lib/shared/helpers';

type CoreModalProps = ModalProps;
type CoreModalContentProps = ModalContentProps;
type CoreModalHeaderProps = FlexProps & {
    withCloseButton?: boolean;
    colorCloseButton?: string;
};
type CoreModalBodyProps = ModalBodyProps;
type CoreModalFooterProps = ModalFooterProps;

export function CoreModal({
    children,
    isAlwaysOpen = false,
    ...props
}: CoreModalProps & { isAlwaysOpen?: boolean }) {
    const { isMobile } = useWindowBreakpoints();
    return (
        <Modal motionPreset="slideInBottom" isCentered={!isMobile} {...props}>
            {isAlwaysOpen !== true && <ModalOverlay />}
            {children}
        </Modal>
    );
}

export function CoreModalContent({
    children,
    ...props
}: CoreModalContentProps) {
    const { isMobile } = useWindowBreakpoints();
    return (
        <ModalContent
            data-testid="modal-content"
            p="0"
            m={isMobile && '0'}
            pos={isMobile ? 'fixed' : 'relative'}
            bottom={isMobile ? '0' : 'unset'}
            alignSelf={isMobile ? 'flex-end' : 'center'}
            minW={!isMobile ? 'lg' : 'full'}
            minH={props?.minH || 'auto'}
            maxH={isMobile ? '80%' : '90%'}
            borderRadius={isMobile ? 'lg' : 'xl'}
            borderBottomRightRadius={isMobile ? 'none' : 'xl'}
            borderBottomLeftRadius={isMobile ? 'none' : 'xl'}
            {...props}>
            {children}
        </ModalContent>
    );
}

export function CoreModalHeader({
    children,
    withCloseButton = true,
    colorCloseButton = 'grey.700',
    ...props
}: CoreModalHeaderProps) {
    const { isMobile } = useWindowBreakpoints();
    return (
        <Flex
            data-testid="modal-header"
            position="relative"
            bg="grey.50"
            borderBottom="1px solid"
            borderColor="strokes.medium"
            fontSize={isMobile ? 'xl' : '2xl'}
            borderTopRadius={isMobile ? 'lg' : 'xl'}
            p="6"
            {...props}>
            <Heading
                as="h1"
                fontSize="24px"
                fontWeight="semibold"
                lineHeight={1.5}
                w="100%"
                mr="2">
                {children}
            </Heading>
            {withCloseButton && (
                <ModalCloseButton
                    data-testid="close-icon"
                    position="relative"
                    fontSize="sm"
                    fontWeight="light"
                    color={colorCloseButton}
                    top="0"
                    left="0"
                />
            )}
        </Flex>
    );
}

const CoreModalBody = forwardRef<HTMLDivElement, CoreModalBodyProps>(
    ({ children, ...props }, ref) => {
        return (
            <ModalBody
                ref={ref}
                p="6"
                {...props}
                overflowY={'auto'}
                sx={getMagicScrollbarStyle('5', 'primary.main')}>
                {children}
            </ModalBody>
        );
    }
);

CoreModalBody.displayName = 'CoreModalBody';

export { CoreModalBody };

export function CoreModalFooter({ children, ...props }: CoreModalFooterProps) {
    return (
        <ModalFooter {...props}>
            <HStack w="full">{children}</HStack>
        </ModalFooter>
    );
}
