import React from 'react';
import { defineMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useToast } from '../Toast';

type ComponentProps = {
    message?: MessageDescriptor;
    title?: MessageDescriptor;
    id?: string;
};

const defaultMessage = defineMessage({
    defaultMessage: 'Une erreur est survenue.',
    id: 'components.toast-error.default-message',
});

export function ToastError({
    message = defaultMessage,
    title,
    errorValues,
    id,
}: ComponentProps & {
    errorValues?: Record<string, string | number> | undefined;
}) {
    const intl = useIntl();
    const toast = useToast({
        ...(title && { title: intl.formatMessage(title) }),
        description: intl.formatMessage(message, errorValues),
        status: 'error',
        id,
    });

    React.useEffect(() => {
        if (!id || (id && !toast.isActive(id))) toast();
        return () => {
            toast.closeAll({ positions: ['bottom'] });
        };
        // `useToast` recreate a new function at every render even if the props doesn't change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(toast), id]);

    return null;
}
