import { Box, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { defineMessages, useIntl } from 'react-intl';
import { FileFileIcon } from 'design-system/icons';
import { useWindowBreakpoints } from 'design-system/hooks';
import { WorkflowTypesEnum } from '../../lib';
import BeneficiaryDocument from './BeneficiaryDocument';

const intlMessages = {
    common: defineMessages({
        specificGuided: {
            id: 'provident.beneficiaries-designation.specific-guided',
            defaultMessage: 'Saisie guidée',
        },
        revertToStandard: {
            id: 'provident.beneficiaries-designation.revert-to-standard',
            defaultMessage:
                'Revenir à la clause standard du contrat citée ci-dessus',
        },
    }),
    standard: defineMessages({
        title: {
            id: 'provident.beneficiaries-designation.choose-specific-block.title',
            defaultMessage:
                'La clause standard du contrat ne vous convient pas ?',
        },
        description: {
            id: 'provident.beneficiaries-designation.choose-specific-block.description',
            defaultMessage:
                "Si la clause standard n'est pas adaptée à votre situation, ou <b>si vous souhaitez modifier votre précédente désignation de bénéficiaires</b>, vous pouvez à tout moment désigner le ou les bénéficiaires de votre choix.",
        },
    }),
    specific: defineMessages({
        title: {
            id: 'provident.beneficiaries-designation.change-specific-block.title',
            defaultMessage: 'Modifier ma désignation de bénéficiaires',
        },
        description: {
            id: 'provident.beneficiaries-designation.change-specific-block.description',
            defaultMessage:
                'Si votre situation a changé, vous pouvez à tout moment modifier les bénéficiaires que vous avez précédemment désignés, ou revenir à une clause de désignation standard.',
        },
    }),
    [WorkflowTypesEnum.PE]: defineMessages({
        revertToStandardTitle: {
            id: 'workflow-signature.workflow-beneficiaries-designation.change-specific-block.title',
            defaultMessage:
                'Vous souhaitez revenir à la clause standard du contrat ?',
        },
        revertToStandardTitleDesc: {
            id: 'workflow-signature.workflow-beneficiaries-designation.change-specific-block.description',
            defaultMessage:
                'Vous avez <b>déjà</b> réalisé une désignation de bénéficiaires au titre de ce contrat mais vous souhaitez revenir à la clause standard du contrat.',
        },
    }),
};

type PickBeneficiaryProps = {
    workflow: WorkflowTypesEnum;
    type?: 'standard' | 'specific' | null;
};

export default function PickBeneficiary({
    workflow,
    type,
}: PickBeneficiaryProps) {
    const { formatMessage } = useIntl();
    const { isMobile } = useWindowBreakpoints();

    const _renderClause = (
        title: string,
        description: string,
        document: string,
        bgColor: string,
        type: 'standard' | 'specific-guided'
    ) => {
        return (
            <>
                <Stack>
                    <Heading as="h2" fontSize="xl" fontWeight="semibold">
                        {title}
                    </Heading>
                    <Text>{description}</Text>
                </Stack>
                <BeneficiaryDocument
                    workflow={workflow}
                    text={document}
                    icon={<FileFileIcon />}
                    bgColor={bgColor}
                    type={type}
                />
            </>
        );
    };

    return (
        <Box
            bg={workflow === WorkflowTypesEnum.PA ? 'primary.50' : 'grey.200'}
            w="full"
            px={isMobile ? '4' : '6'}
            py="10"
            borderRadius={!isMobile && '2xl'}>
            <SimpleGrid
                columns={isMobile ? 1 : 2}
                gap={isMobile ? '5' : '14'}
                alignItems="center">
                {workflow === WorkflowTypesEnum.PA ? (
                    _renderClause(
                        formatMessage(intlMessages?.[type]?.title),
                        formatMessage(intlMessages?.[type]?.description),
                        formatMessage(intlMessages.common.specificGuided),
                        'tertiary.main',
                        'specific-guided'
                    )
                ) : (
                    <>
                        {_renderClause(
                            formatMessage(intlMessages.standard.title),
                            formatMessage(intlMessages.standard.description),
                            formatMessage(intlMessages.common.specificGuided),
                            'primary.main',
                            'specific-guided'
                        )}
                        {_renderClause(
                            formatMessage(
                                intlMessages[workflow].revertToStandardTitle
                            ),
                            formatMessage(
                                intlMessages[workflow].revertToStandardTitleDesc
                            ),
                            formatMessage(intlMessages.common.revertToStandard),
                            'tertiary.main',
                            'standard'
                        )}
                    </>
                )}
            </SimpleGrid>
        </Box>
    );
}
