export function buildUrl({
    urn,
    isLoggedIn,
    client_id,
    microService,
    endpoint,
}: {
    urn: string;
    isLoggedIn: boolean;
    client_id: string;
    microService?: string;
    endpoint: string;
}) {
    if (microService === 'primo') {
        return `/api/proxy/primo/public/privacy-files/${endpoint}`;
    }
    if (microService) {
        return urn.replace('[microService]', microService);
    }
    if (isLoggedIn) {
        return urn.replace('[microService]', 'selfcare');
    }
    return `${urn.replace('[microService]', 'security/public')}/${client_id}/${
        client_id.split('_')[0]
    }`;
}
