import { MutableRefObject, Ref } from 'react';

/**
 * Set a ref if the component is mounted
 * @param ref The ref to be set
 * @param value The value which will update `ref.current`
 */
function setRef<T>(ref: Ref<T>, value: T) {
    if (typeof ref === 'function') {
        ref(value);
    } else if (ref) {
        // work around the immutability of the React.Ref type
        (ref as unknown as MutableRefObject<T>).current = value;
    }
}

export { setRef };
