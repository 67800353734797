import { Icon, IconProps } from '@chakra-ui/react';

export default function VideoIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 18 18" {...props}>
            <path
                d="M16.15 5.77692C16.0426 5.70726 15.9195 5.66551 15.7919 5.65543C15.6643 5.64536 15.5362 5.66729 15.4192 5.71923L13.0385 6.77692V6.42308C13.0385 5.91305 12.8359 5.4239 12.4752 5.06326C12.1146 4.70261 11.6254 4.5 11.1154 4.5H3.42308C2.91305 4.5 2.4239 4.70261 2.06326 5.06326C1.70261 5.4239 1.5 5.91305 1.5 6.42308V11.8077C1.5 12.3177 1.70261 12.8069 2.06326 13.1675C2.4239 13.5282 2.91305 13.7308 3.42308 13.7308H11.1154C11.6254 13.7308 12.1146 13.5282 12.4752 13.1675C12.8359 12.8069 13.0385 12.3177 13.0385 11.8077V11.4538L15.4192 12.5115C15.5176 12.5542 15.6236 12.5764 15.7308 12.5769C15.9348 12.5769 16.1304 12.4959 16.2747 12.3516C16.419 12.2074 16.5 12.0117 16.5 11.8077V6.42308C16.5002 6.29472 16.4683 6.16836 16.4071 6.0555C16.346 5.94264 16.2576 5.84688 16.15 5.77692ZM11.5 11.8077C11.5 11.9097 11.4595 12.0075 11.3873 12.0797C11.3152 12.1518 11.2174 12.1923 11.1154 12.1923H3.42308C3.32107 12.1923 3.22324 12.1518 3.15111 12.0797C3.07898 12.0075 3.03846 11.9097 3.03846 11.8077V6.42308C3.03846 6.32107 3.07898 6.22324 3.15111 6.15111C3.22324 6.07898 3.32107 6.03846 3.42308 6.03846H11.1154C11.2174 6.03846 11.3152 6.07898 11.3873 6.15111C11.4595 6.22324 11.5 6.32107 11.5 6.42308V11.8077ZM14.9615 10.6231L13.0385 9.76923V8.46154L14.9615 7.60769V10.6231ZM7.65385 7.57692C7.65385 7.78094 7.5728 7.97659 7.42854 8.12085C7.28429 8.26511 7.08863 8.34615 6.88462 8.34615H4.96154C4.75753 8.34615 4.56187 8.26511 4.41761 8.12085C4.27335 7.97659 4.19231 7.78094 4.19231 7.57692C4.19231 7.37291 4.27335 7.17725 4.41761 7.033C4.56187 6.88874 4.75753 6.80769 4.96154 6.80769H6.88462C7.08863 6.80769 7.28429 6.88874 7.42854 7.033C7.5728 7.17725 7.65385 7.37291 7.65385 7.57692Z"
                fill="currentColor"
            />
        </Icon>
    );
}
