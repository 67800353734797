import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { GuaranteesFamiliesType } from '../types';
import { APIError } from 'core';

const path = `/api/proxy/guarantees/families`;

async function fetchGuaranteesFamilies() {
    try {
        const res =
            await axios.get<Api<{ families: GuaranteesFamiliesType[] }>>(path);

        return res.data.data;
    } catch (error) {
        throw new Error(error);
    }
}

function useGuaranteesFamilies() {
    return useQuery<any, APIError>({
        queryKey: [path],
        queryFn: main.fetchGuaranteesFamilies,
    });
}

const main = {
    useGuaranteesFamilies,
    fetchGuaranteesFamilies,
};

export { fetchGuaranteesFamilies, useGuaranteesFamilies };
