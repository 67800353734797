import { Icon, IconProps, useTheme, VisuallyHidden } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export default function SuccessBalloonIcon(props: IconProps) {
    const { colors } = useTheme();

    return (
        <figure>
            <Icon
                {...props}
                viewBox="0 0 145 130"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#validated_register)">
                    <path
                        d="M35.752 32.977c-2.48-7.417-10.143-11.54-15.184-9.853-5.04 1.686-8.68 9.589-6.199 17.006 2.297 6.865 9.256 10.828 14.044 10.121a.541.541 0 00-.067.195l-.147 1.22a.55.55 0 00.732.584l2.466-.884a.55.55 0 00.195-.916l-.89-.85a.543.543 0 00-.104-.074c4.27-2.301 7.456-9.67 5.154-16.55z"
                        fill={colors.grey[100]}
                    />
                    <path
                        d="M26.77 27.52a9.048 9.048 0 014.094 11.77c-.213.485.572.74.784.258a9.894 9.894 0 00-4.42-12.715c-.466-.246-.927.439-.458.687z"
                        fill={colors.white}
                    />
                    <path
                        d="M136.205 22.166c-2.481-7.417-10.143-11.54-15.184-9.853-5.04 1.686-8.68 9.589-6.198 17.006 2.296 6.865 9.255 10.828 14.043 10.121a.54.54 0 00-.067.195l-.147 1.22a.547.547 0 00.204.496.548.548 0 00.528.088l2.466-.884a.556.556 0 00.352-.404.54.54 0 00-.157-.512l-.889-.85a.572.572 0 00-.104-.074c4.27-2.301 7.455-9.67 5.153-16.55z"
                        fill={colors.grey[100]}
                    />
                    <path
                        d="M127.223 16.709a9.047 9.047 0 014.094 11.768c-.213.486.572.742.784.26a9.894 9.894 0 00-4.42-12.715c-.466-.247-.927.438-.458.687z"
                        fill={colors.white}
                    />
                    <path
                        d="M77.87 10.228C75.39 2.811 67.727-1.311 62.686.375c-5.04 1.686-8.68 9.59-6.198 17.006 2.296 6.866 9.255 10.829 14.043 10.122a.543.543 0 00-.067.194l-.147 1.221a.55.55 0 00.732.583l2.466-.884a.55.55 0 00.195-.915l-.89-.85a.543.543 0 00-.103-.075c4.27-2.3 7.455-9.669 5.153-16.549z"
                        fill={colors.grey[100]}
                    />
                    <path
                        d="M68.888 4.772a9.048 9.048 0 014.094 11.769c-.213.485.572.74.784.258a9.893 9.893 0 00-4.42-12.714c-.466-.247-.927.438-.458.687z"
                        fill={colors.white}
                    />
                    <path
                        d="M128.561 121.478c.644-.54-.227-2.643-1.947-4.697-1.719-2.053-3.636-3.281-4.281-2.741-.644.54.227 2.643 1.947 4.696 1.719 2.054 3.636 3.281 4.281 2.742z"
                        fill={colors.black}
                    />
                    <path
                        d="M115.13 125.775c5.357 0 9.7-4.343 9.7-9.7a9.7 9.7 0 00-9.7-9.7 9.7 9.7 0 00-9.7 9.7c0 5.357 4.343 9.7 9.7 9.7z"
                        fill={colors.black}
                    />
                    <path
                        d="M119.235 123.688h-2.947v5.279h2.947v-5.279zm-5.894 0h-2.946v5.279h2.946v-5.279z"
                        fill={colors.black}
                    />
                    <path
                        d="M116.78 129.95c1.356 0 2.456-.413 2.456-.921 0-.509-1.1-.921-2.456-.921-1.356 0-2.456.412-2.456.921 0 .508 1.1.921 2.456.921zm-5.894-.123c1.357 0 2.456-.412 2.456-.921 0-.508-1.099-.921-2.456-.921-1.356 0-2.455.413-2.455.921 0 .509 1.099.921 2.455.921z"
                        fill={colors.black}
                    />
                    <path
                        d="M112.083 103.882c.867-3.489 4.69-5.541 8.54-4.585 3.85.956 6.269 4.558 5.403 8.047-.866 3.488-3.74 3.499-7.59 2.543-3.85-.956-7.219-2.517-6.353-6.005z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M108.587 113.652c.36-.76-1.31-2.306-3.73-3.454-2.42-1.148-4.674-1.462-5.035-.703-.36.76 1.31 2.307 3.73 3.454 2.42 1.148 4.674 1.463 5.035.703z"
                        fill={colors.black}
                    />
                    <path
                        d="M113.679 117.262a3.234 3.234 0 100-6.468 3.234 3.234 0 000 6.468z"
                        fill={colors.white}
                    />
                    <path
                        d="M112.35 113.947a1.079 1.079 0 100-2.157 1.079 1.079 0 000 2.157z"
                        fill={colors.black}
                    />
                    <path
                        d="M115.709 120.766a2.164 2.164 0 01-.349 1.613 2.157 2.157 0 01-2.999.546 2.148 2.148 0 01-.895-1.387v-.004c-.213-1.172.693-1.586 1.865-1.799 1.172-.212 2.166-.141 2.378 1.031z"
                        fill={colors.white}
                    />
                    <path
                        d="M35.38 109.293c.956-2.502 1.095-4.774.31-5.074-.786-.3-2.198 1.485-3.155 3.987-.956 2.502-1.094 4.774-.309 5.074.785.3 2.197-1.485 3.154-3.987z"
                        fill={colors.black}
                    />
                    <path
                        d="M24.991 125.775a9.7 9.7 0 009.7-9.7 9.7 9.7 0 00-9.7-9.7 9.7 9.7 0 00-9.7 9.7c0 5.357 4.343 9.7 9.7 9.7z"
                        fill={colors.black}
                    />
                    <path
                        d="M23.518 123.688h-2.947v5.279h2.947v-5.279zm5.894 0h-2.947v5.279h2.947v-5.279z"
                        fill={colors.black}
                    />
                    <path
                        d="M23.027 129.95c1.356 0 2.456-.413 2.456-.921 0-.509-1.1-.921-2.456-.921-1.356 0-2.456.412-2.456.921 0 .508 1.1.921 2.456.921zm5.893-.123c1.357 0 2.456-.412 2.456-.921 0-.508-1.1-.921-2.455-.921-1.357 0-2.456.413-2.456.921 0 .509 1.1.921 2.456.921z"
                        fill={colors.black}
                    />
                    <path
                        d="M25.237 116.934a3.315 3.315 0 100-6.63 3.315 3.315 0 000 6.63z"
                        fill={colors.white}
                    />
                    <path
                        d="M25.237 114.724a1.105 1.105 0 100-2.21 1.105 1.105 0 000 2.21z"
                        fill={colors.black}
                    />
                    <path
                        d="M15.582 107.054c-.783-3.508 1.72-7.053 5.592-7.917 3.872-.865 7.646 1.278 8.429 4.786.783 3.508-1.783 4.801-5.655 5.666-3.872.864-7.583.972-8.366-2.535z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M18.448 113.652c.36-.76-1.31-2.306-3.73-3.454-2.42-1.148-4.674-1.462-5.034-.703-.36.76 1.31 2.307 3.73 3.454 2.42 1.148 4.674 1.463 5.034.703z"
                        fill={colors.black}
                    />
                    <path
                        d="M20.227 119.574c0 .949 2.444 2.824 5.157 2.824 2.712 0 5.256-2.673 5.256-3.622 0-.949-2.544.184-5.256.184-2.713 0-5.157-.335-5.157.614z"
                        fill={colors.white}
                    />
                    <path
                        d="M71.326 116.541c5.357 0 9.7-4.343 9.7-9.701 0-5.357-4.343-9.7-9.7-9.7-5.357 0-9.7 4.343-9.7 9.7 0 5.358 4.343 9.701 9.7 9.701z"
                        fill={colors.black}
                    />
                    <path
                        d="M69.853 114.453h-2.947v5.28h2.947v-5.28zm5.895 0H72.8v5.28h2.947v-5.28z"
                        fill={colors.black}
                    />
                    <path
                        d="M69.362 120.715c1.356 0 2.456-.412 2.456-.921 0-.509-1.1-.921-2.456-.921-1.356 0-2.456.412-2.456.921 0 .509 1.1.921 2.456.921zm5.894-.123c1.356 0 2.455-.412 2.455-.921 0-.508-1.1-.921-2.455-.921-1.357 0-2.456.413-2.456.921 0 .509 1.1.921 2.456.921z"
                        fill={colors.black}
                    />
                    <path
                        d="M61.917 97.82c-.783-3.508 1.72-7.053 5.592-7.917 3.872-.865 7.645 1.278 8.429 4.785.783 3.508-1.783 4.802-5.655 5.666-3.871.865-7.583.973-8.366-2.535z"
                        fill={colors.primary.main}
                    />
                    <path
                        d="M81.78 102.602c2.1-2.103 3.268-4.342 2.608-5.001-.66-.66-2.898.51-4.998 2.612-2.101 2.103-3.269 4.342-2.609 5.001.66.66 2.898-.51 4.999-2.612zm-18.272 3.287c.66-.659-.508-2.898-2.61-5-2.1-2.103-4.338-3.272-4.998-2.613-.66.66.508 2.899 2.609 5.001 2.1 2.102 4.339 3.272 4.999 2.612z"
                        fill={colors.black}
                    />
                    <path
                        d="M130.299 45.277c-.322-9.947-7.134-17.797-15.216-17.536-17.985.533-18.424 33.515-1.06 35.884l-.367 2.923 5.363-.174-.556-2.892c6.985-1.544 12.128-9.188 11.836-18.205z"
                        fill={colors.red[300]}
                    />
                    <path
                        d="M106.14 50.791l-2.026.066a33.009 33.009 0 01-.372-11.48l2.026-.067c-.748 3.998-.624 7.825.372 11.481z"
                        fill={colors.white}
                    />
                    <path
                        d="M119.281 63.436l-6.078.197.044 1.35 6.078-.196-.044-1.351z"
                        fill={colors.black}
                    />
                    <path
                        d="M101.627 115.156l-.612-.286 15.048-51.032.612.287-15.048 51.031z"
                        fill={colors.black}
                    />
                    <path
                        d="M142.754 53.688c3.675-9.248.563-19.166-6.951-22.152-16.704-6.69-30.272 23.375-15.296 32.478l-1.504 2.533 4.986 1.982.645-2.874c7.021 1.372 14.788-3.583 18.12-11.967z"
                        fill={colors.primary.main}
                    />
                    <path
                        d="M118.401 49.1l-1.883-.749a32.98 32.98 0 014.242-10.675l1.883.749c-2.282 3.367-3.696 6.925-4.242 10.675z"
                        fill={colors.white}
                    />
                    <path
                        d="M119.752 63.694l-.499 1.256 5.651 2.245.499-1.255-5.651-2.246z"
                        fill={colors.black}
                    />
                    <path
                        d="M101.508 113.812l-.587-.334 21.009-47.945.587.334-21.009 47.945z"
                        fill={colors.black}
                    />
                    <path
                        d="M28.558 45.92c-3.976-9.123-13.208-13.9-20.62-10.67-16.515 7.145-4.73 37.953 12.28 33.735l.739 2.851 4.919-2.144-1.586-2.482c5.92-4.017 7.873-13.02 4.268-21.29z"
                        fill={colors.primary.main}
                    />
                    <path
                        d="M8.149 59.974l-1.858.81a32.994 32.994 0 01-4.59-10.53l1.858-.81c.783 3.992 2.313 7.502 4.59 10.53z"
                        fill={colors.white}
                    />
                    <path
                        d="M25.034 66.865l-5.575 2.43.54 1.239 5.575-2.43-.54-1.239z"
                        fill={colors.black}
                    />
                    <path
                        d="M37.932 115.286a.337.337 0 01-.41-.246L22.837 68.503l.656-.163 14.687 46.537a.34.34 0 01-.247.409z"
                        fill={colors.black}
                    />
                    <path
                        d="M41.857 130H6.496a.226.226 0 01-.225-.225c0-.06.023-.117.065-.16a.226.226 0 01.16-.066h35.361a.227.227 0 01.226.226.226.226 0 01-.226.225zm89.869 0H96.364a.227.227 0 01-.225-.225.227.227 0 01.225-.226h35.362a.227.227 0 01.225.226.227.227 0 01-.225.225zm-42.12-9.235H54.245a.224.224 0 01-.16-.384.226.226 0 01.16-.066h35.361a.226.226 0 01.226.225.224.224 0 01-.226.225z"
                        fill={colors.grey[200]}
                    />
                    <path
                        d="M70.918 108.781a3.315 3.315 0 100-6.63 3.315 3.315 0 000 6.63z"
                        fill={colors.white}
                    />
                    <path
                        d="M70.919 106.571a1.105 1.105 0 10-.001-2.21 1.105 1.105 0 000 2.21z"
                        fill={colors.black}
                    />
                    <path
                        d="M73.55 111.597a2.156 2.156 0 01-4.242.772v-.004c-.213-1.172.693-1.586 1.865-1.798 1.171-.213 2.165-.142 2.377 1.03z"
                        fill={colors.white}
                    />
                </g>
                <defs>
                    <clipPath id="validated_register">
                        <path fill={colors.white} d="M0 0h144.298v130H0z" />
                    </clipPath>
                </defs>
            </Icon>
            <VisuallyHidden as="figcaption">
                <FormattedMessage
                    id="components.registration.success-balloons.logo-alt"
                    defaultMessage="Des petits hommes trop heureux."
                />
            </VisuallyHidden>
        </figure>
    );
}
