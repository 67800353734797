import React, { ReactElement } from 'react';
import {
    Spinner,
    Box,
    VStack,
    Text,
    Stack,
    StackProps,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useIntersectionObserver, coreSharedMessages } from 'core';

type FeedIndicatorProps = {
    onIntersect: () => void;
    isLoading: boolean;
    isEnd: boolean;
    loadingMessage?: ReactElement;
    isEnabled?: boolean;
};

export type FeedProps = StackProps &
    FeedIndicatorProps & {
        children: ReactElement;
    };

function FeedIndicator({
    isLoading,
    isEnd,
    loadingMessage,
    onIntersect,
}: FeedIndicatorProps) {
    const target = useIntersectionObserver({
        onIntersect,
        enabled: isEnd,
    });

    return (
        <Box ref={target} textAlign="center" py="6">
            {isLoading && (
                <VStack spacing="4">
                    <Spinner color="primary.main" />
                    <Text>{loadingMessage}</Text>
                </VStack>
            )}
        </Box>
    );
}

export function Feed({
    children,
    onIntersect,
    isLoading,
    loadingMessage = <FormattedMessage {...coreSharedMessages.loading} />,
    isEnd,
    isEnabled = true,
    ...props
}: FeedProps) {
    if (!isEnabled) return <>{children}</>;

    return (
        <>
            <Stack spacing="2" role="feed" aria-busy={isLoading} {...props}>
                {children}
            </Stack>

            <FeedIndicator
                {...{
                    isLoading,
                    isEnd,
                    loadingMessage,
                    onIntersect,
                }}
            />
        </>
    );
}
