import { Icon, IconProps } from '@chakra-ui/react';

export default function CreditCardBarredIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M2.179 2.296a1 1 0 0 0 .006 1.414l.882.874A3.015 3.015 0 0 0 1.955 6.94v10.047c0 1.644 1.281 2.977 2.862 2.977h13.761l1.341 1.33a1 1 0 0 0 1.408-1.42l-.512-.509a3.013 3.013 0 0 0 1.14-2.378V6.94c0-1.644-1.28-2.976-2.861-2.976H5.281L3.593 2.29a1 1 0 0 0-1.414.006zm2.32 3.708a.99.99 0 0 0-.636.935v.108h1.688L4.498 6.004zm3.893 1.043-1.11-1.1h11.812c.527 0 .954.444.954.992v.108H8.392zm1.18 3.987H3.863v5.952c0 .548.427.993.954.993h11.76l-7.005-6.945zm9.802 6.901-6.962-6.901h7.636v5.952a.989.989 0 0 1-.674.95z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
