import axios from 'axios';
import { FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';
import { GuaranteesFamily } from '../types';

const path = `/api/proxy/guarantees/families/[id]`;

async function fetchGuaranteesFamily({
    queryKey: [urn, id],
}: FetchQueryParams) {
    try {
        const res = await axios.get(urn.replace('[id]', id));

        return res.data.data;
    } catch (error) {
        throw new Error(error);
    }
}

function useGuaranteesFamily(id: string) {
    return useQuery<GuaranteesFamily, Error>({
        queryKey: [path, id],
        queryFn: main.fetchGuaranteesFamily,
    });
}

const main = {
    useGuaranteesFamily,
    fetchGuaranteesFamily,
};

export { fetchGuaranteesFamily, useGuaranteesFamily };
