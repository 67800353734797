import { Progress, ProgressProps, ResponsiveValue } from '@chakra-ui/react';
import { useWindowBreakpoints } from 'design-system/hooks';

export type ProgressBarProps = ProgressProps & {
    value: number;
    maxValue: number;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    isRounded?: boolean;
    color?: string | null;
    isHeader?: boolean;
};

type mobileFullWidthLeftStyleType = {
    position: ResponsiveValue<'absolute'>;
    left: '0';
    width: '100%';
};

const mobileFullWidthLeftStyle: mobileFullWidthLeftStyleType = {
    position: 'absolute',
    left: '0',
    width: '100%',
};

function getProgressColor(finalValue) {
    if (finalValue < 30) return 'red.400';
    if (finalValue >= 30 && finalValue < 70) return 'orange.400';
    if (finalValue >= 70 && finalValue < 100) return 'yellow.400';
    if (finalValue === 100) return 'green.400';
}

export function ProgressBar({
    value,
    maxValue,
    size = 'sm',
    isRounded = false,
    color = null,
    isHeader = false,
}: ProgressBarProps) {
    const { isMobile } = useWindowBreakpoints();
    const finalValue = maxValue !== 0 && Math.floor((value / maxValue) * 100);

    return (
        <Progress
            {...(isMobile && isHeader && mobileFullWidthLeftStyle)}
            value={finalValue || value}
            borderRadius={isRounded ? 'sm' : 'none'}
            size={size}
            sx={{
                '& > div': {
                    background: color || getProgressColor(finalValue),
                },
            }}
        />
    );
}
