import { Icon, IconProps } from '@chakra-ui/react';

export default function FileFileIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M7 22a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h5.427a3 3 0 0 1 1.952.722l4.573 3.92A3 3 0 0 1 20 8.92V19a3 3 0 0 1-3 3H7zm-1-3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8.92a1 1 0 0 0-.35-.76l-4.572-3.92a1 1 0 0 0-.651-.24H7a1 1 0 0 0-1 1v14z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                d="M8 13.75c0 .414.336.75.75.75h2.5a.75.75 0 0 0 0-1.5h-2.5a.75.75 0 0 0-.75.75zM8.75 17.5a.75.75 0 0 1 0-1.5h4.5a.75.75 0 0 1 0 1.5h-4.5z"
                fill="currentColor"
            />
        </Icon>
    );
}
