import React from 'react';
import { Container } from '@chakra-ui/react';

import { UtilsContext } from 'core/lib/shared/contexts/UtilsContext';
import { coreErrors, coreSharedMessages } from 'core/lib/shared/i18n';
import { useWindowBreakpoints } from 'design-system/hooks';
import { Error } from 'core/components/Layouts';

type PageNotFoundProps = {
    homePagePath?: string;
};
export function PageNotFound({ homePagePath }: PageNotFoundProps) {
    const { isMobile } = useWindowBreakpoints();
    const {
        siteConfig: { homePagePath: defaultHomePagePath },
    } = React.useContext(UtilsContext);

    return (
        <Container
            size={isMobile ? 'full' : 'md'}
            p={isMobile && '6'}
            height="100vh"
            margin="auto"
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Error
                message={coreErrors.notFoundTitle}
                description={coreErrors.notFoundText}
                errorButtons={[
                    {
                        href: homePagePath || defaultHomePagePath,
                        text: coreSharedMessages.buttonBackHome,
                    },
                ]}
            />
        </Container>
    );
}
