import React from 'react';
import axios from 'axios';
import { QueryStatus } from '@tanstack/react-query';
import { ChallengeError, useRedirect, ChallengeWorkflow } from 'core';

export const REGISTRATION_CHALLENGE_NUMBER_OF_PIN = 6;

const errorCodeMessages = {
    403: 'REGISTRATION_CHALLENGE_NO_PENDING_VALIDATION',
    429: 'REGISTRATION_CHALLENGE_TOO_MANY_TRIES',
    500: 'REGISTRATION_CHALLENGE_INVALID_STRATEGY',
};

export function useRegistrationChallengePost(workflow: ChallengeWorkflow) {
    const { redirect } = useRedirect();
    const [status, setStatus] = React.useState<QueryStatus>(null);
    const [error, setError] = React.useState<ChallengeError>();
    const [code, setCode] = React.useState('');
    const [isCodeValid, setIsCodeValid] = React.useState<boolean>();

    function handleSubmit(evt: React.FormEvent) {
        evt.preventDefault();
        setIsCodeValid(code.length === REGISTRATION_CHALLENGE_NUMBER_OF_PIN);
    }

    function handleInput(value: string) {
        setCode(value);
        setIsCodeValid(
            value.length === REGISTRATION_CHALLENGE_NUMBER_OF_PIN
                ? true
                : undefined
        );
        setStatus(null);
    }

    React.useEffect(() => {
        async function postCode() {
            try {
                setStatus('pending');
                const response = await axios.post(
                    `/api/registration-challenge`,
                    {
                        code,
                    }
                );

                const nextStep = response?.data?.meta?.next_step;
                setStatus('success');
                redirect({
                    pathname: '/oauth2-registration-redirect',
                    query: {
                        authorizationUrl: nextStep?.iri,
                    },
                });
            } catch (err) {
                const {
                    response: { status, data },
                } = err;

                setStatus('error');

                switch (status) {
                    case 400:
                        setError({
                            code: data?.code,
                        });
                        break;
                    case 401:
                        redirect('/register');
                        break;
                    default:
                        setError({
                            code:
                                errorCodeMessages?.[status] ||
                                'REGISTRATION_CHALLENGE_UNKNOWN_ERROR',
                        });
                }
            }
        }

        if (isCodeValid) {
            postCode();
        }

        // Nous savons que `useRedirect#redirect` ne peut pas changer
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, isCodeValid]);

    return {
        error,
        status,
        handleInput,
        handleSubmit,
        isCodeValid,
        isEnabled: workflow === 'registration',
    };
}
