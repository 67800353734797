import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';
import { TeleconsultationCountry } from 'lib/teleconsultation/types';

const path = `/api/proxy/europ-assistance/countries`;

async function fetchTeleconsultationCountries({
    queryKey: [urn],
}: FetchQueryParams) {
    try {
        const response = await axios.get(urn);
        return response.data.data;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useTeleconsultationCountries(isEnabled: boolean) {
    return useQuery<TeleconsultationCountry[], APIError>({
        queryKey: [path],
        queryFn: fetchTeleconsultationCountries,
        enabled: isEnabled,
    });
}
