import { Icon, IconProps } from '@chakra-ui/react';

export default function CrossHospiIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M8 20a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-4V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v4H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v4zm6-16h-4v5a1 1 0 0 1-1 1H4v4h5a1 1 0 0 1 1 1v5h4v-5a1 1 0 0 1 1-1h5v-4h-5a1 1 0 0 1-1-1V4z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
