import { Box, Flex, useRadio } from '@chakra-ui/react';
import { size } from 'lodash';

const BUTTON_SIZE = 4;
const BUTTON_INDICATOR_SIZE = 2;

function RadioCardButton({ isChecked }: any) {
    return (
        <Flex
            mr="3"
            borderRadius="100%"
            w={BUTTON_SIZE}
            h={BUTTON_SIZE}
            borderWidth={1}
            alignItems="center"
            justifyContent="center"
            borderColor={isChecked ? 'primary.main' : 'strokes.medium'}
            flexShrink={0}
            position="relative">
            {isChecked && (
                <Box
                    w={BUTTON_INDICATOR_SIZE}
                    h={BUTTON_INDICATOR_SIZE}
                    bg="primary.main"
                    rounded="rounded"
                />
            )}
        </Flex>
    );
}

export default function RadioCard(props: any) {
    const { getInputProps } = useRadio(props);
    const input = getInputProps();
    const { isChecked, radioRef, optionId, widgetId, errors, onBlur } = props;
    const isError = errors?.[widgetId];
    return (
        <Box as="label">
            <Box
                sx={{ '&:focus + div': { borderColor: 'primary.main' } }}
                as="input"
                {...input}
                onBlur={onBlur}
                id={optionId}
                ref={radioRef}
            />
            <Flex
                px="3"
                py="2.5"
                alignItems="center"
                bg="white"
                borderRadius="md"
                cursor="pointer"
                border="1px solid"
                borderColor={
                    isChecked
                        ? 'primary.main'
                        : size(isError)
                        ? 'red.500'
                        : 'strokes.medium'
                }
                _hover={{ borderColor: 'strokes.dark' }}>
                <RadioCardButton isChecked={isChecked} />
                <Box
                    fontSize="sm"
                    wordBreak="break-word"
                    color={
                        isChecked || typeof isChecked === 'string'
                            ? 'primary.main'
                            : 'grey.800'
                    }
                    fontWeight="semibold">
                    {props.children}
                </Box>
            </Flex>
        </Box>
    );
}
