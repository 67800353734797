import axios from 'axios';
import { FetchQueryParams } from 'core/lib/shared';
import { Site } from 'core/lib/site';
import { useQuery } from '@tanstack/react-query';
import { AdherentUser, EnterpriseUser, SRHUser } from '../types/user';

const paths = {
    adherent: `/api/proxy/security/user/account`,
    'enterprise-admin': `/api/proxy/employer-security/user/account`,
    'selfcare-rh': `/api/proxy/srh-security/user/account`,
};

async function fetchUser({ queryKey: [urn, isEnabled] }: FetchQueryParams) {
    try {
        if (!isEnabled) return null;

        const response = await axios.get(urn);

        const { data } = response.data;
        return data;
    } catch (error) {
        throw new Error(error);
    }
}

function useUser(packageName: Site.Package, isEnabled: boolean) {
    return useQuery<
        AdherentUser.UserInfos | EnterpriseUser.UserInfos | SRHUser.UserInfos,
        Error
    >({
        queryKey: [paths[packageName], isEnabled],
        queryFn: fetchUser,
    });
}

export { paths as userPaths, fetchUser, useUser };
