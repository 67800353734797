import { Button } from '@chakra-ui/react';
import { ExternalLinkIcon } from 'design-system/icons';
import { satisfactionMessages, useSatisfaction } from 'lib/satisfaction';
import { siteConfig } from 'lib/site';
import { useRouter } from 'next/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';

function getAppLink(os: string | undefined, locale: string) {
    if (!os) return;

    const { android, ios } = siteConfig.meta.appIds;

    return os === 'ios'
        ? `itms-apps://apps.apple.com/${locale?.split('-')?.[0]}/app/${ios}`
        : `market://details?id=${android}`;
}

function useSatisfactionHref() {
    const router = useRouter();
    const [href, setHref] = React.useState<string>();
    const [nativeOS, setNativeOS] = React.useState<string>();

    React.useEffect(() => {
        const nativeOS = window.localStorage.getItem('nativeOS');

        setNativeOS(nativeOS);
        setHref(getAppLink(nativeOS, router.locale));
    }, [router.locale]);

    return { href, nativeOS };
}

export function SatisfactionEnd() {
    const { step } = useSatisfaction();
    const { href, nativeOS } = useSatisfactionHref();
    const { appName } = siteConfig;

    return (
        <>
            <FormattedMessage
                {...satisfactionMessages[step].description}
                values={{ appName }}
            />
            {step === 'store' && (
                <Button
                    as="a"
                    href={href}
                    bg="white"
                    color="primary.main"
                    w="full"
                    rightIcon={<ExternalLinkIcon />}>
                    <FormattedMessage
                        id="components.satisfaction.app.store.button"
                        defaultMessage="Noter sur {os, select, android {Google Play} other {l'App Store}}"
                        values={{ os: nativeOS }}
                    />
                </Button>
            )}
        </>
    );
}
