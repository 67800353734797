import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';
import { PreventionTheme } from '../types';

const preventionThemesPath = `/api/proxy/europ-assistance/preventions/themes`;

async function fetchPreventionThemes({ queryKey: [urn] }: FetchQueryParams) {
    try {
        const res = await axios.get(urn);

        return res.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function usePreventionThemes() {
    return useQuery<PreventionTheme[], APIError>({
        queryKey: [preventionThemesPath],
        queryFn: fetchPreventionThemes,
    });
}
