import dayjs from 'dayjs';
import { isArray } from 'lodash';
import { SelectedFilter } from 'core';

export function getFiltersQuery(selectedFilters: SelectedFilter[]) {
    return selectedFilters?.reduce((acc, curr) => {
        if (isArray(curr.values)) {
            curr.values.forEach((value: any) => {
                if (value?.id) {
                    acc += `&${curr.name}[]=${value?.id}`;
                } else {
                    acc += `&${curr.name}=${value}`;
                }
            });

            return acc;
        }

        if (curr?.values?.startDate) {
            const formattedDate = dayjs(
                curr?.values?.startDate,
                'DD/MM/YYYY'
            ).format('YYYY-MM-DD');
            acc += `&${curr.name}[startDate]=${formattedDate}`;
        }

        if (curr?.values?.endDate) {
            const formattedDate = dayjs(
                curr?.values?.endDate,
                'DD/MM/YYYY'
            ).format('YYYY-MM-DD');
            acc += `&${curr.name}[endDate]=${formattedDate}`;
        }

        return acc;
    }, '');
}
