import { validationFormsMessages, FormatMessageFn } from 'core';

export function validateMinSelectedOptions(
    minSelectedOptions: number,
    formatMessage: FormatMessageFn
) {
    return (value: string) => {
        const convertedValue = Array.isArray(value) ? value : [value];
        return minSelectedOptions && convertedValue.length < minSelectedOptions
            ? formatMessage(validationFormsMessages.minSelectedOptions, {
                  minSelectedOptions,
              })
            : undefined;
    };
}
