import { Icon, IconProps } from '@chakra-ui/react';

export default function ClockIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M13.293 11.879a1 1 0 0 1-.293-.707V7a1 1 0 1 0-2 0v5a1 1 0 0 0 .293.707l1.793 1.793a1 1 0 0 0 1.414-1.414l-1.207-1.207z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
