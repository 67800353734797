import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';
import { PreferedCanalEnumType, TeleconsultationType } from '../consts';

export interface TeleconsultationMessage {
    content: string;
    date: string;
    state: string;
}

export interface Teleconsultation {
    id: string;
    status: string;
    resolutionDate: string;
    preferredCanal: PreferedCanalEnumType;
    recallPhoneNumber: string | null;
    beneficiary: {
        identifier: string;
        firstName: string;
        lastName: string;
    };
    isAppointment: boolean;
    specialityName: string;
    specialityId: string;
    appointmentDate: string | null;
    messages?: TeleconsultationMessage[];
}

interface TeleconsultationList {
    teleconsultations: Teleconsultation[];
    meta: {
        hasErrors: boolean;
    };
}

export const teleconsultationListPath = `/api/proxy/europ-assistance/teleconsultations/[type]`;

async function fetchTeleconsultationsList({
    queryKey: [urn, type],
}: FetchQueryParams) {
    try {
        const response = await axios.get(urn.replace('[type]', type));
        return {
            teleconsultations: response.data?.data,
            meta: response?.data?.meta,
        };
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useTeleconsultationsList(type: TeleconsultationType) {
    return useQuery<TeleconsultationList, APIError>({
        queryKey: [teleconsultationListPath, type],
        queryFn: fetchTeleconsultationsList,
    });
}
