import axios from 'axios';

async function updateLanguage(language: string) {
    try {
        const response = await axios.post(
            '/api/proxy/selfcare/user/account/language',
            {
                values: [
                    {
                        id: 'languageSelect',
                        value: language.replace('-', '_'),
                    },
                ],
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export { updateLanguage };
