import { Icon, IconProps } from '@chakra-ui/react';

export default function GlassesIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M17.227 3H16a1 1 0 1 0 0 2h1.227l1.662 6.37A5.002 5.002 0 0 0 12.1 15h-.2a5.002 5.002 0 0 0-6.789-3.63L6.773 5H8a1 1 0 0 0 0-2H6.773a2 2 0 0 0-1.936 1.495L2.22 14.53a4.969 4.969 0 0 0-.108.41l-.08.308.023.006A5 5 0 0 0 11.9 17h.2a5.002 5.002 0 0 0 9.845-1.746l.023-.006-.08-.307a4.965 4.965 0 0 0-.108-.41L19.163 4.494A2 2 0 0 0 17.227 3zM4 16c0-.208.021-.412.062-.608l.078-.3A3.001 3.001 0 0 1 10 16a3 3 0 1 1-6 0zm15.86-.909.078.3a3 3 0 1 1-.078-.3z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
