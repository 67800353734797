import {
    Divider,
    Flex,
    HStack,
    IconProps,
    Stack,
    Text,
} from '@chakra-ui/react';
import { MinusIcon, PenIcon, PlusIcon } from 'design-system/icons';
import React, { ReactNode, useEffect, useState } from 'react';
import { accordionColorConfigs } from '..';
import { useWindowBreakpoints } from 'design-system/hooks';

export type AccordionCardProps = {
    color: 'primary' | 'secondary' | 'tertiary' | 'white' | 'accent';
    children: ReactNode;
    icon: (props: IconProps) => JSX.Element;
    title: string;
    editButton: boolean;
    withDivider?: boolean;
    isOpen?: boolean;
    onToggle?: (isOpen: boolean) => void;
    handleEditButtonClick?: () => void;
};

export default function AccordionCard({
    color,
    children,
    icon: Icon,
    withDivider = false,
    title,
    editButton,
    isOpen: isOpenFromProps,
    onToggle,
    handleEditButtonClick,
}: AccordionCardProps) {
    const [show, setShow] = useState<boolean>(false);
    const [isOpen, setIsOpen] = React.useState(isOpenFromProps || false);
    const { isMobile } = useWindowBreakpoints();
    const isControlled = isOpenFromProps !== undefined;
    const [closeOnContentClick, setCloseOnContentClick] =
        React.useState<boolean>(false);

    useEffect(() => {
        if (isControlled) setIsOpen(isOpenFromProps);
    }, [isControlled, isOpenFromProps]);

    function setMobileDisplay() {
        if (isMobile) {
            setCloseOnContentClick(true);
            setIsOpen(false);
        } else {
            setCloseOnContentClick(false);
            setIsOpen(true);
        }
    }

    useEffect(() => {
        setMobileDisplay();
    }, [isMobile]);

    const handleClickFullAreaClick = () => {
        if (closeOnContentClick) toggle();
    };

    const _renderDivider = () =>
        withDivider && (
            <Divider
                color={accordionColorConfigs[color].iconBg}
                w="5"
                borderWidth="1px"
                borderLeft="4px solid"
                borderColor={accordionColorConfigs[color].iconBg}
            />
        );

    const toggle = () => {
        if (isControlled) {
            onToggle && onToggle(!isOpenFromProps);
        } else {
            setIsOpen((prevIsOpen) => !prevIsOpen);
        }
    };

    return (
        <Stack
            gap="6"
            w="full"
            bg="white"
            p="6"
            borderRadius="lg"
            position="relative"
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            onClick={handleClickFullAreaClick}>
            <HStack
                gap="4"
                w="full"
                justifyContent="space-between"
                data-testid="accordion-header">
                <HStack>
                    {Icon && (
                        <Flex p="1.5" bg="primary.ultralight" rounded="full">
                            <Icon color="primary.main" fontSize="xl" />
                        </Flex>
                    )}
                    <Text
                        color="primary.dark"
                        fontWeight="semibold"
                        fontSize="xl">
                        {title}
                    </Text>
                </HStack>
                {isMobile && (
                    <Flex justifyContent="flex-end">
                        <Flex
                            bg={
                                isOpen
                                    ? 'white'
                                    : accordionColorConfigs[color].iconBg
                            }
                            rounded="full"
                            p="2">
                            {isOpen ? (
                                <MinusIcon color={isOpen ? 'black' : 'white'} />
                            ) : (
                                <PlusIcon color="white" />
                            )}
                        </Flex>
                    </Flex>
                )}
            </HStack>
            {isOpen && (
                <>
                    {_renderDivider()}
                    {children}
                </>
            )}
            {editButton && !isMobile && (
                <Flex
                    position="absolute"
                    right="6"
                    border="2px solid"
                    borderColor="strokes.medium"
                    rounded="full"
                    bg="transparent"
                    color="primary.main"
                    cursor="pointer"
                    p="2"
                    onClick={handleEditButtonClick}
                    opacity={show ? '1' : '0'}
                    transition="all 0.2s ease-out"
                    _hover={{ bg: 'grey.50' }}>
                    <PenIcon fontSize="xl" />
                </Flex>
            )}
        </Stack>
    );
}
