import { Tooltip } from '@chakra-ui/react';

export type TooltipLayoutProps = React.PropsWithChildren<
    { isOverflow: boolean; text: string }
>;

export function TooltipLayout({
    children,
    isOverflow,
    text,
}: TooltipLayoutProps) {
    if (!isOverflow) return <>{children}</>;

    return (
        <Tooltip label={text} placement="top" bg="white" color="black" hasArrow>
            {children}
        </Tooltip>
    );
}
