import { Icon, IconProps } from '@chakra-ui/react';

export default function ModulesIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 23" {...props}>
            <path
                fillRule="evenodd"
                d="m16.188 6.197.003-1.375a.965.965 0 0 0-.486-.839l-1.611-.923-1.608-.93a.97.97 0 0 0-.972 0l-1.608.93-1.611.923a.965.965 0 0 0-.486.839l.003 1.375L5.779 7.36a.965.965 0 0 0-.485.839l.006 3.3v.516l-1.203.695-1.611.923a.965.965 0 0 0-.486.838l.004 1.853L2 18.178c0 .346.185.666.486.839l1.611.923 1.608.93a.97.97 0 0 0 .972 0l1.608-.93 1.611-.923a.965.965 0 0 0 .48-.735l1.138.658a.97.97 0 0 0 .972 0l1.137-.658c.033.305.21.58.48.735l1.612.923 1.608.93a.97.97 0 0 0 .972 0l1.608-.93 1.611-.923a.965.965 0 0 0 .486-.839l-.004-1.853.004-1.852a.965.965 0 0 0-.486-.84l-1.611-.922-1.202-.695-.001-.516.006-3.3a.964.964 0 0 0-.485-.84l-2.033-1.163zM12 4.08l1.122.649 1.132.648-.003 1.293.003 1.3-1.125.645L12 9.269l-1.122-.65-1.132-.647.003-1.293-.003-1.301 1.125-.644L12 4.08zm-.486 7.138-1.608-.93-1.611-.923a.965.965 0 0 1-.486-.838l.001-.66-1.064.608.007 3.027-.001.321 1.533.887 1.611.923a.965.965 0 0 1 .486.838l-.004 1.853v.285l1.622.937 1.621-.937v-.285l-.003-1.852c0-.347.184-.667.486-.84l1.611-.922 1.533-.887v-.327l.006-3.02-1.064-.61.001.66a.965.965 0 0 1-.486.84l-1.611.922-1.608.93a.97.97 0 0 1-.972 0zm-5.323 2.512 1.122.65 1.132.647-.003 1.293.003 1.301-1.125.645-1.129.652-1.122-.649-1.132-.648.003-1.293-.003-1.3 1.125-.645 1.13-.653zm11.618 0 1.122.65 1.131.647-.002 1.293.003 1.301-1.125.645-1.13.652-1.121-.649-1.132-.648.003-1.293-.003-1.3 1.125-.645 1.129-.653z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
