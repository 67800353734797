import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    MenuItem,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
} from '@chakra-ui/react';
import { MoreIcon } from 'design-system/icons';
import { messageShowActions } from 'components/RelatedActions/i18n';
import { TeleconsultationConfirmationAction } from 'pages/teleconsultation/[type]/confirmation/index';

export function TeleconsultationMoreAction({ actions }) {
    const { formatMessage } = useIntl();
    return (
        <Menu placement="bottom-end" closeOnSelect={false}>
            <MenuButton
                as={IconButton}
                bg="transparent"
                rounded="rounded"
                zIndex="1"
                color="black"
                aria-label={formatMessage(messageShowActions)}
                icon={<MoreIcon h="5" w="5" />}
            />
            <MenuList
                color="black"
                fontSize="sm"
                zIndex="2"
                px={0}
                gap={2}
                overflow={'hidden'}>
                {actions?.map((action: TeleconsultationConfirmationAction) => (
                    <MenuItem key={action.name}>
                        <HStack
                            onClick={action.onClickAction}
                            gap={3}
                            color={'gray.800'}
                            fontSize="md"
                            fontWeight="bold"
                            justifyContent={'flex-start'}>
                            {action?.icon && <action.icon w={5} h={5} />}
                            <FormattedMessage {...action.label} />
                        </HStack>
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
}
