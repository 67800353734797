import { Icon } from '@chakra-ui/react';

export default function TextExchangeIllustration() {
    return (
        <Icon
            width="199"
            height="100"
            viewBox="0 0 199 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10622_74752)">
                <path
                    d="M198.95 99.9492H0.0495605V86.3499C0.0495605 84.4779 1.56303 82.9576 3.42661 82.9576H195.573C197.437 82.9576 198.95 84.4779 198.95 86.3499V99.9492Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M102.99 91.158L92.8713 91.685L84.4292 93.4534C84.0721 93.5281 83.7052 93.3777 83.5028 93.0728C83.1875 92.5976 83.3878 91.952 83.9154 91.7398L90.1488 89.2411C90.8143 88.9741 91.4997 88.7589 92.1979 88.5965L104.101 85.8289"
                    fill="#FFE7D5"
                />
                <path
                    d="M102.99 91.158L92.8713 91.685L84.4292 93.4534C84.0721 93.5281 83.7052 93.3777 83.5028 93.0728C83.1875 92.5976 83.3878 91.952 83.9154 91.7398L90.1488 89.2411C90.8143 88.9741 91.4997 88.7589 92.1979 88.5965L104.101 85.8289"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M99.2168 88.4331L93.0608 90.5004C92.6878 90.626 92.3288 90.7874 91.9886 90.9846L89.5984 92.3675C89.0678 92.6743 88.8933 93.3618 89.2126 93.8868C89.5072 94.37 90.1191 94.5473 90.6249 94.2963L92.6412 93.293C93.4119 92.9095 94.2559 92.7012 95.1158 92.6803L99.3041 92.5817L105.332 91.6053"
                    fill="#FFE7D5"
                />
                <path
                    d="M99.2168 88.4331L93.0608 90.5004C92.6878 90.626 92.3288 90.7874 91.9886 90.9846L89.5984 92.3675C89.0678 92.6743 88.8933 93.3618 89.2126 93.8868C89.5072 94.37 90.1191 94.5473 90.6249 94.2963L92.6412 93.293C93.4119 92.9095 94.2559 92.7012 95.1158 92.6803L99.3041 92.5817L105.332 91.6053"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M102.002 83.922C101.67 88.1054 102.208 91.9391 103.869 95.3215L130.769 95.0226L134.637 94.9797C140.187 94.918 144.775 90.615 145.215 85.0567L147.326 58.455L137.235 56.9576C137.235 56.9576 134.009 77.8417 133.919 78.9485L102.002 83.922Z"
                    fill="#A27663"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M98.3806 64.5692L98.6236 64.7814C99.3437 65.411 100.251 65.7806 101.205 65.8314L104.231 65.9908L105.409 66.0158C105.441 66.0158 105.474 66.0158 105.506 66.0197C106.247 66.0407 106.985 66.1443 107.703 66.3256L113.296 67.7403L114.048 66.2698L118.807 56.9556L112.053 53.8611L110.974 50.3781C110.651 49.334 109.94 48.4553 108.991 47.9222L106.442 46.4926C105.714 46.0851 104.796 46.375 104.431 47.1272L104.419 47.1521C104.177 47.6513 104.225 48.2341 104.544 48.6794C104.956 49.2593 105.488 50.0045 105.619 50.1868C105.639 50.2147 105.659 50.2406 105.68 50.2675L106.518 51.2877C106.915 51.7689 107.179 52.3477 107.283 52.9654L107.363 53.4327L101.203 52.4434L98.218 54.6581L97.4414 60.0968L97.2282 61.2406C96.9971 62.477 97.4355 63.7432 98.3806 64.5692Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M97.1171 53.347L106.924 53.4347L103.207 39.1679C103.035 38.5273 102.457 38.083 101.796 38.083H94.0515C93.5328 38.083 93.1559 38.5771 93.2908 39.0802L97.1171 53.349V53.347Z"
                    fill="#FFC9CB"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M105.856 53.4277L102.193 39.3642C102.114 39.0693 101.842 38.8601 101.532 38.8601L94.3697 38.865C94.0712 38.865 93.853 39.15 93.9313 39.4399"
                    fill="#FFC9CB"
                />
                <path
                    d="M105.856 53.4277L102.193 39.3642C102.114 39.0693 101.842 38.8601 101.532 38.8601L94.3697 38.865C94.0712 38.865 93.853 39.15 93.9313 39.4399"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M97.7558 56.9576L97.3412 58.3115C96.9366 59.6336 97.3333 61.0722 98.3588 61.9958L99.7344 63.2361"
                    fill="#FFE7D5"
                />
                <path
                    d="M97.7558 56.9576L97.3412 58.3115C96.9366 59.6336 97.3333 61.0722 98.3588 61.9958L99.7344 63.2361"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M97.8462 53.4337L97.3641 55.3585C97.0507 56.6099 97.4385 57.9329 98.3758 58.8146L100.148 60.4804"
                    fill="#FFE7D5"
                />
                <path
                    d="M97.8462 53.4337L97.3641 55.3585C97.0507 56.6099 97.4385 57.9329 98.3758 58.8146L100.148 60.4804"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M140.198 53.2882C130.043 52.6805 125.33 48.245 125.991 40.2409C126.744 31.1389 130.972 31.9508 131.488 20.4697C132.003 8.98847 129.98 1.26628 142.726 0.148447C155.47 -0.969383 158.202 7.6674 159.517 12.8491C160.832 18.0307 165.889 22.9086 165.586 31.647C165.282 40.3854 158.172 54.3642 140.197 53.2882H140.198Z"
                    fill="#FFBE93"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M144.357 27.357L144.48 27.7216L146.327 33.2071L146.365 33.3187L150.261 44.8866L159.808 40.8935L153.784 21.5367L150.963 25.1123L144.851 27.1886L144.357 27.357Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M144.357 27.357L144.48 27.7216L146.327 33.2061C147.751 32.5665 148.961 31.3849 149.866 30.2681C150.871 29.0277 151.64 27.613 152.173 26.1056L153.784 21.5367L150.963 25.1133L144.85 27.1886L144.356 27.358L144.357 27.357Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M137.474 99.9502C137.645 95.2876 137.826 90.4845 137.812 86.9975C137.811 86.8092 137.81 86.6209 137.808 86.4326C137.805 86.056 137.799 85.6804 137.79 85.3058C137.788 85.1663 137.784 85.0278 137.779 84.8884C137.778 84.8176 137.775 84.7469 137.773 84.6752C137.769 84.5257 137.763 84.3763 137.757 84.2278C137.757 84.2039 137.757 84.18 137.754 84.1561C137.75 84.0445 137.746 83.9329 137.74 83.8213C137.735 83.6978 137.73 83.5753 137.723 83.4537C137.72 83.372 137.715 83.2923 137.71 83.2116C137.705 83.1 137.699 82.9895 137.691 82.8789C137.688 82.8101 137.684 82.7404 137.679 82.6716C137.673 82.5461 137.665 82.4206 137.655 82.295C137.653 82.2472 137.649 82.1994 137.646 82.1516C137.644 82.1237 137.642 82.0958 137.64 82.0689C137.632 81.9623 137.624 81.8557 137.616 81.7501C137.612 81.6973 137.608 81.6455 137.603 81.5927C137.595 81.4861 137.586 81.3805 137.577 81.2748C137.568 81.1772 137.56 81.0796 137.551 80.9829C137.546 80.9162 137.539 80.8504 137.533 80.7847C137.525 80.699 137.516 80.6133 137.507 80.5276C137.495 80.4121 137.483 80.2965 137.47 80.1809C137.469 80.162 137.467 80.1421 137.464 80.1231C137.447 79.9707 137.431 79.8183 137.412 79.6668C137.393 79.5064 137.373 79.347 137.352 79.1876C137.35 79.1697 137.347 79.1528 137.345 79.1348C137.325 78.9884 137.306 78.8419 137.285 78.6955C137.262 78.5351 137.238 78.3747 137.213 78.2153C137.047 77.1462 136.899 76.1071 136.768 75.1009C136.012 69.2896 135.831 64.4875 136.103 60.4386C136.291 57.6171 136.7 55.1622 137.284 52.9864C138.037 50.1818 139.083 47.8425 140.333 45.7773C142.178 42.7316 144.714 39.4857 147.518 36.7449C150.421 33.9065 153.61 31.6111 156.616 30.6377H156.617C156.778 30.5839 156.939 30.5361 157.1 30.4913C159.744 29.759 162.838 30.84 165.482 33.0457H165.483C168.869 35.8702 171.511 40.5368 171.511 45.5929C171.511 50.9171 171.542 79.3181 171.568 99.9502H137.474Z"
                    fill="#A27663"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M136.768 75.1009C136.899 76.1081 137.047 77.1463 137.213 78.2153C137.646 81.0029 137.801 83.9808 137.812 86.9985L141.706 86.8391C147.441 86.604 152.862 84.1312 156.812 79.9458L157.881 78.813L136.768 75.1009Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeMiterlimit="10"
                />
                <path
                    d="M134.763 19.1177C134.763 26.3158 139.058 32.1521 144.357 32.1521C149.049 32.1521 152.953 27.5751 153.786 21.5277C154.984 21.4231 156.14 20.382 156.585 18.8766C157.133 17.0235 156.399 15.1684 154.943 14.733C154.034 14.4621 153.057 14.8098 152.319 15.562C152.248 15.7901 152.219 16.1039 152.214 16.4327C152.205 17.0365 151.42 17.4987 151.044 17.0275C147.004 11.9674 138.98 16.1986 135.617 11.9982C134.761 14.751 134.763 16.6071 134.763 19.1177Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M144.112 26.9903C144.112 26.9903 142.993 28.4579 141.393 27.9268"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M138.622 24.0234C138.622 24.0234 137.983 23.9726 137.805 23.2812"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M142.5 19.1037C142.672 19.1037 142.811 18.9641 142.811 18.7919C142.811 18.6197 142.672 18.4801 142.5 18.4801C142.329 18.4801 142.19 18.6197 142.19 18.7919C142.19 18.9641 142.329 19.1037 142.5 19.1037Z"
                    fill="black"
                />
                <path
                    d="M136.154 20.1718C136.325 20.1718 136.464 20.0321 136.464 19.8599C136.464 19.6877 136.325 19.5481 136.154 19.5481C135.982 19.5481 135.844 19.6877 135.844 19.8599C135.844 20.0321 135.982 20.1718 136.154 20.1718Z"
                    fill="black"
                />
                <path
                    d="M135.618 11.9982C134.939 11.1753 134.417 9.15984 134.417 9.15984"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M167.206 46.6002L161.096 72.382C158.844 81.8826 148.564 87.0025 139.673 83.0522L107.194 68.623C106.654 62.4142 109.305 57.5125 113.754 53.346L144.357 63.0578C144.357 63.0578 148.397 43.6592 149.071 41.3528C149.746 39.0464 152.966 36.0286 155.17 35.664"
                    fill="#A27663"
                />
                <path
                    d="M167.206 46.6002L161.096 72.382C158.844 81.8826 148.564 87.0025 139.673 83.0522L107.194 68.623C106.654 62.4142 109.305 57.5125 113.754 53.346L144.357 63.0578C144.357 63.0578 148.397 43.6592 149.071 41.3528C149.746 39.0464 152.966 36.0286 155.17 35.664"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M107.363 53.4337L100.72 51.3226C100.281 51.0775 99.911 50.7238 99.6472 50.2934L97.9423 47.5128C97.4563 46.7207 96.4457 46.4358 95.6205 46.8582C94.7765 47.2906 94.4175 48.3108 94.8043 49.1796L96.3951 52.7542C96.5806 53.1707 96.8602 53.5393 97.2123 53.8282L101.535 57.386"
                    fill="#FFE7D5"
                />
                <path
                    d="M107.363 53.4337L100.72 51.3226C100.281 51.0775 99.911 50.7238 99.6472 50.2934L97.9423 47.5128C97.4563 46.7207 96.4457 46.4358 95.6205 46.8582C94.7765 47.2906 94.4175 48.3108 94.8043 49.1796L96.3951 52.7542C96.5806 53.1707 96.8602 53.5393 97.2123 53.8282L101.535 57.386"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M101.805 43.9042C102.301 43.8684 102.61 43.4589 102.489 42.9956L101.829 40.4571C101.709 39.9938 101.204 39.6441 100.708 39.681C100.213 39.7169 99.9041 40.1263 100.025 40.5896L100.685 43.1281C100.805 43.5914 101.309 43.9411 101.805 43.9042Z"
                    fill="#072F35"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M101.075 41.0947C101.376 41.0728 101.562 40.8257 101.489 40.5448C101.416 40.2638 101.111 40.0526 100.81 40.0745C100.51 40.0964 100.323 40.3435 100.397 40.6245C100.469 40.9054 100.775 41.1166 101.075 41.0947Z"
                    fill="white"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.2701 92.6992C23.2236 92.6992 25.6178 92.3464 25.6178 91.9112C25.6178 91.4759 23.2236 91.1231 20.2701 91.1231C17.3166 91.1231 14.9224 91.4759 14.9224 91.9112C14.9224 92.3464 17.3166 92.6992 20.2701 92.6992Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.4625 91.9112H16.7185C16.0619 91.9112 15.5283 91.3752 15.5283 90.7156V68.0681H21.6526V90.7156C21.6526 91.3752 21.119 91.9112 20.4625 91.9112Z"
                    fill="white"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeMiterlimit="10"
                />
                <path
                    d="M19.8693 90.2474H17.3125C16.6559 90.2474 16.1223 89.7114 16.1223 89.0518V68.0681H21.0595V89.0518C21.0595 89.7114 20.5259 90.2474 19.8693 90.2474Z"
                    fill="white"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeMiterlimit="10"
                />
                <path
                    d="M19.8693 90.2474H17.3125C16.6559 90.2474 16.1223 89.7114 16.1223 89.0518V79.5144H21.0595V89.0518C21.0595 89.7114 20.5259 90.2474 19.8693 90.2474Z"
                    fill="#D5F4F9"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.7678 89.2471C17.7678 89.2471 14.7369 62.738 22.57 56.2233"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.0471 55.6544C19.0471 56.1017 19.8058 56.4226 19.9358 56.826C20.0657 57.2295 19.6501 57.9509 19.902 58.2995C20.1569 58.6512 20.9583 58.4659 21.3074 58.722C21.6565 58.978 21.725 59.799 22.1415 59.9345C22.5432 60.066 23.0807 59.4453 23.5261 59.4453C23.9714 59.4453 24.5089 60.066 24.9106 59.9345C25.3123 59.8029 25.3976 58.975 25.7447 58.722C26.0918 58.4689 26.8962 58.6512 27.1501 58.2995C27.404 57.9479 26.9815 57.2445 27.1163 56.826C27.2512 56.4076 28.005 56.1017 28.005 55.6544C28.005 55.2071 27.2463 54.8863 27.1163 54.4828C26.9815 54.0643 27.402 53.358 27.1501 53.0093C26.8952 52.6576 26.0938 52.8429 25.7447 52.5869C25.3986 52.3338 25.3271 51.5099 24.9106 51.3744C24.5089 51.2429 23.9714 51.8636 23.5261 51.8636C23.0807 51.8636 22.5432 51.2429 22.1415 51.3744C21.7398 51.5059 21.6545 52.3338 21.3074 52.5869C20.9573 52.8429 20.1559 52.6576 19.902 53.0093C19.6501 53.357 20.0706 54.0643 19.9358 54.4828C19.8009 54.9012 19.0471 55.2071 19.0471 55.6544Z"
                    fill="#F8B472"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.0471 55.6544C19.0471 56.1017 19.8058 56.4226 19.9358 56.826C20.0657 57.2295 19.6501 57.9509 19.902 58.2995C20.1569 58.6512 20.9583 58.4659 21.3074 58.722C21.6565 58.978 21.725 59.799 22.1415 59.9345C22.5432 60.066 23.0807 59.4453 23.5261 59.4453C23.9714 59.4453 24.5089 60.066 24.9106 59.9345C25.3123 59.8029 25.3976 58.975 25.7447 58.722C26.0918 58.4689 26.8962 58.6512 27.1501 58.2995C27.404 57.9479 26.9815 57.2445 27.1163 56.826C27.2512 56.4076 28.005 56.1017 28.005 55.6544C28.005 55.2071 27.2463 54.8863 27.1163 54.4828C26.9815 54.0643 27.402 53.358 27.1501 53.0093C26.8952 52.6576 26.0938 52.8429 25.7447 52.5869C25.3986 52.3338 25.3271 51.5099 24.9106 51.3744C24.5089 51.2429 23.9714 51.8636 23.5261 51.8636C23.0807 51.8636 22.5432 51.2429 22.1415 51.3744C21.7398 51.5059 21.6545 52.3338 21.3074 52.5869C20.9573 52.8429 20.1559 52.6576 19.902 53.0093C19.6501 53.357 20.0706 54.0643 19.9358 54.4828C19.8009 54.9012 19.0471 55.2071 19.0471 55.6544Z"
                    fill="#F8B472"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M23.5271 57.2355C24.3964 57.2355 25.1011 56.5276 25.1011 55.6544C25.1011 54.7812 24.3964 54.0733 23.5271 54.0733C22.6578 54.0733 21.9531 54.7812 21.9531 55.6544C21.9531 56.5276 22.6578 57.2355 23.5271 57.2355Z"
                    fill="#FFC9CB"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M93.2641 34.9108L92.2842 32.9312"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M91.8399 35.935L90.3274 34.328"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M90.7757 37.3318L88.8625 36.2378"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M90.1638 38.9796L88.0166 38.4954"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M91.6399 28.6443V24.5633H95.7943C97.2658 24.5633 98.4597 23.3074 98.4597 21.7568V11.1224C98.4597 9.57296 97.2669 8.31587 95.7943 8.31587H62.1493C60.6778 8.31587 59.4839 9.57181 59.4839 11.1224V21.7568C59.4839 23.3063 60.6767 24.5633 62.1493 24.5633H88.1174L91.6399 28.6443Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M94.0264 20.0445H63.5954"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M94.0264 16.4604H65.9601"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M94.0264 12.8763H63.5954"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <mask
                    id="path-48-outside-1_10622_74752"
                    maskUnits="userSpaceOnUse"
                    x="40.9087"
                    y="34.935"
                    width="40"
                    height="22"
                    fill="black">
                    <rect
                        fill="white"
                        x="40.9087"
                        y="34.935"
                        width="40"
                        height="22"
                    />
                    <path d="M41.9087 39.935C41.9087 37.7258 43.6996 35.935 45.9087 35.935H75.9934C78.2025 35.935 79.9934 37.7258 79.9934 39.935V52.0887C79.9934 54.2978 78.2025 56.0887 75.9934 56.0887H45.9087C43.6996 56.0887 41.9087 54.2978 41.9087 52.0887V39.935Z" />
                </mask>
                <path
                    d="M41.9087 39.935C41.9087 37.7258 43.6996 35.935 45.9087 35.935H75.9934C78.2025 35.935 79.9934 37.7258 79.9934 39.935V52.0887C79.9934 54.2978 78.2025 56.0887 75.9934 56.0887H45.9087C43.6996 56.0887 41.9087 54.2978 41.9087 52.0887V39.935Z"
                    fill="#FFC9CB"
                />
                <path
                    d="M45.9087 36.235H75.9934V35.635H45.9087V36.235ZM79.6934 39.935V52.0887H80.2934V39.935H79.6934ZM75.9934 55.7887H45.9087V56.3887H75.9934V55.7887ZM42.2087 52.0887V39.935H41.6087V52.0887H42.2087ZM45.9087 55.7887C43.8652 55.7887 42.2087 54.1321 42.2087 52.0887H41.6087C41.6087 54.4635 43.5339 56.3887 45.9087 56.3887V55.7887ZM79.6934 52.0887C79.6934 54.1321 78.0369 55.7887 75.9934 55.7887V56.3887C78.3682 56.3887 80.2934 54.4635 80.2934 52.0887H79.6934ZM75.9934 36.235C78.0369 36.235 79.6934 37.8915 79.6934 39.935H80.2934C80.2934 37.5601 78.3682 35.635 75.9934 35.635V36.235ZM45.9087 35.635C43.5339 35.635 41.6087 37.5601 41.6087 39.935H42.2087C42.2087 37.8915 43.8652 36.235 45.9087 36.235V35.635Z"
                    fill="#0D0D0D"
                    mask="url(#path-48-outside-1_10622_74752)"
                />
                <path
                    d="M76.2444 47.2628H45.8222"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M76.2444 51.0876H45.8222"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M76.2444 43.6798H48.1863"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M76.2444 40.0967H45.8222"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_10622_74752">
                    <rect width="199" height="100" fill="white" />
                </clipPath>
            </defs>
        </Icon>
    );
}
