import React from 'react';
import { ErrorBoundary } from '@sentry/nextjs';
import {
    FormattedMessage,
    MessageDescriptor,
    useIntl,
    FormatNumberOptions,
    defineMessage,
} from 'react-intl';
import * as Sentry from '@sentry/nextjs';
import { Box } from '@chakra-ui/react';
import { useToast } from 'design-system/components/Toast';
import { IS_DEV_ENV } from 'core/consts';

const unavailableTextMessage = defineMessage({
    id: 'errors.unavailable-text.default',
    defaultMessage: 'Texte indisponible',
});

function UnavailableText() {
    return (
        <Box as="span" fontStyle="italic" color="grey.700">
            <FormattedMessage {...unavailableTextMessage} />
        </Box>
    );
}

type Props = MessageDescriptor & {
    values?: Record<string, any>;
    debugKey: string;
};

const errorMessage = (key: string) =>
    `Une erreur de traduction est survenue, avec la clé "${key}".`;

export function useSafeIntl() {
    const intl = useIntl();
    const safeFormatMessage = (
        message: MessageDescriptor,
        values?: Props['values'],
        debugKey?: Props['debugKey']
    ) => {
        try {
            return intl.formatMessage(message, values);
        } catch {
            const errorMessage = `Missing id and/or defaultMessage for translation with the following debugKey: ${debugKey}`;

            if (IS_DEV_ENV) {
                // Logger.error(errorMessage);
            } else {
                Sentry.captureMessage(errorMessage);
            }

            return intl.formatMessage(unavailableTextMessage);
        }
    };

    const safeFormatNumber = (
        value: number,
        opts?: FormatNumberOptions,
        componentName?: string
    ) => {
        try {
            return intl.formatNumber(value, opts);
        } catch {
            throw new Error(`Une erreur est survenue dans ${componentName}`);
        }
    };

    return { safeFormatMessage, safeFormatNumber };
}

function useSafeIntlErrorToast({ debugKey, ...message }: Props) {
    const errorMessage = `Missing id and/or defaultMessage for translation with the following debugKey: ${debugKey}`;
    const toast = useToast({
        status: 'error',
        description: errorMessage,
    });

    React.useEffect(() => {
        if (!message.id || !message.defaultMessage) {
            if (IS_DEV_ENV) {
                // Logger.error(errorMessage);
                // toast();
            } else {
                Sentry.captureMessage(errorMessage);
            }
        }
    }, [errorMessage, message.defaultMessage, message.id, toast]);
}

export function SafeFormattedMessage({ debugKey, ...message }: Props) {
    useSafeIntlErrorToast({ debugKey, ...message });

    if (!message.id || !message.defaultMessage) {
        return <UnavailableText />;
    }

    return (
        <ErrorBoundary
            fallback={() => {
                throw new Error(errorMessage(debugKey));
            }}>
            <FormattedMessage {...message} />
        </ErrorBoundary>
    );
}
