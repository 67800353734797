import { Icon, IconProps } from '@chakra-ui/react';

export default function XRayIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M5 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H5zm14 2h-4.25v2.354A2.751 2.751 0 0 1 14 11.75a3.087 3.087 0 0 1-1.385-.317l-.017-.008c-.274-.124-.385-.175-.598-.175-.213 0-.324.05-.598.175l-.017.008c-.317.144-.73.317-1.385.317a2.75 2.75 0 0 1-.75-5.396V4H5v16h4.25v-1.354A2.751 2.751 0 0 1 10 13.25c.655 0 1.068.173 1.385.317l.017.008c.274.124.385.175.598.175.213 0 .324-.05.598-.175l.017-.008c.317-.144.73-.317 1.385-.317a2.75 2.75 0 0 1 .75 5.396V20H19V4zm-5.75 16v-2.75H14a1.25 1.25 0 1 0 0-2.5c-.345 0-.532.077-.765.183l-.053.024c-.262.12-.637.293-1.182.293-.545 0-.92-.172-1.182-.293l-.053-.024c-.233-.106-.42-.183-.765-.183a1.25 1.25 0 1 0 0 2.5h.75V20h2.5zm-2.5-16h2.5v3.75H14a1.25 1.25 0 1 1 0 2.5c-.345 0-.532-.077-.765-.183l-.053-.024c-.262-.12-.637-.293-1.182-.293-.545 0-.92.172-1.182.293l-.053.024c-.233.106-.42.183-.765.183a1.25 1.25 0 1 1 0-2.5h.75V4z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
