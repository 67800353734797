import React from 'react';
import { InfiniteData } from '@tanstack/react-query';
import { Statement, UseStatements } from 'lib/refunds';

function useStatementsGrouping(data?: InfiniteData<UseStatements>) {
    const [statements, setStatements] = React.useState<Statement[] | null>(
        null
    );

    React.useEffect(() => {
        if (data?.pages)
            setStatements(
                data.pages.reduce((prev, { statements: s }) => {
                    if (!s) {
                        return prev;
                    }
                    return [...prev, ...s];
                }, [] as Statement[])
            );
    }, [data]);

    return statements;
}

export { useStatementsGrouping };
