import { FieldElement, Ref } from 'react-hook-form';
import type { WidgetKeys } from './widgets';

export type Confirmation = {
    placeholder?: string;
    title?: string;
    description?: string;
};

export type Option = {
    id?: string;
    label?: string;
    value?: string;
    subWidget?: WidgetKeys;
    subWidgets?: WidgetKeys[];
};

export type FormValues = Record<string, any>;

export type FormLink = {
    code: string;
    type: string;
};

export type FormLinksType = {
    url?: string;
    linkKey?: string;
};

export type FormLinks = {
    [key: string]: FormLinksType[];
};

export type FormObject = {
    id?: string;
    title: string;
    description?: string;
    iri?: string;
    showSatisfaction: boolean;
    widgets: WidgetKeys[];
    buttonKey?: string;
    descriptionMessage?: string;
    link?: FormLink;
    links?: FormLinks;
    footer?: string;
    hostName?: string;
};

export type FormWorkflow =
    | 'user_registration_challenge'
    | 'user_registration_challenge_paper_code'
    | 'signature_cotisation'
    | 'signature-health-subscription'
    | 'signature-beneficiaries-designation.standard'
    | 'signature-beneficiaries-designation.specific-guided'
    | 'signature_provident_beneficiaries_designation'
    | 'signature_provident_standard_beneficiaries_designation'
    | 'signature_provident_full_capital_beneficiaries_designation'
    | 'signature_provident_divided_capital_beneficiaries_designation'
    | 'signature_exemption'
    | 'signature_employee_beneficiary_designation_standard'
    | 'signature_employee_beneficiary_designation_full_capital'
    | 'signature_employee_beneficiary_designation_divided_capital'
    | 'signature-beneficiaries-designation.specific-guided.full-capital'
    | 'signature-beneficiaries-designation.specific-guided.divided-capital'
    | 'signature-sepa-primo'
    | 'legal-documents';

export type FormNextStep = {
    procedureId?: string;
    iri?: string;
    workflow: FormWorkflow;
};

export type FormFieldRef = (
    ref: FieldElement<Record<string, any>> & Ref
) => void;

export enum FormTypesEnum {
    ADD = 'add',
    EDIT = 'edit',
}

export type BaseIri =
    | 'selfcare'
    | 'provident'
    | 'security'
    | 'register/security'
    | 'simulator'
    | 'employee-exemption'
    | 'employer-exemption'
    | 'employer-security'
    | 'employer-beneficiary-designation'
    | 'employee-beneficiary-designation'
    | 'pe-admin'
    | 'europ-assistance'
    | 'employer-legal-documents'
    | 'employee-legal-documents'
    | 'home-consultation'
    | 'healthcare-access'
    | 'medicalib'
    | 'srh-admin'
    | 'srh-security';
