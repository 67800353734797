import { OnBoardingType } from '../types';
import {
    MobileIntroIllustration,
    DesktopIntroIllustration,
    TeleconsultationIllustration,
    HomeConsultationIllustration,
    PreventionIllustration,
} from './illustrations';
import { NewServicesIllustration } from 'components/Illustrations';

export const ONBOARDING_V2_SESSION_KEY = 'isOnboardingV2Closed';

const INTRO_EVOLUTION_STEP = {
    titleKey: 'home.onboarding-v2.intro.-evolution-title',
    descriptionKey: 'home.onboarding-v2.intro.evolution-description',
    illustration: {
        mobile: MobileIntroIllustration,
        desktop: DesktopIntroIllustration,
    },
};

const INTRO_BENEFICIARY_STEP = {
    titleKey: 'home.onboarding-v2.intro.new-title',
    descriptionKey: 'home.onboarding-v2.intro.new-description-beneficiary',
    illustration: {
        mobile: NewServicesIllustration,
        desktop: NewServicesIllustration,
    },
};

const INTRO_STEP = {
    titleKey: 'home.onboarding-v2.intro.new-title',
    descriptionKey: 'home.onboarding-v2.intro.new-description',
    illustration: {
        mobile: MobileIntroIllustration,
        desktop: DesktopIntroIllustration,
    },
};

const NEW_SERVICES_STEP = {
    headerKey: 'home.onboarding-v2.step1',
    illustration: {
        mobile: NewServicesIllustration,
        desktop: NewServicesIllustration,
    },
    isNew: true,
};

const TELECONSULTATION_STEP = {
    descriptionKey: 'home.onboarding-v2.step2',
    illustration: {
        mobile: TeleconsultationIllustration,
        desktop: TeleconsultationIllustration,
    },
};

const HOME_CONSULTATION_STEP = {
    descriptionKey: 'home.onboarding-v2.step3',
    illustration: {
        mobile: HomeConsultationIllustration,
        desktop: HomeConsultationIllustration,
    },
};

const PREVENTION_STEP = {
    descriptionKey: 'home.onboarding-v2.step4',
    illustration: {
        mobile: PreventionIllustration,
        desktop: PreventionIllustration,
    },
};

export const ONBOARDING: OnBoardingType[] = [
    INTRO_STEP,
    NEW_SERVICES_STEP,
    TELECONSULTATION_STEP,
    HOME_CONSULTATION_STEP,
    PREVENTION_STEP,
];

export const ONBOARDING_EVOLUTION: OnBoardingType[] = [
    INTRO_EVOLUTION_STEP,
    NEW_SERVICES_STEP,
    TELECONSULTATION_STEP,
    HOME_CONSULTATION_STEP,
    PREVENTION_STEP,
];

export const ONBOARDING_BENEFICIARY: OnBoardingType[] = [
    INTRO_BENEFICIARY_STEP,
    TELECONSULTATION_STEP,
    HOME_CONSULTATION_STEP,
    PREVENTION_STEP,
];

export const getEnhanceStyleOfOnboarding = (color: string = '#409BA8') => ({
    '.swiper-slide': {
        display: 'flex',
        h: 'auto',
    },
    '.swiper-slide swiper-slide-active': {
        w: '100%',
    },
    '.swiper-pagination': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'static',
    },
    '.swiper': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexGrow: '1',
        gap: '50px',
    },
    '.swiper-wrapper': {
        display: 'flex',
        width: '100%',
        flexGrow: '1',
        height: 'calc(100% - 50px - 16px)',
    },
    '.swiper-container': {
        display: 'flex',
        flexDirection: 'column-reverse',
    },
    '.swiper-pagination-bullets': {
        height: '16px',
    },
    '.swiper-pagination-bullets .swiper-pagination-bullet': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '0.3s',
        m: '0 10px',
        overflow: 'hidden',
    },
    '.swiper-pagination-bullets .swiper-pagination-bullet > svg': {
        width: '1.5em',
        height: '1.5em',
    },
    '.swiper-pagination-bullets .swiper-pagination-bullet-active': {
        width: '16px',
        height: '16px',
        bg: color,
        margin: '0 5px',
    },
    '.swiper-slide svg': {
        maxHeight: '295px',
        flexShrink: 1,
    },
});
