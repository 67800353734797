import { Flex, FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';
import { WidgetProps } from './widgets/types';
import { SafeFormattedMessage, formMessages, FormHelperIcon } from 'core';

type FieldsetProps = React.PropsWithChildren<{
    legend: WidgetProps['title'];
    description?: WidgetProps['description'];
    help?: WidgetProps['help'];
    isRequired?: boolean;
    isInvalid?: boolean;
}>;

function Fieldset({
    children,
    legend,
    help,
    description,
    isRequired,
    isInvalid,
}: FieldsetProps) {
    return (
        <FormControl as="fieldset" {...{ isRequired, isInvalid }}>
            <Flex>
                {legend && (
                    <FormLabel as="legend">
                        <SafeFormattedMessage
                            {...formMessages[legend]}
                            debugKey={legend}
                        />
                    </FormLabel>
                )}
                {help && (
                    <FormHelperIcon>
                        <SafeFormattedMessage
                            {...formMessages[help]}
                            debugKey={help}
                        />
                    </FormHelperIcon>
                )}
            </Flex>
            {description && (
                <FormHelperText>
                    <SafeFormattedMessage
                        {...formMessages[description]}
                        debugKey={description}
                    />
                </FormHelperText>
            )}
            {children}
        </FormControl>
    );
}

export default Fieldset;
