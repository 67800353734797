const { SentryConfig, beforeSend } = require('./sentryConfig');
const { filterSensitiveData } = require('./filterSensitiveData');
const { shouldFilterAPIError } = require('./shouldFilterAPIError');

module.exports = {
    SentryConfig,
    beforeSend,
    filterSensitiveData,
    shouldFilterAPIError,
};
