import { Icon, IconProps } from '@chakra-ui/react';

export default function TransferIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M18.984 10.766H10.81a.822.822 0 1 1 0-1.645h8.174l-2.33-2.957a.822.822 0 1 1 1.293-1.018l3.378 4.288a.822.822 0 0 1 0 1.018l-3.378 4.289a.822.822 0 1 1-1.292-1.018l2.33-2.957zM5.103 13.234h8.174a.822.822 0 1 1 0 1.645H5.103l2.33 2.957a.822.822 0 1 1-1.292 1.018l-3.378-4.288a.822.822 0 0 1 0-1.018L6.14 9.259a.822.822 0 0 1 1.292 1.018l-2.33 2.957z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
