import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { PreferedCanalEnumType } from 'lib/teleconsultation';
import { useQuery } from '@tanstack/react-query';

export interface Prevention {
    id: string;
    appointmentDate: string | null;
    beneficiary: {
        identifier: string;
        firstName: string;
        lastName: string;
    };
    recallPhoneNumber: string;
    speciality: string;
    specialityName: string;
    preferredCanal: PreferedCanalEnumType;
    phone: string;
    themeId: string;
    themeName: string;
    status: string;
}

export const preventionDetailsPath = `/api/proxy/europ-assistance/preventions/[id]`;

async function fetchPreventionDetails({
    queryKey: [urn, identifier],
}: FetchQueryParams) {
    try {
        const response = await axios.get(urn.replace('[id]', identifier));
        const { identifier: id, ...otherProps } = response.data?.data;
        return {
            id,
            specialityId: otherProps.themeId,
            specialityName: otherProps.themeName,
            ...otherProps,
        };
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function usePreventionDetails(
    identifier: string,
    enabled: boolean = true
) {
    return useQuery<Prevention, APIError>({
        queryKey: [preventionDetailsPath, identifier],
        queryFn: fetchPreventionDetails,
        enabled: enabled && !!identifier,
    });
}
