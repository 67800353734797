import React from 'react';
import { Box } from '@chakra-ui/react';
import { contextError } from 'core/utils';
import { rgba } from 'themes';

type LegendRowProps = {
    selected?: boolean;
    bordered?: boolean;
    color?: string;
    onClick?: () => void;
};
type LegendRowContextProps = Required<
    Pick<LegendRowProps, 'color' | 'selected'>
>;

const LegendRowContext = React.createContext<LegendRowContextProps | null>(
    null
);

function useLegendRowContext(): LegendRowContextProps {
    const context = React.useContext(LegendRowContext);
    if (!context) {
        throw contextError('useLegendRowContext', 'LegendRow');
    }
    return context;
}

function LegendRow({
    children,
    selected = false,
    color = 'black',
    bordered = false,
    onClick,
}: React.PropsWithChildren<LegendRowProps>) {
    return (
        <LegendRowContext.Provider value={{ selected, color }}>
            <Box
                as="li"
                aria-selected={selected}
                listStyleType="none"
                borderBottom={bordered ? '1px solid' : undefined}
                borderColor="grey.50"
                sx={{
                    '&[aria-selected="true"]': {
                        bgColor: rgba(color, 0.1),
                    },
                }}>
                <Box
                    as="button"
                    bg="none"
                    border="0"
                    display="flex"
                    alignItems="center"
                    position="relative"
                    w="100%"
                    outline="none"
                    py="2"
                    px="3"
                    onClick={onClick}
                    _focus={{
                        bgColor: 'grey.50',
                    }}
                    _hover={{
                        bgColor: 'grey.50',
                    }}
                    sx={{
                        '> * + *': {
                            ml: 3,
                        },
                    }}>
                    {children}
                </Box>
            </Box>
        </LegendRowContext.Provider>
    );
}

export { useLegendRowContext, LegendRow };
