import { Icon, IconProps } from '@chakra-ui/react';

export default function FileUploadIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M12 19a.75.75 0 0 0 .75-.75v-3.667l1.195.977a.75.75 0 0 0 .95-1.16l-2.42-1.98a.75.75 0 0 0-.95 0l-2.42 1.98a.75.75 0 0 0 .95 1.16l1.195-.977v3.667c0 .414.336.75.75.75zM14.25 22a.75.75 0 0 0 0-1.5h-4.5a.75.75 0 0 0 0 1.5h4.5z"
                fill="currentColor"
            />
            <path
                d="M4 5v14c0 1.5 1.5 3 3 3a1 1 0 1 0 0-2c-.5 0-1-.5-1-1V5a1 1 0 0 1 1-1h5.427a1 1 0 0 1 .65.24l4.574 3.92a1 1 0 0 1 .349.76V19c0 .5-.5 1-1 1a1 1 0 1 0 0 2c1.5 0 3-1.5 3-3V8.92a3 3 0 0 0-1.048-2.278l-4.573-3.92A3 3 0 0 0 12.427 2H7a3 3 0 0 0-3 3z"
                fill="currentColor"
            />
        </Icon>
    );
}
