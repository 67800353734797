import { HStack, Text } from '@chakra-ui/react';
import { useSafeIntl } from 'core';
import { InfoFlashIcon } from 'design-system/icons';
import { dividedCapitalMessages } from 'core/lib/signature';
import { FormattedMessage } from 'react-intl';

const capitalStatesColors = {
    full: {
        primary: 'green.50',
        secondary: 'success.main',
    },
    under: {
        primary: 'orange.50',
        secondary: 'warning.main',
    },
    over: {
        primary: 'red.50',
        secondary: 'error.main',
    },
};

function getCapitalState(capital) {
    return capital === 100 ? 'full' : capital < 100 ? 'under' : 'over';
}

type CapitalAlertProps = {
    capital: number;
};

export function DividedCapitalAlert({ capital }: CapitalAlertProps) {
    const capitalState = getCapitalState(capital);
    const { safeFormatNumber } = useSafeIntl();

    return (
        <HStack
            bg={capitalStatesColors[capitalState].primary}
            px="3"
            py="4"
            borderRadius="md">
            <InfoFlashIcon
                w="5"
                h="5"
                color={capitalStatesColors[capitalState].secondary}
            />
            <Text
                color={capitalStatesColors[capitalState].secondary}
                fontWeight="bold">
                <FormattedMessage
                    {...dividedCapitalMessages[capitalState]}
                    values={{
                        allocatedCapital: safeFormatNumber(capital),
                        remainingCapital: safeFormatNumber(100 - capital),
                    }}
                />
            </Text>
        </HStack>
    );
}
