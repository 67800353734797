import React from 'react';
import { FormValues, Validations } from 'core';
import {
    UITreatement,
    useTransformUITreatments,
} from './useTransformUITreatments';
import { useFormContext } from 'react-hook-form';

export function useMultipleTreatments(
    inputId: string,
    validations: Validations
) {
    const {
        setValue,
        trigger,
        getValues,
        formState: { dirtyFields },
    } = useFormContext();
    const [treatments, setTreatments] = React.useState<FormValues[]>([]);
    const [UITreatments, setUITreatments] = React.useState<UITreatement[]>([]);
    const [canAdd, setCanAdd] = React.useState(true);
    const [shouldShowInput, setShouldShowInput] = React.useState(false);
    const transformUITreatments = useTransformUITreatments();

    const add = (treatment: FormValues[]) => {
        setTreatments((currentTreatments) => [...currentTreatments, treatment]);
        setUITreatments((currentUITreatments) => [
            ...currentUITreatments,
            transformUITreatments(treatment),
        ]);
    };

    const remove = (position: number) => {
        setTreatments((currentTreatments) =>
            currentTreatments.filter(
                (_, treatmentPosition) => treatmentPosition !== position
            )
        );
        setUITreatments((currentUITreatments) =>
            currentUITreatments.filter(
                (_, treatmentPosition) => treatmentPosition !== position
            )
        );
    };

    React.useEffect(() => {
        const currentFormValues = Object.keys(getValues());
        const isInputDirty = dirtyFields[inputId];

        if (
            currentFormValues.includes(inputId) &&
            (isInputDirty || !!treatments.length)
        ) {
            setValue(
                inputId,
                treatments.length ? JSON.stringify(treatments) : undefined,
                { shouldDirty: true }
            );
            trigger(inputId);
        }
    }, [getValues, inputId, setValue, treatments, trigger, dirtyFields]);

    React.useEffect(() => {
        setShouldShowInput(validations.required || treatments.length !== 0);
    }, [treatments, validations.required]);

    React.useEffect(() => {
        setCanAdd(treatments.length < validations.maxTreatmentsNumber);
    }, [treatments, validations]);

    return {
        add,
        remove,
        UITreatments,
        canAdd,
        shouldShowInput,
    };
}
