import { Icon, IconProps } from '@chakra-ui/react';

const MapIconMarkerIcon = (props: IconProps) => (
    <Icon
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="20"
        viewBox="0 0 14 20"
        fill="none"
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 7C10 8.65685 8.65685 10 7 10C5.34315 10 4 8.65685 4 7C4 5.34315 5.34315 4 7 4C8.65685 4 10 5.34315 10 7ZM8.5 7C8.5 7.82843 7.82843 8.5 7 8.5C6.17157 8.5 5.5 7.82843 5.5 7C5.5 6.17157 6.17157 5.5 7 5.5C7.82843 5.5 8.5 6.17157 8.5 7Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 7C14 10.866 8 20 7 20C6 20 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM12 7C12 7.55526 11.7562 8.54792 11.1983 9.89883C10.6653 11.1895 9.93066 12.6085 9.15045 13.9369C8.39872 15.2168 7.63009 16.3695 7 17.2016C6.36991 16.3695 5.60128 15.2168 4.84955 13.9369C4.06934 12.6085 3.3347 11.1895 2.80171 9.89883C2.24384 8.54792 2 7.55526 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7Z"
            fill="currentColor"
        />
    </Icon>
);
export default MapIconMarkerIcon;
