import { Box, BoxProps } from '@chakra-ui/react';
import { defineMessage, FormattedMessage } from 'react-intl';

const intlMessage = defineMessage({
    id: 'components.optional',
    defaultMessage: 'optionnel',
});

export function OptionalIndicator(props: BoxProps) {
    return (
        <Box
            as="span"
            color="grey.600"
            fontWeight="normal"
            fontStyle="italic"
            fontSize="sm"
            {...props}>
            (<FormattedMessage {...intlMessage} />)
        </Box>
    );
}
