import React, { useEffect, useState } from 'react';
import { useRadioGroup, Stack, SimpleGrid } from '@chakra-ui/react';
import { BeneficiaryOption } from 'lib/forms';
import { FormGroup, useInputValidation, WidgetProps } from 'core';
import LinkedToMessage from './LinkedToMessages';
import BeneficiarySelectRadioButton from './RadioButton';
import { useController } from 'react-hook-form';

export type BeneficiarySelectProps = Omit<WidgetProps, 'options'> & {
    options: BeneficiaryOption[];
    type: 'default' | 'bankDetails' | 'teleconsultation';
};

export type MainBeneficiaryType = {
    status: 'main' | 'partner';
    firstName: string;
    isSubscriber: boolean;
};

function getBeneficiaries(
    options: BeneficiarySelectProps['options'],
    type: BeneficiarySelectProps['type']
) {
    switch (type) {
        case 'teleconsultation':
            return options
                ?.filter((option) => option.isSubscriber)
                ?.map(({ status, firstName }) => ({
                    status,
                    firstName,
                })) as MainBeneficiaryType[];
        default:
            return options
                ?.filter((option) =>
                    ['main', 'partner'].includes(option.status)
                )
                ?.map(({ status, firstName }) => ({
                    status,
                    firstName,
                })) as BeneficiaryOption[];
    }
}

export function BeneficiarySelect({
    id,
    options,
    validations,
    links,
    title,
    name,
    readonly,
    description,
    help,
    defaultValue,
    type,
}: BeneficiarySelectProps) {
    const [benefeciariesLinkedTo, setBenefeciariesLinkedTo] = useState<
        string[] | null
    >(null);
    const inputName = name ?? `${id}.value`;
    const { setValue, control } = useInputValidation(validations, inputName);

    const { field } = useController({ name: inputName, control, defaultValue });

    const { getRadioProps, value } = useRadioGroup({
        ...field,
        value: field.value ?? '',
    });

    const beneficiaries = getBeneficiaries(options, type);

    useEffect(() => {
        if (defaultValue) setValue(inputName, defaultValue);
    }, [defaultValue, setValue, inputName]);

    useEffect(() => {
        // Auto select the beneficiary when there is only one
        if (options.length === 1) setValue(inputName, options[0].id);
    }, [options, setValue, inputName]);

    return (
        <FormGroup
            label={title}
            name={inputName}
            isRequired={validations.required}
            isReadOnly={readonly}
            {...{ id, description, help, links }}>
            <Stack spacing={8}>
                <SimpleGrid
                    columns={[
                        1,
                        1,
                        type === 'bankDetails' || type === 'teleconsultation'
                            ? 1
                            : 2,
                    ]}
                    spacing={4}>
                    {options?.map((option) => {
                        const radio = getRadioProps({ value: option.id });

                        const onChange = (evt: any) => {
                            setBenefeciariesLinkedTo(option.linkedTo ?? null);
                            radio.onChange?.(evt);
                        };

                        return (
                            <BeneficiarySelectRadioButton
                                {...radio}
                                {...option}
                                key={option.id}
                                id={id}
                                onChange={onChange}
                                onBlur={field.onBlur}
                                checkedOption={value as string}
                                beneficiaries={beneficiaries}
                                type={type}
                                radioRef={field.ref}>
                                {option.id}
                            </BeneficiarySelectRadioButton>
                        );
                    })}
                </SimpleGrid>
                {!!benefeciariesLinkedTo?.length && (
                    <LinkedToMessage beneficiaries={benefeciariesLinkedTo} />
                )}
            </Stack>
        </FormGroup>
    );
}
