import React, { forwardRef } from 'react';
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { translatePathWithParam, UtilsContext } from 'core';

type Props = React.PropsWithChildren<LinkProps> & {
    ref?: React.Ref<HTMLAnchorElement>;
};

const LinkIntl = forwardRef<HTMLAnchorElement, Props>(
    ({ children, href, ...props }, ref) => {
        const { routes, siteConfig } = React.useContext(UtilsContext);
        const { locale } = useRouter();

        if (!href) {
            throw new Error('No `href` prop provided!');
        }

        if (typeof href === 'object' && !href.pathname) {
            throw new Error('No `pathname` provided in the `href` object!');
        }

        return (
            <NextLink
                {...props}
                ref={ref}
                href={href}
                as={translatePathWithParam(
                    routes,
                    href,
                    locale,
                    siteConfig.homePagePath
                )}>
                {children}
            </NextLink>
        );
    }
);

LinkIntl.displayName = 'LinkIntl';

export default LinkIntl;
