import { Icon, IconProps } from '@chakra-ui/react';

export default function ShieldIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M12 5.346 5.66 7.634a.941.941 0 0 0-.635.94C5.42 14.23 10.483 18.5 12 18.5V5.346z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M13.164 1.905a3 3 0 0 0-2.328 0l-7.5 3.158C2.237 5.526 1.46 6.631 1.549 7.9c.322 4.58 2.189 8.196 4.3 10.67 1.053 1.235 2.178 2.198 3.22 2.86 1.005.638 2.052 1.07 2.931 1.07.88 0 1.926-.432 2.932-1.07 1.041-.662 2.166-1.625 3.22-2.86 2.11-2.474 3.977-6.09 4.3-10.67.088-1.269-.689-2.374-1.788-2.837l-7.5-3.158zm-1.552 1.843a1 1 0 0 1 .776 0l7.5 3.158c.384.162.592.517.568.854-.288 4.1-1.957 7.32-3.826 9.511-.936 1.098-1.911 1.924-2.77 2.47-.894.567-1.549.759-1.86.759-.311 0-.966-.192-1.86-.76-.859-.545-1.834-1.371-2.77-2.468C5.5 15.08 3.832 11.86 3.544 7.76c-.024-.337.183-.692.568-.854l7.5-3.158z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
