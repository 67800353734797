import React from 'react';
import { useRouter } from 'next/router';
import { defineMessages, FormattedMessage } from 'react-intl';
import { VStack, Button, Text, Stack } from '@chakra-ui/react';
import {
    LinkIntl,
    SafeFormattedMessage,
    ErrorIllustration,
    coreSharedMessages,
    FormWorkflow,
    WorkflowTypesEnum,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import {
    getSignatureCancelButtonHref,
    signatureErrorCodeMessages,
    signatureErrorMessages,
    SignatureErrorStep,
} from 'core/lib/signature';

const intlMessages = defineMessages({
    buttonLabelNewDemand: {
        id: 'buttons.make-new-request',
        defaultMessage: 'Refaire une demande',
    },
});

type SignatureErrorProps = {
    errorCode: number;
    errorTranslationKey?: string;
    step: SignatureErrorStep;
    redirectToFormId?: string;
    service?: WorkflowTypesEnum;
    hasButton?: boolean;
};

export default function SignatureError({
    errorCode,
    errorTranslationKey,
    step,
    redirectToFormId,
    service = WorkflowTypesEnum.PA,
    hasButton = true,
}: SignatureErrorProps) {
    const { isMobile } = useWindowBreakpoints();
    const router = useRouter();
    const workflow = router.query?.workflow as FormWorkflow;
    const levelId = router.query?.levelId as string;

    return (
        <Stack mx={isMobile ? 4 : 0} spacing="-4">
            <ErrorIllustration width={300} height={200} p="4">
                <VStack spacing="0" textAlign="center" mt="6" mb="6">
                    {errorTranslationKey &&
                    signatureErrorCodeMessages[errorTranslationKey] ? (
                        <SafeFormattedMessage
                            {...signatureErrorCodeMessages[errorTranslationKey]}
                            debugKey={`SignatureErrorTranslation: ${errorCode}`}
                        />
                    ) : (
                        <>
                            {errorCode &&
                            signatureErrorMessages[step]?.[errorCode] ? (
                                <Text mt="0">
                                    <SafeFormattedMessage
                                        {...signatureErrorMessages[step][
                                            errorCode
                                        ]}
                                        debugKey={`SignatureError: ${errorCode}`}
                                    />
                                </Text>
                            ) : (
                                <Text mt="0">
                                    <FormattedMessage
                                        {...signatureErrorMessages.default}
                                    />
                                </Text>
                            )}
                        </>
                    )}
                </VStack>
            </ErrorIllustration>

            {hasButton && (
                <Button
                    as={LinkIntl}
                    href={
                        levelId && !redirectToFormId
                            ? {
                                  pathname: '/account/products/levels/[id]',
                                  query: {
                                      id: levelId,
                                  },
                              }
                            : redirectToFormId
                              ? {
                                    pathname: '/forms/[formId]',
                                    query: {
                                        formId: redirectToFormId,
                                    },
                                }
                              : getSignatureCancelButtonHref(
                                    workflow,
                                    router.query?.levelId as string,
                                    service
                                )
                    }
                    colorScheme="primary"
                    w={isMobile && 'full'}
                    alignSelf="center">
                    <FormattedMessage
                        {...(redirectToFormId || levelId
                            ? intlMessages.buttonLabelNewDemand
                            : coreSharedMessages.buttonBackHome)}
                    />
                </Button>
            )}
        </Stack>
    );
}
