import { Icon, IconProps } from '@chakra-ui/react';

export default function PieIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M15 2.223a9.013 9.013 0 0 1 6.945 7.779c.06.549-.393.998-.945.998h-6a2 2 0 0 1-2-2V3c0-.552.45-1.006.998-.945.34.037.674.094 1.002.168zm0 2.067A7.015 7.015 0 0 1 19.71 9H15V4.29z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M2 12.5c0-4.56 3.214-8.37 7.5-9.29.328-.07.662-.123 1.002-.158.55-.058.998.396.998.948v6.5a2 2 0 0 0 2 2H20c.552 0 1.006.449.948.998-.035.34-.089.674-.159 1.002-.918 4.286-4.728 7.5-9.289 7.5A9.5 9.5 0 0 1 2 12.5zm16.73 2A7.5 7.5 0 1 1 9.5 5.27v5.23a4 4 0 0 0 4 4h5.23z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
