import NavBarV2 from 'components/Layouts/NavBar/v2';
import NavBarV3 from 'components/Layouts/NavBar/v3';
import { useGetEnvVar } from 'lib/shared/api';
import { useRouter } from 'next/router';

type NavBarProps = {
    backgroundColor?: string;
};

const NAVBARS = {
    2: NavBarV2,
    3: NavBarV3,
};

const NavBar = ({ backgroundColor }: NavBarProps) => {
    const router = useRouter();
    const { data: dashboardVersion } = useGetEnvVar('DASHBOARD_VERSION');
    const menuVersion = (router.query?.version as string) || dashboardVersion;
    const NavBar = NAVBARS[menuVersion] || NAVBARS[2];

    return <NavBar backgroundColor={backgroundColor} />;
};

export default NavBar;
