import React, { KeyboardEvent } from 'react';
import '@reach/skip-nav/styles.css';
import { Site } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import * as icons from 'design-system/icons';
import { useRouter } from 'next/router';
import { MenuItem, MenuItemProps } from './MenuItem';
import { FormattedMessage } from 'react-intl';
import { Flex, Stack, Text } from '@chakra-ui/react';

type MenuLinkProps = Pick<MenuItemProps, 'resetFilters' | 'intlMessages'> & {
    link: Site.MenuLink;
    id: number;
    setCurrentFocus: (idx: number) => void;
    handleKeyDown: (evt: KeyboardEvent<Element>) => void;
    setIsMenuExpanded: (value: boolean) => void;
    isMenuExpanded: boolean;
};

export default function MenuLink({ link, ...props }: MenuLinkProps) {
    const router = useRouter();
    const { isSmallDevice } = useWindowBreakpoints();

    const isActive = new RegExp(link.activeRegex).test(router.pathname);
    const Icon = icons[link.icon];

    function _renderIcon() {
        return (
            <Flex
                bg={isActive ? 'rgba(0,0,0,0.2)' : 'transparent'}
                _hover={{ bg: 'rgba(0,0,0,0.2)' }}
                rounded="full"
                w={isSmallDevice ? '70px' : 12}
                h="12"
                alignItems="center"
                justifyContent="center">
                <Icon color="white" w="6" h="6" />
            </Flex>
        );
    }

    return (
        <MenuItem isActive={isActive} {...props} {...link}>
            <Stack alignItems="center">
                {_renderIcon()}

                {props.isMenuExpanded && (
                    <Text
                        color="white"
                        fontWeight="normal"
                        fontSize="sm"
                        textAlign="center"
                        whiteSpace="pre-wrap">
                        <FormattedMessage
                            {...props.intlMessages[`menu.${link.label}`]}
                        />
                    </Text>
                )}
            </Stack>
        </MenuItem>
    );
}
