import React from 'react';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { LoadingCard, coreErrors } from 'core';
import {
    Worker,
    Viewer,
    SpecialZoomLevel,
    LoadError,
} from '@react-pdf-viewer/core';
import * as Sentry from '@sentry/nextjs';
import { Box } from '@chakra-ui/react';

import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

import { Error } from 'core/components/Layouts/ErrorComponent';
import * as pdfjs from 'pdfjs-dist';

type PDFViewerProps = {
    file: string | Uint8Array;
    onDocumentLoad?: () => any;
    onError?: (error?: LoadError) => any;
    locale?: string;
    isSmallDisplay?: boolean;
};

export function PDFViewer({
    file,
    onDocumentLoad,
    onError,
    locale,
    isSmallDisplay = false,
}: PDFViewerProps) {
    const zoomPluginInstance = zoomPlugin();

    return (
        <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
            <Viewer
                defaultScale={SpecialZoomLevel.PageWidth}
                plugins={[zoomPluginInstance]}
                renderLoader={() => <LoadingCard />}
                fileUrl={file}
                httpHeaders={{
                    'Accept-Language': locale?.replace('-', '_'),
                }}
                onDocumentLoad={onDocumentLoad}
                renderError={(error) => {
                    Sentry.captureException(error?.message || error);
                    if (onError) {
                        onError(error);
                    }
                    return (
                        <Box p={!isSmallDisplay && 4}>
                            <Error
                                message={coreErrors.unavailableContentTitle}
                                description={coreErrors.unavailableContentText}
                                isSmallDisplay={isSmallDisplay}
                            />
                        </Box>
                    );
                }}
            />
        </Worker>
    );
}
