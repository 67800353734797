import React, { useState } from 'react';
import {
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Stack,
} from '@chakra-ui/react';
import { GearIcon, MoreIcon } from 'design-system/icons';
import { useWindowBreakpoints } from 'design-system/hooks';

interface SubAction {
    label: JSX.Element;
    icon?: JSX.Element;
    onClick: () => void;
    isDisabled?: boolean;
}

interface Action {
    label: JSX.Element;
    icon?: JSX.Element;
    onClick: () => void;
    subActions?: SubAction[];
    secondaryButton?: boolean;
    isDisabled?: boolean;
}

interface ActionButtonsProps {
    actions: Action[];
}

const ActionButtons = ({ actions }: ActionButtonsProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { isMobile } = useWindowBreakpoints();

    const subActions = actions
        .filter((action) => action.subActions)
        .map((action) => action.subActions)
        .flat();

    const secondaryActions = actions.filter((action) => action.secondaryButton);

    const handleOnClick = () => {
        actions.length > 1 || actions?.[0]?.subActions?.length > 1
            ? setIsOpen(!isOpen)
            : actions[0]?.onClick();
    };

    return (
        <>
            {!isMobile &&
                secondaryActions.length > 0 &&
                secondaryActions.map((action, index) => (
                    <Button
                        key={index}
                        variant="outline"
                        colorScheme="primary"
                        onClick={action.onClick}
                        rightIcon={<GearIcon fontSize="sm" />}>
                        {action.label}
                    </Button>
                ))}

            <Popover
                isOpen={isOpen}
                onClose={() => setIsOpen(!isOpen)}
                placement={isMobile ? 'top' : 'bottom'}>
                <PopoverTrigger>
                    <Button
                        position={isMobile ? 'fixed' : 'relative'}
                        bottom={isMobile ? '20' : '0'}
                        right={isMobile ? '5' : '0'}
                        zIndex={2}
                        w={isMobile ? '12' : 'auto'}
                        h={isMobile ? '12' : 'auto'}
                        justifySelf="flex-end"
                        alignSelf="center"
                        colorScheme={!isOpen ? 'primary' : 'white'}
                        color={!isOpen ? 'white' : 'primary.main'}
                        onClick={handleOnClick}
                        border="2px solid"
                        borderColor={isOpen ? 'primary' : 'transparent'}
                        borderRadius={isMobile ? 'full' : 'sm'}
                        px={isMobile ? '0' : '2.5'}
                        py={isMobile ? '0' : '1.5'}
                        ml="4"
                        rightIcon={!isMobile && actions[0].icon}>
                        {!isMobile ? (
                            actions[0]?.label
                        ) : actions.length > 1 ? (
                            <MoreIcon />
                        ) : (
                            actions[0]?.icon
                        )}
                    </Button>
                </PopoverTrigger>

                <PopoverContent
                    minW="150px"
                    position="relative"
                    right="9"
                    height="auto"
                    w="fit-content"
                    borderTopRadius="xl"
                    rounded="xl"
                    border="0px solid"
                    borderTop="1px solid"
                    boxShadow="base"
                    overflow="hidden"
                    borderTopColor="strokes.medium">
                    <Stack
                        w="100%"
                        spacing="2"
                        fontWeight="bold"
                        fontSize="sm"
                        alignItems="start"
                        p="3">
                        {(isMobile
                            ? subActions.concat(secondaryActions)
                            : subActions
                        ).map((action, index) => (
                            <Button
                                key={index}
                                onClick={action.onClick}
                                p="0"
                                _hover={{
                                    color: 'primary.main',
                                }}
                                variant="ghost"
                                leftIcon={action.icon}
                                isDisabled={action.isDisabled}>
                                {action.label}
                            </Button>
                        ))}
                    </Stack>
                </PopoverContent>
            </Popover>
        </>
    );
};

export default ActionButtons;
