import { librarian } from 'core/lib/shared/helpers';
import coreFormLibrary from 'core/lib/forms/consts/formLibrary';
import { BeneficiarySelect, MultipleTreatments } from './widgets';

const formLibrary = {
    ...coreFormLibrary,
    beneficiarySelect(props: any) {
        return <BeneficiarySelect {...props} type="default" />;
    },
    beneficiarySelectInfo(props: any) {
        return <BeneficiarySelect {...props} type="bankDetails" />;
    },
    beneficiarySelectTeleconsultation(props: any) {
        return <BeneficiarySelect {...props} type="teleconsultation" />;
    },
    multipleTreatments(props: any) {
        return <MultipleTreatments {...props} />;
    },
};

export const FormLibrarian = librarian(formLibrary);
