import axios from 'axios';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getNextPageParam } from 'core/lib/shared/helpers/getNextPageParam';
import { Refunds } from './types';
import { APIError } from 'core';

export type UseRefunds = {
    refunds?: Refunds[];
    pagination?: Pagination;
    refundsTotalErrors: number;
    refundsErrors: number;
};

type FetchRefundsApi = Api<
    {
        refunds: Refunds[];
        refundsTotalErrors: number;
        refundsErrors: number;
    },
    null,
    { pagination: Pagination }
>;

const path = '/api/proxy/selfcare/health/refunds';

export async function fetchRefunds(path: string) {
    try {
        const res = await axios.get(path);
        const { data, meta }: FetchRefundsApi = res.data;

        return {
            pagination: meta.pagination,
            refundsTotalErrors: data.refundsTotalErrors,
            refundsErrors: data.refundsErrors,
            refunds: data.refunds,
        };
    } catch (error) {
        throw { code: error.response?.status };
    }
}

export function useRefunds(filtersQuery = '') {
    return useInfiniteQuery<UseRefunds, APIError>({
        initialPageParam: 1,
        queryKey: [path, filtersQuery],
        queryFn: ({ pageParam = 1 }) =>
            fetchRefunds(`${path}?start=${pageParam}&count=10${filtersQuery}`),
        getNextPageParam,
    });
}
