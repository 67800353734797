import React from 'react';
import { Stack } from '@chakra-ui/react';
import { Confirmation, useConfirmationValidation } from 'core';
import Fieldset from '../Fieldset';
import InputPassword from './InputPassword';
import { PasswordIndications } from './PasswordIndications';
import { WidgetConfirmationProps } from './types';

type PasswordConfirmationProps = WidgetConfirmationProps & {
    password: Confirmation;
};

export default function PasswordConfirmation({
    confirmation,
    password,
    validations,
    help,
    ...props
}: PasswordConfirmationProps) {
    const { confirmationName, originalName } = useConfirmationValidation(
        props.id
    );
    const ref = React.useRef(null);

    return (
        <Fieldset
            legend={props.title}
            isRequired={validations.required}
            help={help}>
            <Stack>
                <InputPassword
                    hasPattern
                    ref={ref}
                    showErrors={false}
                    id={props.id}
                    name={originalName}
                    data-testid="password"
                    {...password}
                    {...{ validations }}
                />
                <PasswordIndications inputRef={ref} validations={validations} />
                <InputPassword
                    id={`${props.id}Confirmation`}
                    name={confirmationName}
                    data-testid="passwordConfirmation"
                    {...confirmation}
                    validations={{
                        ...(validations.required && {
                            required: validations.required,
                        }),
                        sameAs: originalName,
                    }}
                />
            </Stack>
        </Fieldset>
    );
}
