import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams, UtilsContext } from 'core';
import React from 'react';
import { useCredentialsConfig } from 'lib/auth';
import { buildUrl } from './utilsTermsFooter';

export type UseCGU = {
    cgu: string;
};

export const path = `/api/proxy/[microService]/general-conditions-of-use`;

export async function fetchTerms({
    queryKey: [urn, isLoggedIn, client_id, _, microService],
}: FetchQueryParams) {
    try {
        const parsedUrn = buildUrl({
            urn,
            isLoggedIn: isLoggedIn === 'true',
            client_id,
            microService,
            endpoint: 'general-conditions-of-use',
        });
        const response = await axios.get(parsedUrn);
        return microService === 'primo'
            ? { cgu: response.data.data.text }
            : (response.data.data as UseCGU);
    } catch (error) {
        throw { code: error.response?.status };
    }
}

export function useTerms(isLoggedIn: boolean = false, microService?: string) {
    const {
        siteConfig: { client_id },
    } = React.useContext(UtilsContext);
    const { data: credentialsConfig } = useCredentialsConfig(isLoggedIn);
    const isEnabled =
        isLoggedIn ||
        credentialsConfig?.legals?.generalConditionsOfUse ||
        microService === 'primo';

    return useQuery<UseCGU, APIError>({
        queryKey: [path, isLoggedIn, client_id, isEnabled, microService],
        queryFn: main.fetchTerms,
        enabled: isEnabled,
    });
}

const main = {
    useQuery,
    fetchTerms,
};
