import { Icon, IconProps } from '@chakra-ui/react';

export default function ModalIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M7 4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H7zm0 2v2h13V6H7zm13 3.5H7V16h13V9.5z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                d="M2 18v-4.5a1 1 0 1 1 2 0V18a1 1 0 0 0 1 1h4.5a1 1 0 1 1 0 2H5a3 3 0 0 1-3-3z"
                fill="currentColor"
            />
        </Icon>
    );
}
