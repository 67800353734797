import { Icon, IconProps } from '@chakra-ui/react';

export default function CrossCircle(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M7.7749 16.225C7.94157 16.3917 8.1374 16.475 8.3624 16.475C8.5874 16.475 8.78324 16.3917 8.9499 16.225L11.9999 13.175L15.0749 16.25C15.2249 16.4 15.4166 16.4708 15.6499 16.4625C15.8832 16.4542 16.0749 16.375 16.2249 16.225C16.3916 16.0583 16.4749 15.8625 16.4749 15.6375C16.4749 15.4125 16.3916 15.2167 16.2249 15.05L13.1749 12L16.2499 8.925C16.3999 8.775 16.4707 8.58333 16.4624 8.35C16.4541 8.11667 16.3749 7.925 16.2249 7.775C16.0582 7.60833 15.8624 7.525 15.6374 7.525C15.4124 7.525 15.2166 7.60833 15.0499 7.775L11.9999 10.825L8.9249 7.75C8.7749 7.6 8.58324 7.52917 8.3499 7.5375C8.11657 7.54583 7.9249 7.625 7.7749 7.775C7.60824 7.94167 7.5249 8.1375 7.5249 8.3625C7.5249 8.5875 7.60824 8.78333 7.7749 8.95L10.8249 12L7.7499 15.075C7.5999 15.225 7.52907 15.4167 7.5374 15.65C7.54574 15.8833 7.6249 16.075 7.7749 16.225ZM11.9999 22.35C10.5332 22.35 9.17074 22.0875 7.9124 21.5625C6.65407 21.0375 5.55824 20.3083 4.6249 19.375C3.69157 18.4417 2.9624 17.3458 2.4374 16.0875C1.9124 14.8292 1.6499 13.4667 1.6499 12C1.6499 10.55 1.9124 9.19583 2.4374 7.9375C2.9624 6.67917 3.69157 5.58333 4.6249 4.65C5.55824 3.71667 6.65407 2.97917 7.9124 2.4375C9.17074 1.89583 10.5332 1.625 11.9999 1.625C13.4499 1.625 14.8041 1.89583 16.0624 2.4375C17.3207 2.97917 18.4166 3.71667 19.3499 4.65C20.2832 5.58333 21.0207 6.67917 21.5624 7.9375C22.1041 9.19583 22.3749 10.55 22.3749 12C22.3749 13.4667 22.1041 14.8292 21.5624 16.0875C21.0207 17.3458 20.2832 18.4417 19.3499 19.375C18.4166 20.3083 17.3207 21.0375 16.0624 21.5625C14.8041 22.0875 13.4499 22.35 11.9999 22.35ZM11.9999 20.375C14.2999 20.375 16.2707 19.5583 17.9124 17.925C19.5541 16.2917 20.3749 14.3167 20.3749 12C20.3749 9.7 19.5541 7.72917 17.9124 6.0875C16.2707 4.44583 14.2999 3.625 11.9999 3.625C9.6999 3.625 7.72907 4.44583 6.0874 6.0875C4.44574 7.72917 3.6249 9.7 3.6249 12C3.6249 14.3167 4.44574 16.2917 6.0874 17.925C7.72907 19.5583 9.6999 20.375 11.9999 20.375Z"
                fill="currentColor"
            />
        </Icon>
    );
}
