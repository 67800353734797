import { Icon, IconProps } from '@chakra-ui/react';

export default function PregnancyIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M14 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-2 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM14.134 10.61l.096.497a4.502 4.502 0 0 1 3.523 4.393c0 2.318-2 4.254-4.253 4.5H12v1a1 1 0 1 1-2 0v-1H8.107a1 1 0 0 1-.994-1.102l.828-8.101A2 2 0 0 1 9.931 9h2.242a2 2 0 0 1 1.961 1.61zM9.931 11h2.242l.206 1.257c.07.428.44.743.874.743a2.5 2.5 0 0 1 .102 4.998V18h-4.14l.716-7z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
