import { Icon, IconProps } from '@chakra-ui/react';

export default function PlusIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M11 13v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2h-6V5a1 1 0 1 0-2 0v6H5a1 1 0 1 0 0 2h6z"
                fill="currentColor"
            />
        </Icon>
    );
}
