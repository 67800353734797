import { Icon, IconProps } from '@chakra-ui/react';

export default function EmailIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M21.2962 5.14634C22.1078 3.43927 20.3171 1.66289 18.6166 2.48827L3.46398 9.843C2.00675 10.5503 1.95086 12.6059 3.3675 13.3914L8.06559 15.9962L10.5001 20.4595C11.2823 21.8936 13.3607 21.8359 14.0621 20.3606L21.2962 5.14634ZM9.04404 13.9369L5.01485 11.6773L17.2103 5.7707L9.04404 13.9369ZM10.1037 14.9986L12.2251 18.8876L18.2613 6.84101L10.1037 14.9986Z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
