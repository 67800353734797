import React from 'react';
import axios from 'axios';
import { isArray } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { SignatureFile } from '.';
import { FormWorkflow } from 'core/lib/forms';

const signatureFilePath = `/api/proxy/[service]/signature/[procedureId]/files/[fileId]`;
const legalDocumentsFilePath = `/api/proxy/[service]/files/[fileId]`;

function getFilePath(
    service: string,
    procedureId: string,
    file: SignatureFile,
    workflow: FormWorkflow
) {
    if (workflow === 'legal-documents') {
        return legalDocumentsFilePath
            .replace('[service]', service)
            .replace('[fileId]', file.id);
    }

    return signatureFilePath
        .replace('[service]', service)
        .replace('[procedureId]', procedureId)
        .replace('[fileId]', file.id);
}

export function useMergedDocuments(
    files: SignatureFile[],
    procedureId: string,
    service: string,
    workflow: FormWorkflow
) {
    const [mergePdfData, setMergedPdfData] = React.useState<
        Uint8Array | undefined
    >();
    const [fetchStatus, setFetchStatus] = React.useState<
        'pending' | 'error' | 'success'
    >('pending');
    const [fetchErrorCode, setFetchErrorCode] = React.useState<number>();

    React.useEffect(() => {
        async function fetchAndMergeDocuments() {
            try {
                setFetchStatus('pending');
                const mergedPdf = await PDFDocument.create();
                await Promise.all(
                    files.map(async (file) => {
                        const res = await axios.get(
                            getFilePath(service, procedureId, file, workflow),
                            {
                                responseType: 'arraybuffer',
                            }
                        );
                        const pdfDocument = await PDFDocument.load(res.data);
                        const copiedPages = await mergedPdf.copyPages(
                            pdfDocument,
                            pdfDocument.getPageIndices()
                        );

                        return copiedPages.forEach((page) =>
                            mergedPdf.addPage(page)
                        );
                    })
                );

                const mergedPdfFileData = await mergedPdf.save();
                setMergedPdfData(mergedPdfFileData);
                setFetchStatus('success');
            } catch (error) {
                setFetchErrorCode(error?.response?.status);
                setFetchStatus('error');
                return;
            }
        }

        if (files?.length >= 0) {
            fetchAndMergeDocuments();
        } else if (isArray(files)) {
            setFetchErrorCode(404);
            setFetchStatus('error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files, procedureId, service]);
    return { fetchStatus, fetchErrorCode, mergePdfData };
}
