import { defineMessages } from 'react-intl';

export const menuMessages = defineMessages({
    insurance: {
        id: 'menu.insurance',
        defaultMessage: 'Mon assurance',
    },
    guaranteesServices: {
        id: 'menu.guarantees-services',
        defaultMessage: 'Garanties et services',
    },
    services: {
        id: 'menu.services',
        defaultMessage: 'Services',
    },
});
