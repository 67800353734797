import { Icon, IconProps } from '@chakra-ui/react';

export default function MentalHealthIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 25" {...props}>
            <path
                fillRule="evenodd"
                d="M4.01 10.536A7.173 7.173 0 0 1 4 10.17c0-4.017 3.358-7.272 7.5-7.272 4.142 0 7.5 3.255 7.5 7.272 0 1.936-.78 3.696-2.054 5l1.007 3.612a.967.967 0 0 1-.705 1.189l-7.509 1.966c-.533.14-1.082-.166-1.226-.683l-.337-1.211H5c-.552 0-1-.434-1-.97v-3.998l-1.13-.62a.954.954 0 0 1-.377-1.322l1.516-2.598zM6 14.456v3.649h3.597l.49 1.867 5.674-1.62-.523-1.876c-.347-1.133-.096-2.02 1.048-3.676.492-.844.714-1.66.714-2.63 0-2.908-2.5-5.332-5.5-5.332-1.5 0-2.814.563-3.802 1.48C6.158 7.746 6 10.17 6 10.17v.593l-1.5 2.8 1.5.892z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
