import { FormattedMessage } from 'react-intl';
import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    SystemProps,
} from '@chakra-ui/react';
import { coreSharedMessages } from 'core';

type ErrorAccordionProps = {
    message?: string;
    textAlign?: SystemProps['textAlign'];
};

function ErrorAccordion({ message, textAlign = 'left' }: ErrorAccordionProps) {
    return (
        <Accordion allowToggle border="0">
            <AccordionItem border="0">
                <AccordionButton
                    display="block"
                    rounded="sm"
                    textAlign={textAlign}>
                    <Box fontWeight="bold">
                        <FormattedMessage {...coreSharedMessages.error} />
                    </Box>
                    {message && (
                        <Box>
                            Détails
                            <AccordionIcon />
                        </Box>
                    )}
                </AccordionButton>
                {message && <AccordionPanel>{message}</AccordionPanel>}
            </AccordionItem>
        </Accordion>
    );
}

export default ErrorAccordion;
