import { defineMessage, defineMessages } from 'react-intl';
import { oAuthMessages } from './common';

const intlMessages = defineMessages({
    unauthorized: {
        id: 'errors.login.unauthorized',
        defaultMessage: 'Identifiant ou mot de passe incorrect',
    },
    unavailableService: {
        id: 'errors.login.unavailable-service',
        defaultMessage:
            'Suite à un problème technique, nous ne parvenons pas à vous connecter. Merci de bien vouloir réessayer plus tard.',
    },
});

export const loginMessages = {
    ...defineMessages({
        403: {
            id: 'errors.login.403',
            defaultMessage:
                'Le contrat souscrit par votre employeur ne vous permet pas d’accéder à {appName}. Pour plus d’informations, nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}}.',
        },
        '454d0ee2-627c-11ec-90d6-0242ac120003': {
            id: 'errors.login.429',
            defaultMessage:
                'Votre compte est bloqué pendant 15 minutes. Passé ce délai, vous pourrez à nouveau vous connecter.',
        },
        tooManyTries: {
            id: 'errors.login.too-many-tries',
            defaultMessage:
                'Votre compte est bloqué pendant 60 minutes en raison d’un trop grand nombre de tentatives erronées.',
        },
        'f43f9f79-e04a-42b6-a5e7-734cd3303c12': {
            id: 'errors.login.unauthorized-environment-user-caa-caa',
            defaultMessage:
                "<p><l><b>Accès non autorisé à l'application</b></l><br></br>Votre contrat complémentaire santé prévoit un accès à l'ensemble de vos services au travers de l'application CA \"Ma Santé\", que vous pouvez télécharger sur les stores.</p>",
        },
        '3a8de37d-4f5a-4b00-891f-35bbb1cf9cb1': {
            id: 'errors.login.unavailable-primo',
            defaultMessage:
                '<p><b>Vous devez finaliser votre adhésion en ligne avant de pouvoir accéder à votre espace {appName}. Cependant, nous rencontrons actuellement des difficultés.</b><br></br><br></br>Pour plus d’informations, nous vous invitons à contacter votre centre de gestion.</p>',
        },
        'e53cc958-07a2-4f5e-8089-a99bc6ce8b54': {
            id: 'errors.login.no-linked-companies',
            defaultMessage:
                "Vous n'êtes actuellement rattaché à aucune entreprise.<br></br>Veuillez contacter votre service client.",
        },
        '334d04de-3902-4c42-bdc2-c13abfaef030': {
            id: 'errors.login.no-permission',
            defaultMessage:
                'Vous n’avez actuellement aucune habilitation pour accéder à votre espace.<br></br>Veuillez contacter votre service client.',
        },
        'f5eb3cf7-fa21-4600-82d0-6dd5abd64a70': {
            id: 'errors.login.inactive-account',
            defaultMessage:
                'Votre compte est désactivé.<br></br>Veuillez contacter votre service client.',
        },
    }),
    '896c25ec-76ff-49be-bdf7-0f3b23782c29': intlMessages.unavailableService,
    400: intlMessages.unavailableService,
    401: intlMessages.unauthorized,
    404: intlMessages.unauthorized,
    412: intlMessages.unauthorized,
    429: intlMessages.unavailableService,
    500: intlMessages.unavailableService,
    502: intlMessages.unavailableService,
    503: intlMessages.unavailableService,
    504: intlMessages.unavailableService,
};

export const oAuthLoginMessages = {
    token: {
        title: {
            '400_BAD_REQUEST': oAuthMessages.defaultTitle,
            '400_AZURE_ERROR': oAuthMessages.defaultTitle,
            '401_UNAUTHORIZED_0001': oAuthMessages.defaultTitle,
            '401_UNAUTHORIZED_0002': oAuthMessages.defaultTitle,
            '401_UNAUTHORIZED_0007': oAuthMessages.defaultTitle,
            '401_UNAUTHORIZED_9001': oAuthMessages.defaultTitle,
            '401_UNAUTHORIZED_9002': oAuthMessages.defaultTitle,
            '403_FORBIDDEN_0003': oAuthMessages.defaultTitle,
            '403_FORBIDDEN_0004': oAuthMessages.defaultTitle,
            '500_INTERNAL_SERVER_ERROR': oAuthMessages.defaultTitle,
        },
        description: {
            '400_BAD_REQUEST': defineMessage({
                id: 'components.login.oauth.error.400-bad-request.description',
                defaultMessage:
                    '<p>Merci de bien vouloir réessayer plus tard.</p><p>Pour plus d’informations, nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 024</b></l>',
            }),
            '400_AZURE_ERROR': oAuthMessages['AZURE_ERROR'],
            '401_UNAUTHORIZED_0001': defineMessage({
                id: 'components.login.oauth.error.401-unauthorized-0001.description',
                defaultMessage:
                    '<p>Nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 0001</b></l>',
            }),
            '401_UNAUTHORIZED_0002': defineMessage({
                id: 'components.login.oauth.error.401-unauthorized-0002.description',
                defaultMessage:
                    '<p>Nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 0002</b></l>',
            }),
            '401_UNAUTHORIZED_0007': defineMessage({
                id: 'components.login.oauth.error.401-unauthorized-0007.description',
                defaultMessage:
                    '<p>Nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 0007</b></l>',
            }),
            '401_UNAUTHORIZED_9001': defineMessage({
                id: 'components.login.oauth.error.401-unauthorized-9001.description',
                defaultMessage:
                    '<p>Nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 9001</b></l>',
            }),
            '401_UNAUTHORIZED_9002': defineMessage({
                id: 'components.login.oauth.error.401-unauthorized-9002.description',
                defaultMessage:
                    '<p>Nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 9002</b></l>',
            }),
            '403_FORBIDDEN_0003': defineMessage({
                id: 'components.login.oauth.error.403-forbidden-0003.description',
                defaultMessage:
                    '<p>Nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 0003</b></l>',
            }),
            '403_FORBIDDEN_0004': defineMessage({
                id: 'components.login.oauth.error.403-forbidden-0004.description',
                defaultMessage:
                    '<p>Nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 0004</b></l>',
            }),
            '500_INTERNAL_SERVER_ERROR': defineMessage({
                id: 'components.login.oauth.error.500-internal-server-error.description',
                defaultMessage:
                    '<p>Merci de bien vouloir réessayer plus tard.</p><p>Pour plus d’informations, nous vous invitons à contacter votre centre de gestion au {managementCenterPhoneNumber, select, null {numéro indiqué sur votre carte de tiers payant} other {{managementCenterPhoneNumber}}} en mentionnant le code suivant :</p><l><b>Code 025</b></l>',
            }),
        },
    },
    authorize: {
        title: {
            '400_BAD_REQUEST': oAuthMessages.defaultTitle,
            '404_NOT_FOUND': oAuthMessages.defaultTitle,
            '500_INTERNAL_SERVER_ERROR': oAuthMessages.defaultTitle,
        },
        description: {
            '400_BAD_REQUEST': oAuthMessages['400_BAD_REQUEST'],
            '404_NOT_FOUND': oAuthMessages['404_NOT_FOUND'],
            '500_INTERNAL_SERVER_ERROR':
                oAuthMessages['500_INTERNAL_SERVER_ERROR'],
        },
    },
};
