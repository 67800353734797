import { ArrowLeftIcon, ArrowRightIcon } from 'design-system/icons';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { coreSharedMessages } from 'core';
import { defineMessages, useIntl } from 'react-intl';

const intlMessages = {
    ...defineMessages({
        'prev-button': {
            id: 'components.carousel.button-prev',
            defaultMessage: 'Précédent',
        },
    }),
};

type CarouselButtonsProps = {
    onPrev?: () => void;
    onNext?: () => void;
    isFirstItem: boolean;
    isLastItem: boolean;
};

export function CarouselButtons({
    onPrev,
    onNext,
    isFirstItem,
    isLastItem,
}: CarouselButtonsProps) {
    const { formatMessage } = useIntl();
    return (
        <div data-testid="carouselButtons">
            <button
                type="button"
                aria-label={formatMessage(intlMessages['prev-button'])}
                onClick={onPrev}
                disabled={isFirstItem}>
                <ArrowLeftIcon
                    color={isFirstItem ? 'grey.400' : 'black'}
                    w={21}
                    h={21}
                />
            </button>
            <button
                type="button"
                aria-label={formatMessage(coreSharedMessages.next)}
                onClick={onNext}
                disabled={isLastItem}>
                <ArrowRightIcon
                    color={isLastItem ? 'grey.400' : 'black'}
                    w={21}
                    h={21}
                    ml="3"
                />
            </button>
        </div>
    );
}
