import * as Sentry from '@sentry/nextjs';
import { BaseIri, FormWorkflow } from 'core/lib/forms';
import { WorkflowTypesEnum } from '../../../lib/shared/types/common';

export function getWorkflowBaseIri(
    workflow: FormWorkflow,
    service: WorkflowTypesEnum
): BaseIri {
    switch (workflow) {
        case 'signature-health-subscription':
            return 'selfcare';
        case 'signature-beneficiaries-designation.standard':
        case 'signature-beneficiaries-designation.specific-guided':
        case 'signature-beneficiaries-designation.specific-guided.full-capital':
        case 'signature-beneficiaries-designation.specific-guided.divided-capital':
            return service === WorkflowTypesEnum.PA
                ? 'provident'
                : 'employee-beneficiary-designation';
        case 'signature_exemption':
            return 'employee-exemption';
        case 'legal-documents':
            return 'employee-legal-documents';
        default:
            const errorMessage = `Unexpected workflow in SignaturePreviewForm: ${workflow}`;
            Sentry.captureMessage(errorMessage);
            throw errorMessage;
    }
}
