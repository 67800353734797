import React from 'react';
import { Stack } from '@chakra-ui/react';
import { UrlObject } from 'url';
import { Heading, useBackBreadcrumbWithOnClick } from 'core';
import { Breadcrumbs } from 'design-system/components';
import { useWindowBreakpoints } from 'design-system/hooks';

type Props = React.PropsWithChildren<{
    header: {
        href: string | UrlObject;
        title: string;
    };
    title: string;
    Layout: React.ComponentType<any>;
}>;

export default function ChallengeLayout({
    children,
    title,
    header,
    Layout,
}: Props) {
    const { isMobile } = useWindowBreakpoints();
    const backBreadcrumbWithOnClick = useBackBreadcrumbWithOnClick();

    return (
        <Layout title={title}>
            <Stack p={isMobile ? 0 : 9} spacing="6">
                <Stack as="header" spacing="4">
                    <Breadcrumbs breadcrumbs={[backBreadcrumbWithOnClick]} />
                    <Heading as="h1" textStyle="h2">
                        {header.title}
                    </Heading>
                </Stack>

                {children}
            </Stack>
        </Layout>
    );
}
