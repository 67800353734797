import { defineMessages } from 'react-intl';

export const filterBadges = defineMessages({
    groups: {
        id: 'filters.category.groups',
        defaultMessage: 'Groupes',
    },
    companies: {
        id: 'filters.category.companies',
        defaultMessage: 'Entreprises',
    },
    clauses: {
        id: 'filters.category.clauses',
        defaultMessage: 'Clauses',
    },
    exemptionStatus: {
        id: 'filters.category.status',
        defaultMessage: 'Statut',
    },
    exemptionReasons: {
        id: 'filters.category.exemptionReason',
        defaultMessage: 'Motif de dispense',
    },
    validationType: {
        id: 'filters.category.validationType',
        defaultMessage: 'Type de validation',
    },
    creationDate: {
        id: 'filters.category.creationDate',
        defaultMessage: 'Date de création',
    },
    processDate: {
        id: 'filters.category.processDate',
        defaultMessage: 'Date de traitement',
    },
    expireDate: {
        id: 'filters.category.expirationDate',
        defaultMessage: "Date d'expiration",
    },
    groupName: {
        id: 'filters.category.groupName',
        defaultMessage: 'Nom',
    },
    status: {
        id: 'filters.category.status',
        defaultMessage: 'Statut',
    },
    environment: {
        id: 'filters.category.environment',
        defaultMessage: 'Environnement',
    },
    address: {
        id: 'filters.category.address',
        defaultMessage: 'Adresse',
    },
    country: {
        id: 'filters.category.country',
        defaultMessage: 'Pays',
    },
    companyName: {
        id: 'filters.category.companyName',
        defaultMessage: 'Nom',
    },
    linkedTo: {
        id: 'filters.category.linkedTo',
        defaultMessage: 'Rattaché à',
    },
    providerId: {
        id: 'filters.category.providerId',
        defaultMessage: 'Provider Id',
    },
    firstName: {
        id: 'filters.category.firstName',
        defaultMessage: 'Prénom',
    },
    lastName: {
        id: 'filters.category.lastName',
        defaultMessage: 'Nom',
    },
    roles: {
        id: 'filters.category.roles',
        defaultMessage: 'Rôles',
    },
    email: {
        id: 'filters.category.email',
        defaultMessage: 'E-mail',
    },
    primaryPhone: {
        id: 'filters.category.primary-phone',
        defaultMessage: 'Téléphone principal',
    },
    secondaryPhone: {
        id: 'filters.category.secondary-phone',
        defaultMessage: 'Téléphone secondaire',
    },
    gender: {
        id: 'filters.category.gender',
        defaultMessage: 'Genre',
    },
    search: {
        id: 'filters.search',
        defaultMessage: 'Rechercher',
    },
    environments: {
        id: 'filters.environments.label',
        defaultMessage: 'Environnements de gestion',
    },
    environmentStatus: {
        id: 'filters.environment-status',
        defaultMessage: 'Statut',
    },
    processorType: {
        id: 'filters.processorType',
        defaultMessage: 'Type de gestion',
    },
    healthEnvironments: {
        id: 'filters.environments.health.title',
        defaultMessage: 'Environnement de gestion santé',
    },
    providentEnvironments: {
        id: 'filters.environments.provident.title',
        defaultMessage: 'Environnement de gestion prévoyance',
    },
    'clauses-filters.title': {
        id: 'forms.clauses-filters.title',
        defaultMessage: 'Filtrer',
    },
    'search.clauses-placeholder': {
        id: 'widgets.search.clauses-placeholder',
        defaultMessage: 'Nom de la clause',
    },
    type: {
        id: 'widgets.clauses-type.title',
        defaultMessage: 'Type de clause',
    },
    'common.date.placeholder': {
        id: 'widgets.common.date.placeholder',
        defaultMessage: 'jj/mm/aaaa',
    },
    modificationDate: {
        id: 'widgets.date.modification-date.title',
        defaultMessage: 'Date de modification',
    },
    signatureDate: {
        id: 'widgets.date.signature-date.title',
        defaultMessage: 'Date de signature',
    },
    clauseType: {
        id: 'widgets.designation-type.title',
        defaultMessage: 'Type de désignation',
    },
    employeeStatus: {
        id: 'widgets.legal-documents.employee-status.title',
        defaultMessage: 'État',
    },
    lastSentDate: {
        id: 'widgets.date.last-sent.title',
        defaultMessage: 'Date du dernier envoi',
    },
});
