import { Icon, IconProps } from '@chakra-ui/react';

export default function ChartsIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M15 7a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0V9.505l-4.2 4.42a2 2 0 0 1-2.9 0l-2.348-2.47-6.138 6.504a1 1 0 0 1-1.455-1.373l6.139-6.504a2 2 0 0 1 2.904-.005l2.348 2.471L18.67 8H16a1 1 0 0 1-1-1z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
