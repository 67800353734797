import { useCallback, useEffect, useState } from 'react';
import { CallbackRef, RefNode } from './types';

type FocusableElementsObject = {
    /**
     * The first focusable element found inside the given node
     * @defaultValue `null`
     */
    first: HTMLElement | null;

    /**
     * The last focusable element found inside the given node
     * @defaultValue `null`
     */
    last: HTMLElement | null;
};

const DEFAULT_FOCUSABLE_ELEMENTS = { first: null, last: null };
const FOCUSABLE_ELEMENT_SELECTORS =
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';

function getFocusableElements(ref: RefNode): FocusableElementsObject {
    const focusableElements = ref?.querySelectorAll(
        FOCUSABLE_ELEMENT_SELECTORS
    );

    if (!focusableElements || focusableElements.length === 0) {
        return DEFAULT_FOCUSABLE_ELEMENTS;
    }

    return {
        first: focusableElements[0] as HTMLElement,
        last: focusableElements[focusableElements.length - 1] as HTMLElement,
    };
}

/**
 * Trap focus within a DOM node.
 *
 * Returns a `ref` callback and an `object` of the first and last elements
 * focusable found in the node
 */
function useFocusTrap(): [CallbackRef, FocusableElementsObject] {
    const [nodeRef, setNodeRef] = useState<RefNode>(null);
    const [focusableElements, setFocusableElements] =
        useState<FocusableElementsObject>(DEFAULT_FOCUSABLE_ELEMENTS);
    const ref = useCallback((node: RefNode) => {
        setNodeRef(node);
    }, []);

    useEffect(() => {
        if (!nodeRef) return;

        const { first, last } = getFocusableElements(nodeRef);
        setFocusableElements({ first, last });

        if (first) first.focus();

        function handleFocusTrap(evt: KeyboardEvent): void {
            if (evt.key === 'Home') first?.focus();
            if (evt.key === 'End') last?.focus();
            if (evt.key !== 'Tab') return;

            const isFirstFocusableElement =
                evt.shiftKey &&
                (document.activeElement === first ||
                    document.activeElement === nodeRef);
            const isLastFocusabledElement =
                !evt.shiftKey && document.activeElement === last;

            if (isFirstFocusableElement) {
                evt.preventDefault();
                last?.focus();
            } else if (isLastFocusabledElement) {
                evt.preventDefault();
                first?.focus();
            }
        }

        document.addEventListener('keydown', handleFocusTrap, true);
        return (): void => {
            document.removeEventListener('keydown', handleFocusTrap, true);
        };
    }, [nodeRef]);

    return [ref, focusableElements];
}

export default useFocusTrap;
