import { Icon, IconProps } from '@chakra-ui/react';

export default function WarningIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                d="M12 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM13 9a1 1 0 1 0-2 0v3a1 1 0 1 0 2 0V9z"
                fill="currentColor"
            />
        </Icon>
    );
}
