import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams, WorkflowTypesEnum } from 'core';
import { serviceTypes, SignatureCapitalBeneficiary } from '../../types';

export const signatureCapitalPath = `/api/proxy/[service]/beneficiaries-designation/[type]/[procedureId]/beneficiaries`;

async function fetchSignatureCapitalBeneficiaries({
    queryKey: [urn, service, procedureId, type],
}: FetchQueryParams) {
    try {
        const response = await axios.get(
            urn
                .replace('[service]', serviceTypes[service])
                .replace('[procedureId]', procedureId)
                .replace('[type]', type)
        );

        const { data, meta } = response.data;

        return {
            beneficiaries:
                data?.dividedCapitalBeneficiaries ||
                data?.fullCapitalBeneficiaries,
            ...meta,
        };
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useSignatureCapitalBeneficiaries(
    service: WorkflowTypesEnum,
    procedureId: string,
    type: 'full-capital' | 'divided-capital'
) {
    return useQuery<
        {
            beneficiaries: SignatureCapitalBeneficiary[];
            beneficiariesMaxNumber?: number;
        },
        APIError
    >({
        queryKey: [signatureCapitalPath, service, procedureId, type],
        queryFn: fetchSignatureCapitalBeneficiaries,
    });
}
