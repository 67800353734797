import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { FormObject, FetchQueryParams, APIError } from 'core';

const paths = {
    password: '/api/proxy/security/public/user/password/request/[cardType]',
    guestPassword:
        '/api/proxy/security/public/user/beneficiary-accounts/password/request',
    username: '/api/proxy/security/public/user/login/lost',
};

export async function fetchLostCredentialsForm({
    queryKey: [urn, selectedCard],
}: FetchQueryParams) {
    try {
        const response = await axios.options(
            urn.replace('[cardType]', selectedCard)
        );
        return response.data.data as FormObject;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useLostCredentialsForm(
    selectedCard: 'basic' | 'advanced',
    resetType: 'password' | 'username' | 'guestPassword'
) {
    return useQuery<FormObject, APIError>({
        queryKey: [paths[resetType], selectedCard],
        queryFn: fetchLostCredentialsForm,
    });
}
