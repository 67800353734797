import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import {
    satisfactionMessages,
    SATISFACTION_SESSION_KEY,
    useSatisfaction,
} from 'lib/satisfaction';
import { SafeFormattedMessage, formMessages } from 'core';
import { FormattedMessage } from 'react-intl';
import { SatisfactionForm } from './SatisfactionForm';
import { SatisfactionEnd } from './SatisfactionEnd';
import { siteConfig } from 'lib/site';
import {
    CoreModal,
    CoreModalBody,
    CoreModalContent,
    CoreModalHeader,
} from 'design-system/components';
import { useWindowBreakpoints } from 'design-system/hooks';

function useSatisfactionModal() {
    const { data, step } = useSatisfaction();
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClose = () => {
        window.sessionStorage.setItem(SATISFACTION_SESSION_KEY, 'true');
        setIsOpen(false);
    };

    React.useEffect(() => {
        if (step !== 'rating') return;

        setIsOpen(!!data);
    }, [data, step]);

    return {
        handleClose,
        isOpen,
    };
}

function SatisfactionModalHeader() {
    const { step, data } = useSatisfaction();
    const { appName } = siteConfig;

    if (!data) return null;

    return (
        <>
            <CoreModalHeader
                bg="primary.main"
                py="4"
                mr="0"
                borderBottom="0px"
                colorCloseButton="white">
                {step === 'rating' || step === 'comment.form' ? (
                    <Text fontSize="xl" fontFamily="body">
                        <SafeFormattedMessage
                            {...formMessages[data.title]}
                            debugKey={`formMessages[${data.title}]`}
                            values={{ appName }}
                        />
                    </Text>
                ) : (
                    <Text fontSize="xl" fontFamily="body">
                        <FormattedMessage
                            {...satisfactionMessages[step].title}
                        />
                    </Text>
                )}
            </CoreModalHeader>
        </>
    );
}

function SatisfactionModalBody() {
    const { step, data } = useSatisfaction();

    return (
        <CoreModalBody pt="2">
            <Stack spacing="6">
                {(step === 'store' || step === 'comment.end') && (
                    <SatisfactionEnd />
                )}

                {(step === 'rating' || step === 'comment.form') && (
                    <SatisfactionForm {...data} />
                )}
            </Stack>
        </CoreModalBody>
    );
}

export function SatisfactionModal() {
    const { isMobile } = useWindowBreakpoints();
    const { isOpen, handleClose } = useSatisfactionModal();

    return (
        <CoreModal isOpen={isOpen} onClose={handleClose} isCentered={false}>
            <CoreModalContent
                zIndex="tooltip"
                pos="fixed"
                bottom={isMobile ? '0' : '4'}
                bg="primary.main"
                m="0"
                color="white"
                maxW="600px"
                minW="initial">
                <SatisfactionModalHeader />
                <SatisfactionModalBody />
            </CoreModalContent>
        </CoreModal>
    );
}
