import React from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { FetchQueryParams, RelatedActionsCategories } from 'core';
import { UserContext } from '../contexts';

const path = `/api/proxy/selfcare/related-actions`;

async function fetchRelatedActions({
    queryKey: [urn, mainSubscription],
}: FetchQueryParams) {
    try {
        if (mainSubscription !== 'health') return null;

        const response = await axios.get(urn);
        const { data }: Api<RelatedActionsCategories[]> = response.data;

        if (!data || !data.length) return null;
        return data;
    } catch (error) {
        throw new Error(error);
    }
}

function useRelatedActions(id: string): RelatedActionsCategories;
function useRelatedActions(id: string[]): RelatedActionsCategories[];
function useRelatedActions(id: string | string[]) {
    const { userInfos } = React.useContext(UserContext);

    const query = useQuery<RelatedActionsCategories[] | null, Error>({
        queryKey: [path, userInfos?.mainSubscription],
        queryFn: main.fetchRelatedActions,
    });

    if (!query.data) return null;

    if (Array.isArray(id)) {
        return id.map((actionId) =>
            query.data.find((action) => action.item === actionId)
        );
    }

    return query.data.find((action) => action.item === id);
}

const main = {
    useRelatedActions,
    fetchRelatedActions,
};

export { path as relatedActionsPath, fetchRelatedActions, useRelatedActions };
export default main;
