import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';

const path = `/api/proxy/europ-assistance/teleconsultations/specialities`;

export type Specialist = {
    id: string;
    name: string;
    warningMessage: string | null;
};

async function fetchTeleconsultationSpecialities({
    queryKey: [urn],
}: FetchQueryParams) {
    try {
        const response = await axios.get(urn);
        return response.data.data;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

type UseTeleconsultationSpecialitiesProps = {
    enabled: boolean;
};

export function useTeleconsultationSpecialities(
    options?: UseTeleconsultationSpecialitiesProps
) {
    return useQuery<Specialist[], APIError>({
        queryKey: [path],
        queryFn: fetchTeleconsultationSpecialities,
        enabled: options?.enabled ?? true,
    });
}
