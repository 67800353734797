import { Icon, IconProps } from '@chakra-ui/react';

export default function LightbulbIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M12.004 6C8.337 6 6.7 8.78 6.7 11.304c0 1.231.68 2.494 1.245 3.423.586.963 1.07 1.572 1.07 2.806v1.451c0 1.264.656 3.016 2.989 3.016 2.332 0 2.966-1.752 2.966-3.016v-1.45c0-1.235.455-1.843 1.04-2.807.566-.929 1.235-2.192 1.235-3.423C17.245 8.781 15.427 6 12.004 6zm1.526 12.984c0 .841-.685 1.526-1.526 1.526a1.528 1.528 0 0 1-1.526-1.526v-.62h3.052v.62zm1.25-4.955c-.56.92-1.136 1.625-1.236 2.88h-3.08c-.1-1.255-.676-1.96-1.236-2.88-.534-.878-1.038-1.707-1.038-2.725a3.818 3.818 0 0 1 3.814-3.814 3.818 3.818 0 0 1 3.814 3.814c0 1.018-.505 1.847-1.038 2.725zM22 11.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0 0 1h2a.5.5 0 0 0 .5-.5zM19.641 18.494a.5.5 0 0 0 .008-.708l-1.4-1.429a.5.5 0 0 0-.714.7l1.4 1.429a.5.5 0 0 0 .706.008zM19.496 4.358a.5.5 0 0 0-.707-.009l-1.431 1.397a.5.5 0 0 0 .698.716l1.432-1.397a.5.5 0 0 0 .008-.707zM12 2a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 1 0v-2A.5.5 0 0 0 12 2zM4.355 4.352a.5.5 0 0 0-.003.707L5.76 6.48a.5.5 0 0 0 .71-.703l-1.408-1.42a.5.5 0 0 0-.707-.004zM2 11.5a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 0-1h-2a.5.5 0 0 0-.5.5zM4.35 18.454a.5.5 0 0 0 .707.008l1.43-1.397a.5.5 0 0 0-.699-.716l-1.43 1.398a.5.5 0 0 0-.009.707z"
                fill="currentColor"
            />
        </Icon>
    );
}
