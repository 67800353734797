import {
    Legend,
    LegendIndicator,
    LegendLabel,
    LegendRow,
    LegendValue,
} from '../Legend';
import {
    ArrowIndicator,
    setArrowIndicatorStateByTrend,
} from '../ArrowIndicator';
import { useDoughnutContext } from './Doughnut';

export function DoughnutLegend() {
    const { data, selected, setSelected } = useDoughnutContext();

    function handleClick(idx: number): void {
        if (selected === idx) setSelected?.(null);
        else setSelected?.(idx);
    }

    return (
        <Legend>
            {data?.map(({ name, value, trend, color }, idx) => (
                <LegendRow
                    key={name}
                    color={color}
                    selected={selected === idx}
                    onClick={() => handleClick(idx)}
                    bordered={idx !== data.length - 1}>
                    <LegendIndicator />
                    <LegendLabel>{name}</LegendLabel>
                    <LegendValue>{value}</LegendValue>
                    {trend !== undefined && (
                        <ArrowIndicator
                            size="normal"
                            state={setArrowIndicatorStateByTrend(trend)}
                        />
                    )}
                </LegendRow>
            ))}
        </Legend>
    );
}
