import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';
import { Teleconsultation } from './useTeleconsultationsList';

export const teleconsultationDetailsPath = `/api/proxy/europ-assistance/teleconsultations/[id]`;

async function fetchTeleconsultationsDetails({
    queryKey: [urn, id],
}: FetchQueryParams) {
    try {
        const response = await axios.get(urn.replace('[id]', id));
        return response.data?.data as Teleconsultation;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useTeleconsultationsDetails(
    id: string,
    enabled: boolean = true
) {
    return useQuery<Teleconsultation, APIError>({
        queryKey: [teleconsultationDetailsPath, id],
        queryFn: fetchTeleconsultationsDetails,
        enabled: enabled && !!id,
    });
}
