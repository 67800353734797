import { Icon, IconProps } from '@chakra-ui/react';

export default function InfoFlashIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M21.4379 3.94588C21.3234 3.90771 21.2322 3.81987 21.1898 3.70685L20.1745 0.998894C20.0447 0.652806 19.5552 0.652805 19.4254 0.998893L18.41 3.70685C18.3677 3.81987 18.2765 3.90771 18.162 3.94588L16.7382 4.42053C16.3735 4.5421 16.3735 5.0579 16.7382 5.17947L18.162 5.65412C18.2765 5.69229 18.3677 5.78013 18.41 5.89315L19.4254 8.60111C19.5552 8.94719 20.0447 8.94719 20.1745 8.60111L21.1898 5.89315C21.2322 5.78013 21.3234 5.69229 21.4379 5.65412L22.8617 5.17947C23.2264 5.0579 23.2264 4.5421 22.8617 4.42053L21.4379 3.94588ZM19.0378 18.3459C18.9233 18.3077 18.8322 18.2199 18.7898 18.1068L17.7744 15.3989C17.6447 15.0528 17.1551 15.0528 17.0254 15.3989L16.01 18.1068C15.9676 18.2199 15.8765 18.3077 15.762 18.3459L14.3382 18.8205C13.9735 18.9421 13.9735 19.4579 14.3382 19.5795L15.762 20.0541C15.8765 20.0923 15.9676 20.1801 16.01 20.2932L17.0254 23.0011C17.1551 23.3472 17.6447 23.3472 17.7744 23.0011L18.7898 20.2932C18.8322 20.1801 18.9233 20.0923 19.0378 20.0541L20.4617 19.5795C20.8263 19.4579 20.8263 18.9421 20.4617 18.8205L19.0378 18.3459ZM10.3356 9.04472C10.219 9.00584 10.1267 8.91551 10.0854 8.79975L8.17682 3.45387C8.05044 3.09986 7.54979 3.09986 7.4234 3.45387L5.51485 8.79975C5.47352 8.91551 5.38126 9.00584 5.26466 9.04472L1.13814 10.4205C0.773495 10.5421 0.773494 11.0579 1.13814 11.1795L5.26467 12.5553C5.38127 12.5942 5.47352 12.6845 5.51486 12.8002L7.42341 18.1451C7.54981 18.499 8.05042 18.499 8.17682 18.1451L10.0854 12.8002C10.1267 12.6845 10.219 12.5942 10.3356 12.5553L14.4621 11.1795C14.8267 11.0579 14.8267 10.5421 14.4621 10.4205L10.3356 9.04472Z"
                fill="currentColor"
            />
        </Icon>
    );
}
