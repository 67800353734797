import { type FormValues } from 'core';

function transformInternationalPhoneNumber(inputId: string, data: FormValues) {
    return {
        id: inputId,
        ...JSON.parse(data.value),
    };
}

export default transformInternationalPhoneNumber;
