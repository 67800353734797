import { HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { formats } from '../../lib';

type TileDateProps = {
    label: string;
    value: string | null;
};

function TileDate({ label, value }: TileDateProps) {
    const { formatDate } = useIntl();

    return (
        <HStack>
            <Text fontSize="sm" color="texts.medium">
                {label}
            </Text>
            <Text fontWeight="medium">
                {value ? formatDate(new Date(value), formats.date) : '-'}
            </Text>
        </HStack>
    );
}

export default TileDate;
