import React from 'react';
import { Box, BoxProps, Flex, Text, VStack } from '@chakra-ui/react';
import { DownloadIcon, PlusIcon } from 'design-system/icons';

type Props = BoxProps & {
    onClick?: () => void;
    isDisabled: boolean;
    isDragActive?: boolean;
};

type TitleProps = React.PropsWithChildren<{
    description?: string;
    disabledMessage?: string;
    isDisabled?: boolean;
}>;

type IconProps = {
    isDisabled?: boolean;
    isDragActive?: boolean;
};

export function FormInputCardButtonTitle({
    children,
    description,
    disabledMessage,
    isDisabled = false,
}: TitleProps) {
    return (
        <VStack spacing={1}>
            {!isDisabled && <Text fontWeight="bold">{children}</Text>}

            {isDisabled && disabledMessage && <Text>{disabledMessage}</Text>}

            {!isDisabled && description && (
                <Text color="grey.800" fontSize="xs">
                    {description}
                </Text>
            )}
        </VStack>
    );
}

export function FormInputCardButtonIcon({
    isDisabled,
    isDragActive,
}: IconProps) {
    const size = 5;

    return (
        <Flex
            display="inline-flex"
            justifyContent="center"
            alignItems="center"
            bg={isDisabled ? 'grey.400' : 'primary.main'}
            rounded="rounded"
            color="white"
            w="36px"
            h="36px"
            mb="2">
            {isDragActive && !isDisabled ? (
                <DownloadIcon w={size} h={size} />
            ) : (
                <PlusIcon w={size} h={size} />
            )}
        </Flex>
    );
}

export function FormInputCardButton({
    children,
    onClick,
    isDisabled = false,
    isDragActive = false,
    ...boxProps
}: Props) {
    return (
        <Box
            as="button"
            type="button"
            disabled={isDisabled}
            cursor={isDisabled && 'unset'}
            onClick={onClick}
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="100%"
            minH="155px"
            rounded="md"
            p="3"
            textAlign="center"
            fontSize="sm"
            lineHeight="1.2"
            transition="ease-in 0.15s all"
            bg={isDisabled ? 'grey.100' : isDragActive ? 'primary.50' : 'white'}
            borderColor={
                isDisabled
                    ? 'strokes.dark'
                    : isDragActive
                    ? 'primary.main'
                    : 'strokes.medium'
            }
            borderStyle={isDragActive ? 'dashed' : 'solid'}
            borderWidth="1px"
            _hover={
                !isDisabled && {
                    bg: 'grey.50',
                }
            }
            _focus={
                !isDisabled && {
                    bg: 'grey.50',
                }
            }
            {...boxProps}>
            <Box>
                {React.Children.map(children, (child) =>
                    React.cloneElement(child as any, {
                        isDisabled,
                        isDragActive,
                    })
                )}
            </Box>
        </Box>
    );
}
