import { defineMessages } from 'react-intl';

export const refundsExportMessages = defineMessages({
    exportModaleTitle: {
        id: 'refunds.export-modale.title',
        defaultMessage:
            "{refundCount, select, 1 {Export d'un remboursement} other {Export de {refundCount} remboursements}}",
    },
    exportModaleDescription: {
        id: 'refunds.export-modale.description',
        defaultMessage: `{filterCount, select,
                0 {Vous allez exporter la totalité de vos remboursements.<br></br>Vous avez la possibilité d'appliquer des filtres en revenant à l'écran précédent.}
                1 {Vous avez actuellement <b>un filtre actif</b>.<br></br>Vous pouvez modifier les filtres en revenant à l'écran précédent.}
                other {Vous avez actuellement <b>{filterCount} filtres actifs</b>.<br></br>Vous pouvez modifier les filtres en revenant à l'écran précédent.}
            }`,
    },
});
