import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { FetchQueryParams, FormObject } from 'core';

const path = `/api/proxy/security/user/beneficiary-accounts/[identifier]`;

async function fetchBeneficiaryAccountForm({
    queryKey: [urn, identifier],
}: FetchQueryParams) {
    try {
        const response = await axios.options(
            urn.replace('[identifier]', identifier)
        );
        return response.data.data as FormObject;
    } catch (error) {
        throw { code: error?.response?.status };
    }
}

export function useBeneficiaryAccountForm(identifier?: string) {
    return useQuery<FormObject, Error>({
        queryKey: [path, identifier],
        queryFn: fetchBeneficiaryAccountForm,
    });
}

export { path as formsPath };
