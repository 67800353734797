import { Icon, IconProps } from '@chakra-ui/react';

export default function DashboardIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M14.519 10.861a.927.927 0 0 0-1.535-.676c-1.12.945-2.538 2.222-2.82 2.863-.48 1.09.059 2.384 1.204 2.888s2.463.029 2.943-1.062c.283-.64.268-2.548.208-4.013zm-1.58 3.409c-.12.27-.515.492-.966.293-.452-.198-.555-.64-.436-.91l.007-.01c.033-.046.183-.253.556-.633.277-.281.606-.59.952-.9.006.465 0 .917-.02 1.31-.028.532-.08.783-.091.838l-.003.012z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M3.71 19.593c.176.26.475.407.79.407h15a.947.947 0 0 0 .79-.407A9.953 9.953 0 0 0 22 14C22 8.477 17.523 4 12 4S2 8.477 2 14c0 2.072.63 3.997 1.71 5.593zM4 14A8 8 0 0 1 12 6a8 8 0 0 1 8 8 7.96 7.96 0 0 1-.8 3.491.931.931 0 0 1-.847.509H5.647a.931.931 0 0 1-.847-.509A7.961 7.961 0 0 1 4 14z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
