import { BaseIri } from 'core/lib/forms';
import { PEWorkflowTypesEnum } from 'core';

export function getTunnelTypeBaseIri(tunnelType: PEWorkflowTypesEnum): BaseIri {
    switch (tunnelType) {
        case PEWorkflowTypesEnum.EXEMPTIONS:
            return 'employee-exemption';
        case PEWorkflowTypesEnum.BD:
            return 'employee-beneficiary-designation';
        case PEWorkflowTypesEnum.DOCUMENTS:
            return 'employee-legal-documents';
        default:
            return 'employee-exemption';
    }
}
