import { withErrorBoundary } from '@sentry/nextjs';
import { librarian } from 'core/lib/shared/helpers/librarian';
import { BlockError } from 'core/components/errors/BlockError';
import { ContentTypes } from './types';
import { ImageViewer, PDFViewer } from './viewers';

type Library = {
    [key in Partial<ContentTypes>]: (fileUrl: {
        file: string;
        data: any;
        intlMessage: string;
        locale?: string;
    }) => React.ReactElement;
};

const library: Partial<Library> = {
    'image/png': ImageViewer,
    'image/jpeg': ImageViewer,
    'application/pdf': PDFViewer,
};

const ContentTypesLibrarian = librarian(library);

export default withErrorBoundary(ContentTypesLibrarian, {
    fallback: BlockError,
});
