import { marked, MarkedOptions, Renderer } from 'marked';
import { Box, BoxProps } from '@chakra-ui/react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { purifyHTML } from 'core';

export type MarkdownProps = BoxProps & {
    content: string;
    options?: MarkedOptions;
};

function sanitizeMarkdown(markdown: string) {
    const sanitizedMarkdown = markdown
        .replace(/<(br)?\s?\/?>/g, '')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/\s#/g, '\n#');

    return sanitizedMarkdown;
}

function generateMarkdown(markdown: string, options: MarkedOptions = {}) {
    const renderer = new Renderer();

    renderer.image = (href, title, text) => {
        if (title) {
            return `<figure><img src=${href} alt="${text}" /><figcaption>${title}</figcaption></figure>`;
        }

        return `<img src=${href} alt="${text}" />`;
    };

    renderer.link = (href, _, text) =>
        `<a href=${href} rel="noopener noreferrer" target="_blank">${text}</a>`;

    return purifyHTML(
        marked(sanitizeMarkdown(markdown), {
            renderer,
            breaks: true,
            ...options,
        }) as string,
        {
            ADD_ATTR: ['target'],
        }
    );
}

function Markdown({
    content,
    options = {},
    className = '',
    ...boxProps
}: MarkdownProps) {
    return (
        <Box
            className={`contents ${className}`}
            dangerouslySetInnerHTML={{
                __html: generateMarkdown(content, options),
            }}
            {...boxProps}
        />
    );
}

export { generateMarkdown, Markdown };
