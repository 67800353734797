import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { HealthCareAppointment, TimelineStatusEnum } from '../types';
import { healthCareAppointmentsNotificationsPath } from './useHealthCareAppointmentsNotifications';

export const healthCareAppointmentsPath = `/api/proxy/healthcare-access/appointments`;

async function fetchHealthCareAppointments({
    queryKey: [urn, identifier, timelineStatus],
}: FetchQueryParams) {
    try {
        const response = await axios.get(urn, {
            params: {
                timelineStatus,
                ...(identifier && { beneficiaryIdentifier: identifier }),
            },
        });
        return response?.data?.data;
    } catch (error) {
        throw { code: error?.response?.status };
    }
}

export function useHealthCareAppointments(
    identifier: string,
    timelineStatus: TimelineStatusEnum[]
) {
    const queryClient = useQueryClient();

    queryClient.invalidateQueries({
        queryKey: [healthCareAppointmentsNotificationsPath],
        exact: false,
    });

    return useQuery<HealthCareAppointment[], APIError>({
        queryKey: [healthCareAppointmentsPath, identifier, timelineStatus],
        queryFn: fetchHealthCareAppointments,
    });
}
