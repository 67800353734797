import { ArrowLeftIcon } from 'design-system/icons';
import { coreErrors, coreSharedMessages } from '../i18n';
import { useRouterBack } from 'core/hooks';

export const backBreadcrumb = {
    icon: ArrowLeftIcon,
    label: coreSharedMessages.back,
};

export function useBackBreadcrumbWithOnClick() {
    const routerBack = useRouterBack();

    return {
        ...backBreadcrumb,
        onClick: routerBack,
    };
}

export const errorToastsMessages = {
    401: coreErrors.sessionExpired,
    400: coreErrors.serviceUnavailable,
    403: coreErrors.serviceNotFound,
    404: coreErrors.serviceNotFound,
    405: coreErrors.serviceNotFound,
    500: coreErrors.serviceUnavailable,
    503: coreErrors.serviceUnavailable,
};
