import FileSaver from 'file-saver';
import { AxiosResponse } from 'axios';

export const downloadFromResponse = (response: AxiosResponse) => {
    const blob = new Blob([response?.data]);
    const fileName = (
        response.headers['content-disposition'] ||
        response.headers['content-type']
    )
        ?.split('filename=')?.[1]
        ?.split(';')?.[0];
    FileSaver.saveAs(blob, fileName);
};
