import {
    FileFileIcon,
    QrCodeIcon,
    ShieldIcon,
    PersonIcon,
    FolderIcon,
    EuroIcon,
} from 'design-system/icons';

export const contractLinks = {
    tpCard: {
        icon: QrCodeIcon,
        path: {
            pathname: '/account/tp-card',
        },
    },
    healthProducts: {
        icon: FileFileIcon,
        path: {
            pathname: '/account/products/[contractType]/[id]',
        },
    },
    providentProducts: {
        icon: FileFileIcon,
        path: {
            pathname: '/account/products/[contractType]/[id]',
        },
    },
    casualityProducts: {
        icon: FileFileIcon,
        path: {
            pathname: '/account/products/[contractType]/[id]',
        },
    },
    guarantees: {
        icon: ShieldIcon,
        path: {
            pathname: '/account/guarantees/families',
        },
    },
    beneficiaries: {
        icon: PersonIcon,
        path: {
            pathname: '/account/beneficiaries',
        },
    },
    contributions: {
        icon: EuroIcon,
        path: {
            pathname: '/account/contributions',
        },
    },
    documents: {
        icon: FolderIcon,
        path: {
            pathname: '/account/documents',
        },
    },
    risks: {
        icon: FileFileIcon,
        path: {
            pathname: '/account/risks',
        },
    },
    providentDocuments: {
        icon: FolderIcon,
        path: {
            pathname: '/account/documents',
            query: {
                category: 'provident-guarantees',
            },
        },
    },
    beneficiariesDesignation: {
        icon: ShieldIcon,
        path: {
            pathname: '/account/beneficiaries-designation',
        },
    },
};
