import { Icon, IconProps } from '@chakra-ui/react';

export default function CameraIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M15.5 13a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0zM14 13a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M10.292 4a3.586 3.586 0 0 0-3.091 1.768 1.586 1.586 0 0 1-1.368.783h-.09A3.743 3.743 0 0 0 2 10.294v5.916a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4v-5.935a3.724 3.724 0 0 0-3.724-3.724h-.382c-.451 0-.86-.267-1.041-.68A3.138 3.138 0 0 0 13.98 4h-3.689zM8.924 6.782A1.586 1.586 0 0 1 10.292 6h3.69c.45 0 .859.266 1.04.679a3.138 3.138 0 0 0 2.872 1.872h.382c.952 0 1.724.772 1.724 1.724v5.935a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-5.916c0-.963.78-1.743 1.743-1.743h.09a3.586 3.586 0 0 0 3.091-1.769z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
