import { WidgetKeys } from 'core';

export const TELECONSULTATION_DATA_STORAGE_KEY = 'form/teleconsultation';

export enum PreferedCanalEnumType {
    PHONE = 'Phone',
    VIDEO = 'Video',
    WRITTEN = 'Written',
    HOME = 'Home',
}

export const PREFERRED_CANAL_VALUES_ORDERED_INDEX_RADIO_ID = [
    PreferedCanalEnumType.PHONE,
    PreferedCanalEnumType.VIDEO,
    PreferedCanalEnumType.WRITTEN,
];

export type FormObject = {
    widgets: WidgetKeys[];
};

export type ConsultationFormType =
    | 'location'
    | 'beneficiary'
    | 'specialities'
    | 'reason'
    | 'communication'
    | 'contacts';

export const FRANCE_OPTION_ID = '1';
export const OUTSIDE_FRANCE_OPTION_ID = '2';
