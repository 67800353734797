import { Icon, IconProps } from '@chakra-ui/react';

export default function MedicalToolsIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M3 3c0-.552.455-1.013.99-.877A4.001 4.001 0 0 1 7 6v2a1 1 0 0 1-1 1H5v2a2 2 0 0 1 2 2v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3zm1.5 4.5V4c.607.456 1 1.182 1 2v1.5h-1zm.5 5h-.5v8h1V13a.5.5 0 0 0-.5-.5zM12.528 9.433l-2.01-6.213a.75.75 0 1 1 1.426-.462l2.011 6.213c.104.32.33.583.63.733l.165.082.164-.082c.3-.15.527-.414.63-.733l2.011-6.213a.75.75 0 1 1 1.428.462l-2.011 6.213A2.75 2.75 0 0 1 15.84 10.9l1.258 6.53a2.5 2.5 0 1 1-1.093 2.25l-1.175-6.095-.834 6.033a2.5 2.5 0 1 1-1.202-2.257l.89-6.444a2.75 2.75 0 0 1-1.157-1.484zM11.5 20.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm8-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
