import React from 'react';
import SelectInput, { Option } from '../Select';
import { MessageDescriptor } from 'react-intl';
import { FormGroup } from '../../FormGroup';
import { WidgetProps } from '../types';
import { ValidationsUnit } from 'core/lib/forms';
import { Stack } from '@chakra-ui/react';

export type InputRangeValue = {
    title: MessageDescriptor['id'] | null;
    description: MessageDescriptor['id'] | null;
    placeholder: MessageDescriptor['id'] | null;
    defaultValue: number | null;
    values: number[];
};

export type InputRangeProps = WidgetProps & {
    startValue: InputRangeValue;
    endValue: InputRangeValue;
};

const TIME_SEPARATOR_CHAR = ':';

export default function Range({
    id,
    title,
    name,
    description,
    help,
    links,
    validations,
    startValue,
    endValue,
    readonly,
    hideLabel,
}: InputRangeProps) {
    const widgetName = name ?? `${id}.value`;

    const getValueUnitFormated = (value: number): string => {
        switch (validations.unit) {
            case ValidationsUnit.HOUR:
                return `${value}${TIME_SEPARATOR_CHAR}00`;
            default:
                return `${value}`;
        }
    };

    const formatOptions = (list: number[]): Option[] =>
        list?.map(
            (value): Option => ({
                id: `${value}`,
                label: getValueUnitFormated(value),
                isTranslatable: false,
            })
        ) || [];

    const _renderSelect = (inputValue: InputRangeValue, id: string) => {
        const validationOptions = {
            rangeBetween: {
                id: id.replace('.end', '.start'),
                minRangeValue: validations.minRangeValue,
                maxRangeValue: validations.maxRangeValue,
            },
            higherThan: id.replace('.end', '.start'),
        };

        return (
            <SelectInput
                id={id}
                name={id}
                isSearchable={false}
                validations={{
                    required: validations?.required,
                    ...(id.endsWith('.end') && validationOptions),
                }}
                validationOptions={id.endsWith('.end') && validationOptions}
                options={formatOptions(inputValue?.values)}
                {...inputValue}
                defaultValue={`${inputValue?.defaultValue}`}
            />
        );
    };

    return (
        <FormGroup
            label={title}
            name={widgetName}
            isRequired={validations.required}
            isReadOnly={readonly}
            {...{ id, description, hideLabel, help, links }}>
            <Stack>
                {_renderSelect(startValue, `${id}.start`)}
                {_renderSelect(endValue, `${id}.end`)}
            </Stack>
        </FormGroup>
    );
}
