import { Icon, IconProps } from '@chakra-ui/react';

export default function MinusIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M20 12a1 1 0 0 1-1 1H5a1 1 0 1 1 0-2h14a1 1 0 0 1 1 1z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
