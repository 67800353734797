import axios from 'axios';
import { APIError } from 'core';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';

import { Refunds } from './types';

type FetchRefundsApi = Api<{
    refunds: Refunds[];
}>;

export async function fetchLastRefunds(
    path: string,
    hasHealthSubscription: boolean
) {
    if (!hasHealthSubscription) return [];
    try {
        const res = await axios.get(path);
        const { data }: FetchRefundsApi = res.data;
        return data?.refunds;
    } catch (error) {
        throw { code: error?.status };
    }
}

export function useLastRefunds(
    noOfMonth: number,
    hasHealthSubscription: boolean
) {
    const backToDate = new Date().setMonth(new Date().getMonth() - noOfMonth);
    const startDate = dayjs(backToDate).format('YYYY-MM-DD');
    const filtersQuery = `&dateRangePayment[startDate]=${startDate}`;

    const path = '/api/proxy/selfcare/health/refunds?start=1&count=20';
    const uri = `${path}${filtersQuery}`;

    return useQuery<Refunds[], APIError>({
        queryKey: [uri, hasHealthSubscription],

        queryFn: () => fetchLastRefunds(uri, hasHealthSubscription),
    });
}
