import { Icon, IconProps } from '@chakra-ui/react';

export default function FileEuroIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M9.75 14c0-.173.018-.34.052-.5H11.5c.276 0 .5-.243.5-.52a.484.484 0 0 0-.5-.48h-1.213a2.06 2.06 0 0 1 1.57-.75c.051 0 .113.007.18.018.378.06.77-.098.93-.446.185-.405-.017-.883-.452-.981a3.197 3.197 0 0 0-.658-.091c-1.499 0-2.754.938-3.306 2.25H7.5c-.276 0-.5.204-.5.48 0 .277.224.52.5.52h.782a3.902 3.902 0 0 0 0 1H7.5c-.276 0-.5.204-.5.48 0 .277.224.52.5.52h1.051c.552 1.312 1.807 2.25 3.306 2.25.207 0 .43-.033.64-.077.452-.097.653-.595.46-1.015a.805.805 0 0 0-.903-.451l-.197.043a2.06 2.06 0 0 1-1.57-.75H11.5c.276 0 .5-.243.5-.52a.484.484 0 0 0-.5-.48H9.802a2.422 2.422 0 0 1-.052-.5z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M4 19a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8.92a3 3 0 0 0-1.048-2.278l-4.573-3.92A3 3 0 0 0 12.427 2H7a3 3 0 0 0-3 3v14zm3 1a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5.427a1 1 0 0 1 .65.24l4.574 3.92a1 1 0 0 1 .349.76V19a1 1 0 0 1-1 1H7z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
