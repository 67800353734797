import { Icon, IconProps } from '@chakra-ui/react';

export default function EarIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M6.085 8.642C6.545 5.99 8.81 4 11.5 4 14.518 4 17 6.504 17 9.637c0 2.255-.805 4.021-2.225 4.922-.792.502-1.77 1.196-2.315 2.285-.232.464-.365 1.01-.46 1.405-.025.103-.048.196-.069.274a2.001 2.001 0 0 1-3.687.436C7.98 18.474 7.552 18 7 18s-1.013.455-.877.99a4.002 4.002 0 0 0 7.74.054c.05-.186.089-.346.123-.488.083-.338.14-.574.263-.818.28-.559.832-1.005 1.596-1.49C18.032 14.862 19 12.32 19 9.638 19 5.438 15.662 2 11.5 2 7.673 2 4.543 4.908 4.064 8.64c-.07.547.384.997.936.997s.99-.451 1.085-.995zM11.5 6.75c-1.425 0-2.618 1.12-2.74 2.577a3.751 3.751 0 0 1-.015 7.349C8.34 16.758 8 16.414 8 16c0-.414.345-.738.736-.873A2.25 2.25 0 0 0 8 10.75v-.008a.75.75 0 0 1-.75-.75v-.418c0-2.373 1.888-4.324 4.25-4.324s4.25 1.95 4.25 4.324c0 .987-.326 1.9-.875 2.629-.25.33-.73.311-1.013.009-.283-.303-.256-.774-.038-1.127.27-.436.426-.954.426-1.511 0-1.575-1.246-2.824-2.75-2.824z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
