import React from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

type CaptchaProps = {
    onTokenChange: React.Dispatch<React.SetStateAction<string>>;
    setForceCaptchaRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
    forceRefresh?: boolean;
};

export default function Captcha({
    onTokenChange,
    setForceCaptchaRefresh = () => null,
    forceRefresh = false,
}: CaptchaProps) {
    const [captchaToken, setCaptchaToken] = React.useState<string>();
    const [shouldRefresh, setShouldRefresh] = React.useState(false);

    const onVerify = React.useCallback(
        (token) => {
            setCaptchaToken(token);
            setShouldRefresh(false);
            setForceCaptchaRefresh(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setForceCaptchaRefresh, shouldRefresh]
    );

    React.useEffect(() => {
        if (forceRefresh) {
            setForceCaptchaRefresh(false);
            setShouldRefresh(true);
        }
    }, [forceRefresh, setForceCaptchaRefresh]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setShouldRefresh(true);
        }, 90000);

        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        if (!captchaToken) return;

        onTokenChange(captchaToken);
    }, [captchaToken, onTokenChange]);

    return (
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={shouldRefresh} />
    );
}
