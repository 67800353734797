import { Icon, IconProps } from '@chakra-ui/react';

export default function JobIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M4 7h3c0-1.21.374-2.3 1.339-3.042C9.247 3.258 10.514 3 12 3c1.486 0 2.753.259 3.661.958C16.626 4.7 17 5.789 17 7h3a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1zm5.559-1.458C9.225 5.8 9 6.211 9 7h6c0-.79-.225-1.2-.559-1.458C14.051 5.242 13.317 5 12 5c-1.317 0-2.05.241-2.441.542zM5 11V9h14v2H5zm0 2v6h14v-6h-2v1a1 1 0 1 1-2 0v-1H9v1a1 1 0 1 1-2 0v-1H5z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
