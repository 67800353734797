import React from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { useRedirect } from 'core';

function useHandleLostCredentialsResponse(
    setShouldToastError: (value: boolean) => void,
    setErrorMessage: (
        value: React.SetStateAction<{
            error: MessageDescriptor;
        }>
    ) => void,
    setHasError: (value: boolean) => void
) {
    const { redirect } = useRedirect();

    function handleLostCredentialsResponse(result: any) {
        if ([400, 401, 403, 409, 415].includes(result.status)) {
            const errorMessage = result.data?.errors[0]?.message.toLowerCase();
            redirect({
                pathname: '/lost-credentials/error/[status]',
                query: { status: errorMessage },
            });
            return true;
        }

        if (result.status === 404) {
            setErrorMessage(
                defineMessages({
                    errorTitle: {
                        id: 'api.lost-credentials.form.errors.404.title',
                        defaultMessage:
                            'Nous ne parvenons pas à vous identifier',
                    },
                    error: {
                        id: 'api.lost-credentials.form.errors.404.message',
                        defaultMessage:
                            '<l>Veuillez vérifier les informations saisies.</l><l>Si le problème persiste, nous vous invitons à contacter votre service client au numéro indiqué sur votre carte de tiers payant en mentionnant le code suivant :</l><l><b>Code 004</b></l>',
                    },
                })
            );
            setHasError(true);
            return true;
        }

        if ([500, 502, 504].includes(result.status)) {
            setShouldToastError(true);
            return true;
        }

        return false;
    }

    return {
        handleLostCredentialsResponse,
    };
}

export { useHandleLostCredentialsResponse };
