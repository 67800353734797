import { Icon, IconProps } from '@chakra-ui/react';

export default function LotusIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="m12 3.853.49.275a8.27 8.27 0 0 1 2.805 2.597 6.72 6.72 0 0 1 2.898-.563l.55.017.28.473a7.368 7.368 0 0 1 .987 4.45 6.81 6.81 0 0 1 1.528.731l.444.286.015.527c.071 2.517-1.153 5.018-3.431 6.388a6.688 6.688 0 0 1-6.566.203 6.688 6.688 0 0 1-6.566-.203c-2.278-1.37-3.502-3.87-3.431-6.388l.015-.527.444-.286a6.805 6.805 0 0 1 1.77-.807 7.367 7.367 0 0 1 .994-4.374l.28-.473.55-.017a6.715 6.715 0 0 1 2.704.482 8.265 8.265 0 0 1 2.75-2.516l.49-.275zM7.82 8.426a4.728 4.728 0 0 0-1.137-.248 5.385 5.385 0 0 0-.466 2.58c.352.005.704.039 1.055.101.066-.852.255-1.67.548-2.433zm-.465 4.5a4.65 4.65 0 0 0-1.86-.128 4.756 4.756 0 0 0-1.48.451c.12 1.652.996 3.197 2.45 4.07 1.115.67 2.377.83 3.54.547a8.497 8.497 0 0 1-2.65-4.94zM12 16.905c.44-.305.842-.669 1.194-1.081a6.548 6.548 0 0 0 1.537-3.719 6.636 6.636 0 0 0-.701-3.596A6.362 6.362 0 0 0 12 6.179a6.357 6.357 0 0 0-1.978 2.231 6.63 6.63 0 0 0-.753 3.695c.167 2.014 1.218 3.752 2.731 4.8zm1.995.962a4.68 4.68 0 0 0 3.54-.547c1.453-.873 2.33-2.418 2.45-4.07a4.761 4.761 0 0 0-1.282-.42 4.654 4.654 0 0 0-2.058.098 8.528 8.528 0 0 1-1.93 4.195 8.315 8.315 0 0 1-.72.744zm2.733-7.007c.433-.077.87-.11 1.304-.1.06-.89-.104-1.781-.466-2.58-.464.042-.92.153-1.355.33.276.74.453 1.529.517 2.35z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
