import React, {
    Dispatch,
    SetStateAction,
    createContext,
    useState,
    useEffect,
    ReactNode,
} from 'react';
import { useSessionStorage } from 'core';

/*eslint-disable no-unused-vars */
export enum WorkflowKeysEnum {
    TELECONSULTATION = 'teleconsultation',
    HOME_CONSULTATION = 'home-consultation',
    PREVENTION = 'prevention',
}
/* eslint-enable */

export const WorkflowDataDefaultValue = {
    currentStep: 0,
};

type WorkflowContextData = {
    currentStep: number;
};

export type WorkflowContextType = {
    data: WorkflowContextData;
    setData: Dispatch<SetStateAction<WorkflowContextData>> | null;
    isSubmitLoading: boolean;
    setIsSubmitLoading: Dispatch<SetStateAction<boolean>> | null;
};

const WorkflowContext = createContext<WorkflowContextType>({
    data: WorkflowDataDefaultValue,
    setData: null,
    isSubmitLoading: false,
    setIsSubmitLoading: null,
});

type WorkFlowProviderProps = {
    storageKey: WorkflowKeysEnum;
    children: ReactNode;
};

const WorkFlowProvider = ({ storageKey, children }: WorkFlowProviderProps) => {
    const { load, remove, save } = useSessionStorage();
    const [data, setData] = useState<WorkflowContextData>(
        load(storageKey, WorkflowDataDefaultValue) as WorkflowContextData
    );
    const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

    useEffect(() => {
        save(storageKey, data);

        return () => remove(storageKey);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <WorkflowContext.Provider
            value={{
                data,
                setData,
                isSubmitLoading,
                setIsSubmitLoading,
            }}>
            {children}
        </WorkflowContext.Provider>
    );
};

export { WorkflowContext, WorkFlowProvider };
