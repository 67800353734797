/**
 * Returns an array of arrays
 * @param arr The array to slice
 * @param row The number of row inside the new created array
 * @param slice How many item inside each row
 */
export function createRows<A = unknown>(
    arr: A[],
    row: number,
    slice: number
): A[][] {
    return Array.from(Array(row)).map((_, idx) =>
        arr.slice(idx * slice, (idx + 1) * slice)
    );
}

/**
 * get the decade in which the current visible year is in
 * @param year The current year
 */
export function getDecade(year: number): number {
    const stringifiedYear = year.toString();
    const yearLength = stringifiedYear.length;
    return Number(
        stringifiedYear.slice(0, yearLength - 1).padEnd(yearLength, '0')
    );
}
