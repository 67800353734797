import { Icon, IconProps } from '@chakra-ui/react';

export default function DiscutionIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M17 14.5H9l-3.331 2.998c-.644.58-1.669.122-1.669-.743V14.5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2v8.5a2 2 0 0 1-2 2zm-11 .01V13.5a1 1 0 0 0-1-1H4V4h13v8.5H8.616a1 1 0 0 0-.669.257L6 14.509z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                d="M20 16.5h-1a1 1 0 0 0-1 1v1.01l-1.947-1.753a1 1 0 0 0-.67-.257H8.65a1 1 0 0 0-.66.25c-.693.608-.262 1.75.66 1.75H15l3.331 2.998c.644.58 1.669.122 1.669-.743V18.5a2 2 0 0 0 2-2V7a1 1 0 1 0-2 0v9.5z"
                fill="currentColor"
            />
        </Icon>
    );
}
