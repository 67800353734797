import { defineMessages } from 'react-intl';
import { ContractStatusEnum } from '../types';

export const newsCardContractTitle = defineMessages({
    [ContractStatusEnum.PORTABILITY]: {
        id: 'components.news.status.contract.portability.title',
        defaultMessage: 'Portabilité des droits',
    },
    [ContractStatusEnum.CLOSED]: {
        id: 'components.news.status.contract.closed.title',
        defaultMessage: "Vous n'êtes plus couvert",
    },
    [ContractStatusEnum.FUTURE]: {
        id: 'components.news.status.contract.future.title',
        defaultMessage: 'Votre contrat commence bientôt',
    },
});

export const newsCardContractMessage = defineMessages({
    [ContractStatusEnum.PORTABILITY]: {
        id: 'components.news.status.contract.portability.message',
        defaultMessage:
            '<l>Votre contrat ayant pris fin, vous bénéficiez de la portabilité de vos droits. Vous continuez donc à être couvert par votre contrat santé.</l><l>Pour maintenir votre couverture, nous vous invitons à mettre à jour votre situation tous les mois.</l>',
    },
    [ContractStatusEnum.CLOSED]: {
        id: 'components.news.status.contract.closed.message',
        defaultMessage:
            "<l>Vous ne pouvez plus bénéficier des services mis à votre disposition dans {appName}.</l><l>En revanche, vous pouvez accéder à l'historique de vos remboursements pendant une période de deux ans à compter de la date de fin de votre contrat.</l>",
    },
    [ContractStatusEnum.FUTURE]: {
        id: 'components.news.status.contract.future.message',
        defaultMessage:
            "<l>Votre contrat santé prendra effet très prochainement. L'accès à certaines fonctionnalités est donc temporairement restreint.</l><l>Si besoin, n'hésitez pas à contacter votre centre de gestion <i>via</i> le bouton “Aide”.</l>",
    },
});

export const newsCardContractMoreInformations = defineMessages({
    [ContractStatusEnum.PORTABILITY]: {
        id: 'components.news.status.contract.portability.more-informations',
        defaultMessage: 'Plus d’informations',
    },
    [ContractStatusEnum.CLOSED]: {
        id: 'components.news.status.contract.closed.more-informations',
        defaultMessage: 'Plus d’informations',
    },
    [ContractStatusEnum.FUTURE]: {
        id: 'components.news.status.contract.future.more-informations',
        defaultMessage: 'Plus d’informations',
    },
});
