import React from 'react';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
    ButtonProps,
    Text,
    Flex,
    Box,
    Center,
    Spinner,
} from '@chakra-ui/react';
import { lowerCase, startCase } from 'lodash';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { useUserContext, getUserInitials } from 'lib/user';
import { useLogout } from 'lib/auth';
import { coreSharedMessages, LinkIntl } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import {
    ChevronBottomIcon,
    PersonIcon,
    LogoutIcon,
    FileFileIcon,
} from 'design-system/icons';
import { labelMessages } from './index';
import { ThemeContext } from 'themes';

import siteConfig from 'lib/site/config.json';
import { pageTitles } from 'lib/site';

const UserInfoButton = React.forwardRef(function UserInfoButton(
    { children, ...props }: React.PropsWithChildren<unknown> & ButtonProps,
    ref: React.Ref<HTMLButtonElement>
) {
    const styles = {
        bg: 'grey.100',
        borderColor: 'strokes.medium',
    };

    return (
        <Button
            {...props}
            ref={ref}
            bg="grey.50"
            px="1"
            h="44px"
            border="1px solid"
            borderColor="strokes.medium"
            _focus={styles}
            _hover={styles}
            _active={styles}>
            {children}
        </Button>
    );
});

const intlMessages = defineMessages({
    account: {
        id: 'components.account.title',
        defaultMessage: 'Compte',
    },
});

const User = () => {
    const { formatMessage } = useIntl();
    const { isTablet } = useWindowBreakpoints();
    const { userInfos, isLoading } = useUserContext();
    const lastName = userInfos?.user?.lastName;
    const firstName = userInfos?.user?.firstName;
    const userInitials = getUserInitials(lastName, firstName);

    return (
        <Flex flexDir="row" alignItems="center">
            <Center
                rounded="sm"
                bg="user.main"
                w="36px"
                h="36px"
                color="white"
                fontWeight="bold">
                {isLoading ? (
                    <Spinner color="white" size="sm" />
                ) : userInitials ? (
                    userInitials
                ) : (
                    <PersonIcon fontWeight="bold" w="5" h="5" />
                )}
            </Center>

            <Text>
                {!isTablet && (
                    <Box as="span" ml="2">
                        {startCase(
                            lowerCase(
                                isLoading
                                    ? ''
                                    : firstName ??
                                          formatMessage(intlMessages.account)
                            )
                        )}
                    </Box>
                )}
                <ChevronBottomIcon ml={isTablet ? 1 : 2} />
            </Text>
        </Flex>
    );
};

const UserInfo = () => {
    const handleMouseEnter = () => {
        btnRef?.current?.click();
    };
    const btnRef = React.useRef<HTMLButtonElement>(null);
    const { logout } = useLogout();
    const { setVariant } = React.useContext(ThemeContext);
    const { showAccountButton } = siteConfig;

    return (
        <Menu>
            <UserInfoButton
                as={MenuButton}
                onMouseEnter={handleMouseEnter}
                flexShrink={0}
                ref={btnRef}>
                <User />
            </UserInfoButton>
            <MenuList zIndex={15} color="primary.main" fontSize="sm">
                {showAccountButton && (
                    <LinkIntl href="/account" passHref>
                        <MenuItem>
                            <FileFileIcon mr={3} fontSize="lg" />
                            <FormattedMessage {...labelMessages.contract} />
                        </MenuItem>
                    </LinkIntl>
                )}
                <LinkIntl href="/account/profile" passHref>
                    <MenuItem>
                        <PersonIcon mr={3} fontSize="lg" />
                        <FormattedMessage {...pageTitles.profile} />
                    </MenuItem>
                </LinkIntl>
                <MenuItem
                    onClick={() => {
                        logout();
                        setVariant(siteConfig.theme.variant);
                    }}>
                    <LogoutIcon mr={3} fontSize="lg" />
                    <FormattedMessage {...coreSharedMessages.logout} />
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export default UserInfo;
