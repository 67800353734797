import { defineMessages, useIntl } from 'react-intl';
import { useSafeIntl } from 'core';

const intlMessages = defineMessages({
    bytes: {
        id: 'components.forms.widgets.file-upload.file-size-bytes',
        defaultMessage:
            '{fileSize, plural, zero {# octet} one {# octet} other {# octets}}',
    },
    ko: {
        id: 'components.forms.widgets.file-upload.file-size-ko',
        defaultMessage: '{fileSize} Ko',
    },
    mo: {
        id: 'components.forms.widgets.file-upload.file-size-mo',
        defaultMessage: '{fileSize} Mo',
    },
});

export default function useFormattedFileSize(
    size: number,
    precision: number = 1
) {
    const { formatMessage } = useIntl();
    const { safeFormatNumber } = useSafeIntl();

    if (size < 1024) {
        return formatMessage(intlMessages.bytes, {
            fileSize: safeFormatNumber(size, { unit: 'byte' }),
        });
    }

    if (size < 1024 * 1024) {
        return formatMessage(intlMessages.ko, {
            fileSize: safeFormatNumber(+(size / 1024).toFixed(precision), {
                unit: 'kilobyte',
            }),
        });
    }

    return formatMessage(intlMessages.mo, {
        fileSize: safeFormatNumber(+(size / (1024 * 1024)).toFixed(precision), {
            unit: 'megabyte',
        }),
    });
}
