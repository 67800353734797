import axios from 'axios';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getNextPageParam } from 'core/lib/shared/helpers/getNextPageParam';
import type { Statement } from './types';
import { APIError } from 'core';

type UseStatements = {
    statements?: Statement[];
    statementsTotalErrors: number;
    pagination?: Pagination;
};

async function fetchStatements(path: string) {
    try {
        const res = await axios.get(path);
        const {
            data,
            meta,
        }: Api<
            { statements: Statement[]; statementsTotalErrors: number },
            null,
            { pagination: Pagination }
        > = await res.data;

        if (!data || !meta) throw new Error('Une erreur est survenue !');

        return {
            pagination: meta.pagination,
            statementsTotalErrors: data.statementsTotalErrors,
            statements: data.statements,
        };
    } catch (error) {
        throw { code: error.response?.status };
    }
}

function useStatements() {
    const path = '/api/proxy/selfcare/health/refunds/statements';
    return useInfiniteQuery<any, APIError>({
        initialPageParam: 1,
        queryKey: [path],
        queryFn: ({ pageParam = 1 }) =>
            fetchStatements(`${path}?start=${pageParam}&count=10`),
        getNextPageParam,
    });
}

export type { UseStatements };
export { fetchStatements, useStatements };
