import { Icon, IconProps } from '@chakra-ui/react';

export default function ArrowTopIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M13 8.061V18a1 1 0 1 1-2 0V8.061l-3.596 2.833a1 1 0 1 1-1.237-1.571l5.214-4.108a1 1 0 0 1 1.238 0l5.214 4.108a1 1 0 0 1-1.237 1.57L13 8.062z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
