import * as React from 'react';

export default function useIsOverflow(ref) {
    const [isOverflow, setIsOverflow] = React.useState<boolean>(false);

    React.useLayoutEffect(() => {
        const { current } = ref;
        const trigger = () => {
            // We clone the element to get his size in absolute position and with an overflow unset to get the correct size
            const clone = current.cloneNode(true);
            clone.style.position = 'absolute';
            clone.style.overflow = 'unset';

            document.body.appendChild(clone);

            const hasOverflow = clone.scrollWidth > current.clientWidth;

            document.body.removeChild(clone);

            setIsOverflow(hasOverflow);
        };

        if (current) {
            trigger();
        }
    }, [ref]);

    return isOverflow;
}
