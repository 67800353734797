import { IconProps, Image } from '@chakra-ui/react';
import { omit } from 'lodash';
import { UserInfos } from 'lib/user';
import LogoLMV from './LMV';

export default function CoInsurer(
    props: IconProps & { coInsurer: UserInfos['coInsurer'] }
) {
    switch (props?.coInsurer) {
        case 'AG2R':
            return (
                <Image
                    src="/images/logos/AG2R.jpg"
                    alt={props?.coInsurer}
                    maxH="60px"
                />
            );
        case 'AGRICA':
        case 'AGRI-prevoyance':
        case 'CCPMA-prevoyance':
        case 'CPCEA':
            return (
                <Image
                    src="/images/logos/AGRICA.jpg"
                    alt={props?.coInsurer}
                    maxH="60px"
                />
            );
        case 'la-mutuelle-verte':
        case 'FNMF':
            return <LogoLMV {...omit(props, 'coInsurer')} w="20" h="20" />;
        default:
            return null;
    }
}
