import { ComponentType } from 'react';
import { IconProps } from '@chakra-ui/react';
import { MessageDescriptor } from 'react-intl';
import { pathEnum } from 'lib/teleconsultation';
import { WorkflowContextType } from 'core';
import { InternationalPhone } from 'core/components/forms/widgets/InternationalPhoneNumber';
import { CloseInfoType } from 'components/Teleconsultation/agenda/AddTeleconsultationDocumentModal';
import { ConsentModalEnum } from 'lib/consent-modal';
import { AppointmentRequest } from 'lib/shared/types/appointment';

export enum TeleconsultationStepsEnum {
    LOCATION = 'location',
    BENEFICIARY = 'beneficiary',
    PATIENT = 'patient', // new workflow "beneficiary"
    COMMENT = 'comment',
    APPOINTMENT = 'appointment', // new workflow "communication-date"
    COMMUNICATION = 'communication', // new workflow "communication-method"
    COMMUNICATION_METHOD = 'communication-method',
    REASON_AND_DOCUMENT = 'reason-and-document',
    CONFIRMATION = 'confirmation',
    SPECIALITY_CHOICE = 'speciality-choice',
    COMMUNICATION_DATE = 'communication-date',
}

export enum TeleconsultationWorkflowEnum {
    IMMEDIATE_EXCHANGE = 'immediate-exchange',
    APPOINTMENT = 'appointment',
    TEXT_MESSAGE_EXCHANGE = 'text-message-exchange',
}

export type TeleconsultationWorkflowType =
    | 'immediate-exchange'
    | 'appointment'
    | 'text-message-exchange';

export const TELECONSULTATION_STEPS: {
    [x in TeleconsultationWorkflowEnum]: TeleconsultationStepsEnum[];
} = {
    [TeleconsultationWorkflowEnum.TEXT_MESSAGE_EXCHANGE]: [
        TeleconsultationStepsEnum.LOCATION,
        TeleconsultationStepsEnum.BENEFICIARY,
        TeleconsultationStepsEnum.COMMENT,
        TeleconsultationStepsEnum.CONFIRMATION,
    ],
    [TeleconsultationWorkflowEnum.IMMEDIATE_EXCHANGE]: [
        TeleconsultationStepsEnum.LOCATION,
        TeleconsultationStepsEnum.BENEFICIARY,
        TeleconsultationStepsEnum.COMMENT,
        TeleconsultationStepsEnum.COMMUNICATION_METHOD,
        TeleconsultationStepsEnum.CONFIRMATION,
    ],
    [TeleconsultationWorkflowEnum.APPOINTMENT]: [
        TeleconsultationStepsEnum.LOCATION,
        TeleconsultationStepsEnum.BENEFICIARY,
        TeleconsultationStepsEnum.SPECIALITY_CHOICE,
        TeleconsultationStepsEnum.COMMENT,
        TeleconsultationStepsEnum.COMMUNICATION_METHOD,
        TeleconsultationStepsEnum.COMMUNICATION_DATE,
        TeleconsultationStepsEnum.CONFIRMATION,
    ],
};

export type TeleconsultationCountry = {
    id: string;
    group: string;
    code: string;
    name: string;
};
export interface TeleconsultationDocument {
    id: string;
    category: TeleconsultationDocumentCategory;
    creationDate: string;
    fileName: string;
    label: string | null;
    downloadable: boolean;
    extension: string;
}

type TeleconsultationDocumentCategory =
    | 'File'
    | 'Prescription'
    | 'MedicalReport';

export interface PreventionDocument {
    id: string;
    category: PreventionDocumentCategory;
    creationDate: string;
    fileName: string;
    label: string | null;
    downloadable: boolean;
    extension: string;
}

type PreventionDocumentCategory = 'File' | 'Prescription' | 'MedicalReport';

export type ActionIcon = (props: IconProps) => JSX.Element;

type ModalComponentType = {
    data: AppointmentRequest;
    isOpen: boolean;
    onClose: (closeInfo: CloseInfoType) => void;
    onSuccess?: () => void;
    onConfirm?: () => void;
    pathType?: keyof typeof pathEnum;
};

type CollapseContentType = {
    data: AppointmentRequest;
    toogleCollapse?: () => void;
    pathType?: keyof typeof pathEnum;
    refetchTeleconsultationDetails?: () => void;
};

export type AgendaTeleconsultationAction = {
    id: string;
    icon?: ActionIcon;
    label: MessageDescriptor;
    modalComponent?: ComponentType<ModalComponentType>;
    collapseContent?: ComponentType<CollapseContentType>;
};

export type SpecialistFreeTimeSlotOption = {
    value: string;
    status: string;
    type: ConsentModalEnum;
    tooltipMessage: string;
};

export type SpecialistFreeTimeSlot = {
    id: string;
    name: string;
    type: ConsentModalEnum;
    slotTime: string;
    isAvailableNow: boolean;
    options: SpecialistFreeTimeSlotOption[];
};

export type WorkflowTeleconsultationData = {
    currentStep: number;
    acceptShareMedicalData: boolean;
    acceptRemoteMedicalConsultation: boolean;
    countryId: string;
    beneficiaryIdentifier: string | null;
    themeId: string | null;
    comment: string | null;
    preferredCanal: 'Video' | 'Phone';
    phone: InternationalPhone;
    email: string;
    recallPhoneNumber: InternationalPhone;
    freeTimeSlotId: string;
};

export type TeleconsultationContextType = WorkflowContextType & {
    data: WorkflowTeleconsultationData;
    setData: React.Dispatch<React.SetStateAction<WorkflowTeleconsultationData>>;
};

export type WorkflowType =
    | 'immediate-exchange'
    | 'appointment'
    | 'text-message-exchange'
    | 'echange-par-sms'
    | 'echange-immediat'
    | 'rendez-vous';
