import { Flex, HStack, Stack, Text } from '@chakra-ui/react';
import BlockToggle from '../../components/BlockToggle';
import { Heading } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { AttentionIcon, InfoIcon } from 'design-system/icons';
import { uniqueId } from 'lodash';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import {
    CoreModal,
    CoreModalContent,
    CoreModalHeader,
    CoreModalBody,
} from 'design-system/components';

type AdvicesModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const designationMessages = {
    jointly: {
        title: defineMessage({
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.jointly designate-title',
            defaultMessage: 'Désigner conjointement',
        }),
        description: defineMessage({
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.jointly designate-description',
            defaultMessage:
                'Si vous souhaitez que vos bénéficiaires perçoivent tous une partie de votre capital décès, ne remplissez pas le rang de priorité. Par défaut, votre capital sera distribué par parts égales entre les bénéficiaires désignés.',
        }),
        icon: 'pieIcon',
    },
    allocate: {
        title: defineMessage({
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.allocate-capital-title',
            defaultMessage: 'Désigner conjointement et ventiler mon capital',
        }),
        description: defineMessage({
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.allocate-capital-description',
            defaultMessage:
                'Si vous souhaitez répartir votre capital différemment entre vos bénéficiaires, vous devez alors remplir la case "% du capital" en attribuant des pourcentage du capital, de manière à ce que le total fasse bien 100%.',
        }),
        icon: 'pieIcon',
    },
    successively: {
        title: defineMessage({
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.designate successively-title',
            defaultMessage: 'Désigner successivement',
        }),
        description: defineMessage({
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.designate successively-description',
            defaultMessage:
                'Vous pouvez désigner des rangs de priorité. Dans ce cas, 100% votre capital sera versé au bénéficiaire de rang 1. En cas de décès ou de renoncement de celui-ci, il sera versé au bénéficiaire de rang 2, et ainsi de suite.',
        }),
        icon: 'babysittingIcon',
    },
};

export default function AdvicesModal({ isOpen, onClose }: AdvicesModalProps) {
    const { isMobile } = useWindowBreakpoints();
    const { formatMessage } = useIntl();

    return (
        <CoreModal isOpen={isOpen} onClose={onClose} isCentered>
            <CoreModalContent
                minW={!isMobile ? '2xl' : '90%'}
                borderRadius={isMobile ? 'lg' : 'xl'}
                mx="4"
                height="inherit">
                <CoreModalHeader>
                    <FormattedMessage
                        id="workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.title"
                        defaultMessage="Quelques conseils pour remplir votre clause"
                    />
                </CoreModalHeader>
                <CoreModalBody py="4" overflow="auto">
                    <Stack gap="6">
                        <Stack gap="6" spacing="0">
                            <Heading fontSize="2xl" as="h2">
                                <FormattedMessage
                                    id="workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.person"
                                    defaultMessage="Désigner une personne physique"
                                />
                            </Heading>
                            <Stack spacing="4">
                                <Heading as="h4">
                                    <FormattedMessage
                                        id="workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.person-quality-title"
                                        defaultMessage="Désigner par sa qualité"
                                    />
                                </Heading>
                                <Text>
                                    <FormattedMessage
                                        id="workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.person-quality-description"
                                        defaultMessage="Afin d’éviter tout conflit ou restriction, nous vous conseillons de désigner vos bénéficiaires par leur qualité « Mon conjoint, … ». Ceci vous évitera de réaliser une nouvelle désignation si votre situation a changé et que la répartition souhaitée reste la même."
                                    />
                                </Text>
                            </Stack>
                            <Stack spacing="4">
                                <Heading as="h4">
                                    <FormattedMessage
                                        id="workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.person-name-title"
                                        defaultMessage="Désigner nominativement"
                                    />
                                </Heading>
                                <Text>
                                    <FormattedMessage
                                        id="workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.person-name-description"
                                        defaultMessage="Si vous préférez désigner nommément vos bénéficiaires, vous devez mentionner leur identité et leur coordonnées complètes (nom, -nom de jeune fille-, prénom, adresse, date et lieu de naissance)."
                                    />
                                </Text>
                            </Stack>
                            <AdviceComponent
                                icon={<AttentionIcon />}
                                title={formatMessage({
                                    id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.person-name-warning-title',
                                    defaultMessage: 'Désignation nominative',
                                })}
                                description={formatMessage({
                                    id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.person-name-warning-description',
                                    defaultMessage:
                                        '<l>Si le conjoint est désigné nominativement, le capital décès lui sera versé même si au jour du décès il n’a plus le statut de conjoint.</l>Si les enfants sont désignés nominativement, le capital décès leur sera versé et ceux nés postérieurement à la rédaction de la désignation de bénéficiaire en seront exclus.',
                                })}
                            />
                            <Stack spacing="4">
                                <Heading as="h3">
                                    <FormattedMessage
                                        id="workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.legal-entity-title"
                                        defaultMessage="Désigner une personne morale"
                                    />
                                </Heading>
                                <Text>
                                    <FormattedMessage
                                        id="workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.legal-entity-description"
                                        defaultMessage="Assurez-vous que la personne morale est bien dotée de la personnalité juridique et que son représentant est en capacité à percevoir le capital pour le compte de celle-ci."
                                    />
                                </Text>
                            </Stack>
                            <AdviceComponent
                                icon={<InfoIcon />}
                                title={formatMessage({
                                    id: 'components.in-practice',
                                    defaultMessage: 'En pratique',
                                })}
                                description={formatMessage({
                                    id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.legal-entity-info',
                                    defaultMessage:
                                        'Vous devrez fournir les actes constitutifs de la personne morale ou l’acte autorisant son représentant à accepter le bénéfice.',
                                })}
                            />
                        </Stack>
                        <Stack gap="6" spacing="0">
                            <Heading fontSize="2xl" as="h2">
                                <FormattedMessage
                                    id="workflow-signature.workflow-specific-guided-beneficiaries-designation.distribution-capital.divided-capital-title"
                                    defaultMessage="Désigner plusieurs bénéficiaires"
                                />
                            </Heading>
                            <Stack>
                                {Object.keys(designationMessages).map(
                                    (designationType) => (
                                        <BlockToggle
                                            key={uniqueId()}
                                            color="primary"
                                            title={formatMessage(
                                                designationMessages[
                                                    designationType
                                                ]['title']
                                            )}
                                            icon={
                                                designationMessages[
                                                    designationType
                                                ]['icon']
                                            }
                                            wysiwyg={{
                                                value: formatMessage(
                                                    designationMessages[
                                                        designationType
                                                    ]['description']
                                                ),
                                                format: 'html',
                                            }}
                                            containerProps={null}
                                        />
                                    )
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </CoreModalBody>
            </CoreModalContent>
        </CoreModal>
    );
}

type AdviceComponentProps = {
    icon: React.ReactElement;
    title: string;
    description: string;
};
function AdviceComponent({ icon, title, description }: AdviceComponentProps) {
    return (
        <Stack
            spacing="4"
            border="2px solid"
            borderColor="secondary.main"
            p="4"
            borderRadius="2xl">
            <HStack spacing="3">
                <Flex
                    bg="secondary.main"
                    p="2"
                    borderRadius="lg"
                    color="white"
                    fontSize="2xl">
                    {icon}
                </Flex>
                <Heading as="h4">{title}</Heading>
            </HStack>
            <Text>{description}</Text>
        </Stack>
    );
}
