import {
    validationFormsMessages,
    FormatMessageFn,
    WatchFn,
    ValidationOptions,
} from 'core';

function validateHigherThan(
    options: ValidationOptions | undefined,
    watch: WatchFn,
    formatMessage: FormatMessageFn
) {
    return (value: string) => {
        if (!options?.higherThan) return undefined;

        const comparedInputValue = watch(options?.higherThan);

        if (!value && !comparedInputValue) {
            return undefined;
        }

        return parseInt(value) > parseInt(comparedInputValue)
            ? undefined
            : formatMessage(validationFormsMessages.higherThan);
    };
}

export { validateHigherThan };
