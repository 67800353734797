import { coreSharedMessages } from '../../../shared/i18n';
import { defineMessages } from 'react-intl';

export const formMessages = {
    'profile.contact-details.title': coreSharedMessages.updateContactInfo,
    ...defineMessages({
        'user-infos.job-title.title': {
            id: 'widgets.user-infos.job-title.title',
            defaultMessage: 'Fonction',
        },
        'search.contract-number-placeholder': {
            id: 'widgets.search.contract-number-placeholder',
            defaultMessage: 'Numéro du contrat',
        },
        'contracts.contract-covered-risk.title': {
            id: 'widgets.contracts.contract-covered-risk.title',
            defaultMessage: 'Risque couvert',
        },
        'profile.username.title': {
            id: 'forms.profile.username.title',
            defaultMessage: 'Modifier mon identifiant',
        },
        'user-infos.username.title': {
            id: 'widgets.user-infos.username.title',
            defaultMessage: 'Identifiant de connexion',
        },
        'user-infos.username.help': {
            id: 'widgets.user-infos.username.help',
            defaultMessage:
                '<br>L’identifiant est limité à 100 caractères. </br>Si vous souhaitez utiliser l’adresse email en tant qu’identifiant, assurez-vous qu’elle fait moins de 100 caractères',
        },
        'user-infos.account-validity-date-start.title': {
            id: 'widgets.user-infos.account-validity-date-start.title',
            defaultMessage: 'Date de début de validité du compte',
        },
        'user-infos.account-validity-date-end.title': {
            id: 'widgets.user-infos.account-validity-date-end.title',
            defaultMessage: 'Date de fin de validité du compte',
        },
        'profile.notifications-preference.title': {
            id: 'forms.profile.notifications-preference.title',
            defaultMessage: 'Gérer la réception de mes décomptes',
        },
        'notifications.value-email': {
            id: 'widgets.notifications.value-email',
            defaultMessage: 'Je souhaite être notifié par email',
        },
        'notifications.value-none': {
            id: 'widgets.notifications.value-none',
            defaultMessage: 'Je ne souhaite pas être notifié',
        },
        'notifications.receipt.title': {
            id: 'widgets.notifications.receipt.title',
            defaultMessage: 'Mise à disposition des décomptes de paiement',
        },
        health: {
            id: 'widgets.contract.covered-risk.health',
            defaultMessage: 'Santé',
        },
        'admin.user-manage.contact-details.title': {
            id: 'forms.admin.user-manage.contact-details.title',
            defaultMessage:
                'Modification des informations générales et du statut',
        },
        'search.users.placeholder': {
            id: 'widgets.search.users.placeholder',
            defaultMessage: 'Nom, prénom, adresse e-mail',
        },
        'admin.user-manage.permissions.title': {
            id: 'forms.admin.user-create.permissions.title',
            defaultMessage: 'Gestion des permissions',
        },
        'user-permissions.description': {
            id: 'widgets.user-permissions.description',
            defaultMessage:
                'Définissez ci-dessous les différentes permissions pour votre utilisateur.',
        },
        'user-permissions.read-only': {
            id: 'widgets.user-permissions.read-only',
            defaultMessage: 'Consultation',
        },
        'user-permissions.read-write': {
            id: 'widgets.user-permissions.read-write',
            defaultMessage: 'Modification',
        },
        'user-permissions.statistics.title': {
            id: 'widgets.user-permissions.statistics.title',
            defaultMessage: 'Statistiques',
        },
        'user-permissions.documents.title': {
            id: 'widgets.user-permissions.documents.title',
            defaultMessage: 'Documents',
        },
        'user-permissions.contributions.title': {
            id: 'widgets.user-permissions.contributions.title',
            defaultMessage: 'Cotisations',
        },
        'user-permissions.provident.title': {
            id: 'widgets.user-permissions.provident.title',
            defaultMessage: 'Prévoyance',
        },
        'user-permissions.employees-management.title': {
            id: 'widgets.user-permissions.employees-management.title',
            defaultMessage: 'Gestion des salariés',
        },
        'user-permissions.subscription-forms.title': {
            id: 'widgets.user-permissions.subscription-forms.title',
            defaultMessage: 'Bulletins d’adhésions',
        },
        'user-permissions.situation-history.title': {
            id: 'widgets.user-permissions.situation-history.title',
            defaultMessage: 'Historique de situation',
        },
        'user-permissions.employee-access-document.title': {
            id: 'widgets.user-permissions.employee-access-document.title',
            defaultMessage: 'Coupons',
        },
        'admin.user-manage.companies.title': {
            id: 'forms.admin.user-create.companies.title',
            defaultMessage: 'Rattachement des entreprises',
        },
        'user-companies.title': {
            id: 'widgets.user-companies.title',
            defaultMessage: 'Liste des entreprises',
        },
        'user-companies.description': {
            id: 'widgets.user-companies.description',
            defaultMessage:
                'Définissez ci-dessous les entreprises auxquelles vous souhaitez rattacher cet utilisateur.',
        },
        'user-infos.current-username.title': {
            id: 'widgets.user-infos.current-username.title',
            defaultMessage: 'Identifiant actuel',
        },
    }),
};
