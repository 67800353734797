import { Icon, IconProps } from '@chakra-ui/react';

export default function CopyIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 20 20" {...props}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M7.333 0a2 2 0 00-2 2v3.333H2a2 2 0 00-2 2V16a2 2 0 002 2h8.667a2 2 0 002-2v-3.333H16a2 2 0 002-2V2a2 2 0 00-2-2H7.333zm3.334 12.667H7.333a2 2 0 01-2-2V7.333H2V16h8.667v-3.333zm-3.334-2V2H16v8.667H7.333z"
                clipRule="evenodd"
            />
        </Icon>
    );
}
