import React from 'react';
import { defineMessage, useIntl } from 'react-intl';
import '@reach/skip-nav/styles.css';
import { Flex } from '@chakra-ui/react';
import { useRoving, Site } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { useRouter } from 'next/router';
import { MenuLinksLayout, MenuLinksLayoutProps } from './MenuLinksLayout';
import MenuLink from './MenuLink';
import { MenuItemProps } from './MenuItem';

const navbarAriaRoleMessage = defineMessage({
    id: 'components.layouts.navbar.role',
    defaultMessage: 'Menu principal',
});

const navStyleProps = {
    small: {
        bottom: 0,
        w: '100%',
        py: 2,
        filter: 'drop-shadow(0px -2px 16px rgba(0, 0, 0, 0.1))',
    },
    large: {
        h: '100%',
        maxH: '100vh',
        top: 0,
        py: 8,
        borderRight: '1px solid',
        borderRightColor: 'strokes.medium',
    },
};

export type CoreMenuProps = Pick<
    MenuItemProps,
    'resetFilters' | 'intlMessages'
> &
    Pick<MenuLinksLayoutProps, 'logoPath'> & {
        links: Site.MenuLink[];
        width: string;
    };

export default function CoreMenu({
    links,
    width,
    logoPath,
    resetFilters,
    intlMessages,
}: CoreMenuProps) {
    const { isSmallDevice } = useWindowBreakpoints();
    const { formatMessage } = useIntl();
    const router = useRouter();

    const defaultFocus = links.map((link) => link.to).indexOf(router.pathname);
    const rovingGroup = links.map((_, index) => index);
    const { setCurrentFocus, handleKeyDown } = useRoving(
        rovingGroup,
        'horizontal',
        defaultFocus || 0
    );
    const [isMenuExpanded, setIsMenuExpanded] = React.useState(false);

    const maxNbOfEntries =
        isSmallDevice && !isMenuExpanded ? 4 : isSmallDevice ? links.length : 0;

    const filteredMenuEntries = links
        .slice(0, maxNbOfEntries - 1)
        .concat(
            isSmallDevice && links.length >= maxNbOfEntries
                ? [links[links.length - 1]]
                : []
        );

    return (
        <Flex
            as="nav"
            transition="ease-in-out height 0.3s"
            zIndex={3}
            h={
                isMenuExpanded
                    ? `calc(80px * ${Math.ceil(links.length / 3) + 1} + 50px)`
                    : '64px'
            }
            bg="primary.main"
            aria-label={formatMessage(navbarAriaRoleMessage)}
            role="navigation"
            px="3"
            justifyContent="space-between"
            position="sticky"
            flexDir={isSmallDevice ? 'row' : 'column'}
            {...(isSmallDevice
                ? navStyleProps.small
                : { ...navStyleProps.large, w: width })}>
            <MenuLinksLayout
                logoPath={logoPath}
                isExpanded={isMenuExpanded}
                setIsExpanded={setIsMenuExpanded}
                showMenuButton={
                    links.length > maxNbOfEntries && !isMenuExpanded
                }>
                {filteredMenuEntries.map((link, id) => (
                    <MenuLink
                        key={link.label}
                        {...{
                            link,
                            id,
                            setCurrentFocus,
                            handleKeyDown,
                            setIsMenuExpanded,
                            isMenuExpanded,
                            resetFilters,
                            intlMessages,
                        }}
                    />
                ))}
            </MenuLinksLayout>

            {!isSmallDevice && (
                <Flex justifySelf="flex-end">
                    <MenuLink
                        {...{
                            link: links[links.length - 1],
                            id: links.length,
                            setCurrentFocus,
                            handleKeyDown,
                            setIsMenuExpanded,
                            isMenuExpanded,
                            resetFilters,
                            intlMessages,
                        }}
                    />
                </Flex>
            )}
        </Flex>
    );
}
