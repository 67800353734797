import { Icon, IconProps } from '@chakra-ui/react';

export default function CheckCircleExtendedIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 27 22.5" {...props}>
            <circle
                cx="10.5"
                cy="12"
                r="9.5"
                fill="white"
                stroke="#507C27"
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27 2.94585L11.604 19L3 10.0282L5.82508 7.08231L11.604 13.1083L24.1749 0L27 2.94585Z"
                fill="#82C247"
            />
        </Icon>
    );
}
