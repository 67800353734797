import { Icon, IconProps } from '@chakra-ui/react';

export default function CreditCardNormalIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M4.817 5.984h14.277c.527 0 .954.444.954.992v.109H3.863v-.109c0-.548.427-.992.954-.992zm-.954 5.087v5.953c0 .547.427.992.954.992h14.277c.527 0 .954-.444.954-.992V11.07H3.863zM1.955 6.976C1.955 5.333 3.236 4 4.817 4h14.277c1.58 0 2.861 1.333 2.861 2.976v10.048c0 1.643-1.28 2.976-2.861 2.976H4.817c-1.58 0-2.862-1.333-2.862-2.976V6.976z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
