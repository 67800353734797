import { Text, TextProps } from '@chakra-ui/react';
import {
    FormLinks,
    FormLinksType,
    SafeFormattedMessage,
    formMessages,
} from 'core';

type ConvertLinksToJsxProps = {
    links: FormLinksType;
    textProps?: TextProps;
};

function convertLinksToJsx({ links, textProps }: ConvertLinksToJsxProps) {
    return Object.keys(links).reduce((acc, key) => {
        const element = links[key];
        const emptyLink = element.url === '';
        const isExternalLink: boolean =
            element.url.startsWith('http://') ||
            element.url.startsWith('https://') ||
            emptyLink;
        acc[key] = (
            <a
                key={key}
                href={!emptyLink ? element.url : null}
                target={isExternalLink ? '_blank' : '_self'}
                rel="noopener noreferrer">
                <Text
                    as="span"
                    textDecoration={!emptyLink ? 'underline' : 'none'}
                    {...textProps}>
                    <SafeFormattedMessage
                        {...formMessages[element.linkKey]}
                        debugKey={element.linkKey}
                    />
                </Text>
            </a>
        );

        return acc;
    }, {});
}

type ExtractLinksProps = {
    data: FormLinks;
    keyToFind: string;
    textProps?: TextProps;
};

function extractLinks({ data, keyToFind, textProps }: ExtractLinksProps) {
    const linksArray =
        data && keyToFind && data[keyToFind] ? data[keyToFind] : [];
    const fakeLinkstoAdd = 5 - linksArray.length;
    const fakeLinks = new Array(fakeLinkstoAdd).fill({
        url: '',
        linkKey: '',
    });

    const links = {};
    linksArray.concat(fakeLinks).forEach((item, index) => {
        const linkKey = `link${index + 1}`;
        links[linkKey] = item;
    });

    return convertLinksToJsx({ links, textProps });
}

export default extractLinks;
