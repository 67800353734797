/**
 * A more pragmatic check for the object type than `#isObject` of lodash.
 *
 * For exemple the lodash `#isObject` returns true when `[]` is given.
 * This function returns true only when `{}` is given, and false for `[]`
 */
function isStrictObject(value: any) {
    return Object.prototype.toString.call(value) === '[object Object]';
}

export { isStrictObject };
