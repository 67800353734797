import { defineMessages } from 'react-intl';

export const satisfactionMessages = {
    store: defineMessages({
        title: {
            id: 'components.satisfaction.app.store.title',
            defaultMessage: 'Merci pour votre retour !',
        },
        description: {
            id: 'components.satisfaction.app.store.description',
            defaultMessage:
                "Nous nous efforçons de faire évoluer l'application {appName} régulièrement pour améliorer votre expérience. <br></br> N’hésitez pas à partager votre retour sur le store.",
        },
    }),
    'comment.end': defineMessages({
        title: {
            id: 'components.satisfaction.app.comment.end.title',
            defaultMessage: 'Votre avis a bien été envoyé',
        },
        description: {
            id: 'components.satisfaction.app.comment.end.description',
            defaultMessage:
                "Nous vous remercions pour votre retour, il nous aidera à faire évoluer l'application {appName}.",
        },
    }),
};
