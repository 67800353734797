import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';
import {
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    SystemProps,
} from '@chakra-ui/react';
import type { WidgetInputProps, WidgetProps } from './widgets/types';
import {
    SafeFormattedMessage,
    formMessages,
    FormHelperIcon,
    OptionalIndicator,
    UtilsContext,
} from 'core';
import { LongText } from 'design-system/components';
import extractLinks from '../../lib/forms/helpers/extractLinks';

type InputFieldProps = React.PropsWithChildren<
    Pick<WidgetProps, 'id' | 'help' | 'hideLabel' | 'links'> & {
        name: string;
        label?: WidgetInputProps['title'];
        labelFontSize?: SystemProps['fontSize'];
        description?: WidgetInputProps['description'];
        isRequired?: boolean;
        isReadOnly?: boolean;
        isInvalid?: boolean;
        showErrors?: boolean;
    }
>;

export function FormGroup({
    hideLabel,
    id,
    links,
    label,
    labelFontSize = 'md',
    description,
    children,
    name,
    isRequired,
    isReadOnly,
    isInvalid,
    help,
    showErrors = true,
}: InputFieldProps) {
    const { siteConfig } = React.useContext(UtilsContext);
    const { appName } = siteConfig;
    const {
        formState: { errors },
    } = useFormContext();
    const controlIsInvalid = isInvalid ?? Boolean(get(errors, name));
    const labelStyles = hideLabel
        ? { position: 'absolute', left: '-99999' }
        : undefined;

    const _renderDescription = () => {
        return (
            <LongText noOfLines={4}>
                <FormHelperText data-testid="formgroup-description">
                    <SafeFormattedMessage
                        {...formMessages[description]}
                        debugKey={description}
                        values={{
                            appName,
                            ...extractLinks({
                                data: links,
                                keyToFind: description,
                            }),
                        }}
                    />
                </FormHelperText>
            </LongText>
        );
    };

    return (
        <FormControl
            isInvalid={controlIsInvalid}
            {...{ id, isReadOnly, isRequired }}>
            {label && (
                <HStack mb="2">
                    <FormLabel
                        m="0"
                        sx={labelStyles}
                        fontSize={labelFontSize}
                        data-testid="formgroup-label">
                        <SafeFormattedMessage
                            {...formMessages?.[label]}
                            debugKey={label}
                        />
                    </FormLabel>

                    {help && !hideLabel && (
                        <FormHelperIcon data-testid="formgroup-help">
                            <SafeFormattedMessage
                                {...formMessages[help]}
                                debugKey={help}
                            />
                        </FormHelperIcon>
                    )}

                    {isRequired === false && !isReadOnly && (
                        <OptionalIndicator ml="2" mr={help ? '2' : '0'} />
                    )}
                </HStack>
            )}

            {description && _renderDescription()}

            {children}

            {showErrors && (
                <ErrorMessage
                    name={name}
                    errors={errors}
                    render={({ message }) => (
                        <FormErrorMessage data-testid="error">
                            {message}
                        </FormErrorMessage>
                    )}
                />
            )}
        </FormControl>
    );
}
