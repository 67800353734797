import { Icon, IconProps } from '@chakra-ui/react';

export default function PaletteIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M16.5 12a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM13 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM7.5 13a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M22 12c0 4-2.5 8-8 5 0 0-1.75-1.275-3 0-.888.906-.668 2.123-.21 3.13.357.786-.165 1.814-1.008 1.623C5.327 20.744 2 16.761 2 12 2 6.477 6.477 2 12 2s10 4.477 10 10zM4 12a8.002 8.002 0 0 0 4.437 7.166c-.152-1.119.024-2.433 1.135-3.566 1.314-1.34 2.921-1.277 3.915-1.01a5.452 5.452 0 0 1 1.518.676l.034.022c2.233 1.194 3.25.723 3.722.319C19.457 15.01 20 13.69 20 12a8 8 0 1 0-16 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
