import { Icon, IconProps } from '@chakra-ui/react';

export default function ArrowBottomIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M11 15.939V6a1 1 0 1 1 2 0v9.939l3.596-2.833a1 1 0 1 1 1.237 1.571l-5.214 4.108a1 1 0 0 1-1.238 0l-5.214-4.108a1 1 0 0 1 1.237-1.57L11 15.938z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
