import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { AccountContact } from '../types';
import { APIError, FetchQueryParams } from 'core';

const accountContactPath = `/api/proxy/security/user/account/contact`;

async function fetchAccountContact({ queryKey: [urn] }: FetchQueryParams) {
    try {
        const response = await axios.get(urn);
        return response.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useAccountContact() {
    return useQuery<AccountContact, APIError>({
        queryKey: [accountContactPath],
        queryFn: fetchAccountContact,
    });
}
