import { Icon, IconProps } from '@chakra-ui/react';

export default function QuestionCircleIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M14.2508 9.63387C14.0907 8.70719 13.3325 7.94899 12.4058 7.78893C11.7235 7.66256 11.0411 7.8479 10.5272 8.28597C10.0049 8.72404 9.71002 9.36429 9.71002 10.0382C9.71002 10.3584 9.97118 10.6111 10.2829 10.6111C10.603 10.6111 10.8557 10.3584 10.8557 10.0382C10.8557 9.70127 11.0074 9.38114 11.2601 9.16211C11.5213 8.94307 11.8582 8.85883 12.2037 8.9178C12.6501 8.99362 13.0377 9.38114 13.1135 9.82764C13.2314 10.4763 12.8102 10.9734 12.3469 11.125C11.7909 11.3019 11.4202 11.8242 11.4202 12.4308V13.1637C11.4202 13.4838 11.6813 13.7366 11.993 13.7366C12.3132 13.7366 12.5659 13.4838 12.5659 13.1637V12.4308C12.5659 12.3297 12.6249 12.2454 12.7007 12.2202C13.8043 11.8663 14.453 10.7796 14.2508 9.63387Z"
                fill="currentColor"
            />
            <path
                d="M11.2769 15.5255C11.2769 15.1295 11.5971 14.801 12.0014 14.801C12.4058 14.801 12.7344 15.1295 12.7259 15.5255C12.7259 15.9214 12.4058 16.25 12.0014 16.25C11.6055 16.25 11.2769 15.9298 11.2769 15.5255Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
                fill="currentColor"
            />
        </Icon>
    );
}
