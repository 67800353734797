import { Icon, IconProps } from '@chakra-ui/react';

export default function CrossIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="m12.364 13.778 4.243 4.243a1 1 0 0 0 1.414-1.414l-4.243-4.243 4.243-4.243a1 1 0 0 0-1.414-1.414l-4.243 4.243-4.243-4.243a1 1 0 1 0-1.414 1.414l4.243 4.243-4.243 4.243a1 1 0 1 0 1.414 1.414l4.243-4.243z"
                fill="currentColor"
            />
        </Icon>
    );
}
