import { arrayToObject } from 'core';
import { formatFormFinalObject } from './handlers';

function transformMultiTreatments(inputId: string, data: string) {
    const parsedData = JSON.parse(data).map((treatments, position) => {
        const values = treatments.map((treatment) => ({
            [treatment.id]: treatment,
        }));

        return {
            id: `treatment${position + 1}`,
            ...arrayToObject(values),
        };
    });

    return formatFormFinalObject(inputId, { value: parsedData });
}

export default transformMultiTreatments;
