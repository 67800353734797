import { Icon, IconProps } from '@chakra-ui/react';

export default function LockOpenIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M11.5 2a4.502 4.502 0 0 0-4.39 3.508c-.122.539.338.992.89.992s.981-.465 1.197-.974A2.5 2.5 0 0 1 14 6.5V9H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-9a2 2 0 0 0-2-2h-1V6.5A4.5 4.5 0 0 0 11.5 2zM6 11v9h11v-9H6z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
