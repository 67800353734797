import { validationFormsMessages, Validations, FormatMessageFn } from 'core';

function validateForbiddenCharacters(
    forbiddenCharacters: Validations['forbiddenCharacters'],
    formatMessage: FormatMessageFn
) {
    return (value: string = '') => {
        if (!forbiddenCharacters) return undefined;

        const containsChars = forbiddenCharacters.map(
            (forbiddenChar) => value.indexOf(forbiddenChar) !== -1
        );

        return containsChars.indexOf(true) !== -1
            ? formatMessage(validationFormsMessages.forbiddenCharacters, {
                  forbiddenCharacters: forbiddenCharacters.join(', '),
              })
            : undefined;
    };
}

export { validateForbiddenCharacters };
