import type { Theme } from './types';

export * from './get-theme';
export * from './theme-provider';
export * from './utils';

// @deprecated - This is used for components created with emotion.
// If we only use chakra, this type will be useless.
export type WithTheme<T = unknown> = T & { theme: Theme };

export { useTheme } from '@chakra-ui/system';
