import React, { MouseEvent } from 'react';
import { Td, Tr } from '@chakra-ui/react';
import { Row, flexRender } from '@tanstack/react-table';

type DesktopRowProps<T> = {
    row: Row<T>;
    onClickHandler: (event: MouseEvent<unknown>, row: T) => void;
};

export function DesktopRow<T>({ row, onClickHandler }: DesktopRowProps<T>) {
    return (
        <Tr
            cursor={'pointer'}
            _hover={{
                bg: 'gray.50',
            }}
            sx={{
                '&:hover a': {
                    color: 'primary.main',
                    textDecor: 'underline',
                },
            }}
            onClick={(e) => onClickHandler(e, row.original)}>
            {row.getVisibleCells().map((cell) => (
                <Td
                    key={cell.id}
                    sx={{
                        width: cell.column.getSize(),
                    }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
            ))}
        </Tr>
    );
}
