import axios from 'axios';
import { AdherentUser } from 'core/lib/auth';

const path = `/api/proxy/security/user/account/selfcare-campaigns/[type]`;

export default async function closeCampaignCard(
    campaignType: AdherentUser.CampaignTypeEnum,
    campaignId: string | number
) {
    try {
        return axios.put(
            path.replace('[type]', campaignType),
            { id: campaignId },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    } catch (error) {
        throw new Error(error);
    }
}
