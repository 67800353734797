import React from 'react';
import { isValid } from './isValid';
import { SimpleGrid } from '@chakra-ui/react';
import { Validation } from './Validation';
import { arrayToObject, Validations } from 'core';

type PasswordIndicationsType = {
    inputRef: React.MutableRefObject<any>;
    validations: Validations;
};

function cleanValidations(validations: Validations) {
    const shallowValidations = validations;
    for (const prop in shallowValidations) {
        if (
            shallowValidations[prop] === null ||
            shallowValidations[prop].length === 0 ||
            prop === 'required'
        ) {
            delete shallowValidations[prop];
        }
    }
    return shallowValidations;
}

function initValidations(validations: Validations) {
    return arrayToObject(
        Object.keys(validations).map((key) => ({
            [key]: false,
        }))
    );
}

function PasswordIndications(props: PasswordIndicationsType) {
    const { inputRef } = props;
    const cleanedValidations = cleanValidations(props.validations);

    const [validations, setValidations] = React.useState<
        Record<string, boolean>
    >(() => initValidations(props.validations));

    React.useEffect(() => {
        const input = inputRef.current;
        if (!input) return;

        function callback({ currentTarget }) {
            setValidations(isValid(currentTarget.value, cleanedValidations));
        }
        input.addEventListener('input', callback);
        return () => input.removeEventListener('input', callback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cleanedValidations, inputRef.current]);

    return (
        <SimpleGrid py="3" role="data-testid-passwordIndications">
            <SimpleGrid columns={2}>
                {validations &&
                    Object.keys(validations).map(
                        (validation: keyof Validations) => (
                            <Validation
                                name={validation}
                                isValid={validations[validation]}
                                noOf={props.validations[validation]}
                                key={validation}
                            />
                        )
                    )}
            </SimpleGrid>
        </SimpleGrid>
    );
}

export { PasswordIndications };
