import { Box, HStack, Text, BoxProps } from '@chakra-ui/react';
import { WarningIcon } from 'design-system/icons';
import {
    defineMessages,
    FormattedMessage,
    MessageDescriptor,
} from 'react-intl';

type DigitalSignatureProps = {
    name?: string;
    boxProps?: BoxProps;
    title?: MessageDescriptor;
    description?: MessageDescriptor;
    error?: MessageDescriptor;
};

const intlMessages = defineMessages({
    title: {
        id: 'workflow-signature.signature.signature-block.title',
        defaultMessage: 'Signature',
    },
    description: {
        id: 'workflow-signature.signature.signature-block.description',
        defaultMessage: 'Cette signature sera apposée sur le document.',
    },
    error: {
        id: 'errors.workflow-signature.signature.no-preview',
        defaultMessage: 'Nous ne parvenons pas à afficher la signature.',
    },
});

export const DigitalSignature = ({
    name,
    boxProps = {},
    title = intlMessages.title,
    description = intlMessages.description,
    error = intlMessages.error,
}: DigitalSignatureProps) => (
    <Box bgColor="bg.light" px="6" py="4" mt="8" {...boxProps}>
        <Text as="h3" fontSize="2xl" fontWeight="semibold" fontFamily="mono">
            <FormattedMessage {...title} />
        </Text>

        {name ? (
            <>
                <Text>
                    <FormattedMessage {...description} />
                </Text>
                <Box bgColor="white" borderRadius="md" py="2" mt="2">
                    <Text
                        fontFamily="preview"
                        textAlign="center"
                        fontSize="3xl"
                        color="primary.main">
                        {name}
                    </Text>
                </Box>
            </>
        ) : (
            <HStack
                p="2"
                bg="red.50"
                borderRadius="sm"
                color="error.main"
                my="2">
                <WarningIcon w="8" h="8" mr="2" />
                <FormattedMessage {...error} />
            </HStack>
        )}
    </Box>
);
