import { MessageDescriptor } from 'react-intl';
import { Theme } from 'themes/types';
import { Amounts } from '../api/types';
import { manageChart } from './manageChart';

type ManageDoughnutDatasTypes = {
    name: MessageDescriptor;
    value: string;
    color: string;
    percentage: number;
};

type ManageDoughtnughtDataProps = {
    amounts: Amounts;
    hasCoInsurer?: boolean;
    theme: Theme;
};

function manageDoughnutDatas({
    amounts,
    hasCoInsurer,
    theme,
}: ManageDoughtnughtDataProps): ManageDoughnutDatasTypes[] {
    const { totalAmount } = amounts;
    return Object.keys(amounts).reduce((prev, curr) => {
        if (curr === 'errors' || curr === 'totalAmount') return prev;
        if (curr === 'otherInsuranceAmount' && Number(amounts[curr]) === 0)
            return prev;
        return [
            ...prev,
            {
                name: manageChart(curr, hasCoInsurer, theme).name,
                value: amounts[curr],
                color: manageChart(curr, hasCoInsurer, theme).color,
                percentage: Number(
                    ((amounts[curr] / Number(totalAmount)) * 100).toFixed(0)
                ),
            },
        ];
    }, [] as ManageDoughnutDatasTypes[]);
}

export { manageDoughnutDatas };
