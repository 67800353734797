import { benefitsMessages, consentModalMessages } from './i18n';
import EuropAssistanceLogo from 'public/images/networks/europ-assistance';
import MedicalibLogo from 'public/images/networks/medicalib';
import {
    ConsentModalConfigType,
    ConsentModalEnum,
    ConsentModalPartnershipNameEnum,
} from './types';
import {
    TextExchangeIllustration,
    PreventionIllustration,
    VideoInterventionIllustration,
    HomeInterventionIllustration,
} from 'components/Illustrations';

const CONSENT_TYPE_TO_ILLUSTRATION = {
    [ConsentModalEnum.HOME_CONSULTATION]: HomeInterventionIllustration,
    [ConsentModalEnum.IMMEDIATE_EXCHANGE]: VideoInterventionIllustration,
    [ConsentModalEnum.APPOINTMENT]: VideoInterventionIllustration,
    [ConsentModalEnum.PREVENTION]: PreventionIllustration,
    [ConsentModalEnum.WRITTEN_EXCHANGE]: TextExchangeIllustration,
};

export const consentModalConfig: ConsentModalConfigType = {
    [ConsentModalEnum.HOME_CONSULTATION]: {
        illustration:
            CONSENT_TYPE_TO_ILLUSTRATION[ConsentModalEnum.HOME_CONSULTATION],
        title: consentModalMessages[ConsentModalEnum.HOME_CONSULTATION].title,
        description:
            consentModalMessages[ConsentModalEnum.HOME_CONSULTATION]
                .description,
        benefits: Object.values(
            benefitsMessages[ConsentModalEnum.HOME_CONSULTATION]
        ),
        partnershipName: ConsentModalPartnershipNameEnum.MEDICALIB,
        partnershipIcon: MedicalibLogo,
        partnershipDesc:
            consentModalMessages[ConsentModalEnum.HOME_CONSULTATION]
                .partnership,
        consents: [
            consentModalMessages[ConsentModalEnum.HOME_CONSULTATION].agreement,
        ],
        workflowLink: '/home-consultation',
    },
    [ConsentModalEnum.IMMEDIATE_EXCHANGE]: {
        illustration:
            CONSENT_TYPE_TO_ILLUSTRATION[ConsentModalEnum.IMMEDIATE_EXCHANGE],
        title: consentModalMessages[ConsentModalEnum.IMMEDIATE_EXCHANGE].title,
        description:
            consentModalMessages[ConsentModalEnum.IMMEDIATE_EXCHANGE]
                .description,
        benefits: Object.values(
            benefitsMessages[ConsentModalEnum.IMMEDIATE_EXCHANGE]
        ),
        partnershipName: ConsentModalPartnershipNameEnum.EAF,
        partnershipIcon: EuropAssistanceLogo,
        partnershipDesc: consentModalMessages.shared.partnership,
        consents: [
            consentModalMessages.shared.agreement,
            consentModalMessages.shared.dataShare,
        ],
        workflowLink: {
            pathname: '/teleconsultation/[type]',
            query: {
                type: 'immediate-exchange',
            },
        },
        displayExclusions: true,
    },
    [ConsentModalEnum.IMMEDIATE_EXCHANGE_V2]: {
        illustration:
            CONSENT_TYPE_TO_ILLUSTRATION[ConsentModalEnum.IMMEDIATE_EXCHANGE],
        title: consentModalMessages[ConsentModalEnum.IMMEDIATE_EXCHANGE].title,
        description:
            consentModalMessages[ConsentModalEnum.IMMEDIATE_EXCHANGE]
                .description,
        benefits: Object.values(
            benefitsMessages[ConsentModalEnum.IMMEDIATE_EXCHANGE]
        ),
        partnershipName: ConsentModalPartnershipNameEnum.EAF,
        partnershipIcon: EuropAssistanceLogo,
        partnershipDesc: consentModalMessages.shared.partnership,
        consents: [
            consentModalMessages.shared.agreement,
            consentModalMessages.shared.dataShare,
        ],
        workflowLink: {
            pathname: '/teleconsultation/[type]',
            query: {
                type: 'immediate-exchange',
            },
        },
        displayExclusions: true,
    },
    [ConsentModalEnum.APPOINTMENT]: {
        illustration:
            CONSENT_TYPE_TO_ILLUSTRATION[ConsentModalEnum.APPOINTMENT],
        title: consentModalMessages[ConsentModalEnum.APPOINTMENT].title,
        description:
            consentModalMessages[ConsentModalEnum.APPOINTMENT].description,
        benefits: Object.values(benefitsMessages[ConsentModalEnum.APPOINTMENT]),
        partnershipName: ConsentModalPartnershipNameEnum.EAF,
        partnershipIcon: EuropAssistanceLogo,
        partnershipDesc: consentModalMessages.shared.partnership,
        consents: [
            consentModalMessages.shared.agreement,
            consentModalMessages.shared.dataShare,
        ],
        workflowLink: {
            pathname: '/teleconsultation/[type]',
            query: {
                type: 'appointment',
            },
        },
        displayExclusions: true,
    },
    [ConsentModalEnum.PREVENTION]: {
        illustration: CONSENT_TYPE_TO_ILLUSTRATION[ConsentModalEnum.PREVENTION],
        title: consentModalMessages[ConsentModalEnum.PREVENTION].title,
        description:
            consentModalMessages[ConsentModalEnum.PREVENTION].description,
        benefits: Object.values(benefitsMessages[ConsentModalEnum.PREVENTION]),
        partnershipName: ConsentModalPartnershipNameEnum.PREVENTION,
        partnershipIcon: EuropAssistanceLogo,
        partnershipDesc: consentModalMessages.shared.partnershipPreventions,
        consents: [consentModalMessages.shared.dataShare],
        workflowLink: '/prevention',
        displayExclusions: false,
    },
    [ConsentModalEnum.WRITTEN_EXCHANGE]: {
        illustration:
            CONSENT_TYPE_TO_ILLUSTRATION[ConsentModalEnum.WRITTEN_EXCHANGE],
        title: consentModalMessages[ConsentModalEnum.WRITTEN_EXCHANGE].title,
        description:
            consentModalMessages[ConsentModalEnum.WRITTEN_EXCHANGE].description,
        benefits: Object.values(
            benefitsMessages[ConsentModalEnum.WRITTEN_EXCHANGE]
        ),
        partnershipName: ConsentModalPartnershipNameEnum.EAF,
        partnershipIcon: EuropAssistanceLogo,
        partnershipDesc: consentModalMessages.shared.partnership,
        consents: [
            consentModalMessages[ConsentModalEnum.WRITTEN_EXCHANGE].agreement,
            consentModalMessages.shared.dataShare,
        ],
        workflowLink: {
            pathname: '/teleconsultation/[type]',
            query: {
                type: 'text-message-exchange',
            },
        },
        displayExclusions: true,
    },
    [ConsentModalEnum.WRITTEN_EXCHANGE_V2]: {
        illustration:
            CONSENT_TYPE_TO_ILLUSTRATION[ConsentModalEnum.WRITTEN_EXCHANGE],
        title: consentModalMessages[ConsentModalEnum.WRITTEN_EXCHANGE].title,
        description:
            consentModalMessages[ConsentModalEnum.WRITTEN_EXCHANGE].description,
        benefits: Object.values(
            benefitsMessages[ConsentModalEnum.WRITTEN_EXCHANGE]
        ),
        partnershipName: ConsentModalPartnershipNameEnum.EAF,
        partnershipIcon: EuropAssistanceLogo,
        partnershipDesc: consentModalMessages.shared.partnership,
        consents: [
            consentModalMessages[ConsentModalEnum.WRITTEN_EXCHANGE].agreement,
            consentModalMessages.shared.dataShare,
        ],
        workflowLink: {
            pathname: '/teleconsultation/[type]',
            query: {
                type: 'text-message-exchange',
            },
        },
        displayExclusions: true,
    },
};
