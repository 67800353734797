import { coreErrors } from './errors';

export const defaultLayoutBoundaryErrorMessages = {
    400: {
        error: coreErrors.unavailableContentTitle,
        errorDescription: coreErrors.unavailableContentText,
    },
    403: {
        error: coreErrors.notFoundTitle,
        errorDescription: coreErrors.notFoundText,
    },
    404: {
        error: coreErrors.notFoundTitle,
        errorDescription: coreErrors.notFoundText,
    },
    412: {
        error: coreErrors.serviceUnavailableDescription,
        errorDescription: coreErrors.serviceUnavailable,
    },
    500: {
        error: coreErrors.unavailableContentTitle,
        errorDescription: coreErrors.unavailableContentText,
    },
    503: {
        error: coreErrors.maintenanceTitle,
        errorDescription: coreErrors.maintenanceDescription,
    },
};
