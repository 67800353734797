import type { FormValues } from 'core';
import { formatFormFinalObject } from './handlers';

function transformNumber(inputId: string, data: FormValues) {
    const parsedData = { value: data.value.replace(',', '.') };

    return formatFormFinalObject(inputId, parsedData);
}

export default transformNumber;
