import { useTheme } from '@chakra-ui/react';

function SatisfactionNeutralIcon() {
    const theme = useTheme();
    const colors = theme.colors;

    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
            <path
                d="M76.4325 60.6267C79.3917 68.7438 83.8563 74.5708 86.4044 73.6419C88.9526 72.7129 88.6195 65.3797 85.6603 57.2626C82.7011 49.1456 78.2365 43.3185 75.6884 44.2475C73.1402 45.1764 73.4734 52.5097 76.4325 60.6267Z"
                fill={colors.teal[500]}
            />
            <path
                d="M50.4777 86.5354C67.7569 86.5354 81.7645 72.5278 81.7645 55.2486C81.7645 37.9694 67.7569 23.9619 50.4777 23.9619C33.1985 23.9619 19.1909 37.9694 19.1909 55.2486C19.1909 72.5278 33.1985 86.5354 50.4777 86.5354Z"
                fill={colors.teal[500]}
            />
            <path
                d="M45.7255 79.8027H36.2207V96.8322H45.7255V79.8027Z"
                fill={colors.teal[500]}
            />
            <path
                d="M64.7353 79.8027H55.2305V96.8322H64.7353V79.8027Z"
                fill={colors.teal[500]}
            />
            <path
                d="M44.1414 100C48.5159 100 52.0621 98.6703 52.0621 97.0298C52.0621 95.3894 48.5159 94.0596 44.1414 94.0596C39.7669 94.0596 36.2207 95.3894 36.2207 97.0298C36.2207 98.6703 39.7669 100 44.1414 100Z"
                fill={colors.teal[500]}
            />
            <path
                d="M63.1512 99.6046C67.5257 99.6046 71.0719 98.2748 71.0719 96.6343C71.0719 94.9939 67.5257 93.6641 63.1512 93.6641C58.7767 93.6641 55.2305 94.9939 55.2305 96.6343C55.2305 98.2748 58.7767 99.6046 63.1512 99.6046Z"
                fill={colors.teal[500]}
            />
            <path
                d="M51.2701 58.0206C57.1756 58.0206 61.963 53.2332 61.963 47.3277C61.963 41.4221 57.1756 36.6348 51.2701 36.6348C45.3645 36.6348 40.5771 41.4221 40.5771 47.3277C40.5771 53.2332 45.3645 58.0206 51.2701 58.0206Z"
                fill="white"
            />
            <path
                d="M51.2704 50.8914C53.2389 50.8914 54.8347 49.2956 54.8347 47.3271C54.8347 45.3585 53.2389 43.7627 51.2704 43.7627C49.3019 43.7627 47.7061 45.3585 47.7061 47.3271C47.7061 49.2956 49.3019 50.8914 51.2704 50.8914Z"
                fill={colors.teal[800]}
            />
            <path
                d="M20.129 26.1521C17.6028 14.8384 25.6782 3.40636 38.1661 0.617945C50.6539 -2.17047 62.8252 4.74067 65.3515 16.0543C67.8777 27.368 59.6016 31.5407 47.1138 34.3291C34.6259 37.1176 22.6553 37.4659 20.129 26.1521Z"
                fill={colors.teal[800]}
            />
            <path
                d="M23.9042 48.6112C21.3422 47.7213 16.9673 53.616 14.1327 61.7774C11.2981 69.9387 11.0772 77.2762 13.6393 78.1661C16.2013 79.056 20.5762 73.1613 23.4108 64.9999C26.2454 56.8385 26.4663 49.5011 23.9042 48.6112Z"
                fill={colors.teal[500]}
            />
            <path
                d="M35.1104 66.5348C35.1104 69.5969 42.9948 69.4995 51.7438 69.4995C60.4928 69.4995 68.5002 69.5969 68.5002 66.5348C68.5002 63.4727 60.4928 64.5546 51.7438 64.5546C42.9949 64.5546 35.1104 63.4727 35.1104 66.5348Z"
                fill="white"
            />
        </svg>
    );
}

export default SatisfactionNeutralIcon;
