import { Icon, IconProps } from '@chakra-ui/react';

export default function DisabledIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M10 8c0-.552.487-1 1.04-1 .552 0 .96.448.96 1v2h2c.552 0 1 .409 1 .96 0 .553-.448 1.04-1 1.04h-2v2h5a2 2 0 0 1 2 2v3h1a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1v-4h-6a1 1 0 0 1-1-1V8z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path d="M13 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" fill="currentColor" />
            <path
                fillRule="evenodd"
                d="M12.65 17c-.457 0-.844.316-1.041.727A4 4 0 1 1 8 12c.512 0 1-.373 1-.885v-.223c0-.467-.338-.874-.805-.889a6 6 0 1 0 5.412 8.136c.221-.578-.253-1.139-.872-1.139h-.086z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
