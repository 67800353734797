import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { FetchQueryParams, FormObject } from 'core';

const path = `/api/proxy/selfcare/subscription/bank-details/contributions`;

export async function fetchSignatureChangeRibForm({
    queryKey: [urn],
}: FetchQueryParams) {
    try {
        const response = await axios.options(urn);
        return {
            statusCode: response?.status,
            form: response.data.data,
        };
    } catch (error) {
        return {
            statusCode: error?.response?.status,
        };
    }
}

export function useSignatureChangeRibForm() {
    return useQuery<{
        statusCode: number;
        form?: FormObject;
    }>({
        queryKey: [path],
        queryFn: fetchSignatureChangeRibForm,
    });
}
