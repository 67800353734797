import { useState, useCallback, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $getSelection,
    $isRangeSelection,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_LOW,
    FORMAT_TEXT_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND,
} from 'lexical';
import {
    INSERT_UNORDERED_LIST_COMMAND,
    REMOVE_LIST_COMMAND,
    $isListNode,
} from '@lexical/list';
import { mergeRegister } from '@lexical/utils';
import { Button, Divider, HStack, IconButton } from '@chakra-ui/react';
import {
    ArrowClockwiseIcon,
    ArrowCounterClockwiseIcon,
    UnorderedListIcon,
} from 'design-system/icons';

const intlMessages = defineMessages({
    bold: {
        id: 'components.rich-text.bold',
        defaultMessage: 'Mettre en gras',
    },
    italic: {
        id: 'components.rich-text.italic',
        defaultMessage: 'Mettre en italique',
    },
    underline: {
        id: 'components.rich-text.underline',
        defaultMessage: 'Souligner',
    },
    list: {
        id: 'components.rich-text.bulleted-list',
        defaultMessage: 'Ajouter une liste à puces',
    },
    undo: {
        id: 'components.rich-text.undo',
        defaultMessage: 'Annuler',
    },
    redo: {
        id: 'components.rich-text.redo',
        defaultMessage: 'Rétablir',
    },
});

export default function ToolbarPlugin(): JSX.Element {
    const { formatMessage } = useIntl();

    const [editor] = useLexicalComposerContext();
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isList, setIsList] = useState(false);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                setIsList($isListNode(element));
            }

            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, _) => {
                    updateToolbar();
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                COMMAND_PRIORITY_LOW
            )
        );
    }, [editor, updateToolbar]);

    const handleListOnClick = () => {
        if (isList) editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        else editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    };

    return (
        <HStack
            p={2}
            border="1px solid"
            borderColor="strokes.medium"
            borderTopRadius="md"
            borderBottom="none"
            height="45px">
            <IconButton
                pt={3}
                pl={2}
                variant="ghost"
                onClick={() => {
                    editor.dispatchCommand(UNDO_COMMAND, undefined);
                }}
                isDisabled={!canUndo}
                icon={<ArrowCounterClockwiseIcon w={8} h={8} />}
                aria-label={formatMessage(intlMessages.undo)}
                title={formatMessage(intlMessages.undo)}
            />
            <IconButton
                pt={3}
                pl={2}
                variant="ghost"
                onClick={() => {
                    editor.dispatchCommand(REDO_COMMAND, undefined);
                }}
                isDisabled={!canRedo}
                icon={<ArrowClockwiseIcon w={8} h={8} />}
                aria-label={formatMessage(intlMessages.redo)}
                title={formatMessage(intlMessages.redo)}
            />

            <Divider orientation="vertical" />

            <Button
                variant="ghost"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                }}
                isActive={isBold}
                aria-label={formatMessage(intlMessages.bold)}
                title={formatMessage(intlMessages.bold)}
                _active={{
                    bg: 'gray.200',
                    color: 'blackAlpha.900',
                }}>
                {/* TODO Add aria-label */}
                <strong>G</strong>
            </Button>
            <Button
                variant="ghost"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                }}
                aria-label={formatMessage(intlMessages.italic)}
                title={formatMessage(intlMessages.italic)}
                isActive={isItalic}
                _active={{
                    bg: 'gray.200',
                    color: 'blackAlpha.900',
                }}>
                <em>I</em>
            </Button>
            <Button
                variant="ghost"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                }}
                aria-label={formatMessage(intlMessages.underline)}
                title={formatMessage(intlMessages.underline)}
                isActive={isUnderline}
                _active={{
                    bg: 'gray.200',
                    color: 'blackAlpha.900',
                }}>
                <u>S</u>
            </Button>

            <Divider orientation="vertical" />

            <IconButton
                variant="ghost"
                pt={3}
                pl={2}
                onClick={handleListOnClick}
                color={'gray'}
                icon={<UnorderedListIcon w={8} h={8} />}
                aria-label={formatMessage(intlMessages.list)}
                title={formatMessage(intlMessages.list)}
                isActive={isList}
                _active={{
                    bg: 'gray.200',
                    color: 'blackAlpha.900',
                }}
            />
        </HStack>
    );
}
