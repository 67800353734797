import { Icon, IconProps } from '@chakra-ui/react';

export default function SurgeryIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M16.65 3.076a2 2 0 0 1 2.662-.403l1.263.842a2 2 0 0 1 .352 3.028l-6.208 6.652-.012.012c-.235.235-.54.493-.829.737l-.19.161c-.364.309-.714.62-1.013.946-.624.68-.829 1.212-.705 1.707a1 1 0 0 1-.17.842c-.974 1.298-2.526 1.866-3.925 2.132C6.469 20 5.017 20 4.028 20H4c-.875 0-1.328-1.045-.73-1.684l7.476-7.974 5.904-7.266zm-4.972 9.197L6.382 17.92c.373-.034.75-.083 1.118-.153 1.046-.2 1.89-.544 2.441-1.076-.09-1.253.602-2.275 1.26-2.993.36-.393.756-.746 1.108-1.047l-.631-.38zm2.143-1.047-.818-.49 5.2-6.4 1.262.843-5.644 6.047zM14 21.25a.75.75 0 0 1-.75.75H2.75a.75.75 0 0 1 0-1.5h10.5a.75.75 0 0 1 .75.75zM18 21.25a.75.75 0 0 1-.75.75h-.5a.75.75 0 0 1 0-1.5h.5a.75.75 0 0 1 .75.75zM22 21.25a.75.75 0 0 1-.75.75h-.5a.75.75 0 0 1 0-1.5h.5a.75.75 0 0 1 .75.75z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
