import { Icon, IconProps } from '@chakra-ui/react';

export default function PatchworkIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M2 3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3zm2 6V4h2v5H4zM16 14a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-7zm2 6v-5h2v5h-2zM11 2a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H11zm1 2v5h8V4h-8zM2 14a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-7zm2 6v-5h8v5H4z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
