import { coreSharedMessages } from '../../shared/i18n';
import { defineMessages } from 'react-intl';

export const filterBadges = {
    userStatus: coreSharedMessages.status,
    ...defineMessages({
        contractCoveredRisk: {
            id: 'filters.category.covered-risks',
            defaultMessage: 'Risques couverts',
        },
    }),
};
