import { Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { Card, SafeFormattedMessage, WorkflowTypesEnum } from 'core';
import { ChevronRightIcon } from 'design-system/icons';
import {
    signatureBeneficiariesDesignationMessages as designationMessages,
    SignatureFormReason,
    useBeneficiariesDesignationReasons,
} from 'core/lib/signature';
import { useRouter } from 'next/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type SignatureBeneficiaryModalReasonsProps = {
    service: WorkflowTypesEnum;
    onReasonClick: (reason: SignatureFormReason) => void;
    onError: (errorCode: number) => void;
};

export default function SignatureBeneficiaryModalReasons({
    service,
    onReasonClick,
    onError,
}: SignatureBeneficiaryModalReasonsProps) {
    const router = useRouter();
    const type = router.query?.type as 'full-capital' | 'divided-capital';
    const procedureId = router.query?.procedureId as string;
    const { data: reasons, error } = useBeneficiariesDesignationReasons(
        service,
        type,
        procedureId
    );

    React.useEffect(() => {
        if (error) {
            onError(error?.code);
        }
    }, [error, onError]);

    return (
        <Stack spacing="4">
            <Stack>
                <Text fontWeight="bold">
                    <FormattedMessage
                        id="workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiary.beneficiary-type-title"
                        defaultMessage="Type de bénéficiaire"
                    />
                </Text>
                <Text fontSize="sm">
                    <FormattedMessage
                        id="workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiary.beneficiary-type-description"
                        defaultMessage="Afin d’éviter tout conflit ou restriction, nous vous conseillons de désigner vos bénéficiaires par leur qualité (ex : mon conjoint, mes enfants, mes héritiers…). Ceci vous évitera de réaliser une nouvelle désignation si votre situation change mais que la répartition souhaitée reste la même."
                    />
                </Text>
            </Stack>
            <Stack>
                {reasons?.map((reason) => (
                    <HStack
                        as={Card}
                        cursor="pointer"
                        px="5"
                        py="4"
                        key={reason.label}
                        justifyContent="space-between"
                        boxShadow="none"
                        border="1px solid"
                        borderColor="grey.300"
                        onClick={() => onReasonClick(reason)}>
                        <Stack spacing="1">
                            <Text fontWeight="semibold" lineHeight="shorter">
                                <SafeFormattedMessage
                                    {...designationMessages[reason.label].title}
                                    debugKey={`SignatureBeneficiaryModalReasons reason title - ${
                                        designationMessages[reason.label]?.title
                                    }`}
                                />
                            </Text>
                            <Text
                                color="texts.medium"
                                fontSize="sm"
                                fontStyle="italic">
                                <FormattedMessage
                                    {...designationMessages[reason.label]
                                        .description}
                                />
                            </Text>
                        </Stack>
                        <Flex
                            p="1.5"
                            bg="grey.200"
                            rounded="full"
                            alignItems="center"
                            justifyContent="center">
                            <ChevronRightIcon fontSize="xl" />
                        </Flex>
                    </HStack>
                ))}
            </Stack>
        </Stack>
    );
}
