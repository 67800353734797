import React from 'react';
import { useForkRef, CallbackRef, useInputValidation } from 'core';
import InputBase from '../InputBase';
import { WidgetProps } from './types';
import getInputName from 'core/lib/forms/helpers/getInputName';

type InputPasswordProps = Omit<WidgetProps, 'type'> & {
    showErrors?: boolean;
    hasPattern?: boolean;
};

function InputPassword(
    { validations, hasPattern, ...restProps }: InputPasswordProps,
    ref: React.Ref<HTMLInputElement>
) {
    const patternValidations = {
        ...(validations.numberOfRequiredCapitalLetters && {
            numberOfRequiredCapitalLetters:
                validations.numberOfRequiredCapitalLetters,
        }),
        ...(validations.numberOfRequiredNumbers && {
            numberOfRequiredNumbers: validations.numberOfRequiredNumbers,
        }),
        ...(validations.numberOfRequiredSpecialCharacters && {
            numberOfRequiredSpecialCharacters:
                validations.numberOfRequiredSpecialCharacters,
        }),
        ...(validations.lengthMin && {
            lengthMin: validations.lengthMin,
        }),
        ...(validations.sameAs && {
            sameAs: validations.sameAs,
        }),
    };
    const { registerValues } = useInputValidation(
        validations,
        getInputName(restProps.id, restProps.name),
        {
            type: 'password',
            ...(!hasPattern
                ? { sameAs: validations.sameAs }
                : { patternValidations }),
        }
    );
    const inputRef = useForkRef(registerValues.ref as CallbackRef, ref);

    return (
        <InputBase
            {...{ type: 'password', validations, ...restProps }}
            {...registerValues}
            ref={inputRef}
        />
    );
}

export default React.forwardRef(InputPassword);
