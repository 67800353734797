import { Icon, IconProps } from '@chakra-ui/react';

export default function CategoriesIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M12.616 3.436a1 1 0 0 0-.853 0L4.205 6.999a1 1 0 0 0 0 1.809l7.558 3.563a1 1 0 0 0 .853 0l7.558-3.563a1 1 0 0 0 0-1.81l-7.558-3.562zm-.427 2.01L6.976 7.903l5.213 2.458 5.213-2.458-5.213-2.457z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                d="M20.933 11.606a1 1 0 0 1-.478 1.33l-7.413 3.495a2 2 0 0 1-1.705 0l-7.413-3.494a1 1 0 1 1 .853-1.81l7.412 3.495 7.413-3.494a1 1 0 0 1 1.331.478z"
                fill="currentColor"
            />
            <path
                d="M20.455 17.065a1 1 0 1 0-.853-1.809l-7.413 3.495-7.412-3.495a1 1 0 0 0-.853 1.81l7.413 3.494a2 2 0 0 0 1.705 0l7.413-3.495z"
                fill="currentColor"
            />
        </Icon>
    );
}
