import { ChallengeStrategy, ChallengeType } from '..';
import * as Sentry from '@sentry/nextjs';

const strategyType: { [x: string]: ChallengeType } = {
    registration_strategy_email: 'email',
    registration_strategy_sms_1: 'sms',
    registration_strategy_sms_2: 'sms',
    mfa_strategy_email: 'email',
    mfa_strategy_sms_1: 'sms',
    mfa_strategy_sms_2: 'sms',
    registration_strategy_paper: 'paper',
    paper: 'paper',
    sms: 'sms',
    email: 'email',
};

function getChallengeType(strategy: ChallengeStrategy): ChallengeType {
    if (strategy in strategyType) {
        return strategyType[strategy];
    } else {
        Sentry.captureMessage(`Strategy ${strategy} not found`);
    }
    return null;
}

export { getChallengeType };
