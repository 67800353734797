import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import type { Product, PossibleProduct } from '../types';
import { SubscriptionType } from '..';

export async function fetchContract(
    path: string,
    contractType: string,
    id: string
) {
    try {
        const { data: response } = await axios.get(
            path.replace('[id]', id).replace('[contractType]', contractType)
        );
        const {
            data,
        }: Api<{
            type: string;
            products: Product[];
            possibleProducts: PossibleProduct[];
            subscriptionType: SubscriptionType;
            productsTotalErrors: number;
        }> = response;
        return {
            type: data?.type,
            products: data?.products,
            possibleProducts: data?.possibleProducts,
            subscriptionType: data?.subscriptionType,
            productsTotalErrors: data?.productsTotalErrors,
        };
    } catch (err) {
        throw new Error(err);
    }
}

export function useGuarantees(contractType: string, id: string) {
    const path = `/api/proxy/selfcare/[contractType]/contract/[id]`;
    return useQuery({
        queryKey: [path, contractType, id],

        queryFn: () => main.fetchContract(path, contractType, id),
    });
}

const main = {
    useGuarantees,
    fetchContract,
};

export default main;
