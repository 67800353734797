import { ReactElement } from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';

type StepItemsProps = {
    text: ReactElement;
    elementColor: string;
    textColor: string;
    isFinalStep?: boolean;
};

export default function StepItem({
    text,
    elementColor,
    textColor,
    isFinalStep,
}: StepItemsProps) {
    return (
        <HStack>
            {isFinalStep ? (
                <HStack w="8" justifyContent="center">
                    <Stack
                        height="6"
                        w="6"
                        bg={elementColor}
                        borderRadius="full"
                        alignItems="center"
                        justifyContent="center">
                        <CheckIcon
                            color="white"
                            fontSize="xx-small"
                            data-testid="check-icon"
                        />
                    </Stack>
                </HStack>
            ) : (
                <HStack w="8" justifyContent="center">
                    <Box
                        height="5"
                        w="1.5"
                        bg={elementColor}
                        borderRadius="2xl"
                        data-testid="step-bar"
                    />
                </HStack>
            )}
            <Text
                colorScheme="primary"
                alignSelf="flex-end"
                color={textColor}
                fontFamily="mono">
                {text}
            </Text>
        </HStack>
    );
}
