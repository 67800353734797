import React from 'react';
import axios from 'axios';
import { QueryStatus } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { ChallengeRouterQueries, ChallengeError, useRedirect } from 'core';

const errorCodeMessages = {
    400: 'REGISTRATION_CHALLENGE_UNKNOWN_ERROR',
    403: 'REGISTRATION_CHALLENGE_MISSING_USER',
    415: 'REGISTRATION_CHALLENGE_RECIPIENT_NOT_FOUND',
    429: 'REGISTRATION_CHALLENGE_TOO_MANY_REQUESTS',
    500: 'REGISTRATION_CHALLENGE_UNKNOWN_ERROR',
};

export function useRegistrationChallengePut(enabled: boolean) {
    const router = useRouter();
    const { redirect } = useRedirect();
    const { strategy } = router.query as ChallengeRouterQueries;
    const [status, setStatus] = React.useState<QueryStatus>('pending');
    const [error, setError] = React.useState<ChallengeError>();

    React.useEffect(() => {
        async function putStrategy() {
            try {
                setStatus('pending');
                await axios.put(`/api/registration-challenge`, {
                    strategy,
                });
                setStatus('success');
            } catch (err) {
                const {
                    response: { status },
                } = err;

                setStatus('error');

                switch (status) {
                    case 401:
                        redirect('/register');
                        break;
                    default:
                        setError({
                            code:
                                errorCodeMessages?.[status] ||
                                'REGISTRATION_CHALLENGE_UNKNOWN_ERROR',
                        });
                }
            }
        }

        if (enabled) {
            putStrategy();
        }

        // Nous savons que `useRedirect#redirect` ne peut pas changer
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strategy]);

    return { error, status };
}
