import { defineMessages } from 'react-intl';

export const forgotCredentialsReceiveChannelMessages = {
    password: {
        paper: defineMessages({
            title: {
                id: 'api.lost-credentials.password.paper.title',
                defaultMessage: 'Courrier en cours d’envoi',
            },
            description: {
                id: 'api.lost-credentials.password.paper.description',
                defaultMessage:
                    'Un courrier contenant un mot de passe provisoire va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion papier. Vous pourrez ainsi accéder à {appName}.',
            },
        }),
        email: defineMessages({
            title: {
                id: 'api.lost-credentials.password.email.title',
                defaultMessage: 'E-mail envoyé',
            },
            description: {
                id: 'api.lost-credentials.password.email.description',
                defaultMessage:
                    'Un e-mail contenant un lien pour réinitialiser votre mot de passe vous a été envoyé.',
            },
        }),
        'paper.email': defineMessages({
            title: {
                id: 'api.lost-credentials.password.paper-email.title',
                defaultMessage: 'Courrier et e-mail envoyés',
            },
            description: {
                id: 'api.lost-credentials.password.paper-email.description',
                defaultMessage:
                    '<p>Vous possédez actuellement deux comptes sur {appName}. </p><p><l>Pour l’un de ces comptes, un courrier contenant un mot de passe provisoire va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion papier.</l><l>Pour l’autre compte, un e-mail contenant un lien pour réinitialiser votre mot de passe vous a été envoyé.</l></p><p>Vous pourrez ainsi accéder à vos deux espaces {appName}.</p>',
            },
        }),
        'email.email': defineMessages({
            title: {
                id: 'api.lost-credentials.password.email-email.title',
                defaultMessage: 'E-mails envoyés',
            },
            description: {
                id: 'api.lost-credentials.password.email-email.description',
                defaultMessage:
                    '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Deux e-mails contenant des liens pour réinitialiser vos mots de passe vous ont été envoyés.</p>',
            },
        }),
        'paper.paper': defineMessages({
            title: {
                id: 'api.lost-credentials.password.paper-paper.title',
                defaultMessage: 'Courriers en cours d’envoi',
            },
            description: {
                id: 'api.lost-credentials.password.paper-paper.description',
                defaultMessage:
                    '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Deux courriers contenant des mots de passe provisoires vont vous être envoyés à l’adresse postale indiquée sur vos bulletins d’adhésion papier. Vous pourrez ainsi accéder à vos deux espaces {appName}.</p>',
            },
        }),
        paperSent: defineMessages({
            title: {
                id: 'api.lost-credentials.password.papersent.title',
                defaultMessage: 'Courrier déjà envoyé',
            },
            description: {
                id: 'api.lost-credentials.password.papersent.description',
                defaultMessage:
                    '<l>Un courrier contenant un mot de passe provisoire vous a déjà été envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion papier.</l><l> Ce mot de passe vous permettra d’accéder à {appName}.</l>',
            },
        }),
        'paperSent.paper': defineMessages({
            title: {
                id: 'api.lost-credentials.password.papersent-paper.title',
                defaultMessage:
                    'Courrier en cours d’envoi et courrier déjà envoyé',
            },
            description: {
                id: 'api.lost-credentials.password.papersent-paper.description',
                defaultMessage:
                    '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p><l>Pour l’un de ces comptes, un courrier contenant un mot de passe provisoire va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion papier. </l><l>Pour l’autre compte, ce courrier vous a déjà été envoyé.</l></p><p>Ces mots de passe vous permettront d’accéder à {appName}.</p>',
            },
        }),
        'paperSent.email': defineMessages({
            title: {
                id: 'api.lost-credentials.password.papersent-email.title',
                defaultMessage: 'E-mail envoyé et courrier déjà envoyé',
            },
            description: {
                id: 'api.lost-credentials.password.papersent-email.description',
                defaultMessage:
                    '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p><l>Pour l’un de ces comptes, un courrier contenant un mot de passe provisoire vous a déjà été envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion papier.</l><l>Pour l’autre compte, un e-mail contenant un lien pour réinitialiser votre mot de passe vous a été envoyé.</l></p><p>Vous pourrez ainsi accéder à vos deux espaces {appName}.</p>',
            },
        }),
        'paperSent.paperSent': defineMessages({
            title: {
                id: 'api.lost-credentials.password.papersent-papersent.title',
                defaultMessage: 'Courriers déjà envoyés',
            },
            description: {
                id: 'api.lost-credentials.password.papersent-papersent.description',
                defaultMessage:
                    '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Deux courriers contenant des mots de passe provisoires vous ont déjà été envoyés à l’adresse postale indiquée sur vos bulletins d’adhésion papier. Ces mots de passe vous permettront d’accéder à vos deux espaces {appName}.</p>',
            },
        }),
    },
    username: {
        paper: defineMessages({
            title: {
                id: 'api.lost-credentials.username.paper.title',
                defaultMessage: 'Identifiant envoyé par courrier',
            },
            description: {
                id: 'api.lost-credentials.username.paper.description',
                defaultMessage:
                    'Un courrier contenant votre identifiant et un mot de passe provisoire va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion papier. ',
            },
        }),
        email: defineMessages({
            title: {
                id: 'api.lost-credentials.username.email.title',
                defaultMessage: 'Identifiant envoyé par e-mail',
            },
            description: {
                id: 'api.lost-credentials.username.email.description',
                defaultMessage:
                    'Un e-mail contenant votre identifiant vous a été envoyé.',
            },
        }),
        'paper.email': defineMessages({
            title: {
                id: 'api.lost-credentials.username.paper-email.title',
                defaultMessage: 'Identifiants envoyés par e-mail et courrier',
            },
            description: {
                id: 'api.lost-credentials.username.paper-email.description',
                defaultMessage:
                    '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Pour l’un de ces comptes, un courrier contenant votre identifiant et un mot de passe provisoire va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion papier. </p><p>Pour l’autre compte, un e-mail contenant votre identifiant vous a été envoyé.</p>',
            },
        }),
        'email.email': defineMessages({
            title: {
                id: 'api.lost-credentials.username.email-email.title',
                defaultMessage: 'Identifiants envoyés par e-mail',
            },
            description: {
                id: 'api.lost-credentials.username.email-email.description',
                defaultMessage:
                    '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Deux e-mails contenant les identifiants de vos deux comptes vous ont été envoyés.</p>',
            },
        }),
        'paper.paper': defineMessages({
            title: {
                id: 'api.lost-credentials.username.paper-paper.title',
                defaultMessage: 'Identifiants envoyés par courrier',
            },
            description: {
                id: 'api.lost-credentials.username.paper-paper.description',
                defaultMessage:
                    '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Deux courriers contenant les identifiants de vos deux comptes ainsi que des mots de passe provisoires vont vous être envoyés à l’adresse postale indiquée sur vos bulletins d’adhésion papier.</p>',
            },
        }),
        paperSent: defineMessages({
            title: {
                id: 'api.lost-credentials.username.papersent.title',
                defaultMessage: 'Identifiant déjà envoyé par courrier',
            },
            description: {
                id: 'api.lost-credentials.username.papersent.description',
                defaultMessage:
                    'Un courrier contenant votre identifiant et un mot de passe provisoire vous a déjà été envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion papier.',
            },
        }),
        'paperSent.paper': defineMessages({
            title: {
                id: 'api.lost-credentials.username.papersent-paper.title',
                defaultMessage:
                    'Courrier en cours d’envoi et courrier déjà envoyé',
            },
            description: {
                id: 'api.lost-credentials.username.papersent-paper.description',
                defaultMessage:
                    '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Pour l’un de ces comptes, un courrier contenant votre identifiant et un mot de passe provisoire va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion papier.</p><p>Pour l’autre compte, ce courrier vous a déjà été envoyé.</p>',
            },
        }),
        'paperSent.email': defineMessages({
            title: {
                id: 'api.lost-credentials.username.papersent-email.title',
                defaultMessage: 'E-mail envoyé et courrier déjà envoyé',
            },
            description: {
                id: 'api.lost-credentials.username.papersent-email.description',
                defaultMessage:
                    '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Pour l’un de ces comptes, un courrier contenant votre identifiant et un mot de passe provisoire vous a été envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion papier.</p><p>Pour l’autre compte, un e-mail contenant votre identifiant vous a été envoyé.</p>',
            },
        }),
        'paperSent.paperSent': defineMessages({
            title: {
                id: 'api.lost-credentials.username.papersent-papersent.title',
                defaultMessage: 'Courriers déjà envoyés',
            },
            description: {
                id: 'api.lost-credentials.username.papersent-papersent.description',
                defaultMessage:
                    '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Deux courriers contenant les identifiants de vos deux comptes ainsi que des mots de passe provisoires vous ont déjà été envoyés à l’adresse postale indiquée sur vos bulletins d’adhésion papier.</p>',
            },
        }),
    },
};

export const forgotCredentialsMessages = defineMessages({
    'user.login.lost.paper.title': {
        id: 'api.user.login.lost.paper.title',
        defaultMessage: 'Identifiant envoyé par courrier',
    },
    'user.login.lost.paper.description': {
        id: 'api.user.login.lost.paper.description',
        defaultMessage:
            'Un courrier contenant votre identifiant va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion.',
    },
    'user.login.lost..email.title': {
        id: 'api.user.login.lost.email.title',
        defaultMessage: 'Identifiant envoyé par e-mail',
    },
    'user.login.lost.email.description': {
        id: 'api.user.login.lost.email.description',
        defaultMessage:
            'Un e-mail contenant votre identifiant vient de vous être envoyé.',
    },
    'user.login.lost.email.paper.title': {
        id: 'api.user.login.lost.email.paper.title',
        defaultMessage: 'Identifiants envoyés par e-mail et courrier',
    },
    'user.login.lost.email.paper.description': {
        id: 'api.user.login.lost.email.paper.description',
        defaultMessage:
            '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Pour l’un de ces comptes, un courrier contenant votre identifiant va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion. </p><p>Pour l’autre compte, un e-mail contenant votre identifiant vient de vous être envoyé.</p>',
    },
    'user.login.lost.email.email.title': {
        id: 'api.user.login.lost.email.email.title',
        defaultMessage: 'Identifiants envoyés par e-mail',
    },
    'user.login.lost.email.email.description': {
        id: 'api.user.login.lost.email.email.description',
        defaultMessage:
            '<l>Vous possédez actuellement deux comptes sur {appName}.</l><l>Deux e-mails contenant les identifiants de vos deux comptes vous ont été envoyés.</l>',
    },
    'user.login.lost.paper.paper.title': {
        id: 'api.user.login.lost.paper.paper.title',
        defaultMessage: 'Identifiants envoyés par courrier',
    },
    'user.login.lost.paper.paper.description': {
        id: 'api.user.login.lost.paper.paper.description',
        defaultMessage:
            '<l>Vous possédez actuellement deux comptes sur {appName}.</l><l>Deux courriers contenant les identifiants de vos deux comptes vont vous être envoyés à l’adresse postale indiquée sur vos bulletins d’adhésion.</l>',
    },
    'user.login.lost.papersent.title': {
        id: 'api.user.login.lost.papersent.title',
        defaultMessage: 'Identifiant déjà envoyé par courrier ',
    },
    'user.login.lost.papersent.description': {
        id: 'api.user.login.lost.papersent.description',
        defaultMessage:
            'Un courrier contenant votre identifiant vous a déjà été envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion.',
    },
    'user.login.lost.email.papersent.title': {
        id: 'api.user.login.lost.email.papersent.title',
        defaultMessage: 'Email envoyé et courrier déjà envoyé',
    },
    'user.login.lost.email.papersent.description': {
        id: 'api.user.login.lost.email.papersent.description',
        defaultMessage:
            '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Pour l’un de ces comptes, un courrier contenant votre identifiant vous a été envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion.</p><p>Pour l’autre compte, un e-mail contenant votre identifiant vient de vous être envoyé.</p>',
    },
    'user.login.lost.papersent.papersent.title': {
        id: 'api.user.login.lost.papersent.papersent.title',
        defaultMessage: 'Courriers déjà envoyés',
    },
    'user.login.lost.papersent.papersent.description': {
        id: 'api.user.login.lost.papersent.papersent.description',
        defaultMessage:
            '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Deux courriers contenant les identifiants de vos deux comptes vous ont été envoyés à l’adresse postale indiquée sur vos bulletins d’adhésion.</p>',
    },
    'user.password.request.basic.email.title': {
        id: 'api.forms.user.password.request.basic.email.title',
        defaultMessage: 'E-mail envoyé',
    },
    'user.password.request.basic.email.description': {
        id: 'api.forms.user.password.request.basic.email.description',
        defaultMessage:
            'Un e-mail contenant un lien pour réinitialiser votre mot de passe vient de vous être envoyé.',
    },
    'user.login.lost.paper.papersent.title': {
        id: 'api.forms.user.login.lost.paper.papersent.title',
        defaultMessage: 'Courrier en cours d’envoi et courrier déjà envoyé',
    },
    'user.login.lost.paper.papersent.description': {
        id: 'api.forms.user.login.lost.paper.papersent.description',
        defaultMessage:
            '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Pour l’un de ces comptes, un courrier contenant votre identifiant va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion.</p><p>Pour l’autre compte, ce courrier vous a déjà été envoyé.</p>',
    },

    'user​.password.request.advanced.paper.title': {
        id: 'api.forms.user​.password.request.advanced.paper.title',
        defaultMessage: 'Courrier en cours d’envoi',
    },
    'user​.password.request.advanced.paper.description': {
        id: 'api.forms.user​.password.request.advanced.paper.description',
        defaultMessage:
            'Un courrier contenant des codes d’accès provisoires va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion. Ces codes vous permettront d’accéder à {appName}.',
    },
    'user​.password.request.advanced.email.title': {
        id: 'api.forms.user​.password.request.advanced.email.title',
        defaultMessage: 'E-mail envoyé',
    },
    'user​.password.request.advanced.email.description': {
        id: 'api.forms.user​.password.request.advanced.email.description',
        defaultMessage:
            'Un e-mail contenant un lien pour réinitialiser votre mot de passe vient de vous être envoyé.',
    },
    'user​.password.request.advanced.paper.email.title': {
        id: 'api.forms.user​.password.request.advanced.paper.email.title',
        defaultMessage: 'Courrier et e-mail envoyés',
    },
    'user​.password.request.advanced.paper.email.description': {
        id: 'api.forms.user​.password.request.advanced.paper.email.description',
        defaultMessage:
            '<p>Vous possédez actuellement deux comptes sur {appName}. </p><p>Pour l’un de ces comptes, un courrier contenant des codes d’accès provisoires va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion. Ces codes vous permettront d’accéder à {appName}.</p><p>Pour l’autre compte, un e-mail contenant un lien pour réinitialiser votre mot de passe vient de vous être envoyé.</p>',
    },
    'user​.password.request.advanced.email.email.title': {
        id: 'api.forms.user​.password.request.advanced.email.email.title',
        defaultMessage: 'E-mails envoyés',
    },
    'user​.password.request.advanced.email.email.description': {
        id: 'api.forms.user​.password.request.advanced.email.email.description',
        defaultMessage:
            '<l>Vous possédez actuellement deux comptes sur {appName}.</l><l>Deux e-mails contenant des liens pour réinitialiser vos mots de passe vous ont été envoyés.</l>',
    },
    'user​.password.request.advanced.paper.paper.title': {
        id: 'api.forms.user​.password.request.advanced.paper.paper.title',
        defaultMessage: 'Courriers en cours d’envoi',
    },
    'user​.password.request.advanced.paper.paper.description': {
        id: 'api.forms.user​.password.request.advanced.paper.paper.description',
        defaultMessage:
            '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Deux courriers contenant des codes d’accès provisoires vont vous être envoyés à l’adresse postale indiquée sur vos bulletins d’adhésion. Ces codes vous permettront d’accéder à {appName}.</p>',
    },
    'user​.password.request.advanced.papersent.title': {
        id: 'api.forms.user​.password.request.advanced.papersent.title',
        defaultMessage: 'Courrier déjà envoyé',
    },
    'user​.password.request.advanced.papersent.description': {
        id: 'api.forms.user​.password.request.advanced.papersent.description',
        defaultMessage:
            'Un courrier contenant des codes d’accès provisoires vous a déjà été envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion. Ces codes vous permettront d’accéder à {appName}.',
    },
    'user​.password.request.advanced.paper.papersent.title': {
        id: 'api.forms.user​.password.request.advanced.paper.papersent.title',
        defaultMessage: 'Courrier en cours d’envoi et courrier déjà envoyé',
    },
    'user​.password.request.advanced.paper.papersent.description': {
        id: 'api.forms.user​.password.request.advanced.paper.papersent.description',
        defaultMessage:
            '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Pour l’un de ces comptes, un courrier contenant des codes d’accès provisoires va vous être envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion. Ces codes vous permettront d’accéder à {appName}.</p><p>Pour l’autre compte, ce courrier vous a déjà été envoyé.</p>',
    },
    'user​.password.request.advanced.email.papersent.title': {
        id: 'api.forms.user​.password.request.advanced.email.papersent.title',
        defaultMessage: 'E-mail envoyé et courrier déjà envoyé',
    },
    'user​.password.request.advanced.email.papersent.description': {
        id: 'api.forms.user​.password.request.advanced.email.papersent.description',
        defaultMessage:
            '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Pour l’un de ces comptes, un courrier contenant des codes d’accès provisoires vous a déjà été envoyé à l’adresse postale indiquée sur votre bulletin d’adhésion. Ces codes vous permettront d’accéder à {appName}.</p><p>Pour l’autre compte, un e-mail contenant un lien pour réinitialiser votre mot de passe vient de vous être envoyé.</p>',
    },
    'user​.password.request.advanced.papersent.papersent.title': {
        id: 'api.forms.user​.password.request.advanced.papersent.papersent.title',
        defaultMessage: 'Courriers déjà envoyés',
    },
    'user​.password.request.advanced.papersent.papersent.description': {
        id: 'api.forms.user​.password.request.advanced.papersent.papersent.description',
        defaultMessage:
            '<p>Vous possédez actuellement deux comptes sur {appName}.</p><p>Deux courriers contenant des codes d’accès provisoires vous ont déjà été envoyés à l’adresse postale indiquée sur vos bulletins d’adhésion. Ces codes vous permettront d’accéder à {appName}.</p>',
    },
});
