import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { uniqueId } from 'lodash';
import {
    AsYouType,
    CountryCode,
    validatePhoneNumberLength,
} from 'libphonenumber-js';
import parseMax from 'libphonenumber-js/max';
import { defineMessages, useIntl } from 'react-intl';
import {
    formatPhoneNumber,
    FormGroup,
    useInputValidation,
    Validations,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { WidgetProps } from '../types';
import {
    Box,
    FormControl,
    FormErrorMessage,
    HStack,
    Input,
    Select,
    Stack,
    VStack,
} from '@chakra-ui/react';
import { customizedScroll } from '../../../consts';
import { useRouter } from 'next/router';
import countryPhoneCodesFR from './countryPhoneCodes_FR';

export type InternationalPhoneNumberProps = Omit<WidgetProps, 'validations'> & {
    validations: Validations & {
        authorizedCountries: string[];
        unauthorizedCountries: string[];
        phoneType: 'mobile' | 'landline' | null;
    };
    defaultValue: string | null;
    defaultCountry: CountryCode | null;
};

export type Country = {
    name: string;
    dial_code: string;
    code: CountryCode;
};

export type InternationalPhone = {
    country: string;
    value: string;
};

const scrollbarStyle = {
    '::-webkit-scrollbar': {
        ...customizedScroll['::-webkit-scrollbar'],
        width: 2,
    },
    '::-webkit-scrollbar-thumb': {
        ...customizedScroll['::-webkit-scrollbar-thumb'],
        background: 'grey.600',
    },
    '::-webkit-scrollbar-track': {
        ...customizedScroll['::-webkit-scrollbar-track'],
        background: 'transparent',
    },
};

const errorsMessages = defineMessages({
    notMobilePhone: {
        id: 'errors.forms.after-sending.phone.not-mobile',
        defaultMessage:
            'Le numéro de téléphone saisi ne correspond pas à un téléphone portable ; veuillez vérifier votre saisie.',
    },
    notLandlinePhone: {
        id: 'errors.forms.after-sending.phone.not-landline',
        defaultMessage:
            'Le numéro de téléphone saisi ne correspond pas à un téléphone fixe ; veuillez vérifier votre saisie.',
    },
    incorrectFormat: {
        id: 'errors.forms.after-sending.phone.incorrect-format',
        defaultMessage:
            "Le numéro de téléphone saisi ne correspond pas au format attendu pour l'indicatif du pays sélectionné ; veuillez vérifier votre saisie.",
    },
});

enum PhoneTypesEnum {
    MOBILE = 'MOBILE',
    LANDLINE = 'FIXED_LINE',
}

const phoneTypeErrors = {
    mobile: errorsMessages.notMobilePhone,
    landline: errorsMessages.notLandlinePhone,
};

const MAX_CHAR = 15;

export default function InternationalPhoneNumber({
    id,
    title,
    description,
    help,
    validations,
    defaultValue,
    defaultCountry,
}: InternationalPhoneNumberProps) {
    const { authorizedCountries, unauthorizedCountries, phoneType, required } =
        validations;

    const { isSmallDevice } = useWindowBreakpoints();
    const { locale } = useRouter();

    const inputName = `${id}.value`;

    const {
        registerValues,
        formState: { errors },
        clearErrors,
        setValue,
    } = useInputValidation({ required }, inputName);

    const { formatMessage } = useIntl();
    //I've used a Set to avoid having duplicate errors
    const [customErrors, setCustomErrors] = useState<Set<string>>(
        new Set<string>([])
    );
    const [loadedCountries, setLoadedCountries] =
        useState<Country[]>(countryPhoneCodesFR);

    const supportedCountries = useMemo(() => {
        const truncatedCountries = loadedCountries.map((country) => {
            return country.name.length < MAX_CHAR
                ? country
                : { ...country, name: `${country.name.slice(0, MAX_CHAR)}...` };
        });

        if (authorizedCountries.length)
            return truncatedCountries.filter((codeCountry) =>
                authorizedCountries.includes(codeCountry.code)
            );

        if (unauthorizedCountries.length)
            return truncatedCountries.filter(
                (codeCountry) =>
                    !unauthorizedCountries.includes(codeCountry.code)
            );
        else return truncatedCountries;
    }, [loadedCountries, authorizedCountries, unauthorizedCountries]);

    const incorrectFormatError = useMemo(
        () => formatMessage(errorsMessages.incorrectFormat),
        [formatMessage]
    );

    const phoneTypeError = useMemo(
        () => (phoneType ? formatMessage(phoneTypeErrors[phoneType]) : null),
        [formatMessage, phoneType]
    );

    const initializePhoneNumber = (): string => {
        if (defaultCountry) {
            const selectedCountry = supportedCountries.find(
                (country) =>
                    country.code === defaultCountry ||
                    country.dial_code === defaultCountry
            );
            if (selectedCountry) {
                const dialCode = selectedCountry.dial_code;

                if (!defaultValue) return dialCode;

                const asYouType = new AsYouType(defaultCountry);
                asYouType.input(
                    defaultValue.includes(dialCode)
                        ? defaultValue
                        : `${dialCode}${defaultValue}`
                );

                return asYouType.getNumber().formatInternational();
            }
        }
        return supportedCountries[0].dial_code;
    };

    const initializeIndicator = (): Country => {
        return (
            supportedCountries.find(
                (countryCode) => countryCode.code === defaultCountry
            ) || supportedCountries[0]
        );
    };

    const [indicator, setIndicator] = useState<Country>(
        !!defaultCountry ? initializeIndicator() : supportedCountries[0]
    );

    const [phoneNumber, setPhoneNumber] = useState<string>(
        initializePhoneNumber()
    );
    useEffect(() => {
        if (locale === 'en-GB') {
            // Dynamic import only imports the file when it is explicitly required.
            import('./countryPhoneCodes_EN').then((module) =>
                setLoadedCountries(module.default)
            );
        }
    }, [locale]);

    const parsePhoneNumber = (phoneNumberValue: string): string => {
        if (
            phoneNumberValue &&
            phoneNumberValue.replace(indicator.dial_code, '')
        ) {
            const parsedNumber = parseMax(phoneNumberValue);
            return JSON.stringify({
                value: parsedNumber?.isValid()
                    ? parsedNumber.number
                    : formatPhoneNumber.compact(phoneNumberValue),

                country: indicator.code,
            });
        } else return '';
    };

    const handleCountryCodeOnChange = (
        event: ChangeEvent<HTMLSelectElement>
    ) => {
        const countryCode = supportedCountries.find(
            (code) => code.code === event.target.value
        );
        setIndicator(countryCode);
        const formattedValue = new AsYouType(indicator.code).input(
            countryCode.dial_code
        );
        setPhoneNumber(formattedValue);

        setValue(inputName, parsePhoneNumber(formattedValue), {
            shouldValidate: true,
        });
        setCustomErrors(new Set<string>([]));
    };

    const clearCustomError = (error: string): void => {
        setCustomErrors((prev) => {
            prev.delete(error);
            return new Set(prev);
        });
    };

    const addCustomError = (error: string): void => {
        setCustomErrors((prev) => new Set(prev.add(error)));
    };

    const handlePhoneNumberOnBlur = (): void => {
        const parsedNumber = parseMax(phoneNumber);

        if (parsedNumber === undefined) {
            return;
        }
        if (!parsedNumber || !parsedNumber.isValid()) {
            addCustomError(incorrectFormatError);
            return;
        }

        if (
            phoneType &&
            parsedNumber.getType() !== 'FIXED_LINE_OR_MOBILE' &&
            parsedNumber.getType() !== PhoneTypesEnum[phoneType.toUpperCase()]
        ) {
            addCustomError(phoneTypeError);
        }
    };

    const handleCustomErrors = (newNumber: string): void => {
        const parsedNumber = parseMax(newNumber);
        if (!parsedNumber) return;

        if (customErrors.has(incorrectFormatError)) {
            const isValidMax = parsedNumber.isValid();
            if (isValidMax) {
                clearCustomError(incorrectFormatError);
            }
        }

        if (customErrors.has(phoneTypeError)) {
            const isValidType =
                parsedNumber.getType() ===
                PhoneTypesEnum[phoneType.toUpperCase()];

            if (isValidType) {
                clearCustomError(phoneTypeError);
            }
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const newValue = formatPhoneNumber.compact(e.target.value);

        if (validatePhoneNumberLength(newValue, indicator.code) === 'TOO_LONG')
            return;

        if (newValue.startsWith(indicator.dial_code)) {
            const formattedValue = new AsYouType(indicator.code).input(
                newValue
            );
            setPhoneNumber(formattedValue);
            setValue(inputName, parsePhoneNumber(formattedValue), {
                shouldValidate: true,
            });
            if (errors[id]) clearErrors(id);
            handleCustomErrors(newValue);
        }
    };

    const _renderCountrySelect = (): JSX.Element => {
        return (
            <Select
                onChange={handleCountryCodeOnChange}
                w={isSmallDevice ? '100%' : '80'}
                height="44px"
                defaultValue={indicator.code}
                sx={{ ...scrollbarStyle }}>
                {supportedCountries?.map((country) => (
                    <option key={country?.code} value={country?.code}>
                        {country?.name}&nbsp;({country?.dial_code})
                    </option>
                ))}
            </Select>
        );
    };

    // Hidden input may not be needed any more with the new version of react-hook-form
    // I'll check during task[DTA-334]
    const _renderHiddenInput = (): JSX.Element => {
        return (
            <input
                {...registerValues}
                value={parsePhoneNumber(phoneNumber)}
                readOnly
                hidden
            />
        );
    };

    return (
        <Stack w="full" gap={isSmallDevice ? '4' : '2'}>
            <FormControl isInvalid={!!errors[id] || !!customErrors.size}>
                <FormGroup
                    name={inputName}
                    label={title}
                    description={description}
                    {...{ id, help, isRequired: required }}>
                    <HStack
                        w="100%"
                        alignItems="start"
                        flexDir={isSmallDevice ? 'column' : 'row'}
                        gap={isSmallDevice ? '4' : '2'}
                        flex="1">
                        <Box width={isSmallDevice ? '100%' : '80'}>
                            <FormGroup
                                id={'country'}
                                name={`${inputName}.country`}
                                isRequired={true}>
                                {_renderCountrySelect()}
                            </FormGroup>
                        </Box>
                        <FormGroup id={'tel'} name={`${inputName}.phoneNumber`}>
                            <Input
                                id="tel"
                                type="tel"
                                inputMode="tel"
                                autoComplete="tel-national"
                                value={phoneNumber}
                                onChange={handleInputChange}
                                onBlur={handlePhoneNumberOnBlur}
                            />
                        </FormGroup>
                        {_renderHiddenInput()}
                    </HStack>
                </FormGroup>
                {!errors[id] && !!customErrors.size && (
                    <VStack spacing={0} alignItems={'start'}>
                        {Array.from(customErrors).map((error) => (
                            <FormErrorMessage key={uniqueId()}>
                                {error}
                            </FormErrorMessage>
                        ))}
                    </VStack>
                )}
            </FormControl>
        </Stack>
    );
}
