import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Slide, Text } from '@chakra-ui/react';
import {
    coreSharedMessages,
    DashboardContext,
    FilterType,
    onFiltersSubmit,
    useFiltersForm,
    UtilsContext,
    handleFullReset,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import FiltersContent from './FiltersContent';
import {
    CoreModal,
    CoreModalContent,
    CoreModalHeader,
    CoreModalBody,
    CoreModalFooter,
} from 'design-system/components';

type FormModalProps = {
    isOpen: boolean;
    type: FilterType;
    methods: UseFormReturn<FieldValues>;
    searchWidgetId: string;
    id?: string;
};

export default function FiltersModal({
    isOpen,
    type,
    methods,
    searchWidgetId,
    id,
}: FormModalProps) {
    const {
        layouts: { LayoutBoundary },
    } = useContext(UtilsContext);
    const { isMobile, isSmallDevice } = useWindowBreakpoints();
    const {
        setSelectedFilters,
        setIsFiltersOpened,
        shouldFiltersReset,
        setShouldFiltersReset,
    } = React.useContext(DashboardContext);
    const { status: filtersStatus, error: filtersError } = useFiltersForm(
        type,
        id
    );

    const onReset = React.useCallback(() => {
        handleFullReset(setIsFiltersOpened, setSelectedFilters, methods);
    }, [setIsFiltersOpened, setSelectedFilters, methods]);

    React.useEffect(() => {
        if (shouldFiltersReset) {
            setShouldFiltersReset(false);
            onReset();
        }
    }, [shouldFiltersReset, setShouldFiltersReset, onReset]);

    return (
        <CoreModal
            isOpen={true}
            onClose={() => setIsFiltersOpened(false)}
            scrollBehavior="inside"
            blockScrollOnMount={false}
            trapFocus={false}
            motionPreset="none"
            isAlwaysOpen>
            <Slide
                in={isOpen}
                direction={isSmallDevice ? 'bottom' : 'right'}
                style={{ zIndex: 1401, width: '100vw', height: '100vh' }}>
                <CoreModalContent
                    position="absolute"
                    {...(isSmallDevice
                        ? { bottom: 0, maxW: '100vw' }
                        : { right: 0, maxH: 'unset', h: '100vh' })}>
                    <CoreModalHeader>
                        <Text>
                            <FormattedMessage {...coreSharedMessages.filters} />
                        </Text>
                    </CoreModalHeader>
                    <CoreModalBody
                        mx="1"
                        py="4"
                        px={filtersStatus === 'error' && '1'}>
                        <LayoutBoundary
                            status={filtersStatus}
                            errorCode={filtersError?.code}
                            isSmallDisplay
                            size="md"
                            h="full"
                            px="0"
                            pb="0"
                            pt="2"
                            margin="0">
                            <FiltersContent
                                {...{
                                    type,
                                    error: filtersError,
                                    status: filtersStatus,
                                    resourceId: id,
                                    methods,
                                    searchWidgetId,
                                }}
                            />
                        </LayoutBoundary>
                    </CoreModalBody>

                    {filtersStatus === 'success' && (
                        <CoreModalFooter
                            justifyContent={'space-between'}
                            gap={2}>
                            <Button
                                onClick={onReset}
                                colorScheme="gray"
                                size={isMobile ? 'lg' : 'md'}
                                w={'full'}>
                                <FormattedMessage
                                    {...coreSharedMessages.buttonClear}
                                />
                            </Button>
                            <Button
                                colorScheme="primary"
                                size={isMobile ? 'lg' : 'md'}
                                w={'full'}
                                onClick={methods.handleSubmit(() =>
                                    onFiltersSubmit(
                                        methods.getValues,
                                        setSelectedFilters,
                                        setIsFiltersOpened
                                    )
                                )}>
                                <FormattedMessage
                                    {...coreSharedMessages.buttonApply}
                                />
                            </Button>
                        </CoreModalFooter>
                    )}
                </CoreModalContent>
            </Slide>
        </CoreModal>
    );
}
