import { uniqueId } from 'lodash';
import { HStack, Text, VStack } from '@chakra-ui/react';
import {
    CheckCircleExtendedIcon,
    CrossCircleExtendedIcon,
} from 'design-system/icons';
import { useWindowBreakpoints } from 'design-system/hooks';

export enum IconType {
    CHECKED = 'checked',
    CROSSED = 'crossed',
}

const iconTypeToIconComponent = {
    [IconType.CHECKED]: CheckCircleExtendedIcon,
    [IconType.CROSSED]: CrossCircleExtendedIcon,
};

type Props = {
    textList: string[];
    iconType: IconType;
};
export function ListIconText({ textList, iconType }: Props) {
    const { isMobile } = useWindowBreakpoints();
    const IconComponent = iconTypeToIconComponent[iconType];

    const _renderTextWithCheckIcon = (text: string) => {
        return (
            <HStack spacing="2" key={uniqueId()}>
                <IconComponent w={isMobile ? 6 : 8} h={isMobile ? 5 : 6} />
                <Text fontSize="sm" fontWeight="semibold">
                    {text}
                </Text>
            </HStack>
        );
    };

    return (
        <VStack alignItems={'flex-start'}>
            {textList?.map(_renderTextWithCheckIcon) || null}
        </VStack>
    );
}
