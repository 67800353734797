import { Box, HStack, VStack } from '@chakra-ui/react';
import { Row, flexRender } from '@tanstack/react-table';
import { MouseEvent } from 'react';

type MobileRowProps<T> = {
    row: Row<T>;
    columnConfig: number[];
    onClickHandler: (event: MouseEvent<unknown>, row: T) => void;
};

export function MobileRow<T>({
    row,
    columnConfig,
    onClickHandler,
}: MobileRowProps<T>) {
    const cells = row.getVisibleCells();

    const chunkCells = (arr: typeof cells, config: number[]) => {
        const chunked = [];
        let index = 0;
        config.forEach((size) => {
            chunked.push(arr.slice(index, index + size));
            index += size;
        });
        return chunked;
    };

    const chunkedCells = chunkCells(cells, columnConfig);

    return (
        <VStack
            gap={5}
            alignItems="start"
            p={4}
            width="100%"
            bg={'white'}
            border={'1px solid'}
            borderColor={'gray.100'}
            onClick={(e) => onClickHandler(e, row.original)}>
            {chunkedCells.map((cellGroup, idx) => (
                <HStack justifyContent="space-between" w="100%" key={idx}>
                    {cellGroup.map((cell) => (
                        <Box key={cell.id}>
                            {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                            )}
                        </Box>
                    ))}
                </HStack>
            ))}
        </VStack>
    );
}
