import { Icon } from '@chakra-ui/react';

export function GeolocIllustration() {
    return (
        <Icon as="svg" width="75" height="75" viewBox="0 0 75 75" fill="none">
            <g clipPath="url(#clip0_11447_13910)">
                <path
                    d="M67.364 49.0292L64.0665 47.192L63.6291 46.9482L63.4689 46.8595L61.1874 45.5882L60.8987 45.4274L56.5939 43.0295L54.9803 42.1305L49.8519 39.2739L49.8485 39.2722L49.4745 39.0641L49.1402 38.878L46.7417 37.5416L46.2588 37.2725L43.3434 35.6479L42.6467 35.2601H42.6461L41.0141 34.3507L40.5594 34.0983C40.2153 33.9064 39.8263 33.7543 39.4103 33.6407C39.102 33.5572 38.7792 33.4949 38.449 33.4546C37.059 33.2846 35.5451 33.499 34.4698 34.0983L24.949 39.4007L24.5543 39.6208L24.1036 39.8721L22.2192 40.9221L21.6746 41.2252L20.6459 40.6777H20.6454L21.674 41.2263L16.9658 43.849H16.9652L15.3505 44.7486H15.3499L9.54443 47.9821H9.54385L7.99423 48.8459L7.63694 49.0453C7.53782 49.1001 7.44561 49.1571 7.35859 49.2159C7.14652 49.3588 6.96903 49.5127 6.82611 49.6746C6.02911 50.5725 6.29996 51.6945 7.63694 52.4391L13.3813 55.6357L29.7097 64.7214L29.9984 64.8827L32.2822 66.1534L32.4424 66.2422L32.8804 66.4865L33.8964 67.0519L34.4698 67.3705C35.9837 68.2136 38.3678 68.2926 40.0482 67.6109C40.2269 67.5382 40.3974 67.4576 40.5588 67.3671L40.7663 67.2513L42.9636 66.0261L44.1427 65.3685L49.3102 62.4877L50.9215 61.5887L55.3243 59.1337L58.2005 57.5294L58.5947 57.3098L67.3634 52.4206C69.0358 51.4876 69.0358 49.9622 67.3634 49.0304L67.364 49.0292Z"
                    fill="#072F35"
                />
                <path
                    d="M45.3522 47.2975L35.9726 53.0995C35.4044 53.451 34.6915 53.4718 34.1043 53.1542L19.5538 45.2983H19.5532L18.381 44.6673L9.54431 39.8957L15.3498 36.6622H15.3504L16.9651 35.7626H16.9657L21.6739 33.1399L30.9405 38.0712H30.9411L32.1196 38.6982L41.4623 43.6704L45.3003 45.7133C45.9233 46.0452 45.9521 46.9269 45.3522 47.2986V47.2975Z"
                    fill="#FFBD92"
                />
                <path
                    d="M61.1868 37.503L51.7779 43.3229L50.4985 44.1147C49.837 44.5233 49.0083 44.5509 48.3219 44.185L38.7545 39.0935C38.0854 38.7368 38.0503 37.7906 38.6917 37.3854L49.1402 30.7922L49.4745 30.9783L49.8485 31.1864L49.8519 31.1881L54.9803 34.0447L56.5939 34.9437L60.8987 37.3417L61.1874 37.5024L61.1868 37.503Z"
                    fill="#FFE6D4"
                />
                <path
                    d="M67.3633 44.3336L58.5946 49.2227L58.2004 49.4423L54.254 47.3423C53.4195 46.898 53.3809 45.7161 54.1848 45.2193L64.0658 39.1067L67.3633 40.9439C69.0356 41.8757 69.0356 43.4011 67.3633 44.3341V44.3336Z"
                    fill="#FFBD92"
                />
                <path
                    d="M55.3243 51.046L50.9215 53.5009L49.3103 54.3999L44.1427 57.2808L42.9637 57.9383L39.1498 55.8792C38.6283 55.5974 38.6058 54.8563 39.1106 54.544L48.7046 48.6089C49.2889 48.2481 50.0208 48.2245 50.6271 48.5466L55.3243 51.046Z"
                    fill="#FFE6D4"
                />
                <path
                    d="M40.0477 59.5229C38.3672 60.2047 35.9832 60.1257 34.4693 59.2826L33.8959 58.9639L32.8799 58.3986L34.3673 57.4789C34.913 57.1406 35.5976 57.1204 36.1624 57.4253L40.0477 59.5229Z"
                    fill="#FFE6D4"
                />
                <path
                    d="M31.1048 56.1104L29.9989 56.7951L29.7102 56.6337L13.3818 47.548L7.63742 44.3514C6.30045 43.6074 6.0296 42.4848 6.8266 41.587L31.0627 54.6703C31.6258 54.9746 31.6488 55.7727 31.1054 56.1099L31.1048 56.1104Z"
                    fill="#FFBD92"
                />
                <path
                    d="M46.2582 29.1871L35.3607 36.0615C34.7101 36.473 33.8883 36.503 33.2089 36.1411L26.3863 32.5105L24.5537 31.5348L24.9485 31.3147L34.4692 26.0123C35.5446 25.413 37.0585 25.1986 38.4485 25.3686C38.7781 25.409 39.1014 25.4712 39.4097 25.5548C39.8258 25.6683 40.2148 25.8204 40.5588 26.0123L41.0129 26.2647L42.6449 27.1741H42.6455L43.3422 27.562L46.2577 29.1865L46.2582 29.1871Z"
                    fill="#FFE6D4"
                />
                <path
                    d="M54.2548 47.3419L58.2012 49.4419L55.325 51.0463L50.6277 48.5469C50.0214 48.2242 49.2895 48.2478 48.7052 48.6092L39.1112 54.5443C38.6064 54.8561 38.6289 55.5972 39.1504 55.8795L42.9643 57.9386L40.7669 59.1638L40.5594 59.2796C40.3981 59.3695 40.2275 59.4508 40.0489 59.5234L36.1636 57.4257C35.5988 57.1208 34.9142 57.141 34.3684 57.4793L32.8811 58.399L32.4431 58.1547L32.2829 58.0659L29.9991 56.7952L31.105 56.1106C31.649 55.7735 31.6253 54.9753 31.0623 54.6711L6.82617 41.5877C6.96909 41.4258 7.14658 41.2719 7.35866 41.129C7.44568 41.0702 7.5373 41.0132 7.637 40.9584L7.9943 40.759L9.54392 39.8952H9.5445L18.3812 44.6668L19.5534 45.2978H19.5539L34.1045 53.1537C34.6923 53.4712 35.4052 53.4499 35.9728 53.099L45.3524 47.297C45.9523 46.9253 45.9235 46.0435 45.3005 45.7116L41.4625 43.6687L32.1198 38.6965L30.9413 38.0695H30.9407L21.6741 33.1383H21.6747L22.2193 32.8346L24.1037 31.7846L24.5543 31.5333L26.3869 32.509L33.2095 36.1396C33.889 36.5015 34.7102 36.4715 35.3614 36.06L46.2589 29.1856L46.7418 29.4547L49.1403 30.7911L38.6917 37.3843C38.0497 37.7889 38.0855 38.7357 38.7545 39.0924L48.322 44.1839C49.0083 44.5492 49.837 44.5222 50.4986 44.1136L51.7779 43.3218L61.1869 37.5019L63.4684 38.7732L63.6286 38.8619L64.066 39.1057L54.1851 45.2183C53.3811 45.7151 53.4198 46.897 54.2542 47.3413L54.2548 47.3419Z"
                    fill="#FFBD92"
                />
                <path
                    d="M54.1856 45.2189L64.0665 39.1063L63.6291 38.8625L63.4689 38.7737L61.1874 37.5025L51.7784 43.3223L50.4991 44.1141C49.8375 44.5227 49.0088 44.5504 48.3225 44.1845L38.755 39.093C38.0859 38.7363 38.0508 37.79 38.6922 37.3849L49.1408 30.7917L46.7423 29.4553L46.2594 29.1861L35.3619 36.0606C34.7112 36.4721 33.8895 36.502 33.21 36.1401L26.3874 32.5096L24.5548 31.5339L24.1042 31.7852L22.2197 32.8352L21.6752 33.1383L20.6465 32.5902H20.6459L21.6746 33.1389L30.9412 38.0701H30.9418L32.1203 38.6971L41.463 43.6693L45.301 45.7122C45.924 46.0441 45.9528 46.9258 45.3529 47.2975L35.9733 53.0995C35.4051 53.4511 34.6922 53.4718 34.105 53.1543L19.5544 45.2984H19.5539L18.3817 44.6674L9.54498 39.8958H9.54441L7.99478 40.7596L7.63749 40.959C7.53837 41.0137 7.44616 41.0708 7.35915 41.1296C7.14707 41.2725 6.96958 41.4264 6.82666 41.5883L31.0628 54.6716C31.6258 54.9759 31.6489 55.7741 31.1054 56.1112L29.9996 56.7958L32.2834 58.0665L32.4436 58.1553L32.8815 58.3996L34.3689 57.4799C34.9147 57.1416 35.5993 57.1214 36.164 57.4263L40.0493 59.5239C40.228 59.4513 40.3986 59.3707 40.5599 59.2802L40.7674 59.1643L42.9648 57.9392L39.1509 55.8801C38.6294 55.5983 38.6069 54.8572 39.1117 54.5449L48.7057 48.6097C49.29 48.249 50.0219 48.2253 50.6282 48.5475L55.3254 51.0468L58.2017 49.4425L54.2553 47.3425C53.4208 46.8982 53.3822 45.7162 54.1861 45.2195L54.1856 45.2189Z"
                    fill="white"
                />
                <path
                    d="M40.048 35.6702C39.4953 35.6702 38.9761 35.4253 38.6246 34.9989C35.6665 31.4109 28.7869 22.5333 28.7869 17.5905C28.7869 11.3811 33.8386 6.32941 40.048 6.32941C46.2574 6.32941 51.3091 11.3811 51.3091 17.5905C51.3091 22.5333 44.4295 31.4109 41.4714 34.9989C41.1199 35.4253 40.6007 35.6702 40.048 35.6702ZM40.048 14.6625C39.2447 14.6625 38.4949 14.9817 37.9377 15.5609C37.3804 16.14 37.0911 16.9025 37.1222 17.7075C37.181 19.226 38.4171 20.4593 39.9356 20.5163C39.9737 20.5175 40.0105 20.5186 40.0486 20.5186C41.6633 20.5186 42.9767 19.2053 42.9767 17.5905C42.9767 15.9758 41.6633 14.6625 40.0486 14.6625H40.048Z"
                    fill="white"
                />
                <path
                    d="M40.0479 7.04572C34.224 7.04572 29.5031 11.7672 29.5031 17.5905C29.5031 22.3858 36.6518 31.4801 39.1771 34.543C39.6289 35.0905 40.4674 35.0905 40.9186 34.543C43.4439 31.4801 50.5927 22.3858 50.5927 17.5905C50.5927 11.7672 45.8718 7.04572 40.0479 7.04572ZM39.9084 21.2326C38.0199 21.1623 36.4795 19.6248 36.4063 17.7363C36.3257 15.6583 37.9871 13.9467 40.0479 13.9467C42.1087 13.9467 43.6923 15.5793 43.6923 17.5911C43.6923 19.6029 41.9848 21.3104 39.9084 21.2326Z"
                    fill="#072F35"
                />
                <path
                    d="M40.0479 38.1685C42.484 38.1685 44.4588 37.5898 44.4588 36.8759C44.4588 36.1621 42.484 35.5833 40.0479 35.5833C37.6119 35.5833 35.6371 36.1621 35.6371 36.8759C35.6371 37.5898 37.6119 38.1685 40.0479 38.1685Z"
                    fill="#072F35"
                />
            </g>
            <defs>
                <clipPath id="clip0_11447_13910">
                    <rect
                        width="62.2385"
                        height="61.7377"
                        fill="white"
                        transform="translate(6.38013 6.32941)"
                    />
                </clipPath>
            </defs>
        </Icon>
    );
}
