import dayjs from 'dayjs';

function isNewRefund(
    date: string,
    previousLoginDate: string | null | undefined
) {
    if (!previousLoginDate) {
        return false;
    }
    const oneMonthAgo = new Date().setMonth(new Date().getMonth() - 1);

    return (
        (dayjs(date).isAfter(dayjs(previousLoginDate)) &&
            (dayjs(date).isAfter(dayjs(oneMonthAgo)) ||
                dayjs(date).isSame(dayjs(oneMonthAgo))) &&
            dayjs(date).isBefore(dayjs())) ||
        dayjs(date).isSame(dayjs())
    );
}

export { isNewRefund };
