import { Icon, IconProps } from '@chakra-ui/react';

export default function GearIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-1.5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M10.78 2a2 2 0 0 0-1.94 1.515l-.32 1.28c-.43.208-.84.454-1.223.733L6.13 5.194a2 2 0 0 0-2.282.923L2.83 7.883a2 2 0 0 0 .342 2.437l.871.843a8.081 8.081 0 0 0 0 1.674l-.871.843a2 2 0 0 0-.342 2.437l1.02 1.766a2 2 0 0 0 2.281.922l1.166-.333a8 8 0 0 0 1.224.733l.32 1.28A2 2 0 0 0 10.78 22h2.438a2 2 0 0 0 1.94-1.515l.32-1.28c.431-.208.84-.454 1.224-.733l1.166.333a2 2 0 0 0 2.282-.922l1.019-1.766a2 2 0 0 0-.342-2.438l-.871-.842a8.1 8.1 0 0 0 0-1.674l.871-.843a2 2 0 0 0 .342-2.437l-1.02-1.766a2 2 0 0 0-2.281-.923l-1.166.334a8 8 0 0 0-1.224-.734l-.32-1.28A2 2 0 0 0 13.22 2h-2.438zm0 2h2.44l.56 2.246.472.19c.582.237 1.12.562 1.596.96l.406.34 2.165-.619 1.019 1.766-1.617 1.563.09.521a6.045 6.045 0 0 1 0 2.066l-.09.521 1.617 1.563-1.02 1.766-2.163-.62-.407.341a5.998 5.998 0 0 1-1.596.96l-.471.19L13.219 20h-2.438l-.562-2.246-.47-.19a5.998 5.998 0 0 1-1.597-.96l-.407-.34-2.164.619-1.019-1.766 1.617-1.563-.09-.521a6.044 6.044 0 0 1 0-2.066l.09-.521-1.617-1.563 1.02-1.766 2.163.62.407-.341a5.998 5.998 0 0 1 1.596-.96l.471-.19L10.781 4z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
