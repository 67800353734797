import { Icon, IconProps } from '@chakra-ui/react';

export default function AccidentIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 20 20" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.83331 6.66659C3.83331 6.20635 4.20641 5.83325 4.66665 5.83325H16.3333C16.7936 5.83325 17.1666 6.20635 17.1666 6.66659C17.1666 7.12682 16.7936 7.49992 16.3333 7.49992H4.66665C4.20641 7.49992 3.83331 7.12682 3.83331 6.66659ZM3.83331 9.99992C3.83331 9.53968 4.20641 9.16659 4.66665 9.16659H16.3333C16.7936 9.16659 17.1666 9.53968 17.1666 9.99992C17.1666 10.4602 16.7936 10.8333 16.3333 10.8333H4.66665C4.20641 10.8333 3.83331 10.4602 3.83331 9.99992ZM7.16665 12.4999C6.70641 12.4999 6.33331 12.873 6.33331 13.3333C6.33331 13.7935 6.70641 14.1666 7.16665 14.1666H16.3333C16.7935 14.1666 17.1666 13.7935 17.1666 13.3333C17.1666 12.873 16.7935 12.4999 16.3333 12.4999H7.16665Z"
                fill="currentColor"
            />
        </Icon>
    );
}
