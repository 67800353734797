import { Icon, IconProps } from '@chakra-ui/react';

export default function CompanyIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 16 16" {...props}>
            <path
                d="M5.99998 9.33317C5.63179 9.33317 5.33331 9.03469 5.33331 8.6665C5.33331 8.29831 5.63179 7.99984 5.99998 7.99984H7.33331C7.7015 7.99984 7.99998 8.29831 7.99998 8.6665C7.99998 9.03469 7.7015 9.33317 7.33331 9.33317H5.99998Z"
                fill="currentColor"
            />
            <path
                d="M7.33331 6.6665H5.99998C5.63179 6.6665 5.33331 6.36803 5.33331 5.99984C5.33331 5.63165 5.63179 5.33317 5.99998 5.33317H7.33331C7.7015 5.33317 7.99998 5.63165 7.99998 5.99984C7.99998 6.36803 7.7015 6.6665 7.33331 6.6665Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3333 13.3332H14C14.3682 13.3332 14.6666 13.6316 14.6666 13.9998C14.6666 14.368 14.3682 14.6665 14 14.6665H1.99998C1.63179 14.6665 1.33331 14.368 1.33331 13.9998C1.33331 13.6316 1.63179 13.3332 1.99998 13.3332H2.66665V3.33317C2.66665 3.15636 2.73688 2.98679 2.86191 2.86177C2.98693 2.73674 3.1565 2.6665 3.33331 2.6665H9.99998C10.1768 2.6665 10.3464 2.73674 10.4714 2.86177C10.5964 2.98679 10.6666 3.15636 10.6666 3.33317V6.6665H12.6666C12.8435 6.6665 13.013 6.73674 13.1381 6.86177C13.2631 6.98679 13.3333 7.15636 13.3333 7.33317V13.3332ZM10.6666 7.99984V13.3332H12V7.99984H10.6666ZM3.99998 3.99984V13.3332H9.33331V3.99984H3.99998Z"
                fill="currentColor"
            />
        </Icon>
    );
}
