import {
    Stack,
    InputGroup,
    Input,
    InputRightElement,
    Flex,
    VStack,
    Box,
    Text,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSafeIntl } from '../../../SafeIntl';
import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { SelectProps, Option } from '.';
import { useInputValidation, formMessages } from '../../../..';
import { ChevronTopIcon, ChevronBottomIcon } from 'design-system/icons';
import { customizedScroll } from '../../../consts';
import { FormGroup } from '../../FormGroup';
import { coreSharedMessages, handleKeyBoardEvents } from '../../../../lib';

function SearchableSelect(props: Omit<SelectProps, 'isSearchable'>) {
    const {
        id,
        options,
        hideLabel,
        defaultValue,
        validations,
        title,
        links,
        name,
        readonly,
        help,
        description,
    } = props;

    const inputName = name ?? `${id}.value`;
    const { registerValues, trigger, setValue } = useInputValidation(
        validations,
        inputName
    );
    const optionsContainerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const { formatMessage } = useIntl();
    const { safeFormatMessage } = useSafeIntl();
    const [query, setQuery] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
    const [focusedOption, setFocusedOption] = useState<number>(-1);

    const getTranslatedOptions = () => {
        return options.map((option) =>
            option.isTranslatable
                ? {
                      ...option,
                      label: safeFormatMessage(
                          formMessages[option.label],
                          null,
                          `<SearchableSelect /> ${option.label}`
                      ),
                  }
                : option
        );
    };

    const [translatedOptions] = useState<Option[]>(getTranslatedOptions);

    useEffect(() => {
        setFocusedOption(-1);
    }, [isOpen]);

    // Trigger manual validation when the input's value has been updated.
    useEffect(() => {
        if (selectedOption !== '') {
            trigger(inputName);
        }
    }, [trigger, selectedOption, inputName]);

    useEffect(() => {
        setFilteredOptions(translatedOptions);
    }, [translatedOptions]);

    useEffect(() => {
        const selectedItem = translatedOptions.find(
            ({ id }) => id === defaultValue
        );
        if (!selectedItem) return;

        setQuery(selectedItem.label);
        setSelectedOption(defaultValue);
        setValue(inputName, defaultValue, {
            shouldValidate: true,
        });
        setFocusedOption(translatedOptions.indexOf(selectedItem));
    }, [defaultValue, translatedOptions]);

    useEffect(() => {
        if (!optionsContainerRef.current) return;

        optionsContainerRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        });
    }, [focusedOption]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        if (!value.trim()) {
            setQuery('');
            setFilteredOptions(translatedOptions);
            return;
        }
        setQuery(value);
        setFilteredOptions(
            translatedOptions.filter((option) =>
                option.label.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handleSelectChange = (option: Option): void => {
        if (!option) return;
        setQuery(option.label);
        setSelectedOption(option.id);
        setValue(inputName, option.id, {
            shouldValidate: true,
        });
        setFilteredOptions(translatedOptions);
        setIsOpen(false);
        inputRef.current.blur();
    };

    const _renderInput = (): JSX.Element => {
        return (
            <InputGroup>
                <Input
                    ref={inputRef}
                    placeholder={formatMessage(coreSharedMessages.search)}
                    value={query}
                    onChange={handleInputChange}
                    onKeyDown={(e) =>
                        handleKeyBoardEvents(
                            e,
                            filteredOptions,
                            focusedOption,
                            handleSelectChange,
                            setFocusedOption
                        )
                    }
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                    autoComplete="off"
                />
                <InputRightElement>
                    <Flex
                        px="2"
                        h="full"
                        alignItems="center"
                        cursor="pointer"
                        onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? <ChevronTopIcon /> : <ChevronBottomIcon />}
                    </Flex>
                </InputRightElement>
            </InputGroup>
        );
    };

    /**
     * Renders a hidden input that it is only used to store the id of selected
     * option then it can be retrieved by react-hook-form
     */
    const _renderHiddenInput = (): JSX.Element => {
        return (
            <input
                {...registerValues}
                value={selectedOption}
                required={validations.required}
                readOnly
                hidden
            />
        );
    };

    const _renderFilteredOptions = (): JSX.Element => {
        return (
            <>
                {filteredOptions.map((option, index) => (
                    <Box
                        ref={
                            focusedOption === index ? optionsContainerRef : null
                        }
                        key={option.id}
                        w="100%"
                        cursor="pointer"
                        px={3}
                        py={0.25}
                        bg={
                            (focusedOption === index ||
                                option.id === selectedOption) &&
                            'gray.100'
                        }
                        _hover={{ bg: 'gray.100' }}
                        // It's needed to use onMouseDown instead of onClick, bcs it's fired before onBlur of the input
                        onMouseDown={() => handleSelectChange(option)}>
                        {option.label}
                    </Box>
                ))}
            </>
        );
    };

    const _renderOptionsContainer = (): JSX.Element => {
        return (
            <VStack
                w="100%"
                maxH="150px"
                mt={-1}
                py={3}
                top="100%"
                bg="white"
                position="absolute"
                alignItems="start"
                overflowY="auto"
                border="1px solid"
                borderColor="strokes.medium"
                borderRadius={5}
                zIndex={999}
                sx={{ ...customizedScroll }}>
                {filteredOptions.length ? (
                    _renderFilteredOptions()
                ) : (
                    <Text pl={3} color="gray.800">
                        <FormattedMessage {...coreSharedMessages.noResult} />
                    </Text>
                )}
            </VStack>
        );
    };

    return (
        <FormGroup
            label={title}
            name={inputName}
            isRequired={validations.required}
            isReadOnly={readonly}
            {...{ id, description, hideLabel, help, links }}>
            <Stack
                spacing="1"
                bg="white"
                pb={'1'}
                rounded="md"
                position="relative">
                {_renderInput()}
                {_renderHiddenInput()}
                {isOpen && _renderOptionsContainer()}
            </Stack>
        </FormGroup>
    );
}

export default SearchableSelect;
