import { defineMessages } from 'react-intl';
import { validationFormsMessages } from '../validations';

export const formErrorMessages = {
    '11edd7eb-5872-4b6e-9f12-89923999fd0e':
        validationFormsMessages.minSelectedOptions,
    ...defineMessages({
        '3c363b3e-e2ab-11ed-9bd3-00155d7110d8': {
            id: 'errors.pe-admin.user-creation.at-least-one-link',
            defaultMessage:
                'Vous devez sélectionner au moins un environnement de gestion, un groupe ou une entreprise.',
        },
        '7f58e896-be5a-11ed-afa1-0242ac120002': {
            id: 'errors.pe-admin.group-creation.missing-environment',
            defaultMessage:
                'Avant de créer un groupe, vous devez au préalable créer un environnement de gestion.',
        },
        '45e8ba9d-4c1a-4bf4-b407-e4488332fdfd': {
            id: 'errors.pe-admin.company-creation.missing-environment-or-group',
            defaultMessage:
                'Avant de créer une entreprise, vous devez au préalable créer un environnement de gestion ou un groupe.',
        },
        '0d0473b0-31db-4055-8c28-cb744e368acd': {
            id: 'errors.unauthorized-access',
            defaultMessage: 'Accès non autorisé',
        },
        'ec01e4fd-d69f-4560-9afb-ac989a17b049': {
            id: 'errors.forms.validations.companies-or-groups.at-least-one-link',
            defaultMessage:
                'Vous devez sélectionner au moins un groupe ou une entreprise.',
        },
        'cf7a5d4e-cb18-4d30-85b1-1081f85ba826': {
            id: 'errors.legal-documents.document-deletion.existing-data',
            defaultMessage:
                'Le document n’a pas pu être supprimé car il a déjà été envoyé par mail à un ou plusieurs salariés.',
        },
        'd33edbbe-6751-40ca-b908-d025c3af2b6f': {
            id: 'errors.forms.after-sending.user-not-attached-to-all-structures',
            defaultMessage:
                "Vous ne pouvez pas modifier ce document car vous n'êtes pas rattachées à toutes les entreprises et/ou groupes.",
        },
        'aaaa6b02-87d5-47fe-8319-4d2bbd1efe33': {
            id: 'errors.forms.after-sending.document-already-sent-to-employee',
            defaultMessage:
                'Vous ne pouvez pas modifier ce document car il a déjà été envoyé par mail à au moins un salarié.',
        },
        '7ea8084e-0ca3-448e-94bf-090404c51b82': {
            id: 'errors.forms.after-sending.rich-text-nb-characters',
            defaultMessage:
                "Le nombre de caractères saisis dans l'éditeur de texte dépasse la limite autorisée.",
        },
    }),
};
