import { defineMessages } from 'react-intl';

export const tpCardStatusMessages = defineMessages({
    available: {
        id: 'date.validity-date',
        defaultMessage: 'Valide du {startDate} au {endDate}',
        description: 'Statut de la carte de tiers payant : Valide',
    },
    service_unavailable: {
        id: 'tp-card.status.service-unavailable',
        defaultMessage:
            'Service momentanément indisponible, veuillez réessayer plus tard.',
        description:
            'Statut de la carte de tiers payant : Service indisponible',
    },
    unavailable: {
        id: 'tp-card.status.unavailable',
        defaultMessage: 'Carte de tiers payant non disponible',
        description: 'Statut de la carte de tiers payant : Carte indisponible',
    },
    'health.tp-card.status.future_contract': {
        id: 'tp-card.status.future-contract',
        defaultMessage: 'Ouverture des droits prochainement',
        description: 'Statut de la carte de tiers payant : Droits futures',
    },
    future_contract: {
        id: 'tp-card.status.future-contract',
        defaultMessage: 'Ouverture des droits prochainement',
        description: 'Statut de la carte de tiers payant : Droits futures',
    },
    expired: {
        id: 'tp-card.status.expired',
        defaultMessage: 'Carte de tiers payant expirée',
        description:
            'Statut de la carte de tiers payant : Carte de tiers payant expirée',
    },
});

export const tpCardCare = defineMessages({
    PHAR: {
        id: 'tp-card.care.PHAR',
        defaultMessage: 'Pharmacie remboursable',
    },
    MED: {
        id: 'tp-card.care.MED',
        defaultMessage: 'Médecins généralistes et spécialistes',
    },
    RLAX: {
        id: 'tp-card.care.RLAX',
        defaultMessage: 'Laboratoires, Radiologues, Auxiliaires médicaux',
    },
    SAGE: {
        id: 'tp-card.care.SAGE',
        defaultMessage: 'Sages-femmes',
    },
    EXTE: {
        id: 'tp-card.care.EXTE',
        defaultMessage: 'Soins externes sauf prothèse dentaire',
    },
    CSTE: {
        id: 'tp-card.care.PCSTEHAR',
        defaultMessage: 'Centre de santé hors dentaire',
    },
    HOSP: {
        id: 'tp-card.care.HOSP',
        defaultMessage: 'Hospitalisation hors soins externes',
    },
    OPTI: {
        id: 'tp-card.care.OPTI',
        defaultMessage: 'Opticien',
    },
    DESO: {
        id: 'tp-card.care.DESO',
        defaultMessage: 'Soins dentaires',
    },
    DEPR: {
        id: 'tp-card.care.DEPR',
        defaultMessage: 'Prothèse dentaire',
    },
    AUDI: {
        id: 'tp-card.care.AUDI',
        defaultMessage: 'Audioprothèse',
    },
    DIV: {
        id: 'tp-card.care.DIV',
        defaultMessage: 'Transport sanitaire, Fournisseur',
    },
});

export const professionalNetworks = defineMessages({
    SP: {
        id: 'tp-card.professional-networks.SP',
        defaultMessage: 'SP Santé',
    },
    OC: {
        id: 'tp-card.professional-networks.OC',
        defaultMessage: 'Organisme Complémentaire',
    },
    CB: {
        id: 'tp-card.professional-networks.CB',
        defaultMessage: 'Carte Blanche',
    },
    IS: {
        id: 'tp-card.professional-networks.IS',
        defaultMessage: 'ISanté',
    },
    'SP/CB': {
        id: 'tp-card.professional-networks.SP-CB',
        defaultMessage: 'SP Santé / Carte Blanche',
    },
    'OC/CB': {
        id: 'tp-card.professional-networks.OC-CB',
        defaultMessage: 'Organisme Complémentaire / Carte Blanche',
    },
});
