import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { UtilsContext } from 'core';
import { IS_DEV_ENV } from 'core/consts';
import React from 'react';
import cookie from 'cookie';

const path = '/api/oauth2-redirect';

const clearCookieConfig: cookie.CookieSerializeOptions = {
    httpOnly: true,
    sameSite: 'lax',
    expires: new Date(0),
    secure: !IS_DEV_ENV,
    path: '/',
};

function readableAzureError(azureError?: string) {
    return azureError?.split(':')?.[0];
}

async function redirect(
    oAuthPath: string,
    redirectURI: string,
    state: string,
    code: string,
    azureError: string,
    hasRedirectError: boolean,
    oauth2login: boolean
) {
    const hasActionBeenCanceled =
        readableAzureError(azureError) === 'AADB2C90091';

    if (!oauth2login || hasRedirectError || hasActionBeenCanceled) return;

    try {
        const res = await axios.post(path, {
            azureError: hasActionBeenCanceled ? null : azureError,
            state: hasActionBeenCanceled || !code ? undefined : state,
            code,
            oAuthPath,
            redirectURI,
        });

        return {
            statusCode: res.status,
            errorCode: undefined,
        };
    } catch (err) {
        const { data: responseData, status: responseStatus } =
            err?.response || {};
        const { code, error: responseDataError, redirect } = responseData || {};
        const statusCode = err?.response?.status;

        if (redirect && statusCode !== 401) {
            window.location.href = redirect;
            return;
        }

        clearCookies();

        return {
            statusCode: responseStatus as number,
            errorCode:
                code ||
                (typeof responseDataError === 'string'
                    ? responseDataError
                    : (responseDataError?.response as AxiosResponse)),
        };
    }
}

async function clearCookies() {
    await axios.post('/api/set-cookie', {
        ...clearCookieConfig,
        name: 'oauth2_state',
        value: '',
    });

    await axios.post('/api/set-cookie', {
        ...clearCookieConfig,
        name: 'oauth2_code_verifier',
        value: '',
    });
}

export function useOAuth2Redirect(
    oAuthPath: string,
    redirectURI: string,
    state?: string,
    code?: string,
    azureError?: string,
    hasRedirectError?: boolean
) {
    const {
        siteConfig: {
            features: { oauth2login },
        },
    } = React.useContext(UtilsContext);

    return useQuery({
        queryKey: [path],

        queryFn: () =>
            redirect(
                oAuthPath,
                redirectURI,
                state,
                code,
                azureError,
                hasRedirectError,
                oauth2login
            ),
        staleTime: 0,
        gcTime: 0,
    });
}
