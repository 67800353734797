import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    LinkBox,
    LinkOverlay,
    Stack,
    Text,
} from '@chakra-ui/react';
import { Heading, LinkIntl } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { WorkflowTypesEnum, formMessages, purifyHTML } from '../../lib';
import { FileFileIcon, InfoIcon } from 'design-system/icons';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import BeneficiaryDocument from './BeneficiaryDocument';
import { ContractType } from '../../lib/signature';

const intlMessages = {
    common: defineMessages({
        title: {
            id: 'provident.beneficiaries-designation.standard-block.title',
            defaultMessage: 'La clause standard du contrat',
        },
        revertToStandard: {
            id: 'provident.beneficiaries-designation.revert-to-standard',
            defaultMessage:
                'Revenir à la clause standard du contrat citée ci-dessus',
        },
    }),
    [WorkflowTypesEnum.PA]: defineMessages({
        description: {
            id: 'provident.beneficiaries-designation.standard-block.description',
            defaultMessage: `<p>Cette clause désigne les bénéficiaires comme indiqué ci-dessous :</p>
                <p>• votre conjoint survivant non séparé de corps par un jugement définitif passé en force de chose jugée, non divorcé ou à votre partenaire lié par un pacte civil de solidarité,</p>
                <p>• à défaut, vos enfants nés ou à naître, vivants ou représentés, par parts égales entre eux, la totalité revenant au(x) survivant(s) par parts égales entre eux en cas de décès de l'un d'eux sans descendant,</p>
                <p>• à défaut à vos héritiers selon les règles de la dévolution successorale.</p>`,
        },
        blockText: {
            id: 'provident.beneficiaries-designation.standard-block.text',
            defaultMessage:
                "Votre contrat peut dépendre d'une convention collective particulière. Veuillez vous référer à votre Notice d'information pour connaitre le détail de votre clause standard.",
        },
        seeDocument: {
            id: 'buttons.see-my-information-note',
            defaultMessage: "Voir ma Notice d'information",
        },
    }),
    [WorkflowTypesEnum.PE]: defineMessages({
        seeDocument: {
            id: 'buttons.see-my-document',
            defaultMessage: 'Voir {documentName}.',
        },
    }),
};

type StandardClauseProps = {
    workflow: WorkflowTypesEnum;
    hasDocument?: boolean;
    type?: 'standard' | 'specific';
    contract?: ContractType;
    openDocumentModal?: () => void;
};

export default function StandardClause({
    workflow,
    type,
    hasDocument,
    contract,
    openDocumentModal,
}: StandardClauseProps) {
    const { formatMessage } = useIntl();
    const { isMobile } = useWindowBreakpoints();

    const _renderDocumentLinkForPA = () => {
        return (
            <LinkBox>
                <LinkOverlay
                    as={LinkIntl}
                    // @ts-ignore
                    href={{
                        pathname: '/account/documents',
                        query: { category: 'provident-guarantees' },
                    }}
                    passHref>
                    <HStack as="a" spacing="1" color="primary.main">
                        <Text textDecoration="underline">
                            <FormattedMessage
                                {...intlMessages[workflow].seeDocument}
                            />
                        </Text>
                        <ExternalLinkIcon data-testid="see-my-document-icon" />
                    </HStack>
                </LinkOverlay>
            </LinkBox>
        );
    };

    const _renderDocumentLinkForPE = () => {
        return (
            <HStack>
                <Button
                    variant="link"
                    color="primary.main"
                    rightIcon={<ExternalLinkIcon />}
                    textDecoration="underline"
                    onClick={openDocumentModal}>
                    <FormattedMessage
                        {...(contract.documentName in formMessages
                            ? formMessages[contract.documentName]
                            : intlMessages[workflow].seeDocument)}
                        values={{
                            documentName: contract.documentName || '',
                        }}
                    />
                </Button>
            </HStack>
        );
    };

    return (
        <Stack
            w="100%"
            border="2px"
            borderStyle="solid"
            borderColor="primary.main"
            borderRadius="2xl"
            px={isMobile ? '4' : '8'}
            py={isMobile ? '6' : '8'}
            spacing="4">
            <HStack spacing="3">
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexShrink={0}
                    w="10"
                    h="10"
                    background="primary.main"
                    rounded="lg"
                    color="white"
                    fontSize="2xl">
                    <InfoIcon data-testid="standard-icon" />
                </Flex>
                <Heading as="h4" fontWeight="semibold" fontSize="lg">
                    <FormattedMessage {...intlMessages.common.title} />
                </Heading>
            </HStack>
            {workflow === WorkflowTypesEnum.PA ? (
                <>
                    <Box fontSize="sm">
                        <FormattedMessage
                            {...intlMessages[workflow].description}
                        />
                    </Box>
                    <Divider />
                    <Text as="i" fontSize="xs" color="grey.700">
                        <FormattedMessage
                            {...intlMessages[workflow].blockText}
                        />
                    </Text>
                    {hasDocument && _renderDocumentLinkForPA()}
                </>
            ) : (
                <>
                    <Box
                        fontSize="sm"
                        dangerouslySetInnerHTML={{
                            __html: purifyHTML(contract.clause),
                        }}
                        sx={{
                            ul: {
                                marginInlineStart: 6,
                            },
                        }}
                    />
                    {contract?.documentName && _renderDocumentLinkForPE()}
                </>
            )}
            {workflow === WorkflowTypesEnum.PA && type === 'specific' && (
                <BeneficiaryDocument
                    workflow={workflow}
                    text={formatMessage(intlMessages.common.revertToStandard)}
                    icon={
                        <FileFileIcon data-testid="revert-to-standard-icon" />
                    }
                    bgColor="secondary.main"
                    type="standard"
                />
            )}
        </Stack>
    );
}
