import { Box, Button, Stack, Text } from '@chakra-ui/react';
import {
    mfaErrorMessages,
    LinkIntl,
    SafeFormattedMessage,
    registrationChallengeErrorMessages,
    UtilsContext,
    coreSharedMessages,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    ChallengeErrorDefaultIllustration,
    ChallengeErrorTooManyTriesIllustration,
} from '.';

type Props = {
    code: string | undefined;
};

function RedirectValidatedBlock() {
    const { isMobile } = useWindowBreakpoints();

    return (
        <Box textAlign="right">
            <Stack textAlign="left" spacing="6">
                <Text>
                    <FormattedMessage
                        id="components.auth.mfa.code-already-validated.text"
                        defaultMessage="Vous avez déjà validé votre double authentification.
                        Vous allez être redirigé vers le journal."
                    />
                </Text>
            </Stack>

            <Button
                as="a"
                colorScheme="primary"
                mt="10"
                isLoading
                w={isMobile && 'full'}
            />
        </Box>
    );
}

export default function ChallengeErrorBlock({ code }: Props) {
    const {
        siteConfig: { managementCenterPhoneNumber },
    } = React.useContext(UtilsContext);
    const { isMobile } = useWindowBreakpoints();

    if (code === 'MFA_ALREADY_VALIDATED') {
        return <RedirectValidatedBlock />;
    }

    return (
        <Box textAlign="right">
            <Stack textAlign="left" spacing="6">
                {code === 'MFA_TOO_MANY_TRIES' ? (
                    <ChallengeErrorTooManyTriesIllustration />
                ) : (
                    <ChallengeErrorDefaultIllustration />
                )}
                <Text>
                    <SafeFormattedMessage
                        {...(mfaErrorMessages[code] ||
                            registrationChallengeErrorMessages[code])}
                        values={{ managementCenterPhoneNumber }}
                        debugKey={code}
                    />
                </Text>
            </Stack>

            <LinkIntl href="/login" passHref>
                <Button
                    as="a"
                    colorScheme="primary"
                    mt="10"
                    w={isMobile && 'full'}>
                    <FormattedMessage {...coreSharedMessages.backToLogin} />
                </Button>
            </LinkIntl>
        </Box>
    );
}
