import { Flex, Box, Text } from '@chakra-ui/react';
import { CrossIcon } from 'design-system/icons';
import { FormattedMessage } from 'react-intl';

export type BadgeProps = {
    bg: string;
    label: string;
};

type DeleteButtonProps = {
    onClick: () => void;
};

type Props = {
    title: string;
    subItems?: string[];
    color?: string;
    badge?: BadgeProps;
    onDelete?: (params?: unknown) => void;
};

function Badge({ bg, label }: BadgeProps) {
    return (
        <Box
            w="36px"
            h="36px"
            rounded="md"
            color="white"
            fontWeight="bold"
            bg={bg}
            display="inline-flex"
            justifyContent="center"
            alignItems="center">
            {label}
        </Box>
    );
}

function DeleteButton({ onClick }: DeleteButtonProps) {
    return (
        <Box
            as="button"
            type="button"
            onClick={onClick}
            py="2"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontWeight="bold"
            w="100%"
            color="black"
            borderTop="1px solid"
            borderColor="strokes.medium">
            <CrossIcon w={4} h={4} mr="1" />
            <FormattedMessage
                id="components.form-input-card.item.delete"
                defaultMessage="Supprimer"
            />
        </Box>
    );
}

export function FormInputCardItem({
    title,
    subItems,
    color = 'black',
    badge = { bg: 'black', label: 'NN' },
    onDelete,
}: Props) {
    return (
        <Flex
            border="1px solid"
            borderColor="strokes.medium"
            color={color}
            px="2"
            rounded="md"
            fontSize="sm"
            textAlign="center"
            flexDirection="column"
            justifyContent="space-between">
            <Box py="2">
                {badge && <Badge {...badge} />}

                <Text fontWeight="bold" noOfLines={2}>
                    {title}
                </Text>

                {!!subItems?.length &&
                    subItems.map((item) => (
                        <Text key={item} color="grey.800">
                            {item}
                        </Text>
                    ))}
            </Box>

            {onDelete && <DeleteButton onClick={onDelete} />}
        </Flex>
    );
}
