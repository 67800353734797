import { Icon, IconProps } from '@chakra-ui/react';

export default function DownloadIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M13 3a1 1 0 1 0-2 0v11.953l-5.385-4.196a1 1 0 1 0-1.23 1.577l7 5.455a1 1 0 0 0 1.23 0l7-5.455a1 1 0 0 0-1.23-1.577L13 14.953V3z"
                fill="currentColor"
            />
            <path
                d="M2 18v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-3a1 1 0 1 0-2 0v2H4v-2a1 1 0 1 0-2 0z"
                fill="currentColor"
            />
        </Icon>
    );
}
