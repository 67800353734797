import React from 'react';
import { useRouter } from 'next/router';
import { Stack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import {
    APIError,
    coreSharedMessages,
    flattenViolations,
    FormErrors,
    FormValues,
    useFormValidation,
    ViolationMessage,
    UtilsContext,
    WorkflowTypesEnum,
    BaseIri,
    FormLibrarian,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import {
    serviceTypes,
    signatureBeneficiariesFormPath,
    SignatureCapitalBeneficiary,
    SignatureFormReasonType,
    useSignatureBeneficiariesForm,
} from 'core/lib/signature';
import SignatureFooterButtons from '../SignatureFooterButtons';

type SignatureBeneficiaryModalFormProps = {
    service: WorkflowTypesEnum;
    selectedReasonType: SignatureFormReasonType;
    selectedBeneficiary?: SignatureCapitalBeneficiary;
    onModalFormSubmit?: () => void;
    onModalClose: () => void;
    onFormError: (
        errorCode: number,
        step?: string,
        action?: 'add' | 'edit' | 'delete'
    ) => void;
    type: 'full-capital' | 'divided-capital';
};

export function SignatureBeneficiaryModalForm({
    service,
    selectedReasonType,
    selectedBeneficiary,
    onModalFormSubmit,
    onModalClose,
    onFormError,
    type,
}: SignatureBeneficiaryModalFormProps) {
    const {
        layouts: { LayoutBoundary },
    } = React.useContext(UtilsContext);
    const { isMobile } = useWindowBreakpoints();
    const router = useRouter();
    const procedureId = router.query?.procedureId as string;
    const queryClient = useQueryClient();
    const {
        data: form,
        status,
        error: formOptionsError,
    } = useSignatureBeneficiariesForm(
        service,
        type,
        procedureId,
        selectedReasonType,
        selectedBeneficiary
    );
    const [errors, setErrors] = React.useState<ViolationMessage[]>([]);
    const { onSubmit, methods } = useFormValidation(
        serviceTypes[service] as BaseIri,
        `${'/beneficiaries-designation/[type]/[procedureId]/beneficiaries/[selectedReasonType]'
            .replace('[type]', type)
            .replace('[procedureId]', procedureId)
            .replace(
                '[selectedReasonType]',
                selectedReasonType || selectedBeneficiary?.type
            )}${
            selectedBeneficiary?.id
                ? `?beneficiaryId=${selectedBeneficiary?.id}`
                : ''
        }`,
        form?.widgets
    );
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    React.useEffect(() => {
        if (formOptionsError) {
            onFormError(
                (formOptionsError as APIError)?.code,
                'add-beneficiary-form',
                (formOptionsError as APIError)?.code === 403
                    ? !selectedReasonType || !selectedBeneficiary
                        ? 'add'
                        : 'edit'
                    : undefined
            );
        }
    }, [
        onFormError,
        formOptionsError,
        selectedReasonType,
        selectedBeneficiary,
    ]);

    async function onFormSubmit(data: FormValues) {
        setIsSubmitting(true);
        const response = await onSubmit(data);
        setIsSubmitting(false);

        if (response) {
            switch (response.status) {
                case 200:
                    onModalFormSubmit();
                    await queryClient.invalidateQueries({
                        queryKey: [
                            signatureBeneficiariesFormPath,
                            service,
                            type,
                            procedureId,
                            selectedReasonType || selectedBeneficiary?.type,
                            selectedBeneficiary?.id,
                        ],

                        exact: true,
                        refetchType: 'active',
                    });
                    break;
                case 412:
                    if (response?.data?.data) {
                        const violations = flattenViolations(
                            response?.data?.data?.violations
                        );
                        setErrors(violations);
                    }
                    break;
                default:
                    onFormError(
                        response.status,
                        'add-beneficiary-form',
                        selectedBeneficiary ? 'edit' : 'add'
                    );
                    break;
            }
        }
    }

    return (
        <LayoutBoundary status={status} p="0">
            <>
                {errors?.length > 0 && <FormErrors errors={errors} />}
                <FormProvider {...methods}>
                    <Stack
                        as="form"
                        spacing="6"
                        alignItems={isMobile ? 'stretch' : 'flex-end'}>
                        {form?.widgets &&
                            form?.widgets.map(({ type, id, ...props }) => (
                                <FormLibrarian
                                    key={id}
                                    type={type}
                                    props={{
                                        id,
                                        methods: {
                                            setError: methods.setError,
                                            errors: methods.formState.errors,
                                            clearErrors: methods.clearErrors,
                                        },
                                        ...props,
                                    }}
                                />
                            ))}
                    </Stack>

                    <SignatureFooterButtons
                        cancelButton={{
                            onClick: onModalClose,
                            labelTranslationKey: coreSharedMessages.cancel,
                        }}
                        submitButton={{
                            labelTranslationKey: coreSharedMessages.validate,
                            type: 'submit',
                            onClick: methods.handleSubmit(onFormSubmit),
                            isLoading: isSubmitting,
                        }}
                    />
                </FormProvider>
            </>
        </LayoutBoundary>
    );
}
