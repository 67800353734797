import { Icon, IconProps } from '@chakra-ui/react';

export default function PharmacyIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M5.409 8c0-1.424.565-2.304 1.397-2.882.886-.615 2.153-.936 3.565-.994a12.754 12.754 0 0 1 3.959.476c.412.121.768.254 1.058.39a3.749 3.749 0 0 1-3.9-.075C11.018 4.623 10.39 4.609 10 5c-.39.39-.395 1.032.052 1.358 2.352 1.715 5.506 1.498 7.655-.65a1 1 0 0 0 .187-1.155c-.261-.524-.77-.896-1.231-1.157-.498-.28-1.108-.52-1.771-.715a14.751 14.751 0 0 0-4.604-.555c-1.612.066-3.305.434-4.623 1.35C4.292 4.429 3.409 5.924 3.409 8c0 2.11.972 3.63 2.345 4.72 1.33 1.057 3.051 1.726 4.648 2.233.672.214 1.346.406 1.975.584a159.788 159.788 0 0 1 .623.178v1.853a11.138 11.138 0 0 1-1.681-.225c-.54-.117-1.12.142-1.319.657-.198.515.06 1.096.596 1.228.733.181 1.572.302 2.404.343V20h-2c-.552 0-1 .409-1 .96 0 .553.448 1.04 1 1.04h6c.552 0 1-.487 1-1.04 0-.552-.448-.96-1-.96h-2v-.504c.596-.084 1.18-.236 1.671-.491C17.358 18.648 18 18 18 17c0-.783-.43-1.356-.92-1.744-.47-.374-1.082-.652-1.7-.881-.123-.046-.25-.09-.38-.135V13a5.001 5.001 0 0 0 4.901-4.007C20.011 8.453 19.552 8 19 8H9c-.552 0-1.01.452-.901.993A5.001 5.001 0 0 0 13 13v.635l-.083-.023c-.629-.18-1.269-.361-1.91-.565-1.552-.493-2.979-1.074-4.01-1.893C6.01 10.37 5.41 9.391 5.41 8zM15 16.373v1.095c.315-.061.568-.144.75-.238a.676.676 0 0 0 .233-.172c.012-.016.017-.025.017-.058 0 0 0-.005-.007-.017a.639.639 0 0 0-.156-.16c-.174-.14-.447-.289-.837-.45zM10.764 10h6.472c-.55.614-1.348 1-2.236 1h-2a2.993 2.993 0 0 1-2.236-1z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
