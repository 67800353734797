import { useCallback, useEffect, useState } from 'react';
import { useCallbackRef } from '@chakra-ui/react';
import { CallbackRef, RefNode, AnyCallback } from './types';
import { toArray } from 'lodash';

export type ClickOutsideMode = 'all' | 'form' | 'none';

const SCROLLBAR_WIDTH = 18;

/**
 * A hook that can track a click event outside a ref.
 *
 * Returns a callbackRef.
 * @param callback The function to call when the click is outside
 */
function useClickOutside(
    callback: AnyCallback<MouseEvent | TouchEvent>,
    clickOutsideMode: ClickOutsideMode
): CallbackRef {
    const callbackRef = useCallbackRef(callback);
    const [nodeRef, setNodeRef] = useState<RefNode>(null);
    const ref = useCallback((node: RefNode) => {
        setNodeRef(node);
    }, []);

    useEffect(() => {
        if (!nodeRef) return;

        function handleClickOutside(evt: MouseEvent | TouchEvent): void {
            if (clickOutsideMode === 'none') return;

            // Check for excluded nodes, depending on mode
            if (clickOutsideMode === 'form') {
                const isElementExcluded = toArray(
                    document.querySelectorAll('form, button[type="submit"]')
                ).find((elem) => {
                    return elem.contains(evt.target as Node);
                });

                if (isElementExcluded) return;
            }

            if (callbackRef && !nodeRef?.contains(evt.target as Node)) {
                // Prevent scrollbar from triggering clickOutside
                if (
                    evt instanceof MouseEvent &&
                    window.innerWidth - SCROLLBAR_WIDTH <= evt?.offsetX
                ) {
                    return;
                }
                callbackRef(evt);
            }
        }

        document.addEventListener('mousedown', handleClickOutside, true);
        return (): void => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [callbackRef, nodeRef, clickOutsideMode]);

    return ref;
}

export default useClickOutside;
