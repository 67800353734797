function useUserAgent() {
    const { userAgent } = navigator;

    const isIOS = /((iphone)|(ipad)|(ipod)|(macintosh)).*(mobile)/i.test(
        userAgent
    );
    const isAndroid = /android/.test(userAgent.toLowerCase());
    const isSafari = /^(?!.*(?:Chrome|Edge)).*Safari/.test(userAgent);

    return {
        isIOS,
        isAndroid,
        isSafari,
    };
}

export default useUserAgent;
