import React from 'react';
import { Box, Container, Img, LinkOverlay, LinkBox } from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/nextjs';
import {
    LinkIntl,
    UtilsContext,
    coreSharedMessages,
    HeadTitle,
    ErrorPage,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { useIntl, MessageDescriptor } from 'react-intl';

type CredentialsUpgradeProps = React.PropsWithChildren<unknown> & {
    title: string | MessageDescriptor;
    showLanguageSwitch: boolean;
};

export function CredentialsUpgrade({
    children,
    title,
    showLanguageSwitch,
}: CredentialsUpgradeProps) {
    const { isMobile } = useWindowBreakpoints();
    const { formatMessage } = useIntl();
    const {
        siteConfig: { client },
    } = React.useContext(UtilsContext);

    return (
        <Container
            size="lg"
            py={{ base: 6, md: 8 }}
            pb={isMobile && '0'}
            px={isMobile ? '0' : ''}>
            <HeadTitle title={title} />
            <LinkBox>
                <LinkOverlay
                    as={LinkIntl}
                    // @ts-ignore
                    LinkIntl
                    href="/login"
                    passHref>
                    <Box
                        textAlign={{ base: 'left', md: 'center' }}
                        mt={isMobile && showLanguageSwitch && '8'}>
                        {!isMobile && (
                            <Img
                                alt={formatMessage(coreSharedMessages.logoAlt)}
                                display="inline-block"
                                htmlWidth={client.logo?.width}
                                src={client.logo?.path}
                                py={client.logo?.paddingVertical}
                            />
                        )}
                    </Box>
                </LinkOverlay>
            </LinkBox>
            <ErrorBoundary fallback={ErrorPage}>{children}</ErrorBoundary>
        </Container>
    );
}
