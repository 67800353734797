import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { getTheme } from './get-theme';
import { Theme } from './types';
import { containerTheme } from './constsCustomTheme';

type CustomTheme = { primaryColor?: string };

type ThemeProviderProps = React.PropsWithChildren<{
    client: string;
    variant?: string;
    customTheme?: CustomTheme;
    setCustomTheme: React.Dispatch<React.SetStateAction<CustomTheme>>;
}>;

function useLocalTheme(
    client: ThemeProviderProps['client'],
    variant: ThemeProviderProps['variant'],
    customTheme: CustomTheme
) {
    const [theme, setTheme] = React.useState<Theme | null>(null);
    React.useEffect(() => {
        async function lazyLoadThemeConfig() {
            const siteTheme = await getTheme(client, variant);
            setTheme({
                ...siteTheme,
                ...{
                    colors: {
                        ...siteTheme?.colors,
                        ...(customTheme
                            ? {
                                  ...(customTheme?.primaryColor && {
                                      primary: {
                                          main: customTheme?.primaryColor,
                                          50: customTheme?.primaryColor,
                                          ...Array.from(Array(9).keys()).reduce(
                                              (curr, value) => ({
                                                  ...curr,
                                                  [(value + 1) * 100]:
                                                      customTheme?.primaryColor,
                                              }),
                                              {}
                                          ),
                                      },
                                  }),
                              }
                            : {}),
                    },
                    ...siteTheme?.typography,
                    ...siteTheme?.components,
                },
                components: {
                    ...siteTheme?.components,
                    ...{
                        Container:
                            variant === 'adherent' || variant === 'inextenso'
                                ? containerTheme
                                : siteTheme?.components?.Container,
                    },
                },
            });
        }

        lazyLoadThemeConfig();
    }, [client, variant, customTheme]);

    return theme;
}

type ThemeContextType = {
    setVariant: (value: string) => void | null;
    customTheme: CustomTheme;
    setCustomTheme?: React.Dispatch<React.SetStateAction<CustomTheme>>;
    cssVarPrefix: string;
};

const ThemeContext = React.createContext<ThemeContextType>({
    setVariant: () => null,
    customTheme: {},
    setCustomTheme: undefined,
    cssVarPrefix: '',
});

function ThemeProvider({
    children,
    client,
    variant,
    customTheme,
}: ThemeProviderProps) {
    const [variantState, setVariant] = React.useState<string | undefined>(
        variant
    );
    const [customThemeState, setCustomThemeState] = React.useState(
        customTheme || {}
    );
    const theme = useLocalTheme(client, variantState, customThemeState);

    if (theme) {
        return (
            <ThemeContext.Provider
                value={{
                    setVariant,
                    customTheme: customThemeState,
                    setCustomTheme: setCustomThemeState,
                    cssVarPrefix: theme?.config?.cssVarPrefix,
                }}>
                <ChakraProvider resetCSS theme={extendTheme(theme)}>
                    {children}
                </ChakraProvider>
            </ThemeContext.Provider>
        );
    }

    return null;
}

export { ThemeProvider, ThemeContext };
