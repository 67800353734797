import { exemptionsReasons } from 'core/lib/signature';
import { defineMessages } from 'react-intl';

export const formMessages = {
    ...Object.entries(exemptionsReasons)?.reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: value?.title,
        }),
        {}
    ),
    ...defineMessages({
        'login.email.placeholder': {
            id: 'login.email.placeholder',
            defaultMessage: 'exemple@mail.fr',
        },
        'groups.title': {
            id: 'widgets.groups.title',
            defaultMessage: 'Groupes',
        },
        'clauses.title': {
            id: 'widgets.clauses.title',
            defaultMessage: 'Clauses',
        },
        'companies.title': {
            id: 'widgets.companies.title',
            defaultMessage: 'Entreprises',
        },
        'exemption-reasons.title': {
            id: 'widgets.exemption-reasons.title',
            defaultMessage: 'Motif de dispense',
        },
        'exemption-status.title': {
            id: 'exemption-status.title',
            defaultMessage: 'Statut',
        },
        pending: {
            id: 'widgets.pending',
            defaultMessage: 'À valider',
        },
        denied: {
            id: 'widgets.denied',
            defaultMessage: 'Refusé',
        },
        accepted: {
            id: 'widgets.accepted',
            defaultMessage: 'En cours',
        },
        expired: {
            id: 'widgets.expired',
            defaultMessage: 'Expiré',
        },
        userPending: {
            id: 'components.status.pending',
            defaultMessage: 'En attente',
        },
        'expired-less-than-a-month': {
            id: 'widgets.expired-less-than-a-month',
            defaultMessage: 'Expiré - 1 mois',
        },
        'expired-more-than-a-month': {
            id: 'widgets.expired-more-than-a-month',
            defaultMessage: 'Expiré + 1 mois',
        },
        'employee-out': {
            id: 'widgets.employee-out',
            defaultMessage: 'Salarié sorti',
        },
        'validation-type.title': {
            id: 'widgets.validation-type.title',
            defaultMessage: 'Type de validation',
        },
        manual: {
            id: 'widgets.manual',
            defaultMessage: 'Manuelle',
        },
        automatic: {
            id: 'widgets.automatic',
            defaultMessage: 'Automatique',
        },
        'date.creation-date.title': {
            id: 'widgets.date.creation-date.title',
            defaultMessage: 'Date de création',
        },
        'date.processing-date.title': {
            id: 'widgets.date.processing-date.title',
            defaultMessage: 'Date de traitement',
        },
        'date.expiration-date.title': {
            id: 'widgets.date.expiration-date.title',
            defaultMessage: "Date d'expiration",
        },
        'date.start.title': {
            id: 'widgets.date.start.title',
            defaultMessage: 'A partir du',
        },
        'date.end.title': {
            id: 'widgets.date.end.title',
            defaultMessage: "Jusqu'au",
        },
        'employee-details.change-information.title': {
            id: 'forms.employee-details.change-information.title',
            defaultMessage: 'Modifier les informations personnelles',
        },
        'company.title': {
            id: 'widgets.company.title',
            defaultMessage: 'Entreprise',
        },
        'exemption-details.change-expiration-date.title': {
            id: 'forms.exemption-details.change-expiration-date.title',
            defaultMessage: "Modifier la date d'expiration",
        },
        'exemption-details.change-expiration-date.description': {
            id: 'forms.exemption-details.change-expiration-date.description',
            defaultMessage: "Veuillez indiquer la nouvelle date d'expiration.",
        },
        'exemption-details.validation.title': {
            id: 'widgets.exemption-details.validation.title',
            defaultMessage: 'Confirmation de validation',
        },
        'exemption-details.validation.description': {
            id: 'forms.exemption-details.validation.description',
            defaultMessage:
                "Veuillez indiquer la date d'expiration avant de confirmer.",
        },
        'exemption-details.refusal.title': {
            id: 'forms.exemption-details.refusal.title',
            defaultMessage: 'Indiquer le motif de refus',
        },
        'refusal-reasons.title': {
            id: 'widgets.refusal-reasons.title',
            defaultMessage: 'Motif de refus',
        },
        'refusal-reasons.other-details.title': {
            id: 'widgets.refusal-reasons.other-details.title',
            defaultMessage: 'Précisez',
        },
        'refusal-reasons.other-details.placeholder': {
            id: 'widgets.refusal-reasons.other-details.placeholder',
            defaultMessage: 'Indiquez le motif de refus',
        },
        'unreadable-certificate': {
            id: 'widgets.unreadable-certificate',
            defaultMessage: 'Justificatif illisible',
        },
        'incomplete-certificate': {
            id: 'widgets.incomplete-certificate',
            defaultMessage: 'Justificatif incomplet',
        },
        'incorrect-certificate': {
            id: 'widgets.incorrect-certificate',
            defaultMessage: 'Justificatif erroné',
        },
        'user.password.activation.title': {
            id: 'forms.user.password.activation.title',
            defaultMessage: 'Mettre à jour votre mot de passe',
        },
        'user.password.activation.description': {
            id: 'forms.user.password.activation.description',
            defaultMessage: 'Veuillez compléter les champs suivants.',
        },
        'social-security-number.truncated-title': {
            id: 'widgets.social-security-number.truncated-title',
            defaultMessage:
                'Numéro de Sécurité sociale (6 premiers chiffres uniquement)',
        },
        'social-security-number.truncated-description': {
            id: 'widgets.social-security-number.truncated-description',
            defaultMessage:
                'Veuillez renseigner les 6 premiers chiffres de votre numéro de Sécurité sociale sans espace.',
        },
        'password.request-reset.title': {
            id: 'forms.password.request-reset.title',
            defaultMessage: 'Demande de réinitialisation',
        },
        'password.request-reset.description': {
            id: 'forms.password.request-reset.description',
            defaultMessage:
                'Un lien va vous être envoyé par e-mail afin que vous puissiez réinitialiser votre mot de passe.',
        },
        'legals.consent.title': {
            id: 'forms.legals.consent.title',
            defaultMessage: 'Consentement',
        },
        'employee.gcuConfirmation.title': {
            id: 'widgets.employee.gcuConfirmation.title',
            defaultMessage: "Conditions générales d'utilisation",
        },
        'employee.gcuConfirmation.description': {
            id: 'widgets.employee.gcuConfirmation.description',
            defaultMessage: 'Je confirme avoir lu et accepté les',
        },
        'employee.gcuConfirmation.modal.text': {
            id: 'widgets.employee.gcuConfirmation.modal.text',
            defaultMessage: "conditions générales d'utilisation.",
        },
        'employee.gcuConfirmation.modal.title': {
            id: 'widgets.employee.gcuConfirmation.modal.title',
            defaultMessage: "Conditions générales d'utilisation.",
        },
        'exemption.gcuConfirmation.modal.title': {
            id: 'widgets.exemption.gcuConfirmation.modal.title',
            defaultMessage: "Conditions générales d'utilisation",
        },
        'employee.privacy-policy-confirmation.title': {
            id: 'widgets.employee.privacy-policy-confirmation.title',
            defaultMessage: 'Politique de confidentialité',
        },
        'employee.document-confirmation.title': {
            id: 'widgets.employee.document-confirmation.title',
            defaultMessage: 'Documentation légale',
        },
        'employee.document-confirmation.description': {
            id: 'widgets.employee.document-confirmation.description',
            defaultMessage: 'Je confirme avoir bien reçu le document.',
        },
        'employee.privacy-policy-confirmation.description': {
            id: 'widgets.employee.privacy-policy-confirmation.description',
            defaultMessage: 'Je confirme avoir et lu et accepté la',
        },
        'employee.privacy-policy-confirmation.modal.text': {
            id: 'widgets.employee.privacy-policy-confirmation.modal.text',
            defaultMessage: ' politique de confidentialité.',
        },
        'employee.privacy-policy-confirmation.modal.title': {
            id: 'widgets.employee.privacy-policy-confirmation.modal.title',
            defaultMessage: 'Politique de confidentialité',
        },
        'exemption.privacy-policy-confirmation.modal.title': {
            id: 'widgets.exemption.privacy-policy-confirmation.modal.title',
            defaultMessage: 'Politique de confidentialité',
        },
        'admin.group-create.title': {
            id: 'forms.admin.group-create.title',
            defaultMessage: "Création d'un groupe",
        },
        'admin.edit-main-infos.title': {
            id: 'forms.admin.edit-main-infos.title',
            defaultMessage: 'Modifier les informations générales',
        },
        'admin.edit-companies-infos.title': {
            id: 'forms.admin.edit-companies-infos.title',
            defaultMessage: 'Modifier les entreprises rattachées',
        },
        'admin.clauses-modify.title': {
            id: 'forms.admin.clauses-modify.title',
            defaultMessage: 'Modifier la clause',
        },
        'clauses.name.title': {
            id: 'widgets.clauses.name.title',
            defaultMessage: 'Nom de la clause',
        },
        'clauses.name.placeholder': {
            id: 'widgets.clauses.name.placeholder',
            defaultMessage: 'Clause Exemple',
        },
        'clauses.type.title': {
            id: 'widgets.clauses.type.title',
            defaultMessage: 'Type de clause',
        },
        'group.name.title': {
            id: 'widgets.group.name.title',
            defaultMessage: 'Nom du groupe',
        },
        'group.license-number.title': {
            id: 'widgets.group.license-number.title',
            defaultMessage: 'Siren ou Siret',
        },
        'management-environment.title': {
            id: 'widgets.management-environment.title',
            defaultMessage: 'Environnement de gestion',
        },
        'status.admin-group-description': {
            id: 'widgets.status.admin-group-description',
            defaultMessage:
                'Attention, la désactivation du groupe entraînera la désactivation des entreprises rattachées au groupe.',
        },
        'address.street-name.title': {
            id: 'widgets.address.street-name.title',
            defaultMessage: 'Type et nom de voie',
        },
        'address.additional-address-one.title': {
            id: 'widgets.address.additional-address-one.title',
            defaultMessage: "Complément d'adresse",
        },
        'address.additional-address-two.title': {
            id: 'widgets.address.additional-address-two.title',
            defaultMessage: "Complément d'adresse 2",
        },
        'address.postal-code.title': {
            id: 'widgets.address.postal-code.title',
            defaultMessage: 'Code postal',
        },
        'address.city.title': {
            id: 'widgets.address.city.title',
            defaultMessage: 'Ville',
        },
        'address.country.title': {
            id: 'widgets.address.country.title',
            defaultMessage: 'Pays',
        },
        'address.title': {
            id: 'widgets.address.title',
            defaultMessage: 'Adresse',
        },
        'status.title': {
            id: 'widgets.status.title',
            defaultMessage: 'Statut',
        },
        'admin.edit-contacts-infos.title': {
            id: 'forms.admin.edit-contacts-infos.title',
            defaultMessage: 'Modifier les informations de contact',
        },
        'user-infos.last-name.title': {
            id: 'widgets.user-infos.last-name.title',
            defaultMessage: 'Nom',
        },
        'user-infos.first-name.title': {
            id: 'widgets.user-infos.first-name.title',
            defaultMessage: 'Prénom',
        },
        'user-infos.email.title': {
            id: 'widgets.user-infos.email.title',
            defaultMessage: 'Adresse e-mail',
        },
        'admin.edit-features-infos.title': {
            id: 'forms.admin.edit-features-infos.title',
            defaultMessage: 'Modifier les services',
        },
        'features.title': {
            id: 'widgets.features.title',
            defaultMessage: 'Services activés',
        },
        'apply-to-companies.description': {
            id: 'widgets.apply-to-companies.description',
            defaultMessage:
                "Je souhaite également impacter ce changement à toutes les entreprises du groupe (le changement sera visible dans la vue des entreprises d'ici quelques heures)",
        },
        'legal-documents': {
            id: 'widgets.legal-documents.label',
            defaultMessage: 'Documentation légale',
        },
        active: {
            id: 'widgets.active',
            defaultMessage: 'Actif',
        },
        inactive: {
            id: 'widgets.inactive',
            defaultMessage: 'Inactif',
        },
        deactivated: {
            id: 'widgets.deactivated',
            defaultMessage: 'Désactivé',
        },
        'admin.company-create.title': {
            id: 'forms.admin.company-create.title',
            defaultMessage: "Création d'une entreprise",
        },
        'admin.clauses-create.title': {
            id: 'forms.admin.create-clause.title',
            defaultMessage: "Ajout d'une clause",
        },
        'search.clauses-placeholder': {
            id: 'widgets.search.clauses-placeholder',
            defaultMessage: 'Nom de la clause',
        },
        'company.name.title': {
            id: 'widgets.company.name.title',
            defaultMessage: "Nom de l'entreprise",
        },
        'company.license-number.title': {
            id: 'widgets.company.license-number.title',
            defaultMessage: 'Siren ou Siret',
        },
        company: {
            id: 'widgets.clause.type.company',
            defaultMessage: 'Entreprise',
        },
        environment: {
            id: 'widgets.environment',
            defaultMessage: 'Un environnement de gestion',
        },
        insurer: {
            id: 'widgets.clause.type.insurer',
            defaultMessage: 'Assureur',
        },
        'collective-agreement': {
            id: 'widgets.clause.type.collective-agreement',
            defaultMessage: 'Convention collective',
        },
        'clauses.content.title': {
            id: 'widgets.clause.content.title',
            defaultMessage: 'Contenu de la clause',
        },
        'clauses.content.placeholder': {
            id: 'widgets.clause.content.placeholder',
            defaultMessage:
                'Cette clause désigne les bénéficiaires du contrat…',
        },
        'company.provider-id.title': {
            id: 'widgets.company.provider-id.title',
            defaultMessage: 'Identifiant Open',
        },
        group: {
            id: 'widgets.group.label',
            defaultMessage: 'Un groupe',
        },
        'exemption.group': {
            id: 'widgets.exemption.group',
            defaultMessage: 'Idem que le groupe',
        },
        'group.title': {
            id: 'widgets.group.title',
            defaultMessage: 'Groupe',
        },
        'company.link-to.title': {
            id: 'widgets.company.link-to.title',
            defaultMessage: "Rattacher l'entreprise à:",
        },
        'admin.user-create.title': {
            id: 'forms.admin.user-create.title',
            defaultMessage: "Ajout d'un utilisateur",
        },
        'admin.user-create.contact-details.title': {
            id: 'forms.admin.user-create.contact-details.title',
            defaultMessage: 'Informations générales',
        },
        'admin.user-modify.title': {
            id: 'forms.admin.user-modify.title',
            defaultMessage: 'Modifier les informations générales',
        },
        'admin.user-modify-links.title': {
            id: 'forms.admin.user-modify-links.title',
            defaultMessage: 'Modifier les rattachements',
        },
        'admin.user-create.companies.title': {
            id: 'forms.admin.user-create.companies.title',
            defaultMessage: 'Rattachement des entreprises',
        },
        'user-companies.title': {
            id: 'widgets.user-companies.title',
            defaultMessage: 'Liste des entreprises',
        },
        'user-companies.description': {
            id: 'widgets.user-companies.description',
            defaultMessage:
                'Définissez ci-dessous les entreprises auxquelles vous souhaitez rattacher cet utilisateur.',
        },
        'admin.user-create.permissions.title': {
            id: 'forms.admin.user-create.permissions.title',
            defaultMessage: 'Gestion des permissions',
        },
        'user-permissions.description': {
            id: 'widgets.user-permissions.description',
            defaultMessage:
                'Définissez ci-dessous les différentes permissions pour votre utilisateur.',
        },
        'user-permissions.read-only': {
            id: 'widgets.user-permissions.read-only',
            defaultMessage: 'Consultation',
        },
        'user-permissions.read-write': {
            id: 'widgets.user-permissions.read-write',
            defaultMessage: 'Modification',
        },
        'user-permissions.statistics.title': {
            id: 'widgets.user-permissions.statistics.title',
            defaultMessage: 'Statistiques',
        },
        'user-permissions.documents.title': {
            id: 'widgets.user-permissions.documents.title',
            defaultMessage: 'Documents',
        },
        'user-permissions.contributions.title': {
            id: 'widgets.user-permissions.contributions.title',
            defaultMessage: 'Cotisations',
        },
        'user-permissions.provident.title': {
            id: 'widgets.user-permissions.provident.title',
            defaultMessage: 'Prévoyance',
        },
        'user-permissions.employees-management.title': {
            id: 'widgets.user-permissions.employees-management.title',
            defaultMessage: 'Gestion des salariés',
        },
        'user-permissions.subscription-forms.title': {
            id: 'widgets.user-permissions.subscription-forms.title',
            defaultMessage: 'Bulletins d’adhésions',
        },
        'user-permissions.situation-history.title': {
            id: 'widgets.user-permissions.situation-history.title',
            defaultMessage: 'Historique de situation',
        },
        'user-permissions.employee-access-document.title': {
            id: 'widgets.user-permissions.employee-access-document.title',
            defaultMessage: 'Coupons',
        },
        'user-infos.gender.title': {
            id: 'widgets.user-infos.gender.title',
            defaultMessage: 'Civilité',
        },
        'user-infos.phone-number.primary-title': {
            id: 'widgets.user-infos.phone-number.primary-title',
            defaultMessage: 'Téléphone principal',
        },
        'user-infos.phone-number.secondary-title': {
            id: 'widgets.user-infos.phone-number.secondary-title',
            defaultMessage: 'Téléphone secondaire',
        },
        'user-infos.phone-number.placeholder': {
            id: 'widgets.user-infos.phone-number.placeholder',
            defaultMessage: '00 00 00 00 00',
        },
        'user-infos.email.placeholder': {
            id: 'widgets.user-infos.email.placeholder',
            defaultMessage: 'exemple@mail.fr',
        },
        'user-infos.role.title': {
            id: 'widgets.user-infos.role.title',
            defaultMessage: 'Rôle(s)',
        },
        'user-infos.role.help': {
            id: 'widgets.user-infos.role.help',
            defaultMessage:
                "Le rôle de l'utilisateur détermine les fonctionnalités auxquelles il a accès.",
        },
        male: {
            id: 'male',
            defaultMessage: 'M.',
        },
        female: {
            id: 'female',
            defaultMessage: 'Mme.',
        },
        unspecified: {
            id: 'unspecified',
            defaultMessage: 'Non précisé',
        },
        'search.companies-placeholder': {
            id: 'widgets.search.companies-placeholder',
            defaultMessage:
                "Nom de l'entreprise, Siren ou Siret, nom du groupe",
        },
        'search.groups-placeholder': {
            id: 'widgets.search.groups-placeholder',
            defaultMessage: 'Nom du groupe, Siren ou Siret',
        },
        'search.users-placeholder': {
            id: 'widgets.search.users-placeholder',
            defaultMessage: 'Nom, prénom, adresse e-mail',
        },
        'search.roles-placeholder': {
            id: 'widgets.search.roles-placeholder',
            defaultMessage: 'Nom du rôle',
        },
        'search.management-environments-placeholder': {
            id: 'widgets.search.management-environments-placeholder',
            defaultMessage: "Nom de l'environnement",
        },
        'search.structures-placeholder': {
            id: 'widgets.search.structures-placeholder',
            defaultMessage: "Nom du groupe, nom de l'entreprise",
        },
        'environments.title': {
            id: 'widgets.environments.title',
            defaultMessage: 'Environnements de gestion',
        },
        'roles.title': {
            id: 'widgets.roles.title',
            defaultMessage: 'Rôles',
        },
        'processor-type.title': {
            id: 'widgets.processor-type.title',
            defaultMessage: 'Type de gestion',
        },
        'admin.role-create.title': {
            id: 'forms.admin.role-create.title',
            defaultMessage: "Création d'un rôle",
        },
        'admin.role-modify.title': {
            id: 'forms.admin.role-modify.title',
            defaultMessage: 'Modifier le rôle',
        },
        'role.name.title': {
            id: 'widgets.role.name.title',
            defaultMessage: 'Nom du rôle',
        },
        'role.status.help': {
            id: 'widgets.role.status.help',
            defaultMessage:
                'Attention, si vous désactivez un rôle déjà attribué à des utilisateurs, ces derniers ne pourront plus se connecter.',
        },
        'permissions.title': {
            id: 'widgets.permissions.title',
            defaultMessage: 'Autorisations',
        },
        beneficiary_designation_clauses_view: {
            id: 'widgets.permissions.beneficiary_designation_clauses_view',
            defaultMessage: 'Voir les clauses de désignation de bénéficiaires',
        },
        beneficiary_designation_clauses_manage: {
            id: 'widgets.permissions.beneficiary_designation_clauses_manage',
            defaultMessage:
                'Voir et paramétrer les clauses de désignation de bénéficiaires',
        },
        beneficiary_designation_companies_view: {
            id: 'widgets.permissions.beneficiary_designation_companies_view',
            defaultMessage:
                'Voir les entreprises et contrats pour la désignation de bénéficiaires',
        },
        beneficiary_designation_companies_manage: {
            id: 'widgets.permissions.beneficiary_designation_companies_manage',
            defaultMessage:
                'Voir et paramétrer les entreprises et contrats pour la désignation de bénéficiaires',
        },
        beneficiary_designation_portal_view: {
            id: 'widgets.permissions.beneficiary_designation_portal_view',
            defaultMessage:
                'Pouvoir récupérer le lien vers le portail de désignation de bénéficiaires (et code associé)',
        },
        beneficiary_designation_view: {
            id: 'widgets.permissions.beneficiary_designation_view',
            defaultMessage: 'Voir les désignations de bénéficiaires',
        },
        exemptions_view: {
            id: 'widgets.exemptions_view',
            defaultMessage: "Voir les dispenses d'affiliation",
        },
        exemptions_process: {
            id: 'widgets.exemptions_process',
            defaultMessage: "Voir et agir sur les dispenses d'affiliation",
        },
        businesses_view: {
            id: 'widgets.businesses_view',
            defaultMessage: 'Voir les groupes et entreprises',
        },
        businesses_manage: {
            id: 'widgets.businesses_manage',
            defaultMessage: 'Voir et paramétrer les groupes et les entreprises',
        },
        users_view: {
            id: 'widgets.users_view',
            defaultMessage: 'Voir les utilisateurs',
        },
        users_manage: {
            id: 'widgets.users_manage',
            defaultMessage: 'Voir et paramétrer les utilisateurs',
        },
        roles_manage: {
            id: 'widgets.roles_manage',
            defaultMessage: 'Voir et paramétrer les rôles',
        },
        environments_manage: {
            id: 'widgets.environments_manage',
            defaultMessage: 'Voir et paramétrer les environnements de gestion',
        },
        exemptions_configure: {
            id: 'widgets.exemptions_configure',
            defaultMessage:
                "Voir et paramétrer les cas de dispenses d'affiliation",
        },
        exemptions_portal_view: {
            id: 'widgets.exemptions_portal_view',
            defaultMessage:
                "Pouvoir récupérer le lien vers le portail de demande de dispense d'affiliation (et code associé)",
        },
        legal_documents_view: {
            id: 'widgets.legal_documents_view',
            defaultMessage: 'Voir les documents et les salariés',
        },
        legal_documents_manage: {
            id: 'widgets.legal_documents_manage',
            defaultMessage: 'Voir et agir sur les documents et les salariés',
        },
        'role.allowed-delegators.title': {
            id: 'widgets.role.allowed-delegators.title',
            defaultMessage: 'Qui pourra attribuer ce rôle ?',
        },
        'role.allowed-delegators.description': {
            id: 'widgets.role.allowed-delegators.description',
            defaultMessage:
                'Veuillez sélectionner un ou plusieurs rôles parmi les rôles déjà créés.',
        },
        selfDelegator: {
            id: 'widgets.selfDelegator',
            defaultMessage: 'Le rôle que vous êtes en train de créer',
        },
        exemptions: {
            id: 'widgets.exemptions',
            defaultMessage: "Dispenses d'affiliation",
        },
        'beneficiary-designation': {
            id: 'widgets.beneficiary-designation.title.label',
            defaultMessage: 'Désignation de bénéficiaires',
        },
        'admin.environment-create.title': {
            id: 'forms.admin.environment-create.title',
            defaultMessage: "Création d'un environnement de gestion",
        },
        'management-environment.name.title': {
            id: 'widgets.management-environment.name.title',
            defaultMessage: "Nom de l'environnement",
        },
        'exemption.processor-type.title': {
            id: 'widgets.exemption.processor-type.title',
            defaultMessage: 'Type de gestion',
        },
        provider: {
            id: 'widgets.provider',
            defaultMessage: 'Gestion déléguée',
        },
        internal: {
            id: 'widgets.internal',
            defaultMessage: 'Gestion propre',
        },
        'exemption.configuration-mode.title': {
            id: 'widgets.exemption.configuration-mode.title',
            defaultMessage: 'Configuration des cas de dispenses',
        },
        'group.default': {
            id: 'widgets.group.default',
            defaultMessage:
                'Uniquement les cas de dispenses légalement obligatoires',
        },
        'group.group': {
            id: 'widgets.group.group',
            defaultMessage: 'Ajouter des cas de dispenses personnalisés',
        },
        'company.default': {
            id: 'widgets.company.default',
            defaultMessage:
                'Uniquement les cas de dispenses légalement obligatoires',
        },
        'company.group': {
            id: 'widgets.company.group',
            defaultMessage: 'Idem que le groupe',
        },
        'company.company': {
            id: 'widgets.company.company',
            defaultMessage: 'Ajouter des cas de dispenses personnalisés',
        },
        'exemption.configuration-mode.custom-list.title': {
            id: 'widgets.exemption.configuration-mode.custom-list.title',
            defaultMessage: 'Liste des cas de dispenses personnalisés',
        },
        'exemption.configuration-mode.custom-parameter.title': {
            id: 'widgets.exemption.configuration-mode.custom-parameter.title',
            defaultMessage: 'Précisez la valeur',
        },
        '4-months': {
            id: 'widgets.exemption.configuration-mode.custom-parameter.4-months',
            defaultMessage: '4 mois',
        },
        '5-months': {
            id: 'widgets.exemption.configuration-mode.custom-parameter.5-months',
            defaultMessage: '5 mois',
        },
        '6-months': {
            id: 'widgets.exemption.configuration-mode.custom-parameter.6-months',
            defaultMessage: '6 mois',
        },
        '7-months': {
            id: 'widgets.exemption.configuration-mode.custom-parameter.7-months',
            defaultMessage: '7 mois',
        },
        '8-months': {
            id: 'widgets.exemption.configuration-mode.custom-parameter.8-months',
            defaultMessage: '8 mois',
        },
        '9-months': {
            id: 'widgets.exemption.configuration-mode.custom-parameter.9-months',
            defaultMessage: '9 mois',
        },
        '10-months': {
            id: 'widgets.exemption.configuration-mode.custom-parameter.10-months',
            defaultMessage: '10 mois',
        },
        '11-months': {
            id: 'widgets.exemption.configuration-mode.custom-parameter.11-months',
            defaultMessage: '11 mois',
        },
        '12-months': {
            id: 'widgets.exemption.configuration-mode.custom-parameter.12-months',
            defaultMessage: '12 mois',
        },
        'admin.edit-theme.title': {
            id: 'forms.admin.edit-theme.title',
            defaultMessage: 'Modifier le thème',
        },
        'primary-color.title': {
            id: 'widgets.primary-color.title',
            defaultMessage: 'Couleur',
        },
        'primary-color.description': {
            id: 'widgets.primary-color.description',
            defaultMessage:
                "Vous pouvez choisir une teinte de couleur recommandée ou définir vous-même la couleur principale du thème à l'aide de son code hexadécimal.",
        },
        recommendedColor: {
            id: 'widgets.recommendedColor',
            defaultMessage: 'Teinte de couleur recommandée',
        },
        '#4B549E': {
            id: '#4B549E',
            defaultMessage: 'Couleur par défaut (#4B549E)',
        },
        '#043167': {
            id: '#043167',
            defaultMessage: 'Bleu marine (#043167)',
        },
        '#B25600': {
            id: '#B25600',
            defaultMessage: 'Orange (#B25600)',
        },
        '#890808': {
            id: '#890808',
            defaultMessage: 'Rouge (#890808)',
        },
        '#890855': {
            id: '#890855',
            defaultMessage: 'Rose (#890855)',
        },
        '#580889': {
            id: '#580889',
            defaultMessage: 'Violet (#580889)',
        },
        '#087189': {
            id: '#087189',
            defaultMessage: 'Turquoise (#087189)',
        },
        '#08894B': {
            id: '#08894B',
            defaultMessage: 'Vert (#08894B)',
        },
        '#323232': {
            id: '#323232',
            defaultMessage: 'Noir (#323232)',
        },
        customColor: {
            id: 'widgets.customColor',
            defaultMessage: 'Couleur personnalisée',
        },
        'recommended-color.title': {
            id: 'widgets.recommended-color.title',
            defaultMessage: 'Veuillez sélectionner une teinte de couleur',
        },
        'recommended-color.description': {
            id: 'widgets.recommended-color.description',
            defaultMessage:
                "Nous vous proposons une liste de couleurs répondant aux normes d'accessibilité.",
        },
        'custom-color.title': {
            id: 'widgets.custom-color.title',
            defaultMessage:
                'Veuillez saisir le code hexadécimal de la couleur personnalisée',
        },
        'custom-color.description': {
            id: 'widgets.custom-color.description',
            defaultMessage:
                "Attention, veuillez choisir une teinte de couleur répondant aux normes d'accessibilité.",
        },
        'color.placeholder': {
            id: 'widgets.color.placeholder',
            defaultMessage: '#000000',
        },
        'logo.title': {
            id: 'widgets.logo.title',
            defaultMessage: 'Logo',
        },
        'logo.description': {
            id: 'widgets.logo.description',
            defaultMessage: 'Veuillez joindre le logo associé au thème.',
        },
        'admin.create-contract.title': {
            id: 'forms.admin.create-contract.title',
            defaultMessage: "Ajout d'un contrat",
        },
        'contract.number.title': {
            id: 'widgets.contract.number.title',
            defaultMessage: 'Numéro de contrat',
        },
        'contract.name.title': {
            id: 'widgets.contract.name.title',
            defaultMessage: 'Nom du contrat',
        },
        'contract.name.description': {
            id: 'widgets.contract.name.description',
            defaultMessage: 'A titre indicatif',
        },
        'contract.clause.title': {
            id: 'widgets.contract.clause.title',
            defaultMessage: "Attribution d'une clause standard",
        },
        'contract.clause.description': {
            id: 'widgets.contract.clause.description',
            defaultMessage:
                'Utiliser la liste ci-dessous pour attribuer une clause standard.',
        },
        'attachments.contract-document.title': {
            id: 'widgets.attachments.contract-document.title',
            defaultMessage: 'Document',
        },
        'attachments.contract-document.description': {
            id: 'widgets.attachments.contract-document.description',
            defaultMessage:
                "Ce document apparaîtra sur la page d'accueil du portail salariés de désignation de bénéficiaires.",
        },
        'contract.document-name.title': {
            id: 'widgets.contract.document-name.title',
            defaultMessage: 'Nom du document',
        },
        'contract.document-name.description': {
            id: 'widgets.contract.document-name.description',
            defaultMessage:
                "Nom du document qui sera affiché sur la page d'accueil du portail salariés de désignation de bénéficiaires.",
        },
        'admin.edit-contract.title': {
            id: 'forms.admin.edit-contract.title',
            defaultMessage: 'Modifier le contrat',
        },
        'date.deactivation-date.title': {
            id: 'widgets.date.deactivation-date.title',
            defaultMessage: 'Date de désactivation',
        },
        'contract.status.deactivated.description': {
            id: 'widgets.contract.status.deactivated.description',
            defaultMessage:
                'Désactivation du contrat et du portail salarié associé',
        },
        'contract.deactivation-date.description': {
            id: 'widgets.contract.deactivation-date.description',
            defaultMessage:
                'Date à laquelle le contrat et le portail salarié seront désactivés. Les désignations de bénéficiaires existantes ne seront pas supprimées.',
        },
        'attachments.contract-document.add-replace.title': {
            id: 'widgets.attachments.contract-document.add-replace.title',
            defaultMessage: 'Ajouter ou remplacer le document lié au contrat',
        },
        'attachments.contract-document.add-replace.description': {
            id: 'widgets.attachments.contract-document.add-replace.description',
            defaultMessage:
                'Ce document sera visible et téléchargeable sur le portail salarié associé à ce contrat',
        },
        'beneficiary-designation-filters.title': {
            id: 'forms.beneficiary-designation-filters.title',
            defaultMessage: 'Filtrer',
        },
        'search.beneficiary-designation.placeholder': {
            id: 'widgets.search.beneficiary-designation.placeholder',
            defaultMessage: "Nom de l'entreprise, nom ou prénom du salarié",
        },
        standard: {
            id: 'widgets.standard',
            defaultMessage: 'Standard',
        },
        specific: {
            id: 'widgets.specific',
            defaultMessage: 'Spécifique',
        },
        'common.date.placeholder': {
            id: 'widgets.common.date.placeholder',
            defaultMessage: 'jj/mm/aaaa',
        },
        'search.companies-contracts-placeholder': {
            id: 'widgets.search.companies-contracts-placeholder',
            defaultMessage: "Nom de l'entreprise, Siren ou Siret",
        },
        'profile.emails-frequency.title': {
            id: 'api.forms.profile.emails-frequency.title',
            defaultMessage: 'Modifier les paramètres des rapports',
        },
        'profile.emails-frequency.description': {
            id: 'api.forms.profile.emails-frequency.description',
            defaultMessage:
                'Vous pouvez activer ou désactiver la réception des rapports suivants.',
        },
        'emails.title': {
            id: 'widgets.emails.title',
            defaultMessage: 'Liste des rapports',
        },
        'emails.description': {
            id: 'widgets.emails.description',
            defaultMessage:
                "En cas d'activation, vous pouvez choisir la fréquence à laquelle vous souhaitez recevoir le rapport.",
        },
        'emails.frequency.title': {
            id: 'widgets.emails.frequency.title',
            defaultMessage: 'Fréquence de réception',
        },
        exemptionsReport: {
            id: 'widgets.exemptionsReport',
            defaultMessage: 'Rapport des dispenses sur la période',
        },
        daily: {
            id: 'widgets.emails.frequency.daily',
            defaultMessage: 'Tous les jours',
        },
        weekly: {
            id: 'widgets.emails.frequency.weekly',
            defaultMessage: 'Une fois par semaine',
        },
        bimonthly: {
            id: 'widgets.emails.frequency.bimonthly',
            defaultMessage: 'Deux fois par mois',
        },
        monthly: {
            id: 'widgets.emails.frequency.monthly',
            defaultMessage: 'Une fois par mois',
        },
        quarterly: {
            id: 'widgets.emails.frequency.quarterly',
            defaultMessage: 'Une fois par trimestre',
        },
        biannually: {
            id: 'widgets.emails.frequency.biannually',
            defaultMessage: 'Deux fois par an',
        },
        annually: {
            id: 'widgets.emails.frequency.annually',
            defaultMessage: 'Une fois par an',
        },
        'to-send': {
            id: 'email.status.to-send',
            defaultMessage: 'A envoyer',
        },
        error: {
            id: 'email.status.error',
            defaultMessage: 'Erreur',
        },
        'email-sent': {
            id: 'email.status.email-sent',
            defaultMessage: 'Envoyé',
        },
        'email-opened': {
            id: 'email.status.email-opened',
            defaultMessage: 'Ouvert',
        },
        'email-bounced': {
            id: 'email.status.email-bounced',
            defaultMessage: 'Non remis',
        },
        'email-spam': {
            id: 'email.status.email-spam',
            defaultMessage: 'Spam',
        },
        'document-validated': {
            id: 'legal-document.status.validated',
            defaultMessage: 'Validé',
        },
        'contact-needed': {
            id: 'legal-document.status.contact-needed',
            defaultMessage: 'À contacter',
        },
        'documents.add.title': {
            id: 'forms.documents.add.title',
            defaultMessage: 'Ajouter un document',
        },
        'attachments.document-name.title': {
            id: 'widgets.attachments.document-name.title',
            defaultMessage: 'Nom du document',
        },
        'attachments.document-name.description': {
            id: 'widgets.attachments.document-name.description',
            defaultMessage: 'Ce nom sera affiché sur le portail salarié.',
        },
        'attachments.file-name.title': {
            id: 'widgets.attachments.file-name.title',
            defaultMessage: 'Fichier',
        },
        'attachments.file-name.description': {
            id: 'widgets.attachments.file-name.description',
            defaultMessage:
                'Ce document sera visible et téléchargeable sur le portail salarié.',
        },
        'search.legal-documents.placeholder': {
            id: 'widgets.search.legal-documents.placeholder',
            defaultMessage: 'Nom du document',
        },
        'documents.edit.title': {
            id: 'forms.documents.edit.title',
            defaultMessage: 'Modifier un document',
        },
        'legal-documents.document.employees.filters.title': {
            id: 'forms.legal-documents.document.employees.filters.title',
            defaultMessage: 'Filtrer',
        },
        'search.document-employees.placeholder': {
            id: 'widgets.search.document-employees.placeholder',
            defaultMessage: 'Rechercher un salarié',
        },
        'email-error': {
            id: 'widgets.email.status.error',
            defaultMessage: 'Erreur',
        },
        'email-received': {
            id: 'widgets.email.status.recived',
            defaultMessage: 'Reçu',
        },
        'legal-documents.email-composition.title': {
            id: 'forms.legal-documents.email-composition.title',
            defaultMessage: 'Envoyer le document',
        },
        'legal-documents.email-subject.title': {
            id: 'widgets.legal-documents.email-subject.title',
            defaultMessage: 'Objet du mail',
        },
        'legal-documents.email-subject.placeholder': {
            id: 'widgets.legal-documents.email-subject.placeholder',
            defaultMessage: 'Votre document [Document-Nom]',
        },
        'legal-documents.email-message.title': {
            id: 'widgets.legal-documents.email-message.title',
            defaultMessage: 'Corps du mail',
        },
        'legal-documents.email-message.description': {
            id: 'widgets.legal-documents.email-message.description',
            defaultMessage: 'Ajoutez des informations dans le mail',
        },
        'legal-documents.email-message.placeholder': {
            id: 'widgets.legal-documents.email-message.placeholder',
            defaultMessage:
                "Texte inséré dans le mail prédéfini (consultez le bouton d'information pour visualiser le mail de base).",
        },
        'legal-documents.email-message.help': {
            id: 'widgets.legal-documents.email-message.help',
            defaultMessage: `<p><b>Email de base</b></p>
            <p>Bonjour [Prénom] [Nom],</p>
            <p><i>[Votre message ici]</i></p>
            <p>Merci de suivre le lien ci-dessous pour accéder à la validation du document.</p>
            <p>[Lien]</p>
            <p>Code d'accès : [Code].</p>
            <p>Cordialement</p>
            <p>Votre service client</p>`,
        },
        'legal-documents.employees-addition.single-employee.title': {
            id: 'forms.legal-documents.employees-addition.single-employee.title',
            defaultMessage: 'Ajout manuel d’un salarié',
        },
        'legal-documents.employee-modification.title': {
            id: 'forms.legal-documents.employee-modification.title',
            defaultMessage: 'Modifier un salarié',
        },
        'legal-documents.status.description': {
            id: 'widgets.legal-documents.status.description',
            defaultMessage:
                'En désactivant le salarié, plus aucun mail ne lui sera envoyé pour ce document. Il ne pourra pas non plus valider la réception de ce document si un lien lui a déjà été communiqué',
        },
        'legal-documents.employees-addition.from-document.title': {
            id: 'forms.legal-documents.employees-addition.from-document.title',
            defaultMessage: "Ajout des salariés à partir d'un autre document",
        },
        'legal-documents.employees-addition.from-document.description': {
            id: 'forms.legal-documents.employees-addition.from-document.description',
            defaultMessage:
                'Importer des salariés depuis une campagne déjà créée afin de réutiliser la liste de salariés d’un autre document',
        },
        'existing-legal-documents.title': {
            id: 'forms.existing-legal-documents.title',
            defaultMessage: 'Campagne',
        },
        'management-environment.health.title': {
            id: 'widgets.management-environment.health.title',
            defaultMessage: 'Environnement de gestion santé',
        },
        'management-environment.provident.title': {
            id: 'widgets.management-environment.provident.title',
            defaultMessage: 'Environnement de gestion prévoyance',
        },
        'management-environment.linkedTo-description': {
            id: 'widgets.management-environment.linkedTo-description',
            defaultMessage: 'Santé et/ou prévoyance',
        },
        'group.linkedTo-description': {
            id: 'widgets.group.linkedTo-description',
            defaultMessage:
                "L'entreprise héritera des environnements de gestion du groupe",
        },
        environments: {
            id: 'widgets.environments.label',
            defaultMessage: 'Des environnements',
        },
        none: {
            id: 'widgets.none',
            defaultMessage: 'Aucun',
        },
        'date.company-leave-date.title': {
            id: 'widgets.date.company-leave-date.title',
            defaultMessage: 'Date de sortie',
        },
        'search.exemption-placeholder': {
            id: 'widgets.search.exemption-placeholder',
            defaultMessage: 'Nom ou prénom du salarié',
        },
        'legal-documents.employees-addition.from-file.title': {
            id: 'forms.legal-documents.employees-addition.from-file.title',
            defaultMessage: "Ajout de salariés à partir d'un fichier",
        },
        'legal-documents.employees-addition.from-file.description': {
            id: 'forms.legal-documents.employees-addition.from-file.description',
            defaultMessage:
                "Afin d'ajouter des salariés, votre fichier tableur (csv) doit être correctement configuré. Merci d'utiliser le fichier d'exemple disponible ci-dessous pour créer votre propre fichier salarié.",
        },
        'legal-documents.employees-addition.from-file.file-example.title': {
            id: 'widgets.legal-documents.employees-addition.from-file.file-example.title',
            defaultMessage: "Obtenir le fichier d'exemple",
        },
        'legal-documents.employees-addition.from-file.file-example.description':
            {
                id: 'widgets.legal-documents.employees-addition.from-file.file-example.description',
                defaultMessage:
                    'Vous pouvez télécharger et utiliser ce fichier pour y ajouter vos salariés',
            },

        'attachments.employees-file.title': {
            id: 'widgets.attachments.employees-file.title',
            defaultMessage: 'Importer ici votre fichier',
        },
        'attachments.employees-file.description': {
            id: 'widgets.attachments.employees-file.description',
            defaultMessage:
                "Utilisez le fichier d'exemple fourni ci-dessus ou fournissez un fichier au format CSV, séparateur “;” et encodé en UTF-8. <br></br><b>Attention</b>, veillez à bien supprimer la 2ème ligne du fichier d'exemple avant d'y ajouter vos salariés.",
        },
    }),
};
