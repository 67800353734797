import React from 'react';
import {
    FormattedMessage,
    MessageDescriptor,
    defineMessages,
} from 'react-intl';
import {
    Stack,
    Container,
    Box,
    Button,
    ContainerProps,
    Text,
} from '@chakra-ui/react';
import { Heading, LinkIntl, SafeFormattedMessage } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ArrowRightIcon } from 'design-system/icons';
import SentConfirmationIcon from 'design-system/icons/components/SentConfirmationIcon';
import { FormLink } from 'core/lib/forms/types';
import FormSatisfaction from './FormSatisfaction';
import { UtilsContext } from '../../../lib';

type Props = {
    showSatisfaction: boolean;
    title?: MessageDescriptor;
    description?: MessageDescriptor;
    buttonLabel?: MessageDescriptor;
    boundaryComponent: React.ComponentType<any>;
    containerProps?: ContainerProps;
    descriptionMessage?: string;
    link?: FormLink;
};

const intlMessages = defineMessages({
    hospitalizationDescription: {
        id: 'confirmation-page.hospitalization-services-description',
        defaultMessage:
            "Nous vous proposons des services d'accompagnement dans le cadre de votre hospitalisation.",
    },
    supportServices: {
        id: 'buttons.discover-services',
        defaultMessage: 'Découvrir ces services',
    },
    goToMessages: {
        id: 'buttons.access-messages',
        defaultMessage: 'Accéder à la messagerie',
    },
    goToJournal: {
        id: 'components.forms.satisfaction.textarea-back-to-journal',
        defaultMessage: 'Retourner sur le journal',
    },
    confirmationMessage: {
        id: 'components.forms.satisfaction.title',
        defaultMessage: 'Votre demande a bien été envoyée',
    },
    'confirmation-page.description': {
        id: 'confirmation-page.description',
        defaultMessage:
            'Nous vous confirmons l’envoi de votre demande. Elle sera traitée par votre centre de gestion dans les meilleurs délais. Vous pouvez suivre son traitement depuis la rubrique Messages.',
    },
    'confirmation-page.beneficiary-addition-description': {
        id: 'confirmation-page.beneficiary-addition-description',
        defaultMessage:
            "Les nouveaux bénéficiaires que vous souhaitez ajouter à votre contrat ne seront visibles sur votre espace qu'à partir de la date d'effet de leur affiliation",
    },
    'confirmation-page.beneficiary-deletion-description': {
        id: 'confirmation-page.beneficiary-deletion-description',
        defaultMessage:
            'Lorsque vos bénéficiaires seront radiés, ils ne seront plus visibles sur votre espace personnel',
    },
});

function FormSentConfirmationRedirect({
    buttonLabel,
    description,
    link,
}: Pick<Props, 'buttonLabel' | 'description' | 'link'>) {
    const {
        siteConfig: { homePagePath },
    } = React.useContext(UtilsContext);
    const linkTypes = {
        'sections-themes': {
            pathname: `/included-services/sections-themes/:quickAccessCode`,
            query: {
                quickAccessCode: link?.code,
            },
        },
    };
    const linkTo = linkTypes[link?.type];
    const path = link ? linkTo : homePagePath;
    const { isMobile } = useWindowBreakpoints();
    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <Stack spacing="4" textAlign="center">
            {description ? (
                <Text>
                    <FormattedMessage {...description} />
                </Text>
            ) : (
                link && (
                    <Text fontSize="sm">
                        <FormattedMessage
                            {...intlMessages.hospitalizationDescription}
                        />
                    </Text>
                )
            )}
            <LinkIntl href={path} passHref>
                <Button
                    w={isMobile ? 'auto' : 'fit-content'}
                    alignSelf="center"
                    as="a"
                    colorScheme="primary"
                    onClick={() => setIsLoading(true)}
                    isLoading={isLoading}
                    rightIcon={<ArrowRightIcon ml="0" w={5} h={5} />}
                    size={isMobile ? 'sm' : 'md'}>
                    {buttonLabel ? (
                        <FormattedMessage {...buttonLabel} />
                    ) : (
                        <FormattedMessage
                            {...(linkTo
                                ? intlMessages.supportServices
                                : intlMessages.goToJournal)}
                        />
                    )}
                </Button>
            </LinkIntl>
        </Stack>
    );
}

export function FormSentConfirmation({
    showSatisfaction,
    title,
    description,
    buttonLabel,
    boundaryComponent,
    containerProps,
    descriptionMessage,
    link,
}: Props) {
    const { isMobile } = useWindowBreakpoints();

    return (
        <>
            <Container
                as="main"
                display="flex"
                justifyContent="center"
                flexDirection="column">
                <Box bgColor="primary.50" py="12" my="10" borderRadius="lg">
                    <Container as="section" {...containerProps}>
                        <Stack alignItems="center">
                            <Heading as="h2" lineHeight="10" textAlign="center">
                                <FormattedMessage
                                    {...(title ||
                                        intlMessages.confirmationMessage)}
                                />
                            </Heading>
                            {descriptionMessage &&
                                descriptionMessage in intlMessages && (
                                    <Text
                                        color="black"
                                        fontSize="sm"
                                        textAlign="center">
                                        <SafeFormattedMessage
                                            {...intlMessages[
                                                descriptionMessage
                                            ]}
                                            debugKey={`SentConfirmation descriptionMessage - ${descriptionMessage}`}
                                        />
                                    </Text>
                                )}
                            <Stack pt="8" pb="8">
                                <SentConfirmationIcon />
                            </Stack>
                            <FormSentConfirmationRedirect
                                description={description}
                                buttonLabel={buttonLabel}
                                link={link}
                            />
                        </Stack>
                    </Container>
                </Box>
            </Container>
            <Box
                as="section"
                bg={showSatisfaction && 'bg.colorlight'}
                px={isMobile && '4'}>
                <Container textAlign="center" px={isMobile && '0'}>
                    {showSatisfaction && (
                        <FormSatisfaction {...{ boundaryComponent }} />
                    )}
                </Container>
            </Box>
        </>
    );
}
