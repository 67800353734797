import { Icon, IconProps } from '@chakra-ui/react';

export default function ArrowRightIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M15.939 13H6a1 1 0 1 1 0-2h9.939l-2.833-3.596a1 1 0 1 1 1.571-1.237l4.108 5.214a1 1 0 0 1 0 1.238l-4.108 5.214a1 1 0 0 1-1.57-1.237L15.938 13z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
