import { Logger } from 'scripts';

type Prescription = {
    size: number;
    name: string;
    content: string;
};

type Prescriptions = {
    [key: string]: string;
};

type FileData = {
    name: string;
    content: string;
};

export const extractPrescriptionFiles = (
    prescriptions: Prescriptions
): FileData[] => {
    return Object.entries(prescriptions)
        .map(([key, prescriptionStr]) => {
            try {
                const prescriptionData: Prescription =
                    JSON.parse(prescriptionStr);
                const { name, content } = prescriptionData;
                return {
                    name,
                    content: content.replace('data:', '').replace(/^.+,/, ''),
                };
            } catch (error) {
                Logger.error(
                    `Error while parsing prescription file for key ${key}: ${error}`
                );
                return null;
            }
        })
        .filter(Boolean) as FileData[];
};
