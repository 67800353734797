import React from 'react';
import { useRouter } from 'next/router';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
    LinkOverlay,
    LinkBox,
    CloseButton,
    Flex,
    Box,
    Text,
    HStack,
    Button,
    Spinner,
} from '@chakra-ui/react';

import { useUserAgent, coreSharedMessages } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { DownloadIcon, WarningIcon } from 'design-system/icons';

import { MutationStatus } from '@tanstack/react-query';
import DownloadStartingToast from '../DownloadStartingToast';

const intlMessages = defineMessages({
    downloadStarting: {
        id: 'documents.preview.dowload-starting',
        defaultMessage: 'Votre téléchargement va démarrer.',
    },
});

type ButtonDownloadFile = {
    status: MutationStatus;
    fileLink: string;
    isDesktop: boolean;
};

function ButtonDownloadFile({
    status,
    fileLink,
    isDesktop,
}: ButtonDownloadFile) {
    const { formatMessage } = useIntl();
    const [hasDownloadBeenPressed, setHasDownloadBeenPressed] =
        React.useState<boolean>(false);
    const { isIOS, isAndroid } = useUserAgent();
    const [showDownloadStartingToast, setShowDownloadStartingToast] =
        React.useState(false);

    React.useEffect(() => {
        if (isIOS) {
            window.onpopstate = () => setHasDownloadBeenPressed(false);
        }
    }, [isIOS]);

    function onDownloadStart() {
        setHasDownloadBeenPressed(true);
        if (isAndroid) {
            setShowDownloadStartingToast(true);
        }
    }

    const isSuccess = status === 'success';

    return (
        <>
            <LinkBox
                tabIndex={-1}
                as={Button}
                rounded="full"
                bg="bg.light"
                _hover={{ bg: 'grey.500' }}
                _focus={{ bg: 'grey.500' }}
                _active={{ bg: 'grey.600' }}
                color="black"
                p={!isDesktop && 0}
                disabled={status === 'error'}
                isLoading={status === 'pending'}>
                <LinkOverlay
                    download={isSuccess}
                    onClick={isSuccess ? onDownloadStart : null}
                    href={isSuccess ? fileLink : null}>
                    <HStack>
                        {!isIOS && isDesktop && isSuccess && (
                            <Text>
                                <FormattedMessage
                                    {...coreSharedMessages.buttonDownload}
                                />
                            </Text>
                        )}
                        {status === 'error' ? (
                            <WarningIcon />
                        ) : isIOS && hasDownloadBeenPressed ? (
                            <Spinner color="white" size="xs" />
                        ) : (
                            <DownloadIcon />
                        )}
                    </HStack>
                </LinkOverlay>
            </LinkBox>
            {showDownloadStartingToast && (
                <DownloadStartingToast
                    message={formatMessage(intlMessages.downloadStarting)}
                />
            )}
        </>
    );
}

export default function DocumentPreviewHeader({
    fileLink,
    status,
}: {
    fileLink: string | undefined;
    status: MutationStatus;
}) {
    const [isVisible, setIsVisible] = React.useState(true);
    const router = useRouter();
    const { isDesktop } = useWindowBreakpoints();

    React.useEffect(() => {
        if (isDesktop) {
            setIsVisible(true);
        }
    }, [isDesktop]);

    return (
        <Box
            as="header"
            pos="fixed"
            top="0"
            left="0"
            right="0"
            display="flex"
            justifyContent="space-between"
            zIndex={1}
            transform={isVisible ? 'translateY(0)' : 'translateY(-100px)'}
            transition="ease-in-out 0.3s"
            bg="grey.900"
            color="white"
            minW="100%"
            p="2">
            <Flex w="100%" alignItems="center" justifyContent="space-between">
                <ButtonDownloadFile
                    fileLink={fileLink}
                    status={status}
                    isDesktop={isDesktop}
                />
                <CloseButton
                    mt="1"
                    ml="4"
                    rounded="full"
                    bg="bg.light"
                    color="black"
                    _hover={{ bg: 'grey.500' }}
                    _focus={{ bg: 'grey.500' }}
                    _active={{ bg: 'grey.600' }}
                    onClick={router.back}
                />
            </Flex>
        </Box>
    );
}
