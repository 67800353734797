import React from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { Accordion, AccordionProps } from 'design-system/components';
import BlockText from 'core/components/BlockText';
import type { Wysiwyg } from 'core/types/wysiwyg';

type BlockToggleProps = AccordionProps & {
    wysiwyg?: Wysiwyg;
};

export default function BlockToggle({ wysiwyg, ...props }: BlockToggleProps) {
    const router = useRouter();

    const onMissingIcon = (icon: AccordionProps['icon']) => {
        Sentry.captureMessage(
            `BlockToggle: Unhandled icon "${icon}" on ${router.pathname}`,
            {
                ...router.query,
            }
        );
    };

    return (
        <Accordion {...props} onMissingIcon={onMissingIcon}>
            <BlockText
                wysiwyg={wysiwyg}
                containerProps={{
                    color: 'black',
                    fontWeight: 'semibold',
                }}
            />
        </Accordion>
    );
}
