export default function MedicalibLogo() {
    return (
        <svg
            width="110"
            height="30"
            viewBox="0 0 110 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M37.0808 10.7988C36.2942 10.7988 35.6531 10.9621 35.1575 11.2887C34.7052 11.5721 34.3218 11.9475 34.0334 12.3888H33.9956C33.7072 11.9475 33.3237 11.5721 32.8715 11.2887C32.3759 10.9621 31.7348 10.7988 30.9482 10.7988C29.6397 10.7988 28.6461 11.1533 27.9673 11.8623C27.2885 12.5713 26.9485 13.5592 26.9473 14.8261V19.6902H29.5763V15.0109C29.5763 14.3821 29.7068 13.8922 29.9677 13.5412C30.1119 13.3774 30.2908 13.246 30.4923 13.1558C30.694 13.0657 30.913 13.019 31.135 13.019C31.3568 13.019 31.576 13.0657 31.7775 13.1558C31.9792 13.246 32.158 13.3774 32.3022 13.5412C32.5621 13.8899 32.6925 14.3798 32.6937 15.0109V19.6902H35.3227V15.0109C35.3227 14.3821 35.4526 13.8922 35.7124 13.5412C35.8568 13.3777 36.0358 13.2465 36.2373 13.1565C36.4388 13.0665 36.6579 13.0199 36.8796 13.0199C37.1014 13.0199 37.3205 13.0665 37.522 13.1565C37.7235 13.2465 37.9025 13.3777 38.0469 13.5412C38.3078 13.8899 38.4378 14.3798 38.4366 15.0109V19.6902H41.0656V14.8261C41.0656 13.5569 40.7262 12.569 40.0474 11.8623C39.3686 11.1557 38.3796 10.8011 37.0808 10.7988Z"
                fill="#00995C"
            />
            <path
                d="M49.4536 11.3872C48.7318 10.9872 47.9123 10.7842 47.0814 10.7997C46.2474 10.7859 45.4249 10.99 44.7002 11.3907C44.0081 11.778 43.441 12.3449 43.0624 13.0277C42.6758 13.7452 42.4731 14.5426 42.4714 15.3524C42.47 16.162 42.6695 16.9603 43.0535 17.6791C43.4322 18.362 44.0034 18.9262 44.7002 19.3057C45.4515 19.7098 46.2997 19.9136 47.1586 19.8967C48.0909 19.9105 49.0082 19.6689 49.8055 19.1994C50.6007 18.7213 51.1687 17.9574 51.3858 17.0742H49.0064C48.8355 17.3383 48.5846 17.5447 48.2881 17.6652C47.9076 17.8024 47.503 17.8651 47.0975 17.85C46.5916 17.8665 46.0949 17.7166 45.6878 17.4246C45.2874 17.1116 45.0114 16.6735 44.9067 16.185H51.63C51.6426 15.9514 51.6498 15.6742 51.6498 15.3534C51.6604 14.5447 51.4603 13.7464 51.068 13.033C50.6991 12.3485 50.1397 11.7783 49.4536 11.3872ZM44.8905 14.4747C45.0004 14.0087 45.2734 13.5941 45.6627 13.3014C46.0714 13.0053 46.5715 12.8523 47.0814 12.8673C47.5991 12.8558 48.1055 13.015 48.518 13.3189C48.9084 13.5981 49.1743 14.0116 49.2614 14.4747H44.8905Z"
                fill="#00995C"
            />
            <path
                d="M59.8206 12.2187C59.5701 11.7694 59.1851 11.4046 58.7162 11.1726C58.1864 10.9142 57.5994 10.7861 57.0066 10.7995C56.319 10.8013 55.6444 10.9819 55.0528 11.3225C54.4117 11.6938 53.8904 12.2319 53.548 12.8759C53.1434 13.6413 52.9439 14.4933 52.9679 15.3533C52.9435 16.2133 53.143 17.0655 53.548 17.8307C53.8894 18.4754 54.4108 19.0137 55.0528 19.384C55.6444 19.7247 56.319 19.9053 57.0066 19.9071C57.6253 19.9252 58.2382 19.7883 58.7862 19.5096C59.263 19.2576 59.6462 18.8668 59.8816 18.392L60.0343 19.7048H62.4532V7.0791H59.8206V12.2187ZM59.2154 17.0758C59.0243 17.2649 58.7959 17.415 58.544 17.5169C58.2918 17.6187 58.0212 17.6703 57.7483 17.6685C57.4556 17.6767 57.1643 17.6248 56.8937 17.5163C56.6229 17.4079 56.3786 17.2454 56.177 17.0392C55.7639 16.6207 55.5574 16.0536 55.5574 15.3376C55.5574 14.6217 55.7639 14.0545 56.177 13.636C56.3788 13.4301 56.623 13.2678 56.8937 13.1596C57.1645 13.0515 57.4557 13 57.7483 13.0084C58.021 13.0068 58.2915 13.0582 58.5434 13.1597C58.7956 13.2612 59.024 13.4108 59.2154 13.5994C59.6345 13.9934 59.8439 14.5746 59.8439 15.3428C59.8439 16.1111 59.6345 16.6887 59.2154 17.0758Z"
                fill="#00995C"
            />
            <path
                d="M67.2353 10.998H64.6063V19.6889H67.2353V10.998Z"
                fill="#00995C"
            />
            <path
                d="M65.9262 6.61532C65.7126 6.61321 65.5009 6.65217 65.303 6.72996C65.1051 6.80777 64.925 6.92287 64.7733 7.0686C64.6174 7.20875 64.4937 7.37907 64.4098 7.56854C64.3259 7.75803 64.2839 7.96248 64.2866 8.16869C64.2845 8.37097 64.3268 8.57138 64.4107 8.75656C64.4946 8.94173 64.6183 9.10742 64.7733 9.24262C65.0852 9.52713 65.4971 9.6855 65.9253 9.6855C66.3532 9.6855 66.7651 9.52713 67.0772 9.24262C67.2326 9.10772 67.3565 8.94208 67.4403 8.75682C67.5244 8.57159 67.5664 8.37104 67.5639 8.16869C67.5669 7.96241 67.5251 7.75784 67.4412 7.56828C67.3574 7.37873 67.2335 7.20847 67.0772 7.0686C66.9257 6.92308 66.7459 6.8081 66.5484 6.73031C66.3507 6.6525 66.1393 6.61343 65.9262 6.61532Z"
                fill="#00995C"
            />
            <path
                d="M73.5385 13.0153C74.4543 13.0153 75.0769 13.4157 75.4061 14.2165H77.9399C77.7941 13.2344 77.2658 12.344 76.4638 11.7287C75.658 11.1092 74.6644 10.7995 73.4828 10.7995C72.6244 10.7858 71.7772 10.9895 71.0244 11.3905C70.3122 11.7737 69.7225 12.3402 69.3202 13.0275C68.914 13.7373 68.7007 14.536 68.7007 15.348C68.7007 16.1601 68.914 16.9587 69.3202 17.6685C69.7225 18.3559 70.3122 18.9223 71.0244 19.3055C71.7772 19.7065 72.6244 19.9101 73.4828 19.8965C74.6621 19.8965 75.6557 19.5857 76.4638 18.9638C77.2658 18.3486 77.7941 17.4582 77.9399 16.476H75.4061C75.0757 17.278 74.4532 17.6789 73.5385 17.6789C73.2364 17.6882 72.9356 17.6343 72.6568 17.5208C72.3781 17.4075 72.1275 17.2372 71.9223 17.0217C71.5164 16.553 71.2939 15.9603 71.2939 15.348C71.2939 14.7357 71.5164 14.1431 71.9223 13.6743C72.1272 13.4584 72.3777 13.2877 72.6566 13.174C72.9355 13.0603 73.2363 13.0062 73.5385 13.0153Z"
                fill="#00995C"
            />
            <path
                d="M86.0926 12.3496C85.8574 11.8637 85.4704 11.4623 84.9864 11.2025C84.4297 10.9186 83.8066 10.7798 83.1781 10.7997C82.4797 10.802 81.7941 10.9824 81.1902 11.3227C80.5408 11.6894 80.0125 12.2283 79.6674 12.8761C79.2612 13.641 79.0611 14.4933 79.0855 15.3535C79.0608 16.2137 79.261 17.066 79.6674 17.8309C80.0111 18.4798 80.5396 19.019 81.1902 19.3842C81.7952 19.7252 82.4821 19.9055 83.1817 19.9073C83.8054 19.9256 84.4235 19.7887 84.9775 19.5098C85.4578 19.2598 85.8444 18.8687 86.0818 18.3922L86.2183 19.6911H88.5528V10.9985H86.3207L86.0926 12.3496ZM85.4066 17.076C85.2123 17.2664 84.9805 17.417 84.7251 17.5188C84.4698 17.6206 84.1958 17.6717 83.9198 17.6687C83.62 17.6788 83.3216 17.6279 83.0432 17.5197C82.7649 17.4112 82.5129 17.2477 82.3036 17.0394C81.8834 16.6209 81.6738 16.0538 81.675 15.3378C81.6474 14.7184 81.8733 14.1136 82.3036 13.6554C82.5101 13.4423 82.7611 13.2744 83.0398 13.1628C83.3185 13.0513 83.6184 12.9987 83.9198 13.0086C84.2728 13.0113 84.6187 13.1071 84.92 13.2858C85.2551 13.4807 85.5313 13.7579 85.7209 14.0895C85.9376 14.4713 86.0461 14.902 86.0352 15.3378C86.0375 16.1084 85.8281 16.6877 85.4066 17.076Z"
                fill="#00995C"
            />
            <path
                d="M93.387 7.0791H90.7581V19.6909H93.387V7.0791Z"
                fill="#1172D9"
            />
            <path
                d="M96.9498 6.61532C96.7363 6.61321 96.5246 6.65217 96.3267 6.72996C96.1288 6.80777 95.9487 6.92287 95.7969 7.0686C95.6411 7.20875 95.5173 7.37907 95.4335 7.56854C95.3496 7.75803 95.3078 7.96248 95.3103 8.16869C95.3081 8.37097 95.3505 8.57138 95.4344 8.75656C95.5184 8.94173 95.642 9.10742 95.7969 9.24262C96.1089 9.52713 96.5208 9.6855 96.9489 9.6855C97.377 9.6855 97.789 9.52713 98.1009 9.24262C98.2562 9.10772 98.3802 8.94208 98.464 8.75682C98.5481 8.57159 98.5901 8.37104 98.5876 8.16869C98.5906 7.96241 98.5488 7.75784 98.4651 7.56828C98.3812 7.37873 98.2571 7.20847 98.1009 7.0686C97.9493 6.92308 97.7696 6.8081 97.572 6.73031C97.3745 6.6525 97.163 6.61343 96.9498 6.61532Z"
                fill="#1172D9"
            />
            <path
                d="M98.2644 10.998H95.6354V19.6889H98.2644V10.998Z"
                fill="#1172D9"
            />
            <path
                d="M109.418 12.8672C109.074 12.2264 108.553 11.6914 107.913 11.3225C107.322 10.9822 106.648 10.8016 105.961 10.7995C105.367 10.7843 104.778 10.9112 104.246 11.1691C103.777 11.4011 103.392 11.7659 103.142 12.2152V7.0791H100.513V19.6909H102.931L103.084 18.3781C103.32 18.8525 103.703 19.2433 104.18 19.4956C104.728 19.7746 105.342 19.9114 105.961 19.8931C106.648 19.891 107.322 19.7104 107.913 19.3701C108.555 18.9998 109.076 18.4614 109.418 17.8167C109.824 17.0517 110.023 16.1994 109.998 15.3393C110.022 14.481 109.822 13.6307 109.418 12.8672ZM106.789 17.0514C106.587 17.2576 106.343 17.4201 106.072 17.5285C105.801 17.637 105.51 17.6889 105.217 17.6807C104.945 17.6823 104.674 17.6305 104.422 17.5287C104.17 17.4269 103.942 17.2771 103.75 17.088C103.331 16.694 103.122 16.1129 103.122 15.3446C103.122 14.5763 103.331 13.9952 103.75 13.6012C103.942 13.4127 104.171 13.2632 104.422 13.1617C104.674 13.0603 104.945 13.0087 105.217 13.0102C105.51 13.0017 105.801 13.0532 106.072 13.1614C106.343 13.2695 106.587 13.4319 106.789 13.6378C107.202 14.0574 107.408 14.6245 107.408 15.3393C107.408 16.0541 107.202 16.623 106.789 17.0461V17.0514Z"
                fill="#1172D9"
            />
            <path
                d="M13.1932 12.3706C13.5231 11.5924 13.1408 10.7019 12.3391 10.3817C11.5377 10.0614 10.6204 10.4326 10.2905 11.2108C9.96064 11.989 10.343 12.8795 11.1446 13.1998C11.946 13.5201 12.8633 13.1489 13.1932 12.3706Z"
                fill="#00995C"
            />
            <path
                d="M15.5514 4C14.0357 4 12.9116 4.3295 11.7174 4.82289C10.5214 4.33125 9.39906 4 7.88341 4C3.4335 4 0 7.07536 0 11.4129C0 15.0479 2.06872 17.222 4.60075 19.5337L11.6815 26H11.7533L18.8322 19.5337C21.366 17.222 23.4348 15.0479 23.4348 11.4129C23.4348 7.07536 20.0102 4 15.5514 4ZM11.7443 8.294C13.7412 8.294 15.2568 9.92234 15.2568 11.7913C15.2568 13.6602 13.831 15.2885 11.7443 15.2885C9.65765 15.2885 8.22999 13.6637 8.22999 11.793C8.22999 9.92234 9.74564 8.29574 11.7443 8.29574V8.294ZM16.9018 18.1216L11.721 22.8288L6.53659 18.1216C4.42297 16.2038 2.48714 14.4604 2.48714 11.4042C2.48714 8.40035 4.73543 6.30478 7.82954 6.30478C8.38928 6.2913 8.94713 6.37272 9.47807 6.54537C8.38768 6.95299 7.45119 7.67363 6.79347 8.6112C6.13576 9.54875 5.78806 10.6587 5.79673 11.793C5.79673 15.0479 8.47961 17.4904 11.7443 17.4904C15.009 17.4904 17.6919 15.0497 17.6919 11.793C17.7012 10.6526 17.3509 9.53679 16.6881 8.5953C16.0253 7.65382 15.0814 6.93179 13.9836 6.52619C14.5053 6.36198 15.0516 6.28419 15.5998 6.29606C18.6939 6.29606 20.9404 8.38814 20.9404 11.3955C20.9458 14.4604 19.0118 16.1916 16.9018 18.1216Z"
                fill="#00995C"
            />
        </svg>
    );
}
