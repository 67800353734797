import { Icon, IconProps } from '@chakra-ui/react';

export default function FolderIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M4 3a2 2 0 0 0-2 2v13a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-8a3 3 0 0 0-3-3h-6.382l-1.447-2.894A2 2 0 0 0 9.382 3H4zm6.382 4-1-2H4v2h6.382zM4 18V9h15a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
