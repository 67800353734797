import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Img, Heading, VStack, Text, Button } from '@chakra-ui/react';

import MaintenanceIllustration from './MaintenanceIllustration';
import {
    LinkIntl,
    UtilsContext,
    coreSharedMessages,
    MaintenanceType,
    BlockText,
} from 'core';
import { ArrowLeftIcon } from 'design-system/icons';

type MaintenanceProps = {
    data: MaintenanceType;
    showBackButton?: boolean;
    Layout: React.ComponentType<any>;
    Footer?: React.ComponentType<any>;
};

export function Maintenance({
    data,
    showBackButton = false,
    Layout,
    Footer,
}: MaintenanceProps) {
    const intl = useIntl();
    const { siteConfig } = React.useContext(UtilsContext);

    return (
        <Layout Footer={Footer ? () => <Footer /> : undefined}>
            <VStack py="4" spacing="6" textAlign="center">
                <Img
                    alt={intl.formatMessage({
                        id: 'components.maintenance.logo',
                        defaultMessage: 'Logo principal',
                        description: 'Logo principal du site',
                    })}
                    htmlWidth={siteConfig.client.logo?.width}
                    src={siteConfig.client.logo?.path}
                    py={siteConfig.client.logo?.paddingVertical}
                />
                <MaintenanceIllustration />
                <Heading
                    fontSize="2xl"
                    fontWeight={800}
                    as="h2"
                    data-testid="maintenance">
                    <FormattedMessage
                        id="components.maintenance.title"
                        defaultMessage="Maintenance en cours"
                    />
                </Heading>
                {data?.message && (
                    <Text px="3" pb="3">
                        {typeof data.message === 'string' ? (
                            <BlockText
                                wysiwyg={{
                                    value: data?.message,
                                    format: 'html',
                                }}
                            />
                        ) : (
                            <BlockText wysiwyg={data?.message} />
                        )}
                    </Text>
                )}
                {showBackButton && (
                    <LinkIntl href="/login" passHref>
                        <Button as="a" variant="link" color="black" p="2">
                            <ArrowLeftIcon mr="1" w="4" h="4" />
                            <FormattedMessage {...coreSharedMessages.back} />
                        </Button>
                    </LinkIntl>
                )}
            </VStack>
        </Layout>
    );
}
