import { Icon, IconProps } from '@chakra-ui/react';

export default function FilterIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="m15 12 6.65-5.7a1 1 0 0 0 .35-.76V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2.54a1 1 0 0 0 .35.76L9 12v8.382a1 1 0 0 0 1.447.894l4-2a1 1 0 0 0 .553-.894V12zm-4 6.764 2-1V11.08l7-6V4H4v1.08l7 6v7.684z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
