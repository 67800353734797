import { defineMessages, MessageDescriptor } from '@formatjs/intl';
import { Theme } from 'themes/types';

export const doughnutMessages = defineMessages({
    insuranceAmount: {
        id: 'refunds.amount.complementary-health-insurance',
        description: 'Catégorie du remboursement - Complémentaire santé',
        defaultMessage: 'Complémentaire santé',
    },
    insuranceAmount_coInsurance: {
        id: 'refunds.amount.complementary-health-coinsurance',
        description:
            'Catégorie du remboursement - Complémentaire santé (co-assurance)',
        defaultMessage: 'Complémentaire santé',
    },
    otherInsuranceAmount: {
        id: 'refunds.amount.other-insurance',
        description: 'Catégorie du remboursement - Autre complémentaire',
        defaultMessage: 'Autre complémentaire',
    },
    totalAmount: {
        id: 'refunds.amount.total',
        description: 'Catégorie du remboursement - Montant total',
        defaultMessage: 'Montant total',
    },
    socialSecurityAmount: {
        id: 'refunds.amount.social-security',
        description: 'Catégorie du remboursement - Sécurité sociale',
        defaultMessage: 'Sécurité sociale',
    },
    remainingAmount: {
        id: 'refunds.amount.remaining',
        description: 'Catégorie du remboursement - Reste à charge',
        defaultMessage: 'Reste à charge',
    },
    default: {
        id: 'refunds.amount.unknown',
        description: 'Catégorie du remboursement - Défault',
        defaultMessage: 'Inconnu',
    },
});

function manageChart(
    amoutName: string,
    hasCoInsurer: boolean = false,
    theme: Theme
): {
    name: MessageDescriptor;
    color: string;
} {
    switch (amoutName) {
        case 'insuranceAmount':
            return {
                name: hasCoInsurer
                    ? doughnutMessages.insuranceAmount_coInsurance
                    : doughnutMessages.insuranceAmount,
                color: theme.colors.donutsRefunds.main,
            };
        case 'otherInsuranceAmount':
            return {
                name: doughnutMessages.otherInsuranceAmount,
                color: theme.colors.donutsRefunds.other,
            };
        case 'totalAmount':
            return {
                name: doughnutMessages.totalAmount,
                color: theme.colors.donutsRefunds.other,
            };
        case 'socialSecurityAmount':
            return {
                name: doughnutMessages.socialSecurityAmount,
                color: theme.colors.donutsRefunds.socialSecurity,
            };
        case 'remainingAmount':
            return {
                name: doughnutMessages.remainingAmount,
                color: theme.colors.donutsRefunds.remaining,
            };
        default:
            return {
                name: doughnutMessages.default,
                color: '#f25767',
            };
    }
}

export { manageChart };
