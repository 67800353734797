import { defineMessages, useIntl } from 'react-intl';
import { HStack, Stack, Box } from '@chakra-ui/react';
import { uniqueId } from 'lodash';
import { Markdown } from 'design-system/components';
import { List, ListItem, capitalizeHyphenatedName } from 'core';
import { AttentionIcon } from 'design-system/icons';

type LinkedToMessageProps = {
    beneficiaries: string[];
};

const linkedToMessage = defineMessages({
    title: {
        id: 'components.beneficiary-select.linked-to.title',
        defaultMessage: `
            **Attention**, ce changement de RIB impactera également :
        `,
    },
    more: {
        id: 'components.beneficiary-select.linked-to.more',
        defaultMessage: `
            Si vous le souhaitez, vous pourrez modifier par la suite le RIB associé à {noOfBeneficiary, plural, one {ce bénéficiaire} other {ces bénéficiaires}}.
        `,
    },
});

function LinkedToMessage({ beneficiaries }: LinkedToMessageProps) {
    const intl = useIntl();
    return (
        <HStack
            spacing="3"
            p="4"
            alignItems="flex-start"
            bg="orange.50"
            rounded="sm">
            <AttentionIcon w="6" h="6" mt="1" color="warning.main" />
            <Stack spacing="2">
                <Stack spacing="1">
                    <Markdown
                        content={intl.formatMessage(linkedToMessage.title)}
                    />
                    <Box
                        sx={{
                            li: {
                                mt: 0,
                                ':before': {
                                    bg: 'black',
                                    w: 1.5,
                                    h: 1.5,
                                    mr: 3,
                                },
                            },
                        }}>
                        <List>
                            {beneficiaries.map((beneficiary) => (
                                <ListItem key={uniqueId()}>
                                    {capitalizeHyphenatedName(beneficiary)}
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Stack>
                <Markdown
                    content={intl.formatMessage(linkedToMessage.more, {
                        noOfBeneficiary: beneficiaries.length,
                    })}
                />
            </Stack>
        </HStack>
    );
}

export default LinkedToMessage;
