import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
    APIError,
    FetchQueryParams,
    FormObject,
    WorkflowTypesEnum,
} from 'core';
import {
    SignatureCapitalBeneficiary,
    SignatureFormReason,
    serviceTypes,
} from '../../types';

export const signatureBeneficiariesFormPath = `/api/proxy/[service]/beneficiaries-designation/[type]/[procedureId]/beneficiaries/[selectedReasonType]`;

async function fetchSignatureBeneficiariesForm({
    queryKey: [
        urn,
        service,
        type,
        procedureId,
        reasonType,
        selectedBeneficiaryId,
    ],
}: FetchQueryParams) {
    try {
        const response = await axios.options(
            `${urn
                .replace('[service]', serviceTypes[service])
                .replace('[type]', type)
                .replace('[procedureId]', procedureId)
                .replace('[selectedReasonType]', reasonType)}${
                selectedBeneficiaryId
                    ? `?beneficiaryId=${selectedBeneficiaryId}`
                    : ''
            }`
        );

        return response?.data?.data as unknown as FormObject;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useSignatureBeneficiariesForm(
    service: WorkflowTypesEnum,
    type: 'full-capital' | 'divided-capital',
    procedureId: string,
    selectedReasonType: SignatureFormReason | string,
    selectedBeneficiary?: SignatureCapitalBeneficiary
) {
    return useQuery<FormObject, APIError>({
        queryKey: [
            signatureBeneficiariesFormPath,
            service,
            type,
            procedureId,
            selectedReasonType || selectedBeneficiary?.type,
            selectedBeneficiary?.id,
        ],

        queryFn: fetchSignatureBeneficiariesForm,
    });
}
