import { defineMessages } from 'react-intl';

export const signatureBeneficiariesDesignationMessages = {
    'person-quality': defineMessages({
        title: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiary.person-quality-title',
            defaultMessage: 'Personne physique par sa qualité',
        },
        description: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiary.person-quality-description',
            defaultMessage: 'Mon conjoint, mes enfants...',
        },
    }),
    'person-name': defineMessages({
        title: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiary.person-name-title',
            defaultMessage: 'Personne physique nominativement',
        },
        description: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiary.person-name-description',
            defaultMessage: 'Jean Dupont, Marie Durant...',
        },
    }),
    'legal-entity': defineMessages({
        title: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiary.legal-entity-title',
            defaultMessage: 'Personne morale',
        },
        description: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiary.legal-entity-description',
            defaultMessage: 'Association, entreprise...',
        },
    }),
};
