import { Icon } from '@chakra-ui/react';

export default function TextExchangeIllustration() {
    return (
        <Icon
            width="201"
            height="101"
            viewBox="0 0 201 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10547_85266)">
                <path
                    d="M143.344 99.4963H2.61676C0.666385 99.4963 -0.341508 97.1654 0.994421 95.7442L19.6399 75.9135C20.7013 74.7847 22.183 74.1435 23.733 74.1435H123.463C125.08 74.1435 126.618 74.8402 127.685 76.0552L145.017 95.7997C146.28 97.2387 145.258 99.4953 143.344 99.4953V99.4963Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M199.169 7.03821V53.1969C199.169 57.0104 195.043 60.1302 190 60.1302H85.5416C80.4982 60.1302 76.3724 57.0104 76.3724 53.1969V7.03821C76.3724 3.22468 80.4982 0.104889 85.5416 0.104889H190C195.043 0.104889 199.169 3.22468 199.169 7.03821Z"
                    fill="white"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M69.3587 26.4398V22.9394H65.6066C64.2776 22.9394 63.1993 21.8622 63.1993 20.5322V11.4107C63.1993 10.0817 64.2766 9.00344 65.6066 9.00344H95.993C97.322 9.00344 98.4003 10.0807 98.4003 11.4107V20.5322C98.4003 21.8612 97.323 22.9394 95.993 22.9394H72.5399L69.3587 26.4398Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M67.2034 19.0635H94.687"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M67.2034 15.9893H92.5513"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M67.2034 12.9151H94.687"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M183.378 25.7074V35.377C183.378 38.2114 181.08 40.5086 178.247 40.5086H173.566C170.732 40.5086 168.434 38.2104 168.434 35.377V25.7074C168.434 23.6926 169.595 21.9494 171.285 21.11C171.971 20.7681 172.746 20.5758 173.567 20.5758H178.248C178.929 20.5758 179.579 20.7086 180.174 20.9494C182.054 21.7106 183.38 23.5539 183.38 25.7074H183.378Z"
                    fill="#D5F4F9"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M144.295 35.2582C144.233 35.5902 144.152 35.9361 144.052 36.29C144.009 36.4426 143.964 36.5913 143.917 36.736C143.897 36.7975 143.877 36.8589 143.857 36.9194C143.79 37.1146 143.72 37.304 143.648 37.4844C143.626 37.5399 143.604 37.5934 143.581 37.6469C143.577 37.6548 143.575 37.6628 143.571 37.6697C143.554 37.6717 143.538 37.6747 143.522 37.6776C143.523 37.6657 143.523 37.6529 143.524 37.64C143.201 37.5964 142.935 37.526 142.714 37.4417C142.708 37.4457 142.701 37.4487 142.695 37.4536C141.928 37.977 140.478 37.8481 140.752 37.191C140.792 37.0938 140.836 37.0066 140.883 36.9293C140.962 36.7965 141.048 36.6894 141.128 36.6062H141.126C140.865 36.6141 140.546 36.6904 140.21 36.7945H140.209C139.993 36.8609 139.769 36.9412 139.549 37.0205C139.45 36.9452 139.358 36.8787 139.267 36.8292C139.147 36.7628 139.025 36.7231 138.881 36.7281C138.417 36.7439 137.775 36.968 137.175 37.187C136.852 37.3069 136.54 37.4239 136.277 37.5062C136.028 37.5825 135.823 37.6261 135.691 37.6112C135.16 37.5468 133.827 37.0175 135.691 35.9579C137.361 35.0074 139.343 34.5078 140.834 34.6357H140.838C140.913 34.6417 140.987 34.6496 141.059 34.6605C141.15 34.6724 141.239 34.6863 141.327 34.7041C141.541 34.7467 141.736 34.7883 141.92 34.829C142.667 34.9945 143.224 35.1402 143.994 35.2284C144.092 35.2403 144.192 35.2502 144.297 35.2592H144.295V35.2582Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M145.293 35.2582C145.231 35.5902 145.15 35.9361 145.05 36.29C144.926 36.7301 144.783 37.1454 144.629 37.526C144.609 37.5746 144.589 37.6221 144.569 37.6697L143.648 37.4844L143.539 37.4625L142.887 37.3317L142.222 37.1969H142.221L140.882 36.9283C140.961 36.7955 141.047 36.6884 141.127 36.6052H141.125C140.864 36.6131 140.545 36.6894 140.209 36.7935H140.208L139.876 36.7271C139.695 36.733 139.488 36.7707 139.265 36.8282C138.721 36.967 138.095 37.2217 137.577 37.4031H137.576C137.192 37.5379 136.87 37.633 136.686 37.6102C136.573 37.5974 136.424 37.5617 136.275 37.5052C135.733 37.295 135.222 36.7905 136.686 35.956C138.006 35.2056 139.52 34.7358 140.832 34.6337H140.836C140.99 34.6218 141.138 34.6159 141.285 34.6149C141.286 34.6139 141.287 34.6139 141.289 34.6139C141.659 34.6099 142.006 34.6397 142.324 34.7031C142.538 34.7457 142.732 34.7874 142.917 34.828C143.327 34.9192 143.678 35.0044 144.041 35.0768C144.416 35.1521 144.8 35.2146 145.269 35.2562C145.277 35.2572 145.286 35.2572 145.294 35.2582H145.293Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M164.421 26.3001L163.18 32.1056L162.657 34.5534C162.106 37.1311 160.104 39.0865 157.623 39.6424C157.127 39.7544 156.61 39.8099 156.082 39.803L155.694 39.797L144.262 39.6087H144.26L144.044 39.6058L144.587 37.6782L144.63 37.5246L145.269 35.2551L145.35 34.9657L146.17 32.056L155.502 32.1353L157.463 23.428C157.817 21.8562 159.353 19.3261 160.902 18.8841H160.903C159.842 19.8494 159.077 21.1308 158.747 22.5827C158.161 25.1633 157.415 28.6657 156.766 32.4039C157.037 32.1244 157.296 31.9936 157.516 32.056L159.402 32.0719L160.686 32.0828C161.197 29.3326 161.79 26.2178 162.181 24.3685C162.199 24.2853 162.217 24.205 162.234 24.1267C162.335 23.6609 162.42 23.2913 162.484 23.0514C162.637 22.4826 162.847 22.0009 163.097 21.5887L163.759 22.4033L164.231 22.985C164.59 24.0534 164.657 25.197 164.421 26.3001Z"
                    fill="white"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M146.541 35.1372C146.48 35.4692 146.399 35.815 146.299 36.1688C146.16 36.6653 145.995 37.1301 145.818 37.5484C145.326 38.7059 144.733 39.5067 144.257 39.6107C144.182 39.6276 144.109 39.6266 144.042 39.6068C143.632 39.4918 143.455 38.7337 143.521 37.6772C143.522 37.6653 143.522 37.6524 143.523 37.6395C143.527 37.5811 143.531 37.5216 143.537 37.4621C143.549 37.3145 143.567 37.1629 143.589 37.0053C143.603 36.9022 143.62 36.7962 143.639 36.6891C143.704 36.3086 143.795 35.9062 143.911 35.4959C143.937 35.4057 143.964 35.3156 143.99 35.2274C144.006 35.1758 144.021 35.1263 144.038 35.0767C144.078 34.9509 144.12 34.827 144.163 34.7071C144.757 33.0372 145.594 31.8965 146.166 32.058C146.713 32.2126 146.848 33.5079 146.539 35.1382H146.541V35.1372Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M146.541 35.1373C146.48 35.4693 146.399 35.8152 146.299 36.1691C146.16 36.6657 145.995 37.1305 145.818 37.5488C145.353 37.6241 144.945 37.6638 144.584 37.6796C144.198 37.6965 143.867 37.6816 143.58 37.6479C143.561 37.6459 143.542 37.643 143.523 37.641C143.2 37.5974 142.934 37.527 142.713 37.4427C142.521 37.3694 142.361 37.2861 142.221 37.1989H142.22C141.746 36.9075 141.52 36.5943 141.127 36.6062H141.125C140.864 36.6141 140.545 36.6904 140.209 36.7945H140.208C139.992 36.8609 139.768 36.9412 139.548 37.0205C138.869 37.2663 138.224 37.525 137.936 37.4893C137.836 37.4774 137.708 37.4487 137.578 37.4041H137.577C137.433 37.3525 137.286 37.2812 137.174 37.188C136.852 36.9204 136.816 36.4744 137.936 35.836C138.923 35.274 140.019 34.8696 141.058 34.6605C141.134 34.6437 141.211 34.6298 141.285 34.6169C141.286 34.6159 141.287 34.6159 141.289 34.6159C142.125 34.4692 142.916 34.4514 143.573 34.5842C143.787 34.6268 143.982 34.6684 144.167 34.7091C144.599 34.8052 144.968 34.8944 145.35 34.9697C145.713 35.0411 146.088 35.0996 146.543 35.1402L146.541 35.1373Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M144.349 36.8072C144.349 36.8072 144.149 36.835 143.857 36.9192C143.774 36.943 143.685 36.9718 143.59 37.0065C143.372 37.0838 143.127 37.1898 142.887 37.3326C142.829 37.3663 142.771 37.4029 142.714 37.4416C142.708 37.4455 142.701 37.4485 142.695 37.4535C141.928 37.9768 140.478 37.848 140.752 37.1908C140.792 37.0937 140.836 37.0065 140.883 36.9291C140.962 36.7963 141.048 36.6893 141.128 36.606C141.321 36.4058 141.663 36.2115 141.663 36.2115C142.05 36.049 142.923 35.7001 142.923 35.7001"
                    fill="#FFE7D5"
                />
                <path
                    d="M144.349 36.8072C144.349 36.8072 144.149 36.835 143.857 36.9192C143.774 36.943 143.685 36.9718 143.59 37.0065C143.372 37.0838 143.127 37.1898 142.887 37.3326C142.829 37.3663 142.771 37.4029 142.714 37.4416C142.708 37.4455 142.701 37.4485 142.695 37.4535C141.928 37.9768 140.478 37.848 140.752 37.1908C140.792 37.0937 140.836 37.0065 140.883 36.9291C140.962 36.7963 141.048 36.6893 141.128 36.606C141.321 36.4058 141.663 36.2115 141.663 36.2115C142.05 36.049 142.923 35.7001 142.923 35.7001"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M143.251 36.5628C143.251 36.5628 143.237 36.3643 143.054 36.1421"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M168.631 16.6949C168.593 16.8773 168.417 17.7969 168.17 18.0308C167.009 19.1319 164.454 19.3539 163.097 21.5896C162.846 22.0019 162.636 22.4836 162.484 23.0524C162.42 23.2923 162.335 23.6619 162.234 24.1277C162.217 24.206 162.199 24.2863 162.181 24.3695C161.79 26.2188 161.197 29.3336 160.685 32.0838L159.402 32.0729L161.265 23.9672V23.9642C161.265 23.9642 161.459 19.0813 163.009 17.5541C163.315 17.4283 163.632 17.3232 163.958 17.241L165.331 16.8931C166.424 16.6166 167.542 16.5552 168.631 16.6939V16.6949Z"
                    fill="#FFC9CB"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M161.265 23.9681L159.402 32.0739L157.516 32.057C157.296 31.9956 157.036 32.1264 156.766 32.4059C157.415 28.6677 158.161 25.1653 158.747 22.5847C159.077 21.1328 159.842 19.8514 160.903 18.8871C161.133 18.677 161.376 18.4827 161.632 18.3034C161.554 18.4272 161.488 18.561 161.438 18.7018C161.409 18.785 161.377 18.8752 161.347 18.9713L161.716 19.2369L161.154 19.671C161.002 20.333 160.897 21.1506 160.963 22.0426C161.121 24.1822 161.25 23.9979 161.264 23.9691H161.265V23.9681Z"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M163.009 17.5551C161.46 19.0825 161.266 23.9661 161.266 23.9661V23.9691C161.25 23.9978 161.122 24.1832 160.964 22.0422C160.898 21.1491 161.003 20.3324 161.155 19.6703L161.717 19.2362L161.348 18.9705C161.378 18.8744 161.409 18.7842 161.439 18.7009C161.489 18.5602 161.555 18.4264 161.633 18.3025C162.06 18.0051 162.521 17.7534 163.01 17.5551H163.009Z"
                    fill="#FFC9CB"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M169.989 18.6492L169.019 18.5194L168.783 19.5739C168.783 19.5739 166.521 19.8504 164.182 21.995C164.041 22.1248 163.9 22.2616 163.758 22.4043C163.22 22.9494 162.685 23.5975 162.18 24.3705C162.198 24.2873 162.216 24.207 162.233 24.1287C162.334 23.6629 162.419 23.2932 162.483 23.0534C162.636 22.4846 162.846 22.0029 163.096 21.5906C164.043 20.0307 165.573 19.452 166.805 18.8702C167.339 18.6175 167.813 18.3589 168.17 18.0318C168.491 17.7375 168.322 17.24 168.322 17.24C168.322 17.24 168.147 16.7266 168.168 16.6235C168.76 16.5651 169.543 16.7355 170.038 16.9486C170.23 17.0319 170.364 17.2093 170.384 17.4174C170.419 17.791 170.282 18.2885 169.989 18.6492H169.989Z"
                    fill="white"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M177.489 27.3694C177.414 31.2107 179.296 39.6226 179.296 39.6226L175.906 39.9348L177.489 27.3694Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M177.519 25.8323L177.255 39.3144C177.255 39.3144 154.577 50.2822 155.649 40.1974C155.664 40.0646 155.677 39.9308 155.693 39.798C155.699 39.7366 155.707 39.6742 155.712 39.6117C155.714 39.5988 155.715 39.5869 155.716 39.5741C155.783 38.9884 155.856 38.3987 155.934 37.806C155.937 37.7723 155.942 37.7387 155.948 37.705C155.953 37.6534 155.961 37.6029 155.968 37.5513C156.069 36.7952 156.179 36.037 156.295 35.2799L156.352 34.9132C156.484 34.0708 156.622 33.2324 156.765 32.4059C157.036 32.1264 157.295 31.9956 157.515 32.058L159.401 32.0739L160.685 32.0848C161.195 29.3426 161.786 26.2356 162.177 24.3854C162.178 24.3804 162.179 24.3765 162.18 24.3705C162.685 23.5975 163.22 22.9494 163.758 22.4043C163.899 22.2616 164.04 22.1248 164.182 21.995C166.522 19.8504 168.783 19.5739 168.783 19.5739L169.019 18.5194L169.989 18.6492C170.393 18.1557 170.441 17.4015 170.214 17.0656C171.326 17.4293 172.429 18.015 173.337 18.8038L175.067 20.3072C176.662 21.6947 177.561 23.7194 177.518 25.8333H177.519V25.8323Z"
                    fill="white"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M162.181 24.3695C162.18 24.3744 162.179 24.3784 162.178 24.3842L162.151 24.4167C162.162 24.4009 162.172 24.3852 162.182 24.3705H162.181V24.3695Z"
                    fill="white"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M159.662 37.6711C159.662 37.6711 160.126 35.096 160.685 32.0838"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M156.107 35.3801C156.046 35.7121 155.964 36.058 155.864 36.4119C155.725 36.9085 155.561 37.3733 155.384 37.7916C154.387 37.9512 153.65 37.9581 153.089 37.8828C151.528 37.6737 151.333 36.8262 150.691 36.848C149.74 36.8797 148.03 37.7946 147.501 37.7311C146.971 37.6667 145.638 37.1374 147.501 36.0779C149.364 35.0183 151.612 34.5197 153.137 34.825C153.351 34.8676 153.546 34.9093 153.731 34.9499C154.572 35.1362 155.172 35.2988 156.107 35.3801Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M151.688 36.848C150.737 36.8797 149.027 37.7946 148.498 37.7311C147.968 37.6667 146.635 37.1374 148.498 36.0779C150.361 35.0183 152.609 34.5197 154.134 34.825C154.348 34.8676 154.543 34.9093 154.727 34.9499C155.569 35.1362 156.169 35.2988 157.104 35.3801C157.043 35.7121 156.961 36.058 156.861 36.4119C156.722 36.9085 156.558 37.3733 156.381 37.7916"
                    fill="#FFE7D5"
                />
                <path
                    d="M151.688 36.848C150.737 36.8797 149.027 37.7946 148.498 37.7311C147.968 37.6667 146.635 37.1374 148.498 36.0779C150.361 35.0183 152.609 34.5197 154.134 34.825C154.348 34.8676 154.543 34.9093 154.727 34.9499C155.569 35.1362 156.169 35.2988 157.104 35.3801C157.043 35.7121 156.961 36.058 156.861 36.4119C156.722 36.9085 156.558 37.3733 156.381 37.7916"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M175.578 22.986C175.937 24.0544 176.003 25.198 175.767 26.3011L174.003 34.5544C173.342 37.6475 170.59 39.8446 167.429 39.805L157.622 39.6444L155.713 39.6127L155.609 39.6107L155.391 39.6078L155.898 37.806L155.927 37.702L155.968 37.5523L156.596 35.3255L156.69 34.9915L157.515 32.059L159.401 32.0749L160.685 32.0858L163.179 32.1076L166.848 32.1393L168.809 23.432C169.163 21.8602 170.348 20.6055 171.898 20.1635L172.499 19.9921"
                    fill="white"
                />
                <path
                    d="M175.578 22.986C175.937 24.0544 176.003 25.198 175.767 26.3011L174.003 34.5544C173.342 37.6475 170.59 39.8446 167.429 39.805L157.622 39.6444L155.713 39.6127L155.609 39.6107L155.391 39.6078L155.898 37.806L155.927 37.702L155.968 37.5523L156.596 35.3255L156.69 34.9915L157.515 32.059L159.401 32.0749L160.685 32.0858L163.179 32.1076L166.848 32.1393L168.809 23.432C169.163 21.8602 170.348 20.6055 171.898 20.1635L172.499 19.9921"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M157.889 35.1372C157.885 35.159 157.881 35.1818 157.876 35.2065C157.817 35.5167 157.739 35.8388 157.646 36.1688C157.507 36.6644 157.342 37.1292 157.166 37.5484C157.137 37.6148 157.108 37.6822 157.078 37.7466C156.645 38.7099 156.143 39.3937 155.717 39.5741C155.679 39.5909 155.643 39.6018 155.608 39.6097C155.531 39.6276 155.457 39.6276 155.389 39.6078C155.003 39.4987 154.824 38.8238 154.858 37.8645C154.86 37.8011 154.864 37.7367 154.867 37.6713C154.868 37.6604 154.868 37.6504 154.87 37.6395C154.873 37.59 154.876 37.5414 154.882 37.4899C154.934 36.8903 155.059 36.2075 155.259 35.4959C155.28 35.4226 155.3 35.3532 155.322 35.2829C155.34 35.2204 155.36 35.158 155.379 35.0955C155.419 34.9707 155.461 34.8468 155.504 34.7269C155.505 34.7199 155.507 34.712 155.51 34.7051C155.875 33.6774 156.332 32.8508 156.763 32.4039C157.034 32.1244 157.293 31.9936 157.513 32.056C158.06 32.2106 158.194 33.5059 157.886 35.1362H157.889V35.1372Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M157.876 35.2066C157.816 35.5169 157.739 35.84 157.646 36.1691C157.507 36.6656 157.343 37.1305 157.165 37.5488C157.137 37.6152 157.108 37.6816 157.078 37.746C156.383 37.8253 155.835 37.8223 155.392 37.7688C155.374 37.7668 155.355 37.7639 155.335 37.7619C155.163 37.7391 155.008 37.7084 154.867 37.6717C154.512 37.5805 154.246 37.4526 154.032 37.3198C153.556 37.0284 153.33 36.7142 152.937 36.7271C152.675 36.735 152.356 36.8113 152.02 36.9154C151.804 36.9818 151.579 37.0621 151.359 37.1414C150.68 37.3872 150.036 37.6459 149.747 37.6102C149.647 37.5983 149.519 37.5696 149.389 37.525C149.244 37.4735 149.097 37.4021 148.985 37.3089C148.663 37.0413 148.628 36.5953 149.747 35.9569C150.734 35.3949 151.831 34.9905 152.869 34.7814C152.946 34.7646 153.024 34.7507 153.1 34.7358C153.937 34.5891 154.727 34.5713 155.384 34.7041C155.426 34.712 155.467 34.7209 155.505 34.7289C155.673 34.7626 155.828 34.7963 155.978 34.829C156.667 34.9826 157.195 35.1184 157.877 35.2076H157.876V35.2066Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M192.025 45.335C192.025 45.0258 192.002 44.7215 191.959 44.4232V42.2112C191.959 40.3045 190.399 38.7446 188.492 38.7446H98.7276C96.8208 38.7446 95.2609 40.3045 95.2609 42.2112V44.331C95.2103 44.6591 95.1836 44.9941 95.1836 45.336C95.1836 45.6779 95.2103 46.0446 95.2609 46.4281V50.6479C95.2609 52.5547 96.8208 54.1146 98.7276 54.1146H176.672L180.53 57.9747V54.1146H188.492C190.399 54.1146 191.959 52.5547 191.959 50.6479V46.4301C192.002 46.0218 192.025 45.6481 192.025 45.336V45.335Z"
                    fill="#FFC9CB"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M119.883 10.2769H127.465C127.926 10.2769 128.316 10.6169 128.381 11.0727L131.513 33.4336C131.591 33.9895 131.159 34.4861 130.597 34.4861H123.016C122.555 34.4861 122.165 34.1461 122.1 33.6902L118.968 11.3294C118.889 10.7734 119.321 10.2769 119.883 10.2769Z"
                    fill="#072F35"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M118.372 10.2769H125.954C126.414 10.2769 126.805 10.6169 126.869 11.0727L130.002 33.4336C130.08 33.9895 129.648 34.4861 129.086 34.4861H121.505C121.044 34.4861 120.653 34.1461 120.589 33.6902L117.456 11.3294C117.378 10.7734 117.81 10.2769 118.372 10.2769Z"
                    fill="#072F35"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M123.257 22.2685H124.218C124.395 22.2685 124.538 22.4122 124.538 22.5886V37.5741H122.414V23.1119C122.414 22.6471 122.792 22.2695 123.257 22.2695V22.2685Z"
                    fill="#072F35"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M154.137 38.6903H147.576L147.074 37.1906L153.839 38.0291C154.01 38.0519 154.137 38.1976 154.137 38.3691V38.6903Z"
                    fill="#072F35"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M133.21 37.1876H147.103C147.364 37.1876 147.576 37.3997 147.576 37.6604V38.6903H132.915V37.483C132.915 37.3204 133.046 37.1876 133.21 37.1876Z"
                    fill="#072F35"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M119.012 37.4364H130.063C130.587 37.4364 131.011 37.8616 131.011 38.384V39.3731C131.011 39.5099 130.9 39.6219 130.762 39.6219H118.312C118.176 39.6219 118.064 39.5109 118.064 39.3731V38.384C118.064 37.8606 118.489 37.4364 119.011 37.4364H119.012Z"
                    fill="#072F35"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M163.457 17.2311C163.296 17.3084 163.146 17.4184 163.009 17.5552C161.46 19.0826 161.266 23.9661 161.266 23.9661V23.9691C161.25 23.9978 161.122 24.1832 160.964 22.0422C160.898 21.1492 161.003 20.3325 161.155 19.6704L161.717 19.2362L161.348 18.9706C161.378 18.8744 161.409 18.7842 161.439 18.701C161.489 18.5602 161.555 18.4264 161.633 18.3025C161.819 18.0081 162.08 17.7663 162.394 17.6037C163.115 17.2311 163.457 17.2311 163.457 17.2311H163.457Z"
                    fill="white"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M169.214 12.3468C169.337 11.6864 169.087 11.0864 168.657 11.0066C168.226 10.9267 167.778 11.3974 167.655 12.0578C167.533 12.7182 167.782 13.3182 168.213 13.398C168.643 13.4779 169.092 13.0072 169.214 12.3468Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M168.414 12.748C168.213 12.7104 168.097 12.4311 168.153 12.1231C168.211 11.815 168.42 11.5961 168.62 11.6328C168.82 11.6694 168.937 11.9497 168.881 12.2578"
                    fill="#FFE7D5"
                />
                <path
                    d="M168.414 12.748C168.213 12.7104 168.097 12.4311 168.153 12.1231C168.211 11.815 168.42 11.5961 168.62 11.6328C168.82 11.6694 168.937 11.9497 168.881 12.2578"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M168.623 12.207C168.66 12.0073 168.585 11.8258 168.454 11.8016C168.324 11.7774 168.188 11.9197 168.151 12.1194C168.114 12.3191 168.19 12.5006 168.32 12.5247C168.451 12.5489 168.586 12.4066 168.623 12.207Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M168.297 17.1918C168.312 17.3167 168.292 17.4455 168.229 17.5555C167.942 18.067 166.978 18.518 165.797 18.6419C164.697 18.7578 163.723 18.5576 163.281 18.173C163.159 18.067 163.083 17.9213 163.055 17.7627C163.052 17.7518 163.051 17.7419 163.05 17.731C163.045 17.6983 163.043 17.6636 163.043 17.6309V17.5605C163.043 17.5605 163.044 17.5605 163.044 17.5585V17.4009L163.046 17.0679V16.9281L163.054 15.6683V15.1549L167.753 14.6593L168.149 16.5287L168.217 16.8468L168.219 16.8548L168.249 16.9787L168.283 17.1135C168.287 17.1283 168.29 17.1412 168.292 17.1561C168.294 17.167 168.295 17.1769 168.296 17.1888V17.1918H168.297Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M168.092 9.45945C168.108 9.61801 168.115 9.77856 168.113 9.94208L168.067 13.6466C168.042 15.6435 166.388 17.256 164.391 17.2312L162.443 17.2074C160.988 17.1886 159.813 15.9845 159.831 14.5286L159.839 13.931L159.89 9.84001C159.891 9.74883 159.895 9.65766 159.903 9.56846C160.07 7.43475 161.884 5.75197 164.054 5.77972C166.153 5.80647 167.878 7.42187 168.093 9.46044H168.092V9.45945Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M165.395 5.19091C164.523 5.01153 163.789 5.16613 162.822 4.94513C161.855 4.72413 160.766 5.05811 160.622 6.03032C160.478 7.00253 159.372 7.05406 159 7.81716C158.627 8.58027 159.275 9.5723 159.902 9.56635C163.427 9.64563 165.182 8.83397 165.182 8.83397C165.857 9.33445 166.799 9.69518 167.359 9.88249C167.641 9.97664 167.828 10.2432 167.822 10.5396L167.775 13.0717C167.767 13.4859 167.395 13.7971 166.987 13.7307L163.887 13.2292C163.265 13.1291 162.629 13.1628 162.021 13.3293L159.839 13.9279L159.831 14.5255C159.812 15.9804 160.988 17.1855 162.443 17.2043L164.391 17.2281C166.388 17.2529 168.042 15.6404 168.067 13.6435L168.113 9.93898C168.113 9.9152 168.112 9.89141 168.112 9.86763H168.113C168.137 7.02334 166.268 5.3683 165.395 5.18893V5.19091Z"
                    fill="#072F35"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M163.269 14.7384C163.269 14.7384 163.981 14.9523 164.356 14.4175"
                    stroke="#FFC9CB"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M162.671 12.9378C162.671 12.9378 162.43 12.9635 162.32 12.7496"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M163.788 11.2764C163.86 11.2764 163.918 11.2182 163.918 11.1463C163.918 11.0745 163.86 11.0162 163.788 11.0162C163.716 11.0162 163.658 11.0745 163.658 11.1463C163.658 11.2182 163.716 11.2764 163.788 11.2764Z"
                    fill="black"
                />
                <path
                    d="M160.852 11.4558C160.924 11.4558 160.983 11.3976 160.983 11.3257C160.983 11.2539 160.924 11.1956 160.852 11.1956C160.781 11.1956 160.722 11.2539 160.722 11.3257C160.722 11.3976 160.781 11.4558 160.852 11.4558Z"
                    fill="black"
                />
                <path
                    d="M133.375 26.3189L136.519 26.9027"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M133.561 23.21L137.505 23.1069"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M133.096 20.1299L135.943 19.447"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M130.845 15.0312L133.058 13.1133"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M132.001 17.2135L135.727 15.4373"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M104.064 49.1842H185.38"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M112.289 45.9821H185.38"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M105.929 42.7801H185.381"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M43.7529 85.702C55.7374 85.702 65.4528 82.7833 65.4528 79.183C65.4528 75.5826 55.7374 72.6639 43.7529 72.6639C31.7684 72.6639 22.053 75.5826 22.053 79.183C22.053 82.7833 31.7684 85.702 43.7529 85.702Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M62.0715 92.8771C60.6702 92.0724 59.2262 91.3698 57.7496 90.7454L50.7449 89.9972L50.3167 93.999C50.29 94.2507 50.4297 94.4925 50.6626 94.5907C54.384 96.1644 58.5821 97.1505 63.2707 97.5311C63.5194 97.5509 63.7563 97.4092 63.8495 97.1773C64.4718 95.6283 63.7672 93.8513 62.0705 92.8771H62.0715Z"
                    fill="#A27663"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M70.7927 69.2389C70.7927 73.0088 69.2507 76.4378 66.7651 78.9223C64.2796 81.4078 60.8526 82.9499 57.0826 82.9499H36.6383C29.0975 82.9499 22.9272 76.7797 22.9272 69.2379C22.9272 65.468 24.4693 62.0399 26.9549 59.5554C28.1501 58.3602 29.5643 57.3821 31.1281 56.6893C32.8169 55.9431 34.68 55.5268 36.6374 55.5268H57.0816C64.6225 55.5268 70.7927 61.697 70.7927 69.2379V69.2389Z"
                    fill="#D5F4F9"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M38.108 55.5278L36.1665 58.6441C35.7324 57.9304 33.6215 57.0116 31.129 56.6905C32.8178 55.9441 34.6809 55.5278 36.6382 55.5278H38.108Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M53.7318 49.6628L66.5678 55.1938C69.5261 56.4683 71.6866 59.0916 72.3724 62.2381L77.2473 84.6228L68.3547 86.8189L58.4304 62.7565L41.8948 51.6994L53.7318 49.6638V49.6628Z"
                    fill="#072F35"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M51.1987 54.2712L57.2133 59.2264C59.6989 61.2739 61.0437 64.3956 60.8257 67.6086L59.2717 90.465C56.6504 91.57 53.5286 91.2429 50.1194 90.0973L47.292 64.2222L42.0603 63.2242C38.0049 62.4512 35.1566 58.7834 35.4103 54.6626L35.7513 49.1376L46.4268 50.3387"
                    fill="#072F35"
                />
                <path
                    d="M51.1987 54.2712L57.2133 59.2264C59.6989 61.2739 61.0437 64.3956 60.8257 67.6086L59.2717 90.465C56.6504 91.57 53.5286 91.2429 50.1194 90.0973L47.292 64.2222L42.0603 63.2242C38.0049 62.4512 35.1566 58.7834 35.4103 54.6626L35.7513 49.1376L46.4268 50.3387"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M65.5957 51.1345L68.1675 52.3723C69.1704 52.855 69.9959 53.6429 70.5252 54.622L70.6332 54.8222C71.0514 55.5962 71.3636 56.4237 71.5598 57.282L71.6827 57.8181C71.8026 58.3404 71.4528 58.8547 70.9236 58.936C70.544 58.9945 70.1684 58.8121 69.9791 58.4771L68.6105 56.0541L65.0695 54.175"
                    fill="#FFE7D5"
                />
                <path
                    d="M65.5957 51.1345L68.1675 52.3723C69.1704 52.855 69.9959 53.6429 70.5252 54.622L70.6332 54.8222C71.0514 55.5962 71.3636 56.4237 71.5598 57.282L71.6827 57.8181C71.8026 58.3404 71.4528 58.8547 70.9236 58.936C70.544 58.9945 70.1684 58.8121 69.9791 58.4771L68.6105 56.0541L65.0695 54.175"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M64.8343 50.6182L67.4061 51.856C68.409 52.3387 69.2346 53.1265 69.7638 54.1057L70.1602 54.839C70.3872 55.2593 70.5566 55.7082 70.6637 56.174L70.9213 57.3018C71.0413 57.8241 70.6914 58.3384 70.1622 58.4197C69.7826 58.4781 69.407 58.2958 69.2177 57.9608L67.8491 55.5377L64.3081 53.6587"
                    fill="#FFE7D5"
                />
                <path
                    d="M64.8344 50.6182L67.4061 51.856C68.409 52.3387 69.2346 53.1265 69.7638 54.1057L70.1602 54.839C70.3872 55.2593 70.5566 55.7082 70.6637 56.174L70.9213 57.3018C71.0413 57.8241 70.6914 58.3384 70.1622 58.4197C69.7826 58.4781 69.407 58.2958 69.2177 57.9608L67.8491 55.5377L64.3081 53.6587"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M63.1993 53.4179L65.0575 54.8373C65.4649 55.1485 65.8989 55.4211 66.3548 55.654L67.7611 56.3706C67.999 56.4915 68.2893 56.4331 68.4618 56.2289C68.6422 56.0138 68.6422 55.7006 68.4598 55.4865L66.6749 53.3882"
                    fill="#FFE7D5"
                />
                <path
                    d="M63.1993 53.4179L65.0575 54.8373C65.4649 55.1485 65.8989 55.4211 66.3548 55.654L67.7611 56.3706C67.999 56.4915 68.2893 56.4331 68.4618 56.2289C68.6422 56.0138 68.6422 55.7006 68.4598 55.4865L66.6749 53.3882"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M53.6358 26.417C55.821 27.3307 57.3888 29.3138 57.7307 31.6576C57.8457 32.4425 57.8685 33.2423 57.7962 34.0431L57.0727 42.0378L65.6274 48.3121C66.334 48.8304 66.5738 49.7768 66.2002 50.5687L64.9545 53.2098C64.5333 54.1037 63.4778 54.5021 62.57 54.1087L52.3573 49.6797C50.0561 48.6817 48.6112 46.3657 48.7261 43.8603L49.0046 37.7882"
                    fill="#FFC9CB"
                />
                <path
                    d="M53.6358 26.417C55.821 27.3307 57.3888 29.3138 57.7307 31.6576C57.8457 32.4425 57.8685 33.2423 57.7962 34.0431L57.0727 42.0378L65.6274 48.3121C66.334 48.8304 66.5738 49.7768 66.2002 50.5687L64.9545 53.2098C64.5333 54.1037 63.4778 54.5021 62.57 54.1087L52.3573 49.6797C50.0561 48.6817 48.6112 46.3657 48.7261 43.8603L49.0046 37.7882"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M56.7694 32.9232L54.0995 48.8244C53.938 49.7897 53.3017 50.6123 52.4048 51.0017C46.9372 53.3773 40.5708 54.1116 36.559 54.1374C35.2667 54.1453 34.2449 53.0463 34.35 51.7579L34.4342 50.7322L36.2567 29.6646C36.8038 26.2218 40.0703 23.8502 43.5132 24.3973L45.0235 24.6371L50.3107 25.4775L51.501 25.6658C52.4137 25.8105 53.2522 26.1465 53.9796 26.6281C54.04 26.6668 54.0985 26.7074 54.157 26.749C56.0657 28.1018 57.1579 30.4664 56.7694 32.9232Z"
                    fill="#FFC9CB"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M67.9565 39.4987C67.9139 39.9318 67.6215 40.2648 67.2915 40.5195C66.8078 40.8931 65.8366 41.0081 65.0904 41.0388C65.0458 41.0408 65.0022 41.0428 64.9576 41.0467C64.2698 41.0864 63.5919 41.236 62.9497 41.4878L62.14 41.8079L60.2263 37.4116L60.374 37.3016C60.7605 37.0162 61.0846 36.6634 61.3462 36.265C61.4869 36.0499 61.6088 35.82 61.7129 35.5812C61.7297 35.5425 61.7476 35.5078 61.7654 35.4791C62.0399 35.0232 62.4849 34.6813 62.9775 34.4801C63.7158 34.1769 64.5314 34.1214 65.2628 34.4543C65.6731 34.6407 66.0636 34.8835 66.4273 35.1491L66.8118 38.5711C67.2191 38.588 67.672 38.6236 67.8802 39.0518C67.9475 39.1905 67.9713 39.3471 67.9555 39.4997H67.9565V39.4987Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M66.3369 39.5407C66.3369 39.5407 67.7789 39.3336 67.9602 38.4613C68.1416 37.5891 67.2447 36.9746 67.2447 36.9746C66.9444 36.9974 66.6352 37.0499 66.3657 37.1847"
                    fill="#FFE7D5"
                />
                <path
                    d="M66.3369 39.5407C66.3369 39.5407 67.7789 39.3336 67.9602 38.4613C68.1416 37.5891 67.2447 36.9746 67.2447 36.9746C66.9444 36.9974 66.6352 37.0499 66.3657 37.1847"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M66.3985 38.3188C66.3985 38.3188 67.2766 37.9838 67.5471 37.4128C67.8167 36.8419 67.8068 36.2145 67.4163 35.6267C66.7533 35.9013 66.1091 36.1878 65.5095 36.5803"
                    fill="#FFE7D5"
                />
                <path
                    d="M66.3985 38.3188C66.3985 38.3188 67.2766 37.9838 67.5471 37.4128C67.8167 36.8419 67.8068 36.2145 67.4163 35.6267C66.7533 35.9013 66.1091 36.1878 65.5095 36.5803"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M69.2418 30.0948C69.5718 30.1503 69.7661 30.4951 69.6432 30.8063L68.6561 33.2958L67.5055 36.1956C67.4639 36.2263 67.4213 36.2551 67.3757 36.2818L65.8247 37.2134L63.1528 36.6604L63.2846 36.3264L65.7741 30.0511C65.8911 29.7568 66.1963 29.5844 66.5085 29.6369L69.2418 30.0957V30.0948Z"
                    fill="#072F35"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M66.9778 30.9892C67.0874 30.7716 67.034 30.5235 66.8584 30.435C66.6828 30.3465 66.4516 30.4512 66.342 30.6688C66.2324 30.8864 66.2859 31.1345 66.4615 31.2229C66.637 31.3114 66.8682 31.2067 66.9778 30.9892Z"
                    fill="#D5F4F9"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M62.8168 36.7937C62.8168 36.7937 63.0417 36.6946 63.3341 36.5628C63.7761 36.3645 64.3757 36.0919 64.5907 35.977L65.6512 35.4457C65.9049 35.3178 66.114 35.1176 66.2517 34.8698L66.9405 33.6199C67.1338 33.271 67.5579 33.1214 67.9266 33.273C68.1367 33.3583 68.2913 33.5268 68.3646 33.725C68.4122 33.8519 68.4281 33.9916 68.4043 34.1294C68.3964 34.175 68.3845 34.2216 68.3676 34.2672L68.2358 34.6349L67.8622 35.6727C67.7502 35.9819 67.55 36.2486 67.2864 36.4408C67.2467 36.4696 67.2071 36.4963 67.1655 36.5221L65.7126 37.3944"
                    fill="#FFE7D5"
                />
                <path
                    d="M62.8168 36.7937C62.8168 36.7937 63.0417 36.6946 63.3341 36.5628C63.7761 36.3645 64.3757 36.0919 64.5907 35.977L65.6512 35.4457C65.9049 35.3178 66.114 35.1176 66.2517 34.8698L66.9405 33.6199C67.1338 33.271 67.5579 33.1214 67.9266 33.273C68.1367 33.3583 68.2913 33.5268 68.3646 33.725C68.4122 33.8519 68.4281 33.9916 68.4043 34.1294C68.3964 34.175 68.3845 34.2216 68.3676 34.2672L68.2358 34.6349L67.8622 35.6727C67.7502 35.9819 67.55 36.2486 67.2864 36.4408C67.2467 36.4696 67.2071 36.4963 67.1655 36.5221L65.7126 37.3944"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.193 32.0253L51.388 38.9507L59.1528 35.82C60.0468 35.5217 61.0259 35.9062 61.4778 36.7347L63.4758 40.4006C63.9485 41.2687 63.6958 42.3529 62.8881 42.9228L53.2869 48.2149C50.6556 49.6648 47.3743 49.1297 45.3407 46.9177L38.3677 39.3332C36.6264 37.4394 35.6056 34.8081 36.0318 32.2701"
                    fill="#FFC9CB"
                />
                <path
                    d="M45.193 32.0253L51.388 38.9507L59.1528 35.82C60.0468 35.5217 61.0259 35.9062 61.4778 36.7347L63.4758 40.4006C63.9485 41.2687 63.6958 42.3529 62.8881 42.9228L53.2869 48.2149C50.6556 49.6648 47.3743 49.1297 45.3407 46.9177L38.3677 39.3332C36.6264 37.4394 35.6056 34.8081 36.0318 32.2701"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M50.4812 26.0659C48.624 26.6914 45.2485 25.272 45.0939 25.2066L45.6826 21.897L45.8976 20.6858L47.0631 20.9078L50.4495 21.5531L50.4713 24.6773L50.4743 25.1085L50.4812 26.061V26.0659Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M50.4714 24.6773C50.4565 24.6773 50.4426 24.6773 50.4278 24.6753C50.069 24.6565 49.7073 24.592 49.3495 24.483C49.2474 24.4523 49.1473 24.4166 49.0492 24.3789C47.853 23.918 46.9502 22.9843 46.4913 21.8613C46.5538 21.6671 46.643 21.4827 46.7629 21.3221C46.8709 21.1794 46.971 21.0406 47.0632 20.9078L50.4496 21.5531L50.4714 24.6773Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M54.5038 20.4262L54.9329 17.7226C54.4701 17.6681 54.0212 17.896 53.3899 17.8564C52.5881 17.8078 51.9142 17.2509 51.3315 16.9565C50.7498 16.6622 50.504 17.0051 49.4426 16.8257C48.4158 16.6503 47.5853 15.0964 47.5338 14.9982C47.8361 15.686 47.3455 16.5165 46.8044 17.1696C46.2623 17.8257 46.6756 18.6106 46.8797 19.2379C47.0839 19.8652 46.8074 20.5411 46.3208 21.4182C46.2246 21.5946 46.1612 21.7889 46.1265 21.991C46.0383 22.4875 46.1047 23.0187 46.1691 23.3547C46.2009 23.5212 46.0571 23.6689 45.8897 23.6421L42.2961 23.0713C42.1078 23.0415 42.0226 22.8176 42.1455 22.6729C42.5439 22.2031 42.8719 21.4787 42.9344 21.0832L43.6826 16.3728C43.9086 14.9497 43.0652 13.5107 45.295 12.1966C48.6378 10.2284 48.4535 11.9409 51.4802 10.601C52.9905 9.93304 54.7219 10.385 56.48 11.2026C58.2371 12.0212 58.4016 14.2391 57.249 16.2588C56.0964 18.2776 56.0855 19.1953 56.1678 20.2418C56.2481 21.2884 56.0687 21.5748 56.0053 21.9692C55.9527 22.2982 55.475 23.9245 56.5761 24.9523C56.7218 25.088 56.6058 25.3288 56.4096 25.2942L50.4168 24.2278C52.4018 24.0564 54.1064 22.5738 54.483 20.5431C54.49 20.5064 54.4959 20.4678 54.5028 20.4291V20.4262H54.5038Z"
                    fill="#FFBE93"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M54.9339 17.7226L54.5047 20.4262C54.4988 20.4648 54.4928 20.5035 54.4849 20.5401C54.1083 22.5708 52.4037 24.0534 50.4187 24.2248C50.0609 24.2565 49.6932 24.2446 49.3236 24.1852C49.2175 24.1683 49.1145 24.1475 49.0114 24.1237C47.7627 23.8344 46.7379 23.0366 46.1274 21.989C46.1621 21.7869 46.2246 21.5926 46.3217 21.4162C46.8083 20.5391 47.0848 19.8633 46.8806 19.2359C46.6765 18.6086 46.2632 17.8227 46.8053 17.1676C47.3464 16.5145 47.837 15.684 47.5347 14.9963C47.5863 15.0944 48.4168 16.6493 49.4435 16.8237C50.5049 17.0031 50.7507 16.6602 51.3324 16.9546C51.9151 17.2489 52.59 17.8059 53.3908 17.8544C54.0221 17.8941 54.471 17.6661 54.9339 17.7206V17.7226Z"
                    fill="#FFE7D5"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M54.934 17.7226C55.1482 17.7563 55.5835 17.8306 55.765 18.1211"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M50.1195 22.1427C50.1195 22.1427 50.4359 22.8662 51.5238 22.7978"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M50.588 18.8352C50.6651 18.8352 50.7276 18.7727 50.7276 18.6956C50.7276 18.6186 50.6651 18.5561 50.588 18.5561C50.5109 18.5561 50.4484 18.6186 50.4484 18.6956C50.4484 18.7727 50.5109 18.8352 50.588 18.8352Z"
                    fill="black"
                />
                <path
                    d="M53.6633 19.3238C53.7404 19.3238 53.8029 19.2613 53.8029 19.1842C53.8029 19.1071 53.7404 19.0447 53.6633 19.0447C53.5862 19.0447 53.5237 19.1071 53.5237 19.1842C53.5237 19.2613 53.5862 19.3238 53.6633 19.3238Z"
                    fill="black"
                />
                <path
                    d="M52.0283 21.2372C52.0283 21.2372 52.4016 21.1995 52.482 20.886"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M74.748 86.1056C74.2238 86.2831 73.64 86.4535 73.0206 86.6072C72.3408 86.7747 71.6916 86.9035 71.102 86.9917C71.0237 87.0036 70.9464 87.0136 70.8701 87.0245C70.4281 87.0829 70.0267 87.1186 69.6798 87.1285C68.9108 87.1513 68.4123 87.0492 68.3558 86.8183C68.3003 86.5933 68.6729 86.2821 69.3329 85.953C69.6471 85.7954 70.0287 85.6338 70.4588 85.4752C71.0792 85.2473 71.8026 85.0252 72.5826 84.833C72.76 84.7893 72.9364 84.7477 73.1108 84.7091C73.5261 84.6169 73.9284 84.5406 74.306 84.4781C75.9472 84.2155 77.1563 84.2521 77.2474 84.6218C77.3376 84.9876 76.302 85.5763 74.747 86.1046H74.748V86.1056Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.2386 64.9464C45.2386 64.9464 39.9494 59.3879 33.0468 59.7021L45.2386 64.9464Z"
                    fill="#D5F4F9"
                />
                <path
                    d="M45.2386 64.9464C45.2386 64.9464 39.9494 59.3879 33.0468 59.7021"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M74.7767 86.2117L72.3536 87.1245L71.0385 87.6191L70.9325 87.2613L70.87 87.0234L70.4587 85.4752C71.0791 85.2472 71.8026 85.0252 72.5825 84.8329C72.7599 84.7893 72.9363 84.7477 73.1108 84.709C73.526 84.6168 73.9284 84.5405 74.306 84.4781L74.747 86.1046L74.7767 86.2117Z"
                    fill="white"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M85.5563 89.3367C81.1263 90.9177 76.8935 91.7453 72.854 91.8404C72.6281 91.8464 72.4239 91.7175 72.3258 91.5183C72.3139 91.4935 72.303 91.4687 72.2951 91.443L71.0385 87.619C72.8877 86.1679 74.3148 85.3433 76.9778 85.4235C78.5803 85.3839 80.941 85.4295 82.5484 85.5861C84.4959 85.7774 85.8684 87.1076 85.9378 88.7757C85.9487 89.0245 85.7911 89.2525 85.5563 89.3357V89.3367Z"
                    fill="#A27663"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M85.5564 89.3363C81.1264 90.9172 76.8937 91.7449 72.8542 91.84C72.6282 91.846 72.424 91.7171 72.3259 91.5179C72.1614 91.1264 77.9828 89.6128 81.3335 88.9587C84.6882 88.3035 85.8477 88.0924 85.9379 88.7763C85.9488 89.0251 85.7912 89.253 85.5564 89.3363Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_10547_85266">
                    <rect
                        width="200"
                        height="100.053"
                        fill="white"
                        transform="translate(0.237793)"
                    />
                </clipPath>
            </defs>
        </Icon>
    );
}
