import { Icon, IconProps } from '@chakra-ui/react';

export default function MicroscopeIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M18.257 4.714a1.75 1.75 0 0 0 0-2.476 1.752 1.752 0 0 0-2.476 0l-.705.705a1.998 1.998 0 0 0-1.789.55L7.51 9.272a2 2 0 0 0-.503 1.982 1.752 1.752 0 0 0 2.581 2.305 1.996 1.996 0 0 0 1.637-.573l4.075-4.075C16.914 9.967 18 11.844 18 14c0 3.349-2.607 6-5.745 6-1.692 0-3.226-.767-4.283-2H9c.552 0 1.02-.474.756-.959A2 2 0 0 0 8 16H5a2 2 0 0 0-1.756 1.041c-.265.485.204.959.756.959h1.546c1.293 2.308 3.68 3.903 6.454 3.996V22h9c.552 0 1-.487 1-1.04 0-.552-.448-.96-1-.96h-3.621A8.111 8.111 0 0 0 20 14c0-2.685-1.283-5.072-3.264-6.526l.266-.266c.486-.486.67-1.16.55-1.789l.705-.705zm-9.333 5.972 5.778-5.778.886.886-5.778 5.778-.886-.886z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
