import { useTheme } from '@chakra-ui/react';

export default function RegistrationPathsHasAccountIcon() {
    const theme = useTheme();
    const colors = theme.colors;

    return (
        <svg
            width="77"
            height="100"
            viewBox="0 0 77 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M39.0291 97.1429C57.9545 97.1429 73.2965 95.6238 73.2965 93.75C73.2965 91.8762 57.9545 90.3572 39.0291 90.3572C20.1038 90.3572 4.76172 91.8762 4.76172 93.75C4.76172 95.6238 20.1038 97.1429 39.0291 97.1429Z"
                fill="#E6E6E6"
            />
            <path
                d="M62.5816 22.1962H62.0682V8.1369C62.0682 5.97886 61.2109 3.90921 59.685 2.38324C58.159 0.857278 56.0894 0 53.9313 0H24.1447C21.9867 0 19.917 0.857278 18.3911 2.38324C16.8651 3.90921 16.0078 5.97886 16.0078 8.1369V85.2673C16.0078 87.4254 16.8651 89.495 18.3911 91.021C19.917 92.547 21.9867 93.4042 24.1447 93.4042H53.9312C56.0892 93.4042 58.1589 92.547 59.6848 91.021C61.2108 89.495 62.0681 87.4254 62.0681 85.2673V32.2038H62.5815L62.5816 22.1962Z"
                fill="#3F3D56"
            />
            <path
                d="M54.2583 2.11671H50.3702C50.5491 2.55511 50.6174 3.03079 50.5691 3.50182C50.5209 3.97285 50.3575 4.42478 50.0934 4.81778C49.8293 5.21079 49.4726 5.5328 49.0547 5.75545C48.6368 5.9781 48.1706 6.09456 47.6971 6.09455H30.6328C30.1594 6.09451 29.6932 5.97804 29.2754 5.7554C28.8576 5.53276 28.5009 5.21079 28.2368 4.81784C27.9727 4.42489 27.8094 3.97302 27.761 3.50206C27.7127 3.03109 27.781 2.55547 27.9598 2.11709H24.3277C22.7161 2.11709 21.1704 2.75732 20.0308 3.89693C18.8912 5.03655 18.251 6.58219 18.251 8.19385V85.2107C18.251 86.8223 18.8912 88.368 20.0308 89.5076C21.1704 90.6472 22.7161 91.2874 24.3277 91.2874H54.2572C55.8688 91.2874 57.4145 90.6472 58.5541 89.5076C59.6937 88.368 60.3339 86.8223 60.3339 85.2107V85.2107V8.19345C60.3339 6.5818 59.6937 5.03615 58.5541 3.89654C57.4145 2.75693 55.8688 2.1167 54.2572 2.1167H54.2583V2.11671Z"
                fill="white"
            />
            <path
                d="M39.3457 86.9836C41.1886 86.9836 42.6826 85.4896 42.6826 83.6467C42.6826 81.8038 41.1886 80.3098 39.3457 80.3098C37.5028 80.3098 36.0088 81.8038 36.0088 83.6467C36.0088 85.4896 37.5028 86.9836 39.3457 86.9836Z"
                fill="#E6E6E6"
            />
            <path
                d="M25.8084 52.7869C25.493 52.7869 25.2363 53.0798 25.2363 53.4398C25.2363 53.7999 25.493 54.0928 25.8084 54.0928H52.7786C53.094 54.0928 53.3507 53.7999 53.3507 53.4398C53.3507 53.0798 53.094 52.7869 52.7786 52.7869H25.8084Z"
                fill="#E6E6E6"
            />
            <path
                d="M25.8084 68.188C25.493 68.188 25.2363 68.481 25.2363 68.841C25.2363 69.201 25.493 69.494 25.8084 69.494H52.7786C53.094 69.494 53.3507 69.201 53.3507 68.841C53.3507 68.481 53.094 68.188 52.7786 68.188H25.8084Z"
                fill="#E6E6E6"
            />
            <path
                d="M25.8084 56.7051C25.493 56.7051 25.2363 56.998 25.2363 57.3581C25.2363 57.7181 25.493 58.011 25.8084 58.011H37.4138C37.7292 58.011 37.9859 57.7181 37.9859 57.3581C37.9859 56.998 37.7292 56.7051 37.4138 56.7051H25.8084Z"
                fill="#E6E6E6"
            />
            <path
                d="M25.8084 60.4395C25.493 60.4395 25.2363 60.7324 25.2363 61.0924C25.2363 61.4525 25.493 61.7454 25.8084 61.7454H52.7786C53.094 61.7454 53.3507 61.4525 53.3507 61.0924C53.3507 60.7324 53.094 60.4395 52.7786 60.4395H25.8084Z"
                fill="#E6E6E6"
            />
            <path
                d="M25.8084 64.3574C25.493 64.3574 25.2363 64.6504 25.2363 65.0104C25.2363 65.3705 25.493 65.6634 25.8084 65.6634H37.4138C37.7292 65.6634 37.9859 65.3705 37.9859 65.0104C37.9859 64.6504 37.7292 64.3574 37.4138 64.3574H25.8084Z"
                fill="#E6E6E6"
            />
            <path
                d="M30.8997 23.0537H47.6887C47.8741 23.0539 48.0519 23.1277 48.1831 23.2588C48.3142 23.39 48.388 23.5678 48.3882 23.7532V41.2382C48.388 41.4237 48.3142 41.6015 48.1831 41.7326C48.0519 41.8638 47.8741 41.9375 47.6887 41.9377H30.8997C30.7143 41.9375 30.5365 41.8638 30.4053 41.7326C30.2742 41.6015 30.2004 41.4237 30.2002 41.2382V23.7532C30.2004 23.5678 30.2742 23.39 30.4053 23.2588C30.5365 23.1277 30.7143 23.0539 30.8997 23.0537Z"
                fill={colors.tertiary.main}
            />
            <path
                d="M44.1108 38.3322C44.1733 38.3323 44.2351 38.3201 44.2928 38.2962C44.3505 38.2724 44.403 38.2375 44.4472 38.1934C44.4913 38.1492 44.5264 38.0968 44.5503 38.0392C44.5742 37.9815 44.5865 37.9197 44.5865 37.8573C44.5865 37.7948 44.5742 37.733 44.5503 37.6753C44.5264 37.6177 44.4913 37.5653 44.4472 37.5212C44.403 37.477 44.3505 37.4421 44.2928 37.4183C44.2351 37.3944 44.1733 37.3822 44.1108 37.3823H34.4767C34.4142 37.3822 34.3524 37.3944 34.2947 37.4183C34.237 37.4421 34.1845 37.477 34.1404 37.5212C34.0962 37.5653 34.0611 37.6177 34.0372 37.6753C34.0133 37.733 34.001 37.7948 34.001 37.8573C34.001 37.9197 34.0133 37.9815 34.0372 38.0392C34.0611 38.0968 34.0962 38.1492 34.1404 38.1934C34.1845 38.2375 34.237 38.2724 34.2947 38.2962C34.3524 38.3201 34.4142 38.3323 34.4767 38.3322H44.1108Z"
                fill="white"
            />
            <path
                d="M44.111 35.5338C44.237 35.5338 44.3578 35.4838 44.4468 35.3947C44.5359 35.3057 44.5859 35.1849 44.5859 35.0589C44.5859 34.9329 44.5359 34.8121 44.4468 34.7231C44.3578 34.634 44.237 34.584 44.111 34.584H34.4769C34.4145 34.584 34.3527 34.5963 34.2951 34.6201C34.2375 34.644 34.1852 34.679 34.1411 34.7231C34.097 34.7672 34.062 34.8195 34.0381 34.8772C34.0142 34.9348 34.002 34.9965 34.002 35.0589C34.002 35.1213 34.0142 35.183 34.0381 35.2406C34.062 35.2983 34.097 35.3506 34.1411 35.3947C34.1852 35.4388 34.2375 35.4738 34.2951 35.4977C34.3527 35.5215 34.4145 35.5338 34.4769 35.5338H44.111Z"
                fill="white"
            />
            <path
                d="M39.2941 26.6594C39.8854 26.6594 40.4633 26.8348 40.9549 27.1632C41.4466 27.4917 41.8297 27.9586 42.056 28.5048C42.2822 29.0511 42.3414 29.6522 42.2261 30.2321C42.1107 30.8119 41.826 31.3446 41.408 31.7627C40.9899 32.1808 40.4572 32.4655 39.8773 32.5808C39.2974 32.6962 38.6963 32.637 38.1501 32.4107C37.6039 32.1844 37.137 31.8013 36.8085 31.3097C36.48 30.8181 36.3047 30.2401 36.3047 29.6488C36.3056 28.8563 36.6208 28.0964 37.1813 27.536C37.7417 26.9756 38.5015 26.6603 39.2941 26.6594Z"
                fill="white"
            />
        </svg>
    );
}
