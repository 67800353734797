import { isArray } from '@chakra-ui/utils';
import { SubWidgetType } from './handlers';

function transformCheckboxList(
    inputId: string,
    data: string | string[] | { value: unknown }[] | boolean,
    subWidgetsType: SubWidgetType[] = [],
    hasSubWidgets: boolean = false
) {
    if (
        data !== false &&
        !isArray(data) &&
        typeof data !== 'string' &&
        subWidgetsType.find((widget) => widget.subWidget.id.includes(inputId))
    ) {
        const currentOptionId = subWidgetsType.find((widget) =>
            widget.subWidget.id.includes(inputId)
        ).optionId;

        const arr = [];
        Object.keys(data).forEach((key) => (arr[key] = data[key]));
        arr.push(`{"id":"${currentOptionId}","label":"${currentOptionId}"}`);
        data = arr;
    }

    const dataValue = data['value'] || data;
    const valuesArray = Array.isArray(dataValue) ? dataValue : [dataValue];
    const values = valuesArray.map((value) => JSON.parse(value));

    //@TODO: Skip this when data is a string
    const subWidgets = Object.entries(data)
        .filter(([_, value]) => value?.value)
        .map(([id, value]) => ({
            id: `${inputId}.${id}`,
            value: value?.value,
        }));

    return {
        id: inputId,
        values: values.map((value) => value.id),
        ...(!!subWidgets?.length
            ? { subWidgets }
            : hasSubWidgets && { subWidgets: [] }),
    };
}

export default transformCheckboxList;
