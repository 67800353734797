import { FileDownloadCard } from 'core';
import Fieldset from '../../Fieldset';
import { WidgetFileDownloadProps } from '../types';
import ExpressDownload from './ExpressDownload';

function FileDownload({
    title,
    file,
    description,
    help,
}: WidgetFileDownloadProps) {
    return (
        <Fieldset legend={title} help={help} description={description}>
            {file.hasPreview ? (
                <FileDownloadCard {...file} />
            ) : (
                // Download file directly without preview
                <ExpressDownload
                    name={file.name}
                    url={file.source}
                    type={file.type}
                    size={file.size}
                />
            )}
        </Fieldset>
    );
}

export default FileDownload;
