import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { WidgetInformationProps } from '../types';
import { SafeFormattedMessage, formMessages, FormGroup } from 'core';
import { Markdown } from 'design-system/components';

export default function Information({
    id,
    title,
    isTranslatable,
    description,
}: WidgetInformationProps) {
    if (!description) {
        return null;
    }

    return (
        <FormGroup label={title} name={`${id}.value`} id={id}>
            <Stack w="full">
                <Text as={isTranslatable ? 'p' : 'div'} fontSize="sm">
                    {isTranslatable ? (
                        <SafeFormattedMessage
                            {...formMessages?.[description]}
                            debugKey={description}
                        />
                    ) : (
                        <Markdown content={description} />
                    )}
                </Text>
            </Stack>
        </FormGroup>
    );
}
