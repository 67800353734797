import { Icon, IconProps } from '@chakra-ui/react';

export default function DumbbellsIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M9 9.5V8.25a2.25 2.25 0 0 0-4.488-.235A2.25 2.25 0 0 0 2 10.25v3.5a2.25 2.25 0 0 0 2.512 2.235A2.25 2.25 0 0 0 9 15.75V14.5h6v1.25a2.25 2.25 0 0 0 4.488.235A2.25 2.25 0 0 0 22 13.75v-3.5a2.25 2.25 0 0 0-2.512-2.235A2.25 2.25 0 0 0 15 8.25V9.5H9zM7 8.25a.25.25 0 0 0-.5 0v7.5a.25.25 0 1 0 .5 0v-7.5zm2 3.25v1h6v-1H9zm8 4.25a.25.25 0 1 0 .5 0v-7.5a.25.25 0 1 0-.5 0v7.5zm-12.5-2v-3.5a.25.25 0 1 0-.5 0v3.5a.25.25 0 1 0 .5 0zm15.5-3.5v3.5a.25.25 0 1 1-.5 0v-3.5a.25.25 0 1 1 .5 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
