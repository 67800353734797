import React from 'react';
import {
    Img,
    Button,
    Flex,
    HStack,
    Text,
    LinkBox,
    LinkOverlay,
} from '@chakra-ui/react';
import { get, uniqueId } from 'lodash';
import { useRouter } from 'next/router';
import { defineMessage, useIntl } from 'react-intl';

import { LinkIntl, useRoving, SafeFormattedMessage } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import {
    PersonIcon,
    EuroIcon,
    GridIcon,
    HomeIcon,
    DiscutionIcon,
    StarOutlineIcon,
    ClockIcon,
    FileFileIcon,
    MessageIcon,
} from 'design-system/icons';
import siteConfig from 'lib/site/config.json';
import { useEligibility, sharedMessages } from 'lib/shared';
import UserInfo from './UserInfo';
import { pageTitles, Site } from 'lib/site';
import { useUserContext } from 'lib/user';
import CoInsurer from 'components/Logos/CoInsurer';
import NavbarSkeleton from './Skeleton';

type MenuItemProps = React.PropsWithChildren<unknown> & {
    to: string;
    id: number;
    activeRegex: RegExp;
    handleKeyDown: (evt: React.KeyboardEvent<Element>) => void;
    setCurrentFocus: (idx: number) => void;
    isFocused?: boolean;
};

const intlMessage = defineMessage({
    id: 'components.layouts.navbar.role',
    defaultMessage: 'Menu principal',
});

const labelMessages = {
    news: sharedMessages.news,
    refunds: sharedMessages.myRefunds,
    contract: sharedMessages.contracts,
    messages: pageTitles.messages,
    services: sharedMessages.services,
    account: sharedMessages.myAccount,
    agenda: sharedMessages.agenda,
};

const icons = {
    StarOutlineIcon,
    ClockIcon,
    PersonIcon,
    EuroIcon,
    GridIcon,
    HomeIcon,
    DiscutionIcon,
    FileFileIcon,
    MessageIcon,
};

function Logo() {
    const { userInfos } = useUserContext();
    const { menu, client, client_id } = siteConfig;
    const { formatMessage } = useIntl();
    const coInsurer = userInfos?.coInsurer;
    return (
        <LinkBox>
            <LinkOverlay as={LinkIntl} href="/news" passHref>
                {!coInsurer ||
                coInsurer === 'FNMF' ||
                (client_id === 'caa_caa' &&
                    coInsurer === 'la-mutuelle-verte') ? (
                    <Img
                        htmlWidth={menu.mainLogo.width}
                        maxH="70px"
                        src={`/images/${menu.mainLogo?.path}`}
                        alt={formatMessage(sharedMessages.logoImageAlt, {
                            name: client.name,
                        })}
                        py={menu.mainLogo.paddingVertical}
                    />
                ) : (
                    <CoInsurer coInsurer={coInsurer} />
                )}
            </LinkOverlay>
        </LinkBox>
    );
}

function MenuItem({
    children,
    to = '/',
    activeRegex,
    handleKeyDown,
    id,
    isFocused = false,
    setCurrentFocus,
}: MenuItemProps) {
    const router = useRouter();
    const isActive = activeRegex.test(router.pathname);
    const { isMobile } = useWindowBreakpoints();
    const ref = React.useRef<HTMLButtonElement>(null);
    const styles = !isMobile
        ? {
              borderColor: 'grey.400',
          }
        : {};

    React.useEffect(() => {
        if (isFocused && ref.current) {
            ref.current.focus();
        }
    }, [isFocused, id]);

    return (
        <LinkBox>
            <LinkOverlay tabIndex={-1} as={LinkIntl} href={to} passHref>
                <Button
                    h={isMobile ? '60px' : 69}
                    ref={ref}
                    onKeyDown={handleKeyDown}
                    onClick={() => setCurrentFocus(id)}
                    color={
                        isMobile
                            ? isActive
                                ? 'primary.main'
                                : 'strokes.medium'
                            : 'black'
                    }
                    fontWeight={isActive ? 'bold' : 'normal'}
                    bg="transparent"
                    px={1}
                    borderRadius={0}
                    borderBottom="2px solid"
                    borderColor={
                        !isMobile && isActive ? 'black' : 'transparent'
                    }
                    _focus={styles}
                    _hover={styles}
                    _active={{}}>
                    {children}
                </Button>
            </LinkOverlay>
        </LinkBox>
    );
}

function useFilteredMenuItems(menuLinks: Site.MenuLink[]) {
    const { isMobile } = useWindowBreakpoints();
    const { data: eligibility } = useEligibility();
    const links = menuLinks.filter((link) => {
        const isShown =
            link.show === 'always' ||
            link.show === (isMobile ? 'mobile' : 'desktop');
        const isEligible = link.eligibilities?.length
            ? link.eligibilities.every((linkEligibility) =>
                  get(eligibility, linkEligibility)
              )
            : true;
        return isShown && isEligible;
    });

    return links;
}

function MobileMenuLinks() {
    const { menu } = siteConfig;
    const iconFontSize = menu.showLabel ? 'xl' : '2xl';
    const links = useFilteredMenuItems(menu.links as Site.MenuLink[]);
    const router = useRouter();
    const defaultFocus = links.map((e) => e.to).indexOf(router.pathname);
    const rovingGroup = links.map((_, index) => index);
    const { setCurrentFocus, handleKeyDown } = useRoving(
        rovingGroup,
        'horizontal',
        defaultFocus || 0
    );

    return (
        <Flex
            w="100%"
            maxW="400px"
            flexDir="row"
            justifyContent="space-between"
            align="center"
            margin="auto">
            {links.map(({ to, label, icon, activeRegex }, id) => {
                const Icon = icons[icon];

                return (
                    <MenuItem
                        to={to}
                        key={uniqueId()}
                        activeRegex={new RegExp(activeRegex)}
                        setCurrentFocus={setCurrentFocus}
                        handleKeyDown={handleKeyDown}
                        id={id}>
                        <Flex flexDir="column" align="center">
                            {Icon && <Icon fontSize={iconFontSize} />}
                            {menu.showLabel && (
                                <Text fontSize="sm">
                                    <SafeFormattedMessage
                                        {...labelMessages[label]}
                                        debugKey={label}
                                    />
                                </Text>
                            )}
                        </Flex>
                    </MenuItem>
                );
            })}
        </Flex>
    );
}

function DesktopMenuLinks() {
    const router = useRouter();
    const { menu } = siteConfig;
    const links = useFilteredMenuItems(menu.links as Site.MenuLink[]);

    const defaultFocus = links.map((link) => link.to).indexOf(router.pathname);
    const rovingGroup = links.map((_, index) => index);
    const { currentFocus, setCurrentFocus, handleKeyDown, hasFocusMoved } =
        useRoving(rovingGroup, 'horizontal', defaultFocus || 0);

    return (
        <HStack
            spacing={{ base: 4, sm: 5, md: 8 }}
            align="center"
            justify="center"
            mb="-3px">
            {links?.map((link, idx) => (
                <MenuItem
                    setCurrentFocus={setCurrentFocus}
                    handleKeyDown={handleKeyDown}
                    isFocused={currentFocus === idx && hasFocusMoved}
                    id={idx}
                    to={link.to}
                    activeRegex={new RegExp(link.activeRegex)}
                    key={uniqueId()}>
                    <SafeFormattedMessage
                        {...labelMessages[link.label]}
                        debugKey={link.label}
                    />
                </MenuItem>
            ))}
        </HStack>
    );
}

function NavBarContainer({ children }: React.PropsWithChildren<unknown>) {
    const { isMobile, isTablet } = useWindowBreakpoints();
    const padding = isMobile ? 6 : isTablet ? 4 : 9;
    const { formatMessage } = useIntl();

    return (
        <Flex
            as="nav"
            aria-label={formatMessage(intlMessage)}
            role="navigation"
            align="center"
            justify="space-between"
            w="100%"
            bottom={isMobile ? '0' : 'none'}
            top={!isMobile ? '0' : 'none'}
            px={padding}
            position={isMobile ? 'fixed' : 'sticky'}
            zIndex={11}
            h={isMobile ? '60px' : 70}
            bg="white"
            borderBottomColor={!isMobile ? 'strokes.medium' : 'none'}
            borderBottomWidth={!isMobile ? '1px' : '0px'}
            filter={
                isMobile && 'drop-shadow(0px -2px 16px rgba(0, 0, 0, 0.1))'
            }>
            {children}
        </Flex>
    );
}

function NavBar() {
    const { isMobile } = useWindowBreakpoints();
    const { status } = useEligibility();

    return (
        <NavBarContainer>
            {status === 'pending' ? (
                <NavbarSkeleton />
            ) : isMobile ? (
                <MobileMenuLinks />
            ) : (
                <>
                    <Logo />
                    <DesktopMenuLinks />
                    <UserInfo />
                </>
            )}
        </NavBarContainer>
    );
}

export { labelMessages };
export default NavBar;
