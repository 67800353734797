import { capitalize } from 'lodash';
import { validationFormsMessages, Validations, FormatMessageFn } from 'core';

type LengthType = 'max' | 'min';

type Length = Validations['lengthMin'] | Validations['lengthMax'];

type ValidateSubFn = (amount: Length, formatMessage: FormatMessageFn) => any;

type ValidateLengthFn = (type: LengthType) => ValidateSubFn;

const validateLength: ValidateLengthFn = (type) => (length, formatMessage) => {
    if (!length) return undefined;
    return {
        value: length,
        message: formatMessage(
            validationFormsMessages[`length${capitalize(type)}`],
            {
                length,
            }
        ),
    };
};

const validateMinLength = validateLength('min');
const validateMaxLength = validateLength('max');

export { validateLength, validateMinLength, validateMaxLength };
