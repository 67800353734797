import { AxiosResponse } from 'axios';
import { Error } from 'core/components/Layouts/ErrorComponent';
import { coreErrors } from 'core';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import ContentTypesLibrarian from './ContentTypesLibrarian';
import { ContentTypes } from './types';
import { Center } from '@chakra-ui/react';

type FileViewerProps = {
    data: AxiosResponse<any> | undefined;
};

const intlMessage = defineMessage({
    id: 'documents.preview.document-viewing',
    defaultMessage: 'Visualisation de votre document',
});

const SUPPORTED_MIME_TYPE = ['image/png', 'image/jpeg', 'application/pdf'];

export default function FileViewer({ data }: FileViewerProps) {
    const contentType = data?.headers['content-type'] as ContentTypes;
    const { formatMessage } = useIntl();

    if (!contentType)
        return (
            <Error
                message={coreErrors.unavailableContentTitle}
                description={coreErrors.unavailableContentText}
            />
        );

    if (!SUPPORTED_MIME_TYPE.includes(contentType)) {
        return (
            <Center h="full">
                <FormattedMessage
                    id="documents.preview.download-to-view"
                    defaultMessage="Veuillez télécharger le fichier afin de le visualiser."
                />
            </Center>
        );
    }

    return (
        <ContentTypesLibrarian
            type={contentType}
            props={{
                intlMessage: formatMessage(intlMessage),
                file: data?.config.url,
                data: data?.data,
            }}
        />
    );
}
