import { validationFormsMessages, Validations, FormatMessageFn } from 'core';

type AmountType = 'max' | 'min';

type Amount = Validations['minAmount'] | Validations['maxAmount'];

type ValidateSubFn = (amount: Amount, formatMessage: FormatMessageFn) => any;

type ValidateAmountFn = (type: AmountType) => ValidateSubFn;

const validateAmount: ValidateAmountFn = (type) => (amount, formatMessage) => {
    if (!amount) return undefined;
    return {
        value: amount,
        message: formatMessage(validationFormsMessages[`${type}Amount`], {
            amount: amount,
        }),
    };
};

const validateMinAmount = validateAmount('min');
const validateMaxAmount = validateAmount('max');

export { validateAmount, validateMinAmount, validateMaxAmount };
