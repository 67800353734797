import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { useWindowBreakpoints } from 'design-system/hooks';

export type Props = React.PropsWithChildren<
    BoxProps & {
        caption?: React.ReactNode;
    }
>;

export function Illustration({ children, caption, ...boxProps }: Props) {
    const { isMobile } = useWindowBreakpoints();

    return (
        <Flex
            as="figure"
            py="20"
            m="auto"
            flex={1}
            flexGrow={1}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            {...boxProps}>
            {children}
            <Box
                as="figcaption"
                textAlign="center"
                mt="0"
                width={isMobile ? 'full' : 'xs'}>
                {caption}
            </Box>
        </Flex>
    );
}
