import React from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    HStack,
    Text,
    TextProps,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ChevronRightIcon } from 'design-system/icons';
import { uniqueId } from 'lodash';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { UrlObject } from 'url';
import { useDesignSystemContext } from 'design-system/lib';
import { useWindowBreakpoints } from 'design-system/hooks';

export type BreadcrumbType = {
    icon?: React.ComponentType<any>;
    label: MessageDescriptor | string;
    href?: UrlObject | string;
    LinkComponent?: React.ComponentType<unknown>;
    onClick?: () => void;
};

type BreadcrumbsProps = {
    breadcrumbs: BreadcrumbType[];
    iconColor?: string;
    textColor?: string;
};

export function Breadcrumbs({
    breadcrumbs,
    iconColor = 'black',
    textColor = null,
}: BreadcrumbsProps) {
    const { LinkComponent } = useDesignSystemContext();
    const { isMobile } = useWindowBreakpoints();

    function _renderBreadcrumb({
        icon: Icon,
        label,
        onClick,
        color,
    }: BreadcrumbType & Pick<TextProps, 'color'>) {
        const iconProps = {
            w: '6',
            h: '6',
            color: iconColor,
        };

        return (
            <HStack onClick={onClick} cursor={onClick && 'pointer'}>
                {isMobile && breadcrumbs.length > 1 ? (
                    <ArrowLeftIcon {...iconProps} />
                ) : (
                    Icon && <Icon {...iconProps} />
                )}

                <Text fontWeight="medium" color={color}>
                    {typeof label === 'string' ? (
                        <>{label}</>
                    ) : (
                        <FormattedMessage {...label} />
                    )}
                </Text>
            </HStack>
        );
    }

    return (
        <Breadcrumb
            separator={
                <Flex
                    position="relative"
                    top="1px"
                    as={ChevronRightIcon}
                    color={'primary.main'}
                />
            }>
            {breadcrumbs
                ?.filter((breadcrumb) => !!breadcrumb?.label)
                ?.slice(
                    isMobile ? breadcrumbs.length - 2 : 0,
                    isMobile && breadcrumbs.length > 1
                        ? breadcrumbs.length - 1
                        : breadcrumbs.length
                )
                ?.map((breadcrumb) => (
                    <BreadcrumbItem
                        key={uniqueId()}
                        isCurrentPage={!breadcrumb.href}>
                        {breadcrumb.href && LinkComponent ? (
                            <BreadcrumbLink
                                as={LinkComponent}
                                // @ts-expect-error
                                href={breadcrumb.href}
                                passHref
                                _hover={{ textDecor: 'none' }}>
                                {_renderBreadcrumb({
                                    ...breadcrumb,
                                    color: textColor || 'primary.main',
                                })}
                            </BreadcrumbLink>
                        ) : (
                            _renderBreadcrumb({
                                ...breadcrumb,
                                color: textColor || 'gray.800',
                            })
                        )}
                    </BreadcrumbItem>
                ))}
        </Breadcrumb>
    );
}
