// @see https://optisantis.atlassian.net/wiki/spaces/PA/pages/2612822072/Erreurs+MFA
export const MFA_ERROR_CODE = {
    MFA_REDIRECT: 'bc3a629d-ce06-4379-a96d-be732194d1c5',
    MFA_TOO_MANY_TRIES: '6d78dbe7-028c-46ac-b594-c152d04d942a',
    MFA_CODE_ALREADY_SENT: 'e79d5a69-3af3-445c-93ad-8d0e6f34c6f4',
    MFA_HAS_EXPIRED: 'd0c7a4c1-7836-4dd7-9d75-37981c586cd5',
    MFA_ALREADY_VALIDATED: 'cfc208ac-0f1c-44b5-a0a5-6b1d03e5fd47',
    MFA_INVALID_STRATEGY: 'be797f0e-1c52-4563-9574-d63a061b7993',
    MFA_BAD_CODE: 'be2d5a3f-492e-4f19-8ccc-53bf57476e1d',
    MFA_MISSING_CONTACT: 'e2c6d832-1a47-4083-b5a3-2d50c083d5f0',
    MFA_SMS_ERROR: 'af9261cb-a0e1-42e9-afed-7c9fbffa9d85',
    MFA_EMAIL_ERROR: '77719bab-5037-40f6-b009-0edf1d87a78b',
};
