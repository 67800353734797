import { Icon, IconProps } from '@chakra-ui/react';

export default function PagesIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 2.00001L19.0137 2C19.566 2 20.0137 2.44771 20.0137 3V6.15014L15 2.00001ZM7 20C6.44772 20 6 19.5523 6 19L6 5C6 4.44772 6.44772 4 7 4L12.4268 4C12.6655 4 12.8964 4.08539 13.0776 4.24075L17.6508 8.16061C17.8724 8.3506 18 8.62795 18 8.91987V19C18 19.5523 17.5523 20 17 20H7ZM4 19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19L20 8.91987C20 8.0441 19.6173 7.21205 18.9524 6.6421L14.3792 2.72223C13.8355 2.25618 13.143 2 12.4268 2H7C5.34315 2 4 3.34315 4 5V19ZM12.0002 14.0067H8.00025V13.5067C8.00025 12.9545 8.44796 12.5067 9.00025 12.5067H11.0002C11.5525 12.5067 12.0002 12.9545 12.0002 13.5067V14.0067ZM8.00025 16.5012V17.0012H14.0002V16.5012C14.0002 15.9489 13.5525 15.5012 13.0002 15.5012H9.00025C8.44796 15.5012 8.00025 15.9489 8.00025 16.5012Z"
                fill="currentColor"
            />
        </Icon>
    );
}
