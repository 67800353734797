import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams } from 'core';

const path = `/api/get-env-var/[name]`;

export async function fetchEnvVar({ queryKey: [urn, name] }: FetchQueryParams) {
    try {
        const response = await axios.get(urn.replace('[name]', name));
        return response?.data?.data;
    } catch (error) {
        throw { code: error?.response?.status };
    }
}

export function useGetEnvVar(name: string, isEnabled: boolean = true) {
    return useQuery<number, APIError>({
        queryKey: [path, name],
        queryFn: main.fetchEnvVar,
        enabled: isEnabled,
    });
}

const main = {
    useGetEnvVar,
    fetchEnvVar,
};

export default main;
