export const formatSiret = (input: string): string => {
    const str = String(input);
    if (str.length <= 5) {
        return str;
    }

    const beforeLastFive = str.slice(0, -5);

    const lastFive = str.slice(-5);

    return `${beforeLastFive} ${lastFive}`;
};
