export function formatOrganizationCodeValue(value: string): string {
    return value.replace(/^(\d{2})(\d{3})(\d{4})/, '$1 $2 $3');
}

export function formatValidationErrorMessageValue(
    value: number | string | string[] | undefined | null | boolean
) {
    if (value !== null && value !== undefined && typeof value === 'object') {
        return value.join(', ');
    }

    return value;
}

export const formatPhoneNumber = {
    compact: (phoneNumber: string = '') =>
        phoneNumber && phoneNumber.replaceAll(/\s/g, ''),
    loose: (phoneNumber: string = '', delimiter: string = '\u00a0') => {
        const matchedPhoneNumber = phoneNumber?.match(
            /^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/
        );

        if (!matchedPhoneNumber) return phoneNumber;

        const [, ...splittedPhoneNumber] = matchedPhoneNumber;

        return splittedPhoneNumber.join(delimiter);
    },
};

// Inspirated by Cleave.js getFormattedValue method
// (See: node_modules/cleave.js/src/utils/Util.js l.143)
export const formatSocialSecurityNumber = {
    compact: (socialSecurityNumber: string = '') =>
        socialSecurityNumber && socialSecurityNumber.replace(/\s/g, ''),
    loose: (
        socialSecurityNumber: string = '',
        delimiter: string = ' ',
        blocks: number[] = [1, 2, 2, 2, 3, 3, 2]
    ) => {
        let result = '';

        blocks.forEach(function (length, index) {
            if (socialSecurityNumber?.length > 0) {
                const sub = socialSecurityNumber.slice(0, length);
                const rest = socialSecurityNumber.slice(length);

                result += sub;

                if (sub.length === length && index < blocks.length - 1) {
                    result += delimiter;
                }

                // update remaining string
                socialSecurityNumber = rest;
            }
        });

        return result.toUpperCase();
    },
};

export function formatFrenchIban(iban: string) {
    const matchedIban = iban?.match(
        /^(FR\d{2})([A-Z0-9]{4})([A-Z0-9]{4})([A-Z0-9]{4})([A-Z0-9]{4})([A-Z0-9]{4})([A-Z0-9]{3})/
    );

    if (!matchedIban) return iban;

    const [, ...splittedIban] = matchedIban;

    return splittedIban.join(' ');
}
