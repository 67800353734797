import { arrayToObject, type FormValues } from 'core';
import { formatFormFinalObject } from './handlers';

function transformFileUpload(inputId: string, data: FormValues) {
    const fileData = Object.entries(data);
    const parsedData = arrayToObject(
        fileData.map(([id, content]) => {
            const parsedContent = JSON.parse(content);
            delete parsedContent.size;

            return {
                [id]: {
                    content: parsedContent.content
                        .replace('data:', '')
                        .replace(/^.+,/, ''),
                    name: parsedContent.name,
                },
            };
        })
    );

    return formatFormFinalObject(inputId, parsedData);
}

export default transformFileUpload;
