import { Icon, IconProps } from '@chakra-ui/react';

export default function ArrowLeftIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M8.061 11H18a1 1 0 1 1 0 2H8.061l2.833 3.596a1 1 0 0 1-1.571 1.237L5.215 12.62a1 1 0 0 1 0-1.238l4.108-5.214a1 1 0 0 1 1.57 1.237L8.062 11z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
