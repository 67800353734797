import { defineMessages } from 'react-intl';

export const exemptionsReasons = {
    'ME-1': defineMessages({
        title: {
            id: 'workflow-signature.workflow-exemption.reasons.ME-1.title',
            defaultMessage:
                'Je bénéficie de la complémentaire santé solidaire (CSS)',
        },
    }),
    'ME-2': defineMessages({
        title: {
            id: 'workflow-signature.workflow-exemption.reasons.ME-2.title',
            defaultMessage:
                'Je bénéficie d’une couverture individuelle frais de santé',
        },
    }),
    'ME-4': defineMessages({
        title: {
            id: 'workflow-signature.workflow-exemption.reasons.ME-4.title',
            defaultMessage: 'Je suis couvert, au titre d’un autre emploi',
        },
        description: {
            id: 'workflow-signature.workflow-exemption.reasons.ME-4.description',
            defaultMessage:
                "<p>Dans le cadre d'un des dispositifs suivants :</p><p>• Régime collectif et obligatoire d’entreprise</p><p>• Régime local d’Alsace Moselle</p><p>• Contrat “Madelin”</p><p>• Régime Camieg</p><p>• Régime des agents de l’Etat ou des collectivités territoriales</p>",
        },
    }),
    'ME-5': defineMessages({
        title: {
            id: 'workflow-signature.workflow-exemption.reasons.ME-5.title',
            defaultMessage: 'Je suis couvert en tant qu’ayant-droit',
        },
        description: {
            id: 'workflow-signature.workflow-exemption.reasons.ME-5.description',
            defaultMessage:
                "<p>Dans le cadre d'un des dispositifs suivants :</p><p>• Régime collectif et obligatoire d’entreprise</p><p>• Régime local d’Alsace Moselle</p><p>• Contrat “Madelin”</p><p>• Régime Camieg</p><p>• Régime des agents de l’Etat ou des collectivités territoriales</p>",
        },
    }),
    'ME-6': defineMessages({
        title: {
            id: 'workflow-signature.workflow-exemption.reasons.ME-6.title',
            defaultMessage:
                "Je suis en CDD ou en contrat de mission, couvert moins de 3 mois par le régime et je bénéficie d'une couverture responsable",
        },
    }),
    'OE-1': defineMessages({
        title: {
            id: 'workflow-signature.workflow-exemption.reasons.OE-1.title',
            defaultMessage:
                'Je suis salarié ou apprenti en CDD ou en contrat de mission pour moins de {month} mois',
        },
    }),
    'OE-2': defineMessages({
        title: {
            id: 'workflow-signature.workflow-exemption.reasons.OE-2.title',
            defaultMessage:
                "Je suis salarié ou apprenti en CDD ou en contrat de mission pour plus de {month} mois et je bénéficie d'une couverture individuelle frais de santé",
        },
    }),
    'OE-3': defineMessages({
        title: {
            id: 'workflow-signature.workflow-exemption.reasons.OE-3.title',
            defaultMessage:
                'Je suis en temps partiel ou apprenti et l’affiliation au régime coûterait au moins 10 % de ma rémunération brute',
        },
    }),
    'OE-4': defineMessages({
        title: {
            id: 'workflow-signature.workflow-exemption.reasons.OE-4.title',
            defaultMessage:
                'Je suis en couple avec une personne de la même entreprise',
        },
    }),
    'OE-5': defineMessages({
        title: {
            id: 'workflow-signature.workflow-exemption.reasons.OE-5.title',
            defaultMessage: 'Je suis couvert en tant qu’ayant-droit',
        },
        description: {
            id: 'workflow-signature.workflow-exemption.reasons.OE-5.description',
            defaultMessage:
                "<p>Dans le cadre d'un des dispositifs suivants : </p><p>• Régime collectif et obligatoire d’entreprise</p><p>• Régime local d’Alsace Moselle</p><p>• Contrat “Madelin”</p><p>• Régime Camieg</p><p>• Régime des agents de l’Etat ou des collectivités territoriales</p><p>• Caisse du personnel de la SNCF</p><p>• Régime de la sécurité sociale des gens de mer</p>",
        },
    }),
    'OE-6': defineMessages({
        title: {
            id: 'workflow-signature.workflow-exemption.reasons.OE-6.title',
            defaultMessage: 'Je suis couvert, au titre d’un autre emploi',
        },
        description: {
            id: 'workflow-signature.workflow-exemption.reasons.OE-6.description',
            defaultMessage:
                "<p>Dans le cadre d'un des dispositifs suivants : </p><p>• Régime collectif et obligatoire d’entreprise</p><p>• Régime local d’Alsace Moselle</p><p>• Contrat “Madelin”</p><p>• Régime Camieg</p><p>• Régime des agents de l’Etat ou des collectivités territoriales</p><p>• Caisse du personnel de la SNCF</p><p>• Régime de la sécurité sociale des gens de mer</p>",
        },
    }),
    'OE-7': defineMessages({
        title: {
            id: 'workflow-signature.workflow-exemption.reasons.OE-7.title',
            defaultMessage:
                "J'étais salarié à la mise en place par DUE du régime avec précompte salarial",
        },
        description: {
            id: 'workflow-signature.workflow-exemption.reasons.OE-7.description',
            defaultMessage:
                'J’ai refusé d’adhérer à la première mise en place du régime dans mon entreprise co-financé entre l’employeur et le salarié et je n’y ai jamais adhéré par la suite. (Art. 11 de la loi Evin)',
        },
    }),
};
