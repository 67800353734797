import React from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ChallengeError, FetchQueryParams } from 'core';
import { useFormRedirect } from 'core/lib/auth';

const path = '/api/mfa';

export function useMFAGet() {
    const { redirectAfterLogin } = useFormRedirect();

    const [error, setError] = React.useState<ChallengeError>();

    async function getChannels({ queryKey: [urn] }: FetchQueryParams) {
        try {
            const { data } = await axios.get(urn);
            return data;
        } catch (err) {
            const { data } = err.response;

            if (data.code === 'MFA_ALREADY_VALIDATED') {
                redirectAfterLogin();
            }

            setError(data);
        }
    }

    const { status, data } = useQuery<any, ChallengeError>({
        queryKey: [path],
        queryFn: getChannels,
        gcTime: 0,
    });

    return { error, status, data };
}
