import { Icon, IconProps } from '@chakra-ui/react';

export default function CalendarIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 32 30" {...props}>
            <path
                d="M11.9998 12C11.9998 12.7364 11.4029 13.3333 10.6665 13.3333C9.93012 13.3333 9.33317 12.7364 9.33317 12C9.33317 11.2636 9.93012 10.6667 10.6665 10.6667C11.4029 10.6667 11.9998 11.2636 11.9998 12Z"
                fill="currentColor"
            />
            <path
                d="M11.9998 17.3333C11.9998 18.0697 11.4029 18.6667 10.6665 18.6667C9.93012 18.6667 9.33317 18.0697 9.33317 17.3333C9.33317 16.597 9.93012 16 10.6665 16C11.4029 16 11.9998 16.597 11.9998 17.3333Z"
                fill="currentColor"
            />
            <path
                d="M17.3332 12C17.3332 12.7364 16.7362 13.3333 15.9998 13.3333C15.2635 13.3333 14.6665 12.7364 14.6665 12C14.6665 11.2636 15.2635 10.6667 15.9998 10.6667C16.7362 10.6667 17.3332 11.2636 17.3332 12Z"
                fill="currentColor"
            />
            <path
                d="M22.6665 12C22.6665 12.7364 22.0696 13.3333 21.3332 13.3333C20.5968 13.3333 19.9998 12.7364 19.9998 12C19.9998 11.2636 20.5968 10.6667 21.3332 10.6667C22.0696 10.6667 22.6665 11.2636 22.6665 12Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.33317 5.33334V4.00001C9.33317 3.26363 9.93012 2.66667 10.6665 2.66667C11.4029 2.66667 11.9998 3.26363 11.9998 4.00001V5.33334H19.9998V4.00001C19.9998 3.26363 20.5968 2.66667 21.3332 2.66667C22.0696 2.66667 22.6665 3.26363 22.6665 4.00001V5.33334H26.6665C28.1393 5.33334 29.3332 6.52725 29.3332 8.00001V26.6667C29.3332 28.1394 28.1393 29.3333 26.6665 29.3333H5.33317C3.86041 29.3333 2.6665 28.1394 2.6665 26.6667V8.00001C2.6665 6.52725 3.86041 5.33334 5.33317 5.33334H9.33317ZM5.33317 8.00001V26.6667H26.6665V8.00001H5.33317Z"
                fill="currentColor"
            />
        </Icon>
    );
}
