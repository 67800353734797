import { Button, LinkBox, LinkOverlay, Stack } from '@chakra-ui/react';
import { LayoutButtonType } from 'core/lib/shared';
import { SafeFormattedMessage, LinkIntl } from '..';

type LayoutButtonProps = {
    button: LayoutButtonType;
    index: number;
};

function LayoutButton({ button, index }: LayoutButtonProps) {
    return (
        <Button
            colorScheme="primary"
            variant={index === 0 ? undefined : 'outline'}
            fontWeight="bold"
            w="full"
            onClick={button.onClick}>
            <SafeFormattedMessage
                {...button.text}
                debugKey="Layout boundary errorButton"
            />
        </Button>
    );
}

export function LayoutButtons({ buttons }: { buttons: LayoutButtonType[] }) {
    return (
        <Stack mt="6">
            {buttons?.map((button, index) => (
                <Stack key={button?.text?.id}>
                    {button?.href ? (
                        <LinkBox>
                            <LinkOverlay
                                as={LinkIntl}
                                // @ts-expect-error
                                href={button.href}
                                passHref>
                                <LayoutButton {...{ button, index }} />
                            </LinkOverlay>
                        </LinkBox>
                    ) : (
                        <LayoutButton {...{ button, index }} />
                    )}
                </Stack>
            ))}
        </Stack>
    );
}
