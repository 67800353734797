import React from 'react';
import { Button, HStack, Stack, Text, useBoolean } from '@chakra-ui/react';
import {
    FormInputCardButton,
    FormInputCardButtonIcon,
    FormInputCardButtonTitle,
    FormValues,
    TreatmentWidgetObject,
    useFormValidation,
    __DEV__,
    WidgetKeys,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { sharedMessages } from 'lib/shared';
import { FormProvider } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { FormLibrarian } from 'components/forms';
import { omit } from 'lodash';
import { DevTool } from '@hookform/devtools';
import {
    CoreModal,
    CoreModalContent,
    CoreModalHeader,
    CoreModalBody,
} from 'design-system/components';

type Props = {
    onAdd: (data: FormValues[]) => void;
    widgets: TreatmentWidgetObject;
    isDisabled: boolean;
};

const intlMessages = defineMessages({
    buttonLabel: {
        id: 'components.multiple-treatments.button-label',
        defaultMessage: 'Ajouter un soin',
    },
    disableMessage: {
        id: 'components.multiple-treatments.button-disabled',
        defaultMessage: 'La limite de soins a été atteinte.',
    },
});

function useMultipleTreatmentsForm(
    widgets: Props['widgets'],
    onSuccess: (result: FormValues[]) => void
) {
    const [formWidgets, setFormWidgets] = React.useState<WidgetKeys[]>();
    const { onSubmit, methods } = useFormValidation(null, null, formWidgets);

    React.useEffect(() => {
        setFormWidgets(Object.values(omit(widgets, 'id')));

        // On veut récupérer les widgets uniquement lors du premier rendu.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleSubmit(event: React.BaseSyntheticEvent) {
        event?.stopPropagation();
        methods.handleSubmit(async (values: FormValues) => {
            const result = await onSubmit(values);
            if (result) onSuccess(result);
        })(event);
    }

    return { formWidgets, methods, handleSubmit };
}

export function MultipleTreatmentsAdd({ widgets, onAdd, isDisabled }: Props) {
    const intl = useIntl();
    const { isMobile } = useWindowBreakpoints();
    const [isOpen, setIsOpen] = useBoolean();
    const { formWidgets, handleSubmit, methods } = useMultipleTreatmentsForm(
        widgets,
        (values) => {
            onAdd(values);
            setIsOpen.off();
        }
    );

    return (
        <>
            <FormInputCardButton onClick={setIsOpen.on} isDisabled={isDisabled}>
                <FormInputCardButtonIcon />
                <FormInputCardButtonTitle
                    disabledMessage={intl.formatMessage(
                        intlMessages.disableMessage
                    )}>
                    {intl.formatMessage(intlMessages.buttonLabel)}
                </FormInputCardButtonTitle>
            </FormInputCardButton>

            <CoreModal isOpen={isOpen} onClose={setIsOpen.off} size="3xl">
                <CoreModalContent
                    p="0"
                    minW={!isMobile ? 'lg' : '90%'}
                    borderRadius={isMobile ? 'lg' : 'xl'}
                    mx="4">
                    <CoreModalHeader>
                        <Text
                            as="h3"
                            fontWeight="semibold"
                            fontFamily="mono"
                            w="100%"
                            mr="2">
                            <FormattedMessage
                                id="components.multiple-treatments.modal.title"
                                defaultMessage="Ajout d'un soin"
                            />
                        </Text>
                    </CoreModalHeader>
                    <CoreModalBody>
                        <FormProvider {...methods}>
                            <Stack
                                noValidate
                                as="form"
                                id="treatments-form"
                                onSubmit={handleSubmit}
                                spacing="6"
                                alignItems={isMobile ? 'stretch' : 'flex-end'}>
                                {formWidgets?.map(({ id, type, ...props }) => (
                                    <FormLibrarian
                                        key={id}
                                        type={type}
                                        props={{
                                            id,
                                            methods: {
                                                setError: methods.setError,
                                                errors: methods.formState
                                                    .errors,
                                                clearErrors:
                                                    methods.clearErrors,
                                            },
                                            ...props,
                                        }}
                                    />
                                ))}

                                <HStack w="full">
                                    <Button onClick={setIsOpen.off} w="full">
                                        <FormattedMessage
                                            {...sharedMessages.cancel}
                                        />
                                    </Button>
                                    <Button
                                        form="treatments-form"
                                        type="submit"
                                        colorScheme="primary"
                                        w="full">
                                        <FormattedMessage
                                            {...sharedMessages.validate}
                                        />
                                    </Button>
                                </HStack>
                            </Stack>
                        </FormProvider>
                    </CoreModalBody>
                </CoreModalContent>
                {__DEV__ && <DevTool control={methods.control} />}
            </CoreModal>
        </>
    );
}
