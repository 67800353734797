import React from 'react';
import { useEligibility } from 'lib/shared';
import { isNative } from 'core';
import { SATISFACTION_SESSION_KEY } from '..';

export function useSatisfactionVisibility() {
    const { data } = useEligibility();
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
        const isSatisfactionClosedOnSession = !!window.sessionStorage.getItem(
            SATISFACTION_SESSION_KEY
        );
        setIsVisible(
            data?.showSatisfaction &&
                !isSatisfactionClosedOnSession &&
                isNative()
        );
    }, [data]);

    return isVisible;
}
