import axios from 'axios';
import { APIError, FetchQueryParams, WorkflowKeysEnum } from 'core';
import { useToast } from 'design-system/components';
import { useQuery } from '@tanstack/react-query';
import { TeleconsultationDocument } from '../types';
import { errors } from 'lib/shared';
import { useIntl } from 'react-intl';

const categoriesSorting = ['MedicalReport', 'Prescription', 'File'];

export const pathEnum = {
    [WorkflowKeysEnum.TELECONSULTATION]: `/api/proxy/europ-assistance/teleconsultations/[id]/documents`,
    [WorkflowKeysEnum.PREVENTION]: `/api/proxy/europ-assistance/preventions/[id]/documents`,
};

async function fetchTeleconsultationDocuments(
    { queryKey: [urn, teleconsultationId] }: FetchQueryParams,
    toast: () => void
) {
    try {
        if (!teleconsultationId) {
            throw { code: 404 };
        }

        const response = await axios.get(
            urn.replace('[id]', teleconsultationId)
        );

        if (response.data?.meta?.hasErrors) {
            toast();
        }

        return response.data?.data
            ?.sort((a, b) => {
                return categoriesSorting.indexOf(a.category) !== -1 &&
                    categoriesSorting.indexOf(a.category) <
                        categoriesSorting.indexOf(b.category)
                    ? -1
                    : 1;
            })
            ?.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr?.category]: [...(acc[curr?.category] || []), curr],
                }),
                {}
            );
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useTeleconsultationDocuments(
    teleconsultationId: string,
    pathType: keyof typeof pathEnum
) {
    const { formatMessage } = useIntl();
    const toast = useToast({
        status: 'error',
        description: formatMessage(errors.counter),
    });

    return useQuery<
        { [category: string]: TeleconsultationDocument[] },
        APIError
    >({
        queryKey: [pathEnum[pathType], teleconsultationId],

        queryFn: (params: FetchQueryParams) =>
            fetchTeleconsultationDocuments(params, toast),
    });
}
