import { Icon, IconProps } from '@chakra-ui/react';

export default function CashIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M17.851 8.96a.891.891 0 1 1-1.652.671.891.891 0 0 1 1.652-.67zM7.45 14.144a.891.891 0 1 0-.67-1.652.891.891 0 0 0 .67 1.652z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M2.47 11.355c-.37-.912.07-1.952.982-2.322L16.666 3.67c.912-.37 1.952.07 2.322.982l2.681 6.607c.37.912-.069 1.952-.981 2.322l-.023.01a4.01 4.01 0 1 1-7.388 2.998l-5.803 2.355a1.783 1.783 0 0 1-2.322-.982L2.47 11.355zm10.74 3.337c.094-.45.263-.881.501-1.273a2.674 2.674 0 1 1 .74-.895 4.007 4.007 0 0 1 4.94-.34l.626-.255-2.68-6.607-13.215 5.363 2.681 6.607 6.407-2.6zm-.47-1.733a1.783 1.783 0 1 0-1.34-3.304 1.783 1.783 0 0 0 1.34 3.304zm6.464 1.705a2.228 2.228 0 1 1-4.13 1.676 2.228 2.228 0 0 1 4.13-1.676z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
