import { Site } from 'core';
import * as Sentry from '@sentry/nextjs';

const appIds = {
    integration: {
        android: 'fr.[client].[environment]',
        ios: 'fr.[client]-[environment]',
    },
    staging: {
        android: 'fr.[client].[environment]',
        ios: 'fr.[client]-[environment]',
    },
    production: {
        android: 'fr.[client].[environment]',
        ios: 'fr.[client]-[environment]',
    },
};

const productionAppIds = {
    caa: 'fr.creditagricole.camasante',
    lcl: 'lcl.com.application.lclmasante',
    lmv: 'com.mutuelleverte',
    bnp: 'com.bnp.bnpmasante',
    mercer: 'fr.mercer.mercermasante',
    mfu: 'fr.mutuelledefranceunie.monespace',
    mth: 'fr.mutuellemth.monespace',
    samir: 'fr.mutuellesamir.monespace',
};

const firebaseDomains = {
    caa: 'camasante',
    lcl: 'lclmasante',
    lmv: 'lmvmasante',
    bnp: 'bnpprotectionsante',
    mercer: 'mercermasante',
    mfu: 'mfumasante',
    mth: 'mthmasante',
    samir: 'samirmasante',
};

function replaceParameters(
    rawText: string,
    site: string,
    environment: Site.Env
) {
    return rawText
        .replace('[client]', site === 'caa' ? 'ca' : site)
        .replace('[environment]', environment);
}

export function getClientNativeAppInfos(site: string, environment: Site.Env) {
    if (!firebaseDomains[site]) {
        const errorMessage = `#getClientNativeAppInfos - Could not find site ${site}`;
        Sentry.captureMessage(errorMessage);
        throw errorMessage;
    }

    if (['preprod', 'production'].includes(environment)) {
        return {
            android: productionAppIds[site],
            ios: productionAppIds[site],
            firebaseDomain: firebaseDomains[site],
        };
    }

    return {
        android: replaceParameters(
            appIds[environment].android,
            site,
            environment
        ),
        ios: replaceParameters(appIds[environment].ios, site, environment),
        firebaseDomain: firebaseDomains[site],
    };
}
