import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    PinInput,
    Flex,
    Stack,
    Text,
    PinInputField,
    SimpleGrid,
    Button,
} from '@chakra-ui/react';
import { NUMBER_OF_PIN } from 'core/lib/signature';

type VerificationCodePinsProps = {
  handleInput: (value: string) => void;
  isCodeValid: boolean;
  onResend: () => void;
}

export const VerificationCodePins = ({ handleInput, isCodeValid, onResend }: VerificationCodePinsProps) => (
  <Flex justifyContent="center" mt="4">
      <Stack alignItems="center">
          <SimpleGrid columns={{ base: 6, sm: 6 }} gap="2">
              <PinInput
                  otp
                  onChange={handleInput}
                  isInvalid={isCodeValid}>
                  {Array.from(
                      Array(NUMBER_OF_PIN).keys()
                  ).map((pin) => (
                      <PinInputField key={pin} mt="2" />
                  ))}
              </PinInput>
          </SimpleGrid>
          <Button
              variant="ghost"
              textDecor="underline"
              textDecorationColor="primary.main"
              onClick={onResend}>
              <Text color="primary.main">
                  <FormattedMessage
                      id="workflow-signature.signature.verification-code-block.text-link"
                      defaultMessage="Renvoyer le code"
                  />
              </Text>
          </Button>
      </Stack>
  </Flex>
)

