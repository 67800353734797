import React, { ComponentType } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Text } from '@chakra-ui/react';

import {
    WorkflowTypesEnum,
    useBackBreadcrumbWithOnClick,
} from '../../../lib/shared';
import { signatureMessages } from '../../../lib/signature';
import { useWindowBreakpoints } from 'design-system/hooks';
import { HeaderBoxProps, HeaderSecondaryProps } from '.';

type BeneficiariesHeaderProps = {
    service: WorkflowTypesEnum;
    HeaderBox: ComponentType<HeaderBoxProps>;
    HeaderSecondary: ComponentType<HeaderSecondaryProps>;
    workflowSteps: string[];
    type: 'full-capital' | 'divided-capital';
    submitError: number;
};

const intlMessages = defineMessages({
    title: {
        id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiary.title',
        defaultMessage:
            "Ajout {isFullCapital, select, true {d'un bénéficiaire} other {des bénéficiaires}}",
    },
});

export const BeneficiariesHeader = ({
    HeaderBox,
    HeaderSecondary,
    service,
    workflowSteps,
    type,
    submitError,
}: BeneficiariesHeaderProps) => {
    const { isMobile } = useWindowBreakpoints();
    const backBreadcrumbWithOnClick = useBackBreadcrumbWithOnClick();

    return (
        <HeaderBox bg="white" pt={isMobile ? '12' : '8'} px="0">
            <HeaderSecondary
                breadcrumbs={[backBreadcrumbWithOnClick]}
                mb={isMobile ? '0' : '3'}
                progressBar={{
                    currentStep:
                        Object.values(workflowSteps).indexOf(
                            service === WorkflowTypesEnum.PA
                                ? 'add-beneficiaries'
                                : 'beneficiary-addition'
                        ) + 1,
                    maxStep: workflowSteps?.length,
                    ...(service === WorkflowTypesEnum.PE
                        ? { color: 'grey.600' }
                        : {}),
                }}
                subtitle={
                    <Text
                        as="h2"
                        color="primary.main"
                        fontFamily="mono"
                        fontWeight="semibold"
                        fontSize="2xl">
                        <FormattedMessage
                            {...intlMessages.title}
                            values={{
                                isFullCapital: type === 'full-capital',
                            }}
                        />
                    </Text>
                }>
                <FormattedMessage
                    {...(submitError
                        ? signatureMessages['error-title']
                        : signatureMessages[
                              'signature-beneficiaries-designation.specific-guided'
                          ])}
                />
            </HeaderSecondary>
        </HeaderBox>
    );
};
