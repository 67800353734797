import axios from 'axios';

async function deleteAccount() {
    try {
        const response = await axios.delete('/api/account');
        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export { deleteAccount };
