import { coreSharedMessages } from 'core/lib/shared/i18n';
import { defineMessages } from 'react-intl';

export const profileReasonsFormsMessages = {
    'contactInfo.email': coreSharedMessages.profileEmail,
    'connexionInfo.password': coreSharedMessages.profilePassword,
    'contactInfo.phones': coreSharedMessages.profilePhoneNumbers,
    'profile.privacy-policy': coreSharedMessages.profilePrivacyPolicy,
    'parametersInfo.privacy-policy': coreSharedMessages.profilePrivacyPolicy,
    'connexionInfo.id': coreSharedMessages.profileUsername,
    ...defineMessages({
        'contactInfo.address': {
            id: 'profile.address',
            defaultMessage: 'Adresse',
        },
        'parametersInfo.terms-of-use': {
            id: 'profile.terms-of-use',
            defaultMessage: 'Mentions légales & CGU',
        },
        'param.language': {
            id: 'profile.language',
            defaultMessage: "Langue d'affichage",
        },
        'profile.consent': {
            id: 'profile.consent',
            defaultMessage: 'Gestion du consentement',
        },
    }),
};
