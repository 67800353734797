import React from 'react';
import { Flex } from '@chakra-ui/react';

import { contextError } from 'core/utils';
import { DoughnutData } from './types';
import { useClickOutside } from 'core/';

type DoughnutProps = {
    data: DoughnutData[];
    total: string;
    alignArc?: 'flex-start' | 'center' | 'flex-end';
    position?: 'vertical' | 'horizontal';
    defaultSelected?: number | null;
};

type DoughnutContextProps = Pick<DoughnutProps, 'data' | 'total'> & {
    selected: number | null;
    setSelected: React.Dispatch<React.SetStateAction<number | null>>;
};

const DoughnutContext = React.createContext<DoughnutContextProps | null>(null);

export function useDoughnutContext(): DoughnutContextProps {
    const context = React.useContext(DoughnutContext);
    if (!context) {
        throw contextError('useDoughnutContext', 'Doughnut');
    }
    return context;
}

export function Doughnut({
    children,
    data,
    alignArc = 'center',
    position = 'vertical',
    defaultSelected = null,
    total,
}: React.PropsWithChildren<DoughnutProps>) {
    const [selected, setSelected] = React.useState<number | null>(
        defaultSelected
    );
    const alignText =
        alignArc === 'flex-end'
            ? 'right'
            : alignArc === 'flex-start'
            ? 'left'
            : 'center';
    const clickOutsideRef = useClickOutside(() => setSelected(null), 'all');

    return (
        <DoughnutContext.Provider
            value={{ data, selected, setSelected, total }}>
            <Flex
                as="figure"
                w="100%"
                ref={clickOutsideRef}
                textAlign={alignText}
                flexDir={position === 'vertical' ? 'column' : 'row'}
                sx={{
                    '> svg': {
                        alignSelf: alignArc ?? 'center',
                    },
                    '> * + *': {
                        mt: 6,
                    },
                }}>
                {children}
            </Flex>
        </DoughnutContext.Provider>
    );
}
