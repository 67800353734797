import type { FormValues } from 'core';
import { transformDate } from './transformDate';
import transformCheckboxList from './transformCheckboxList';
import transformMultipleFileUpload from './transformMultipleFileUpload';
import { SubWidgetType } from './handlers';
import transformPhoneNumber from './transformPhoneNumber';

/* This function was meant to be used as a specific case but it seems we will have to add more and more cases
   When we'll have the time, it would be nice to be able to get the subwidget type here, so we can call the proper transformations automatically */
function getFormattedSubWidgetValue(
    inputId,
    subWidgetId,
    subWidgetValue: { value?: unknown } | string[],
    subWidgetsType?: SubWidgetType[]
) {
    const subWidgetType = subWidgetsType?.find((widget) =>
        widget?.subWidget?.id.includes(subWidgetId)
    )?.subWidget?.type;
    switch (subWidgetType) {
        case 'conditionalCheckBoxList':
        case 'autocompleteCheckboxList':
            return transformCheckboxList(
                `${inputId}.${subWidgetId}`,
                subWidgetValue as string[],
                subWidgetsType
            );
        case 'date':
            return transformDate(
                `${inputId}.${subWidgetId}`,
                subWidgetValue as string[]
            );
        case 'multipleFileUpload':
            return transformMultipleFileUpload(
                `${inputId}.${subWidgetId}`,
                subWidgetValue
            );
        case 'phoneNumber':
        case 'faxNumber':
            return transformPhoneNumber(
                `${inputId}.${subWidgetId}`,
                subWidgetValue
            );
        default:
            const value = (subWidgetValue as { value?: unknown })?.value;
            return value ? { value } : undefined;
    }
}

function transformSingleSubwidget(
    subWidgetKey: {
        [x: string]: any;
    },
    value: any,
    inputId: string,
    subWidgetsType: SubWidgetType[]
) {
    const [subWidgetId, subWidgetValue] = subWidgetKey[value]
        ? [value, subWidgetKey[value]]
        : Object.entries(subWidgetKey)[0] || [];

    return {
        id: inputId,
        value,
        ...(subWidgetId && {
            subWidget: {
                id: `${inputId}.${subWidgetId}`,
                ...getFormattedSubWidgetValue(
                    inputId,
                    subWidgetId,
                    subWidgetValue,
                    subWidgetsType
                ),
            },
        }),
    };
}

function transformMultipleSubwidgets(
    inputId: string,
    data: FormValues,
    subWidgetsType: SubWidgetType[]
) {
    const { value, ...subWidgetsData } = data;
    const subWidgets = Object.entries(subWidgetsData).map(
        ([subWidgetId, subWidgetValue]) => ({
            id: `${inputId}.${subWidgetId}`,
            ...getFormattedSubWidgetValue(
                inputId,
                subWidgetId,
                subWidgetValue,
                subWidgetsType
            ),
        })
    );
    return {
        id: inputId,
        value,
        ...(subWidgets.length ? { subWidgets } : { subWidgets: [] }),
    };
}

function transformConditionalRadio(
    inputId: string,
    data: FormValues,
    subWidgetsType: SubWidgetType[]
) {
    const { value, ...subWidgetKeys } = data;
    if (Object.keys(subWidgetKeys).length > 1) {
        return transformMultipleSubwidgets(inputId, data, subWidgetsType);
    } else {
        return transformSingleSubwidget(
            subWidgetKeys,
            value,
            inputId,
            subWidgetsType
        );
    }
}

export default transformConditionalRadio;
