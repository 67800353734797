import axios from 'axios';
import { FormObject, FetchQueryParams, FormWorkflow, APIError } from 'core';
import { useQuery } from '@tanstack/react-query';
import { WorkflowTypesEnum } from 'core/lib/shared/types';

const paths = {
    [WorkflowTypesEnum.PA]: {
        'signature-health-subscription': `/api/proxy/selfcare/subscription/health-product/consent/{procedureId}`,
        'signature-beneficiaries-designation.standard': `/api/proxy/provident/beneficiaries-designation/consent/{procedureId}`,
        'signature-beneficiaries-designation.specific-guided': `/api/proxy/provident/beneficiaries-designation/consent/{procedureId}`,
    },
    [WorkflowTypesEnum.PE]: {
        signature_exemption: `/api/proxy/employee-exemption/consent/{procedureId}`,
        'signature-beneficiaries-designation.standard': `/api/proxy/employee-beneficiary-designation/beneficiaries-designation/consent/{procedureId}`,
        'signature-beneficiaries-designation.specific-guided.divided-capital': `/api/proxy/employee-beneficiary-designation/beneficiaries-designation/consent/{procedureId}`,
        'signature-beneficiaries-designation.specific-guided.full-capital': `/api/proxy/employee-beneficiary-designation/beneficiaries-designation/consent/{procedureId}`,
        'legal-documents': '/api/proxy/employee-legal-documents/consent',
    },
};

async function fetchSignaturePreviewConsentForm({
    queryKey: [urn, procedureId],
}: FetchQueryParams) {
    try {
        const response = await axios.options(
            urn.replace('{procedureId}', procedureId)
        );
        return response.data.data;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useSignaturePreviewConsentForm(
    procedureId: string,
    workflow: FormWorkflow,
    service: WorkflowTypesEnum
) {
    return useQuery<FormObject, APIError>({
        queryKey: [paths[service][workflow], procedureId],
        queryFn: fetchSignaturePreviewConsentForm,
    });
}
