import React from 'react';
import { uniqueId } from 'lodash';
import {
    FormInputCardButton,
    FormInputCardButtonIcon,
    FormInputCardButtonTitle,
} from 'core';
import { defineMessages, useIntl } from 'react-intl';
import getDisplayableMimeTypes from './utils/getDisplayableMimeTypes';
import useFormattedFileSize from './utils/useFormattedFileSize';

const intlMessages = defineMessages({
    disableMessage: {
        id: 'components.file-upload-button.disable-message',
        defaultMessage:
            'La limite de fichiers a été atteinte pour cette catégorie.',
    },
    max: {
        id: 'components.file-upload-button.max',
        defaultMessage: 'max/document',
    },
});

function getMimeTypeSeparator(
    mimeTypesLength: number,
    currentIndex: number,
    wordSeparator: string
) {
    if (currentIndex === 0) return '';
    if (currentIndex === mimeTypesLength - 1) return ` ${wordSeparator} `;
    return ', ';
}

type FileUploadButtonProps = {
    onClick: () => void;
    isDragActive: boolean;
    acceptedMimeTypes: string[];
    maxFileSize: number;
    disabled: boolean;
};

function FileUploadButton({
    onClick,
    isDragActive,
    acceptedMimeTypes,
    maxFileSize,
    disabled,
}: FileUploadButtonProps) {
    const { formatMessage } = useIntl();
    const formattedFileSize = useFormattedFileSize(maxFileSize, 0);
    const displayableMimeTypes = getDisplayableMimeTypes(acceptedMimeTypes);

    return (
        <FormInputCardButton
            onClick={disabled ? undefined : onClick}
            isDisabled={disabled}
            isDragActive={isDragActive}>
            <FormInputCardButtonIcon />
            <FormInputCardButtonTitle
                disabledMessage={formatMessage(intlMessages.disableMessage)}
                description={`${formattedFileSize} ${formatMessage(
                    intlMessages.max
                )}`}>
                {displayableMimeTypes?.map((mimeType, index) => (
                    <React.Fragment key={uniqueId()}>
                        {getMimeTypeSeparator(
                            displayableMimeTypes.length,
                            index,
                            formatMessage({
                                id: 'components.common.or',
                                defaultMessage: 'ou',
                            })
                        )}
                        {mimeType}
                    </React.Fragment>
                ))}
            </FormInputCardButtonTitle>
        </FormInputCardButton>
    );
}

export default FileUploadButton;
