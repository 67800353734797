import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { purifyHTML } from 'core';

type TileProps = {
    label: string;
    value: string | null | undefined;
    isHTML?: boolean;
};

function Tile({ label, value, isHTML = false }: TileProps) {
    return (
        <Stack>
            <Text as="b" fontSize="md">
                {label}
            </Text>
            {isHTML ? (
                <Box
                    dangerouslySetInnerHTML={{
                        __html: purifyHTML(value),
                    }}
                    sx={{
                        ul: {
                            marginInlineStart: 6,
                        },
                    }}
                />
            ) : (
                <Text fontSize="md" fontWeight={400}>
                    {value || '-'}
                </Text>
            )}
        </Stack>
    );
}

export default Tile;
