import {
    Box,
    Button,
    HStack,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import {
    defineMessages,
    FormattedMessage,
    MessageDescriptor,
} from 'react-intl';
import { coreSharedMessages } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import React from 'react';
import dayjs from 'dayjs';

const intlMessages = defineMessages({
    title: {
        id: 'cookies-consent.title',
        defaultMessage: 'Ce site utilise des cookies',
    },
    description: {
        id: 'cookies-consent.description.default',
        defaultMessage: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
        tristique tempus neque, vitae pretium erat venenatis quis. Ut
        augue lorem, varius non feugiat a, sagittis eu massa. Curabitur
        euismod leo ac lacus aliquam laoreet. Proin pellentesque sapien
        lobortis maximus dapibus. Phasellus ornare velit vel quam
        commodo, a egestas lectus commodo. Vestibulum eget {privacy_link} tempor
        quam tincidunt ultrices.
        `,
    },
    rejected: {
        id: 'cookies-consent.description.rejected',
        defaultMessage: "Vous avez rejeté l'utilisation de cookies.",
    },
});

type CookieConsentBannerProps = {
    onConsentChanged: (isConsentGiven: boolean) => void;
    ModalComponent: React.ComponentType<any>;
    modalProps?: any;
};

export default function CookieConsentBanner({
    onConsentChanged,
    ModalComponent,
    modalProps,
}: CookieConsentBannerProps) {
    const { isMobile } = useWindowBreakpoints();
    const [isConsentGiven, setIsConsentGiven] = React.useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    React.useEffect(() => {
        const consent = window.localStorage.getItem('cookie-consent');
        if (consent) {
            setIsConsentGiven(!!consent);
        }
    }, []);

    React.useEffect(() => {
        onConsentChanged(isConsentGiven);
    }, [onConsentChanged, isConsentGiven]);

    function onConsentGiven() {
        setIsConsentGiven(true);
        window.localStorage.setItem('cookie-consent', btoa(dayjs().toString()));
    }

    function onConsentDenied() {
        setIsConsentGiven(false);
        window.localStorage.removeItem('cookie-consent');
    }

    if (isConsentGiven) return null;

    return (
        <>
            {!isConsentGiven && <ConsentOverlay />}
            <Stack
                position="fixed"
                bottom="0"
                left="0"
                bg="primary.main"
                w="full"
                h="fit-content"
                py="6"
                px={isMobile ? 4 : 20}
                zIndex="1"
                spacing="4">
                <Stack spacing="4">
                    <Text color="white" fontWeight="bold" fontSize="md">
                        <FormattedMessage {...intlMessages.title} />
                    </Text>
                    <Text color="white" fontSize="xs">
                        <FormattedMessage
                            {...(isConsentGiven === false
                                ? intlMessages.rejected
                                : intlMessages.description)}
                            values={{
                                privacy_link: (
                                    <Text
                                        as="a"
                                        cursor="pointer"
                                        fontWeight="bold"
                                        textDecor="underline"
                                        onClick={onOpen}>
                                        <FormattedMessage
                                            {...coreSharedMessages[
                                                'privacy-policy'
                                            ]}
                                        />
                                    </Text>
                                ),
                            }}
                        />
                    </Text>
                </Stack>
                <CookieConsentBannerButtons
                    {...{ onConsentGiven, onConsentDenied, isConsentGiven }}
                />
            </Stack>

            {isOpen && (
                <ModalComponent {...{ isOpen, onClose }} {...modalProps} />
            )}
        </>
    );
}

function ConsentOverlay() {
    return (
        <Box
            position="absolute"
            top="0"
            left="0"
            w="full"
            h="100%"
            bg="rgba(0,0,0,0.6)"
        />
    );
}

type CookieConsentBannerButtonsProps = {
    onConsentGiven: () => void;
    onConsentDenied: () => void;
    isConsentGiven: boolean;
};

function CookieConsentBannerButtons({
    onConsentGiven,
    onConsentDenied,
    isConsentGiven,
}: CookieConsentBannerButtonsProps) {
    return (
        <HStack alignSelf="flex-end">
            <CookieConsentBannerButton
                onClick={onConsentGiven}
                text={coreSharedMessages.accept}
                isActive={isConsentGiven}
            />
            <CookieConsentBannerButton
                onClick={onConsentDenied}
                text={coreSharedMessages.reject}
                isActive={isConsentGiven === false}
            />
        </HStack>
    );
}

type CookieConsentBannerButtonProps = {
    text: MessageDescriptor;
    onClick: () => void;
    isActive: boolean;
};

function CookieConsentBannerButton({
    text,
    onClick,
    isActive,
}: CookieConsentBannerButtonProps) {
    return (
        <Button
            py="2"
            px="6"
            onClick={onClick}
            {...(isActive && {
                bg: 'gray.900',
                color: 'white',
                _hover: {
                    bg: 'gray.800',
                },
            })}>
            <Text fontSize="sm">
                <FormattedMessage {...text} />
            </Text>
        </Button>
    );
}
