import { coreSharedMessages } from 'core';
import { defineMessages } from 'react-intl';

export const pageTitles = {
    ...defineMessages({
        phoneContact: {
            id: 'page-title.phone-contact',
            defaultMessage: 'Nous contacter par téléphone',
        },
        acceo: {
            defaultMessage: 'Accueil sourds et malentendants',
            id: 'page-title.accessibility-service',
        },
        beneficiaries: {
            id: 'page-title.beneficiaries',
            defaultMessage: 'Bénéficiaires',
        },
        newRequest: {
            id: 'page-title.new-request',
            defaultMessage: 'Nouvelle demande',
        },
        profile: {
            id: 'page-title.profile',
            defaultMessage: 'Profil',
        },
        termsOfUse: {
            id: 'page-title.terms-of-use',
            defaultMessage: 'Mentions légales et CGU',
        },
        privacyPolicy: {
            id: 'page-title.privacy-policy',
            defaultMessage: 'Politique de confidentialité',
        },
        faq: {
            id: 'page-title.faq',
            defaultMessage: 'Comment peut-on vous aider ?',
        },
        statementsTitle: {
            id: 'page-title.statements',
            defaultMessage: 'E-relevés',
        },
        signature: {
            id: 'page-title.signature',
            defaultMessage: 'Signature',
        },
        refundsHistory: {
            id: 'page-title.refunds-history',
            defaultMessage: 'Historique',
        },
        refundsDetails: {
            id: 'page-title.refunds-details',
            defaultMessage: 'Détail',
        },
        tpCard: {
            id: 'page-title.tp-card',
            defaultMessage: 'Carte de tiers payant',
        },
        risks: {
            id: 'page-title.risks',
            defaultMessage: 'Risques couverts',
        },
        beneficiariesDesignation: {
            id: 'page-title.beneficiaries-designation',
            defaultMessage: 'Choix des bénéficiaires en cas de décès',
        },
        contact_mail: {
            id: 'page-title.mail-contact',
            defaultMessage: 'Envoyer un courrier',
        },
        messages: {
            id: 'page-title.messages',
            defaultMessage: 'Messages',
        },
        teleconsultation: {
            id: 'page-title.teleconsultation',
            defaultMessage: 'Consultation à distance',
        },
        'text-message-exchange': {
            id: 'page-title.teleconsultation',
            defaultMessage: 'Consultation à distance',
        },
        'immediate-exchange': {
            id: 'page-title.teleconsultation',
            defaultMessage: 'Consultation à distance',
        },
        appointment: {
            id: 'page-title.teleconsultation',
            defaultMessage: 'Consultation à distance',
        },
        servicesPreview: {
            id: 'page-title.services-preview',
            defaultMessage: 'Prévisualisation',
        },
        'home-consultation': {
            id: 'page-title.consultation-home',
            defaultMessage: 'Consultation à domicile',
        },
        primo: {
            id: 'page-title.primo',
            defaultMessage: 'Primo',
        },
        appointments: {
            id: 'page-title.healthcare-appointments',
            defaultMessage: 'Mes rendez-vous',
        },
        includedServices: {
            id: 'page-title.services',
            defaultMessage: 'Vos services',
        },
        healthServices: {
            id: 'page-title.healthcare-access',
            defaultMessage: 'Mes services',
        },
        prevention: {
            id: 'page-title.prevention',
            defaultMessage: 'Prévention',
        },
        complaint: {
            id: 'page-title.complaint',
            defaultMessage: 'Formuler une réclamation',
        },
        account: {
            id: 'page-title.account',
            defaultMessage: 'Mon compte',
        },
    }),
    documents: coreSharedMessages.documents,
    providentDocuments: coreSharedMessages.documents,
};
