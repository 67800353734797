import React from 'react';
import { AccordionButton, HStack, ScaleFade, Text } from '@chakra-ui/react';
import { PlusIcon, MinusIcon } from 'design-system/icons';
import {
    FormHelperIcon,
    SafeFormattedMessage,
    filterBadges,
    formMessages,
} from 'core';

type FilterGroupAccordionButtonProps = {
    isExpanded: boolean;
    name: string;
    help: string;
};

export default function FilterGroupAccordionButton({
    isExpanded,
    name,
    help,
}: FilterGroupAccordionButtonProps) {
    const iconProps = { rounded: 'full', p: 1, w: 7, h: 7 };

    return (
        <AccordionButton
            justifyContent="space-between"
            bg="grey.50"
            rounded="lg">
            <HStack>
                <Text
                    fontWeight={isExpanded ? 'semibold' : 'medium'}
                    color={isExpanded ? 'primary.main' : 'grey.700'}>
                    <SafeFormattedMessage
                        {...filterBadges[name]}
                        debugKey={`filter category: ${name}`}
                    />
                </Text>
                {help && isExpanded && (
                    <ScaleFade
                        initialScale={0.9}
                        in={isExpanded}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}>
                        <FormHelperIcon mb="0" iconColor="primary.main">
                            <SafeFormattedMessage
                                {...formMessages[help]}
                                debugKey={`helper text : ${help}`}
                            />
                        </FormHelperIcon>
                    </ScaleFade>
                )}
            </HStack>

            {!isExpanded ? (
                <PlusIcon bg="grey.800" color="white" {...iconProps} />
            ) : (
                <MinusIcon bg="white" color="grey.800" {...iconProps} />
            )}
        </AccordionButton>
    );
}
7;
