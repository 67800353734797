import { isArray } from 'lodash';
import { Violation, ViolationMessage } from '../types';

const VIOLATION_KEYS = [
    'value',
    'values',
    'confirmation',
    'iban',
    'bic',
    'bank',
    'owner',
    'streetNumber',
    'streetName',
    'additionalAddressOne',
    'additionalAddressTwo',
    'postalCode',
    'city',
    'country',
    'startDate',
    'endDate',
    'file',
    'subWidget',
];

export const flattenViolations = (violations: Violation[]) => {
    return violations.reduce(
        (violationList: ViolationMessage[], input: Violation) => {
            const errorKey = Object.keys(input).filter((key) =>
                VIOLATION_KEYS.includes(key.includes('file') ? 'file' : key)
            );
            return !errorKey.length
                ? violationList
                : violationList.concat(
                      isArray(input[errorKey[0]])
                          ? input[errorKey[0]][0]?.value ||
                                input[errorKey[0]][0]
                          : input[errorKey[0]]?.code || input[errorKey[0]]
                  );
        },
        []
    );
};
