type Props<Library> = {
    type?: keyof Library | any;
    props?: unknown;
};

export function librarian<Library>(library: Library) {
    if (!library) {
        throw new Error(`No library has been given to #librarian.`);
    }

    // eslint-disable-next-line react/display-name
    return ({ type, props }: Props<Library>) => {
        if (!type) {
            throw new Error(`Type is missing in #librarian.`);
        }
        if (!library.hasOwnProperty(type.toString())) {
            throw new Error(
                `The type does not exists in the library. Type given: ${type}`
            );
        }

        const Comp = library[type as string];
        // @ts-ignore
        return <Comp {...props} />;
    };
}
