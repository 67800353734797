import { Icon, IconProps } from '@chakra-ui/react';

export default function AdviceIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M7 10.5a1 1 0 1 0 2 0 1 1 0 0 0-2 0zM12 11.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM15 10.5a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M19 17h-1l-2.2 2.933a1 1 0 0 1-1.6 0L12 17H5a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3zm-6-2H5a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-2l-2 2.667L13 15z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
