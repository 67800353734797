import {
    CashIcon,
    HeartOutlineIcon,
    HomeIcon,
    JobIcon,
    ShieldIcon,
    UmbrellaIcon,
    CarIcon,
    AccidentIcon,
    CivilLiabilityIcon,
    ProfesionnalLegalProtectionIcon,
    PersonIcon,
} from 'design-system/icons';

export const contractTypeContent = {
    health: {
        bgColor: 'primary.main',
        collective: {
            icon: HeartOutlineIcon,
        },
        individual: {
            icon: HeartOutlineIcon,
        },
    },
    provident: {
        bgColor: 'secondary.main',
        collective: {
            icon: UmbrellaIcon,
        },
        individual: {
            icon: UmbrellaIcon,
        },
        territorial: {
            icon: UmbrellaIcon,
        },
    },
    'casuality.carInsurance': {
        bgColor: 'tertiary.main',
        icon: CarIcon,
    },
    'casuality.legalProtection': {
        bgColor: 'tertiary.main',
        icon: ShieldIcon,
    },
    'casuality.homeInsurance': {
        bgColor: 'tertiary.main',
        icon: HomeIcon,
    },
    'casuality.financialLost': {
        bgColor: 'tertiary.main',
        icon: CashIcon,
    },
    'casuality.accident': {
        bgColor: 'tertiary.main',
        icon: AccidentIcon,
    },
    'casuality.civilLiability': {
        bgColor: 'tertiary.main',
        icon: CivilLiabilityIcon,
    },
    'casuality.professionalMultiriskInsurance': {
        bgColor: 'tertiary.main',
        icon: JobIcon,
    },
    'casuality.professionalLegalProtection': {
        bgColor: 'tertiary.main',
        icon: ProfesionnalLegalProtectionIcon,
    },
    'casuality.realEstateSecurityPackageAlurLaw': {
        bgColor: 'tertiary.main',
        icon: HomeIcon,
    },
    honorary: {
        bgColor: 'tertiary.main',
        collective: {
            icon: PersonIcon,
        },
        individual: {
            icon: PersonIcon,
        },
        territorial: {
            icon: PersonIcon,
        },
    },
    'provident-educational-fund': {
        bgColor: 'secondary.main',
        collective: {
            icon: UmbrellaIcon,
        },
        individual: {
            icon: UmbrellaIcon,
        },
        territorial: {
            icon: UmbrellaIcon,
        },
    },
    'provident-guarantee': {
        bgColor: 'secondary.main',
        collective: {
            icon: UmbrellaIcon,
        },
        individual: {
            icon: UmbrellaIcon,
        },
        territorial: {
            icon: UmbrellaIcon,
        },
    },
};
