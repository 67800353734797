import { useIntl } from 'react-intl';

const useFormattedTime = () => {
    const { formatDate } = useIntl();

    const formatCustomDate = (date, options = {}) => {
        const formattedDate = formatDate(date, options);
        return formattedDate.replace(':', 'h');
    };

    return formatCustomDate;
};

export default useFormattedTime;
