import { Icon, IconProps } from '@chakra-ui/react';

export default function ExpandIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.0318 15.5259C21.5844 15.6048 21.9683 16.1168 21.8894 16.6693L21.3176 20.6715C21.2541 21.1161 20.9047 21.4655 20.46 21.529L16.4579 22.1008C15.9054 22.1797 15.3934 21.7958 15.3145 21.2432C15.2355 20.6906 15.6195 20.1787 16.1721 20.0997L18.1731 19.8139L13.8851 15.5259C13.4905 15.1312 13.4904 14.4913 13.8851 14.0966C14.2798 13.7019 14.9198 13.7019 15.3145 14.0966L19.6025 18.3845L19.8883 16.3835C19.9673 15.8309 20.4792 15.4469 21.0318 15.5259ZM2.76754 8.69633C2.21496 8.61739 1.831 8.10545 1.90994 7.55287L2.48167 3.55076C2.5452 3.10608 2.89459 2.75669 3.33927 2.69316L7.34138 2.12143C7.89396 2.04249 8.4059 2.42645 8.48484 2.97903C8.56378 3.5316 8.17982 4.04355 7.62725 4.12249L5.62619 4.40835L9.91417 8.69633C10.3089 9.09103 10.3089 9.73096 9.91417 10.1257C9.51947 10.5204 8.87954 10.5204 8.48484 10.1257L4.19686 5.83768L3.911 7.83874C3.83206 8.39131 3.32012 8.77527 2.76754 8.69633ZM15.3841 2.96985C15.4622 2.42312 15.9687 2.04322 16.5155 2.12132L20.4753 2.68701C20.9153 2.74986 21.261 3.09556 21.3238 3.53554L21.8895 7.49534C21.9676 8.04207 21.5877 8.5486 21.041 8.62671C20.4942 8.70481 19.9877 8.32491 19.9096 7.77818L19.6267 5.79828L15.3841 10.0409C14.9936 10.4314 14.3604 10.4314 13.9699 10.0409C13.5794 9.65039 13.5794 9.01723 13.9699 8.6267L18.2125 4.38406L16.2326 4.10122C15.6859 4.02312 15.306 3.51659 15.3841 2.96985ZM8.62677 21.041C8.54866 21.5877 8.04213 21.9676 7.4954 21.8895L3.5356 21.3238C3.09562 21.261 2.74992 20.9153 2.68707 20.4753L2.12138 16.5155C2.04328 15.9688 2.42318 15.4622 2.96991 15.3841C3.51665 15.306 4.02318 15.6859 4.10128 16.2327L4.38413 18.2126L8.62677 13.9699C9.01729 13.5794 9.65046 13.5794 10.041 13.9699C10.4315 14.3604 10.4315 14.9936 10.041 15.3841L5.79834 19.6268L7.77824 19.9096C8.32497 19.9877 8.70487 20.4942 8.62677 21.041Z"
                fill="currentColor"
            />
        </Icon>
    );
}
