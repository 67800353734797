import SatisfactionSadIcon from './Sad';
import SatisfactionNeutralIcon from './Neutral';
import SatisfactionHappyIcon from './Happy';

const icons = {
    score_1: <SatisfactionSadIcon />,
    score_2: <SatisfactionNeutralIcon />,
    score_3: <SatisfactionHappyIcon />,
};

export default icons;
