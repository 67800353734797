import { Validations, validationFormsMessages } from 'core';
import { FormatMessageFn } from '../useInputValidation';
import { regex } from './regex';

function hasPatternMatches(value: string, numberOf: number, regex: RegExp) {
    const noOfMatches = value.match(regex)?.length || 0;

    return noOfMatches >= numberOf;
}

function validateNumberOfRequiredNumbers(
    numberOf: Validations['numberOfRequiredNumbers'],
    formatMessage: FormatMessageFn
) {
    return (value: string) => {
        if (!numberOf || !value) return undefined;

        const isValid = hasPatternMatches(value, numberOf, regex.number);
        return !isValid
            ? formatMessage(validationFormsMessages.numberOfRequiredNumbers, {
                  numberOf,
              })
            : undefined;
    };
}

function validateNumberOfRequiredCapitalLetters(
    numberOf: Validations['numberOfRequiredCapitalLetters'],
    formatMessage: FormatMessageFn
) {
    return (value: string) => {
        if (!numberOf || !value) return undefined;

        const isValid = hasPatternMatches(value, numberOf, regex.capitalLetter);
        return !isValid
            ? formatMessage(
                  validationFormsMessages.numberOfRequiredCapitalLetters,
                  {
                      numberOf,
                  }
              )
            : undefined;
    };
}

function validateNumberOfRequiredSpecialCharacters(
    numberOf: Validations['numberOfRequiredSpecialCharacters'],
    formatMessage: FormatMessageFn
) {
    return (value: string) => {
        if (!numberOf || !value) return undefined;

        const isValid = hasPatternMatches(
            value,
            numberOf,
            regex.specialCharacters
        );
        return !isValid
            ? formatMessage(
                  validationFormsMessages.numberOfRequiredSpecialCharacters,
                  {
                      numberOf,
                  }
              )
            : undefined;
    };
}

export {
    hasPatternMatches,
    validateNumberOfRequiredNumbers,
    validateNumberOfRequiredCapitalLetters,
    validateNumberOfRequiredSpecialCharacters,
};
