import { defineMessages } from 'react-intl';

export const refundsComponentsMessages = defineMessages({
    setTo: {
        id: 'date.refund-date-paid-to',
        defaultMessage: `Remboursé le {paymentDate} à {type, select,
            insured {{gender, select, female {l'adhérente} other {l'adhérent}}}
            thirdParty {un tiers}
            other {{paymentBeneficiary}}
        }`,
    },
    refundedAt: {
        id: 'date.care-date',
        defaultMessage: `{nbCares, plural,
            one {Soins le}
            other {Soins les}
        } : {date}`,
    },
    refundDate: {
        id: 'date.refund-date',
        defaultMessage: `Remboursé le {date}`,
    },
});
