import { Icon, IconProps } from '@chakra-ui/react';

export default function UploadIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M12.615 2.211a1 1 0 0 0-1.23 0l-7 5.455a1 1 0 0 0 1.23 1.577L11 5.047V17a1 1 0 1 0 2 0V5.047l5.385 4.196a1 1 0 0 0 1.23-1.577l-7-5.455z"
                fill="currentColor"
            />
            <path
                d="M2 18v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-3a1 1 0 1 0-2 0v2H4v-2a1 1 0 1 0-2 0z"
                fill="currentColor"
            />
        </Icon>
    );
}
