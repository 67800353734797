import { useTheme } from '@chakra-ui/react';

function SatisfactionHappyIcon() {
    const theme = useTheme();
    const colors = theme.colors;

    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
            <path
                d="M89.9838 33.3747C93.0684 25.3045 93.5151 17.9773 90.9817 17.009C88.4482 16.0406 83.8938 21.7978 80.8093 29.8681C77.7247 37.9383 77.2779 45.2655 79.8114 46.2338C82.3449 47.2022 86.8992 41.445 89.9838 33.3747Z"
                fill={colors.green[900]}
            />
            <path
                d="M56.4782 86.5354C73.7574 86.5354 87.765 72.5278 87.765 55.2486C87.765 37.9694 73.7574 23.9619 56.4782 23.9619C39.199 23.9619 25.1914 37.9694 25.1914 55.2486C25.1914 72.5278 39.199 86.5354 56.4782 86.5354Z"
                fill={colors.green[900]}
            />
            <path
                d="M51.7255 79.8027H42.2207V96.8322H51.7255V79.8027Z"
                fill={colors.green[900]}
            />
            <path
                d="M70.7353 79.8027H61.2305V96.8322H70.7353V79.8027Z"
                fill={colors.green[900]}
            />
            <path
                d="M50.1414 100C54.5159 100 58.0621 98.6703 58.0621 97.0298C58.0621 95.3894 54.5159 94.0596 50.1414 94.0596C45.7669 94.0596 42.2207 95.3894 42.2207 97.0298C42.2207 98.6703 45.7669 100 50.1414 100Z"
                fill={colors.green[900]}
            />
            <path
                d="M69.1512 99.6046C73.5257 99.6046 77.0719 98.2748 77.0719 96.6343C77.0719 94.9939 73.5257 93.6641 69.1512 93.6641C64.7767 93.6641 61.2305 94.9939 61.2305 96.6343C61.2305 98.2748 64.7767 99.6046 69.1512 99.6046Z"
                fill={colors.green[900]}
            />
            <path
                d="M57.2701 58.0206C63.1756 58.0206 67.963 53.2332 67.963 47.3277C67.963 41.4221 63.1756 36.6348 57.2701 36.6348C51.3645 36.6348 46.5771 41.4221 46.5771 47.3277C46.5771 53.2332 51.3645 58.0206 57.2701 58.0206Z"
                fill="white"
            />
            <path
                d="M57.2704 50.8914C59.2389 50.8914 60.8347 49.2956 60.8347 47.3271C60.8347 45.3585 59.2389 43.7627 57.2704 43.7627C55.3019 43.7627 53.7061 45.3585 53.7061 47.3271C53.7061 49.2956 55.3019 50.8914 57.2704 50.8914Z"
                fill={colors.green[900]}
            />
            <path
                d="M26.129 26.1521C23.6028 14.8384 31.6782 3.40636 44.1661 0.617945C56.6539 -2.17047 68.8252 4.74067 71.3515 16.0543C73.8777 27.368 65.6016 31.5407 53.1138 34.3291C40.6259 37.1176 28.6553 37.4659 26.129 26.1521Z"
                fill={colors.green[700]}
            />
            <path
                d="M35.373 47.434C36.5353 44.9834 31.1493 39.9955 23.3432 36.2932C15.5371 32.5909 8.26675 31.5762 7.10448 34.0267C5.94221 36.4773 11.3281 41.4652 19.1343 45.1675C26.9404 48.8698 34.2107 49.8845 35.373 47.434Z"
                fill={colors.green[900]}
            />
            <path
                d="M41.5 63.0003C41.5 66.0625 46.5 75.6441 57.7437 75.6441C71 75.6441 75 66.0623 75 63.0002C75 59.9381 66.4926 65.5005 57.7437 65.5005C48.9947 65.5005 41.5 59.9382 41.5 63.0003Z"
                fill="white"
            />
        </svg>
    );
}

export default SatisfactionHappyIcon;
