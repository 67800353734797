import { useMediaQuery } from '@chakra-ui/react';

export type UseWindowBreakpointsReturnType = {
    isMobile: boolean;
    isTablet?: boolean;
    isSmallOrMediumDevice?: boolean;
    isDesktop?: boolean;
    isSmallDevice?: boolean;
    isExtraLargeDevice?: boolean;
    isExtraLargeAndMoreDevice?: boolean;
    breakpoint?:
        | 'mobile.small'
        | 'mobile.large'
        | 'tablet.small'
        | 'tablet.large'
        | 'desktop.small'
        | 'desktop.large'
        | 'widescreen';
};
export function useWindowBreakpoints(): UseWindowBreakpointsReturnType {
    const [
        isMobileSmall,
        isMobileLarge,
        isTabletSmall,
        isTabletLarge,
        isDesktopSmall,
        isDesktopLarge,
        isDesktopExtraLarge,
        isDesktopExtraLargeAndMore,
    ] = useMediaQuery(
        [
            `(max-width: 370px)`,
            `(min-width: 370px) and (max-width: 576px)`,
            `(min-width: 576px) and (max-width: 768px)`,
            `(min-width: 768px) and (max-width: 1024px)`,
            `(min-width: 1024px) and (max-width: 1280px)`,
            `(min-width: 1280px) and (max-width: 1700px)`,
            `(min-width: 1700px) and (max-width: 1920px)`,
            `(min-width: 1920px)`,
        ],
        {
            ssr: false,
        }
    );

    const breakpoint = () => {
        if (isMobileSmall) return 'mobile.small';
        if (isMobileLarge) return 'mobile.large';
        if (isTabletSmall) return 'tablet.small';
        if (isTabletLarge) return 'tablet.large';
        if (isDesktopSmall) return 'desktop.small';
        if (isDesktopLarge) return 'desktop.large';
        return 'widescreen';
    };

    return {
        isMobile: isMobileSmall || isMobileLarge,
        isTablet: isTabletSmall || isTabletLarge,
        isSmallOrMediumDevice:
            isMobileSmall ||
            isMobileLarge ||
            isTabletSmall ||
            isTabletLarge ||
            isDesktopSmall,
        isDesktop:
            isDesktopSmall || isDesktopLarge || breakpoint() === 'widescreen',
        isSmallDevice:
            isMobileSmall || isMobileLarge || isTabletSmall || isTabletLarge,
        isExtraLargeDevice: isDesktopExtraLarge,
        isExtraLargeAndMoreDevice: isDesktopExtraLargeAndMore,
        breakpoint: breakpoint(),
    };
}
