import { Text, Center } from '@chakra-ui/react';
import { coreSharedMessages } from 'core';
import SentConfirmationModalIcon from 'design-system/icons/components/SentConfirmationModalIcon';
import {
    CoreModal,
    CoreModalContent,
    CoreModalHeader,
    CoreModalBody,
    CoreModalFooter,
} from 'design-system/components';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type SentConfirmationModalProps = {
    isOpen: boolean;
    onClose: () => void;
    Message: React.ComponentType;
    Actions: React.ComponentType;
    showIllustration?: boolean;
    title?: string | React.ReactNode;
};

export default function SentConfirmationModal({
    isOpen,
    onClose,
    Message,
    Actions,
    showIllustration = true,
    title = <FormattedMessage {...coreSharedMessages.confirmation} />,
}: SentConfirmationModalProps) {
    return (
        <CoreModal isOpen={isOpen} onClose={onClose}>
            <CoreModalContent maxH={'none'}>
                <CoreModalHeader>
                    {title}
                </CoreModalHeader>
                <CoreModalBody>
                    <Text mt={4} textAlign={'center'}>
                        {Message && <Message />}
                    </Text>
                    {showIllustration &&<Center mt={4}>
                        <SentConfirmationModalIcon />
                    </Center>}
                </CoreModalBody>
                <CoreModalFooter justifyContent={'center'} mt={4} mb={4}>
                    {Actions && <Actions />}
                </CoreModalFooter>
            </CoreModalContent>
        </CoreModal>
    );
}
