import { Stack, VStack, Divider } from '@chakra-ui/react';
import React, { ComponentType } from 'react';
import {
    FormattedMessage,
    MessageDescriptor,
    defineMessages,
    useIntl,
} from 'react-intl';
import { QueryStatus } from '@tanstack/react-query';
import { useWindowBreakpoints } from 'design-system/hooks';
import {
    APIError,
    defaultLayoutBoundaryErrorMessages,
    formats,
    GlobalLayoutType,
    HeaderBoxType,
    HeaderSecondaryType,
    LayoutBoundaryType,
    PEWorkflowTypesEnum,
    RelatedActionsCategories,
    useBackBreadcrumbWithOnClick,
    WorkflowTypesEnum,
} from '../../lib';
import {
    ClauseType,
    ContractType,
    signatureMessages,
} from '../../lib/signature';
import StandardClause from './StandardClause';
import PickBeneficiary from './PickBeneficiary';

const intlMessages = {
    [WorkflowTypesEnum.PA]: defineMessages({
        standardDescription: {
            id: 'provident.beneficiaries-designation.standard-description',
            defaultMessage:
                'Par défaut, vos bénéficiaires sont désignés selon les règles de la clause standard du contrat.',
        },
        specificDescription: {
            id: 'provident.beneficiaries-designation.specific-description',
            defaultMessage:
                '<p>En cas de décès, le capital sera versé selon la désignation de bénéficiaires que vous avez réalisée{date, select, null {} other { le {date}}}.</p><p>Pour des raisons de confidentialité, le contenu de votre clause ne peut être communiqué.</p>',
            description: "Affiche la date de commencement de l'assurance",
        },
    }),
    [WorkflowTypesEnum.PE]: defineMessages({
        description: {
            id: 'workflow-signature.workflow-beneficiaries-designation.description',
            defaultMessage:
                '<p><b>Par défaut, vos bénéficiaires sont désignés selon les règles de la clause standard spécifiée dans votre contrat {contractNumber}.</b></p><p>La désignation de bénéficiaires est une clause qui vous permet de désigner, dans le cadre de votre contrat prévoyance, la ou les personne(s) qui percevront le capital garanti si vous décédez.</p>',
        },
    }),
};

type GlobalLayoutProps = GlobalLayoutType & {
    footerSticky?: boolean;
    disableFooter?: boolean;
    type?: PEWorkflowTypesEnum;
};

type LayoutBoundaryProps = LayoutBoundaryType &
    React.PropsWithChildren<{
        EmptyComponent?: React.ComponentType<{
            message?: MessageDescriptor;
        }>;
    }>;

type HeaderBoxProps = HeaderBoxType;

type HeaderSecondaryProps = HeaderSecondaryType & {
    breadcrumbsIconColor?: string;
} & React.PropsWithChildren<{
        relatedActions?: RelatedActionsCategories;
    }>;

type SelectBeneficiaryProps = {
    workflow: WorkflowTypesEnum;
    clause: ClauseType | null;
    contract: ContractType | null;
    status: QueryStatus;
    error: APIError;
    GlobalLayout: ComponentType<GlobalLayoutProps>;
    LayoutBoundary: ComponentType<LayoutBoundaryProps>;
    HeaderBox: ComponentType<HeaderBoxProps>;
    HeaderSecondary: ComponentType<HeaderSecondaryProps>;
    hasDocument?: boolean;
    openDocumentModal?: () => void;
    GuideFile?: ComponentType;
};

export default function SelectBeneficiary({
    workflow,
    clause,
    contract,
    status,
    error,
    GlobalLayout,
    LayoutBoundary,
    HeaderBox,
    HeaderSecondary,
    hasDocument,
    openDocumentModal,
    GuideFile = null,
}: SelectBeneficiaryProps) {
    const backBreadcrumbWithOnClick = useBackBreadcrumbWithOnClick();
    const { formatMessage, formatDate } = useIntl();
    const date = clause?.signatureDate;

    const { isMobile } = useWindowBreakpoints();

    const _renderDescription = () => {
        return workflow === WorkflowTypesEnum.PA ? (
            clause?.type === 'standard' ? (
                <FormattedMessage
                    {...intlMessages[workflow].standardDescription}
                />
            ) : (
                <FormattedMessage
                    {...intlMessages.pa.specificDescription}
                    values={{
                        date: date
                            ? formatDate(date, {
                                  month: formats.date.month,
                                  day: formats.date.day,
                                  year: formats.date.day,
                              })
                            : null,
                    }}
                />
            )
        ) : (
            <FormattedMessage
                {...intlMessages[workflow].description}
                values={{
                    contractNumber: contract?.contractNumber || '',
                }}
            />
        );
    };

    return (
        <GlobalLayout
            title={formatMessage(signatureMessages.beneficiariesDesignation)}
            py={isMobile ? '12' : '0'}
            margin="auto"
            minHeight={isMobile && '100vh'}
            display="flex"
            flexDirection="column"
            {...(workflow === WorkflowTypesEnum.PE
                ? { type: PEWorkflowTypesEnum.BD }
                : {})}>
            <HeaderBox pt={isMobile ? '0' : '8'}>
                <HeaderSecondary
                    breadcrumbs={[backBreadcrumbWithOnClick]}
                    mb={isMobile ? '0' : '3'}>
                    <FormattedMessage
                        {...signatureMessages.beneficiariesDesignation}
                    />
                </HeaderSecondary>
            </HeaderBox>
            <LayoutBoundary
                status={status}
                messages={defaultLayoutBoundaryErrorMessages[error?.code]}
                pt="0"
                px="0"
                pb="8"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                flex="auto">
                {isMobile && <Divider />}
                <VStack mt="4" alignItems="flex-start" spacing="6" mx="4">
                    <Stack spacing="0" gap="2">
                        {_renderDescription()}
                    </Stack>
                    {GuideFile && <GuideFile />}
                    {workflow === WorkflowTypesEnum.PA ? (
                        clause?.type === 'standard' ? (
                            <Stack spacing="6">
                                <StandardClause
                                    workflow={workflow}
                                    type={clause?.type}
                                    hasDocument={hasDocument}
                                />
                                <PickBeneficiary
                                    workflow={workflow}
                                    type={'standard'}
                                />
                            </Stack>
                        ) : (
                            <Stack spacing="6">
                                <PickBeneficiary
                                    workflow={workflow}
                                    type={'specific'}
                                />
                                <StandardClause
                                    workflow={workflow}
                                    type={
                                        clause?.type ? clause?.type : 'specific'
                                    }
                                    hasDocument={hasDocument}
                                />
                            </Stack>
                        )
                    ) : (
                        <>
                            <StandardClause
                                workflow={workflow}
                                contract={contract}
                                openDocumentModal={openDocumentModal}
                            />
                            <PickBeneficiary workflow={workflow} />
                        </>
                    )}
                </VStack>
            </LayoutBoundary>
        </GlobalLayout>
    );
}
