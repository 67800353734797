import React from 'react';
import { uniqueId } from 'lodash';
import { FormattedMessage, defineMessage } from 'react-intl';
import { Table } from '@tanstack/react-table';
import { Box, Button, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { NB_RESULTS_PER_PAGE } from '.';
import { ChevronLeftIcon, ChevronRightIcon } from 'design-system/icons';

const intlMessages = {
    nbOfResults: defineMessage({
        id: 'components.results-out-of-entries',
        defaultMessage: `{nbResults, plural, =0 {# résultat affiché} one {# résultat affiché} other {# résultats affichés}} ({firstResult} à {lastPageResult} sur {totalResults, plural, =0 {# entrée} one {# entrée} other {# entrées}})`,
    }),
};

type PaginationProps<T> = {
    table: Table<T>;
    isLoading: boolean;
};

export function Pagination<T>({ table, isLoading }: PaginationProps<T>) {
    const currentPage = table.getState().pagination.pageIndex || 1;

    const _renderPaginationArrow = (isFirst): JSX.Element => {
        const isDisabled = isFirst
            ? !table.getCanPreviousPage()
            : !table.getCanNextPage();

        const isFirstOrLast =
            (isFirst && currentPage === 1) ||
            (!isFirst && currentPage === table.getPageCount());

        return (
            <Button
                onClick={isFirst ? table.previousPage : table.nextPage}
                _focus={{
                    border: '3px solid',
                    borderColor: 'grey.200',
                }}
                bg="transparent"
                color="texts.medium"
                alignItems="center"
                justifyContent="center"
                borderRadius="sm"
                minW="6"
                h="6"
                py="2"
                px="1"
                _hover={
                    !isDisabled && {
                        bg: 'grey.200',
                    }
                }
                isDisabled={isFirstOrLast}
                _disabled={{
                    color: 'texts.light',
                    cursor: 'not-allowed',
                }}>
                {isFirst ? (
                    <ChevronLeftIcon data-testid="previous-page" />
                ) : (
                    <ChevronRightIcon data-testid="next-page" />
                )}
            </Button>
        );
    };

    const _renderPaginationButton = (content: number | string): JSX.Element => {
        return (
            <Button
                key={uniqueId()}
                onClick={
                    content !== '...'
                        ? () => table.setPageIndex(content as number)
                        : () => null
                }
                bg={currentPage === content ? 'primary.main' : 'transparent'}
                minW="6"
                h="6"
                py="2"
                px="1"
                paddingInlineEnd="0"
                paddingInlineStart="0"
                borderRadius="sm"
                alignItems="center"
                justifyContent="center"
                _hover={content !== '...' && { bg: 'grey.300' }}
                isDisabled={content === '...'}
                _disabled={{
                    borderColor: 'grey.200',
                    cursor: 'not-allowed',
                }}>
                <Text
                    fontSize="sm"
                    fontWeight="normal"
                    color={currentPage === content ? 'white' : 'black'}>
                    {content}
                </Text>
            </Button>
        );
    };

    return (
        <VStack w={'100%'} px="6">
            <Box alignSelf={'start'}>
                <Skeleton isLoaded={!isLoading}>
                    <Text color="texts.medium" fontSize="sm">
                        <FormattedMessage
                            {...intlMessages.nbOfResults}
                            values={{
                                firstResult:
                                    (currentPage - 1 || 0) *
                                        NB_RESULTS_PER_PAGE +
                                    1,
                                lastPageResult: Math.min(
                                    (currentPage - 1 || 0) *
                                        NB_RESULTS_PER_PAGE +
                                        NB_RESULTS_PER_PAGE,
                                    table.getRowCount()
                                ),
                                nbResults: table.getState().pagination.pageSize,
                                totalResults: table.getRowCount(),
                            }}
                        />
                    </Text>
                </Skeleton>
            </Box>
            <Skeleton isLoaded={!isLoading}>
                <HStack spacing="0" gap="1" alignSelf="center">
                    {_renderPaginationArrow(true)}

                    {currentPage - 2 > 1 && (
                        <>
                            {_renderPaginationButton(1)}
                            {currentPage - 3 > 1 &&
                                _renderPaginationButton('...')}
                        </>
                    )}

                    {[
                        currentPage - 2,
                        currentPage - 1,
                        currentPage,
                        currentPage + 1,
                        currentPage + 2,
                    ].map((pageNb) =>
                        pageNb >= 1 && pageNb <= table.getPageCount()
                            ? _renderPaginationButton(pageNb)
                            : null
                    )}

                    {currentPage + 2 < table.getPageCount() && (
                        <>
                            {currentPage + 3 < table.getPageCount() &&
                                _renderPaginationButton('...')}
                            {_renderPaginationButton(table.getPageCount())}
                        </>
                    )}
                    {_renderPaginationArrow(false)}
                </HStack>
            </Skeleton>
        </VStack>
    );
}
