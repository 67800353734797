import { Text, Stack, HStack, Box } from '@chakra-ui/react';
import { SafeFormattedMessage, formMessages } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';

type TileColorProps = {
    color: string | null;
    label: string;
};

export default function TileColor({ color, label }: TileColorProps) {
    const { isMobile } = useWindowBreakpoints();
    return (
        <Stack>
            <Text as="b" fontSize="md">
                {label}
            </Text>
            <HStack
                border={color && '1px solid'}
                borderColor="strokes.medium"
                borderRadius="md"
                spacing="3"
                fontSize="md"
                fontWeight={400}
                gap="2"
                p="2"
                w={isMobile ? 'full' : 'sm'}>
                {color && <Box borderRadius="md" w="7" h="7" bg={color} />}
                {color ? (
                    formMessages[color] ? (
                        <SafeFormattedMessage
                            {...formMessages[color]}
                            debugKey={color}
                        />
                    ) : (
                        <Text>{color}</Text>
                    )
                ) : (
                    <Text>-</Text>
                )}
            </HStack>
        </Stack>
    );
}
