import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { contextError } from 'core/utils';
import { ThemeContext } from 'themes';
import { UserInfos, User } from 'lib/user';
import { capitalizeHyphenatedName, useUser, LanguageContext } from 'core';
import { useEligibility } from 'lib/shared';
import { NOT_AUTH_ROUTES } from './consts';
import { AdherentUser } from 'core/lib/auth';
import { QueryStatus } from '@tanstack/react-query';

type UserInfosContext =
    | (UserInfos & {
          user: User & {
              fullName: string;
          };
      })
    | null
    | undefined;

type InitialsProps = {
    children: React.ReactNode;
    defaultUserInfos?: UserInfosContext;
};

type UserContext = {
    userInfos: UserInfosContext;
    isLoading: boolean;
    isOnboardingCompleted: boolean;
    setOnboardingCompleted: (value: boolean) => void;
    clearUserInfos: () => void;
};

const UserContext = React.createContext<UserContext | null>(null);

function useUserContext() {
    const context = React.useContext(UserContext);
    if (!context) {
        contextError('useUserInitialsContext', 'UserInitialsProvider');
    }
    return context;
}

function capitalizeUserName(userInfo: AdherentUser.UserInfos) {
    return {
        ...userInfo,
        user: {
            ...userInfo.user,
            firstName: capitalizeHyphenatedName(
                userInfo?.user?.firstName ?? ''
            ),
            lastName: capitalizeHyphenatedName(userInfo?.user?.lastName ?? ''),
            fullName: capitalizeHyphenatedName(
                `${userInfo?.user?.firstName} ${userInfo?.user?.lastName}`
            ),
        },
    };
}

function UserProvider({ children, defaultUserInfos = null }: InitialsProps) {
    const router = useRouter();
    const { setVariant } = React.useContext(ThemeContext);
    const [userInfos, setUserInfos] =
        React.useState<UserInfosContext>(defaultUserInfos);
    const { setLocale } = React.useContext(LanguageContext);
    const eligibility = useEligibility();
    const { data: userData, status: userStatus } = useUser(
        'adherent',
        !NOT_AUTH_ROUTES.includes(router?.pathname)
    ) as { data: AdherentUser.UserInfos; status: QueryStatus };

    const [isOnboardingCompleted, setOnboardingCompleted] = useState(false);

    const clearUserInfos = () => setUserInfos(null);

    // Gestion de tous les effets secondaires lié à l'appel api /user/account
    React.useEffect(() => {
        if (!userData || !setLocale) return;

        // Remplace la langue du site par rapport aux préférences utilisateurs
        if (userData?.user?.language) {
            setLocale(userData.user.language.replace('_', '-'));
        }

        const modifiedUserInfo = capitalizeUserName(
            userData as AdherentUser.UserInfos
        );
        setUserInfos(modifiedUserInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, setLocale]);

    React.useEffect(() => {
        // Change le thème du site pour un client, après la connexion
        if (eligibility?.data?.subThemeToDisplay)
            setVariant(eligibility?.data?.subThemeToDisplay.toLowerCase());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eligibility]);

    return (
        <UserContext.Provider
            value={{
                userInfos,
                isLoading: userStatus === 'pending',
                isOnboardingCompleted,
                setOnboardingCompleted,
                clearUserInfos,
            }}>
            {children}
        </UserContext.Provider>
    );
}

export type { UserInfosContext, InitialsProps };
export { UserContext, UserProvider, useUserContext };
