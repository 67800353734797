import { Icon, IconProps } from '@chakra-ui/react';

export default function ArrowDownRightIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M14.078 15.492 7.05 8.464A1 1 0 1 1 8.464 7.05l7.028 7.028.54-4.545a1 1 0 0 1 1.986.235l-.782 6.592a1 1 0 0 1-.876.876l-6.592.782a1 1 0 1 1-.236-1.986l4.546-.54z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
