import React, { ReactNode } from 'react';
import {
    Alert as ChakraAlert,
    AlertProps as ChakraAlertProps,
    AlertDescription,
    AlertTitle,
    Box,
    Button,
    ButtonProps,
    CloseButton,
    HStack,
    Stack,
} from '@chakra-ui/react';
import * as icons from 'design-system/icons';

export type AlertColors =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'white'
    | 'accent'
    | 'info'
    | 'error'
    | 'warning'
    | 'success';

type AlertProps = Partial<ChakraAlertProps> & {
    displayedTitle?: ReactNode;
    color: AlertColors;
    description?: ReactNode;
    isClosable?: boolean;
    onClose?: () => void;
    icon?: string;
    showIcon?: boolean;
    primaryButtonProps?: ButtonProps;
    primaryButtonText?: string;
    secondaryButtonProps?: ButtonProps;
    secondaryButtonText?: string;
    redirectLinkProps?: ButtonProps;
    redirectLinkText?: string;
};

export const alertColorConfigs = {
    primary: {
        blockBg: 'primary.50',
        buttonsColorScheme: 'primary',
        blockHoverBg: 'primary.100',
        blockBorderColor: 'primary.300',
        iconBg: 'primary.main',
    },
    secondary: {
        blockBg: 'secondary.50',
        buttonsColorScheme: 'secondary',
        blockHoverBg: 'secondary.100',
        blockBorderColor: 'secondary.300',
        iconBg: 'secondary.main',
    },
    tertiary: {
        blockBg: 'tertiary.50',
        buttonsColorScheme: 'tertiary',
        blockHoverBg: 'tertiary.100',
        blockBorderColor: 'tertiary.300',
        iconBg: 'tertiary.main',
    },
    white: {
        blockBg: 'grey.50',
        buttonsColorScheme: 'gray',
        blockHoverBg: 'grey.100',
        blockBorderColor: 'grey.300',
        iconBg: 'grey.800',
    },
    accent: {
        blockBg: 'accent.ultralight',
        buttonsColorScheme: 'teal',
        blockHoverBg: 'accent.light',
        blockBorderColor: 'accent.main',
        iconBg: 'accent.main',
    },
    info: {
        blockBg: 'blue.50',
        buttonsColorScheme: 'blue',
        blockHoverBg: 'blue.100',
        blockBorderColor: 'blue.400',
        iconBg: 'blue.400',
    },
    error: {
        blockBg: 'red.50',
        buttonsColorScheme: 'red',
        blockHoverBg: 'red.100',
        blockBorderColor: 'red.400',
        iconBg: 'red.400',
    },
    warning: {
        blockBg: 'orange.50',
        buttonsColorScheme: 'orange',
        blockHoverBg: 'orange.100',
        blockBorderColor: 'orange.500',
        iconBg: 'orange.500',
    },
    success: {
        blockBg: 'green.50',
        buttonsColorScheme: 'green',
        blockHoverBg: 'treatmentStatus.treated',
        blockBorderColor: 'green.500',
        iconBg: 'treatmentStatus.treated',
    },
};

export default function Alert({
    displayedTitle,
    color = 'primary',
    description = null,
    isClosable = false,
    onClose,
    icon,
    showIcon = true,
    primaryButtonProps,
    primaryButtonText,
    secondaryButtonProps,
    secondaryButtonText,
    redirectLinkProps,
    redirectLinkText,
    ...props
}: AlertProps) {
    const Icon =
        !!icon && icons[icon?.charAt(0)?.toUpperCase() + icon?.slice(1)];
    const hasSecondPart =
        secondaryButtonText || primaryButtonText || redirectLinkText;

    return (
        <ChakraAlert
            alignItems="flex-start"
            bg={alertColorConfigs[color].blockBg}
            color="black"
            justifyContent="space-between"
            my="4"
            data-testid="alert-component"
            {...props}>
            <HStack alignItems="flex-start" spacing="0">
                {showIcon && (
                    <Box mr="3" data-testid="alert-icon">
                        {(!!Icon && (
                            <Icon
                                w={6}
                                h={6}
                                color={alertColorConfigs[color].iconBg}
                            />
                        )) || (
                            <icons.AttentionIcon
                                w={6}
                                h={6}
                                color={alertColorConfigs[color].iconBg}
                            />
                        )}
                    </Box>
                )}
                <Stack gap="4">
                    <Stack
                        alignSelf={description ? 'unset' : 'self-end'}
                        flexGrow={1}>
                        {displayedTitle && (
                            <AlertTitle>{displayedTitle}</AlertTitle>
                        )}
                        {displayedTitle && description && (
                            <Box
                                h="1"
                                w="6"
                                borderRadius="lg"
                                bg={alertColorConfigs[color].iconBg}
                                role="separator"
                                data-testid="alert-separator"
                            />
                        )}
                        {description && (
                            <AlertDescription>{description} </AlertDescription>
                        )}
                    </Stack>
                    {hasSecondPart && (
                        <Stack alignItems="flex-start">
                            {secondaryButtonText && (
                                <Button
                                    {...secondaryButtonProps}
                                    w="fit-content"
                                    colorScheme={
                                        alertColorConfigs[color]
                                            .buttonsColorScheme
                                    }
                                    variant="outline"
                                    size="sm">
                                    {secondaryButtonText}
                                </Button>
                            )}
                            {primaryButtonText && (
                                <Button
                                    {...primaryButtonProps}
                                    w="fit-content"
                                    colorScheme={
                                        alertColorConfigs[color]
                                            .buttonsColorScheme
                                    }
                                    variant="solid"
                                    size="sm">
                                    {primaryButtonText}
                                </Button>
                            )}
                            {redirectLinkText && (
                                <Button
                                    {...redirectLinkProps}
                                    variant="link"
                                    colorScheme={
                                        alertColorConfigs[color]
                                            .buttonsColorScheme
                                    }
                                    rightIcon={<icons.ExternalLinkIcon />}
                                    fontWeight="normal">
                                    {redirectLinkText}
                                </Button>
                            )}
                        </Stack>
                    )}
                </Stack>
            </HStack>
            {isClosable && (
                <CloseButton
                    alignSelf="flex-start"
                    position="relative"
                    right={-1}
                    top={-1}
                    onClick={onClose}
                    color={alertColorConfigs[color].iconBg}
                />
            )}
        </ChakraAlert>
    );
}
