import { useState, useCallback, KeyboardEvent, useEffect } from 'react';

type RovingOrientations = 'vertical' | 'horizontal';

type RovingObject = {
    /**
     * The index of the current focused element
     */
    currentFocus: number;

    /**
     * Dispatcher for manually set the current focused element
     */
    setCurrentFocus: (idx: number) => void;

    /**
     * Function to apply on the parent of the list, to track the keyboard keys
     */
    handleKeyDown: (evt: KeyboardEvent) => void;
    hasFocusMoved: boolean;
};

const ORIENTATIONS = {
    vertical: {
        NEXT: 'ArrowDown',
        PREV: 'ArrowUp',
    },
    horizontal: {
        NEXT: 'ArrowRight',
        PREV: 'ArrowLeft',
    },
};

/**
 * Traps the focus within a list an add keyboard support
 * @param group An array of indexes for each focusable element
 * @param orientation
 * @param defaultFocusId defaults to: `0`
 */
function useRoving(
    group: number[],
    orientation: RovingOrientations,
    defaultFocusId = 0
): RovingObject {
    const [currentFocus, setCurrentFocus] = useState(defaultFocusId);
    // Pointer is useful for keeping trace of the last element selected when
    // using a keyboard. We should always move the pointer, which will trigger
    // the setCurrentFocus().
    const [pointer, setPointer] = useState(defaultFocusId);
    const [hasFocusMoved, setHasFocusMoved] = useState(false);
    const movePointer = useCallback(
        (idx: number) => {
            const indexInGroup = group.indexOf(idx);
            if (indexInGroup !== -1) setPointer(indexInGroup);
        },
        [group]
    );
    const handleKeyDown = useCallback(
        (evt: KeyboardEvent) => {
            const MIN = 0;
            const MAX = group.length - 1;
            setHasFocusMoved(true);

            switch (evt.key) {
                case ORIENTATIONS[orientation].NEXT:
                    evt.preventDefault();
                    setPointer((pointer) =>
                        pointer === MAX ? MIN : pointer + 1
                    );
                    break;
                case ORIENTATIONS[orientation].PREV:
                    evt.preventDefault();
                    setPointer((pointer) =>
                        pointer === MIN ? MAX : pointer - 1
                    );
                    break;
                case 'Home':
                    evt.preventDefault();
                    setPointer(MIN);
                    break;
                case 'End':
                    evt.preventDefault();
                    setPointer(MAX);
                    break;
            }
        },
        [group, orientation]
    );

    useEffect(() => {
        setCurrentFocus(group[pointer]);
    }, [group, pointer]);

    return {
        currentFocus,
        setCurrentFocus: movePointer,
        handleKeyDown,
        hasFocusMoved,
    };
}

export default useRoving;
