import { Icon, useTheme } from '@chakra-ui/react';

export default function PreventionBeneficiaryIllustration() {
    const { colors } = useTheme();
    return (
        <Icon
            width="75"
            height="75"
            viewBox="0 0 75 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path
                    opacity="0.5"
                    d="M19.4979 58.2552C29.9747 58.2552 38.4676 49.7621 38.4676 39.2853C38.4676 28.8087 29.9747 20.3156 19.4979 20.3156C9.02123 20.3156 0.528198 28.8087 0.528198 39.2853C0.528198 49.7621 9.02123 58.2552 19.4979 58.2552Z"
                    fill="url(#paint0_linear_9608_804)"
                />
            </g>
            <path
                d="M19.5356 57.7316C29.7908 57.7316 38.1043 49.4181 38.1043 39.1629C38.1043 28.9076 29.7908 20.5941 19.5356 20.5941C9.28042 20.5941 0.966919 28.9076 0.966919 39.1629C0.966919 49.4181 9.28042 57.7316 19.5356 57.7316Z"
                fill="#FAFAFA"
            />
            <path
                d="M29.7512 40.9935C29.7512 47.171 25.3137 50.2108 19.5503 50.2108C13.7868 50.2108 8.8844 47.171 8.8844 40.9935C8.8844 34.8159 13.556 29.8088 19.3178 29.8088C25.0796 29.8088 29.7512 34.8111 29.7512 40.9935Z"
                fill={colors.black}
            />
            <path
                d="M19.5503 57.8062C23.1794 57.8126 26.7301 56.7503 29.7594 54.7518C29.0753 51.0133 26.4711 50.7989 26.4711 50.7989H12.1123C12.1123 50.7989 9.65703 51.0001 8.8877 54.4409C12.0092 56.6366 15.7339 57.8122 19.5503 57.8062Z"
                fill={colors.primary['main']}
            />
            <path
                opacity="0.1"
                d="M16.462 46.234H20.8119V49.7137C20.8124 50 20.7564 50.2836 20.647 50.5481C20.5377 50.8127 20.3771 51.0531 20.1747 51.2554C19.9722 51.4578 19.7317 51.6182 19.4671 51.7274C19.2025 51.8366 18.9189 51.8925 18.6326 51.8918C18.0559 51.8918 17.5029 51.6627 17.0951 51.255C16.6873 50.8472 16.4583 50.2941 16.4583 49.7175V46.234H16.462Z"
                fill={colors.black}
            />
            <path
                d="M17.0098 46.0255H21.6569C21.8024 46.0255 21.9418 46.0832 22.0447 46.1861C22.1476 46.2889 22.2053 46.4284 22.2053 46.5739V50.6202C22.2053 50.9977 22.1308 51.3714 21.9862 51.7201C21.8416 52.0687 21.6297 52.3855 21.3626 52.6521C21.0954 52.9188 20.7784 53.1302 20.4296 53.2741C20.0806 53.4182 19.7068 53.492 19.3292 53.4913C18.5678 53.4913 17.8376 53.1888 17.2991 52.6503C16.7606 52.1119 16.4583 51.3817 16.4583 50.6202V46.5722C16.4583 46.4268 16.516 46.2874 16.6188 46.1845C16.7217 46.0816 16.8611 46.0238 17.0065 46.0238L17.0098 46.0255Z"
                fill="#FDA57D"
            />
            <path
                opacity="0.1"
                d="M16.4747 48.2401C17.8766 48.7641 19.4198 48.7685 20.8246 48.2525V47.7182H16.4747V48.2401Z"
                fill={colors.black}
            />
            <path
                d="M19.3556 48.7908C23.9018 48.7908 27.5873 45.1052 27.5873 40.559C27.5873 36.0126 23.9018 32.3271 19.3556 32.3271C14.8092 32.3271 11.1237 36.0126 11.1237 40.559C11.1237 45.1052 14.8092 48.7908 19.3556 48.7908Z"
                fill="#FDA57D"
            />
            <path
                opacity="0.1"
                d="M9.86816 38.7891C9.86816 38.7891 13.7508 38.3181 16.6863 34.5544C16.6863 34.5544 20.5689 38.8288 23.2739 38.3577C23.2739 38.3577 24.842 36.7102 23.2739 35.0639C21.7057 33.4177 20.4896 31.3388 18.4119 31.6127C16.3343 31.8867 13.7843 32.0045 12.6871 32.985C11.59 33.9656 9.86816 38.7891 9.86816 38.7891Z"
                fill={colors.black}
            />
            <path
                d="M9.86816 40.9933C9.86816 40.9933 14.9948 40.3712 18.8709 35.4017C18.8709 35.4017 23.9976 41.0457 27.5694 40.4237C27.5694 40.4237 29.6399 38.2483 27.5694 36.0745C25.4987 33.9006 23.8929 31.1556 21.1495 31.5174C18.4062 31.8792 15.0456 32.0282 13.597 33.3376C12.1483 34.6471 9.86816 40.9933 9.86816 40.9933Z"
                fill={colors.black}
            />
            <path
                d="M11.1204 41.9873C11.5435 41.9873 11.8865 41.3445 11.8865 40.5517C11.8865 39.7588 11.5435 39.1161 11.1204 39.1161C10.6973 39.1161 10.3544 39.7588 10.3544 40.5517C10.3544 41.3445 10.6973 41.9873 11.1204 41.9873Z"
                fill="#FDA57D"
            />
            <path
                d="M27.5858 41.9873C28.0089 41.9873 28.3518 41.3445 28.3518 40.5517C28.3518 39.7588 28.0089 39.1161 27.5858 39.1161C27.1627 39.1161 26.8197 39.7588 26.8197 40.5517C26.8197 41.3445 27.1627 41.9873 27.5858 41.9873Z"
                fill="#FDA57D"
            />
            <path
                d="M43.2142 13.7236C42.3309 13.5295 41.4069 13.6936 40.6445 14.18C39.8821 14.6663 39.3437 15.4352 39.1477 16.318L30.6674 54.6871C30.4733 55.5704 30.6374 56.4945 31.1237 57.2568C31.6102 58.0192 32.379 58.5574 33.2617 58.7536L61.4083 64.9745C62.2915 65.1686 63.2157 65.0045 63.9779 64.5182C64.7404 64.0319 65.2787 63.2629 65.4748 62.3802L73.9551 24.0111C74.1493 23.1278 73.9852 22.2037 73.4987 21.4414C73.0124 20.679 72.2435 20.1407 71.3607 19.9445L43.2142 13.7236Z"
                fill={colors.tintedgray['700']}
            />
            <path
                d="M72.4365 22.6642L41.0928 15.7367L32.186 56.0355L63.5297 62.9631L72.4365 22.6642Z"
                fill="white"
            />
            <path
                d="M66.7425 28.9818L43.5937 23.8655L43.2577 25.3863L66.4063 30.5025L66.7425 28.9818Z"
                fill={colors.primary['main']}
            />
            <path
                d="M65.8462 33.0385L42.6975 27.9223L42.3615 29.4429L65.51 34.5593L65.8462 33.0385Z"
                fill={colors.primary['main']}
            />
            <path
                d="M64.9499 37.0926L41.8011 31.9764L41.4651 33.4971L64.6137 38.6134L64.9499 37.0926Z"
                fill={colors.primary['main']}
            />
            <path
                d="M64.0536 41.148L40.905 36.0318L40.5688 37.5524L63.7176 42.6688L64.0536 41.148Z"
                fill={colors.primary['main']}
            />
            <path
                d="M63.1573 45.2035L40.0087 40.0872L39.6725 41.6078L62.8212 46.7242L63.1573 45.2035Z"
                fill={colors.primary['main']}
            />
            <path
                d="M62.261 49.2588L39.1124 44.1425L38.7764 45.6632L61.925 50.7794L62.261 49.2588Z"
                fill={colors.primary['main']}
            />
            <path
                d="M61.3648 53.3141L38.2161 48.1979L37.88 49.7186L61.0286 54.8348L61.3648 53.3141Z"
                fill={colors.primary['main']}
            />
            <path
                d="M69.2295 19.2759L45.3472 18.4033L44.2252 49.1094L68.1077 49.9819L69.2295 19.2759Z"
                fill="#F5F5F5"
            />
            <path
                d="M65.9063 24.666L48.2681 24.0216L48.2258 25.1803L65.864 25.8248L65.9063 24.666Z"
                fill={colors.secondary['main']}
            />
            <path
                d="M65.7935 27.7556L48.1552 27.1111L48.1129 28.2698L65.7511 28.9143L65.7935 27.7556Z"
                fill={colors.secondary['main']}
            />
            <path
                d="M65.6805 30.8452L48.0423 30.2007L47.9999 31.3595L65.6382 32.0039L65.6805 30.8452Z"
                fill={colors.secondary['main']}
            />
            <path
                d="M65.5675 33.936L47.9293 33.2916L47.8871 34.4503L65.5253 35.0948L65.5675 33.936Z"
                fill={colors.secondary['main']}
            />
            <path
                d="M65.4544 37.0244L47.8163 36.3799L47.7739 37.5387L65.4121 38.1832L65.4544 37.0244Z"
                fill={colors.secondary['main']}
            />
            <path
                d="M65.342 40.1138L47.7036 39.4695L47.6614 40.6282L65.2996 41.2727L65.342 40.1138Z"
                fill={colors.secondary['main']}
            />
            <path
                d="M65.229 43.2049L47.5907 42.5604L47.5485 43.719L65.1867 44.3635L65.229 43.2049Z"
                fill={colors.secondary['main']}
            />
            <path
                d="M63.5204 16.1745L60.7583 15.564L60.8832 14.9986C60.9739 14.5883 60.898 14.1589 60.6719 13.8048C60.446 13.4506 60.0887 13.2007 59.6785 13.1101L56.6889 12.4493C56.2788 12.3587 55.8494 12.4347 55.4951 12.6606C55.141 12.8865 54.8911 13.2439 54.8004 13.6541L54.6755 14.2196L51.9134 13.6091C51.7104 13.5642 51.5003 13.5598 51.2955 13.596C51.0907 13.6323 50.895 13.7085 50.7197 13.8204C50.5443 13.9323 50.3927 14.0776 50.2735 14.2481C50.1543 14.4186 50.0699 14.6108 50.025 14.8139L49.0784 19.0967L63.7786 22.3457L64.7251 18.0629C64.77 17.8598 64.7745 17.6499 64.7382 17.4451C64.702 17.2402 64.6257 17.0445 64.5138 16.8692C64.402 16.6938 64.2566 16.5422 64.0862 16.423C63.9157 16.3038 63.7234 16.2193 63.5204 16.1745Z"
                fill={colors.tintedgray['700']}
            />
            <path
                d="M47.5141 56.3506C48.0397 56.1389 48.581 55.9176 49.1547 55.8954C49.6781 55.8752 50.2386 56.035 50.55 56.4838C50.8011 56.846 50.8352 57.3249 50.5162 57.6578C50.4466 57.7344 50.3616 57.7955 50.2666 57.8368C50.1718 57.8783 50.0692 57.8992 49.9657 57.898C49.8673 57.8895 49.7726 57.8562 49.6906 57.8013C49.6085 57.7464 49.5417 57.6715 49.4964 57.5838C49.4037 57.4212 49.3745 57.2046 49.4792 57.0402C49.5894 56.8675 49.7981 56.7932 49.9925 56.7687C50.2167 56.7487 50.4425 56.7518 50.6662 56.7781L51.5896 56.8535L55.3685 57.162L59.5769 57.5057C59.6112 57.5037 59.6433 57.4882 59.6663 57.4628C59.6893 57.4371 59.7014 57.4036 59.6998 57.3693C59.6972 57.3352 59.6816 57.3035 59.6563 57.2805C59.6309 57.2577 59.5976 57.2454 59.5636 57.2463L51.1896 56.5626C50.8868 56.5379 50.5822 56.5008 50.2782 56.4957C50.0411 56.4821 49.8039 56.5243 49.5859 56.6189C49.1285 56.8411 49.0359 57.3924 49.3228 57.7942C49.6134 58.2007 50.1517 58.2608 50.5497 57.9706C50.7509 57.8236 50.8975 57.6138 50.9662 57.3743C51.035 57.1348 51.0219 56.8793 50.9291 56.6479C50.6973 56.0473 50.1087 55.7061 49.4873 55.6466C48.7687 55.578 48.0887 55.8401 47.4344 56.1034C47.2805 56.1654 47.3585 56.4132 47.5141 56.3506Z"
                fill={colors.tintedgray['700']}
            />
            <defs>
                <linearGradient
                    id="paint0_linear_9608_804"
                    x1="14873.9"
                    y1="28719.3"
                    x2="14873.9"
                    y2="19926.1"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BBBBBB" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#BBBBBB"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#BBBBBB" stopOpacity="0.1" />
                </linearGradient>
            </defs>
        </Icon>
    );
}
