import { useInputValidation } from 'core';
import InputBase from '../InputBase';
import { WidgetProps } from './types';
import getInputName from 'core/lib/forms/helpers/getInputName';

function InputProfessionalId(props: Omit<WidgetProps, 'type'>) {
    const { validations } = props;
    const { registerValues } = useInputValidation(
        validations,
        getInputName(props.id, props.name)
    );

    return <InputBase type="text" {...registerValues} {...props} />;
}

export default InputProfessionalId;
