import type { Options } from 'intl-messageformat';
import { useFormContext } from 'react-hook-form';
import { MessageDescriptor, useIntl } from 'react-intl';
import type { Validations, ValidationOptions } from '../types';
import {
    validateAllowLetters,
    validateAllowNumbers,
    validateAllowSpecialCharacters,
} from './validations/validateAllow';
import {
    validateMaxAmount,
    validateMinAmount,
} from './validations/validateAmount';
import { validateSameAs } from './validations/validateConfirmation';
import {
    validateDateMin,
    validateDateMax,
    validateOpenDays,
} from './validations/validateDate';
import { validateMaxSize } from './validations/validateFile';
import { validateForbiddenCharacters } from './validations/validateForbidden';
import {
    validateMaxLength,
    validateMinLength,
} from './validations/validateLength';
import { validateMinSelectedOptions } from './validations/validateMinSelectedOptions';
import {
    validateNumberOfRequiredCapitalLetters,
    validateNumberOfRequiredNumbers,
    validateNumberOfRequiredSpecialCharacters,
} from './validations/validateNumberOf';
import { validatePattern } from './validations/validatePattern';
import { validateRequired } from './validations/validateRequired';
import { validateMaxSelectedOptions } from './validations/validateMaxSelectedOptions';
import { validateRangeBetween } from './validations/validateRangeBetween';
import { validateHigherThan } from './validations/validateHigherThan';
import { validateRichTextareaRequired } from './validations/validateRichTextareaRequired';
import { MessageDescriptorValues } from '../../shared';
import { validateRegex } from './validations';

type FormatMessageFn = {
    (
        descriptor: MessageDescriptor,
        values?: MessageDescriptorValues | undefined,
        opts?: Options | undefined | any
    ): string;
};

type WatchFn = (id?: string | string[]) => string;

function useInputValidation(
    validations: Validations,
    name: string,
    options?: ValidationOptions
) {
    const { formatMessage } = useIntl();
    const { register, watch, ...formMethods } = useFormContext();
    const {
        forbiddenCharacters,
        numberOfRequiredNumbers,
        numberOfRequiredCapitalLetters,
        numberOfRequiredSpecialCharacters,
        allowSpecialCharacters,
        lengthMin,
        lengthMax,
        dateMax,
        dateMin,
        openDays,
        required,
        richTextareaRequired,
        maxAmount,
        minAmount,
        maxSize,
        allowNumbers,
        allowLetters,
        minSelectedOptions,
        maxSelectedOptions,
        regexPattern,
        regexFlags,
    } = validations;

    const rules = {
        required: validateRequired(
            minSelectedOptions > 0 || required,
            formatMessage
        ),
        pattern: validatePattern(
            options?.type,
            formatMessage,
            options?.patternValidations
        ),
        minLength: validateMinLength(lengthMin, formatMessage),
        maxLength: validateMaxLength(lengthMax, formatMessage),
        max: validateMaxAmount(maxAmount, formatMessage),
        min: validateMinAmount(minAmount, formatMessage),
        validate: {
            sameAs: validateSameAs(options, watch, formatMessage),
            forbiddenCharacters: validateForbiddenCharacters(
                forbiddenCharacters,
                formatMessage
            ),
            numberOfRequiredNumbers: validateNumberOfRequiredNumbers(
                numberOfRequiredNumbers,
                formatMessage
            ),
            numberOfRequiredCapitalLetters:
                validateNumberOfRequiredCapitalLetters(
                    numberOfRequiredCapitalLetters,
                    formatMessage
                ),
            numberOfRequiredSpecialCharacters:
                validateNumberOfRequiredSpecialCharacters(
                    numberOfRequiredSpecialCharacters,
                    formatMessage
                ),
            dateMax: validateDateMax(dateMax, formatMessage),
            dateMin: validateDateMin(dateMin, formatMessage),
            openDays: validateOpenDays(openDays, formatMessage),
            maxSize: validateMaxSize(maxSize, formatMessage),
            allowNumbers: validateAllowNumbers(allowNumbers, formatMessage),
            allowLetters: validateAllowLetters(allowLetters, formatMessage),
            allowSpecialCharacters: validateAllowSpecialCharacters(
                allowSpecialCharacters,
                formatMessage
            ),
            minSelectedOptions: validateMinSelectedOptions(
                minSelectedOptions,
                formatMessage
            ),
            maxSelectedOptions: validateMaxSelectedOptions(
                maxSelectedOptions,
                formatMessage
            ),
            regexPattern: validateRegex(
                regexPattern,
                regexFlags,
                formatMessage
            ),
            rangeBetween: validateRangeBetween(options, watch, formatMessage),
            higherThan: validateHigherThan(options, watch, formatMessage),
            ...(richTextareaRequired && {
                richTextareaRequired:
                    validateRichTextareaRequired(formatMessage),
            }),
        },
    };

    const registerValues = register(name, rules);

    return {
        rules,
        registerValues,
        register,
        ...formMethods,
    };
}

export type { FormatMessageFn, WatchFn };
export {
    useInputValidation,
    validateDateMax,
    validateDateMin,
    validateRequired,
};
