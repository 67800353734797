import { Icon } from '@chakra-ui/react';

export default function TextExchangeIllustration() {
    return (
        <Icon
            width="75"
            height="75"
            viewBox="0 0 75 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9608_868)">
                <path
                    d="M38.5583 36.8728C43.5052 36.2503 46.8178 30.2019 45.9573 23.3634C45.0967 16.5248 40.3887 11.4858 35.4418 12.1083C30.4949 12.7309 27.1823 18.7793 28.0428 25.6178C28.9034 32.4563 33.6113 37.4954 38.5583 36.8728Z"
                    fill="#FFBE93"
                />
                <path
                    d="M59.1917 62.0205C58.534 62.6019 57.852 63.1582 57.1491 63.6876C52.146 67.4557 46.0355 69.8739 39.3239 70.358C30.6227 70.9856 22.4551 68.2518 16.0948 63.2588C16.0362 63.2124 15.9776 63.166 15.919 63.119C16.4969 59.2972 17.4665 55.6439 18.5429 52.3965C21.2792 44.1445 29.2326 38.7669 37.9081 39.3157C38.0548 39.3248 38.1995 39.3348 38.3422 39.3442C45.5005 39.836 51.7753 44.3097 54.5988 50.9058C56.1853 54.6115 57.8439 58.6691 59.1924 62.0205L59.1917 62.0205Z"
                    fill="#293A4C"
                />
                <path
                    d="M36.2269 30.7067C36.2269 30.7067 38.5133 32.1637 40.9277 31.0243"
                    stroke="#293A4C"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
                <path
                    d="M56.6804 63.8525L56.6798 63.853C56.0075 64.366 55.3132 64.8535 54.6003 65.3141C49.5296 68.5894 43.4887 70.4905 37.0003 70.4905C28.5912 70.4905 20.9306 67.2973 15.161 62.056C15.1061 62.0057 15.0544 61.9584 15.0032 61.9108L14.9993 61.9072C8.5483 55.9691 4.50954 47.4575 4.50954 38.0003C4.50954 20.056 19.056 5.50954 37.0003 5.50954C54.9447 5.50954 69.4905 20.0559 69.4905 38.0003C69.4905 48.5449 64.4689 57.9159 56.6804 63.8525Z"
                    stroke="#293A4C"
                    strokeWidth="2.42874"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_9608_868">
                    <rect
                        width="68"
                        height="68"
                        fill="white"
                        transform="translate(3 4)"
                    />
                </clipPath>
            </defs>
        </Icon>
    );
}
