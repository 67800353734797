import { Icon, IconProps } from '@chakra-ui/react';

export default function PlaceIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M15 9a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-1.5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M19 9c0 3.866-6 13-7 13S5 12.866 5 9a7 7 0 0 1 14 0zm-2 0c0 .555-.244 1.548-.802 2.899-.533 1.29-1.267 2.71-2.048 4.038-.751 1.28-1.52 2.432-2.15 3.265-.63-.833-1.399-1.985-2.15-3.265-.78-1.329-1.515-2.747-2.048-4.038C7.244 10.548 7 9.555 7 9a5 5 0 0 1 10 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
