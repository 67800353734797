import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { __DEV__, contextError } from 'core/utils';
import type { DatePickerProps } from '..';
import {
    selectedDateReducer,
    SelectedDateAction,
} from './reducers/selectedDate';
import { currentDateReducer, CurrentDateAction } from './reducers/currentDate';

dayjs.extend(customParseFormat);

export type Date = {
    /** @see https://day.js.org/docs/en/get-set/year */
    year: number;

    /** @see https://day.js.org/docs/en/get-set/month */
    month: number;

    /** @see https://day.js.org/docs/en/get-set/date */
    day: number;
};

type DateContext = {
    today: Date;
    current: Date;
    setCurrent: React.Dispatch<CurrentDateAction>;
    selected: Date;
    setSelected: React.Dispatch<SelectedDateAction>;
    format: string;
    range: {
        min: string | Dayjs;
        max: string | Dayjs;
    };
    openDays: boolean;
    onClickDay?: () => void;
};

export type DateProviderProps = Pick<
    DatePickerProps,
    'onChange' | 'onClickDay'
> &
    Partial<Pick<DateContext, 'format' | 'range' | 'openDays'>> & {
        children: React.ReactNode;
        defaultDate?: string;
    };

const DateContext = React.createContext<DateContext | null>(null);

if (__DEV__) {
    DateContext.displayName = 'DateContext';
}

export function DateProvider({
    children,
    onChange,
    onClickDay,
    defaultDate,
    format = 'YYYY-MM-DD',
    range = { min: '', max: '' },
    openDays,
}: DateProviderProps) {
    const date = defaultDate
        ? dayjs(defaultDate, format)
        : dayjs().format(format);
    const isDateValid = dayjs(date).isValid();
    const INIT_STATE = {
        year: isDateValid ? dayjs(date, format).year() : dayjs().year(),
        month: isDateValid ? dayjs(date, format).month() : dayjs().month(),
        day: isDateValid ? dayjs(date, format).date() : dayjs().date(),
    };

    const [current, setCurrent] = React.useReducer(
        currentDateReducer,
        INIT_STATE
    );

    const [selected, setSelected] = React.useReducer(
        selectedDateReducer,
        INIT_STATE
    );

    const modifiedRange = {
        min: dayjs(range.min),
        max: dayjs(range.max),
    };

    React.useEffect(() => {
        if (!selected) return;
        const { year, month, day } = selected;
        onChange(dayjs(new Date(year, month, day)).format(format));
        // Without JSON.stringify this cause a infinite loop when the calendar open
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify({ format, onChange, selected })]);

    return (
        <DateContext.Provider
            value={{
                today: {
                    year: dayjs().year(),
                    month: dayjs().month(),
                    day: dayjs().date(),
                },
                current,
                setCurrent,
                selected,
                setSelected,
                format,
                range: modifiedRange,
                openDays,
                onClickDay,
            }}>
            {children}
        </DateContext.Provider>
    );
}

export function useDate(): DateContext {
    const context = React.useContext(DateContext);
    if (!context) {
        throw contextError('useDate', 'DateProvider');
    }
    return context;
}
