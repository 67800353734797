import { defineMessages, defineMessage } from 'react-intl';

export const teleconsultationLocationMessages = defineMessages({
    workflowTeleconsultationCountryTitle: {
        id: 'workflow-teleconsultation.country.title',
        defaultMessage: 'Où vous situez-vous ?',
    },
});

export const teleconsultationBeneficiaryMessages = defineMessages({
    workflowTeleconsultationBeneficiaryTitle: {
        id: 'workflow-teleconsultation.beneficiary.title',
        defaultMessage: 'Pour qui souhaitez-vous consulter ?',
    },
    workflowTeleconsultationBeneficiaryBlockAddBeneficiaryTitle: {
        id: 'workflow-teleconsultation.beneficiary.block-add-beneficiary-title',
        defaultMessage: "Quelqu'un manque à l'appel ?",
    },
    workflowTeleconsultationBeneficiaryBlockAddBeneficiaryDescription: {
        id: 'workflow-teleconsultation.beneficiary.block-add-beneficiary-description',
        defaultMessage:
            "<l>Si l'un de vos bénéficiaires est manquant pour accéder à la consultation, vous avez la possibilité de l'ajouter ou de l'inviter en fonction de son âge.</l>",
    },
    addMinorChild: {
        id: 'buttons.add-minor-child',
        defaultMessage: 'Ajouter un enfant mineur',
    },
    workflowTeleconsultationBeneficiaryBlockInvitedBeneficiaryDescription: {
        id: 'workflow-teleconsultation.beneficiary.block-beneficiary-description',
        defaultMessage:
            'Si vous souhaitez réaliser une consultation pour un bénéficiaire mineur, vous devez le faire avec le compte {appName} du souscripteur du contrat.',
    },
});

export const teleconsultationSpecialityChoiceMessages = defineMessages({
    workflowTeleconsultationSpecialityChoiceTitle: {
        id: 'workflow-teleconsultation.speciality.title',
        defaultMessage: 'Avec quel spécialiste souhaitez-vous échanger ?',
    },
});

export const teleconsultationReasonMessages = defineMessages({
    workflowTeleconsultationReasonTitle: {
        id: 'workflow-teleconsultation.motive.title',
        defaultMessage: 'Quel est le motif de votre consultation ?',
    },
    workflowTextMessageTitle: {
        id: 'workflow-teleconsultation.motive.written-title',
        defaultMessage: 'Formulez votre demande par écrit',
    },
    workflowTextMessageInfoText: {
        id: 'workflow-teleconsultation.motive.written-info',
        defaultMessage:
            '<b>Soyez le plus précis possible dans votre demande pour permettre à nos professionnels de santé de répondre au mieux à vos questions.</b>',
    },
});

export const teleconsultationCommonMessages = defineMessages({
    workflowTextMessageButtonNext: {
        id: 'buttons.send-my-request',
        defaultMessage: 'Envoyer ma demande',
    },
});

export const teleconsultationCancelMessages = defineMessages({
    teleconsultationAtDateTime: {
        id: 'workflow-teleconsultation.detail-page.date-time',
        defaultMessage: '{date} à {time}',
    },
    teleconsultationActors: {
        id: 'workflow-teleconsultation.detail-page.title',
        defaultMessage:
            '<l><b>{specialityName}</b></l> <b>pour</b> {beneficiaryName}',
    },
});

export const teleconsultationConfirmationMessages = defineMessages({
    globalStatus: {
        id: 'workflow-teleconsultation.detail-page.status.global',
        defaultMessage: 'Consultation à distance',
    },
    buttonAccessMyExchange: {
        id: 'buttons.access-my-exchange',
        defaultMessage: 'Accéder à mon échange',
    },
    textMessageWorkflowTitle: {
        id: 'workflow-teleconsultation.confirmation.written-workflow.title',
        defaultMessage: 'Votre message a bien été envoyé !',
    },
    textMessageWorkflowDescription: {
        id: 'workflow-teleconsultation.confirmation.written-workflow.description',
        defaultMessage:
            "<l><b>Un professionnel de santé vous répondra dans un délai maximum de 2 heures.</b></l><l></l>Si vous n'avez pas de réponse durant ce laps de temps, vous serez contacté par téléphone au {phoneNumber}.",
    },
    textMessageWorkflowCheckText1: {
        id: 'workflow-teleconsultation.confirmation.written-workflow.text1',
        defaultMessage: 'Je reste disponible en cas de réponse.',
    },
    textMessageWorkflowCheckText2: {
        id: 'workflow-teleconsultation.confirmation.written-workflow.text2',
        defaultMessage:
            "Je peux joindre des documents en accédant au détail de l'échange.",
    },
    fastWorkflowTitle: {
        id: 'workflow-teleconsultation.confirmation.fast-workflow.title',
        defaultMessage: 'Vous allez être contacté !',
    },
    phoneFastWorkflowTitleDescription: {
        id: 'workflow-teleconsultation.confirmation.phone-fast-workflow.description',
        defaultMessage:
            'Vous allez être contacté sous 30 minutes par téléphone au {phoneNumber}.',
    },
    videoFastWorkflowTitleDescription: {
        id: 'workflow-teleconsultation.channel.video.field-description',
        defaultMessage:
            'Vous allez être contacté par téléphone par un médecin au {phoneNumber} avant de pouvoir accéder à la visio.',
    },
    videoFastWorflowDescription: {
        id: 'workflow-teleconsultation.confirmation.video-fast-workflow.description',
        defaultMessage:
            'Vous allez être contacté par téléphone au {phoneNumber} par un médecin avant de pouvoir accéder à la visio.',
    },
    appointmentWorkflowTitle: {
        id: 'workflow-teleconsultation.confirmation.appointment-workflow.title',
        defaultMessage: 'Votre rendez-vous a bien été enregistré !',
    },
    infosTime: {
        id: 'workflow-teleconsultation.confirmation.infos.time',
        defaultMessage:
            '{isFastWorkflow, select, true {Dans un délai de 30 minutes} other {Le {date} à {time}}}',
    },
    infosSpecialityBeneficiary: {
        id: 'workflow-teleconsultation.confirmation.infos.speciality-beneficiary',
        defaultMessage: '{speciality} pour {beneficiary}',
    },
    infosSpecialityBeneficiaryOther: {
        id: 'workflow-teleconsultation.confirmation.infos.speciality-beneficiary-other',
        defaultMessage: 'Bilan de prévention pour {beneficiary}',
    },
    phoneFastWorflowText1: {
        id: 'workflow-teleconsultation.confirmation.phone-fast-workflow.text1',
        defaultMessage: 'Je reste joignable et réponds aux numéros masqués.',
    },
    phoneFastWorflowText2: {
        id: 'workflow-teleconsultation.confirmation.phone-fast-workflow.text2',
        defaultMessage: 'Je garde mon téléphone à proximité.',
    },
    videoFastWorflowText1: {
        id: 'workflow-teleconsultation.confirmation.video-fast-workflow.text1',
        defaultMessage: 'Je reste joignable et réponds aux numéros masqués.',
    },
    videoFastWorflowText2: {
        id: 'workflow-teleconsultation.confirmation.video-fast-workflow.text2',
        defaultMessage: 'Je me connecte au lien envoyé par SMS ou mail.',
    },
    phoneDescriptionAppointmentWorkflow: {
        id: 'workflow-teleconsultation.confirmation.phone-appointment-workflow.description',
        defaultMessage:
            "Vous serez contacté par téléphone au {phoneNumber} à la date et l'heure de votre rendez-vous.",
    },
    videoDescriptionAppointmentWorkflow: {
        id: 'workflow-teleconsultation.confirmation.video-appointment-workflow.description',
        defaultMessage:
            'Le jour du rendez-vous, le lien de la visio vous sera envoyé aux coordonnées de notifications (SMS et Mail) que vous avez renseignées dans le parcours.',
    },
    videoAlertTitleAppointmentWorkflow: {
        id: 'workflow-teleconsultation.confirmation.video-appointment-workflow.alert-title',
        defaultMessage: 'Vos coordonnées ne sont pas à jour ?',
    },
    videoAlertDescriptionAppointmentWorkflow: {
        id: 'workflow-teleconsultation.confirmation.video-appointment-workflow.alert-description',
        defaultMessage:
            'Vous pouvez modifier vos coordonnées depuis votre profil.',
    },
    'Open-TreatmentDoctor': {
        id: 'workflow-teleconsultation.detail-page.status.open-treatment-doctor',
        defaultMessage: 'Consultation en cours',
    },
    Open: {
        id: 'workflow-teleconsultation.detail-page.status.open',
        defaultMessage: 'Consultation en cours',
    },
    'Resolved-Completed': {
        id: 'workflow-teleconsultation.detail-page.status.resolved-completed',
        defaultMessage: 'Consultation passée',
    },
    'Resolved-Cancelled': {
        id: 'workflow-teleconsultation.detail-page.status.resolved-cancelled',
        defaultMessage: 'Consultation annulée',
    },
    'Open-WaitingDoctor': {
        id: 'workflow-teleconsultation.detail-page.status.open-waiting-doctor',
        defaultMessage: 'Consultation à venir',
    },
    New: {
        id: 'workflow-teleconsultation.detail-page.status.new',
        defaultMessage: 'Consultation à venir',
    },
    'Pending-Patient': {
        id: 'workflow-teleconsultation.detail-page.status.pending-patient',
        defaultMessage: 'Nouveau message reçu',
    },
    medicalInfosTitle: {
        id: 'workflow-teleconsultation.confirmation.appointment-workflow.medical-data-title',
        defaultMessage: 'Vos informations médicales',
    },
    medicalInfosDescription: {
        id: 'workflow-teleconsultation.confirmation.appointment-workflow.medical-data-description',
        defaultMessage:
            'Afin de préparer au mieux votre consultation, merci <span>de renseigner les informations médicales.</span>',
    },
});

export const teleconsultationCommunicationDateMessages = defineMessages({
    emptyState: {
        id: 'emtpty-state.slots',
        defaultMessage:
            "Aucun créneau n'est disponible pour le moment. Veuillez réessayer plus tard.",
    },
    workflowTeleconsultationButtonShowMoreAvailabilites: {
        id: 'buttons.show-more-availabilities',
        defaultMessage: 'Afficher plus de disponibilités',
    },
    workflowTeleconsultationButtonConfirmAppointment: {
        id: 'buttons.confirm-appointment',
        defaultMessage: 'Confirmer le rendez-vous',
    },
    workflowTeleconsultationButtonConfirmDateHour: {
        id: 'buttons.confirm-date-hour',
        defaultMessage: 'Confirmer le {date} à {time}',
    },
});

export const teleconsultationDateMessages = defineMessages({
    title: {
        id: 'workflow-teleconsultation.slot.title',
        defaultMessage: 'Choisissez le créneau de votre consultation',
    },
    modalTitle: {
        id: 'workflow-teleconsultation.slot.modal-title',
        defaultMessage: 'Modifier le créneau du rendez-vous',
    },
});

export const teleconsultationCommunicationMethodMessages = defineMessages({
    workflowTeleconsultationChannelTitle: {
        id: 'workflow-teleconsultation.channel.title',
        defaultMessage: 'Comment souhaitez-vous échanger ?',
    },
    workflowTeleconsultationChannelPhoneLabel: {
        id: 'workflow-teleconsultation.channel.phone.label',
        defaultMessage: 'Téléphone',
    },
    workflowTeleconsultationChannelPhoneFieldTitle: {
        id: 'workflow-teleconsultation.channel.phone.field-title',
        defaultMessage:
            'Entrez le numéro sur lequel vous souhaitez être rappelé.',
    },
    workflowTeleconsultationChannelPhoneFieldDescription: {
        id: 'workflow-teleconsultation.channel.phone.field-description',
        defaultMessage: 'Vous allez être rappelé sur ce numéro.',
    },
    workflowTeleconsultationChannelVideo: {
        id: 'workflow-teleconsultation.channel.video.label',
        defaultMessage: 'Visio',
    },
    workflowTeleconsultationChannelVideoFieldTitle: {
        id: 'workflow-teleconsultation.channel.video.field-title',
        defaultMessage: 'Numéro de téléphone de contact',
    },
});

export const teleconsultationButtonsMessages = defineMessages({
    accessMyExchangeDetails: {
        id: 'buttons.access-my-exchange',
        defaultMessage: 'Accéder à mon échange',
    },
    uptadeMyContactDetails: {
        id: 'buttons.update-my-contact-details',
        defaultMessage: 'Mettre à jour mes coordonnées',
    },
    buttonUploadDocument: {
        id: 'buttons.upload-document',
        defaultMessage: 'Joindre un document',
    },
    buttonModifyAppointment: {
        id: 'buttons.modify-appointment',
        defaultMessage: 'Modifier le rendez-vous',
    },
    buttonAddToCalendar: {
        id: 'buttons.add-to-calendar',
        defaultMessage: 'Ajouter au calendrier',
    },
    buttonCancelAppointment: {
        id: 'buttons.cancel-appointment',
        defaultMessage: 'Annuler le rendez-vous',
    },
});

export const teleconsultationToastMessage = defineMessages({
    cancelSuccess: {
        id: 'success-message.cancelation-form',
        defaultMessage: 'L’annulation a bien été effectuée.',
    },
});

export const teleconsultationModalMessages = defineMessages({
    modalDeleteTeleconsultationText: {
        id: 'components.confirmation-modal.deletion-teleconsultation-text',
        defaultMessage:
            'Vous êtes sur le point d’annuler le rendez-vous suivant :',
    },
});

export const teleconsultationDetailMessages = defineMessages({
    buttonAccessExchange: {
        id: 'buttons.access-exchange',
        defaultMessage: "Accéder à l'échange",
    },
    detailPageWrittenTitle: {
        id: 'workflow-teleconsultation.detail-page.written-title',
        defaultMessage: '<l>Echange en cours</l> avec {beneficiaryName}',
    },
    detailPageMessageBlockTitle: {
        id: 'workflow-teleconsultation.detail-page.messages-block-title',
        defaultMessage: 'Historique de ma consultation écrite',
    },
    detailPageCallback: {
        id: 'workflow-teleconsultation.detail-page.call-back',
        defaultMessage: 'Je souhaite être rappelé',
    },
    detailPageDateReceived: {
        id: 'date-received',
        defaultMessage: 'Reçu le {date}',
    },
    buttonsUpdateMedicalData: {
        id: 'buttons.update-medical-data',
        defaultMessage: 'Mettre à jour les informations médicales',
    },
});

export const timeSlotErrorMessage = defineMessage({
    id: 'errors.workflow-teleconsultation.slot',
    defaultMessage:
        "Le créneau que vous aviez sélectionné n'est plus disponible. Veuillez en choisir un autre.",
});
