import {
    getIntlPath,
    getAuthToken,
    GetServerSidePropsContextWithAuth,
} from 'core';
import { getPrimoAuthToken } from 'lib/primo/helpers';
import routes from 'routes';

function withAuthRedirect(handler: any) {
    return (context: GetServerSidePropsContextWithAuth) => {
        switch (true) {
            case !!getAuthToken(context.req): {
                const destination = getIntlPath(
                    routes,
                    '/news',
                    context.locale
                );

                return {
                    redirect: {
                        destination,
                        permanent: false,
                    },
                };
            }
            case !!getPrimoAuthToken(context.req): {
                const destination = getIntlPath(
                    routes,
                    '/primo',
                    context.locale
                );

                return {
                    redirect: {
                        destination,
                        permanent: false,
                    },
                };
            }
            default:
                return handler(context);
        }
    };
}

export { withAuthRedirect };
