import { defineMessage, defineMessages } from 'react-intl';

export const validationFormsMessages = defineMessages({
    regexPattern: {
        id: 'errors.forms.validations.regex-pattern',
        defaultMessage: 'Veuillez respecter le format requis',
    },
    required: {
        id: 'errors.forms.validations.required',
        defaultMessage: 'Veuillez renseigner ce champ.',
    },
    sameAs: {
        id: 'errors.forms.validations.same-as',
        defaultMessage:
            'Les {type, select, password {mots de passe} other {e-mails}} ne correspondent pas.',
    },
    lengthMin: {
        id: 'errors.forms.validations.length-min',
        defaultMessage: 'Ce champ doit comporter au moins {length} caractères.',
    },
    lengthMax: {
        id: 'errors.forms.validations.length-max',
        defaultMessage:
            'Ce champ doit comporter au maximum {length} caractères.',
    },
    forbiddenCharacters: {
        id: 'errors.forms.validations.forbidden-characters',
        defaultMessage:
            'Ce champ contient des caractères interdits ; veuillez vérifier votre saisie.',
    },
    numberOfRequiredSpecialCharacters: {
        id: 'errors.forms.validations.number-of-required-special-characters',
        defaultMessage:
            'Ce champ doit contenir au moins {numberOf, plural, =0 {} one {# caractère spécial} other {# caractères spéciaux}}.',
    },
    numberOfRequiredNumbers: {
        id: 'errors.forms.validations.number-of-required-numbers',
        defaultMessage:
            'Ce champ doit contenir au moins {numberOf, plural, =0 {} one {# chiffre} other {# chiffres}}.',
    },
    numberOfRequiredCapitalLetters: {
        id: 'errors.forms.validations.number-of-required-capital-letters',
        defaultMessage:
            'Ce champ doit contenir au moins {numberOf, plural, =0 {} one {# majuscule} other {# majuscules}}.',
    },
    dateMax: {
        id: 'errors.forms.validations.date-max',
        defaultMessage:
            'La date est trop récente ; veuillez vérifier votre saisie.',
    },
    dateMin: {
        id: 'errors.forms.validations.date-min',
        defaultMessage:
            'La date est trop ancienne ; veuillez vérifier votre saisie.',
    },
    openDays: {
        id: 'errors.forms.validations.open-days',
        defaultMessage:
            'Seuls les jours de semaine sont acceptés. Veuillez vérifier votre saisie.',
    },
    minAmount: {
        id: 'errors.forms.validations.min-amount',
        defaultMessage: 'Le montant doit être supérieur à {amount}.',
    },
    maxAmount: {
        id: 'errors.forms.validations.max-amount',
        defaultMessage: 'Le montant doit être inférieur ou égal à {amount}.',
    },
    maxSize: {
        id: 'errors.forms.validations.max-size',
        defaultMessage:
            'La taille du fichier doit être inférieure à {maxSize} octets.',
    },
    allowNumbers: {
        id: 'errors.forms.validations.allow-numbers',
        defaultMessage:
            'Ce champ n’autorise pas les chiffres ; veuillez vérifier votre saisie.',
    },
    allowLetters: {
        id: 'errors.forms.validations.allow-letters',
        defaultMessage:
            'Ce champ n’autorise pas les lettres ; veuillez vérifier votre saisie.',
    },
    allowSpecialCharacters: {
        id: 'errors.forms.validations.allow-special-characters',
        defaultMessage:
            "Ce champ n'autorise pas les caractères spéciaux ; veuillez vérifier votre saisie.",
    },
    dateRangeMin: {
        id: 'errors.forms.validations.date-range-min',
        defaultMessage:
            'La date de fin {range, plural, =0 {doit être la même ou se situer après la date de début} one {doit se situer après la date de début} other {doit se situer au moins # jours après la date de début}}.',
    },
    dateRangeMax: {
        id: 'errors.forms.validations.date-range-max',
        defaultMessage:
            'La date de fin {range, plural, =0 {doit être la même que la date de début} one {ne peut être située plus de # jour après la date de début} other {ne peut être située plus de # jours après la date de début}}.',
    },
    minSelectedOptions: {
        id: 'errors.forms.after-sending.min-selected-options',
        defaultMessage:
            'Ce champ doit comporter au moins {minSelectedOptions} {minSelectedOptions, plural, =0 {élément} one {élément} other {éléments}}.',
    },
    maxSelectedOptions: {
        id: 'errors.forms.after-sending.max-selected-options',
        defaultMessage:
            'Ce champ doit comporter au maximum {maxSelectedOptions} {maxSelectedOptions, plural, =0 {élément} one {élément} other {éléments}}.',
    },
    rangeMin: {
        id: 'errors.forms.validations.range-min',
        defaultMessage:
            "L'heure de fin {range, plural, =0 {doit être la même ou se situer après l'heure de début} one {doit se situer après l'heure de début} other {doit se situer au moins # heures après l'heure de début}}.",
    },
    rangeMax: {
        id: 'errors.forms.validations.range-max',
        defaultMessage:
            "L'heure de fin {range, plural, =0 {doit être la au moins égale à l'heure de début} one {ne peut être située plus de # heur après la date de début} other {ne peut être située plus de # heures après la date de début}}.",
    },
    higherThan: {
        id: 'errors.forms.validations.higher-than',
        defaultMessage:
            "L'heure de fin doit être supérieure à l'heure de début.",
    },
});

const phonePatternValidation = defineMessage({
    id: 'errors.forms.validations.pattern-type.phone',
    defaultMessage:
        "Le numéro de téléphone n'est pas valide ; veuillez vérifier votre saisie.",
});

export const validationPatternTypes = {
    ...defineMessages({
        email: {
            id: 'errors.forms.validations.pattern-type.email',
            defaultMessage:
                "L'adresse e-mail n'est pas valide ; veuillez vérifier votre saisie.",
        },
        date: {
            id: 'errors.forms.validations.pattern-type.date',
            defaultMessage:
                "Le format de la date n'est pas valide ; veuillez vérifier votre saisie.",
        },

        ssn: {
            id: 'errors.forms.validations.pattern-type.ssn',
            defaultMessage:
                "Le numéro de Sécurité sociale n'est pas valide ; veuillez vérifier votre saisie.",
        },
        ssnWith0: {
            id: 'errors.forms.validations.pattern-type.ssn-with-0',
            defaultMessage:
                "Le numéro de Sécurité sociale n'est pas valide ; veuillez vérifier votre saisie.",
        },
        password: {
            id: 'errors.forms.validations.pattern-type.password',
            defaultMessage:
                'Votre mot de passe doit contenir au moins {lengthMin, plural, =0 {# caractère} one {# caractère} other {# caractères}}, avec au moins 1 minuscule{numberOfRequiredCapitalLetters, plural, =0 {} one {, # majuscule} other {, # majuscules}}{numberOfRequiredNumbers, plural, =0 {} one {, # chiffre} other {, # chiffres}}{numberOfRequiredSpecialCharacters, plural, =0 {} one { et # caractère spécial} other { et # caractères spéciaux}}.',
        },
    }),
    phone: phonePatternValidation,
    internationalPhone: phonePatternValidation,
};
