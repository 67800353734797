import { Box } from '@chakra-ui/react';

type LegendValueProps = React.PropsWithChildren<unknown>;

export function LegendValue({ children }: LegendValueProps) {
    return (
        <Box fontFamily="mono" fontWeight="semibold" flexShrink={0}>
            {children}
        </Box>
    );
}
