import { InternationalPhoneNumberProps } from 'core/components/forms/widgets/InternationalPhoneNumber';
import { InputRangeValue } from 'core/components/forms/widgets/Range';
import { WidgetKeys } from 'core';
import { Address } from '../types/address';

type InternationalPhoneNumberDefaultValue = {
    defaultValue: InternationalPhoneNumberProps['defaultValue'];
    defaultCountry: InternationalPhoneNumberProps['defaultCountry'];
};

type ConditionalRadioDefaultValue = {
    defaultValue: string;
    subDefaultValue?: string | number | null;
};

type RangeDefaultValue = {
    startValue?: InputRangeValue['defaultValue'];
    endValue?: InputRangeValue['defaultValue'];
};

type DefaultValue =
    | string
    | Address
    | InternationalPhoneNumberDefaultValue
    | ConditionalRadioDefaultValue
    | RangeDefaultValue;

export function prefillWidget(
    config: WidgetKeys,
    defaultValue: DefaultValue
): void {
    if (!config?.type || defaultValue === null || defaultValue === undefined) {
        return;
    }

    switch (config?.type) {
        case 'internationalPhoneNumber':
            config.defaultValue = (
                defaultValue as InternationalPhoneNumberDefaultValue
            )?.defaultValue;
            config.defaultCountry = (
                defaultValue as InternationalPhoneNumberDefaultValue
            )?.defaultCountry;
            break;
        case 'conditionalRadio':
        case 'conditionalRadioMultipleSubWidgets':
            config.defaultValue = (
                defaultValue as ConditionalRadioDefaultValue
            )?.defaultValue;
            config.subDefaultValue = (
                defaultValue as ConditionalRadioDefaultValue
            )?.subDefaultValue;
            break;
        case 'range':
            config.startValue = {
                ...config.startValue,
                defaultValue: (defaultValue as RangeDefaultValue)?.startValue,
            };
            config.endValue = {
                ...config.endValue,
                defaultValue: (defaultValue as RangeDefaultValue)?.endValue,
            };
            break;
        default:
            config.defaultValue = defaultValue as string;
            break;
    }
}
