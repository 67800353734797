import { MessageDescriptor, PrimitiveType } from 'react-intl';
import { UrlObject } from 'url';
import { QueryFunctionContext } from '@tanstack/react-query';
import { BoxProps, ContainerProps, IconProps } from '@chakra-ui/react';
import {
    EnhancedQueryStatus,
    HeadTitleProps,
    ProgressBarProps,
} from '../../..';
import { BreadcrumbType } from 'design-system/components';
import { ErrorProps } from '../../../components/Layouts/ErrorComponent';
import { FormatXMLElementFn } from 'intl-messageformat';

export type FetchQueryParams = QueryFunctionContext<string[]>;
export type APIError = { code: number; customErrorCode?: string };

export type LayoutButtonType = {
    text: MessageDescriptor;
    href?: string | UrlObject;
    onClick?: () => any;
};

export type GlobalLayoutType = React.PropsWithChildren<
    Omit<BoxProps, 'title'> & HeadTitleProps
> & {
    hasPaddingBottom?: boolean;
};

export type LayoutBoundaryType = React.PropsWithChildren<
    ContainerProps & {
        status?: EnhancedQueryStatus;
        toastError?: boolean;
        alertError?: boolean;
        errorValues?: Record<string, string | number> | undefined;
        ErrorComponent?: React.ComponentType<ErrorProps>;
        LoadingComponent?: React.ComponentType<{
            message?: MessageDescriptor;
        }>;
        messages?: {
            error?: MessageDescriptor;
            errorDescription?: MessageDescriptor;
            loading?: MessageDescriptor;
            empty?: MessageDescriptor;
            maintenanceTitle?: MessageDescriptor;
            maintenanceDescription?: MessageDescriptor;
            alertError?: MessageDescriptor;
            toastError?: MessageDescriptor;
            toastTitleError?: MessageDescriptor;
        };
        errorCode?: number;
        errorButtons?: LayoutButtonType[];
    }
>;

export type HeaderBoxType = React.PropsWithChildren<
    BoxProps & {
        isFixed?: boolean;
    }
>;

export type HeaderTabsType = {
    tabs: {
        href: UrlObject | string;
        eligibilities?: string[] | null;
        title: MessageDescriptor | string;
    }[];
    colorScheme?: 'black' | 'whiteAlpha';
    defaultIndex?: number;
    size?: string;
};

export type HeaderImageType = {
    imageUrl: string;
    imageAltText: string;
};

export type HeaderPrimaryType = BoxProps &
    React.PropsWithChildren<{
        description?: React.ReactNode;
        isBordered?: boolean;
        progressBar?: ProgressBarProps;
        tabs?: HeaderTabsType['tabs'];
        tabsOptions?: Pick<HeaderTabsType, 'colorScheme' | 'defaultIndex'>;
        size?: string;
        subtitle?: React.ReactNode;
        image?: HeaderImageType;
        littleDivider?: boolean;
        divider?: boolean;
    }>;

export type HeaderSecondaryType = HeaderPrimaryType & {
    breadcrumbs?: BreadcrumbType[];
};

export type RelatedActionsType = 'reason' | 'redirect' | 'form';

export type RelatedActionsLink = {
    translationKey: string;
    icon?: string;
    type: RelatedActionsType;
    formId?: string;
    reasonIds?: string[];
    slug?: string;
};

export type RelatedActionCategory = {
    category: string;
    relatedActions: RelatedActionsLink[];
};

export type RelatedActionsCategories = {
    item: string;
    categories: RelatedActionCategory[];
    type: 'page' | 'section';
};

export enum WorkflowTypesEnum {
    PA = 'pa',
    PE = 'pe',
}

export enum PEWorkflowTypesEnum {
    EXEMPTIONS = 'exemptions',
    BD = 'beneficiary-designations',
    DOCUMENTS = 'legal-documents',
}

export enum ProcedureTypesEnum {
    STANDARD = 'signature-beneficiaries-designation.standard',
    GUIDED = 'signature-beneficiaries-designation.specific-guided',
}

export enum CapitalProcedureTypesEnum {
    FULL = 'signature-beneficiaries-designation.specific-guided.full-capital',
    DIVIDED = 'signature-beneficiaries-designation.specific-guided.divided-capital',
}

export type IconComponent = (props: IconProps) => JSX.Element;

export type MessageDescriptorValues = Record<
    string,
    PrimitiveType | FormatXMLElementFn<string, string>
>;
