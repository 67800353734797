import { Stack } from '@chakra-ui/react';
import { Confirmation, useConfirmationValidation } from 'core';
import Fieldset from '../Fieldset';
import InputEmail from './InputEmail';
import { WidgetConfirmationProps } from './types';

type EmailConfirmationProps = WidgetConfirmationProps & {
    email: Confirmation;
};

function EmailConfirmation({
    confirmation,
    email,
    validations,
    help,
    ...props
}: EmailConfirmationProps) {
    const { confirmationName, originalName } = useConfirmationValidation(
        props.id
    );

    return (
        <Fieldset
            legend={props.title}
            isRequired={validations.required}
            help={help}>
            <Stack>
                <InputEmail
                    id={props.id}
                    name={originalName}
                    {...email}
                    {...{ validations }}
                />
                <InputEmail
                    hasNoPattern
                    id={`${props.id}Confirmation`}
                    name={confirmationName}
                    {...confirmation}
                    validations={{
                        ...(validations.required && {
                            required: validations.required,
                        }),
                        sameAs: `${props.id}.value`,
                    }}
                />
            </Stack>
        </Fieldset>
    );
}

export default EmailConfirmation;
