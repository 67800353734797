import { Icon, IconProps } from '@chakra-ui/react';

export default function ForwardIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M7.5 14.633a1 1 0 0 1-1.407-.14L2.226 9.765a1 1 0 0 1 0-1.266l3.867-4.726A1 1 0 1 1 7.64 5.04L4.292 9.133l3.349 4.093a1 1 0 0 1-.141 1.407z"
                fill="currentColor"
            />
            <path
                d="M12.5 14.633a1 1 0 0 1-1.407-.14L7.226 9.765a1 1 0 0 1 0-1.266l3.867-4.726A1 1 0 1 1 12.64 5.04l-2.53 3.093H14c2.76 0 4.572.982 5.664 2.386C20.72 11.877 21 13.496 21 14.633c0 2.113-1.504 4.22-3.859 5.07-.581.21-1.141-.265-1.141-.883v-.202c0-.379.213-.727.557-.887.533-.247.696-.29.91-.44.78-.546 1.38-1.532 1.533-2.24.182-.842-.22-2.411-.914-3.304-.658-.846-1.847-1.614-4.086-1.614h-3.89l2.53 3.093a1 1 0 0 1-.14 1.407z"
                fill="currentColor"
            />
        </Icon>
    );
}
