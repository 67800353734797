import {
    validationFormsMessages,
    FormatMessageFn,
    WatchFn,
    ValidationOptions,
} from 'core';

function validateSameAs(
    options: ValidationOptions | undefined,
    watch: WatchFn,
    formatMessage: FormatMessageFn
) {
    return (value: string) => {
        if (!options?.sameAs) return undefined;

        const sameAsValue = watch(options.sameAs);

        return sameAsValue !== value
            ? formatMessage(validationFormsMessages.sameAs, {
                  type: options?.type,
              })
            : undefined;
    };
}

export { validateSameAs };
