import { defineMessages } from 'react-intl';

export const coreSharedMessages = defineMessages({
    loginTitle: {
        id: 'components.common.login.title',
        defaultMessage: 'Connexion',
    },
    logoAlt: {
        id: 'components.logo-alt',
        defaultMessage: 'Logo du site',
    },
    welcome: {
        defaultMessage: 'Bienvenue',
        id: 'components.welcome',
    },
    welcomeMessage: {
        id: 'components.news.welcome',
        defaultMessage:
            '{firstName, select, undefined {} null {Bonjour et bienvenue !} other {Bonjour {firstName}}}',
    },
    welcomeMessageNoFirstName: {
        id: 'components.news.welcome-no-name',
        defaultMessage: 'Bonjour et bienvenue !',
    },
    send: {
        id: 'buttons.send',
        defaultMessage: 'Envoyer',
    },
    submitting: {
        id: 'components.common.submitting',
        defaultMessage: 'Envoi en cours',
    },
    submitDuration: {
        id: 'components.common.submit-duration',
        defaultMessage: 'Veuillez patienter encore quelques secondes.',
    },
    back: {
        id: 'buttons.back',
        defaultMessage: 'Retour',
    },
    backToLogin: {
        id: 'buttons.back-login-page',
        defaultMessage: 'Retour à la page de connexion',
    },
    buttonBackHome: {
        id: 'buttons.back-home',
        defaultMessage: "Retour à l'accueil",
    },
    buttonClearFilters: {
        id: 'buttons.clear-filters',
        defaultMessage: 'Effacer les filtres',
    },
    loading: {
        id: 'loader.default',
        defaultMessage: 'Chargement en cours',
    },
    'common.next.label': {
        id: 'forms.common.next.label',
        defaultMessage: 'Suivant',
    },
    next: {
        id: 'forms.common.next.label',
        defaultMessage: 'Suivant',
    },
    error: {
        id: 'components.common.error',
        defaultMessage: 'Une erreur est survenue',
    },
    errorReload: {
        id: 'errors.default-reload',
        defaultMessage:
            'Une erreur est survenue. Veuillez recharger la page ou réessayer plus tard.',
    },
    loginAgain: {
        id: 'buttons.login-again',
        defaultMessage: 'Se reconnecter',
    },
    cancel: {
        id: 'buttons.cancel',
        defaultMessage: 'Annuler',
    },
    confirm: {
        id: 'buttons.confirm',
        defaultMessage: 'Confirmer',
    },
    validate: {
        id: 'buttons.validate',
        defaultMessage: 'Valider',
    },
    accept: {
        id: 'buttons.accept',
        defaultMessage: 'Accepter',
    },
    reject: {
        id: 'buttons.reject',
        defaultMessage: 'Refuser',
    },
    edit: {
        id: 'buttons.edit',
        defaultMessage: 'Modifier',
    },
    add: {
        id: 'buttons.add',
        defaultMessage: 'Ajouter',
    },
    delete: {
        id: 'buttons.delete',
        defaultMessage: 'Supprimer',
    },
    choose: {
        id: 'buttons.choose',
        defaultMessage: 'Choisir',
    },
    remove: {
        id: 'buttons.remove',
        defaultMessage: 'Retirer',
    },
    replace: {
        id: 'buttons.replace',
        defaultMessage: 'Remplacer',
    },
    or: {
        id: 'components.common.or',
        defaultMessage: 'ou',
    },
    max: {
        id: 'components.common.max',
        defaultMessage: 'max',
    },
    refresh: {
        id: 'buttons.refresh',
        defaultMessage: 'Actualiser',
    },
    filters: {
        id: 'components.filters',
        defaultMessage: 'Filtres',
    },
    filter: {
        id: 'buttons.filter',
        defaultMessage: 'Filtrer',
    },
    allFilters: {
        id: 'buttons.all-filters',
        defaultMessage: 'Tous les filtres',
    },
    export: {
        id: 'buttons.export',
        defaultMessage: 'Exporter',
    },
    exportExcel: {
        id: 'buttons.export-excel',
        defaultMessage: 'Exporter (format Excel)',
    },
    logout: {
        id: 'buttons.logout',
        defaultMessage: 'Déconnexion',
    },
    showMore: {
        id: 'buttons.show-more',
        defaultMessage: 'Afficher plus',
    },
    deselect: {
        id: 'buttons.deselect',
        defaultMessage: 'Désélectionner',
    },
    reloadPage: {
        id: 'buttons.reload-page',
        defaultMessage: 'Recharger la page',
    },
    buttonDownload: {
        id: 'buttons.download',
        defaultMessage: 'Télécharger',
    },
    loginButton: {
        id: 'buttons.login',
        defaultMessage: 'Connexion',
    },
    buttonClear: {
        id: 'buttons.clear',
        description: 'Bouton de réinitilisation des filtres',
        defaultMessage: 'Effacer',
    },
    buttonApply: {
        id: 'buttons.apply',
        description: 'Bouton de validation des filtres',
        defaultMessage: 'Appliquer',
    },
    buttonSelectAll: {
        id: 'buttons.select-all',
        defaultMessage: 'Tout sélectionner',
    },
    buttonDeselectAll: {
        id: 'buttons.deselect-all',
        defaultMessage: 'Tout désélectionner',
    },
    lostCredentialsLink: {
        id: 'login.forgotten-login-details',
        defaultMessage: 'Identifiant ou mot de passe oublié ?',
    },
    lostPassword: {
        id: 'buttons.forgotten-password',
        defaultMessage: 'J’ai oublié mon mot de passe',
    },
    lostUsername: {
        id: 'buttons.forgotten-username',
        defaultMessage: 'Je ne trouve plus mon identifiant',
    },
    addUser: {
        id: 'buttons.add-a-new-user',
        defaultMessage: 'Ajouter un nouvel utilisateur',
    },
    congratulations: {
        id: 'components.congratulations',
        defaultMessage: 'Félicitations',
    },
    accessHomePage: {
        id: 'components.access-homepage',
        defaultMessage: 'Accéder à mon espace',
    },
    passwordUpgradeTitle: {
        id: 'components.password-change.title',
        defaultMessage: 'Votre mot de passe a bien été modifié',
    },
    profileUsername: {
        id: 'profile.username',
        defaultMessage: 'Identifiant',
    },
    profilePassword: {
        id: 'profile.password',
        defaultMessage: 'Mot de passe',
    },
    profileNotification: {
        id: 'profile.notifications-preference',
        defaultMessage: 'Préférences de notification',
    },
    profilePhoneNumbers: {
        id: 'profile.phone-numbers',
        defaultMessage: 'Téléphones',
    },
    profileEmail: {
        id: 'profile.email',
        defaultMessage: 'E-mail',
    },
    profileGeneralConditionsOfUse: {
        id: 'profile.general-conditions-of-use',
        defaultMessage: "Conditions générales d'utilisation",
    },
    profilePrivacyPolicy: {
        id: 'profile.privacy-policy',
        defaultMessage: 'Politique de confidentialité',
    },
    profileLegalNotices: {
        id: 'profile.legal-notices',
        defaultMessage: 'Mentions légales',
    },
    profileEmailsFrequency: {
        id: 'profile.emails-frequency',
        defaultMessage: 'Paramètres des rapports',
    },
    'legal-notices': {
        id: 'page-title.legal-notices',
        defaultMessage: 'Mentions légales',
    },
    'general-conditions-of-use': {
        id: 'page-title.general-conditions-of-use',
        defaultMessage: 'Conditions générales d’utilisation',
    },
    'privacy-policy': {
        id: 'page-title.privacy-policy',
        defaultMessage: 'Politique de confidentialité',
    },
    address: {
        id: 'contact.address.title',
        defaultMessage: 'Adresse',
    },
    status: {
        id: 'components.status.title',
        defaultMessage: 'Statut',
    },
    search: {
        id: 'components.search',
        defaultMessage: 'Rechercher',
    },
    emailAddress: {
        id: 'components.email-adress',
        defaultMessage: 'Adresse e-mail',
    },
    confirmationToast: {
        id: 'success-message.form',
        defaultMessage: 'La mise à jour a bien été effectuée.',
    },
    deletionToast: {
        id: 'success-message.deletion-form',
        defaultMessage: 'La suppression a bien été effectuée.',
    },
    resendEmailToast: {
        id: 'success-message.resend-activation-mail-form',
        defaultMessage: "L'email d'activation a bien été renvoyé.",
    },
    confirmation: {
        id: 'components.confirmation-modal.title',
        defaultMessage: 'Confirmation',
    },
    noResult: {
        id: 'components.common.no-result',
        defaultMessage: 'Aucun résultat',
    },
    inProgress: {
        id: 'components.common.load',
        defaultMessage: 'Chargement...',
    },
    close: {
        id: 'components.common.close',
        defaultMessage: 'Fermer',
    },
    continue: {
        id: 'buttons.continue',
        defaultMessage: 'Continuer',
    },
    previewAndSend: {
        id: 'buttons.preview-and-send',
        defaultMessage: 'Prévisualiser puis envoyer',
    },
    previous: {
        id: 'buttons.previous',
        defaultMessage: 'Précédent',
    },
    addSuccess: {
        id: 'success-message.add-form',
        defaultMessage: "L'ajout a bien été effectué.",
    },
    fullyRefunded: {
        id: 'home.refunds.fully-refunded',
        defaultMessage: '100% remboursé',
    },
    contact: {
        id: 'contact.title',
        defaultMessage: 'Contact',
    },
    'components.messages.reason-link': {
        id: 'components.messages.reason-link',
        defaultMessage: 'Temporairement indisponible',
    },
    characters: {
        id: 'components.forms.widgets.characters',
        defaultMessage: 'caractères',
    },
    new: {
        id: 'components.new',
        defaultMessage: 'Nouveau',
    },
    visit: {
        id: 'buttons.visit',
        defaultMessage: 'Consulter',
    },
    home: {
        id: 'page-title.home',
        defaultMessage: 'Accueil',
    },
    administration: {
        id: 'page-title.structures',
        defaultMessage: 'Administration',
    },
    employees: {
        id: 'page-title.employees',
        defaultMessage: 'Salariés',
    },
    documents: {
        id: 'page-title.documents',
        defaultMessage: 'Documents',
    },
    account: {
        id: 'page-title.account',
        defaultMessage: 'Mon compte',
    },
    'menu.home': {
        id: 'menu.home',
        defaultMessage: 'Accueil',
    },
    'menu.documents': {
        id: 'menu.documents',
        defaultMessage: 'Documents',
    },
    'menu.contact': {
        id: 'menu.contact',
        defaultMessage: 'Contact',
    },
    'menu.administration': {
        id: 'menu.administration',
        defaultMessage: 'Administration',
    },
    'menu.account': {
        id: 'menu.account',
        defaultMessage: 'Mon compte',
    },
    'connexionInfo.level_title': {
        id: 'profile.subtitles.connexion-info',
        defaultMessage: 'Informations de connexion',
    },
    'contactInfo.level_title': {
        id: 'profile.subtitles.contact-details',
        defaultMessage: 'Coordonnées',
    },
    'parametersInfo.level_title': {
        id: 'profile.subtitles.settings',
        defaultMessage: 'Paramètres',
    },
    'param.level_title': {
        id: 'profile.subtitles.preferences',
        defaultMessage: 'Préférences',
    },
    deleteAccount: {
        id: 'profile.delete-account.title',
        defaultMessage: 'Supprimer mon compte',
    },
    deleteAccountDescription: {
        id: 'profile.delete-account.text',
        defaultMessage:
            'Attention, après avoir cliqué sur "Confirmer", votre compte {appName} sera automatiquement supprimé. Cette suppression n\'aura aucun impact sur votre contrat.',
    },
    'legalInfo.level_title': {
        id: 'profile.subtitles.legal-info',
        defaultMessage: 'Informations légales',
    },
    addBankDetails: {
        id: 'buttons.add-bank-details',
        defaultMessage: 'Ajouter les coordonnées bancaires',
    },
    addAnUser: {
        id: 'buttons.add-a-user',
        defaultMessage: 'Ajouter un utilisateur',
    },
    firstName: {
        id: 'components.first-name',
        defaultMessage: 'Prénom',
    },
    lastName: {
        id: 'components.name',
        defaultMessage: 'Nom',
    },
    active: {
        id: 'components.status.active',
        defaultMessage: 'Actif',
    },
    inactive: {
        id: 'components.status.inactive',
        defaultMessage: 'Inactif',
    },
    selectedElements: {
        id: 'selected-elements-out-of-entries',
        defaultMessage: '{number} sur {total}',
    },
    displayedOfEntries: {
        id: 'component.displayed-items-out-of-entries',
        defaultMessage:
            '{number, plural, one {# élément affiché } other {# éléments affichés}} sur {total}',
    },
    expiredCode: {
        id: 'errors.workflow-signature.signature.400',
        defaultMessage: 'Code erroné ou expiré',
    },
    updateContactInfo: {
        id: 'forms.profile.contact-details.title',
        defaultMessage: 'Modifier mes coordonnées',
    },
    manageUsers: {
        id: 'profile.users-management',
        defaultMessage: 'Gérer les utilisateurs',
    },
    viewContracts: {
        id: 'profile.contracts-consultation',
        defaultMessage: 'Voir mes contrats',
    },
    viewEnterprises: {
        id: 'profile.companies-consultation',
        defaultMessage: 'Voir mes entreprises',
    },
});

export const coreEmptyStates = defineMessages({
    default: {
        id: 'empty-state.default',
        defaultMessage: "Aucun contenu n'est disponible pour le moment.",
    },
    refunds: {
        id: 'empty-state.refunds',
        defaultMessage: "Vous n'avez aucun remboursement pour le moment.",
    },
    filteredRefunds: {
        id: 'empty-state.filtered-refunds',
        defaultMessage:
            'Les filtres sélectionnés ne retournent aucun remboursement.<br></br>Vous modifier les filtres appliqués ou réinitialiser tous les filtres.',
    },
    messages: {
        id: 'empty-state.messages',
        defaultMessage: "Vous n'avez aucun message pour le moment.",
    },
    statements: {
        id: 'empty-state.statements',
        defaultMessage: "Vous n'avez aucun e-relevé pour le moment.",
    },
    homeRefunds: {
        id: 'empty-state.home-refunds',
        defaultMessage: "Vous n'avez aucun remboursement ce mois-ci.",
    },
    homeMessages: {
        id: 'empty-state.home-messages',
        defaultMessage: "Vous n'avez pas de messages ce mois-ci.",
    },
    filters: {
        id: 'empty-state.filters',
        defaultMessage:
            'Aucun résultat ne correspond à vos critères de recherche.',
    },
    documents: {
        id: 'empty-state.documents',
        defaultMessage: "Aucun document n'est disponible pour le moment.",
    },
    appointments: {
        id: 'empty-state.healthcare-appointments.next-appointments',
        defaultMessage: `{isPrincipalBeneficiary, select,
                            true {Vous n'avez pas de rendez-vous à venir.}
                            other {<b>{beneficiary}</b> n'a pas de rendez-vous à venir.}}`,
    },
    pastAppointments: {
        id: 'empty-state.healthcare-appointments.past-appointments',
        defaultMessage: `{isPrincipalBeneficiary, select,
                            true {Vous n'avez pas de rendez-vous passé.}
                            other {<b>{beneficiary}</b> n'a pas de rendez-vous passé.}}`,
    },
});
