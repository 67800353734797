import React from 'react';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import { useDate } from '../Date/context';
import { CalendarButton, CalendarRow } from '../Calendar';
import { createRows } from '../utils';
import { usePicker } from './context';

type Month = {
    value: number;
    displayValue: string;
};

const MONTH_COLUMNS = 4;

export function PickerMonth() {
    const rows = createRows<Month>(
        Array.from(Array(12)).map((_, idx) => ({
            value: idx,
            displayValue: capitalize(dayjs().month(idx).format('MMM')),
        })),
        3,
        MONTH_COLUMNS
    );
    const { setPicker, options: pickerOptions } = usePicker();
    const { today, current, setCurrent, selected, setSelected, format, range } =
        useDate();
    const { year } = current;

    function handleClick(value: number) {
        setCurrent({ type: 'SET_MONTH', value });
        setSelected({ type: 'SET_MONTH', value: { year, month: value } });

        if (pickerOptions.min !== 'month') {
            setPicker('date');
        }
    }

    return (
        <>
            {rows.map((row, idx) => (
                <CalendarRow key={idx} col={MONTH_COLUMNS}>
                    {row.map((monthLabel) => {
                        const formattedDate = dayjs(
                            new Date(year, monthLabel.value)
                        );
                        const isDisabled =
                            formattedDate.isBefore(range.min, 'month') ||
                            formattedDate.isAfter(range.max, 'month');

                        return (
                            <CalendarButton
                                key={formattedDate.format(format)}
                                onClick={() => handleClick(monthLabel.value)}
                                disabled={isDisabled}
                                current={
                                    today.year === year &&
                                    today.month === monthLabel.value
                                }
                                selected={
                                    !isDisabled &&
                                    year === selected.year &&
                                    monthLabel.value === selected.month
                                }>
                                {monthLabel.displayValue}
                            </CalendarButton>
                        );
                    })}
                </CalendarRow>
            ))}
        </>
    );
}
