import { Icon, IconProps } from '@chakra-ui/react';

export default function TargetIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M4.062 11A8.004 8.004 0 0 1 11 4.062V3a1 1 0 1 1 2 0v1.062A8.004 8.004 0 0 1 19.938 11H21a1 1 0 1 1 0 2h-1.062A8.004 8.004 0 0 1 13 19.938V21a1 1 0 1 1-2 0v-1.062A8.004 8.004 0 0 1 4.062 13H3a1 1 0 1 1 0-2h1.062zM13 8.126V6.083A6.005 6.005 0 0 1 17.917 11h-2.043a4.01 4.01 0 0 1 0 2h2.043A6.005 6.005 0 0 1 13 17.917v-2.043a4.01 4.01 0 0 1-2 0v2.043A6.005 6.005 0 0 1 6.083 13h2.043a4.007 4.007 0 0 1 0-2H6.083A6.005 6.005 0 0 1 11 6.083v2.043a4.007 4.007 0 0 1 2 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
