import { Icon, IconProps } from '@chakra-ui/react';

export default function HomeIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M12.264 2.5a1 1 0 0 1 .713.313l8.5 9a1 1 0 0 1-.727 1.687h-1v7a1 1 0 0 1-1 1h-4.5a1 1 0 0 1-1-1v-5h-2.5v5a1 1 0 0 1-1 1h-4.5a1 1 0 0 1-1-1v-7h-1a1 1 0 0 1-.707-1.707l9-9a1 1 0 0 1 .721-.293zm-6.014 17v-7a1 1 0 0 0-.65-.937l6.63-6.628 6.238 6.605a1 1 0 0 0-.718.96v7h-2.5v-5a1 1 0 0 0-1-1h-4.5a1 1 0 0 0-1 1v5h-2.5z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
