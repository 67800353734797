import React from 'react';
import {
    Flex,
    HStack,
    Stack,
    Text,
    IconButton,
    VStack,
} from '@chakra-ui/react';
import { defineMessage, useIntl } from 'react-intl';
import { uniqueId } from 'lodash';
import { useWindowBreakpoints } from 'design-system/hooks';
import { Tag } from '../Tag';
import { PenIcon, TrashIcon } from 'design-system/icons';

export type TagType = {
    status: string;
    color: string;
};

export type CardProps = {
    title: React.ReactNode;
    value: React.ReactNode;
    isFullWidth?: boolean;
};

export type CardInfosProps = {
    name: string;
    tag?: TagType;
    items: CardProps[];
    hasFullWidthItems?: boolean;
    onDelete?: () => void;
    onEdit?: () => void;
};

const editArialLabel = defineMessage({
    id: 'buttons.edit',
    defaultMessage: 'Modifier',
});

const deleteArialLabel = defineMessage({
    id: 'components.form-input-card.item.delete',
    defaultMessage: 'Supprimer',
});

function Card({ isFullWidth = false, title, value }: CardProps) {
    const { isMobile } = useWindowBreakpoints();
    const isFullWidthCard = isMobile ? true : isFullWidth;

    return (
        <VStack
            alignItems="start"
            flex={isFullWidthCard && 1}
            p={4}
            backgroundColor="white"
            borderRadius={8}>
            <Text
                fontSize="sm"
                fontWeight={700}
                color="texts.medium"
                lineHeight="17.57px">
                {title}
            </Text>
            <Text fontSize="md" fontWeight={700}>
                {value}
            </Text>
        </VStack>
    );
}

export function CardInfos({
    name,
    tag,
    items,
    hasFullWidthItems = false,
    onDelete,
    onEdit,
}: CardInfosProps) {
    const { isMobile } = useWindowBreakpoints();
    const { formatMessage } = useIntl();

    return (
        <Stack p={6} backgroundColor="tertiary.ultralight" borderRadius={8}>
            <Flex justifyContent="space-between" mb={2}>
                <Flex
                    gap={2}
                    direction={isMobile || hasFullWidthItems ? 'column' : 'row'}
                    alignItems="flex-start">
                    <Text fontSize="lg" fontWeight={600} fontFamily="mono">
                        {name}
                    </Text>
                    {tag && (
                        <Tag
                            alignSelf="start"
                            rounded="full"
                            formattedText={tag.status}
                            bg={tag.color}
                        />
                    )}
                </Flex>
                <HStack alignItems="top">
                    {onEdit && (
                        <IconButton
                            data-testid="cardinfos-edit-action"
                            colorScheme="primary"
                            isRound={true}
                            aria-label={formatMessage(editArialLabel)}
                            size="sm"
                            fontSize="xl"
                            icon={<PenIcon />}
                            onClick={onEdit}
                        />
                    )}
                    {onDelete && (
                        <IconButton
                            data-testid="cardinfos-delete-action"
                            colorScheme="red"
                            isRound={true}
                            aria-label={formatMessage(deleteArialLabel)}
                            size="sm"
                            fontSize="xl"
                            icon={<TrashIcon />}
                            onClick={onDelete}
                        />
                    )}
                </HStack>
            </Flex>
            <Flex
                data-testid="cardinfos-wrapper"
                direction={isMobile || hasFullWidthItems ? 'column' : 'row'}
                gap={2}
                justifyContent="stretch">
                {items.map((item) => (
                    <Card
                        key={uniqueId()}
                        title={item.title}
                        value={item.value}
                        isFullWidth={item?.isFullWidth || hasFullWidthItems}
                    />
                ))}
            </Flex>
        </Stack>
    );
}
