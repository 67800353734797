import { Icon, IconProps } from '@chakra-ui/react';

export default function AttentionIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.84566 19.8864H19.946L11.8958 5.24972L3.84566 19.8864ZM2.9843 21.4525L2.98501 21.4512L2.9843 21.4525ZM10.9698 2.54751L1.13232 20.4339C0.74495 21.1382 1.2545 22 2.05831 22H21.7333C22.5371 22 23.0467 21.1382 22.6593 20.4339L12.8218 2.54751C12.4203 1.8175 11.3713 1.8175 10.9698 2.54751Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8958 14.6024C11.3122 14.6024 10.839 14.1292 10.839 13.5456V10.3751C10.839 9.79148 11.3122 9.31834 11.8958 9.31834C12.4795 9.31834 12.9526 9.79148 12.9526 10.3751V13.5456C12.9526 14.1292 12.4795 14.6024 11.8958 14.6024Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8958 17.7728C11.3122 17.7728 10.839 17.2996 10.839 16.716C10.839 16.1323 11.3122 15.6592 11.8958 15.6592C12.4795 15.6592 12.9526 16.1323 12.9526 16.716C12.9526 17.2996 12.4795 17.7728 11.8958 17.7728Z"
                fill="currentColor"
            />
        </Icon>
    );
}
