/* eslint-disable import/no-unused-modules */
import { useTheme } from '@chakra-ui/react';

function SentConfirmationIcon() {
    const theme = useTheme();
    const colors = theme.colors;

    return (
        <svg
            width="222"
            height="261"
            viewBox="0 0 222 261"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5130_19555)">
                <path
                    d="M132.773 149.349C108.668 154.88 99.9899 178.94 99.4513 180.514C87.2936 167.709 68.5773 163.943 53.1997 170.986C39.8295 177.11 30.9146 190.522 30.0489 205.653C23.8789 201.845 16.1755 201.653 10.0389 205.187C1.71879 209.981 0.598962 219.134 0.5 220.079C64.3678 219.653 128.237 219.226 192.104 218.8C200.083 183.015 172.992 140.121 132.773 149.349Z"
                    fill="white"
                />
                <path
                    d="M94.3161 136.445L93.0051 137.32L125.714 186.028L127.025 185.153L94.3161 136.445Z"
                    fill="#E9A363"
                />
                <path
                    d="M154.923 126.934L125.67 185.24L127.081 185.944L156.334 127.638L154.923 126.934Z"
                    fill="#E9A363"
                />
                <path
                    d="M127.161 119.704H125.583V185.583H127.161V119.704Z"
                    fill="#E9A363"
                />
                <path
                    d="M92.544 135.054C104.054 130.849 109.447 116.72 104.589 103.497C99.7316 90.274 86.4632 82.9633 74.9533 87.1684C63.4434 91.3734 58.0506 105.502 62.9081 118.725C67.7657 131.948 81.0341 139.259 92.544 135.054Z"
                    fill="#51A0F9"
                />
                <path
                    d="M104.588 103.5C101.634 95.4564 95.5669 89.6012 88.6777 87.1943C88.6813 87.2924 88.6837 87.3892 88.6885 87.4873C89.2976 100.851 96.2419 112.2 106.003 117.852C106.769 113.301 106.366 108.341 104.588 103.501V103.5Z"
                    fill="#2284F7"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M70.6505 116.751C70.0006 115.716 69.4263 114.659 68.9228 113.587C70.28 110.723 72.8649 108.829 75.481 108.703C75.6537 108.694 75.8263 108.694 75.999 108.701C74.8876 108.108 73.6838 107.806 72.5016 107.863C70.7356 107.949 68.9851 108.841 67.6159 110.292C65.3655 103.385 65.9614 96.1803 69.111 90.5439C61.771 96.6825 58.9679 108.002 62.9076 118.726C67.6255 131.57 80.2806 138.837 91.5507 135.388C91.4541 135.781 91.3575 136.175 91.261 136.568C91.0251 137.529 90.7892 138.489 90.553 139.45C91.5277 138.845 92.7758 138.235 94.3021 137.832C95.8787 137.416 97.3402 137.33 98.5524 137.376C97.4505 136.758 96.3483 136.14 95.2462 135.522C94.7091 135.221 94.1721 134.92 93.635 134.619C97.3796 132.99 100.414 130.273 102.581 126.884C92.228 132.971 78.0384 128.514 70.6505 116.752V116.751ZM87.431 117.994L87.429 117.993L87.4298 117.993L87.431 117.994ZM87.429 117.993C85.9402 118 84.5905 118.554 83.707 119.012C83.7778 118.39 83.791 117.784 83.7526 117.195C84.4995 117.111 85.298 117.144 86.1025 117.389C86.5646 117.53 87.0076 117.736 87.429 117.993Z"
                    fill="#2284F7"
                />
                <g opacity="0.73">
                    <path
                        d="M88.2346 95.8293C89.1188 95.2109 88.9104 93.3938 87.7691 91.7708C86.6278 90.1477 84.9858 89.3333 84.1015 89.9516C83.2173 90.57 83.4257 92.387 84.567 94.0101C85.7083 95.6332 87.3503 96.4476 88.2346 95.8293Z"
                        fill="white"
                    />
                </g>
                <g opacity="0.73">
                    <path
                        d="M81.886 94.2215C82.4596 93.8204 82.3242 92.6414 81.5836 91.5882C80.843 90.535 79.7777 90.0064 79.2041 90.4075C78.6305 90.8086 78.7659 91.9876 79.5065 93.0408C80.2471 94.094 81.3124 94.6226 81.886 94.2215Z"
                        fill="white"
                    />
                </g>
                <path
                    d="M173.49 84.9162C163.708 80.7326 151.861 86.4502 147.027 97.6883C142.332 108.605 145.984 120.713 155.164 125.232C154.144 125.889 153.124 126.548 152.105 127.206C153.082 127.403 154.234 127.759 155.439 128.412C156.683 129.086 157.658 129.901 158.396 130.649C157.909 129.094 157.422 127.539 156.936 125.984C166.523 129.421 177.772 123.716 182.45 112.84C187.283 101.602 183.271 89.0998 173.49 84.9162Z"
                    fill="#9F7AEA"
                />
                <path
                    d="M150.714 106.085C149.551 98.5774 151.817 91.4477 156.221 86.7344C152.361 89.1819 149.069 92.9435 147.027 97.6891C142.332 108.605 145.984 120.714 155.164 125.232C154.144 125.89 153.124 126.549 152.105 127.206C153.082 127.404 154.234 127.76 155.439 128.413C156.683 129.087 157.658 129.901 158.396 130.65C157.909 129.094 157.422 127.54 156.936 125.984C161.41 127.589 166.245 127.202 170.606 125.228C161.056 125.252 152.433 117.192 150.713 106.085H150.714Z"
                    fill="#805AD5"
                />
                <g opacity="0.73">
                    <path
                        d="M179.241 97.491C180.233 97.1761 180.565 95.4457 179.984 93.6261C179.402 91.8065 178.128 90.5866 177.136 90.9015C176.145 91.2164 175.813 92.9468 176.394 94.7664C176.976 96.586 178.25 97.8059 179.241 97.491Z"
                        fill="white"
                    />
                    <path
                        d="M173.262 94.3977C173.842 94.2135 174.036 93.2011 173.696 92.1365C173.356 91.0719 172.61 90.3582 172.03 90.5425C171.45 90.7267 171.256 91.739 171.596 92.8036C171.936 93.8682 172.682 94.5819 173.262 94.3977Z"
                        fill="white"
                    />
                </g>
                <path
                    d="M154.547 78.284C153.651 58.6344 139.06 43.3335 121.957 44.1083C104.853 44.883 91.7135 61.4406 92.6091 81.0914C93.4784 100.18 107.273 115.161 123.74 115.292C122.724 116.958 121.71 118.623 120.696 120.289C122.25 119.892 124.168 119.603 126.372 119.705C128.648 119.812 130.63 120.31 132.226 120.88C130.429 118.968 128.633 117.056 126.836 115.143C143.133 113.423 155.414 97.3034 154.547 78.284Z"
                    fill="#F78888"
                />
                <path
                    d="M106.863 86.4973C100.16 71.3506 103.521 54.6591 114.093 45.6426C101.008 50.2626 91.8573 64.5855 92.6091 81.0917C93.4783 100.18 107.273 115.161 123.74 115.293C122.724 116.958 121.71 118.624 120.696 120.289C122.25 119.892 124.168 119.603 126.371 119.706C128.648 119.812 130.63 120.311 132.226 120.88C130.429 118.968 128.633 117.056 126.835 115.143C132.447 114.551 137.582 112.252 141.868 108.715C128.103 110.356 113.546 101.595 106.865 86.4973H106.863Z"
                    fill="#F35D59"
                />
                <g opacity="0.73">
                    <path
                        d="M146.612 73.2233C148.207 72.7209 148.749 69.9389 147.822 67.0095C146.894 64.0801 144.849 62.1125 143.253 62.6148C141.658 63.1172 141.116 65.8992 142.043 68.8286C142.971 71.7581 145.016 73.7256 146.612 73.2233Z"
                        fill="white"
                    />
                    <path
                        d="M137.006 68.2218C137.939 67.928 138.256 66.3008 137.714 64.5873C137.171 62.8739 135.975 61.723 135.042 62.0168C134.109 62.3106 133.792 63.9379 134.334 65.6513C134.877 67.3648 136.073 68.5156 137.006 68.2218Z"
                        fill="white"
                    />
                </g>
                <path
                    d="M89.0818 118.228C86.9765 117.588 84.9107 118.389 83.7081 119.012C84.3855 113.061 79.7888 108.494 75.4821 108.703C72.1347 108.865 68.8411 111.919 68.099 116.192C66.3869 107.304 58.9954 100.851 50.683 100.518C38.2547 100.022 32.5825 113.562 32.2672 114.348C31.4759 116.328 30.3944 119.064 30.5083 121.717H93.1894C92.2027 120.098 90.7628 118.74 89.0806 118.227L89.0818 118.228Z"
                    fill="white"
                />
                <path
                    d="M154.221 240.83H75.8826L85.4058 185.583H163.743L154.221 240.83Z"
                    fill={colors.primary.main}
                />
                <path
                    d="M133.194 217.262L163.743 185.583H85.4058L105.529 211.124C105.511 211.894 105.601 212.709 105.439 213.434C105.175 214.616 104.127 215.77 104.181 217.039C104.235 218.307 105.375 219.369 105.739 220.525C106.114 221.722 105.795 223.24 106.455 224.275C107.124 225.323 108.644 225.678 109.555 226.513C110.466 227.348 110.948 228.829 112.052 229.405C113.141 229.973 114.631 229.53 115.859 229.802C117.045 230.065 118.202 231.11 119.474 231.057C120.746 231.003 121.811 229.866 122.97 229.503C124.17 229.129 125.693 229.447 126.73 228.79C127.781 228.122 128.138 226.606 128.974 225.698C129.812 224.789 131.297 224.308 131.875 223.207C132.444 222.121 131.999 220.635 132.273 219.411C132.432 218.695 132.878 217.989 133.195 217.263L133.194 217.262Z"
                    fill={colors.primary[800]}
                />
                <path
                    d="M85.4058 185.583L121.047 217.804L163.743 185.583H85.4058Z"
                    fill={colors.primary.main}
                />
                <path
                    d="M137.485 212.59C137.539 213.859 136.491 215.013 136.227 216.195C135.963 217.378 136.4 218.906 135.829 219.991C135.258 221.077 133.766 221.573 132.929 222.482C132.091 223.391 131.736 224.907 130.684 225.574C129.633 226.241 128.124 225.914 126.924 226.288C125.724 226.662 124.7 227.787 123.428 227.841C122.156 227.895 120.999 226.85 119.813 226.587C118.586 226.314 117.095 226.759 116.007 226.19C114.918 225.621 114.42 224.132 113.509 223.297C112.598 222.462 111.078 222.108 110.409 221.059C109.749 220.025 110.068 218.506 109.693 217.31C109.318 216.113 108.19 215.092 108.136 213.823C108.082 212.554 109.129 211.401 109.393 210.218C109.657 209.036 109.221 207.508 109.791 206.422C110.362 205.336 111.855 204.84 112.692 203.931C113.528 203.023 113.885 201.506 114.936 200.839C115.973 200.182 117.496 200.5 118.696 200.125C119.855 199.763 120.92 198.626 122.192 198.572C123.464 198.519 124.621 199.564 125.807 199.827C127.035 200.099 128.525 199.654 129.614 200.224C130.718 200.8 131.2 202.281 132.111 203.116C133.022 203.952 134.542 204.306 135.211 205.354C135.88 206.403 135.552 207.907 135.927 209.104C136.302 210.301 137.431 211.322 137.485 212.59Z"
                    fill="#E9A363"
                />
                <path
                    d="M122.811 223.47C128.495 223.47 133.102 218.874 133.102 213.206C133.102 207.538 128.495 202.942 122.811 202.942C117.127 202.942 112.519 207.538 112.519 213.206C112.519 218.874 117.127 223.47 122.811 223.47Z"
                    fill="#E9A363"
                />
                <path
                    d="M132.493 213.206C132.461 217.192 129.96 220.889 126.174 222.266C122.324 223.666 117.949 222.485 115.332 219.343C112.739 216.231 112.429 211.675 114.517 208.221C116.606 204.767 120.745 202.934 124.703 203.734C129.206 204.643 132.456 208.641 132.493 213.207C132.499 213.988 133.717 213.99 133.711 213.207C133.674 208.65 130.799 204.539 126.497 202.975C122.249 201.432 117.287 202.788 114.42 206.272C111.503 209.818 111.09 214.945 113.507 218.873C115.883 222.735 120.495 224.731 124.947 223.867C130.013 222.886 133.671 218.309 133.711 213.207C133.717 212.425 132.499 212.425 132.493 213.207V213.206Z"
                    fill="white"
                />
                <path
                    d="M125.179 213.779C124.67 214.181 124.349 214.503 124.216 214.747C124.083 214.991 124.016 215.308 124.016 215.7H121.145V215.414C121.145 214.928 121.201 214.532 121.312 214.228C121.422 213.925 121.588 213.647 121.807 213.396C122.027 213.145 122.518 212.703 123.284 212.07C123.692 211.739 123.896 211.435 123.896 211.159C123.896 210.883 123.814 210.669 123.65 210.515C123.486 210.362 123.239 210.285 122.905 210.285C122.548 210.285 122.252 210.403 122.018 210.639C121.784 210.874 121.635 211.287 121.569 211.874L118.638 211.513C118.739 210.438 119.13 209.574 119.813 208.918C120.496 208.263 121.542 207.936 122.952 207.936C124.05 207.936 124.937 208.164 125.612 208.621C126.528 209.239 126.987 210.061 126.987 211.091C126.987 211.518 126.868 211.929 126.632 212.326C126.396 212.723 125.911 213.207 125.181 213.78M121.05 216.462H124.124V219.166H121.05V216.462Z"
                    fill="white"
                />
                <path
                    d="M42.8144 200.699H38.6013C37.9395 200.699 37.4023 200.163 37.4023 199.503C37.4023 198.843 37.9395 198.308 38.6013 198.308H42.8144C43.4762 198.308 44.0134 198.843 44.0134 199.503C44.0134 200.163 43.4762 200.699 42.8144 200.699Z"
                    fill="#E9A363"
                />
                <path
                    d="M68.6518 200.699H48.9878C48.3259 200.699 47.7888 200.163 47.7888 199.503C47.7888 198.843 48.3259 198.308 48.9878 198.308H68.6518C69.3136 198.308 69.8507 198.843 69.8507 199.503C69.8507 200.163 69.3136 200.699 68.6518 200.699Z"
                    fill="#E9A363"
                />
                <path
                    d="M74.2702 208.016H65.386C64.7241 208.016 64.187 207.481 64.187 206.821C64.187 206.161 64.7241 205.625 65.386 205.625H74.2702C74.932 205.625 75.4691 206.161 75.4691 206.821C75.4691 207.481 74.932 208.016 74.2702 208.016Z"
                    fill="#E9A363"
                />
                <path
                    d="M65.3846 214.401H49.6736C49.0117 214.401 48.4746 213.865 48.4746 213.205C48.4746 212.545 49.0117 212.01 49.6736 212.01H65.3846C66.0464 212.01 66.5836 212.545 66.5836 213.205C66.5836 213.865 66.0464 214.401 65.3846 214.401Z"
                    fill="#E9A363"
                />
                <path
                    d="M81.7956 45.8797L82.8992 48.5729C83.0394 48.9136 83.3185 49.178 83.667 49.301L85.2565 49.858C86.4083 50.261 86.4083 51.8868 85.2565 52.2898L83.667 52.8468C83.3185 52.9684 83.0394 53.2342 82.8992 53.5749L81.7956 56.2681C81.3578 57.3355 79.8434 57.3355 79.4056 56.2681L78.302 53.5749C78.1618 53.2342 77.8827 52.9698 77.5342 52.8468L75.9446 52.2898C74.7929 51.8868 74.7929 50.261 75.9446 49.858L77.5342 49.301C77.8827 49.1794 78.1618 48.9136 78.302 48.5729L79.4056 45.8797C79.8434 44.8122 81.3578 44.8122 81.7956 45.8797Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M90.3846 23.4268L92.2433 27.9594C92.4785 28.5334 92.9488 28.9787 93.5353 29.1851L96.21 30.1225C98.1481 30.8011 98.1481 33.5368 96.21 34.2168L93.5353 35.1541C92.9488 35.3591 92.4785 35.8059 92.2433 36.3799L90.3846 40.9125C89.6479 42.7094 87.0979 42.7094 86.3598 40.9125L84.5011 36.3799C84.2659 35.8059 83.7956 35.3605 83.209 35.1541L80.5343 34.2168C78.5963 33.5382 78.5963 30.8025 80.5343 30.1225L83.209 29.1851C83.7956 28.9801 84.2659 28.5334 84.5011 27.9594L86.3598 23.4268C87.0964 21.6299 89.6465 21.6299 90.3846 23.4268Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M138.796 20.6912L139.899 23.3844C140.04 23.7252 140.319 23.9895 140.667 24.1125L142.257 24.6696C143.409 25.0725 143.409 26.6983 142.257 27.1013L140.667 27.6583C140.319 27.7799 140.04 28.0457 139.899 28.3864L138.796 31.0797C138.358 32.1471 136.844 32.1471 136.406 31.0797L135.302 28.3864C135.162 28.0457 134.883 27.7813 134.534 27.6583L132.945 27.1013C131.793 26.6983 131.793 25.0725 132.945 24.6696L134.534 24.1125C134.883 23.991 135.162 23.7252 135.302 23.3844L136.406 20.6912C136.844 19.6238 138.358 19.6238 138.796 20.6912Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M163.727 48.6337L164.49 50.497C164.587 50.7331 164.781 50.9169 165.022 51.0003L166.121 51.3863C166.917 51.6662 166.917 52.7902 166.121 53.0687L165.022 53.4547C164.781 53.5395 164.587 53.7219 164.49 53.958L163.727 55.8213C163.424 56.5607 162.375 56.5607 162.072 55.8213L161.309 53.958C161.212 53.7219 161.018 53.5381 160.777 53.4547L159.678 53.0687C158.882 52.7888 158.882 51.6648 159.678 51.3863L160.777 51.0003C161.018 50.9155 161.212 50.7331 161.309 50.497L162.072 48.6337C162.375 47.8942 163.424 47.8942 163.727 48.6337Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M129.483 10.5605L130.147 12.1793C130.231 12.3843 130.399 12.544 130.609 12.6175L131.564 12.9526C132.256 13.1958 132.256 14.1727 131.564 14.4145L130.609 14.7495C130.399 14.8231 130.232 14.9828 130.147 15.1878L129.483 16.8066C129.219 17.4484 128.308 17.4484 128.046 16.8066L127.382 15.1878C127.298 14.9828 127.129 14.8231 126.92 14.7495L125.965 14.4145C125.272 14.1713 125.272 13.1944 125.965 12.9526L126.92 12.6175C127.129 12.544 127.297 12.3843 127.382 12.1793L128.046 10.5605C128.31 9.91864 129.22 9.91864 129.483 10.5605Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M98.3454 37.3495L98.718 38.2586C98.7647 38.3731 98.8597 38.4635 98.9772 38.5045L99.5128 38.6926C99.9009 38.8283 99.9009 39.3769 99.5128 39.5126L98.9772 39.7006C98.8597 39.7416 98.7661 39.8307 98.718 39.9466L98.3454 40.8557C98.1981 41.2162 97.6866 41.2162 97.5393 40.8557L97.1667 39.9466C97.12 39.8321 97.025 39.7416 96.9075 39.7006L96.3719 39.5126C95.9838 39.3769 95.9838 38.8283 96.3719 38.6926L96.9075 38.5045C97.025 38.4635 97.1185 38.3745 97.1667 38.2586L97.5393 37.3495C97.6866 36.989 98.1981 36.989 98.3454 37.3495Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M71.7646 55.3495L72.1372 56.2585C72.1839 56.3731 72.2788 56.4635 72.3964 56.5045L72.9319 56.6926C73.3201 56.8283 73.3201 57.3769 72.9319 57.5126L72.3964 57.7006C72.2788 57.7416 72.1853 57.8307 72.1372 57.9466L71.7646 58.8557C71.6172 59.2162 71.1058 59.2162 70.9585 58.8557L70.5859 57.9466C70.5391 57.8321 70.4442 57.7416 70.3266 57.7006L69.7911 57.5126C69.403 57.3769 69.403 56.8283 69.7911 56.6926L70.3266 56.5045C70.4442 56.4635 70.5377 56.3745 70.5859 56.2585L70.9585 55.3495C71.1058 54.989 71.6172 54.989 71.7646 55.3495Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M145.425 39.2802L145.797 40.1892C145.844 40.3037 145.939 40.3942 146.056 40.4352L146.592 40.6232C146.98 40.759 146.98 41.3075 146.592 41.4432L146.056 41.6313C145.939 41.6723 145.845 41.7613 145.797 41.8773L145.425 42.7863C145.277 43.1469 144.766 43.1469 144.618 42.7863L144.246 41.8773C144.199 41.7628 144.104 41.6723 143.987 41.6313L143.451 41.4432C143.063 41.3075 143.063 40.759 143.451 40.6232L143.987 40.4352C144.104 40.3942 144.198 40.3051 144.246 40.1892L144.618 39.2802C144.766 38.9196 145.277 38.9196 145.425 39.2802Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M110.131 33.1594C107.346 33.1594 105.081 30.8987 105.081 28.1192C105.081 25.3397 107.346 23.0791 110.131 23.0791C112.917 23.0791 115.182 25.3397 115.182 28.1192C115.182 30.8987 112.917 33.1594 110.131 33.1594ZM110.131 24.4929C108.128 24.4929 106.498 26.1201 106.498 28.1192C106.498 30.1183 108.128 31.7456 110.131 31.7456C112.135 31.7456 113.765 30.1183 113.765 28.1192C113.765 26.1201 112.135 24.4929 110.131 24.4929Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M65.1777 41.2602C63.4706 41.2602 62.0808 39.8732 62.0808 38.1696C62.0808 36.466 63.4706 35.0791 65.1777 35.0791C66.8848 35.0791 68.2746 36.466 68.2746 38.1696C68.2746 39.8732 66.8848 41.2602 65.1777 41.2602ZM65.1777 35.9472C63.9494 35.9472 62.9492 36.9453 62.9492 38.171C62.9492 39.3968 63.948 40.3949 65.1777 40.3949C66.4074 40.3949 67.4062 39.3968 67.4062 38.171C67.4062 36.9453 66.4074 35.9472 65.1777 35.9472Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M159.448 20.3764C157.741 20.3764 156.351 18.9895 156.351 17.2858C156.351 15.5822 157.741 14.1953 159.448 14.1953C161.155 14.1953 162.545 15.5822 162.545 17.2858C162.545 18.9895 161.155 20.3764 159.448 20.3764ZM159.448 15.0634C158.22 15.0634 157.22 16.0615 157.22 17.2873C157.22 18.513 158.219 19.5111 159.448 19.5111C160.678 19.5111 161.677 18.513 161.677 17.2873C161.677 16.0615 160.678 15.0634 159.448 15.0634Z"
                    fill={colors.primary[200]}
                />
            </g>
            <path
                d="M111 260.921C168.063 260.921 214.322 258.27 214.322 255C214.322 251.73 168.063 249.079 111 249.079C53.9369 249.079 7.67798 251.73 7.67798 255C7.67798 258.27 53.9369 260.921 111 260.921Z"
                fill={colors.primary[100]}
            />
            <defs>
                <clipPath id="clip0_5130_19555">
                    <rect
                        width="222"
                        height="240"
                        fill="white"
                        transform="translate(0 0.0791016)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SentConfirmationIcon;
