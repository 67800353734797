import dayjs from 'dayjs';
import { Refunds, GroupedRefunds, GroupedRefund } from 'lib/refunds';

function groupRefund(data: Refunds[]) {
    return data.reduce((prev, curr, index) => {
        const days = dayjs(curr.paymentDate);
        const month = days.month();
        const year = days.year();
        const currentWithPosition = { ...curr, position: index };

        return {
            ...prev,
            [year]: {
                ...prev[year],
                [month]: prev[year]?.[month]
                    ? [...prev[year][month], currentWithPosition]
                    : [currentWithPosition],
            },
        };
    }, {} as Record<string, Record<string, GroupedRefund[]>>);
}

async function groupRefunds(data?: Refunds[]) {
    if (!data) return [];

    const grouped = groupRefund(data);

    return Object.entries(grouped)
        .map(([year, months]) => ({
            year,
            months: Object.entries(months)
                .sort(([a], [b]) => Number(b) - Number(a))
                .map(([month, refunds]) => ({
                    month: dayjs().month(Number(month)).format('MMMM'),
                    refunds,
                })),
        }))
        .sort(
            ({ year: a }, { year: b }) => Number(b) - Number(a)
        ) as GroupedRefunds[];
}

export { groupRefunds };
