import { Flex } from '@chakra-ui/react';
import { formatFileExtension, FileFormat } from 'core';

type FileFormatIconProps = {
    name?: string;
    type?: FileFormat;
};
export function FileFormatIcon({ name, type }: FileFormatIconProps) {
    if (!name && !type) return null;

    const SIZE = '40px';
    const format = formatFileExtension(name, type);

    return (
        <Flex
            bg={format?.color ?? 'black'}
            alignItems="center"
            justifyContent="center"
            color="white"
            fontWeight="bold"
            fontSize="xs"
            w={SIZE}
            h={SIZE}
            flexShrink={0}
            borderRadius="md">
            {format?.text}
        </Flex>
    );
}
