import React from 'react';
import { Box } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

import { useDoughnutContext } from '.';

type DoughnutArcProps = {
    height?: string | number;
    width?: string | number;
    bgColor?: string;
    type?: 'half' | 'full';
    icon?: React.ReactElement;
};

export const CIRCUMFERENCE = 100;
export const RADIUS = CIRCUMFERENCE / (Math.PI * 2);
export const POSITION = 21;

export function DoughnutArc({
    height,
    width,
    bgColor = 'white',
    type = 'half',
    icon,
}: DoughnutArcProps) {
    const { data, selected, setSelected, total } = useDoughnutContext();
    const uuid = uuidv4();
    const isHalf = type === 'half';
    const selectedData = data.find((_, idx) => selected === idx);
    const percentages = data.map(({ percentage }) =>
        isHalf ? percentage / 2 : percentage
    );

    function handleSegmentClick(idx: number): void {
        if (selected === idx) setSelected(null);
        else setSelected(idx);
    }

    return (
        <svg
            width={width}
            height={height}
            style={{ height: 'auto', flexShrink: 0 }}
            viewBox={isHalf ? '0 0 42 20' : '0 0 42 42'}>
            {isHalf && (
                <clipPath id={uuid}>
                    {/*
                        Remove 0.1 from POSITION to avoid a visual bug caused when the last segment of the Arc is selected. We can see the other half of the component, which should be hidden.
                    */}
                    <rect x="0" y="0" width="100" height={POSITION - 0.1} />
                </clipPath>
            )}

            <Box
                as="circle"
                cx={POSITION}
                cy={POSITION}
                r={RADIUS}
                fill="transparent"
                stroke="strokes.medium"
                strokeWidth="4"
                clipPath={isHalf ? `url(#${uuid})` : undefined}
            />

            {data.map(({ percentage, color, name, desc }, idx) => {
                const formattedPercentage = isHalf
                    ? percentage / 2
                    : percentage;
                const basePercentage =
                    (isHalf ? 50 : 100) - formattedPercentage;
                const precedingsSegmentValues = percentages
                    .slice(0, idx)
                    .reduce((prev, curr) => prev + curr, 0);
                // Circumference − All preceding segments’ total length + First segment’s offset = Current segment offset
                const halfOffset =
                    precedingsSegmentValues === 0
                        ? 0
                        : CIRCUMFERENCE - precedingsSegmentValues;
                const fullOffset = CIRCUMFERENCE - precedingsSegmentValues + 25;

                return (
                    <Box
                        as="circle"
                        key={name}
                        cx={POSITION}
                        cy={POSITION}
                        r={RADIUS}
                        clipPath={isHalf ? `url(#${uuid})` : undefined}
                        fill="transparent"
                        cursor="pointer"
                        transition="all 0.15s ease-in-out"
                        stroke={color}
                        strokeWidth={selected === idx ? 8 : 4}
                        strokeDasharray={`${formattedPercentage} ${basePercentage}`}
                        strokeDashoffset={isHalf ? halfOffset : fullOffset}
                        onClick={() => handleSegmentClick(idx)}>
                        <title>{name}</title>
                        {desc && <desc>{desc}</desc>}
                    </Box>
                );
            })}

            <Box
                as="circle"
                fill={bgColor}
                cx={POSITION}
                cy={POSITION}
                r={RADIUS}
            />

            {icon && (
                <g
                    transform={isHalf ? `translate(6 8)` : `translate(16 11)`}
                    color="black">
                    {React.cloneElement(icon, {
                        viewBox: isHalf ? '0 0 60 60' : '0 0 100 100',
                    })}
                </g>
            )}

            <g transform={isHalf ? `translate(22 19)` : `translate(21 26)`}>
                <Box
                    as="text"
                    fontSize="0.24em"
                    fontFamily="mono"
                    fontWeight="bold"
                    textAnchor="middle">
                    {selected === null ? total : `${selectedData?.percentage}%`}
                </Box>
            </g>
        </svg>
    );
}
