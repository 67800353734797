import { useEffect } from 'react';
import Cleave from 'cleave.js/react';
import { formatSocialSecurityNumber, setRef, useInputValidation } from 'core';
import InputBase from '../InputBase';
import type { WidgetProps } from './types';
import getInputName from 'core/lib/forms/helpers/getInputName';
import { omit } from 'lodash';
import { useWatch } from 'react-hook-form';

const LENGTH_OF_SS_NUMBER = 15;
const BLOCKS = [1, 2, 2, 2, 3, 3, 2];

function InputSocialSecurityNumber(props: Omit<WidgetProps, 'type'>) {
    const type = props.validations.allow0 ? 'ssnWith0' : 'ssn';
    const name = getInputName(props.id, props.name);
    const { registerValues, setValue } = useInputValidation(
        props.validations,
        name,
        { type }
    );
    const value = useWatch({
        name,
        defaultValue: formatSocialSecurityNumber.loose(props?.defaultValue),
    });

    useEffect(() => {
        setValue(name, formatSocialSecurityNumber.loose(props?.defaultValue));
    }, [setValue, name, props?.defaultValue, props.validations.allow0]);

    return (
        <InputBase
            {...registerValues}
            {...omit(props, 'defaultValue')}
            as={Cleave}
            value={value}
            maxLength={LENGTH_OF_SS_NUMBER + BLOCKS.length - 1}
            options={{
                blocks: BLOCKS,
                uppercase: true,
            }}
            htmlRef={(input: HTMLInputElement) =>
                setRef(registerValues.ref, input)
            }
        />
    );
}

export default InputSocialSecurityNumber;
