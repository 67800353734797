import axios from 'axios';
import { APIError, FetchQueryParams, WorkflowKeysEnum } from 'core';
import { useQuery } from '@tanstack/react-query';
import { TeleconsultationWorkflowEnum } from 'lib/teleconsultation';
import { WorkflowType } from '../types';

export const freeTimeSlotspathEnum = {
    [WorkflowKeysEnum.TELECONSULTATION]: `/api/proxy/europ-assistance/teleconsultations/specialities/[id]/free-time-slots`,
    [WorkflowKeysEnum.PREVENTION]: `/api/proxy/europ-assistance/preventions/themes/[id]/free-time-slots`,
};

export type Slot = {
    id: string;
    startAt: string;
    endAt: string;
};

async function fetchTeleconsultationFreeTimeSlots({
    queryKey: [url, id, workflow],
}: FetchQueryParams) {
    try {
        const isNotFastWorkflow =
            workflow !== TeleconsultationWorkflowEnum.APPOINTMENT;
        if (!id || isNotFastWorkflow) return [];
        const response = await axios.get(url.replace('[id]', id));
        return response.data.data;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useTeleconsultationFreeTimeSlots(
    specialityId: number | string,
    workflow: WorkflowType,
    pathType: keyof typeof freeTimeSlotspathEnum
) {
    return useQuery<Slot[], APIError>({
        queryKey: [freeTimeSlotspathEnum[pathType], specialityId, workflow],
        queryFn: fetchTeleconsultationFreeTimeSlots,
        gcTime: 0,
        staleTime: 0,
    });
}
