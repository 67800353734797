import { Icon, IconProps } from '@chakra-ui/react';

export default function ArobaseIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M21.944 13.069C21.41 18.089 17.162 22 12 22 6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c0 .144-.003.287-.009.429H22c0 .217-.02.431-.056.64zM20 12c0 .264-.013.525-.038.783-.067.309-.22.578-.428.784l-.008.007A1.47 1.47 0 0 1 18.5 14a1.47 1.47 0 0 1-1.034-.433l-.017-.017A1.593 1.593 0 0 1 17 12.429V8a1 1 0 0 0-1-1h-1v1a5 5 0 1 0 1.03 6.96 3.443 3.443 0 0 0 2.916 1.011A8 8 0 1 1 20 12zm-5 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
