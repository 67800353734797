import { defineMessages } from 'react-intl';

export const beneficiariesStatusMessages = defineMessages({
    main: {
        id: 'beneficiary.status.main',
        defaultMessage:
            '{gender, select, male {Adhérent} female {Adhérente} other {Adhérent} }',
        description: 'Statut du beneficiaire : principale',
    },
    partner: {
        id: 'beneficiary.status.partner',
        defaultMessage:
            '{gender, select, male {Conjoint} female {Conjointe} other {Conjoint} }',
        description: 'Statut du beneficiaire : Conjoint',
    },
    child: {
        id: 'beneficiary.status.child',
        defaultMessage: 'Enfant',
        description: 'Statut du beneficiaire : Enfant',
    },
    ascendant: {
        id: 'beneficiary.status.ascendant',
        defaultMessage: 'Ascendant',
        description: 'Statut du beneficiaire : Ascendant',
    },
    other: {
        id: 'beneficiary.status.other',
        defaultMessage: 'Autre',
        description: 'Statut du beneficiaire : Autre',
    },
});

export const connectionStatusMessages = defineMessages({
    true: {
        id: 'social-security.teletransmission-status.active',
        defaultMessage: 'Télétransmission active',
        description: 'Statut de télétransmission : active',
    },
    false: {
        id: 'social-security.teletransmission-status.inactive',
        defaultMessage: 'Télétransmission inactive',
        description: 'Statut de télétransmission : inactive',
    },
});

export const registrationStatusMessages = defineMessages({
    deregistered: {
        id: 'beneficiary.registration-status.deregistered',
        defaultMessage:
            '{gender, select, male {Radié} female {Radié} other {Radié}}',
        description: 'Statut de rattachement : Radié',
    },
    registration_in_progress: {
        id: 'beneficiary.registration-status.registration-in-progress',
        defaultMessage: 'Demande de rattachement en cours',
        description:
            'Statut de rattachement : Demande de rattachement en cours',
    },
    deregistration_in_progress: {
        id: 'beneficiary.registration-status.deregistration-in-progress',
        defaultMessage: 'Demande de radiation en cours',
        description: 'Statut de rattachement : Demande de radiation en cours',
    },
    interrupted: {
        id: 'beneficiary.registration-status.interrupted',
        defaultMessage: 'Suspendu',
        description: 'Statut de rattachement : Suspendu',
    },
});

export const socialSecurityConnection = defineMessages({
    main: {
        id: 'social-security.connection-status.main',
        defaultMessage:
            "{gender, select, female {Rattachée} other {Rattaché}} à {connectedSocialMainGender, select, female {l'adhérente} other {l'adhérent}}",
        description: 'Rattachement sécurité sociale : Rattaché à l’assuré',
    },
    partner: {
        id: 'social-security.connection-status.partner',
        defaultMessage:
            '{gender, select, female {Rattachée} other {Rattaché}} {connectedSocialPartnetGender, select, female {à la conjointe} other {au conjoint}}',
        description: 'Rattachement sécurité sociale : Rattaché au conjoint',
    },
    main_partner: {
        id: 'social-security.connection-status.main-partner',
        defaultMessage:
            "{gender, select, female {Rattachée} other {Rattaché}} à {connectedSocialMainGender, select, female {l'adhérente} other {l'adhérent}} et {connectedSocialPartnetGender, select, female {à la conjointe} other {au conjoint}}",
        description:
            "Rattachement sécurité sociale : Rattaché à l'adhérent et au conjoint",
    },
});

export const refundRibConnection = defineMessages({
    main: {
        id: 'bank-details.connection-status.main',
        defaultMessage:
            "{gender, select, female {Rattachée} other {Rattaché}} au RIB de {connectedRibGender, select, female {l'adhérente} other {l'adhérent}}",
        description: 'Rattachement : Rattaché au RIB de l’assuré',
    },
    partner: {
        id: 'bank-details.connection-status.partner',
        defaultMessage:
            '{gender, select, female {Rattachée} other {Rattaché}} au RIB {connectedRibGender, select, female {de la conjointe} other {du conjoint}}',
        description: 'Rattachement : Rattaché au RIB du conjoint',
    },
});

export const statusBeneficiaryTarget = defineMessages({
    main: {
        id: 'beneficiary.for-status.main',
        defaultMessage: 'pour vous',
    },
    partner: {
        id: 'beneficiary.for-status.partner',
        defaultMessage: 'pour votre conjoint',
    },
    children: {
        id: 'beneficiary.for-status.children',
        defaultMessage: 'pour vos enfants',
    },
    ascendant: {
        id: 'beneficiary.for-status.ascendant',
        defaultMessage: 'pour vos ascendants',
    },
    other: {
        id: 'beneficiary.for-status.other',
        defaultMessage: 'pour les autres bénéficiaires',
    },
});
