import React, { useContext } from 'react';
import axios, { AxiosHeaders } from 'axios';
import { LanguageContext } from 'core';
import useNativeInfos from './useNativeInfos';
import { MessageDescriptor } from 'react-intl';

export type UseInterceptorObject = {
    route: string;
    status: number | null;
    message?: MessageDescriptor;
};

type UseErrorInterceptor = () => {
    onError: (err: unknown) => void;
    state: UseInterceptorObject;
};

function useInterceptor(useErrorInterceptor: UseErrorInterceptor) {
    const error = useErrorInterceptor();
    const { locale } = useContext(LanguageContext);
    const nativeInfos = useNativeInfos();

    React.useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            undefined,
            (err) => {
                error.onError(err);
                throw err;
            }
        );

        return () => axios.interceptors.response.eject(interceptor);
    }, [error]);

    React.useEffect(() => {
        const interceptor = axios.interceptors.request.use((config) => {
            config.headers = new AxiosHeaders({
                ...config.headers,
                ...nativeInfos,
                'Accept-Language': locale,
            });

            return config;
        }, undefined);

        return () => axios.interceptors.request.eject(interceptor);
    }, [locale, nativeInfos]);

    return { error: error.state };
}

export { useInterceptor };
