import React, { ComponentType } from 'react';
import { useRouter } from 'next/router';
import {
    MessageDescriptor,
    FormattedMessage,
    defineMessages,
} from 'react-intl';
import {
    Stack,
    Button,
    SimpleGrid,
    useDisclosure,
    Text,
} from '@chakra-ui/react';
import SignatureError from '../../components/signature/SignatureError';
import { LightbulbIcon } from 'design-system/icons';
import {
    GlobalLayoutType,
    PEWorkflowTypesEnum,
    LayoutBoundaryType,
    HeaderBoxType,
    HeaderSecondaryType,
    RelatedActionsCategories,
    WorkflowTypesEnum,
    useBackBreadcrumbWithOnClick,
} from '../../lib/shared';
import { signatureSubtitles, signatureMessages } from '../../lib/signature';
import CardBeneficiaryOption, {
    CapitalTypesEnum,
    handleNextStepFnType,
} from './CardBeneficiaryOption';
import { useWindowBreakpoints } from 'design-system/hooks';
import AdvicesModal from './AdvicesModal';

const intlMessages = defineMessages({
    description: {
        id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.distribution-capital.description',
        defaultMessage:
            'Vous pouvez désigner un seul bénéficiaire principal qui recevra 100% de votre capital, avec éventuellement des bénéficiaires successifs à défaut, ou répartir votre capital entre plusieurs bénéficiaires dans les proportions que vous souhaitez.',
    },
    adviceButton: {
        id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.advice.button',
        defaultMessage: 'Conseils pour votre désignation',
    },
    iWish: {
        id: 'components.i-wish',
        defaultMessage: 'Je souhaite :',
    },
});

type GlobalLayoutProps = GlobalLayoutType & {
    footerSticky?: boolean;
    disableFooter?: boolean;
    type?: PEWorkflowTypesEnum;
};

type LayoutBoundaryProps = LayoutBoundaryType &
    React.PropsWithChildren<{
        EmptyComponent?: React.ComponentType<{
            message?: MessageDescriptor;
        }>;
    }>;

type HeaderBoxProps = HeaderBoxType;

type HeaderSecondaryProps = HeaderSecondaryType & {
    breadcrumbsIconColor?: string;
} & React.PropsWithChildren<{
        relatedActions?: RelatedActionsCategories;
    }>;

type CapitalAllocationProps = {
    project: WorkflowTypesEnum;
    GlobalLayout: ComponentType<GlobalLayoutProps>;
    LayoutBoundary: ComponentType<LayoutBoundaryProps>;
    HeaderBox: ComponentType<HeaderBoxProps>;
    HeaderSecondary: ComponentType<HeaderSecondaryProps>;
    handleNextStep: handleNextStepFnType;
    showAdvicesModal: boolean;
    workflowSteps: string[];
};

export function CapitalAllocation({
    project,
    GlobalLayout,
    LayoutBoundary,
    HeaderBox,
    HeaderSecondary,
    handleNextStep,
    showAdvicesModal,
    workflowSteps,
}: CapitalAllocationProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const router = useRouter();
    const backBreadcrumbWithOnClick = useBackBreadcrumbWithOnClick();
    const { isMobile } = useWindowBreakpoints();
    const workflow = router.query?.workflow as string;
    const [redirectError, setRedirectError] = React.useState<number>();

    return (
        <GlobalLayout
            title={signatureMessages.beneficiariesDesignation}
            py={isMobile ? '12' : '0'}
            margin="auto"
            minHeight={isMobile && '100vh'}
            display="flex"
            flexDirection="column"
            {...(project === WorkflowTypesEnum.PE && {
                type: PEWorkflowTypesEnum.BD,
            })}>
            <HeaderBox pt={isMobile ? '0' : '8'}>
                <HeaderSecondary
                    breadcrumbs={[backBreadcrumbWithOnClick]}
                    mb={isMobile ? '0' : '3'}
                    subtitle={
                        <Text
                            as="h2"
                            color="primary.main"
                            fontFamily="mono"
                            fontWeight="semibold"
                            fontSize="2xl">
                            <FormattedMessage
                                {...signatureSubtitles['capital-repartition']}
                            />
                        </Text>
                    }
                    progressBar={{
                        currentStep:
                            Object.values(workflowSteps).indexOf(
                                project === WorkflowTypesEnum.PA
                                    ? 'capital-repartition'
                                    : 'identity-form'
                            ) + 1,
                        maxStep: workflowSteps.length,
                        ...(project === WorkflowTypesEnum.PE && {
                            color: 'grey.600',
                        }),
                    }}>
                    <FormattedMessage {...signatureMessages[workflow]} />
                </HeaderSecondary>
            </HeaderBox>
            <LayoutBoundary
                status={redirectError ? 'error' : 'success'}
                pt={isMobile ? '6' : '3'}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                flex="auto"
                ErrorComponent={() => (
                    <SignatureError
                        errorCode={redirectError}
                        step="capital-allocation-type-selection"
                        service={project}
                    />
                )}>
                <Stack spacing="2" gap="4">
                    <FormattedMessage {...intlMessages.description} />

                    {showAdvicesModal && (
                        <Button
                            color="black"
                            w="full"
                            leftIcon={<LightbulbIcon w="4" h="4" />}
                            placeContent="center"
                            onClick={onOpen}>
                            <FormattedMessage {...intlMessages.adviceButton} />
                        </Button>
                    )}

                    <Stack spacing="3">
                        <Text fontWeight="bold" fontSize="md">
                            <FormattedMessage {...intlMessages.iWish} />
                        </Text>
                        <SimpleGrid columns={2} spacing="3">
                            <CardBeneficiaryOption
                                project={project}
                                type={CapitalTypesEnum.FULL}
                                onError={setRedirectError}
                                handleNextStep={handleNextStep}
                            />
                            <CardBeneficiaryOption
                                project={project}
                                type={CapitalTypesEnum.DIVIDED}
                                onError={setRedirectError}
                                handleNextStep={handleNextStep}
                            />
                        </SimpleGrid>
                    </Stack>
                    {isOpen && (
                        <AdvicesModal isOpen={isOpen} onClose={onClose} />
                    )}
                </Stack>
            </LayoutBoundary>
        </GlobalLayout>
    );
}
