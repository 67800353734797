import type { ArrowIndicatorProps } from './ArrowIndicator';

function setArrowIndicatorStateByTrend(
    trend: number
): ArrowIndicatorProps['state'] {
    if (trend > 0) return 'up';
    if (trend < 0) return 'down';
    return 'equal';
}

export { setArrowIndicatorStateByTrend };
