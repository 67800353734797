import { Icon, IconProps } from '@chakra-ui/react';

export default function AttachementIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M20.398 3.6a5.43 5.43 0 0 0-7.7 0l-1 1.05a1 1 0 0 0 1.41 1.41L14.118 5a3.53 3.53 0 0 1 4.88 0 3.44 3.44 0 0 1 0 4.87L9.878 19a3.53 3.53 0 0 1-4.88 0 3.44 3.44 0 0 1 0-4.87l1.05-1.06 3.62-3.61a1.16 1.16 0 0 1 1.62 0 1.15 1.15 0 0 1 0 1.61l-3.62 3.62a1 1 0 1 0 1.42 1.41l3.61-3.61a3.15 3.15 0 0 0 0-4.45 3.22 3.22 0 0 0-4.45 0l-3.6 3.61-1.05 1a5.445 5.445 0 0 0 7.7 7.7l9.1-9.1a5.43 5.43 0 0 0 1.185-5.934c-.275-.66-.677-1.21-1.185-1.715z"
                fill="currentColor"
            />
        </Icon>
    );
}
