import type { FormValues } from 'core';
import { Dictionary, groupBy } from 'lodash';
import { formatFormFinalObject } from './handlers';

function transformMultipleFileUpload(inputId: string, data: FormValues) {
    const fileData = Object.entries(data).map(([id, content], index) => {
        const fileCategory = id.replace(/(file)|[0-9]/g, '');
        const parsedContent = JSON.parse(content);
        delete parsedContent.size;

        return {
            [`file${index + 1}`]: {
                content: parsedContent.content
                    .replace('data:', '')
                    .replace(/^.+,/, ''),
                name: parsedContent.name,
                category: fileCategory,
            },
        };
    });

    const groupedData: Dictionary<any> = groupBy(fileData, (file) => {
        const fileCategory = file[Object.keys(file)[0]].category;
        delete file.category;
        return fileCategory;
    });

    const parsedData = {
        fileCategoryValues: Object.keys(groupedData).reduce((aggr, value) => {
            return [
                ...aggr,
                groupedData[value].reduce((groupedAggr, file, index) => {
                    const fileData = file[Object.keys(file)[0]];
                    return {
                        ...groupedAggr,
                        id: value,
                        [`file${index + 1}`]: {
                            content: fileData.content,
                            name: fileData.name,
                        },
                    };
                }, {}),
            ];
        }, []),
    };

    return formatFormFinalObject(inputId, parsedData);
}

export default transformMultipleFileUpload;
