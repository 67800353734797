import { Text } from '@chakra-ui/react';
import { SafeFormattedMessage } from 'core';
import { MessageDescriptor } from 'react-intl';

type Props = React.PropsWithChildren<{
    text: MessageDescriptor;
}>;

export function FormFooter({ text }: Props) {
    return (
        <Text fontSize="sm" color="texts.medium" mt="12">
            <SafeFormattedMessage
                {...text}
                debugKey={`Footer Text: ${text?.id}`}
            />
        </Text>
    );
}
