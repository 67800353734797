/* eslint-disable import/no-unused-modules */
import { useTheme } from '@chakra-ui/react';

function SentConfirmationModalIcon() {
    const theme = useTheme();
    const colors = theme.colors;

    return (
        <svg
            width="245"
            height="220"
            viewBox="0 0 245 220"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M60.503 55.807C56.3041 43.2554 43.3373 36.2794 34.8071 39.133C26.2768 41.9867 20.1179 55.3609 24.3168 67.9125C28.2034 79.5306 39.9805 86.2377 48.0822 85.0408C48.0238 85.1424 47.9853 85.2542 47.9689 85.3703L47.7211 87.4365C47.7023 87.594 47.724 87.7537 47.7842 87.9005C47.8444 88.0472 47.9411 88.1762 48.0651 88.2751C48.1892 88.3739 48.3365 88.4394 48.493 88.4654C48.6495 88.4913 48.81 88.4769 48.9593 88.4233L53.1327 86.9269C53.282 86.8734 53.4152 86.7825 53.5195 86.6631C53.6239 86.5436 53.696 86.3994 53.7289 86.2442C53.7619 86.089 53.7547 85.928 53.7079 85.7764C53.6611 85.6248 53.5764 85.4877 53.4617 85.3781L51.9574 83.9401C51.9039 83.8912 51.8448 83.8487 51.7814 83.8136C59.0074 79.9195 64.3981 67.4504 60.503 55.807Z"
                    fill="#F2F2F2"
                />
                <path
                    d="M45.3022 46.5724C48.7893 48.3721 51.4523 51.4418 52.7416 55.148C54.0309 58.8542 53.848 62.9139 52.2308 66.4892C51.8697 67.3112 53.1977 67.7436 53.5564 66.9269C55.2544 63.0584 55.4271 58.691 54.0399 54.7005C52.6527 50.7101 49.8081 47.3915 46.0767 45.4105C45.2891 44.9932 44.5088 46.152 45.3022 46.5724Z"
                    fill="white"
                />
                <path
                    d="M230.502 37.5111C226.304 24.9595 213.337 17.9835 204.807 20.8371C196.276 23.6908 190.117 37.065 194.316 49.6166C198.203 61.2347 209.98 67.9418 218.082 66.7449C218.023 66.8465 217.985 66.9583 217.968 67.0744L217.721 69.1406C217.702 69.2981 217.723 69.4578 217.784 69.6046C217.844 69.7513 217.941 69.8803 218.065 69.9792C218.189 70.078 218.336 70.1435 218.492 70.1695C218.649 70.1954 218.809 70.181 218.959 70.1274L223.132 68.631C223.281 68.5775 223.415 68.4866 223.519 68.3672C223.623 68.2477 223.695 68.1035 223.728 67.9483C223.761 67.7931 223.754 67.6321 223.707 67.4805C223.661 67.3289 223.576 67.1918 223.461 67.0822L221.957 65.6442C221.903 65.5953 221.844 65.5528 221.781 65.5177C229.007 61.6236 234.398 49.1545 230.502 37.5111Z"
                    fill="#F2F2F2"
                />
                <path
                    d="M215.302 28.2765C218.789 30.0762 221.452 33.1459 222.741 36.8521C224.03 40.5582 223.848 44.618 222.23 48.1933C221.869 49.0153 223.197 49.4476 223.556 48.631C225.254 44.7625 225.427 40.3951 224.039 36.4046C222.652 32.4141 219.808 29.0956 216.076 27.1146C215.289 26.6973 214.508 27.8561 215.302 28.2765Z"
                    fill="white"
                />
                <path
                    d="M131.781 17.309C127.582 4.75735 114.615 -2.21866 106.085 0.635001C97.5547 3.48866 91.3957 16.8628 95.5946 29.4144C99.4813 41.0325 111.258 47.7396 119.36 46.5427C119.302 46.6443 119.263 46.7562 119.247 46.8722L118.999 48.9384C118.98 49.0959 119.002 49.2556 119.062 49.4024C119.122 49.5492 119.219 49.6781 119.343 49.777C119.467 49.8759 119.614 49.9414 119.771 49.9673C119.927 49.9933 120.088 49.9788 120.237 49.9252L124.41 48.4288C124.56 48.3753 124.693 48.2845 124.797 48.165C124.902 48.0455 124.974 47.9013 125.007 47.7461C125.04 47.591 125.033 47.4299 124.986 47.2783C124.939 47.1268 124.854 46.9896 124.74 46.88L123.235 45.442C123.182 45.3931 123.123 45.3506 123.059 45.3155C130.285 41.4214 135.676 28.9523 131.781 17.309Z"
                    fill="#F2F2F2"
                />
                <path
                    d="M116.58 8.07438C120.067 9.87401 122.73 12.9437 124.019 16.6499C125.309 20.3561 125.126 24.4158 123.509 27.9911C123.148 28.8132 124.476 29.2455 124.834 28.4288C126.532 24.5603 126.705 20.1929 125.318 16.2025C123.931 12.212 121.086 8.8935 117.355 6.91248C116.567 6.49513 115.787 7.65393 116.58 8.07438Z"
                    fill="white"
                />
                <path
                    d="M110.722 77.7048C111.274 77.7048 111.722 77.2569 111.722 76.7044C111.722 76.152 111.274 75.7041 110.722 75.7041C110.169 75.7041 109.721 76.152 109.721 76.7044C109.721 77.2569 110.169 77.7048 110.722 77.7048Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M108.589 117.833C109.681 117.833 110.566 116.948 110.566 115.857C110.566 114.766 109.681 113.881 108.589 113.881C107.498 113.881 106.613 114.766 106.613 115.857C106.613 116.948 107.498 117.833 108.589 117.833Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M75.5062 110.224C76.1368 110.224 76.6481 109.713 76.6481 109.082C76.6481 108.452 76.1368 107.94 75.5062 107.94C74.8755 107.94 74.3643 108.452 74.3643 109.082C74.3643 109.713 74.8755 110.224 75.5062 110.224Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M92.8538 91.527C93.5368 91.527 94.0904 90.9733 94.0904 90.2903C94.0904 89.6074 93.5368 89.0537 92.8538 89.0537C92.1708 89.0537 91.6172 89.6074 91.6172 90.2903C91.6172 90.9733 92.1708 91.527 92.8538 91.527Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M153.705 163.927C154.257 163.927 154.705 163.48 154.705 162.927C154.705 162.375 154.257 161.927 153.705 161.927C153.152 161.927 152.705 162.375 152.705 162.927C152.705 163.48 153.152 163.927 153.705 163.927Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M120.944 143.359C122.035 143.359 122.92 142.474 122.92 141.382C122.92 140.291 122.035 139.406 120.944 139.406C119.853 139.406 118.968 140.291 118.968 141.382C118.968 142.474 119.853 143.359 120.944 143.359Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M143.447 117.344C144.078 117.344 144.589 116.833 144.589 116.202C144.589 115.572 144.078 115.061 143.447 115.061C142.816 115.061 142.305 115.572 142.305 116.202C142.305 116.833 142.816 117.344 143.447 117.344Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M150.956 141.887C151.639 141.887 152.193 141.334 152.193 140.651C152.193 139.968 151.639 139.414 150.956 139.414C150.273 139.414 149.72 139.968 149.72 140.651C149.72 141.334 150.273 141.887 150.956 141.887Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M217.564 205.578C218.655 204.664 217.18 201.106 214.27 197.63C211.36 194.155 208.117 192.078 207.025 192.991C205.934 193.905 207.409 197.463 210.319 200.939C213.229 204.414 216.473 206.491 217.564 205.578Z"
                    fill="#2F2E41"
                />
                <path
                    d="M194.835 212.85C203.901 212.85 211.251 205.5 211.251 196.434C211.251 187.368 203.901 180.019 194.835 180.019C185.769 180.019 178.419 187.368 178.419 196.434C178.419 205.5 185.769 212.85 194.835 212.85Z"
                    fill="#2F2E41"
                />
                <path
                    d="M201.783 209.317H196.796V218.253H201.783V209.317Z"
                    fill="#2F2E41"
                />
                <path
                    d="M191.809 209.317H186.822V218.253H191.809V209.317Z"
                    fill="#2F2E41"
                />
                <path
                    d="M197.627 219.915C199.922 219.915 201.783 219.217 201.783 218.356C201.783 217.496 199.922 216.798 197.627 216.798C195.332 216.798 193.471 217.496 193.471 218.356C193.471 219.217 195.332 219.915 197.627 219.915Z"
                    fill="#2F2E41"
                />
                <path
                    d="M187.653 219.707C189.948 219.707 191.809 219.009 191.809 218.148C191.809 217.288 189.948 216.59 187.653 216.59C185.358 216.59 183.497 217.288 183.497 218.148C183.497 219.009 185.358 219.707 187.653 219.707Z"
                    fill="#2F2E41"
                />
                <path
                    d="M189.68 175.8C191.146 169.896 197.616 166.423 204.132 168.041C210.648 169.658 214.742 175.755 213.276 181.659C211.81 187.562 206.947 187.581 200.431 185.963C193.916 184.345 188.215 181.703 189.68 175.8Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M183.761 192.334C184.371 191.049 181.545 188.432 177.449 186.489C173.353 184.546 169.539 184.014 168.929 185.3C168.319 186.586 171.145 189.203 175.241 191.145C179.337 193.088 183.151 193.62 183.761 192.334Z"
                    fill="#2F2E41"
                />
                <path
                    d="M192.38 198.443C195.403 198.443 197.853 195.993 197.853 192.97C197.853 189.947 195.403 187.497 192.38 187.497C189.358 187.497 186.907 189.947 186.907 192.97C186.907 195.993 189.358 198.443 192.38 198.443Z"
                    fill="white"
                />
                <path
                    d="M190.131 192.833C191.139 192.833 191.955 192.017 191.955 191.009C191.955 190.001 191.139 189.185 190.131 189.185C189.123 189.185 188.307 190.001 188.307 191.009C188.307 192.017 189.123 192.833 190.131 192.833Z"
                    fill="#3F3D56"
                />
                <path
                    d="M195.814 204.373C195.899 204.845 195.892 205.328 195.79 205.797C195.689 206.265 195.497 206.709 195.224 207.103C194.952 207.497 194.604 207.833 194.201 208.093C193.799 208.353 193.349 208.531 192.877 208.616C192.406 208.702 191.922 208.694 191.454 208.593C190.986 208.492 190.542 208.3 190.148 208.027C189.754 207.754 189.417 207.407 189.158 207.004C188.898 206.601 188.72 206.152 188.634 205.68L188.633 205.673C188.274 203.69 189.807 202.988 191.79 202.63C193.773 202.271 195.455 202.39 195.814 204.373Z"
                    fill="white"
                />
                <path
                    d="M59.8736 184.958C61.492 180.724 61.7264 176.879 60.3972 176.371C59.0679 175.863 56.6783 178.884 55.0598 183.118C53.4414 187.353 53.207 191.197 54.5363 191.705C55.8655 192.213 58.2551 189.192 59.8736 184.958Z"
                    fill="#2F2E41"
                />
                <path
                    d="M42.2937 212.85C51.3598 212.85 58.7094 205.5 58.7094 196.434C58.7094 187.368 51.3598 180.019 42.2937 180.019C33.2275 180.019 25.8779 187.368 25.8779 196.434C25.8779 205.5 33.2275 212.85 42.2937 212.85Z"
                    fill="#2F2E41"
                />
                <path
                    d="M39.8 209.317H34.813V218.253H39.8V209.317Z"
                    fill="#2F2E41"
                />
                <path
                    d="M49.7742 209.317H44.7871V218.253H49.7742V209.317Z"
                    fill="#2F2E41"
                />
                <path
                    d="M38.9689 219.915C41.2641 219.915 43.1248 219.217 43.1248 218.356C43.1248 217.496 41.2641 216.798 38.9689 216.798C36.6736 216.798 34.813 217.496 34.813 218.356C34.813 219.217 36.6736 219.915 38.9689 219.915Z"
                    fill="#2F2E41"
                />
                <path
                    d="M48.943 219.707C51.2382 219.707 53.0989 219.009 53.0989 218.148C53.0989 217.288 51.2382 216.59 48.943 216.59C46.6478 216.59 44.7871 217.288 44.7871 218.148C44.7871 219.009 46.6478 219.707 48.943 219.707Z"
                    fill="#2F2E41"
                />
                <path
                    d="M42.7091 197.889C45.8076 197.889 48.3195 195.377 48.3195 192.278C48.3195 189.18 45.8076 186.668 42.7091 186.668C39.6105 186.668 37.0986 189.18 37.0986 192.278C37.0986 195.377 39.6105 197.889 42.7091 197.889Z"
                    fill="white"
                />
                <path
                    d="M42.7095 194.149C43.7424 194.149 44.5797 193.311 44.5797 192.278C44.5797 191.246 43.7424 190.408 42.7095 190.408C41.6767 190.408 40.8394 191.246 40.8394 192.278C40.8394 193.311 41.6767 194.149 42.7095 194.149Z"
                    fill="#3F3D56"
                />
                <path
                    d="M26.3696 181.168C25.0441 175.232 29.2812 169.234 35.8334 167.771C42.3856 166.307 48.7717 169.934 50.0972 175.87C51.4227 181.806 47.0804 183.995 40.5282 185.458C33.976 186.921 27.6951 187.104 26.3696 181.168Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M31.22 192.334C31.8298 191.049 29.0039 188.432 24.9081 186.489C20.8124 184.546 16.9977 184.014 16.3879 185.3C15.7781 186.586 18.604 189.203 22.6997 191.145C26.7955 193.088 30.6102 193.62 31.22 192.334Z"
                    fill="#2F2E41"
                />
                <path
                    d="M34.2305 202.356C34.2305 203.963 38.3674 207.135 42.9578 207.135C47.5483 207.135 51.8524 202.612 51.8524 201.006C51.8524 199.399 47.5483 201.317 42.9578 201.317C38.3674 201.317 34.2305 200.75 34.2305 202.356Z"
                    fill="white"
                />
                <path
                    d="M120.707 197.222C129.773 197.222 137.122 189.873 137.122 180.806C137.122 171.74 129.773 164.391 120.707 164.391C111.641 164.391 104.291 171.74 104.291 180.806C104.291 189.873 111.641 197.222 120.707 197.222Z"
                    fill="#2F2E41"
                />
                <path
                    d="M118.213 193.689H113.226V202.625H118.213V193.689Z"
                    fill="#2F2E41"
                />
                <path
                    d="M128.187 193.689H123.2V202.625H128.187V193.689Z"
                    fill="#2F2E41"
                />
                <path
                    d="M117.382 204.287C119.677 204.287 121.538 203.589 121.538 202.728C121.538 201.868 119.677 201.17 117.382 201.17C115.087 201.17 113.226 201.868 113.226 202.728C113.226 203.589 115.087 204.287 117.382 204.287Z"
                    fill="#2F2E41"
                />
                <path
                    d="M127.356 204.08C129.651 204.08 131.512 203.382 131.512 202.521C131.512 201.661 129.651 200.963 127.356 200.963C125.061 200.963 123.2 201.661 123.2 202.521C123.2 203.382 125.061 204.08 127.356 204.08Z"
                    fill="#2F2E41"
                />
                <path
                    d="M104.783 165.54C103.457 159.604 107.694 153.606 114.247 152.143C120.799 150.68 127.185 154.306 128.51 160.242C129.836 166.178 125.493 168.367 118.941 169.831C112.389 171.294 106.108 171.476 104.783 165.54Z"
                    fill={colors.primary.main}
                />
                <path
                    d="M138.396 173.633C141.951 170.075 143.928 166.286 142.811 165.17C141.694 164.054 137.907 166.033 134.352 169.591C130.797 173.149 128.82 176.938 129.937 178.054C131.054 179.17 134.841 177.191 138.396 173.633Z"
                    fill="#2F2E41"
                />
                <path
                    d="M107.475 179.198C108.592 178.082 106.615 174.293 103.06 170.735C99.5048 167.178 95.7174 165.198 94.6005 166.314C93.4836 167.43 95.4601 171.219 99.0152 174.777C102.57 178.335 106.358 180.314 107.475 179.198Z"
                    fill="#2F2E41"
                />
                <path
                    d="M220.506 76.6218C219.962 59.7897 208.433 46.5035 194.757 46.9463C164.32 47.8482 163.577 103.664 192.963 107.673L192.341 112.619L201.416 112.325L200.476 107.43C212.297 104.817 221.001 91.8821 220.506 76.6218Z"
                    fill={colors.secondary.main}
                />
                <path
                    d="M179.621 85.9546L176.192 86.0656C174.848 79.6758 174.635 73.0998 175.563 66.6364L178.992 66.5254C177.726 73.2912 177.935 79.7676 179.621 85.9546Z"
                    fill="white"
                />
                <path
                    d="M201.861 107.354L191.575 107.687L191.649 109.972L201.935 109.639L201.861 107.354Z"
                    fill="#3F3D56"
                />
                <path
                    d="M171.984 194.879L170.949 194.394L196.415 108.033L197.451 108.518L171.984 194.879Z"
                    fill="#3F3D56"
                />
                <path
                    d="M241.584 90.8561C247.803 75.2056 242.536 58.422 229.82 53.3689C201.553 42.0463 178.591 92.9259 203.934 108.332L201.39 112.618L209.829 115.972L210.92 111.108C222.802 113.431 235.945 105.045 241.584 90.8561Z"
                    fill={colors.primary.main}
                />
                <path
                    d="M200.372 83.0919L197.184 81.825C198.502 75.4298 200.932 69.3155 204.363 63.7598L207.551 65.0266C203.689 70.7245 201.296 76.7462 200.372 83.0919Z"
                    fill="white"
                />
                <path
                    d="M202.657 107.79L201.812 109.915L211.377 113.716L212.221 111.59L202.657 107.79Z"
                    fill="#3F3D56"
                />
                <path
                    d="M171.783 192.606L170.79 192.04L206.344 110.902L207.338 111.468L171.783 192.606Z"
                    fill="#3F3D56"
                />
                <path
                    d="M48.3287 77.7099C41.6 62.2716 25.9767 54.1883 13.4331 59.6553C-14.5145 71.7451 5.42831 123.882 34.215 116.744L35.4652 121.569L43.7895 117.941L41.1057 113.741C51.1238 106.943 54.429 91.7065 48.3287 77.7099Z"
                    fill={colors.primary.main}
                />
                <path
                    d="M13.7896 101.495L10.6448 102.866C7.03383 97.4254 4.40504 91.394 2.87793 85.0454L6.0227 83.6748C7.34714 90.4294 9.9361 96.3695 13.7896 101.495Z"
                    fill="white"
                />
                <path
                    d="M42.3645 113.157L32.9302 117.269L33.8439 119.365L43.2782 115.253L42.3645 113.157Z"
                    fill="#3F3D56"
                />
                <path
                    d="M64.1923 195.1C64.0451 195.136 63.8895 195.113 63.7596 195.034C63.6298 194.956 63.5363 194.83 63.4998 194.682L38.645 115.928L39.7548 115.652L64.6096 194.407C64.6276 194.48 64.6312 194.556 64.62 194.63C64.6088 194.704 64.5831 194.775 64.5444 194.84C64.5056 194.904 64.4546 194.96 64.3942 195.005C64.3337 195.049 64.2651 195.082 64.1923 195.1Z"
                    fill="#3F3D56"
                />
                <path
                    d="M70.8353 220H10.9925C10.8914 220 10.7945 219.959 10.723 219.888C10.6515 219.817 10.6113 219.72 10.6113 219.618C10.6113 219.517 10.6515 219.42 10.723 219.349C10.7945 219.277 10.8914 219.237 10.9925 219.237H70.8353C70.9364 219.237 71.0334 219.277 71.1048 219.349C71.1763 219.42 71.2165 219.517 71.2165 219.618C71.2165 219.72 71.1763 219.817 71.1048 219.888C71.0334 219.959 70.9364 220 70.8353 220Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M222.92 220H163.077C162.976 220 162.879 219.959 162.807 219.888C162.736 219.817 162.696 219.72 162.696 219.618C162.696 219.517 162.736 219.42 162.807 219.349C162.879 219.277 162.976 219.237 163.077 219.237H222.92C223.021 219.237 223.118 219.277 223.189 219.349C223.261 219.42 223.301 219.517 223.301 219.618C223.301 219.72 223.261 219.817 223.189 219.888C223.118 219.959 223.021 220 222.92 220Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M151.642 204.373H91.7991C91.698 204.373 91.6011 204.333 91.5296 204.261C91.4581 204.19 91.418 204.093 91.418 203.992C91.418 203.89 91.4581 203.793 91.5296 203.722C91.6011 203.651 91.698 203.61 91.7991 203.61H151.642C151.743 203.61 151.84 203.651 151.911 203.722C151.983 203.793 152.023 203.89 152.023 203.992C152.023 204.093 151.983 204.19 151.911 204.261C151.84 204.333 151.743 204.373 151.642 204.373Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M120.016 184.09C123.114 184.09 125.626 181.578 125.626 178.48C125.626 175.381 123.114 172.869 120.016 172.869C116.917 172.869 114.405 175.381 114.405 178.48C114.405 181.578 116.917 184.09 120.016 184.09Z"
                    fill="white"
                />
                <path
                    d="M120.016 180.351C121.049 180.351 121.886 179.513 121.886 178.481C121.886 177.448 121.049 176.61 120.016 176.61C118.983 176.61 118.146 177.448 118.146 178.481C118.146 179.513 118.983 180.351 120.016 180.351Z"
                    fill="#3F3D56"
                />
                <path
                    d="M124.47 188.857C124.643 189.809 124.431 190.79 123.88 191.586C123.33 192.382 122.486 192.926 121.534 193.1C120.582 193.273 119.6 193.061 118.804 192.51C118.008 191.96 117.464 191.115 117.29 190.163L117.289 190.156C116.93 188.174 118.463 187.472 120.446 187.113C122.429 186.754 124.111 186.874 124.47 188.857Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="244.196" height="220" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SentConfirmationModalIcon;
