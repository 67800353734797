import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { FetchQueryParams } from 'core';
import { ContributionsRib } from '../types';

export const contributionsBankDetailsPath = `/api/proxy/selfcare/subscription/bank-details/contributions`;

export async function fetchContributionsBankDetails({
    queryKey: [urn, procedureId],
}: FetchQueryParams) {
    try {
        const response = await axios.get(
            procedureId ? `${urn}?procedureId=${procedureId}` : urn
        );
        return {
            statusCode: response?.status,
            bankDetails: response.data.data,
        };
    } catch (error) {
        return {
            statusCode: error?.response?.status,
        };
    }
}

export function useContributionsBankDetails(procedureId?: string) {
    return useQuery<{ bankDetails?: ContributionsRib; statusCode: number }>({
        queryKey: [contributionsBankDetailsPath, procedureId],
        queryFn: fetchContributionsBankDetails,
    });
}
