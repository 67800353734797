import React, { ReactElement, useEffect } from 'react';
import {
    BoxProps,
    Divider,
    Flex,
    HStack,
    Stack,
    Text,
    ContainerProps as CContainerProps,
} from '@chakra-ui/react';
import * as icons from 'design-system/icons';

type HeadingLevel = '1' | '2' | '3' | '4' | '5' | '6';

type WithHeadingLevel<Props> = Props & {
    headingLevel?: HeadingLevel;
};

type ContainerProps = WithHeadingLevel<
    React.PropsWithChildren<
        BoxProps & {
            columns?: number | Record<string, number>;
            size?: CContainerProps['size'];
        }
    >
>;

export const accordionColorConfigs = {
    primary: {
        blockBg: 'primary.50',
        buttonBg: 'primary.200',
        blockHoverBg: 'primary.100',
        blockBorderColor: 'primary.300',
        iconBg: 'primary.main',
    },
    secondary: {
        blockBg: 'secondary.50',
        buttonBg: 'secondary.200',
        blockHoverBg: 'secondary.100',
        blockBorderColor: 'secondary.300',
        iconBg: 'secondary.main',
    },
    tertiary: {
        blockBg: 'tertiary.50',
        buttonBg: 'tertiary.200',
        blockHoverBg: 'tertiary.100',
        blockBorderColor: 'tertiary.300',
        iconBg: 'tertiary.main',
    },
    white: {
        blockBg: 'grey.50',
        buttonBg: 'primary.200',
        blockHoverBg: 'grey.100',
        blockBorderColor: 'grey.300',
        iconBg: 'grey.800',
    },
    accent: {
        blockBg: 'accent.ultralight',
        buttonBg: 'primary.200',
        blockHoverBg: 'accent.light',
        blockBorderColor: 'accent.main',
        iconBg: 'accent.main',
    },
};

export type AccordionProps = {
    id?: string;
    color: 'primary' | 'secondary' | 'tertiary' | 'white' | 'accent';
    icon?: string;
    title: string;
    containerProps?: ContainerProps;
    children?: ReactElement;
    withDivider?: boolean;
    closeOnContentClick?: boolean;
    onMissingIcon?: (icon: AccordionProps['icon']) => void;
    isOpen?: boolean;
    onToggle?: (isOpen: boolean) => void;
};

export function Accordion({
    id = '',
    color,
    title,
    icon,
    containerProps,
    withDivider = true,
    children,
    closeOnContentClick = true,
    onMissingIcon,
    isOpen: isOpenFromProps,
    onToggle,
}: AccordionProps) {
    const Icon = icons[icon?.charAt(0)?.toUpperCase() + icon?.slice(1)];
    const [isOpen, setIsOpen] = React.useState(isOpenFromProps || false);
    const isControlled = isOpenFromProps !== undefined;

    useEffect(() => {
        if (isControlled) setIsOpen(isOpenFromProps);
    }, [isControlled, isOpenFromProps]);

    useEffect(() => {
        if (icon && !Icon && onMissingIcon) onMissingIcon(icon);
    }, [Icon, icon, onMissingIcon]);

    const _renderDivider = () =>
        withDivider && (
            <Divider
                color={accordionColorConfigs[color].iconBg}
                w="5"
                borderWidth="1px"
                borderLeft="4px solid"
                borderColor={accordionColorConfigs[color].iconBg}
            />
        );

    const toggle = () => {
        if (isControlled) {
            onToggle && onToggle(!isOpenFromProps);
        } else {
            setIsOpen((prevIsOpen) => !prevIsOpen);
        }
    };

    const handleHeaderAreaClick = () => {
        if (closeOnContentClick) toggle();
    };

    const handleClickFullAreaClick = () => {
        if (!closeOnContentClick) toggle();
    };

    return (
        <Flex
            direction="column"
            tabIndex={0}
            bg={accordionColorConfigs[color].blockBg}
            _hover={{
                bg: accordionColorConfigs[color].blockHoverBg,
            }}
            _focus={{
                border: '2px solid',
                borderColor: accordionColorConfigs[color].blockBorderColor,
                m: '-2px',
            }}
            _focusVisible={{
                outline: 'none',
            }}
            p="4"
            rounded="xl"
            {...containerProps}
            cursor="pointer"
            onClick={handleClickFullAreaClick}>
            <HStack
                id={id}
                spacing="4"
                onClick={handleHeaderAreaClick}
                data-testid="accordion-header">
                {Icon && (
                    <Flex
                        p="2.5"
                        rounded="lg"
                        bg={accordionColorConfigs[color].iconBg}>
                        <Icon w="6" h="6" color="white" />
                    </Flex>
                )}
                <Text fontSize="md" fontWeight="bold" flex="1">
                    {title}
                </Text>
                <Flex justifyContent="flex-end">
                    <Flex
                        bg={
                            isOpen
                                ? 'white'
                                : accordionColorConfigs[color].iconBg
                        }
                        rounded="full"
                        p="2">
                        {isOpen ? (
                            <icons.MinusIcon
                                color={isOpen ? 'black' : 'white'}
                            />
                        ) : (
                            <icons.PlusIcon color="white" />
                        )}
                    </Flex>
                </Flex>
            </HStack>
            <Stack
                mt={isOpen && 5}
                overflow="hidden"
                spacing="3"
                transition="ease-in-out max-height 0.5s"
                maxHeight={isOpen ? 'calc(100% + 20px)' : 0}>
                {isOpen && (
                    <>
                        {_renderDivider()}
                        {children}
                    </>
                )}
            </Stack>
        </Flex>
    );
}
