import { isEmpty } from 'lodash';

export function getRedirectUrl(url: string, query) {
    if (!query || isEmpty(query)) return url;
    try {
        return Object.keys(query).reduce(
            (aggr, key) => {
                if (
                    !url.includes(query[key]) &&
                    !url.includes(encodeURIComponent(query[key]))
                ) {
                    throw new Error(
                        `Une erreur est survenue dans la création de l'url de redirection`
                    );
                }
                if (new RegExp(`[?|&]${key}=`).test(url)) {
                    return {
                        ...aggr,
                        pathname: aggr.pathname.replace(/\?.*/, ''),
                    };
                }
                return {
                    ...aggr,
                    pathname: aggr.pathname.replace(query[key], `[${key}]`),
                };
            },
            { pathname: url, query }
        );
    } catch {
        return 'news';
    }
}

export function getPreviousUrlParams(redirect: string) {
    try {
        return JSON.parse(redirect);
    } catch {
        return redirect;
    }
}
