import React, { useContext } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionPanel,
    Stack,
} from '@chakra-ui/react';
import {
    APIError,
    EnhancedQueryStatus,
    FilterType,
    UtilsContext,
    useFiltersForm,
} from 'core';
import { FormProvider, FieldValues, UseFormReturn } from 'react-hook-form';
import FilterGroupAccordionButton from './FilterGroupAccordionButton';
import FiltersFormInput from './FiltersFormInput';
import { DashboardContext } from 'core/lib/shared/contexts/DashboardContext';

type FiltersContentProps = {
    status: EnhancedQueryStatus;
    error: APIError;
    type: FilterType;
    methods: UseFormReturn<FieldValues>;
    searchWidgetId: string;
    resourceId?: string;
};

export default function FiltersContent({
    status,
    error,
    type,
    methods,
    searchWidgetId,
    resourceId,
}: FiltersContentProps) {
    const {
        layouts: { LayoutBoundary },
    } = useContext(UtilsContext);
    const { data: filtersForm } = useFiltersForm(type, resourceId);
    const { openedFilterCategory, setOpenedFilterCategory } =
        React.useContext(DashboardContext);

    const concernedFilters = filtersForm?.widgets?.filter(
        (widget) => widget.id !== searchWidgetId
    );

    const openedWidgetIndex = concernedFilters?.indexOf(
        filtersForm?.widgets.find(
            (widget) => widget.id === openedFilterCategory
        )
    );

    return (
        <LayoutBoundary
            as={Stack}
            status={status}
            isSmallDisplay
            errorCode={error?.code}
            position="relative"
            h="full"
            gap="2"
            p="0">
            <FormProvider {...methods}>
                <Stack
                    as="form"
                    h="full"
                    noValidate
                    spacing="4"
                    px="5"
                    overflow="auto"
                    sx={{
                        'select, input, input + span': {
                            bg: 'white',
                        },
                        '::-webkit-scrollbar': {
                            width: '6px',
                            borderRadius: 'lg',
                        },
                        '::-webkit-scrollbar-track': {
                            width: '6px',
                            background: 'grey.400',
                            borderRadius: 'lg',
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: 'primary.main',
                            borderRadius: 'lg',
                        },
                    }}>
                    <Stack spacing="0" flex="1">
                        <Accordion
                            as={Stack}
                            allowToggle
                            index={openedWidgetIndex}
                            onChange={(expandedIdx: number) =>
                                setOpenedFilterCategory(
                                    concernedFilters?.[expandedIdx]?.id
                                )
                            }>
                            {concernedFilters?.map(({ id, help }) => (
                                <AccordionItem
                                    key={id}
                                    bg="grey.50"
                                    rounded="lg"
                                    border="none">
                                    {({ isExpanded }) => (
                                        <>
                                            <FilterGroupAccordionButton
                                                {...{
                                                    isExpanded,
                                                    name: id,
                                                    help,
                                                }}
                                            />

                                            <AccordionPanel>
                                                <FiltersFormInput
                                                    name={id}
                                                    type={type}
                                                    resourceId={resourceId}
                                                />
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </Stack>
                </Stack>
            </FormProvider>
        </LayoutBoundary>
    );
}
