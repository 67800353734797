import React, { useRef } from 'react';
import {
    BadgeProps,
    Button,
    HStack,
    Text,
    Tooltip,
    useClipboard,
} from '@chakra-ui/react';
import useIsOverflow from 'core/lib/shared/helpers/isOverflow';
import { TooltipLayout } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { defineMessages, useIntl } from 'react-intl';
import { CheckCircleIcon, CopyIcon } from 'design-system/icons';

const intlMessages = defineMessages({
    copied: {
        id: 'components.username-upgrade.tooltip.copied',
        defaultMessage: 'Copié',
    },
});

type CopyToClipboardProps = BadgeProps & {
    label: string;
    value: string;
    type: 'button' | 'text';
};

export default function CopyToClipboard({
    label,
    value,
    type,
    ...props
}: CopyToClipboardProps) {
    const intl = useIntl();
    const { isSmallOrMediumDevice } = useWindowBreakpoints();
    const labelRef = useRef();
    const labelIsOverflow = useIsOverflow(labelRef);

    const { hasCopied, onCopy } = useClipboard(value, 3000);

    return (
        <Tooltip
            hasArrow
            label={hasCopied && intl.formatMessage(intlMessages.copied)}
            bg="white"
            color="black"
            placement="top-end"
            fontSize="sm"
            isOpen={hasCopied ? true : undefined}>
            {type === 'button' ? (
                <Button
                    onClick={onCopy}
                    w={isSmallOrMediumDevice ? 'full' : 'fit-content'}
                    variant="outline"
                    colorScheme="primary"
                    mb={isSmallOrMediumDevice ? '2' : '0'}
                    mr={isSmallOrMediumDevice ? '0' : '2'}
                    {...props}
                    rightIcon={
                        !hasCopied ? (
                            <CopyIcon />
                        ) : (
                            <CheckCircleIcon color="green.500" />
                        )
                    }>
                    {label}
                </Button>
            ) : (
                <HStack
                    onClick={onCopy}
                    color="blue.700"
                    fontSize="sm"
                    variant="outline"
                    colorScheme="primary"
                    mb="0"
                    mr={isSmallOrMediumDevice ? '0' : '2'}
                    _hover={{ color: 'blue.600' }}
                    {...props}>
                    <TooltipLayout isOverflow={labelIsOverflow} text={label}>
                        <Text
                            ref={labelRef}
                            textOverflow="ellipsis"
                            overflow="hidden"
                            fontSize="sm"
                            whiteSpace={
                                isSmallOrMediumDevice ? 'unset' : 'nowrap'
                            }>
                            {label}
                        </Text>
                    </TooltipLayout>
                    {!hasCopied ? (
                        <CopyIcon />
                    ) : (
                        <CheckCircleIcon color="green.500" />
                    )}
                </HStack>
            )}
        </Tooltip>
    );
}
