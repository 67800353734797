import { defineMessages } from 'react-intl';

export const handleSubmitResponseErrorMessages = {
    400: defineMessages({
        toastTitleError: {
            id: 'api.register.form.errors.400.title',
            defaultMessage: 'Votre demande n’a pas pu aboutir',
        },
        toastError: {
            id: 'api.register.form.errors.400.message',
            defaultMessage:
                "Pour plus d'informations, nous vous invitons à contacter votre service client au numéro indiqué sur votre carte de tiers payant.",
        },
    }),
    404: defineMessages({
        title: {
            id: 'api.register.form.errors.404.title',
            defaultMessage: 'Nous ne parvenons pas à vous identifier',
        },
        message: {
            id: 'api.register.form.errors.404.message',
            defaultMessage:
                '<l>Veuillez vérifier les informations saisies.</l><l>Si le problème persiste, nous vous invitons à contacter votre service client au numéro indiqué sur votre carte de tiers payant en mentionnant le code suivant :</l><l><b>Code 004</b></l>',
        },
    }),
    429: defineMessages({
        message: {
            id: 'api.register.form.errors.429.message',
            defaultMessage:
                'Votre compte est bloqué pendant 15 minutes. Passé ce délai, vous pourrez à nouveau vous connecter.',
        },
    }),
    500: defineMessages({
        toastError: {
            id: 'components.register.errors',
            defaultMessage:
                'Une erreur est survenue lors de la soumission du formulaire.',
        },
    }),
};
