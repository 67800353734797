import { Icon, IconProps } from '@chakra-ui/react';

export default function LiveCameraIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 20 15" {...props}>
            <path
                fillRule="evenodd"
                d="M0 1.333a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v1.948a.5.5 0 0 0 .55.498l3.35-.336a1 1 0 0 1 1.1.995v5.79a1 1 0 0 1-1.1.995l-3.35-.335a.5.5 0 0 0-.55.498v1.948a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-12zm13 10.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-8.5a1 1 0 0 1 1-1h9.5a.5.5 0 0 1 .5.5v2.448a.5.5 0 0 0 .55.498l3.35-.336a1 1 0 0 1 1.1.995v1.79a1 1 0 0 1-1.1.996l-3.35-.336a.5.5 0 0 0-.55.498v2.448z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
