import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Eligibility } from '../types/eligibility';
import { useRouter } from 'next/router';
import { FetchQueryParams } from 'core';
import { NOT_AUTH_ROUTES } from 'lib/user/contexts/consts';
import { useGetEnvVar } from './useGetEnvVar';
import { DashboardVersionsEnum } from 'lib/news/type';

const path = `/api/proxy/security/user/account/eligibility`;

const defaultEligibilities: { [x: string]: Eligibility } = {
    [DashboardVersionsEnum.SECOND]: {
        features: {
            statements: false,
            guarantees: false,
            dematerializedTpCard: false,
            healthCertificate: false,
            providentContract: false,
            providentSimulator: false,
            coInsurance: false,
            sponsorship: false,
            privilegeOffers: false,
            teleconsultationMedaviz: false,
            teleconsultationAg2r: false,
            assistanceMva: false,
            assistanceAg2r: false,
            teleconsultationAgricaMondialAssistance: false,
            assistanceAgricaMondialAssistance: false,
            assistanceAgricaMutuaide: false,
            healthcareAccess: false,
            europAssistanceWorkflow: false,
            medicalib: false,
            prevention: false,
            refunds: true,
            contracts: true,
            messages: true,
            requests: true,
            includedServices: false,
            adultBeneficiariesManagement: false,
        },
        legalsDocuments: {
            generalConditionsOfUse: false,
            privacyPolicy: false,
        },
        showSatisfaction: false,
        subThemeToDisplay: null,
        phoneContact: false,
        addressContact: false,
        displayFooterCgu: false,
        displayAcceo: false,
        saveServiceView: false,
        allowRefundsExport: false,
        displayConsent: {
            login: false,
            profile: false,
            formId: null,
        },
    },
    [DashboardVersionsEnum.THIRD]: {
        features: {
            statements: false,
            guarantees: false,
            dematerializedTpCard: false,
            healthCertificate: false,
            providentContract: false,
            providentSimulator: false,
            coInsurance: false,
            sponsorship: false,
            privilegeOffers: false,
            teleconsultationMedaviz: false,
            teleconsultationAg2r: false,
            assistanceMva: false,
            assistanceAg2r: false,
            teleconsultationAgricaMondialAssistance: false,
            assistanceAgricaMondialAssistance: false,
            assistanceAgricaMutuaide: false,
            healthcareAccess: false,
            europAssistanceWorkflow: false,
            medicalib: false,
            prevention: false,
            refunds: true,
            contracts: true,
            messages: true,
            requests: true,
            includedServices: false,
            adultBeneficiariesManagement: false,
        },
        legalsDocuments: {
            generalConditionsOfUse: false,
            privacyPolicy: false,
        },
        showSatisfaction: false,
        subThemeToDisplay: null,
        phoneContact: false,
        addressContact: false,
        displayFooterCgu: false,
        displayAcceo: false,
        saveServiceView: false,
        allowRefundsExport: false,
        displayConsent: {
            login: false,
            profile: false,
            formId: null,
        },
    },
};

export async function fetchEligibility({
    queryKey: [urn, isAuthRoute, dashboardVersion],
}: FetchQueryParams) {
    if (!isAuthRoute) {
        return null;
    }

    try {
        const response = await axios.get(urn);
        const { data } = response?.data as Api<Eligibility>;

        return data;
    } catch (error) {
        return defaultEligibilities[Number(dashboardVersion)];
    }
}

export function useEligibility() {
    const router = useRouter();
    const isAuthRoute = !NOT_AUTH_ROUTES.includes(router?.pathname);
    const { data: dashboardVersion } = useGetEnvVar(
        'DASHBOARD_VERSION',
        isAuthRoute
    );

    return useQuery<Eligibility, Error>({
        queryKey: [path, isAuthRoute, dashboardVersion],
        queryFn: main.fetchEligibility,
        retryOnMount: false,
        enabled: !!dashboardVersion,
    });
}

const main = {
    useEligibility,
    fetchEligibility,
};

export default main;
