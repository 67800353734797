import { Icon, IconProps } from '@chakra-ui/react';

export default function PauseIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M8 6a1 1 0 1 0-2 0v12a1 1 0 1 0 2 0V6zM18 6a1 1 0 1 0-2 0v12a1 1 0 1 0 2 0V6z"
                fill="currentColor"
            />
        </Icon>
    );
}
