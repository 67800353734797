import React, { useContext, useMemo, useRef, useState } from 'react';
import { Flex, HStack, Box } from '@chakra-ui/react';
import { InputText, WidgetProps, DashboardContext, SelectedFilter } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ArrowRightIcon, CrossIcon, SearchIcon } from 'design-system/icons';
import { useFormContext } from 'react-hook-form';
import getInputName from 'core/lib/forms/helpers/getInputName';

const iconProps = {
    w: '5',
    h: '5',
    color: 'primary.main',
    transition: 'all 0.3s ease-in-out',
    _hover: { transform: 'scale(1.2)' },
};

export default function SearchFilter(props: WidgetProps) {
    const { isMobile } = useWindowBreakpoints();
    const { selectedFilters, setSelectedFilters } =
        useContext(DashboardContext);
    const inputRef = useRef<HTMLInputElement>();
    const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);

    const searchFilter = useMemo(
        () => selectedFilters.find((filter) => filter.name === props?.id),
        [selectedFilters, props]
    );

    const { setValue } = useFormContext();

    const setInputRef = (ref: HTMLInputElement) => {
        if (!ref) return;

        inputRef.current = ref;

        inputRef.current.addEventListener('focus', () =>
            setIsSearchInputFocused(true)
        );

        inputRef.current.addEventListener('focusout', () =>
            setTimeout(() => {
                setIsSearchInputFocused(false);
            }, 100)
        );
    };

    const handleSearchReset = () => {
        setSelectedFilters(
            selectedFilters.map((filter) =>
                filter?.name === props?.id
                    ? ({
                          ...filter,
                          values: [],
                      } as SelectedFilter)
                    : filter
            )
        );
        setValue(getInputName(props.id, props.name), '');
    };

    return (
        <HStack
            maxW={isMobile ? '100%' : '400px'}
            position="relative"
            sx={{
                'input[type="text"]': {
                    h: 'fit-content',
                    py: '2px',
                    px: 9,
                    rounded: 'lg',
                    bg: 'grey.50',
                    textOverflow: 'ellipsis',
                    _placeholder: {
                        color: 'texts.medium',
                        overflow: 'hidden',
                    },
                    _focusVisible: { zIndex: 0 },
                },
            }}>
            <InputText
                {...props}
                defaultValue={searchFilter?.values[0]}
                ref={setInputRef}
            />

            <SearchIcon w="5" h="5" position="absolute" left="2.5" top="1.5" />

            <Flex position="absolute" right="2" top="0.5" alignItems="center">
                {!!searchFilter?.values[0] && !isSearchInputFocused ? (
                    <Box
                        data-testid="search-reset-button"
                        onClick={handleSearchReset}
                        cursor="pointer">
                        <CrossIcon {...iconProps} />
                    </Box>
                ) : (
                    <button>
                        <ArrowRightIcon {...iconProps} />
                    </button>
                )}
            </Flex>
        </HStack>
    );
}
