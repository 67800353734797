import { useEffect, useState } from 'react';
import { omit, size } from 'lodash';
import { useController, useFormContext } from 'react-hook-form';
import { Text, Box, useRadioGroup, SimpleGrid } from '@chakra-ui/react';
import {
    SafeFormattedMessage,
    formMessages,
    FormGroup,
    useInputValidation,
} from 'core';
import type { RadioProps, WidgetProps } from '../types';
import RadioCard from './RadioCard';

type InputRadiosProps = WidgetProps & {
    options: RadioProps[];
    nbDisplayColumns?: number;
    oneColumn?: boolean;
};

export default function InputRadio({
    name,
    id,
    validations,
    title,
    links,
    readonly,
    description,
    help,
    options,
    defaultValue,
    nbDisplayColumns,
    oneColumn = false,
}: InputRadiosProps) {
    const [desc, setDesc] = useState<string | undefined>();
    const inputName = name ?? `${id}.value`;
    const { control, setValue, getValues, rules } = useInputValidation(
        validations,
        inputName
    );
    const {
        formState: { errors },
    } = useFormContext();
    const { field } = useController({
        name: inputName,
        control,
        defaultValue,
        rules,
    });
    const { getRootProps, getRadioProps } = useRadioGroup({ ...field });
    const group = getRootProps();
    const formValue = getValues(inputName);

    useEffect(() => {
        if (!formValue && !formValue?.value && defaultValue === undefined) {
            setValue(inputName, '');
        }
    }, [formValue, setValue, defaultValue, inputName]);

    useEffect(() => {
        if (defaultValue !== undefined) setValue(inputName, defaultValue);
    }, [defaultValue, setValue, inputName]);

    useEffect(() => {
        setDesc(
            options.find((option) => option.id === defaultValue)?.description
        );
    }, [defaultValue, options, setDesc]);

    return (
        <FormGroup
            label={title}
            name={inputName}
            isRequired={validations.required}
            isReadOnly={readonly}
            {...{ id, description, help, links }}>
            <SimpleGrid
                mt="2"
                {...group}
                columns={{
                    base: 1,
                    md: nbDisplayColumns || (oneColumn ? 1 : 2),
                }}
                gap="2"
                spacing={4}>
                {options.map((option) => {
                    const radio = getRadioProps({ value: option.id });

                    return (
                        <Box
                            key={option.id}
                            onClick={() => setDesc(option.description)}>
                            <RadioCard
                                errors={errors}
                                optionId={option.id}
                                widgetId={id}
                                radioRef={field.ref}
                                {...omit(field, 'ref')}
                                {...omit(radio, 'ref')}>
                                {formMessages?.[option?.label] ? (
                                    <SafeFormattedMessage
                                        {...formMessages[option.label]}
                                        debugKey={option.label}
                                    />
                                ) : (
                                    <>{option?.label}</>
                                )}
                            </RadioCard>
                        </Box>
                    );
                })}
            </SimpleGrid>
            {!size(errors) && desc && (
                <Text mt="10">
                    <SafeFormattedMessage
                        {...formMessages[desc]}
                        debugKey={desc}
                    />
                </Text>
            )}
        </FormGroup>
    );
}
