import * as RM from 'react-masonry-css';
import { GridProps, FlexProps, Flex } from '@chakra-ui/react';

type MasonryProps = FlexProps &
    Omit<RM.MasonryProps, 'className' | 'columnClassName'> & {
        col?: number;
        spacing?: GridProps['gridGap'];
    };

function Masonry({
    children,
    col,
    spacing = '4',
    ...boxProps
}: React.PropsWithChildren<MasonryProps>) {
    return (
        <Flex
            as={RM.default}
            width="auto"
            ml={`-${spacing}`}
            sx={{
                '> *': {
                    paddingLeft: spacing,
                    backgroundClip: 'padding-box',
                    '> *:not(:last-of-type)': {
                        marginBottom: spacing,
                    },
                },
            }}
            breakpointCols={col}
            className=""
            columnClassName=""
            {...boxProps}>
            {children}
        </Flex>
    );
}

export { Masonry };
