import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, CheckboxProps, Text, VStack } from '@chakra-ui/react';
import { coreSharedMessages } from 'core';

type PaginationProps = {
    items: string[] | CheckboxProps[];
    paginatedItems: string[] | CheckboxProps[];
    pageSize: number;
    handleShowMoreClick: () => void;
};

export function Pagination({
    items,
    paginatedItems,
    pageSize,
    handleShowMoreClick,
}: PaginationProps) {
    return (
        items.length > pageSize && (
            <VStack py={4} justifyItems="center">
                <Text textAlign={'center'} color="texts.medium" fontSize="xs">
                    <FormattedMessage
                        {...coreSharedMessages.displayedOfEntries}
                        values={{
                            number: paginatedItems.length,
                            total: items.length,
                        }}
                    />
                </Text>
                {items.length > paginatedItems.length && (
                    <Button colorScheme="primary" onClick={handleShowMoreClick}>
                        <FormattedMessage {...coreSharedMessages.showMore} />
                    </Button>
                )}
            </VStack>
        )
    );
}
