import { Icon, IconProps } from '@chakra-ui/react';

export default function AmbulanceIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M4 5a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h1.17a3.001 3.001 0 0 0 5.66 0h2.34a3.001 3.001 0 0 0 5.66 0H20a2 2 0 0 0 2-2v-2.842a2 2 0 0 0-.704-1.524L18.5 9.258l-.83-2.822A2 2 0 0 0 15.753 5H4zm14.83 11H20v-2.842L18.638 12H13a1 1 0 0 1-1-1V7H4v9h1.17a3.001 3.001 0 0 1 5.66 0h2.34a3.001 3.001 0 0 1 5.66 0zm-2.187-6a1.996 1.996 0 0 1-.062-.178L15.751 7H14v3h2.643zM8 18a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm9-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                d="M8.75 9.25v-.5a.75.75 0 0 0-1.5 0v.5h-.5a.75.75 0 0 0 0 1.5h.5v.5a.75.75 0 0 0 1.5 0v-.5h.5a.75.75 0 0 0 0-1.5h-.5z"
                fill="currentColor"
            />
        </Icon>
    );
}
