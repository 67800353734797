import { Icon, IconProps } from '@chakra-ui/react';

export default function EyeCloseIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M13 14.534c1.168-.188 2.305-.683 3.348-1.314l1.152 1.994a1 1 0 0 0 1.732-1l-1.238-2.144c1.566-1.239 2.908-2.706 3.794-3.838L20.212 7c-.857 1.095-2.18 2.53-3.688 3.68-1.534 1.168-3.118 1.936-4.524 1.936-1.406 0-2.99-.768-4.524-1.937C5.968 9.53 4.645 8.095 3.788 7L2.212 8.232a23.814 23.814 0 0 0 3.61 3.691L4.5 14.214a1 1 0 0 0 1.732 1l1.222-2.116c1.097.689 2.303 1.236 3.546 1.436v2.082a1 1 0 1 0 2 0v-2.082z"
                fill="currentColor"
            />
        </Icon>
    );
}
