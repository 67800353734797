import { Icon, IconProps } from '@chakra-ui/react';

export default function StarFillIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M11.087 3.05c.352-.791 1.474-.791 1.826 0l2.402 5.387 5.867.62c.86.09 1.207 1.157.564 1.737l-4.382 3.949 1.224 5.77c.18.847-.728 1.507-1.478 1.074L12 18.64l-5.11 2.947c-.75.433-1.658-.227-1.478-1.073l1.224-5.771-4.382-3.95c-.643-.579-.296-1.646.564-1.737l5.867-.619 2.402-5.388z"
                fill="currentColor"
            />
        </Icon>
    );
}
