import { IconProps } from '@chakra-ui/react';
import { FormValues, WorkflowContextType } from 'core';
import { InternationalPhone } from 'core/components/forms/widgets/InternationalPhoneNumber';
import { ComponentType } from 'react';
import { MessageDescriptor } from 'react-intl';

export enum HomeConsultationStepsEnum {
    SELECT_PRACTITIONER = 'select-practitioner',
    CARE = 'cares',
    ORDINANCE = 'prescription',
    DATE = 'date',
    PATIENT = 'patient',
    OVERVIEW = 'overview',
}

export enum PractitionerNumberEnum {
    nurse = 60,
    midwife = 50,
    physiotherapist = 70,
}

export enum PractitionerIdEnum {
    NURSE = 'nurse',
    PHYSIOTHERAPIST = 'physiotherapist',
    MIDWIFE = 'midwife',
}

export enum GenderEnum {
    male = 'M',
    female = 'F',
}

export type PractitionerIcon = ComponentType<IconProps>;

export type Practitioner = {
    id: PractitionerIdEnum;
    icon: PractitionerIcon;
    label: MessageDescriptor;
};

export enum InterventionStatusEnum {
    DONE = 'done',
    UNALLOCATED = 'unallocated',
    WAITING = 'waiting',
    PENDING = 'pending',
    ALLOCATED = 'allocated',
    CANCELED = 'canceled',
    UNACCEPTED = 'unaccepted',
}

export type FrequencyDetails = {
    frequencyNumber: string;
    period: string;
    value: string;
};

export type Care = {
    careName: string;
    options: FormValues;
};

export type CareLocationAddress = {
    additionalInfos: string;
    city: string;
    coordinates: {
        latitude: number;
        longitude: number;
    };
    country: {
        longName: string;
        shortName: string;
    };
    department: string;
    locationType: 'NCCF' | 'PTRES' | 'null';
    postalCode: string;
    region: string;
    street: string;
    streetNumber: string;
    isManualAddress?: boolean;
};

export type PatientInfos = {
    email: string;
    firstName: string;
    hasPrescription: boolean;
    hasPrescriptionAtHome: boolean;
    identifier: string;
    lastName: string;
    phone: InternationalPhone;
};

export type WorkflowMedicalibData = {
    currentStep: number;
    acceptShareMedicalData?: boolean;
    cares?: Care[];
    careDuration?: string; // The finale value of careDuration
    careDurationParentValue?: string; // The parent value if a custom value is selected
    careLocationAddress?: CareLocationAddress;
    careStartDate?: string;
    careEndDate?: string;
    nurseGenderPreference?: string | null;
    patientAvailabilities?: {
        endTime: string;
        startTime: string;
    }[];
    patientInfo?: PatientInfos;
    profession?: number;
    themeId?: number;
};

export type MedicalibContextType = WorkflowContextType & {
    data: WorkflowMedicalibData;
    setData: React.Dispatch<React.SetStateAction<WorkflowMedicalibData>>;
};
