import { defineMessages } from 'react-intl';

export const paymentTypeMessages = defineMessages({
    cheque: {
        id: 'contributions.payment-type.cheque',
        defaultMessage: 'Paiement par chèque',
        description: 'Mode de paiement par chèque',
    },
    transfer: {
        id: 'contributions.payment-type.transfer',
        defaultMessage: 'Paiement par virement',
        description: 'Mode de paiement par virement',
    },
    credit_card: {
        id: 'contributions.payment-type.credit-card',
        defaultMessage: 'Paiement par carte bleue',
        description: 'Mode de paiement par carte bleue',
    },
    cash: {
        id: 'contributions.payment-type.cash',
        defaultMessage: 'Paiement par espèces',
        description: 'Mode de paiement en espèces',
    },
    debit: {
        id: 'contributions.payment-type.debit',
        defaultMessage: 'Paiement par prélèvement',
        description: 'Mode de paiement en prélèvement',
    },
});

export const paymentFrequencyMessages = defineMessages({
    monthly: {
        id: 'frequency.monthly',
        defaultMessage: 'mois',
        description: 'Fractionnement mensuel',
    },
    quarterly: {
        id: 'frequency.quarterly',
        defaultMessage: 'trimestre',
        description: 'Fractionnement trimestriel',
    },
    'bi-annual': {
        id: 'frequency.bi-annual',
        defaultMessage: 'bi-annuel',
        description: 'Fractionnement bi-annuel',
    },
    annual: {
        id: 'frequency.annual',
        defaultMessage: 'annuel',
        description: 'Fractionnement annuel',
    },
});
