import { UtilsContext } from 'core';
import { useRouter } from 'next/router';
import { useContext } from 'react';

const useRouterBack = () => {
    const router = useRouter();
    const { siteConfig } = useContext(UtilsContext);

    const routerBack = () => {
        if (window.history.length > 1) router.back();
        else router.push(siteConfig.homePagePath || '/');
    };

    return routerBack;
};

export default useRouterBack;
