import { Icon, IconProps } from '@chakra-ui/react';

export default function HospiIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M12 5a.75.75 0 0 1 .75.75v.5h.5a.75.75 0 0 1 0 1.5h-.5v.5a.75.75 0 0 1-1.5 0v-.5h-.5a.75.75 0 0 1 0-1.5h.5v-.5A.75.75 0 0 1 12 5z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M7.1 6a5.002 5.002 0 0 1 9.8 0H19a2 2 0 0 1 2 2v13a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-5h-4v5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V8a2 2 0 0 1 2-2h2.1zm4.9 4a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM7.1 8H5v12h3v-4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v4h3V8h-2.1a5.002 5.002 0 0 1-9.8 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
