import React from 'react';
import { Stack, Button } from '@chakra-ui/react';
import { DevTool } from '@hookform/devtools';
import {
    SafeFormattedMessage,
    __DEV__,
    formMessages,
    ViolationMessage,
    FormErrors,
    flattenViolations,
    useFormValidation,
    coreSharedMessages,
} from 'core';
import { SatisfactionFormData, useSatisfaction } from 'lib/satisfaction';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { siteConfig } from 'lib/site';
import { FormLibrarian } from 'components/forms';

type Props = SatisfactionFormData;

export function SatisfactionForm({ widgets, iri, description }: Props) {
    const { appName } = siteConfig;
    const { step, setStep, setNextStepIri } = useSatisfaction();
    const { onSubmit, methods, isSubmitting } = useFormValidation(
        'security',
        iri,
        widgets
    );
    const [errors, setErrors] = React.useState<ViolationMessage[] | null>(null);
    const handleSubmit = async (data) => {
        setErrors(null);
        const response = await onSubmit(data);

        if (response?.status === 200) {
            if (response.data.hasOwnProperty('meta')) {
                setStep('comment.form');
                setNextStepIri(response.data.meta.next_step.iri);
            } else if (step === 'comment.form') {
                setStep('comment.end');
            } else {
                setStep('store');
            }
        }

        if (response?.data?.data?.violations) {
            const violations = flattenViolations(response.data.data.violations);
            setErrors(violations);
        }
        methods.reset();
    };

    return (
        <FormProvider {...methods}>
            <Stack
                noValidate
                as="form"
                onSubmit={methods.handleSubmit(handleSubmit)}
                spacing="6"
                alignItems="center"
                sx={{
                    // Force le <span> "optionnel" à être blanc au lieu de gris
                    span: {
                        color: 'white',
                    },
                    textarea: {
                        color: 'black',
                    },
                }}>
                {description && (
                    <p>
                        <SafeFormattedMessage
                            {...formMessages[description]}
                            debugKey={`formMessages[${description}]`}
                            values={{ appName }}
                        />
                    </p>
                )}

                {errors?.length && <FormErrors errors={errors} />}

                {widgets?.map(({ type, id, ...props }) => (
                    <FormLibrarian
                        key={id}
                        type={type === 'select' ? 'starRating' : type}
                        props={{
                            id,
                            methods: {
                                setError: methods.setError,
                                errors: methods.formState.errors,
                                clearErrors: methods.clearErrors,
                            },
                            ...props,
                        }}
                    />
                ))}

                <Button
                    type="submit"
                    bg="white"
                    color="primary.main"
                    w="full"
                    isLoading={isSubmitting && !errors?.length}>
                    <FormattedMessage {...coreSharedMessages.send} />
                </Button>
            </Stack>
            {__DEV__ && <DevTool control={methods.control} />}
        </FormProvider>
    );
}
