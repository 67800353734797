import { type FormValues } from 'core';

function transformJSON(inputId: string, data: FormValues) {
    return {
        id: inputId,
        values: JSON.parse(data.value),
    };
}

export default transformJSON;
