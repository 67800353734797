import { Icon, IconProps } from '@chakra-ui/react';

export default function ShrinkIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.6267 10.041C19.5486 10.5877 19.0421 10.9676 18.4953 10.8895L14.5355 10.3238C14.0956 10.261 13.7499 9.91526 13.687 9.47528L13.1213 5.51548C13.0432 4.96875 13.4231 4.46221 13.9699 4.38411C14.5166 4.306 15.0231 4.6859 15.1012 5.23264L15.3841 7.21254L19.6267 2.9699C20.0172 2.57937 20.6504 2.57937 21.0409 2.9699C21.4315 3.36042 21.4315 3.99358 21.0409 4.38411L16.7983 8.62675L18.7782 8.90959C19.3249 8.9877 19.7048 9.49423 19.6267 10.041ZM4.38406 13.97C4.46216 13.4233 4.96869 13.0434 5.51543 13.1215L9.47523 13.6871C9.9152 13.75 10.2609 14.0957 10.3238 14.5357L10.8894 18.4955C10.9675 19.0422 10.5876 19.5487 10.0409 19.6268C9.49418 19.7049 8.98765 19.325 8.90954 18.7783L8.6267 16.7984L4.38406 21.0411C3.99353 21.4316 3.36037 21.4316 2.96984 21.0411C2.57932 20.6505 2.57932 20.0174 2.96984 19.6268L7.21248 15.3842L5.23259 15.1014C4.68585 15.0233 4.30595 14.5167 4.38406 13.97ZM13.8852 19.8139C13.3326 19.735 12.9486 19.223 13.0276 18.6705L13.5993 14.6683C13.6628 14.2237 14.0122 13.8743 14.4569 13.8107L18.459 13.239C19.0116 13.1601 19.5235 13.544 19.6025 14.0966C19.6814 14.6492 19.2975 15.1611 18.7449 15.2401L16.7438 15.5259L21.0318 19.8139C21.4265 20.2086 21.4265 20.8485 21.0318 21.2432C20.6371 21.6379 19.9972 21.6379 19.6025 21.2432L15.3145 16.9553L15.0286 18.9563C14.9497 19.5089 14.4378 19.8929 13.8852 19.8139ZM9.91421 4.40839C10.4668 4.48733 10.8507 4.99928 10.7718 5.55185L10.2001 9.55397C10.1365 9.99865 9.78716 10.348 9.34248 10.4116L5.34036 10.9833C4.78779 11.0622 4.27584 10.6783 4.1969 10.1257C4.11797 9.57312 4.50192 9.06118 5.0545 8.98224L7.05555 8.69637L2.76758 4.40839C2.37288 4.0137 2.37288 3.37376 2.76758 2.97907C3.16228 2.58437 3.80221 2.58437 4.19691 2.97907L8.48488 7.26704L8.77075 5.26599C8.84969 4.71341 9.36163 4.32945 9.91421 4.40839Z"
                fill="currentColor"
            />
        </Icon>
    );
}
