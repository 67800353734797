import { defineMessages } from 'react-intl';

export const relatedActionsMessages = defineMessages({
    'refunds.request': {
        id: 'related-actions.refunds.request',
        defaultMessage: 'Demander un remboursement',
    },
    'refunds.ask-question': {
        id: 'related-actions.refunds.ask-question',
        defaultMessage: 'Question sur un remboursement',
    },
    'refunds.change-rib': {
        id: 'related-actions.refunds.change-rib',
        defaultMessage: 'Modifier le RIB de remboursement',
    },
    'refunds.manage-statements': {
        id: 'related-actions.refunds.manage-statements',
        defaultMessage: 'Gérer mes e-relevés',
    },
    'health-guarantees.access-documents': {
        id: 'related-actions.health-guarantees.access-documents',
        defaultMessage: 'Accès à mes documents de garanties',
    },
    'ask-question': {
        id: 'related-actions.ask-question',
        defaultMessage: 'Une question ? Envoyer un message',
    },
    'contact-call-center': {
        id: 'related-actions.contact-call-center',
        defaultMessage: 'Joindre un conseiller',
    },
    'tp-card.ask-question': {
        id: 'related-actions.tp-card.ask-question',
        defaultMessage: 'Question sur ma carte TP',
    },
    'tp-card.request-new': {
        id: 'related-actions.tp-card.request-new',
        defaultMessage: 'Demander une nouvelle carte',
    },
    'tp-card.report-error': {
        id: 'related-actions.tp-card.report-error',
        defaultMessage: 'Indiquer une anomalie',
    },
    'health-contract.change-options': {
        id: 'related-actions.health-contract.change-options',
        defaultMessage: 'Changer d’options',
    },
    'health-contract.delete': {
        id: 'related-actions.health-contract.delete',
        defaultMessage: 'Résilier mon contrat',
    },
    'health-guarantees.ask-question': {
        id: 'related-actions.health-guarantees.ask-question',
        defaultMessage: 'Questions sur mes garanties',
    },
    'health-beneficiaries.get-health-certificate': {
        id: 'related-actions.health-beneficiaries.get-health-certificate',
        defaultMessage: 'Obtenir une attestation d’appartenance',
    },
    'health-contract.act-on-contract': {
        id: 'related-actions.health-contract.act-on-contract',
        defaultMessage: 'Agir sur mon contrat',
    },
    'health-beneficiaries.add-child': {
        id: 'related-actions.health-beneficiaries.add-child',
        defaultMessage: 'Ajouter un enfant',
    },
    'health-beneficiaries.add-partner': {
        id: 'related-actions.health-beneficiaries.add-partner',
        defaultMessage: 'Ajouter mon conjoint',
    },
    'health-beneficiaries.add': {
        id: 'related-actions.health-beneficiaries.add',
        defaultMessage: 'Ajouter un bénéficiaire',
    },
    'health-beneficiaries.remove': {
        id: 'related-actions.health-beneficiaries.remove',
        defaultMessage: 'Supprimer ce bénéficiaire',
    },
    'health-beneficiaries.report-situation-change': {
        id: 'related-actions.health-beneficiaries.report-situation-change',
        defaultMessage: 'Signaler un changement de situation',
    },
    'complementary-health-insurance.change-rank': {
        id: 'related-actions.complementary-health-insurance.change-rank',
        defaultMessage: 'Changer le rang de ma complémentaire santé',
    },
    'health-guarantees.consult': {
        id: 'related-actions.health-guarantees.consult',
        defaultMessage: 'Consulter mes garanties',
    },
    'health-beneficiaries.download-health-certificate': {
        id: 'related-actions.health-beneficiaries.download-health-certificate',
        defaultMessage: 'Télécharger mon attestation de couverture',
    },
    'social-security.teletransmission.ask-question': {
        id: 'related-actions.social-security.teletransmission.ask-question',
        defaultMessage: 'Question sur la télétransmission',
    },

    'social-security.change-organization-code': {
        id: 'related-actions.social-security.change-organization-code',
        defaultMessage: 'Changer mon code organisme',
    },
    'social-security.teletransmission.establish': {
        id: 'related-actions.social-security.teletransmission.establish',
        defaultMessage: 'Mettre en place la télétransmission',
    },
    'social-security.change-cpam': {
        id: 'related-actions.social-security.change-cpam',
        defaultMessage: 'Modifier ma caisse de Sécurité sociale',
    },
    'social-security.teletransmission.remove': {
        id: 'related-actions.social-security.teletransmission.remove',
        defaultMessage: 'Supprimer la télétransmission',
    },
    'social-security.teletransmission.report-error': {
        id: 'related-actions.social-security.teletransmission.report-error',
        defaultMessage: 'Signaler un dysfonctionnement',
    },
    'social-security.card.link-beneficiary': {
        id: 'related-actions.social-security.card.link-beneficiary',
        defaultMessage: 'Rattacher un bénéficiaire',
    },
    'social-security.card.unlink-beneficiary': {
        id: 'related-actions.social-security.card.unlink-beneficiary',
        defaultMessage: 'Ajouter un n° de Sécurité sociale à un bénéficiaire',
    },
    'contributions.ask-question': {
        id: 'related-actions.contributions.ask-question',
        defaultMessage: 'Questions sur mes cotisations',
    },
    'contributions.change-rib': {
        id: 'related-actions.contributions.change-rib',
        defaultMessage: 'Modifier mon RIB de cotisations',
    },
    'contributions.change-rib-by-contract': {
        id: 'related-actions.contributions.change-rib-by-contract',
        defaultMessage: 'Demander la modification de mon RIB de cotisations',
    },
    'categories.help': {
        id: 'related-actions.categories.help',
        defaultMessage: 'Aide',
    },
});
