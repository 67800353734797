import React, { useEffect } from 'react';
import { ONBOARDING_V2_SESSION_KEY } from 'components/onboarding/v2/consts';
import { useUserContext } from 'lib/user';
import { siteConfig } from 'lib/site';

const DEADLINE = new Date(Date.UTC(2024, 0, 3)).getTime();

export const useOnboarding = () => {
    const { userInfos, isLoading } = useUserContext();
    const [displayOnboarding, setDisplayOnboarding] = React.useState(false);

    useEffect(() => {
        if (
            !window.sessionStorage.getItem(ONBOARDING_V2_SESSION_KEY) &&
            !isLoading &&
            userInfos?.user &&
            siteConfig.onboardingVersion === 2
        ) {
            const previousLoginDate = userInfos.user?.previousLoginDate;
            const isPreviousLoginDateOverDeadline = previousLoginDate
                ? new Date(previousLoginDate).getTime() > DEADLINE
                : false;

            if (!isPreviousLoginDateOverDeadline) {
                setDisplayOnboarding(true);
            } else {
                setDisplayOnboarding(false);
            }
        }
    }, [isLoading, userInfos]);

    return { displayOnboarding };
};
