import { Icon, IconProps } from '@chakra-ui/react';

export default function ChevronLeftIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="m9.69 11.83 6.387-5.59a1 1 0 0 0-1.317-1.505l-7.248 6.342a1 1 0 0 0 0 1.505l7.248 6.341a1 1 0 1 0 1.317-1.505L9.689 11.83z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
