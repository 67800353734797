import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from '@chakra-ui/react';
import { WidgetProps } from '../types';
import {
    FormGroup,
    coreSharedMessages,
    purifyHTML,
    useInputValidation,
} from 'core';
import RichTextEditor from './RichTextEditor';

export const richTextareaRootElement =
    '<p dir="ltr" class="editor-paragraph"></p>';

// When the rich text editor is empty it returns the following string
const emptyContent = '<p class="editor-paragraph"><br></p>';

export default function RichTextarea({
    name,
    id,
    validations,
    title,
    links,
    readonly,
    description,
    help,
    placeholder,
    defaultValue,
}: WidgetProps) {
    const { required: isRequired, ...restValidation } = validations;
    const inputName = name ?? `${id}.value`;
    const {
        registerValues,
        setValue,
        formState: { errors },
    } = useInputValidation(
        {
            ...restValidation,
            richTextareaRequired: isRequired,
            required: false, // statically set to false bcs it's overloaded by richTextareaRequired
        },
        inputName
    );
    const [htmlContent, setHTMLContent] = useState<string>(defaultValue || '');
    const [contentSize, setContentSize] = useState<number>(0);
    const purifiedHTML = !!defaultValue
        ? purifyHTML(defaultValue)
        : richTextareaRootElement;

    useEffect(() => {
        if (defaultValue) {
            setValue(inputName, defaultValue, {
                shouldValidate: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const _renderHiddenInput = (): JSX.Element => {
        return (
            <input
                {...registerValues}
                value={htmlContent}
                hidden={true}
                readOnly
            />
        );
    };
    const handleOnChange = (htmlContent: string, textContentSize: number) => {
        const cleanedHTML = purifyHTML(htmlContent);

        if (cleanedHTML !== emptyContent) {
            setHTMLContent(cleanedHTML);
            setValue(inputName, cleanedHTML, {
                shouldValidate: true,
            });
            setContentSize(textContentSize);
        } else {
            setHTMLContent('');
            setValue(inputName, '', {
                shouldValidate: false,
            });
            setContentSize(0);
        }
    };

    return (
        <FormGroup
            label={title}
            name={inputName}
            isReadOnly={readonly}
            {...{
                id,
                description,
                isRequired,
                help,
                links,
                isInvalid: !!errors[id],
            }}>
            <RichTextEditor
                placeholder={placeholder}
                initialContent={purifiedHTML}
                maxLength={validations.lengthMaxWithoutHtmlTags || null}
                isInvalid={!!errors[id]}
                onChange={handleOnChange}
            />
            <Box mt="2" data-testid="characters-count">
                {contentSize}/{validations.lengthMaxWithoutHtmlTags}{' '}
                <FormattedMessage {...coreSharedMessages.characters} />
            </Box>
            {_renderHiddenInput()}
        </FormGroup>
    );
}
