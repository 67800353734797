import React from 'react';
import { Flex, HStack, Text, Wrap } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { DashboardContext } from 'core/lib/shared/contexts/DashboardContext';
import {
    coreSharedMessages,
    filterBadges,
    FilterType,
    handleReset,
    SafeFormattedMessage,
    useFiltersForm,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { CrossIcon } from 'design-system/icons';
import {
    DateFilterContent,
    FilterBadge,
    TextFilterContent,
} from './FilterBadge';
import { FieldValues, UseFormReturn } from 'react-hook-form';

type FiltersBadgesProps = {
    type: FilterType;
    methods: UseFormReturn<FieldValues>;
    searchWidgetId: string;
    id?: string;
};
export default function FiltersBadges({
    type,
    methods,
    searchWidgetId,
    id,
}: FiltersBadgesProps) {
    const { selectedFilters, setSelectedFilters } =
        React.useContext(DashboardContext);
    const { data: filtersForm } = useFiltersForm(type, id);
    const { isMobile, isTablet } = useWindowBreakpoints();
    const isSmallDevice = isMobile || isTablet;

    function onRemoveFilter(name: string) {
        setSelectedFilters(
            selectedFilters.filter((filter) => filter.name !== name)
        );
        handleReset(setSelectedFilters, methods, name);
    }

    function _renderFilterValue(id, selectedValues) {
        if (selectedValues.type === 'date') {
            return (
                <DateFilterContent
                    name={id}
                    values={
                        selectedValues.values as {
                            startDate: string;
                            endDate: string;
                        }
                    }
                />
            );
        }

        if (selectedValues.type === 'text') {
            return (
                <TextFilterContent
                    name={id}
                    values={selectedValues.values as string[]}
                />
            );
        }
    }

    function _renderFilterCategoryBadge(id: string) {
        const selectedValues = selectedFilters?.find(({ name }) => name === id);

        return (
            <FilterBadge key={id} id={id} isSelected={!!selectedValues}>
                {!selectedValues ? (
                    <SafeFormattedMessage
                        {...filterBadges[id]}
                        debugKey={`filterBadge - ${id}`}
                    />
                ) : (
                    <HStack>
                        {_renderFilterValue(id, selectedValues)}

                        <CrossIcon
                            onClick={() => onRemoveFilter(id)}
                            cursor="pointer"
                            w="5"
                            h="5"
                            _hover={{
                                bg: 'white',
                                color: 'primary.main',
                                rounded: 'full',
                            }}
                        />
                    </HStack>
                )}
            </FilterBadge>
        );
    }

    const concernedFilters = selectedFilters?.filter(
        (filter) => filter.name !== searchWidgetId
    );

    return (
        <HStack spacing="1">
            <Wrap spacing="1" overflow="hidden" maxW="90%" maxH="8">
                {filtersForm?.widgets
                    ?.filter((widget) => widget.id !== searchWidgetId)
                    ?.map(({ id }) => _renderFilterCategoryBadge(id))}
            </Wrap>

            <FilterBadge isSelected={false}>
                <Flex
                    as="button"
                    bg="transparent"
                    p="0"
                    m="0"
                    h="unset"
                    _hover={{ bg: 'unset' }}>
                    <HStack spacing="1">
                        <Text
                            fontWeight="semibold"
                            _selection={{ bg: 'transparent' }}>
                            <FormattedMessage
                                {...coreSharedMessages.allFilters}
                            />
                        </Text>
                        {!!concernedFilters?.length && (
                            <Flex
                                bg={'primary.main'}
                                w="5"
                                h="5"
                                alignItems={
                                    isSmallDevice ? 'flex-start' : 'center'
                                }
                                justifyContent="center"
                                rounded="full"
                                color="white"
                                fontWeight="semibold"
                                _selection={{ bg: 'transparent' }}>
                                {concernedFilters?.length}
                            </Flex>
                        )}
                    </HStack>
                </Flex>
            </FilterBadge>
        </HStack>
    );
}
