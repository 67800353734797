import React, { SVGProps } from 'react';
import { useTheme } from '@chakra-ui/react';

type SuccessIllustrationProps = SVGProps<SVGSVGElement>;

export default function SuccessIllustration(props: SuccessIllustrationProps) {
    const { colors } = useTheme();

    return (
        <svg
            width="300"
            height="262"
            viewBox="0 0 300 262"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g clipPath="url(#clip0_16075_321589)">
                <path
                    d="M119.648 225.12C111.301 237.578 91.0494 239.679 88.6325 235.592C86.4281 231.864 97.8863 220.995 97.1142 220.311C96.2954 219.585 86.533 234.586 76.3172 233.323C70.0526 232.548 64.1124 225.721 64.5714 220.125C65.1211 213.425 74.7673 209.439 74.2219 208.505C73.6311 207.495 63.9226 214.918 52.3114 215.426C37.4319 216.076 22.49 205.23 23.5029 199.351C24.4677 193.758 39.938 192.301 39.6249 190.468C39.2948 188.539 22.7563 193.748 13.6343 186.026C6.83415 180.269 4.93862 167.989 9.12636 160.198C18.4992 142.756 59.3495 145.93 66.0377 159.221C67.9899 163.099 67.6485 169.067 69.2777 169.199C71.1208 169.349 71.227 161.691 75.6712 159.586C80.6551 157.225 89.4641 162.624 94.5571 169.272C102.765 179.984 100.832 193.289 103.577 193.461C106.037 193.615 106.694 182.882 109.94 182.52C115.232 181.931 130.754 208.545 119.65 225.12H119.648Z"
                    fill={colors.orange[200]}
                />
                <path
                    d="M95.1123 184.517C95.2299 183.366 83.1483 183.654 73.5828 177.313C64.5203 171.306 59.0957 160.263 58.5971 153.257C54.4391 151.847 49.5104 150.487 47.3117 152.617C43.0744 156.722 48.6774 174.06 60.936 182.185C67.977 186.852 76.3822 187.928 80.2795 188.629C80.3914 188.478 80.509 188.33 80.6309 188.188C84.9773 183.159 95.0046 185.58 95.1123 184.517Z"
                    fill={colors.red[200]}
                />
                <path
                    d="M92.9168 212.039C97.2348 209.426 106.674 208.019 106.503 207.242C106.272 206.187 89.7292 212.493 82.31 205.55C78.2483 201.749 77.2071 194.251 79.6126 189.695C76.2862 189.666 70.8773 189.712 67.983 193.06C64.369 197.241 65.1057 206.078 69.5994 210.359C72.2897 209.047 73.9855 208.103 74.2221 208.507C74.4558 208.907 72.821 209.867 70.832 211.359C77.5598 215.982 89.4969 212.501 92.9168 212.039Z"
                    fill={colors.red[200]}
                />
                <path
                    d="M90.8185 214.513C90.8681 213.996 91.143 213.513 91.5807 213.061C87.308 214.247 78.4806 216.184 78.1689 219.384C77.9323 221.81 82.5224 225.703 88.94 228.163C92.3967 225.237 95.0884 221.949 96.362 220.753C92.8416 218.615 90.6528 216.208 90.8185 214.513Z"
                    fill={colors.red[200]}
                />
                <path
                    d="M134.963 228.339C134.527 228.645 133.919 228.603 133.529 228.213C123.674 218.325 110.809 208.517 95.2915 199.066C82.8558 191.491 68.7031 184.13 53.2257 177.184C26.8526 165.347 5.50162 158.747 5.28911 158.681C4.69977 158.501 4.36826 157.877 4.5496 157.287C4.73094 156.699 5.3557 156.369 5.94504 156.549C6.15896 156.615 27.619 163.249 54.114 175.137C69.6806 182.121 83.9226 189.529 96.4405 197.153C112.116 206.701 125.127 216.621 135.112 226.639C135.547 227.076 135.545 227.782 135.108 228.216C135.062 228.261 135.013 228.302 134.962 228.337L134.963 228.339Z"
                    fill={colors.red[200]}
                />
                <path
                    d="M123.771 225.665C109.067 231.474 90.1937 219.648 90.8185 214.513C91.3582 210.082 106.715 208.207 106.503 207.24C106.272 206.186 89.729 212.491 82.3098 205.548C77.7594 201.291 77.0001 192.389 80.631 188.188C84.9789 183.159 95.0048 185.58 95.1125 184.517C95.23 183.366 83.1485 183.654 73.583 177.313C61.3244 169.188 55.7214 151.85 59.9587 147.746C63.9906 143.841 77.1927 151.665 78.0116 150.02C78.8743 148.288 62.632 142.84 59.8524 131.375C57.7812 122.829 63.431 111.925 71.3191 108.136C88.9739 99.6557 119.737 125.92 117.325 140.415C116.621 144.645 112.869 149.212 114.092 150.264C115.47 151.45 119.784 145.684 124.803 146.302C131.095 147.077 137.096 157.73 136.623 167.541C136.041 179.602 125.787 188.095 127.322 189.555C128.698 190.863 135.667 182.86 138.783 184.517C143.934 187.257 142.244 218.364 123.769 225.662L123.771 225.665Z"
                    fill={colors.orange[200]}
                />
                <path
                    d="M128.864 233.146C128.728 233.242 128.568 233.308 128.392 233.334C127.79 233.425 127.228 233.009 127.137 232.408C123.754 209.849 117.424 187.869 108.323 167.079C101.24 150.898 92.4308 135.362 82.1385 120.903C74.3864 110.011 65.7063 99.6131 56.3392 89.9937C55.9142 89.5568 55.9241 88.8598 56.3618 88.4357C56.7996 88.0116 57.498 88.0215 57.923 88.4583C67.3695 98.1583 76.1219 108.644 83.9392 119.627C94.3193 134.21 103.203 149.879 110.348 166.197C119.526 187.163 125.91 209.33 129.321 232.082C129.385 232.509 129.195 232.915 128.864 233.146Z"
                    fill={colors.red[200]}
                />
                <path
                    d="M187.767 228.993C182.345 225.518 195.446 207.618 205.884 157.597C216.012 109.058 212.217 84.9835 220.174 83.5653C228.838 82.0208 246.449 108.22 240.262 124.85C237.508 132.254 230.769 135.947 231.986 142.383C233.275 149.2 241.763 149.948 245.024 154.937C256.424 172.379 198.36 235.783 187.769 228.993H187.767Z"
                    fill={colors.teal[500]}
                />
                <path
                    d="M184.797 233.984C181.789 228.35 201.861 219.027 235.264 180.408C267.676 142.935 276.108 120.115 283.697 122.698C291.959 125.51 294.471 156.817 281.01 168.351C275.017 173.487 267.378 173.473 265.299 179.669C263.096 186.234 270.091 190.962 270.489 196.878C271.878 217.565 190.672 244.99 184.797 233.983V233.984Z"
                    fill={colors.teal[400]}
                />
                <path
                    d="M142 191.62V186.5H119V191.62H124.195C125.43 191.62 126.432 194.498 126.432 198.046V212.954C126.432 216.503 125.43 219.38 124.195 219.38H119V224.5H142V219.38H136.805C135.57 219.38 134.568 216.502 134.568 212.954V198.046C134.568 194.497 135.57 191.62 136.805 191.62H142Z"
                    fill={colors.tintedgray[500]}
                />
                <path
                    d="M165 191.62V186.5H142V191.62H147.195C148.43 191.62 149.432 194.498 149.432 198.046V212.954C149.432 216.503 148.43 219.38 147.195 219.38H142V224.5H165V219.38H159.805C158.57 219.38 157.568 216.502 157.568 212.954V198.046C157.568 194.497 158.57 191.62 159.805 191.62H165Z"
                    fill={colors.tintedgray[500]}
                />
                <path
                    d="M189 191.62V186.5H165V191.62H170.42C171.709 191.62 172.755 194.498 172.755 198.046V212.954C172.755 216.503 171.709 219.38 170.42 219.38H165V224.5H189V219.38H183.58C182.291 219.38 181.245 216.502 181.245 212.954V198.046C181.245 194.497 182.291 191.62 183.58 191.62H189Z"
                    fill={colors.tintedgray[500]}
                />
                <path
                    d="M230.391 224.538H86.0825V238.501H230.391V224.538Z"
                    fill={colors.tintedgray[300]}
                />
                <path
                    d="M105.5 224.5H86.5V238.5H105.5V224.5Z"
                    fill={colors.tintedgray[500]}
                />
                <path
                    d="M205.5 178.5H110.5V186.5H205.5V178.5Z"
                    fill={colors.tintedgray[300]}
                />
                <path
                    d="M123.5 178.5H110.5V186.5H123.5V178.5Z"
                    fill={colors.tintedgray[500]}
                />
                <path
                    d="M146.5 191.62V186.5H123.5V191.62H128.695C129.93 191.62 130.932 194.498 130.932 198.046V212.954C130.932 216.503 129.93 219.38 128.695 219.38H123.5V224.5H146.5V219.38H141.305C140.07 219.38 139.068 216.502 139.068 212.954V198.046C139.068 194.497 140.07 191.62 141.305 191.62H146.5Z"
                    fill={colors.tintedgray[200]}
                />
                <path
                    d="M169.5 191.62V186.5H146.5V191.62H151.695C152.93 191.62 153.932 194.498 153.932 198.046V212.954C153.932 216.503 152.93 219.38 151.695 219.38H146.5V224.5H169.5V219.38H164.305C163.07 219.38 162.068 216.502 162.068 212.954V198.046C162.068 194.497 163.07 191.62 164.305 191.62H169.5Z"
                    fill={colors.tintedgray[200]}
                />
                <path
                    d="M193.5 191.62V186.5H169.5V191.62H174.92C176.209 191.62 177.255 194.498 177.255 198.046V212.954C177.255 216.503 176.209 219.38 174.92 219.38H169.5V224.5H193.5V219.38H188.08C186.791 219.38 185.745 216.502 185.745 212.954V198.046C185.745 194.497 186.791 191.62 188.08 191.62H193.5Z"
                    fill={colors.tintedgray[200]}
                />
                <path
                    d="M193.5 186.5H123.5V188.5H193.5V186.5Z"
                    fill={colors.tintedgray[500]}
                />
                <path
                    d="M173.487 170.694H167.6V165.541H160.592V137.599H152.918V165.541H145.909V170.694H140.023C135.72 170.694 132.234 174.175 132.234 178.467H181.277C181.277 174.173 177.789 170.694 173.488 170.694H173.487Z"
                    fill={colors.tertiary.main}
                />
                <path
                    d="M119.45 83.2383V90.8557H126.135V98.4746H187.374V90.8557H194.06V83.2383H119.45Z"
                    fill={colors.tertiary.main}
                />
                <path
                    d="M208.897 104.474C205.03 101.145 199.918 99.6638 194.866 100.412L182.664 102.217V93.8574H130.845V102.219L118.643 100.413C113.593 99.6652 108.479 101.145 104.613 104.475C100.746 107.803 98.5293 112.635 98.5293 117.731C98.5293 121.927 100.042 125.988 102.789 129.165C105.536 132.342 109.34 134.432 113.499 135.047L140.68 139.069C145.097 142.561 150.682 144.648 156.757 144.648C162.832 144.648 168.415 142.561 172.833 139.069L200.014 135.047C204.173 134.432 207.977 132.342 210.724 129.165C213.471 125.988 214.984 121.927 214.984 117.731C214.984 112.635 212.767 107.803 208.901 104.475L208.897 104.474ZM107.371 125.218C105.572 123.137 104.581 120.478 104.581 117.729C104.581 114.393 106.034 111.227 108.565 109.049C110.666 107.24 113.329 106.265 116.067 106.265C116.628 106.265 117.193 106.306 117.755 106.389L130.844 108.326V118.792C130.844 123.639 132.181 128.173 134.506 132.049L114.385 129.072C111.661 128.669 109.17 127.3 107.371 125.219V125.218ZM206.137 125.218C204.338 127.299 201.847 128.668 199.123 129.071L179.002 132.048C181.326 128.171 182.664 123.637 182.664 118.791V108.325L195.753 106.388C199.062 105.899 202.41 106.868 204.943 109.047C207.474 111.227 208.926 114.391 208.926 117.728C208.926 120.476 207.936 123.135 206.137 125.217V125.218Z"
                    fill={colors.tertiary.main}
                />
                <path
                    d="M156.755 135.646C167.587 135.646 176.368 126.883 176.368 116.073C176.368 105.264 167.587 96.501 156.755 96.501C145.923 96.501 137.143 105.264 137.143 116.073C137.143 126.883 145.923 135.646 156.755 135.646Z"
                    fill={colors.success.main}
                />
                <path
                    d="M163.286 107.762L154.294 116.736L150.223 112.673L145.888 116.999L154.294 125.389L167.621 112.089L163.286 107.762Z"
                    fill="white"
                />
                <path
                    d="M168.816 64.6018C169.815 65.8204 175.83 64.4915 176.34 61.0023C176.572 59.4132 175.671 57.3194 174.372 57.0804C171.836 56.6125 167.656 63.188 168.816 64.6018Z"
                    fill="#E9A363"
                />
                <path
                    d="M171.127 39.6063C174.832 43.8067 166.311 60.3678 160.499 59.822C156.727 59.4672 151.853 51.69 154.862 44.8798C158.18 37.3712 168.575 36.7123 171.126 39.6063H171.127Z"
                    fill="#E9A363"
                />
                <path
                    d="M143.853 53.0232C141.816 53.3667 140.118 56.576 140.656 59.0727C141.621 63.5474 149.526 64.6289 150.918 62.7938C152.508 60.7014 147.455 52.4166 143.853 53.0217V53.0232Z"
                    fill="#E9A363"
                />
                <path
                    d="M152.788 4.40042L154.646 8.93301C154.882 9.50701 155.352 9.95235 155.938 10.1588L158.613 11.0961C160.551 11.7747 160.551 14.5104 158.613 15.1904L155.938 16.1278C155.352 16.3328 154.882 16.7795 154.646 17.3535L152.788 21.8861C152.051 23.683 149.501 23.683 148.763 21.8861L146.904 17.3535C146.669 16.7795 146.199 16.3342 145.612 16.1278L142.937 15.1904C140.999 14.5118 140.999 11.7761 142.937 11.0961L145.612 10.1588C146.199 9.95377 146.669 9.50701 146.904 8.93301L148.763 4.40042C149.5 2.6035 152.05 2.6035 152.788 4.40042Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M106.536 24.2381L107.639 26.9313C107.779 27.272 108.059 27.5364 108.407 27.6594L109.997 28.2164C111.148 28.6194 111.148 30.2452 109.997 30.6482L108.407 31.2052C108.059 31.3268 107.779 31.5926 107.639 31.9333L106.536 34.6265C106.098 35.6939 104.583 35.6939 104.146 34.6265L103.042 31.9333C102.902 31.5926 102.623 31.3282 102.274 31.2052L100.685 30.6482C99.5329 30.2452 99.5329 28.6194 100.685 28.2164L102.274 27.6594C102.623 27.5378 102.902 27.272 103.042 26.9313L104.146 24.2381C104.583 23.1706 106.098 23.1706 106.536 24.2381Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M183.393 11.1131L184.497 13.8063C184.637 14.147 184.916 14.4114 185.265 14.5344L186.854 15.0914C188.006 15.4944 188.006 17.1202 186.854 17.5231L185.265 18.0802C184.916 18.2018 184.637 18.4676 184.497 18.8083L183.393 21.5015C182.956 22.5689 181.441 22.5689 181.004 21.5015L179.9 18.8083C179.76 18.4676 179.481 18.2032 179.132 18.0802L177.543 17.5231C176.391 17.1202 176.391 15.4944 177.543 15.0914L179.132 14.5344C179.481 14.4128 179.76 14.147 179.9 13.8063L181.004 11.1131C181.441 10.0456 182.956 10.0456 183.393 11.1131Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M198.888 39.0546L199.652 40.9179C199.748 41.154 199.942 41.3378 200.183 41.4212L201.282 41.8072C202.079 42.0871 202.079 43.2111 201.282 43.4896L200.183 43.8756C199.942 43.9604 199.748 44.1428 199.652 44.3789L198.888 46.2422C198.585 46.9816 197.537 46.9816 197.234 46.2422L196.47 44.3789C196.374 44.1428 196.18 43.959 195.939 43.8756L194.839 43.4896C194.043 43.2097 194.043 42.0857 194.839 41.8072L195.939 41.4212C196.18 41.3364 196.374 41.154 196.47 40.9179L197.234 39.0546C197.537 38.3151 198.585 38.3151 198.888 39.0546Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M174.08 0.98237L174.745 2.60115C174.828 2.80615 174.997 2.96591 175.206 3.03942L176.161 3.37449C176.854 3.61766 176.854 4.59459 176.161 4.83634L175.206 5.17141C174.997 5.24493 174.83 5.40468 174.745 5.60968L174.08 7.22847C173.817 7.87032 172.906 7.87032 172.644 7.22847L171.979 5.60968C171.896 5.40468 171.727 5.24493 171.517 5.17141L170.563 4.83634C169.87 4.59317 169.87 3.61625 170.563 3.37449L171.517 3.03942C171.727 2.96591 171.894 2.80615 171.979 2.60115L172.644 0.98237C172.907 0.340512 173.818 0.340512 174.08 0.98237Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M135.413 18.5604L135.786 19.4695C135.832 19.584 135.927 19.6745 136.045 19.7155L136.58 19.9035C136.969 20.0392 136.969 20.5878 136.58 20.7235L136.045 20.9115C135.927 20.9525 135.834 21.0416 135.786 21.1575L135.413 22.0666C135.266 22.4271 134.754 22.4271 134.607 22.0666L134.234 21.1575C134.188 21.043 134.093 20.9525 133.975 20.9115L133.44 20.7235C133.051 20.5878 133.051 20.0392 133.44 19.9035L133.975 19.7155C134.093 19.6745 134.186 19.5854 134.234 19.4695L134.607 18.5604C134.754 18.1999 135.266 18.1999 135.413 18.5604Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M97.2646 35.7704L97.6372 36.6794C97.6839 36.794 97.7788 36.8844 97.8964 36.9254L98.4319 37.1135C98.8201 37.2492 98.8201 37.7977 98.4319 37.9335L97.8964 38.1215C97.7788 38.1625 97.6853 38.2516 97.6372 38.3675L97.2646 39.2766C97.1172 39.6371 96.6058 39.6371 96.4585 39.2766L96.0859 38.3675C96.0391 38.253 95.9442 38.1625 95.8266 38.1215L95.2911 37.9335C94.903 37.7977 94.903 37.2492 95.2911 37.1135L95.8266 36.9254C95.9442 36.8844 96.0377 36.7954 96.0859 36.6794L96.4585 35.7704C96.6058 35.4099 97.1172 35.4099 97.2646 35.7704Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M189.265 44.702L189.637 45.6111C189.684 45.7256 189.779 45.8161 189.896 45.8571L190.432 46.0451C190.82 46.1808 190.82 46.7294 190.432 46.8651L189.896 47.0531C189.779 47.0941 189.685 47.1832 189.637 47.2991L189.265 48.2082C189.117 48.5687 188.606 48.5687 188.458 48.2082L188.086 47.2991C188.039 47.1846 187.944 47.0941 187.827 47.0531L187.291 46.8651C186.903 46.7294 186.903 46.1808 187.291 46.0451L187.827 45.8571C187.944 45.8161 188.038 45.727 188.086 45.6111L188.458 44.702C188.606 44.3415 189.117 44.3415 189.265 44.702Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M131.05 43.5803C128.265 43.5803 126 41.3196 126 38.5401C126 35.7606 128.265 33.5 131.05 33.5C133.836 33.5 136.101 35.7606 136.101 38.5401C136.101 41.3196 133.836 43.5803 131.05 43.5803ZM131.05 34.9138C129.047 34.9138 127.417 36.541 127.417 38.5401C127.417 40.5392 129.047 42.1665 131.05 42.1665C133.054 42.1665 134.684 40.5392 134.684 38.5401C134.684 36.541 133.054 34.9138 131.05 34.9138Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M201.05 32.5803C198.265 32.5803 196 30.3196 196 27.5401C196 24.7606 198.265 22.5 201.05 22.5C203.836 22.5 206.101 24.7606 206.101 27.5401C206.101 30.3196 203.836 32.5803 201.05 32.5803ZM201.05 23.9138C199.047 23.9138 197.417 25.541 197.417 27.5401C197.417 29.5392 199.047 31.1665 201.05 31.1665C203.054 31.1665 204.684 29.5392 204.684 27.5401C204.684 25.541 203.054 23.9138 201.05 23.9138Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M95.7756 23.4018C94.0685 23.4018 92.6787 22.0148 92.6787 20.3112C92.6787 18.6076 94.0685 17.2207 95.7756 17.2207C97.4827 17.2207 98.8725 18.6076 98.8725 20.3112C98.8725 22.0148 97.4827 23.4018 95.7756 23.4018ZM95.7756 18.0888C94.5473 18.0888 93.5471 19.0869 93.5471 20.3126C93.5471 21.5384 94.5459 22.5365 95.7756 22.5365C97.0053 22.5365 98.0041 21.5384 98.0041 20.3126C98.0041 19.0869 97.0053 18.0888 95.7756 18.0888Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M204.046 10.7982C202.339 10.7982 200.949 9.41133 200.949 7.70772C200.949 6.00411 202.339 4.61719 204.046 4.61719C205.753 4.61719 207.143 6.00411 207.143 7.70772C207.143 9.41133 205.753 10.7982 204.046 10.7982ZM204.046 5.48525C202.818 5.48525 201.818 6.48338 201.818 7.70913C201.818 8.93488 202.816 9.93301 204.046 9.93301C205.276 9.93301 206.275 8.93488 206.275 7.70913C206.275 6.48338 205.276 5.48525 204.046 5.48525Z"
                    fill={colors.primary[200]}
                />
                <path
                    d="M158.236 261.5C215.299 261.5 261.558 258.849 261.558 255.579C261.558 252.309 215.299 249.658 158.236 249.658C101.173 249.658 54.9141 252.309 54.9141 255.579C54.9141 258.849 101.173 261.5 158.236 261.5Z"
                    fill={colors.primary[100]}
                />
            </g>
            <defs>
                <clipPath id="clip0_16075_321589">
                    <rect
                        width="300"
                        height="261"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
