import { formatSocialSecurityNumber, type FormValues } from 'core';
import { formatFormFinalObject } from './handlers';

function transformSocialSecurityNumber(inputId: string, data: FormValues) {
    const parsedData = {
        value: formatSocialSecurityNumber.compact(data.value),
    };
    return formatFormFinalObject(inputId, parsedData);
}

export default transformSocialSecurityNumber;
