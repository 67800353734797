import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Textarea, Box } from '@chakra-ui/react';
import {
    useSafeIntl,
    formMessages,
    FormGroup,
    useInputValidation,
    WidgetProps,
    coreSharedMessages,
} from 'core';
import { ChangeHandler } from 'react-hook-form';

function useCharactersCount(onChange: ChangeHandler) {
    const [charactersCount, setCharactersCount] = React.useState(0);

    function handleCharactersCount(
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) {
        onChange(event);
        setCharactersCount(event.target.value.length);
    }

    return { charactersCount, handleCharactersCount };
}

function NativeTextarea({
    name,
    id,
    validations,
    title,
    links,
    readonly,
    description,
    help,
    placeholder,
    defaultValue,
}: WidgetProps) {
    const { safeFormatMessage } = useSafeIntl();
    const inputName = name ?? `${id}.value`;
    const { registerValues } = useInputValidation(validations, inputName);
    const { charactersCount, handleCharactersCount } = useCharactersCount(
        registerValues.onChange
    );

    return (
        <FormGroup
            label={title}
            name={inputName}
            isRequired={validations.required}
            isReadOnly={readonly}
            {...{ id, description, help, links }}>
            <Textarea
                {...registerValues}
                bg="white"
                defaultValue={defaultValue}
                placeholder={
                    placeholder &&
                    safeFormatMessage(
                        formMessages[placeholder],
                        null,
                        placeholder
                    )
                }
                cols={4}
                rows={5}
                maxLength={validations.lengthMax}
                minLength={validations.lengthMin}
                wrap="hard"
                onChange={handleCharactersCount}
            />
            <Box mt="2" textAlign="left">
                {charactersCount}/{validations.lengthMax}{' '}
                <FormattedMessage {...coreSharedMessages.characters} />
            </Box>
        </FormGroup>
    );
}

export default NativeTextarea;
