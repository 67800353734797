import React from 'react';
import { FormObject, DashboardContext } from 'core';
import { useRouter } from 'next/router';
import {
    autoSelectCompanyGroup,
    autoSelectContractClause,
    autoSelectStatus,
    onFiltersSubmit,
} from '../helpers';
import { useFormContext } from 'react-hook-form';

export function useAutoFilterSelection(
    filtersForm: FormObject,
    filterName: string,
    isFormRendered: boolean
) {
    const router = useRouter();
    const {
        query: { selectedGroup, selectedClause, selectedStatus, entityName },
    } = router;
    const methods = useFormContext();
    const { getValues } = methods;
    const { setSelectedFilters, setIsFiltersOpened } =
        React.useContext(DashboardContext);

    const formData = React.useMemo<FormObject>(() => {
        if (selectedGroup && filterName === 'groups') {
            return autoSelectCompanyGroup(
                filtersForm,
                selectedGroup as string,
                entityName as string
            );
        }

        if (selectedClause && filterName === 'clauses') {
            return autoSelectContractClause(
                filtersForm,
                selectedClause as string
            );
        }

        if (selectedStatus) {
            return autoSelectStatus(filtersForm, selectedStatus as string);
        }

        return filtersForm;
    }, [
        filtersForm,
        selectedClause,
        selectedGroup,
        selectedStatus,
        entityName,
        filterName,
    ]);

    React.useEffect(() => {
        if (
            (!['groups', 'clauses'].includes(filterName) && !selectedStatus) ||
            !isFormRendered
        ) {
            return;
        }

        if ((selectedGroup || selectedClause || selectedStatus) && getValues) {
            onFiltersSubmit(getValues, setSelectedFilters, setIsFiltersOpened);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filtersForm,
        filterName,
        selectedGroup,
        selectedClause,
        selectedStatus,
        isFormRendered,
    ]);

    return formData;
}
