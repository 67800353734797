import { WidgetKeys, prefillWidget } from 'core';
import { WorkflowTeleconsultationData } from 'lib/teleconsultation/types';

const getCountryByIdDefaultFrance = (item, idTargetCountry) => {
    const findTargetCountry = (country) => country.id === idTargetCountry;
    const findFranceCountry = (country) => country.name === 'France';

    return item.options[1].subWidget.options?.find(
        idTargetCountry ? findTargetCountry : findFranceCountry
    );
};

function getWidgetDefaultValue(
    item: WidgetKeys,
    data: WorkflowTeleconsultationData
) {
    switch (item.type) {
        case 'conditionalRadio':
            if (item.id === 'countryId') {
                const country = getCountryByIdDefaultFrance(
                    item,
                    data.countryId
                );

                return {
                    defaultValue:
                        !country || country?.label === 'France' ? '1' : '2',
                    subDefaultValue:
                        country?.label === 'France'
                            ? undefined
                            : data.countryId,
                };
            }

            if (item.id === 'preferredCanal') {
                return {
                    defaultValue: data[item?.id] === 'Video' ? '2' : '1',
                    subDefaultValue: data.recallPhoneNumber?.value,
                };
            }

            return data[item.id]?.value;
        default:
            return data[item.id]?.value || data[item.id];
    }
}

export function prefillTeleconsultationForm(
    item: WidgetKeys,
    data: WorkflowTeleconsultationData
) {
    prefillWidget(item, getWidgetDefaultValue(item, data));
}
