import React from 'react';
import { Box, Divider, HStack, Text } from '@chakra-ui/react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { FormWorkflow } from '../../../lib/forms';
import { WorkflowTypesEnum, coreSharedMessages } from '../../../lib/shared';
import SignatureFooterButtons from '../../signature/SignatureFooterButtons';
import { InfoIcon } from 'design-system/icons';
import { getSignatureCancelButtonHref } from '../../../lib/signature';

type BeneficiariesSignatureFooterProps = {
    workflow: FormWorkflow;
    service: WorkflowTypesEnum;
    type: 'full-capital' | 'divided-capital';
    isSubmitEnabled: () => boolean;
    isSubmitting: boolean;
    onSubmit: () => void;
    cumulatedCapitalErrorToast: () => void;
    fullCapitalBeneficiariesErrorType: 'main' | 'rank';
    noMainBeneficiaryToast: () => void;
    beneficiariesRanksToast: () => void;
};

const intlMessages = defineMessages({
    description: {
        id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiary.text',
        defaultMessage:
            'Si tous les bénéficiaires décèdent avant vous, le capital sera versé à vos héritiers.',
    },
});

export const BeneficiariesSignatureFooter = ({
    workflow,
    service,
    type,
    isSubmitEnabled,
    isSubmitting,
    onSubmit,
    cumulatedCapitalErrorToast,
    fullCapitalBeneficiariesErrorType,
    noMainBeneficiaryToast,
    beneficiariesRanksToast,
}: BeneficiariesSignatureFooterProps) => (
    <Box mt="5">
        <Divider />
        <HStack lineHeight="normal" spacing="3" mt="5">
            <InfoIcon fontSize="xl" />
            <Text fontSize="sm">
                <FormattedMessage {...intlMessages.description} />
            </Text>
        </HStack>
        <SignatureFooterButtons
            cancelButton={{
                href: getSignatureCancelButtonHref(workflow, null, service),
                labelTranslationKey: coreSharedMessages.cancel,
            }}
            submitButton={{
                type: 'button',
                labelTranslationKey: coreSharedMessages.validate,
                disabled: !isSubmitEnabled(),
                isLoading: isSubmitting,
                onClick: () => {
                    if (type === 'divided-capital') {
                        isSubmitEnabled()
                            ? onSubmit()
                            : cumulatedCapitalErrorToast();
                    }

                    if (type === 'full-capital') {
                        isSubmitEnabled()
                            ? onSubmit()
                            : fullCapitalBeneficiariesErrorType === 'main'
                            ? noMainBeneficiaryToast()
                            : beneficiariesRanksToast();
                    }
                },
            }}
        />
    </Box>
);
