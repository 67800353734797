import { useInputValidation } from 'core';
import { omit } from 'lodash';
import InputBase from '../InputBase';
import { WidgetProps } from './types';
import getInputName from 'core/lib/forms/helpers/getInputName';

type InputEmailProps = WidgetProps & {
    hasNoPattern?: boolean;
};

function InputEmail(props: Omit<InputEmailProps, 'type'>) {
    const { validations, hasNoPattern } = props;
    const { registerValues } = useInputValidation(
        validations,
        getInputName(props.id, props.name),
        {
            ...(!hasNoPattern && { type: 'email' }),
            sameAs: validations.sameAs,
        }
    );

    return (
        <InputBase
            inputMode="email"
            autoComplete="email"
            {...registerValues}
            {...{
                type: 'email',
                ...omit(props, 'hasNoPattern'),
            }}
        />
    );
}

export default InputEmail;
