import { Icon, IconProps } from '@chakra-ui/react';

export default function LogoutIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M11 10a1 1 0 1 0 2 0V3a1 1 0 1 0-2 0v7zM8.465 7.361C8.945 7.09 9.202 6.514 9 6c-.202-.514-.786-.771-1.275-.513C4.922 6.967 3 9.864 3 13.217 3 18.093 7.056 22 12 22s9-3.907 9-8.783c0-3.353-1.922-6.25-4.725-7.73-.489-.258-1.073 0-1.275.513-.202.514.055 1.089.535 1.361C17.617 8.544 19 10.732 19 13.217 19 16.937 15.892 20 12 20s-7-3.063-7-6.783c0-2.485 1.383-4.673 3.465-5.856z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
