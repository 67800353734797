import { Icon, IconProps } from '@chakra-ui/react';

export default function PlaneRightIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M20.63 13.698c1.782-.633 1.772-3.156-.014-3.775L4.7 4.41c-1.53-.53-3.023.884-2.577 2.441l1.48 5.164-1.434 4.878c-.461 1.567 1.05 2.996 2.588 2.449l15.874-5.643zM5.752 11.25 4.5 6.803l12.8 4.447H5.751zM4.5 17l1.25-4.25h11.536L4.5 17z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
