import { Icon, IconProps } from '@chakra-ui/react';

export default function CarIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.68513 4H16.3149C16.8244 4 17.2525 4.38314 17.3088 4.88957L17.7655 9H6.23453L6.69124 4.88957C6.74751 4.38314 7.17558 4 7.68513 4ZM4.21056 9.10495L4.22222 9L4.70348 4.6687C4.87229 3.14941 6.15648 2 7.68513 2H16.3149C17.8435 2 19.1277 3.14941 19.2965 4.66871L19.7778 9L19.7894 9.10495C21.0633 9.4515 22 10.6164 22 12V16V18H21V20C21 21.1046 20.1046 22 19 22H17C15.8954 22 15 21.1046 15 20V18H9V20C9 21.1046 8.10457 22 7 22H5C3.89543 22 3 21.1046 3 20V18H2V16V12C2 10.6164 2.9367 9.4515 4.21056 9.10495ZM5 11C4.44772 11 4 11.4477 4 12V16H5H7H17H19H20V12C20 11.4477 19.5523 11 19 11H17.9877H6.01231H5ZM7 18V20H5V18H7ZM17 20V18H19V20H17ZM6.5 14C6.77614 14 7 13.7761 7 13.5C7 13.2239 6.77614 13 6.5 13C6.22386 13 6 13.2239 6 13.5C6 13.7761 6.22386 14 6.5 14ZM6.5 15C7.32843 15 8 14.3284 8 13.5C8 12.6716 7.32843 12 6.5 12C5.67157 12 5 12.6716 5 13.5C5 14.3284 5.67157 15 6.5 15ZM18 13.5C18 13.7761 17.7761 14 17.5 14C17.2239 14 17 13.7761 17 13.5C17 13.2239 17.2239 13 17.5 13C17.7761 13 18 13.2239 18 13.5ZM19 13.5C19 14.3284 18.3284 15 17.5 15C16.6716 15 16 14.3284 16 13.5C16 12.6716 16.6716 12 17.5 12C18.3284 12 19 12.6716 19 13.5Z"
                fill="currentColor"
            />
        </Icon>
    );
}
