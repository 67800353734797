const shouldFilterAPIError = ({ filterPath, filterStatus }, errorMessage) => {
    const isPathMatching = !!errorMessage?.config?.url.match(filterPath);
    const isStatusCodeMatching =
        filterStatus === 'all' ||
        errorMessage?.response?.status === filterStatus;

    return isPathMatching && isStatusCodeMatching;
};

module.exports = { shouldFilterAPIError };
