import axios from 'axios';
import * as Sentry from '@sentry/nextjs';

export const postNotificationTokenPath =
    '/api/proxy/security/user/notifications/token';

type NotificationTokenData = {
    token: string;
    generationDate: Date;
    lastSentTime?: Date;
};

export async function postNotificationToken() {
    try {
        const storedToken = window.localStorage.getItem('notificationToken');
        if (!storedToken) {
            return null;
        }

        const notificationData: NotificationTokenData = JSON.parse(storedToken);
        if (!notificationData?.token) return null;

        return axios.post(postNotificationTokenPath, {
            token: notificationData.token,
        });
    } catch (error) {
        Sentry.captureMessage(error);
    }
}
