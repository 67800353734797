import { Icon, IconProps } from '@chakra-ui/react';

export default function LockCloseIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M7 9V6a4 4 0 0 1 4-4h1a4 4 0 0 1 4 4v3h1a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h1zm4-5h1a2 2 0 0 1 2 2v3H9V6a2 2 0 0 1 2-2zm-5 7v9h11v-9H6z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
