import { FormValues } from 'core';
import { Step } from 'lib/form-wizard/types';
import { PrimoContextData, PrimoStepsEnum } from 'lib/primo/types';

export const formatPrimoContextData = (
    step: Step['name'],
    data: FormValues
): Partial<PrimoContextData> => {
    switch (step) {
        case PrimoStepsEnum.SEND_CODE_VERIFICATION_SEPA_MANDATE:
            return { signaturePhoneNumber: data?.phoneNumber?.value };
        default:
            return {};
    }
};
