import { Icon, IconProps } from '@chakra-ui/react';

export default function UpdateIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M19.097 9.076c-.521.183-1.085-.097-1.348-.583a6.27 6.27 0 0 0-11.342.657l2.511-.845a1 1 0 1 1 .638 1.895l-4.099 1.38a1 1 0 0 1-1.24-.558c-.23-.543-1.08-2.115-1.68-3.197a1.012 1.012 0 0 1 .38-1.368.989.989 0 0 1 1.357.376c.165.297.35.635.539.982a8.272 8.272 0 0 1 14.842.01c.243.495-.037 1.069-.558 1.251zM4.89 14.798c.52-.182 1.084.098 1.347.584a6.27 6.27 0 0 0 11.304-.564l-2.452.945a1 1 0 1 1-.719-1.866l4.118-1.587a1 1 0 0 1 1.28.542c.24.563 1.102 2.296 1.694 3.436.254.49.083 1.104-.397 1.378a.964.964 0 0 1-1.34-.385c-.18-.344-.383-.74-.589-1.147a8.272 8.272 0 0 1-14.804-.084c-.244-.496.036-1.07.557-1.252z"
                fill="currentColor"
            />
        </Icon>
    );
}
