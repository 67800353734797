import {
    HStack,
    VStack,
    Flex,
    Button,
    StackProps,
    Heading,
    useDisclosure,
    ButtonProps,
    Box,
    FlexProps,
    forwardRef,
    LinkOverlay,
    LinkBox,
} from '@chakra-ui/react';
import ConsentModal from 'components/ConsentModal';
import { LinkIntl } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ArrowRightIcon } from 'design-system/icons';
import { ConsentModalEnum, consentModalConfig } from 'lib/consent-modal';
import { healthCareMessages } from 'lib/health-care/i18n';
import { menuMessages } from 'lib/menu/i18n';
import { sharedMessages, useEligibility } from 'lib/shared';
import { pageTitles } from 'lib/site';
import { PropsWithChildren, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UrlObject } from 'url';
import Logo from './Logo';
import { Tag } from 'design-system/components';

type UserInfoProps = FlexProps;

type ButtonLinkProps = PropsWithChildren<ButtonProps>;

type MenuLinkProps = ButtonLinkProps & {
    href?: UrlObject | string;
};

const Section = ({ children, ...props }: PropsWithChildren<StackProps>) => {
    const { isMobile, isSmallDevice, isDesktop } = useWindowBreakpoints();

    return (
        <VStack
            px={isDesktop ? 12 : 6}
            py={isDesktop ? 9 : 8}
            alignItems="flex-start"
            gap={6}
            {...(isSmallDevice ? { w: 'full' } : {})}
            {...(isMobile ? { flexGrow: 1 } : {})}
            {...props}>
            {children}
        </VStack>
    );
};

const Title = ({ children }: PropsWithChildren) => (
    <Heading as="p" fontWeight="semibold" fontSize="lg">
        {children}
    </Heading>
);

const ButtonLink = ({ children, ...props }: ButtonLinkProps) => {
    const { isDesktop } = useWindowBreakpoints();

    return (
        <Button
            fontWeight="semibold"
            variant="link"
            color="blackandwhite.black"
            fontSize="lg"
            w="full"
            justifyContent="flex-start"
            whiteSpace="normal"
            textAlign="left"
            {...(isDesktop
                ? {}
                : {
                      rightIcon: <ArrowRightIcon />,
                      justifyContent: 'space-between',
                  })}
            {...props}>
            {children}
        </Button>
    );
};

const MenuLink = ({ href, children, ...props }: MenuLinkProps) => {
    if (href) {
        return (
            <ButtonLink as={LinkBox} cursor="pointer">
                <LinkOverlay
                    as={LinkIntl}
                    // @ts-expect-error
                    href={href}>
                    {children}
                </LinkOverlay>
            </ButtonLink>
        );
    }

    return <ButtonLink {...props}>{children}</ButtonLink>;
};

const UserInfo = forwardRef((props: UserInfoProps, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isMobile, isDesktop } = useWindowBreakpoints();
    const [selectedType, setSelectedType] = useState<ConsentModalEnum | null>(
        null
    );
    const { data: eligibility } = useEligibility();

    const onOpenConsentModal = (type: ConsentModalEnum) => {
        setSelectedType(type);
        onOpen();
    };
    const { formatMessage } = useIntl();

    const _renderConsentModal = (): JSX.Element => {
        if (!selectedType) return null;

        return (
            <ConsentModal
                {...{ isOpen, onClose }}
                {...consentModalConfig[selectedType]}
            />
        );
    };

    return (
        <Flex
            gap={0}
            alignItems="stretch"
            flexDirection={isDesktop ? 'row' : 'column-reverse'}
            overflowY={isMobile ? 'scroll' : 'auto'}
            ref={ref}
            {...props}>
            <Section
                flexGrow={1}
                bg="bg.colorextralight"
                {...(isMobile ? { justifyContent: 'center' } : {})}>
                <HStack>
                    <Title>
                        <FormattedMessage {...healthCareMessages.title} />
                    </Title>
                    <Tag
                        rounded="full"
                        colorScheme="green"
                        variant="subtle"
                        formattedText={formatMessage(sharedMessages.new)}
                    />
                </HStack>
                <MenuLink
                    {...(eligibility?.features?.europAssistanceWorkflow
                        ? {
                              onClick: () =>
                                  onOpenConsentModal(
                                      ConsentModalEnum.APPOINTMENT
                                  ),
                          }
                        : {
                              href: '/medical-sphere',
                          })}>
                    <FormattedMessage
                        {...healthCareMessages.appointmentTitle}
                    />
                </MenuLink>
                <MenuLink
                    {...(eligibility?.features?.europAssistanceWorkflow
                        ? {
                              onClick: () =>
                                  onOpenConsentModal(
                                      ConsentModalEnum.PREVENTION
                                  ),
                          }
                        : {
                              href: '/medical-sphere',
                          })}>
                    <FormattedMessage {...healthCareMessages.preventionTitle} />
                </MenuLink>
                {eligibility?.features?.medicalib && (
                    <MenuLink
                        onClick={() =>
                            onOpenConsentModal(
                                ConsentModalEnum.HOME_CONSULTATION
                            )
                        }>
                        <FormattedMessage
                            {...healthCareMessages.homeCareTitle}
                        />
                    </MenuLink>
                )}
                <LinkIntl href="/medical-sphere">
                    <Button
                        as={Box}
                        mt={3}
                        rightIcon={<ArrowRightIcon />}
                        colorScheme="primary"
                        borderRadius="full">
                        <FormattedMessage {...sharedMessages.discover} />
                    </Button>
                </LinkIntl>
            </Section>
            <Section
                w={isDesktop ? '270px' : 'auto'}
                {...(isDesktop ? { px: 8 } : { w: 'full' })}
                {...(isMobile
                    ? { gap: 0, justifyContent: 'space-between' }
                    : {})}>
                {isMobile && <Logo alignSelf="flex-start" />}
                <VStack gap="6" w="full" alignItems="flex-start">
                    <Title>
                        <FormattedMessage {...menuMessages.insurance} />
                    </Title>
                    {eligibility?.features?.requests && (
                        <MenuLink href="/messages/new">
                            <FormattedMessage {...pageTitles.newRequest} />
                        </MenuLink>
                    )}
                    {eligibility?.features?.guarantees && (
                        <MenuLink href="/account/guarantees/families">
                            <FormattedMessage
                                {...menuMessages.guaranteesServices}
                            />
                        </MenuLink>
                    )}
                    {!eligibility?.features?.guarantees &&
                        eligibility?.features?.includedServices && (
                            <MenuLink href="/included-services">
                                <FormattedMessage {...menuMessages.services} />
                            </MenuLink>
                        )}
                    {eligibility?.features?.contracts && (
                        <MenuLink href="/account">
                            <FormattedMessage {...sharedMessages.contracts} />
                        </MenuLink>
                    )}
                    <MenuLink href="/account/profile">
                        <FormattedMessage {...pageTitles.account} />
                    </MenuLink>
                    <MenuLink href="/help/faq">
                        <FormattedMessage {...sharedMessages.help} />
                    </MenuLink>
                </VStack>
            </Section>
            {isOpen && _renderConsentModal()}
        </Flex>
    );
});

export default UserInfo;
