import { Icon, IconProps } from '@chakra-ui/react';

export default function CheckCircleIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M16.607 9.856a1 1 0 0 0-1.51-1.312l-4.108 4.725-2.113-1.937a1 1 0 1 0-1.352 1.475l2.87 2.63a1.002 1.002 0 0 0 1.43-.08l4.783-5.5z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm2 0a8 8 0 1 1 16 0 8 8 0 0 1-16 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
