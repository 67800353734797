import React from 'react';
import { Box, As } from '@chakra-ui/react';

type LegendProps = React.PropsWithChildren<{
    as?: As;
}>;

export function Legend({ children, as = 'figcaption' }: LegendProps) {
    return (
        <Box as={as} style={{ width: '100%' }}>
            <ul>{children}</ul>
        </Box>
    );
}
