import { Icon, IconProps } from '@chakra-ui/react';

export default function StackIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M3.557 14.833a2.001 2.001 0 0 1-1.169-2.162l1.333-8A2 2 0 0 1 5.694 3h12.612a2 2 0 0 1 1.973 1.671l1.333 8a2.001 2.001 0 0 1-1.17 2.162l.134.915a1.751 1.751 0 0 1-1.16 1.907l.16 1.092a1.75 1.75 0 0 1-1.732 2.003H6.156a1.75 1.75 0 0 1-1.731-2.003l.16-1.092a1.751 1.751 0 0 1-1.16-1.907l.132-.915zM5.694 5h12.612l1.328 7.969-.216.031H4.582l-.216-.031L5.694 5zM5.05 15l-.14.964a.25.25 0 0 0 .247.286h13.688a.25.25 0 0 0 .247-.286l-.14-.964H5.05zm1.037 2.75h11.828l.177 1.214a.25.25 0 0 1-.247.286H6.156a.25.25 0 0 1-.247-.286l.177-1.214z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
