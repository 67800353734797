import { capitalize, toLower } from 'lodash';

export function capitalizeHyphenatedName(hyphenatedName: string) {
    const allNames = toLower(hyphenatedName).split(' ');
    const capitalizedNames = allNames.map((name) =>
        name
            .split('-')
            .map((n) => capitalize(n))
            .join('-')
    );

    return capitalizedNames.join(' ');
}
