import { ComponentType, useEffect } from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage, defineMessages } from 'react-intl';
import {
    Box,
    Button,
    Container,
    Divider,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import {
    GlobalLayoutType,
    HeaderBoxType,
    HeaderSecondaryType,
    PEWorkflowTypesEnum,
    RelatedActionsCategories,
    WorkflowTypesEnum,
    coreSharedMessages,
    ProcedureTypesEnum,
    useBackBreadcrumbWithOnClick,
} from '../../../lib/shared';
import {
    PEWelcomeSteps,
    signatureSubtitles,
    signatureWelcomeMessages,
} from '../../../lib/signature';
import { CONTAINER_WIDTHS, LinkIntl } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import StepItem from './StepItem';

const intlMessages = {
    common: defineMessages({
        welcomeText: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.welcome.text',
            defaultMessage:
                "Toute demande de désignation de bénéficiaires spécifiques fera l'objet d'une vérification par le centre de gestion et ne sera effective qu'après validation.",
        },
    }),
};

type GlobalLayoutProps = GlobalLayoutType & {
    footerSticky?: boolean;
    disableFooter?: boolean;
    type?: PEWorkflowTypesEnum;
};

type HeaderBoxProps = HeaderBoxType;

type HeaderSecondaryProps = HeaderSecondaryType & {
    breadcrumbsIconColor?: string;
} & React.PropsWithChildren<{
        relatedActions?: RelatedActionsCategories;
    }>;

type pathType = {
    pathname: string;
    query: {
        [key: string]: string | string[];
    };
};

type WelcomeProps = {
    project: WorkflowTypesEnum;
    workflow: ProcedureTypesEnum;
    GlobalLayout: ComponentType<GlobalLayoutProps>;
    HeaderBox: ComponentType<HeaderBoxProps>;
    HeaderSecondary: ComponentType<HeaderSecondaryProps>;
    signatureSteps: { [key: string]: string[] };
    nextButtonHref: { [key: string]: pathType };
    startProcedure?: () => void;
    isNextStepLoading?: boolean;
};

export default function Welcome({
    project,
    workflow,
    GlobalLayout,
    HeaderBox,
    HeaderSecondary,
    signatureSteps,
    nextButtonHref,
    startProcedure,
    isNextStepLoading,
}: WelcomeProps) {
    const router = useRouter();
    const backBreadcrumbWithOnClick = useBackBreadcrumbWithOnClick();
    const { isMobile } = useWindowBreakpoints();
    useEffect(() => {
        if (!workflow || !(workflow in signatureWelcomeMessages)) router.back();
    }, [workflow, router]);

    if (!workflow || !(workflow in signatureWelcomeMessages)) return null;

    const _renderStepItems = () => {
        const steps =
            project === WorkflowTypesEnum.PA
                ? signatureSubtitles
                : PEWelcomeSteps;

        return signatureSteps[workflow].map((key, i, row) => {
            return (
                <StepItem
                    key={key}
                    elementColor={steps[key].color}
                    text={<FormattedMessage {...steps[key]} />}
                    textColor={
                        project === WorkflowTypesEnum.PA
                            ? 'secondary.main'
                            : 'primary.main'
                    }
                    isFinalStep={i + 1 === row.length}
                />
            );
        });
    };

    return (
        <GlobalLayout
            title={signatureWelcomeMessages[workflow].title}
            py={isMobile ? '12' : '0'}
            {...(project === WorkflowTypesEnum.PE
                ? { type: PEWorkflowTypesEnum.BD }
                : {})}>
            <HeaderBox pt={isMobile ? '0' : '5'}>
                <HeaderSecondary
                    isBordered
                    size="md"
                    breadcrumbs={[backBreadcrumbWithOnClick]}>
                    <FormattedMessage
                        {...signatureWelcomeMessages[workflow].title}
                    />
                </HeaderSecondary>
            </HeaderBox>
            <Container
                size={CONTAINER_WIDTHS.SECONDARY}
                p={isMobile ? '0' : undefined}>
                <Stack spacing="6" mt="6" mb="8" fontSize="md">
                    <Box px={isMobile ? '4' : undefined}>
                        {project === WorkflowTypesEnum.PE && (
                            <>
                                <Text>
                                    <FormattedMessage
                                        {...intlMessages.common.welcomeText}
                                    />
                                </Text>
                                <br />
                            </>
                        )}
                        <Text
                            {...(project === WorkflowTypesEnum.PE && {
                                fontWeight: 'bold',
                            })}>
                            <FormattedMessage
                                {...signatureWelcomeMessages[workflow]
                                    .description}
                            />
                        </Text>
                    </Box>

                    <Stack
                        {...(isMobile
                            ? {
                                  py: 5,
                                  px: 7,
                                  bg: 'gray.50',
                              }
                            : {})}
                        gap="4"
                        w="100%">
                        <Text as="h4" fontWeight="bold" fontSize="lg">
                            <FormattedMessage
                                {...signatureWelcomeMessages[workflow]
                                    .stepTitle}
                            />
                        </Text>
                        <VStack
                            alignItems="flex-start"
                            bg={!isMobile ? 'gray.50' : undefined}
                            p={!isMobile ? '6' : undefined}
                            borderRadius={!isMobile ? '2xl' : undefined}>
                            {_renderStepItems()}
                        </VStack>
                    </Stack>
                    {project === WorkflowTypesEnum.PA &&
                        workflow === ProcedureTypesEnum.GUIDED && (
                            <Box px={isMobile ? '4' : undefined}>
                                <Divider />
                                <Text
                                    mt="6"
                                    color="grey.700"
                                    fontStyle="italic"
                                    fontSize="xs">
                                    <FormattedMessage
                                        {...intlMessages.common.welcomeText}
                                    />
                                </Text>
                            </Box>
                        )}
                    <Button
                        style={isMobile ? { marginRight: '16px' } : undefined}
                        w="fit-content"
                        colorScheme="primary"
                        type="submit"
                        px="6"
                        alignSelf="flex-end"
                        {...(project === WorkflowTypesEnum.PA &&
                        workflow === ProcedureTypesEnum.STANDARD
                            ? {
                                  onClick: startProcedure,
                                  isLoading: isNextStepLoading,
                              }
                            : {
                                  as: LinkIntl,
                                  href: nextButtonHref[workflow],
                              })}>
                        <FormattedMessage {...coreSharedMessages.next} />
                    </Button>
                </Stack>
            </Container>
        </GlobalLayout>
    );
}
