import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { FetchQueryParams } from 'core';

export const path = `/api/proxy/[service]/signature/[procedureId]/files`;
const legalDocumentsFilesPath = `/api/proxy/employee-legal-documents/files`;

export type SignatureFile = { id: string; name: string; type: string };

export async function fetchSignatureFiles({
    queryKey: [urn, procedureId, service],
}: FetchQueryParams) {
    try {
        const response = await axios.get(
            service === 'employee-legal-documents'
                ? legalDocumentsFilesPath.replace('[service]', service)
                : urn
                      .replace('[service]', service)
                      .replace('[procedureId]', procedureId)
        );

        return response.data?.data?.files as SignatureFile[];
    } catch (error) {
        throw error;
    }
}

export function useSignatureFiles(procedureId: string, service: string) {
    return useQuery<SignatureFile[]>({
        queryKey: [path, procedureId, service],
        queryFn: fetchSignatureFiles,
        gcTime: 0,
    });
}

export { path as formsPath };
