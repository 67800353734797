import { FormattedMessage } from 'react-intl';
import {
    Button,
    Card,
    Divider,
    Flex,
    LinkBox,
    LinkOverlay,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { coreSharedMessages } from 'core';
import { DownloadIcon } from 'design-system/icons';

enum MimeTypesEnum {
    PDF = 'application/pdf',
    JPG = 'image/jpeg',
    PNG = 'image/png',
    CSV = 'text/csv',
}

const mimeType = {
    [MimeTypesEnum.PDF]: 'pdf',
    [MimeTypesEnum.JPG]: 'jpg',
    [MimeTypesEnum.PNG]: 'png',
    [MimeTypesEnum.CSV]: 'csv',
};

type ExpressDownloadProps = {
    name: string;
    url: string;
    type: string;
    size: string;
};

const ExpressDownload = ({
    name,
    url,
    type,
    size = null,
}: ExpressDownloadProps) => {
    return (
        <SimpleGrid columns={[2, 3, 4]} gap="2">
            <LinkBox
                as={Card}
                rounded="md"
                borderRadius="lg"
                borderStyle="solid"
                borderColor="strokes.medium"
                borderWidth="1px"
                boxShadow="none"
                p={2}
                alignItems="center">
                <VStack pb={1.5} gap={0}>
                    {mimeType[type] && (
                        <Flex
                            w="36px"
                            h="36px"
                            p={1.5}
                            mb={1.5}
                            rounded="md"
                            bg="green.700"
                            color="white">
                            {mimeType[type]}
                        </Flex>
                    )}
                    <Text
                        color="texts.main"
                        fontWeight={'bold'}
                        fontSize="sm"
                        textAlign="center">
                        {name}
                    </Text>
                    {!!size && (
                        <Text color="texts.medium" fontSize="xs">
                            {size}
                        </Text>
                    )}
                </VStack>
                <Divider />
                <LinkOverlay
                    href={`/api/proxy${url}`}
                    download={name}
                    p={1.5}
                    pb={0}>
                    <Button
                        rightIcon={<DownloadIcon />}
                        variant="link"
                        color="texts.main">
                        <FormattedMessage
                            {...coreSharedMessages.buttonDownload}
                        />
                    </Button>
                </LinkOverlay>
            </LinkBox>
        </SimpleGrid>
    );
};

export default ExpressDownload;
