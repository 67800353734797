import { Flex, HStack, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { Card, LinkIntl, WorkflowTypesEnum } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';

export type BeneficiaryDocumentProps = {
    workflow: WorkflowTypesEnum;
    text: string;
    icon: React.ReactElement;
    bgColor: string;
    type: 'standard' | 'specific-guided';
};

export default function BeneficiaryDocument({
    workflow,
    text,
    icon,
    bgColor,
    type,
}: BeneficiaryDocumentProps) {
    const { isMobile } = useWindowBreakpoints();

    return (
        <LinkBox
            as={Card}
            border="1px"
            borderStyle="solid"
            borderColor="grey.300"
            px={isMobile ? '5' : '7'}
            py="9"
            borderRadius="lg"
            boxShadow="lg"
            bg="white"
            height="fit-content">
            <LinkIntl
                href={{
                    pathname: `/${
                        workflow === WorkflowTypesEnum.PA
                            ? 'signature'
                            : 'beneficiary-designations'
                    }/welcome`,
                    query: {
                        workflow: `signature-beneficiaries-designation.${type}`,
                    },
                }}
                passHref>
                <HStack
                    as={LinkOverlay}
                    spacing="0"
                    gap="6"
                    justifyContent="space-between">
                    <Text fontWeight="bold">{text}</Text>

                    <Flex
                        bg={bgColor}
                        cursor="pointer"
                        p="3"
                        justifyContent="center"
                        alignItems="center"
                        flexShrink={0}
                        color="white"
                        rounded="full"
                        fontSize="lg">
                        {icon}
                    </Flex>
                </HStack>
            </LinkIntl>
        </LinkBox>
    );
}
