import React from 'react';
import { useBoolean, Text } from '@chakra-ui/react';
import { SafeFormattedMessage, formMessages } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import {
    CoreModal,
    CoreModalBody,
    CoreModalContent,
    CoreModalHeader,
    Markdown,
} from 'design-system/components';
import Checkbox, { CheckboxProps } from '../Checkbox';

export type CheckboxModalProps = CheckboxProps & {
    modal: {
        text: string;
        title: string | null;
        content: string;
        contentType: 'text/plain' | 'text/markdown';
    };
};

function CheckboxModal({ modal, ...checkboxProps }: CheckboxModalProps) {
    const [isOpen, setIsOpen] = useBoolean(false);
    const { isMobile } = useWindowBreakpoints();
    return (
        <>
            <Checkbox
                {...checkboxProps}
                modalLink={{ text: modal.text, onClick: setIsOpen.on }}
            />

            <CoreModal
                isOpen={isOpen}
                onClose={setIsOpen.off}
                isCentered={false}
                size="4xl">
                <CoreModalContent
                    borderRadius={isMobile ? 'lg' : 'xl'}
                    mx="4"
                    {...(isMobile && { maxW: '90%' })}>
                    <CoreModalHeader>
                        <Text
                            as="h3"
                            fontWeight="semibold"
                            fontFamily="mono"
                            w="100%"
                            mr="2">
                            <SafeFormattedMessage
                                {...formMessages[modal.title]}
                                debugKey={modal.title}
                            />
                        </Text>
                    </CoreModalHeader>
                    <CoreModalBody>
                        {modal.contentType === 'text/markdown' ? (
                            <Markdown content={modal.content} />
                        ) : (
                            <SafeFormattedMessage
                                {...formMessages[modal.content]}
                                debugKey={modal.content}
                            />
                        )}
                    </CoreModalBody>
                </CoreModalContent>
            </CoreModal>
        </>
    );
}

export default CheckboxModal;
