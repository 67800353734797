import { useEffect } from 'react';
import axios from 'axios';
import { UseFormReturn } from 'react-hook-form';

const API_PATH = '/api/auth-username';

function useRememberUsername(methods: UseFormReturn) {
    function update(username: string, shouldRemember: boolean) {
        if (shouldRemember === undefined) return;

        if (shouldRemember) {
            axios.post(API_PATH, { username: btoa(username) });
        } else {
            axios.delete(API_PATH);
        }
    }

    useEffect(() => {
        const getCookie = async () => {
            try {
                const res = await axios.get(API_PATH);
                if (res.data.username && !methods.getValues('username.value')) {
                    methods.setValue('username.value', atob(res.data.username));
                    methods.setValue('shouldRememberUsername.value', true);
                }
            } catch {
                methods.setValue('username', undefined);
            }
        };

        getCookie();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { update };
}

export { useRememberUsername };
