import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { UtilsContext } from 'core';
import { useRedirect } from './useRedirect';
import { UrlObject } from 'url';

const path = `/api/login`;

function readableAzureError(azureError?: string) {
    return azureError?.split(':')?.[0];
}

async function login(
    state: string,
    code: string,
    azureError: string,
    hasRedirectError: boolean,
    redirect: (path: string | UrlObject) => Promise<boolean>,
    oauth2loginEnabled: boolean,
    step: 'token' | 'redirect'
) {
    if (step === 'token' && !state) return null;
    if (!oauth2loginEnabled) return null;
    if (hasRedirectError) return null;

    try {
        const hasLoginBeenCanceled =
            readableAzureError(azureError) === 'AADB2C90091';

        const res = await axios.post(path, {
            azureError: hasLoginBeenCanceled ? null : azureError,
            state: hasLoginBeenCanceled || !code ? undefined : state,
            code,
        });

        return {
            statusCode: res.status,
            errorCode: undefined,
        };
    } catch (err) {
        const { data: responseData, status: responseStatus } =
            err?.response || {};
        const {
            code,
            errors,
            error: responseDataError,
            redirect: redirectUrl,
        } = responseData || {};
        const errorCodeNumber = errors?.[0]?.code;

        if (responseStatus === 503) {
            redirect({
                pathname: '/maintenance',
                query: {
                    message: responseData?.message,
                },
            });
            return;
        }

        if (redirectUrl) {
            window.location.href = redirectUrl;
        }

        return {
            statusCode: responseStatus as number,
            errorCode:
                code ||
                (typeof responseDataError === 'string'
                    ? responseDataError
                    : (responseDataError?.response as AxiosResponse)),
            errorCodeNumber,
        };
    }
}

export function useOAuth2Login(
    step: 'token' | 'redirect',
    state?: string,
    code?: string,
    azureError?: string,
    hasRedirectError?: boolean
) {
    const {
        siteConfig: {
            features: { oauth2login },
        },
    } = React.useContext(UtilsContext);
    const { redirect } = useRedirect();

    return useQuery({
        queryKey: [path, step, state],

        queryFn: () =>
            login(
                state,
                code,
                azureError,
                hasRedirectError,
                redirect,
                oauth2login,
                step
            ),

        staleTime: 0,
        gcTime: 0,
    });
}
