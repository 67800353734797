import { WidgetKeys } from 'core';
import { WorkflowMedicalibData } from '../types';
import { CARE_DURATION_CORRESPONDENCES } from '../const/cares/duration';
import { prefillWidget } from 'core/lib/forms/helpers/prefillWidget';

function getHasPrescriptionValue(data: WorkflowMedicalibData): string | null {
    if (data?.patientInfo?.hasPrescription === undefined) {
        return null;
    }

    if (!data?.patientInfo?.hasPrescription) {
        return '3';
    }

    return data?.patientInfo?.hasPrescriptionAtHome ? '1' : '2';
}

function getHasNurseGenderPreference(data): string | null {
    if (data?.nurseGenderPreference === undefined) {
        return null;
    }

    return data?.nurseGenderPreference === 'M' ? 'male' : 'female';
}

function parseCareDurationValue(duration: string) {
    return (
        Object.entries(CARE_DURATION_CORRESPONDENCES).find(
            ([_, value]) => value === duration
        )?.[0] || duration
    );
}

function getWidgetDefaultValue(item: WidgetKeys, data: WorkflowMedicalibData) {
    switch (item.id) {
        case 'hasPrescription':
            return getHasPrescriptionValue(data);
        case 'locationType':
            return data?.careLocationAddress?.locationType;
        case 'careStartDate':
            return data?.careStartDate || null;
        case 'careEndDate':
            return data?.careEndDate || null;
        case 'careDuration':
            return {
                defaultValue: parseCareDurationValue(
                    data?.careDurationParentValue || data?.careDuration
                ),
                subDefaultValue: data?.careDurationParentValue
                    ? parseCareDurationValue(data?.careDuration)
                    : null,
            };
        case 'careLocationAddress':
            return data?.careLocationAddress?.city;
        case 'available-time-ranges':
            if (!data?.patientAvailabilities?.[0]) {
                return null;
            }

            return {
                ...(data?.patientAvailabilities?.[0]?.startTime && {
                    startValue: parseInt(
                        data?.patientAvailabilities?.[0]?.startTime
                    ),
                }),
                ...(data?.patientAvailabilities?.[0]?.endTime && {
                    endValue: parseInt(
                        data?.patientAvailabilities?.[0]?.endTime
                    ),
                }),
            };
        case 'available-time-ranges-optional':
            if (!data?.patientAvailabilities?.[1]) {
                return null;
            }

            return {
                ...(data?.patientAvailabilities?.[1]?.startTime && {
                    startValue: parseInt(
                        data?.patientAvailabilities?.[1]?.startTime
                    ),
                }),
                ...(data?.patientAvailabilities?.[1]?.endTime && {
                    endValue: parseInt(
                        data?.patientAvailabilities?.[1]?.endTime
                    ),
                }),
            };
        case 'address':
            return data?.careLocationAddress;
        case 'additionalInfos':
            return data?.careLocationAddress?.additionalInfos;
        case 'beneficiaryIdentifier':
            return data?.patientInfo?.identifier;
        case 'phone':
            return {
                defaultValue: data?.patientInfo?.phone?.value,
                defaultCountry: data?.patientInfo?.phone?.country,
            };
        case 'email':
            return data?.patientInfo?.email;
        case 'nurseGenderPreference':
            return getHasNurseGenderPreference(data);
    }
}

export function prefillMedicalibWidget(
    item: WidgetKeys,
    data: WorkflowMedicalibData
) {
    prefillWidget(item, getWidgetDefaultValue(item, data));
}
