import React from 'react';
import { useFormContext } from 'react-hook-form';

export function useConfirmationValidation(id: string) {
    const { watch, trigger } = useFormContext();
    const originalName = `${id}.value`;
    const confirmationName = `${id}.confirmation`;
    const [originalValue, confirmationValue] = watch([
        originalName,
        confirmationName,
    ]);
    const [hasConfirmationBeenTouched, setHasConfirmationBeenTouched] =
        React.useState(false);

    React.useEffect(() => {
        if (!confirmationValue || !originalValue) return;

        if (
            originalValue.includes(confirmationValue) &&
            !hasConfirmationBeenTouched
        ) {
            return;
        }

        setHasConfirmationBeenTouched(true);
        trigger(confirmationName);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationValue, originalValue]);

    return { confirmationName, originalName };
}
