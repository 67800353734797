import {
    As,
    Box,
    Divider,
    Flex,
    Heading,
    HStack,
    Stack,
    Text,
    TextProps,
} from '@chakra-ui/react';
import * as icons from 'design-system/icons';
import { uniqueId } from 'lodash';
import { ReactNode } from 'react';

type ListItemProps = {
    icon?: string;
    label?: ReactNode;
    labelProps?: TextProps;
    description?: string | ReactNode;
    descriptionProps?: TextProps;
    isInactive?: boolean;
    onClick?: () => void;
};

type ListProps = {
    title?: ReactNode | string;
    hasDividers?: boolean;
    items: ListItemProps[];
    titleBalise?: As;
};

export default function List({
    title,
    hasDividers = false,
    items,
    titleBalise = 'h2',
}: ListProps) {
    return (
        <Stack data-testid="list">
            <Heading as={titleBalise}>{title}</Heading>
            <Stack gap="0">
                {items?.map((item) => (
                    <Box key={uniqueId()}>
                        {hasDividers && <Divider data-testid="hr" />}
                        <ListItem {...item} />
                    </Box>
                ))}
            </Stack>
        </Stack>
    );
}

function ListItem({
    icon,
    label,
    labelProps,
    description,
    descriptionProps,
    isInactive,
    onClick,
}: ListItemProps) {
    const Icon = icons[icon];

    return (
        <HStack
            alignItems={label && description ? 'flex-start' : 'center'}
            data-testid={`listitem`}
            justifyContent="space-between"
            onClick={!isInactive && onClick}
            borderRadius="md"
            py="3"
            transition="ease-in-out all 0.3s"
            _hover={
                onClick && {
                    bg: 'gray.100',
                    cursor: isInactive ? 'not-allowed' : 'pointer',
                    '.arrow-icon': {
                        transform: 'translateX(-10px)',
                        transition: 'ease-in-out all 0.3s',
                    },
                }
            }>
            <HStack gap="3">
                {Icon && (
                    <Flex
                        bg="bg.light"
                        rounded="xl"
                        p="1"
                        data-testid="listitem-icon">
                        <Icon
                            h="6"
                            w="6"
                            color={isInactive ? 'texts.light' : 'primary.main'}
                        />
                    </Flex>
                )}
                <Stack gap="0" spacing="0">
                    <Text
                        fontWeight="bold"
                        color={isInactive && 'texts.light'}
                        {...labelProps}>
                        {label}
                    </Text>
                    <Text
                        fontSize="sm"
                        color={isInactive && 'texts.light'}
                        whiteSpace="pre-line"
                        {...descriptionProps}>
                        {description}
                    </Text>
                </Stack>
            </HStack>
            <icons.ArrowRightIcon
                className="arrow-icon"
                fontSize="lg"
                color={isInactive && 'texts.light'}
                transition="ease-in-out all 0.3s"
            />
        </HStack>
    );
}
