import { useCredentialsConfig } from 'lib/auth';
import { useEligibility } from 'lib/shared';
import TermsFooter, { TermsFooterProps } from 'components/TermsFooter';

type SharedTermsFooterProps = Pick<
    TermsFooterProps,
    'textColor' | 'stackProps'
>;

const SharedTermsFooter = (props: SharedTermsFooterProps) => {
    const { data: credentialsConfig } = useCredentialsConfig();
    const { data: eligibility } = useEligibility();
    const displayCgu =
        eligibility?.displayFooterCgu ||
        credentialsConfig?.legals?.generalConditionsOfUse;
    const displayPrivacyPolicy =
        eligibility?.displayFooterCgu ||
        credentialsConfig?.legals?.generalConditionsOfUse;

    return (
        <TermsFooter
            displayCgu={displayCgu}
            displayPrivacyPolicy={displayPrivacyPolicy}
            {...props}
        />
    );
};

export default SharedTermsFooter;
