import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { debounce, intersection, orderBy } from 'lodash';
import {
    Stack,
    Divider,
    Box,
    VStack,
    Input,
    FormErrorMessage,
} from '@chakra-ui/react';
import {
    FormGroup,
    formMessages,
    handleKeyBoardEvents,
    useForkRef,
    useInputValidation,
    useSafeIntl,
} from 'core';
import { MapIconMarkerIcon } from 'design-system/icons';
import { Address } from 'core/lib/forms/types/address';
import * as Sentry from '@sentry/nextjs';
import Script from 'next/script';

type Prediction = google.maps.places.AutocompletePrediction;
type GeoPlace = google.maps.GeocoderResult;

const getAddress = (place: GeoPlace, isManualAddress?: boolean): Address => {
    const address: Address = {
        city: '',
        country: { longName: '', shortName: '' },
        department: '',
        postalCode: '',
        region: '',
        street: '',
        streetNumber: '',
        coordinates: { latitude: 0, longitude: 0 },
        isManualAddress,
    };

    const addressComponents = place.address_components;
    const geometry = place.geometry;

    const addressComponentMapping: { [key: string]: string } = {
        street_number: 'streetNumber',
        route: 'street',
        administrative_area_level_2: 'department',
        administrative_area_level_1: 'region',
        postal_code: 'postalCode',
        locality: 'city',
        country: 'country',
    };

    addressComponents.forEach((component) => {
        const types = component.types;

        for (const type of types) {
            if (addressComponentMapping[type]) {
                const propertyName = addressComponentMapping[type];
                if (propertyName === 'country') {
                    address.country.longName = component.long_name;
                    address.country.shortName = component.short_name;
                } else {
                    address[propertyName] = component.long_name;
                }
            }
        }
    });

    if (geometry && geometry.location) {
        address.coordinates.latitude = geometry.location.lat();
        address.coordinates.longitude = geometry.location.lng();
    }

    return address;
};

const findNeastGeocoderResultByTypes = (
    results: google.maps.GeocoderResult[],
    types: string[]
): google.maps.GeocoderResult => {
    const resultsScores = results.map((result) => ({
        place_id: result.place_id,
        score: intersection(result.types, types).length,
    }));
    const resultScore = orderBy(resultsScores, 'score', 'desc')[0];
    return (
        results.find((result) => result.place_id === resultScore.place_id) ||
        results[0]
    );
};

type GooglePlacesInputProps = {
    id: string;
    placeholder: string;
    description?: string;
    title: string;
    helper: string;
    isRequired: boolean;
    restrictions: string[];
    types: string[]; // type of the address | geocode | address | postal_code | regions | ...
    minChar: number; // min of characters required to call google api
    onChange: (address: Address | null) => void;
    defaultValue?: Address;
    isManualAddress?: boolean;
};

const GooglePlacesInput = ({
    id,
    placeholder,
    title,
    helper,
    isRequired,
    restrictions,
    types,
    minChar,
    description,
    onChange,
    defaultValue,
    isManualAddress = false,
}: GooglePlacesInputProps): JSX.Element => {
    const queryInputRef = useRef(null);
    const { safeFormatMessage } = useSafeIntl();
    const [predictions, setPredictions] = useState<Prediction[] | null>([]);
    const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
    const [focusedOption, setFocusedOption] = useState<number>(-1);
    const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);
    const inputName = `${id}.place`;
    const {
        formState: { errors },
        registerValues,
        setValue,
    } = useInputValidation({ required: isRequired }, inputName);
    const forkRef = useForkRef(registerValues.ref, queryInputRef);

    useEffect(() => {
        setFocusedOption(-1);
    }, [predictions]);

    const setInputValue = (value) => {
        if (queryInputRef.current) queryInputRef.current.value = value;
        setValue(inputName, value, { shouldValidate: true });
    };

    const handleDefaultValue = (address: Address) => {
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode(
            {
                location: {
                    lat: address.coordinates.latitude,
                    lng: address.coordinates.longitude,
                },
            },
            (results, status) => {
                if (status === google.maps.GeocoderStatus.OK && results[0]) {
                    // geocoder.geocode doesn't accept `types` parametter,
                    // So we need to parse the results ourselves.
                    // See: https://stackoverflow.com/a/45918223
                    setInputValue(
                        findNeastGeocoderResultByTypes(results, types)
                            .formatted_address
                    );
                } else {
                    Sentry.captureMessage(
                        `Geocoder request failed: ${status}, for address ${JSON.stringify(
                            defaultValue
                        )}`
                    );
                }
            }
        );
    };

    useEffect(() => {
        if (defaultValue && isScriptLoaded) handleDefaultValue(defaultValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue, isScriptLoaded]);

    useEffect(() => {
        if (window.google) setIsScriptLoaded(true);
    }, []);

    const fetchPlaces = (value) => {
        if (!isScriptLoaded) return;

        const service = new google.maps.places.AutocompleteService();

        const options = {
            input: value,
            componentRestrictions: {
                country: restrictions,
            },
            types,
        };
        service.getPlacePredictions(options, (predictions, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                setPredictions(predictions);
                onChange(null);
            } else {
                setPredictions([]);
            }
        });
    };

    const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;

        if (!value.trim() || value.trim().length < minChar) {
            setPredictions(null);
            onChange(null); //Remove the oldest value when the user empties the input
            return;
        }
        onChange(null);

        fetchPlaces(value);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), [
        isScriptLoaded,
    ]);

    const handlePlaceOnClick = (prediction: Prediction): void => {
        if (!isScriptLoaded) return;

        const geocoder = new google.maps.Geocoder();

        geocoder.geocode(
            { placeId: prediction.place_id },
            (results, status) => {
                if (status === google.maps.GeocoderStatus.OK && results[0]) {
                    onChange(getAddress(results[0], isManualAddress));
                    setPredictions(null);
                    setInputValue(results[0].formatted_address);
                } else {
                    Sentry.captureMessage(`Geocoder request failed: ${status}`);
                }
            }
        );
    };

    const _renderPredictions = (): JSX.Element => {
        return (
            <Stack
                w="100%"
                spacing={0}
                divider={<Divider orientation="horizontal" />}>
                {predictions.map((prediction, index) => (
                    <Box
                        key={prediction.place_id}
                        w="100%"
                        cursor="pointer"
                        px={3}
                        py={1}
                        bg={focusedOption === index && 'gray.100'}
                        _hover={{ bg: 'gray.100' }}
                        onMouseDown={() => handlePlaceOnClick(prediction)}>
                        <MapIconMarkerIcon h={5} w={5} />
                        {prediction.description}
                    </Box>
                ))}
            </Stack>
        );
    };

    const _renderPredictionsContainer = (): JSX.Element => {
        return (
            <VStack
                gap={1}
                w="100%"
                mt={-1}
                top="100%"
                bg="white"
                position="absolute"
                alignItems="start"
                overflowY="auto"
                border="1px solid"
                borderColor="strokes.medium"
                borderBottomRadius={5}
                zIndex={999}>
                {_renderPredictions()}
            </VStack>
        );
    };

    return (
        <>
            <Script
                id="google-maps"
                src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}&libraries=places`}
                onLoad={() => setIsScriptLoaded(true)}
            />
            <FormGroup
                label={title}
                name={inputName}
                {...(helper && { help: helper })}
                {...{ id, description, isRequired, isInvalid: !!errors[id] }}>
                <Input
                    ref={forkRef}
                    name={inputName}
                    type="text"
                    placeholder={
                        placeholder &&
                        safeFormatMessage(
                            formMessages[placeholder],
                            null,
                            placeholder
                        )
                    }
                    onChange={debouncedChangeHandler}
                    onKeyDown={(e) =>
                        handleKeyBoardEvents(
                            e,
                            predictions,
                            focusedOption,
                            handlePlaceOnClick,
                            setFocusedOption
                        )
                    }
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                    autoComplete="off"
                    {...(!!predictions &&
                        !!predictions.length &&
                        isInputFocused && { borderBottomRadius: 0 })}
                />
                {errors[id] && !predictions?.length && (
                    <FormErrorMessage>
                        {errors[id].message as string}
                    </FormErrorMessage>
                )}
                {!!predictions &&
                    !!predictions.length &&
                    isInputFocused &&
                    _renderPredictionsContainer()}
            </FormGroup>
        </>
    );
};

export default GooglePlacesInput;
