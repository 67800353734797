import { Icon, IconProps } from '@chakra-ui/react';

export default function MoreIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M14 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM14 19a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
                fill="currentColor"
            />
        </Icon>
    );
}
