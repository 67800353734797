import { Icon, useTheme } from '@chakra-ui/react';

type IllustationProps = {
    w?: string;
    h?: string;
};

export default function MobileIntroIllustration({
    w = 'auto',
    h = 'auto',
}: IllustationProps) {
    const theme = useTheme();
    const colors = theme.colors;

    return (
        <Icon viewBox="0 0 150 150" width={w} height={h} fill="none">
            <path
                d="M53.167 128.997C61.3841 128.212 70.1866 125.113 79.3307 119.326C85.0265 115.722 90.6318 115.122 96.3493 113.064C109.079 108.482 123.208 94.2397 129.872 79.7791C138.255 61.5857 127.889 49.9621 117.607 44.1562C107.018 38.1767 96.833 32.2422 87.3533 24.5366C76.3199 15.5679 60.8771 12.7093 44.9813 26.4911C32.8296 37.0271 28.7813 51.0816 24.7989 65.541C22.3143 74.5611 15.9894 82.5394 12.7281 91.3794C9.02447 101.418 12.2343 108.855 17.0472 114.233C26.4944 124.791 38.955 130.355 53.167 128.997Z"
                fill="black"
                fillOpacity="0.04"
            />
            <path
                d="M94.7504 24.4117L49.3575 24.4772L49.4998 123.092L94.8926 123.026L94.7504 24.4117Z"
                fill={colors.gray['50']}
            />
            <path
                d="M62.8587 120.408L81.2439 120.381"
                stroke={colors.primary['800']}
                strokeWidth="2.06064"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M53.5624 126.047C49.5736 125.743 46.4187 122.403 46.4126 118.337L46.2845 29.7444C46.2813 27.6761 47.0839 25.7303 48.5443 24.2658C50.0048 22.801 51.9481 21.993 54.0165 21.9902L90.0416 21.938C92.1094 21.9342 94.0554 22.7374 95.5202 24.1982C96.9846 25.6586 97.7929 27.6019 97.7958 29.6703L97.9242 118.263C97.9303 122.533 94.4619 126.011 90.1922 126.018L54.1668 126.07C53.9637 126.07 53.7619 126.062 53.5624 126.047ZM90.4467 24.6209C90.3139 24.6107 90.1802 24.6055 90.0457 24.6058L54.0203 24.6579C52.6645 24.6597 51.3909 25.1895 50.4337 26.1496C49.4766 27.1097 48.9504 28.3848 48.9525 29.7406L49.0806 118.334C49.0847 121.132 51.3666 123.406 54.1633 123.401L90.1884 123.349C91.5439 123.347 92.8178 122.818 93.775 121.857C94.7321 120.898 95.2583 119.622 95.2561 118.267L95.1278 29.6735C95.126 28.3177 94.5965 27.0441 93.6364 26.0869C92.772 25.2249 91.651 24.7126 90.4467 24.6209Z"
                fill={colors.primary.main}
            />
            <path
                d="M90.6093 25.3466C92.8237 25.5153 94.5608 27.3896 94.5638 29.6141L94.6924 118.427C94.6942 119.573 94.2499 120.65 93.4414 121.46C92.6329 122.271 91.5572 122.718 90.4125 122.72L53.9373 122.773C53.8248 122.773 53.7114 122.769 53.5998 122.76C51.3853 122.592 49.6479 120.717 49.6449 118.493L49.5163 29.6793C49.5145 28.5343 49.9588 27.4573 50.7674 26.6466C51.5759 25.836 52.6516 25.3885 53.7963 25.3869L90.2715 25.3341C90.3837 25.334 90.4974 25.338 90.6093 25.3466ZM90.6786 24.4326C90.544 24.4224 90.4075 24.4172 90.2702 24.4173L53.795 24.4701C50.9217 24.4744 48.5955 26.807 48.5998 29.6803L48.7284 118.494C48.7323 121.23 50.8474 123.47 53.5302 123.674C53.6648 123.684 53.8013 123.689 53.9386 123.689L90.4138 123.636C93.2871 123.632 95.6133 121.3 95.609 118.426L95.4807 29.6128C95.4765 26.8768 93.3617 24.637 90.6786 24.4326Z"
                fill={colors.primary['800']}
            />
            <path
                d="M81.9447 28.5677L62.1329 28.5964C60.5604 28.5987 59.2841 27.326 59.2818 25.7535L59.28 24.4629L84.7858 24.4259L84.7877 25.7165C84.7897 27.2887 83.517 28.5653 81.9447 28.5677Z"
                fill={colors.primary['800']}
            />
            <path
                d="M73.5972 27.0218L68.0837 27.0299C67.863 27.0303 67.6837 26.8513 67.6832 26.6306C67.683 26.4099 67.8618 26.2306 68.0825 26.2301L73.596 26.2221C73.8168 26.2216 73.996 26.4006 73.9965 26.6213C73.997 26.8421 73.818 27.0213 73.5972 27.0218Z"
                fill={colors.primary.main}
            />
            <path
                d="M77.1743 26.7325C77.1748 27.1566 76.8314 27.501 76.4073 27.5018C75.9831 27.5026 75.6387 27.1589 75.638 26.7348C75.6372 26.3106 75.9809 25.9662 76.405 25.9655C76.8291 25.965 77.1736 26.3084 77.1743 26.7325Z"
                fill={colors.primary.main}
            />
            <path
                d="M46.4317 29.4098L45.7385 29.4108L45.7438 33.09L46.437 33.089L46.4317 29.4098Z"
                fill={colors.primary.main}
            />
            <path
                d="M46.44 36.4525L45.7468 36.4535L45.7568 43.3827L46.45 43.3817L46.44 36.4525Z"
                fill={colors.primary.main}
            />
            <path
                d="M46.4529 45.3868L45.7598 45.3878L45.7698 52.317L46.4629 52.316L46.4529 45.3868Z"
                fill={colors.primary.main}
            />
            <path
                d="M98.3571 38.8335L97.6639 38.8345L97.6799 49.8772L98.373 49.8762L98.3571 38.8335Z"
                fill={colors.primary.main}
            />
            <circle cx="85.8485" cy="72.5114" r="14.2219" fill="#D5F4F9" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.8359 68.8125C78.8326 67.9888 79.4977 67.3184 80.3214 67.3151L92.2527 67.2679C93.0764 67.2646 93.7467 67.9297 93.75 68.7534L93.7795 76.2105C93.7828 77.0341 93.1177 77.7045 92.294 77.7078L80.3627 77.755C79.539 77.7583 78.8687 77.0932 78.8654 76.2695L78.8359 68.8125ZM91.0289 68.7642L81.5289 68.8018L86.2499 73.0054L91.0289 68.7642ZM80.3309 69.7321L80.3568 76.2636L92.2881 76.2164L92.2622 69.6637L87.2399 74.1209C86.6744 74.6227 85.8228 74.622 85.2582 74.1193L80.3309 69.7321Z"
                fill={colors.primary.main}
            />
            <circle cx="51.5881" cy="98.6591" r="14.2219" fill="#D5F4F9" />
            <path
                d="M56.5886 96.4365C56.749 96.8361 56.5552 97.2899 56.1556 97.4501C55.7561 97.6103 55.3022 97.4162 55.1418 97.0166C54.9814 96.617 55.1752 96.1632 55.5748 96.003C55.9743 95.8428 56.4282 96.0369 56.5886 96.4365Z"
                fill={colors.primary.main}
            />
            <path
                d="M47.4749 100.931C47.8744 100.771 48.0683 100.317 47.9079 99.9174C47.7475 99.5178 47.2936 99.3238 46.894 99.484C46.4945 99.6442 46.3007 100.098 46.4611 100.498C46.6215 100.897 47.0754 101.091 47.4749 100.931Z"
                fill={colors.primary.main}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.1291 98.4734C42.8083 97.6742 43.196 96.7666 43.995 96.4462L55.5693 91.8051C56.3684 91.4847 57.2762 91.8729 57.597 92.6721L59.9205 98.4604C60.2413 99.2596 59.8536 100.167 59.0546 100.488L59.0347 100.496C59.0955 100.609 59.1507 100.727 59.1998 100.849C59.9216 102.648 59.0493 104.69 57.2515 105.411C55.4537 106.132 53.4111 105.258 52.6893 103.46C52.6402 103.338 52.5985 103.214 52.5639 103.09L47.4803 105.129C46.6813 105.449 45.7734 105.061 45.4526 104.262L43.1291 98.4734ZM52.5113 101.431C52.5943 101.038 52.7442 100.662 52.9536 100.32C52.7872 100.448 52.6007 100.556 52.3962 100.638C51.1976 101.118 49.8359 100.536 49.3547 99.3373C48.8735 98.1385 49.455 96.777 50.6535 96.2964C51.8521 95.8158 53.2138 96.398 53.695 97.5968C53.9584 98.2528 53.9035 98.9575 53.6034 99.54C53.8998 99.2745 54.2468 99.0554 54.6376 98.8988C55.7662 98.4462 56.9913 98.6219 57.9256 99.2604L58.4737 99.0406L56.1502 93.2522L44.5759 97.8933L46.8994 103.682L52.5113 101.431ZM52.1057 99.9141C52.9048 99.5937 53.2924 98.6861 52.9716 97.8869C52.6508 97.0877 51.743 96.6996 50.944 97.02C50.1449 97.3404 49.7573 98.248 50.0781 99.0472C50.3989 99.8464 51.3067 100.235 52.1057 99.9141ZM57.753 101.43C58.154 102.429 57.6694 103.563 56.6706 103.964C55.6718 104.364 54.5371 103.879 54.1361 102.88C53.735 101.881 54.2196 100.746 55.2184 100.346C56.2172 99.9453 57.352 100.431 57.753 101.43Z"
                fill={colors.primary.main}
            />
            <circle cx="53.3574" cy="49.6173" r="14.2219" fill="#D5F4F9" />
            <path
                d="M53.6857 48.1378C53.6857 47.7755 53.9794 47.4819 54.3416 47.4819L58.2773 47.4819C58.6395 47.4819 58.9332 47.7755 58.9332 48.1378C58.9332 48.5001 58.6395 48.7938 58.2773 48.7938L54.3416 48.7938C53.9794 48.7938 53.6857 48.5001 53.6857 48.1378Z"
                fill={colors.primary.main}
            />
            <path
                d="M57.184 50.7616C57.184 50.3993 56.8904 50.1056 56.5281 50.1056L54.3416 50.1056C53.9794 50.1056 53.6857 50.3993 53.6857 50.7616C53.6857 51.1238 53.9794 51.4175 54.3416 51.4175L56.5281 51.4175C56.8904 51.4175 57.184 51.1238 57.184 50.7616Z"
                fill={colors.primary.main}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.7501 50.9802C50.9576 50.9802 51.9365 50.0013 51.9365 48.7938C51.9365 47.5862 50.9576 46.6073 49.7501 46.6073C48.5425 46.6073 47.5636 47.5862 47.5636 48.7938C47.5636 50.0013 48.5425 50.9802 49.7501 50.9802ZM49.7501 49.6683C50.2331 49.6683 50.6246 49.2768 50.6246 48.7938C50.6246 48.3107 50.2331 47.9192 49.7501 47.9192C49.267 47.9192 48.8755 48.3107 48.8755 48.7938C48.8755 49.2768 49.267 49.6683 49.7501 49.6683Z"
                fill={colors.primary.main}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46.689 43.9835C45.723 43.9835 44.9398 44.7667 44.9398 45.7327V54.4786C44.9398 55.4446 45.723 56.2277 46.689 56.2277H60.6824C61.6484 56.2277 62.4316 55.4446 62.4316 54.4786V45.7327C62.4316 44.7667 61.6484 43.9835 60.6824 43.9835H46.689ZM60.6824 45.7327H46.689V52.4411C46.8333 52.2604 46.9996 52.0981 47.186 51.9549C47.8922 51.4125 48.8163 51.1989 49.7501 51.1989C50.6838 51.1989 51.6079 51.4125 52.3141 51.9549C53.0462 52.5171 53.467 53.3744 53.467 54.4786H60.6824V45.7327ZM52.1552 54.4786C52.1552 53.7384 51.8907 53.2839 51.515 52.9953C51.1133 52.6868 50.5069 52.5107 49.7501 52.5107C48.9932 52.5107 48.3868 52.6868 47.9851 52.9953C47.6094 53.2839 47.3449 53.7384 47.3449 54.4786H52.1552Z"
                fill={colors.primary.main}
            />
            <path
                d="M33.9554 65.5376C29.5659 67.6623 29.2542 70.7968 29.1151 71.3567V71.3645C29.1129 71.3617 29.1129 71.3589 29.1129 71.3567C28.9716 70.7968 28.6626 67.6623 24.2725 65.5376C28.4361 63.9057 29.0633 60.0198 29.1107 59.6592C29.1129 59.6536 29.1129 59.6486 29.1129 59.6431C29.1151 59.6486 29.1151 59.6542 29.1151 59.6592C29.1172 59.6592 29.1151 59.6619 29.1151 59.6619C29.1695 60.0365 29.7988 63.9085 33.9554 65.5376Z"
                fill="#FFCF33"
            />
            <path
                d="M41.8547 123.364C36.0784 126.042 35.6689 129.994 35.4859 130.7V130.711C35.4828 130.707 35.4828 130.704 35.4828 130.7C35.2964 129.994 34.8898 126.042 29.114 123.364C34.592 121.306 35.4177 116.407 35.4798 115.952C35.4828 115.945 35.4828 115.938 35.4828 115.932C35.4858 115.938 35.4859 115.945 35.4859 115.952C35.4889 115.952 35.4859 115.955 35.4859 115.955C35.5571 116.428 36.3858 121.31 41.8547 123.364Z"
                fill="#FFCF33"
            />
            <path
                d="M116.851 52.2297C111.537 54.9085 111.16 58.8606 110.991 59.5666V59.5768C110.989 59.5734 110.989 59.57 110.989 59.5666C110.817 58.8602 110.443 54.9085 105.129 52.2297C110.169 50.1722 110.929 45.2729 110.986 44.818C110.989 44.8112 110.989 44.8044 110.989 44.7976C110.991 44.8044 110.991 44.8112 110.991 44.818C110.994 44.818 110.991 44.8214 110.991 44.8214C111.057 45.2938 111.819 50.1761 116.851 52.2297Z"
                fill="#FFCF33"
            />
        </Icon>
    );
}
