import { FormValues, parseDate } from 'core';
import { GenderEnum, WorkflowMedicalibData } from '../types';
import { WizardFormContainerAdditionalData } from 'lib/form-wizard/types';
import { extractPrescriptionFiles } from './extractPrescriptionFiles';
import { CARE_DURATION_CORRESPONDENCES } from '../const/cares/duration';

export function formatHomeConsultationData(
    data: FormValues,
    additionalData: WizardFormContainerAdditionalData
) {
    const activeBeneficiary = additionalData?.beneficiaries?.find(
        (beneficiary) =>
            beneficiary.identifier === data.beneficiaryIdentifier?.value
    );

    const formattedGender = GenderEnum[data?.nurseGenderPreference?.value];

    const formCareDurationValue = data?.careDuration?.value;
    const formCareCustomDurationValue =
        data?.careDuration?.duration?.['custom-value']?.value;
    const careDuration =
        CARE_DURATION_CORRESPONDENCES[formCareDurationValue] ||
        formCareDurationValue;
    const customDuration =
        CARE_DURATION_CORRESPONDENCES[formCareCustomDurationValue] ||
        formCareCustomDurationValue;

    const hasPrescription =
        data?.hasPrescription?.value === '1' ||
        data?.hasPrescription?.value === '2';
    const prescriptions = hasPrescription
        ? data.hasPrescription[data.hasPrescription.value]?.prescription
        : null;

    return {
        careDuration: customDuration || careDuration,
        careDurationParentValue: customDuration ? careDuration : undefined,
        careLocationAddress: {
            ...(data?.address?.value && JSON?.parse(data?.address?.value)),
            additionalInfos: data?.additionalInfos?.value,
            ...(data?.locationType?.value !== 'null' && {
                locationType: data?.locationType?.value,
            }),
        },
        ...(data?.careStartDate?.value && {
            careStartDate: parseDate(data?.careStartDate?.value),
        }),
        patientInfo: {
            email: data?.email?.value,
            firstName: activeBeneficiary?.firstName,
            identifier: activeBeneficiary?.identifier,
            lastName: activeBeneficiary?.lastName,
            ...(!!data?.phone?.value && {
                phone: JSON.parse(data?.phone?.value),
            }),
            ...(!!data?.hasPrescription && {
                hasPrescription,
                hasPrescriptionAtHome:
                    data?.hasPrescription?.value === '1' ? true : false,
            }),
            ...(hasPrescription && prescriptions
                ? {
                      prescriptionFiles:
                          extractPrescriptionFiles(prescriptions),
                  }
                : {}),
        },
        ...(data?.['available-time-ranges'] && {
            patientAvailabilities: [
                {
                    startTime: data?.['available-time-ranges']?.start,
                    endTime: data?.['available-time-ranges']?.end,
                },
                ...(data?.['available-time-ranges-optional']?.start && [
                    {
                        startTime:
                            data?.['available-time-ranges-optional']?.start,
                        endTime: data?.['available-time-ranges-optional']?.end,
                    },
                ]),
            ],
        }),

        nurseGenderPreference: formattedGender,
    } as WorkflowMedicalibData;
}
