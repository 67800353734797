import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { toArray } from 'lodash';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { FileRejection } from 'react-dropzone';
import { Validations, toBase64 } from 'core';
import getDisplayableMimeTypes from './utils/getDisplayableMimeTypes';

type FileUploadValidations = Pick<
    Validations,
    | 'maxSize'
    | 'maxFiles'
    | 'acceptedMimeTypes'
    | 'totalMaxSize'
    | 'multipleTotalMaxSize'
>;

type FileObject = {
    name: string;
    size: number;
    content: string;
};

const errorMessages = defineMessages({
    'too-many-files': {
        id: 'components.forms.widgets.file-upload.error.too-many-files',
        defaultMessage: `{nbRejectedFiles, plural,
            one {Un fichier n’a pas pu être ajouté}
            other {# fichiers n'ont pas pu être ajoutés} } car le nombre maximal de fichiers a été atteint pour cette catégorie ({maxFiles} max).`,
    },
    'total-max-size-multiple': {
        id: 'components.forms.widgets.file-upload.error.total-max-size-multiple',
        defaultMessage: `{nbRejectedFiles, plural,
            one {Un fichier n’a pas pu être ajouté}
            other {# fichiers n’ont pas pu être ajoutés} } car vous avez atteint la taille totale autorisée de {totalMaxSize} Mo pour l'ensemble de vos fichiers.`,
    },
    'file-too-large': {
        id: 'components.forms.widgets.file-upload.error.file-too-large',
        defaultMessage: `{nbRejectedFiles, plural,
                one {Un fichier n’a pas pu être ajouté car il est trop lourd.}
                other {# fichiers n’ont pas pu être ajoutés car ils sont trop lourds.} } La taille maximale autorisée par fichier est {maxSize} Mo.`,
    },
    'file-invalid-type': {
        id: 'components.forms.widgets.file-upload.error.file-invalid-type',
        defaultMessage: `{nbRejectedFiles, plural,
                one {Un fichier n’a pas pu être ajouté car son extension n'est pas autorisée}
                other {# fichiers n’ont pas pu être ajoutés car leurs extensions ne sont pas autorisées} }. Les extensions autorisées sont {allowedTypes}.`,
    },
    'category-max-size': {
        id: 'components.forms.widgets.file-upload.file-group-to-heavy',
        defaultMessage: `{nbRejectedFiles, plural,
                one {Un fichier n'a pas pu être ajouté}
                other {# fichiers n'ont pas pu être ajoutés} } car la taille totale autorisée de {totalMaxSize} Mo a été atteinte pour les fichiers de cette catégorie.`,
    },
    'file-already-exists': {
        id: 'components.forms.widgets.file-upload.file-already-exist',
        defaultMessage: `Le fichier "{duplicatedFileName}" existe déjà.`,
    },
    'contains-empty-files': {
        id: 'components.forms.widgets.file-upload.contains-empty-files',
        defaultMessage: `{nbRejectedFiles, plural,
            one {Un fichier n’a pas pu être ajouté car il est vide}
            other {# fichiers n’ont pas pu être ajoutés car ils sont vides} }.`,
    },
});

function useFileUpload(
    id: string,
    validations: FileUploadValidations,
    categoryId?: string,
    availableFileSize?: number
) {
    const { formatNumber } = useIntl();
    const { setValue, unregister } = useFormContext();
    const [files, setFiles] = React.useState<FileObject[]>([]);
    const [totalSize, setTotalSize] = React.useState(0);
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const [toastMessage, setToastMessage] = React.useState<{
        message: MessageDescriptor;
        values: { [x: string]: string | number | undefined };
    }>();
    const [cleartoastTimeout, setCleartoastTimeout] =
        React.useState<NodeJS.Timeout>();

    React.useEffect(() => {
        if (cleartoastTimeout) {
            clearTimeout(cleartoastTimeout);
            setCleartoastTimeout(undefined);
        }

        if (toastMessage) {
            const timeout = setTimeout(() => {
                setToastMessage(undefined);
                setCleartoastTimeout(undefined);
            }, 5000);
            setCleartoastTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toastMessage]);

    type ToastErrorParams = {
        nbRejectedFiles?: number;
        duplicatedFileName?: string;
    };

    function toastError(errorCode: string, params?: ToastErrorParams) {
        if (cleartoastTimeout) {
            clearTimeout(cleartoastTimeout);
            setCleartoastTimeout(undefined);
        }

        if (toastMessage) {
            setToastMessage(undefined);
        }

        setToastMessage({
            message: errorMessages[errorCode],
            values: {
                maxSize: formatNumber(validations.maxSize / 1024 ** 2, {
                    unit: 'megabyte',
                    maximumFractionDigits: 1,
                }),
                maxFiles: validations.maxFiles,
                totalMaxSize: formatNumber(
                    (validations.multipleTotalMaxSize ||
                        validations.totalMaxSize) /
                        1024 ** 2,
                    {
                        unit: 'megabyte',
                        maximumFractionDigits: 1,
                    }
                ),
                nbRejectedFiles: params?.nbRejectedFiles,
                allowedTypes: getDisplayableMimeTypes(
                    validations.acceptedMimeTypes
                ).join(', '),
                duplicatedFileName: params?.duplicatedFileName,
            },
        });
    }

    const onDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
        const errorCodes: string[] = [];
        rejectedFiles?.map((rejectedFile) => {
            errorCodes.push(rejectedFile.errors[0].code);
        });
    }, []);

    function removeFilesTooLarge(filesArray: File[]) {
        const filteredArray = filesArray.filter(
            (f) => f.size <= validations.maxSize
        );
        const nbFilesTooLarge = filesArray.length - filteredArray.length;

        if (validations.maxSize && nbFilesTooLarge > 0) {
            toastError('file-too-large', { nbRejectedFiles: nbFilesTooLarge });
        }

        return filteredArray;
    }

    function removeWrongTypeFiles(filesArray: File[]) {
        const filteredArray = filesArray.filter((f) =>
            validations.acceptedMimeTypes.includes(f.type)
        );
        const nbWrongTypeFiles = filesArray.length - filteredArray.length;

        if (nbWrongTypeFiles > 0) {
            toastError('file-invalid-type', {
                nbRejectedFiles: nbWrongTypeFiles,
            });
        }

        return filteredArray;
    }

    function removeOverCategoryMaxSizeFiles(filesArray: File[]) {
        if (!validations.totalMaxSize) return filesArray;

        const newCategoryFilesSize = filesArray.reduce(
            (acc, curr) => acc + curr.size,
            0
        );

        if (totalSize + newCategoryFilesSize > validations.totalMaxSize) {
            toastError('category-max-size', {
                nbRejectedFiles: filesArray.length,
            });
            return [];
        }

        return filesArray;
    }

    function removeFilesOverMaxTotalSize(filesArray: File[]) {
        const newFilesSize = filesArray.reduce(
            (acc, curr) => acc + curr.size,
            0
        );

        if (availableFileSize && newFilesSize > availableFileSize) {
            toastError('total-max-size-multiple', {
                nbRejectedFiles: filesArray.length,
            });
            return [];
        }

        return filesArray;
    }

    function removeEmptyFiles(filesArray: File[]) {
        const nbEmptyFiles = filesArray.filter(
            (file) => file.size === 0
        ).length;

        if (nbEmptyFiles) {
            toastError('contains-empty-files', {
                nbRejectedFiles: nbEmptyFiles,
            });
        }

        return filesArray.filter((file) => file.size > 0);
    }

    function removeDuplicatedFiles(filesArray: File[]) {
        const existingFiles = files.map((f) => `${f.name}-${f.size}`);
        const duplicatedFiles = filesArray.filter((newFile) =>
            existingFiles.includes(`${newFile.name}-${newFile.size}`)
        );
        const filteredFilesArray = filesArray.filter(
            (newFile) =>
                !existingFiles.includes(`${newFile.name}-${newFile.size}`)
        );

        if (duplicatedFiles?.length >= 1) {
            toastError('file-already-exists', {
                duplicatedFileName: duplicatedFiles[0].name,
            });
        }

        if (filesArray?.length === 1 && duplicatedFiles?.length === 1) {
            return [];
        }

        return filteredFilesArray;
    }

    function checkNumberOfFiles(filesArray: File[]) {
        if (filesArray.length > validations.maxFiles - files.length) {
            toastError('too-many-files', {
                nbRejectedFiles: filesArray.length,
            });
            return [];
        }

        return filesArray;
    }

    function checkFiles(filesToCheck: File[]) {
        if (!filesToCheck) return;

        const noFilesOverMaxNumber = checkNumberOfFiles(filesToCheck);
        const noOverCategoryMaxSizeArray =
            removeOverCategoryMaxSizeFiles(noFilesOverMaxNumber);
        const noOverTotalMaxSizeArray = removeFilesOverMaxTotalSize(
            noOverCategoryMaxSizeArray
        );
        const noDuplicateArray = removeDuplicatedFiles(noOverTotalMaxSizeArray);
        const noEmptyFiles = removeEmptyFiles(noDuplicateArray);
        const noWrongExtensionFiles = removeWrongTypeFiles(noEmptyFiles);
        const filteredFilesArray = removeFilesTooLarge(noWrongExtensionFiles);

        return filteredFilesArray;
    }

    function handleChange(this: HTMLInputElement) {
        checkFiles(toArray(this.files))?.forEach((file) => {
            toBase64(file).then((content) => {
                const finalObject = {
                    size: file.size,
                    name: file.name,
                    content,
                };
                setFiles((currentFiles) => [...currentFiles, finalObject]);
                setTotalSize((size) => size + file.size);
            });
        });
    }

    function addFile() {
        inputRef.current = document.createElement('input');
        const input = inputRef.current;
        input.setAttribute('type', 'file');
        input.setAttribute(
            'accept',
            validations.acceptedMimeTypes?.join(',') ?? 'image/*'
        );
        input.addEventListener('change', handleChange);
        if (validations.maxFiles > 1) {
            input.setAttribute('multiple', 'true');
        }
        input.click();
        input.remove();
    }

    function dropFiles(files) {
        const list = new DataTransfer();
        files?.map((file) => {
            list.items.add(file);
        });
        const filesList = list.files;

        inputRef.current = document.createElement('input');
        const input = inputRef.current;
        input.setAttribute('type', 'file');
        input.setAttribute(
            'accept',
            validations.acceptedMimeTypes?.join(',') ?? 'image/*'
        );
        input.addEventListener('change', handleChange);
        input.setAttribute('multiple', 'true');
        input.files = filesList;
        const event = new Event('change');
        input.dispatchEvent(event);
        input.remove();
    }

    function removeFile(params: { index: number; file: FileObject }) {
        const { index, file } = params;
        const newFilesGroup = files.filter((_, idx) => idx !== index);

        files.forEach((_, idx) => {
            unregister(`${id}.file${categoryId ?? ''}${idx + 1}`);
        });

        setFiles(newFilesGroup);
        setTotalSize((size) => size - file.size);
    }

    React.useEffect(() => {
        if (!files) return;

        files.forEach((file, idx) => {
            setValue(
                `${id}.file${categoryId ?? ''}${idx + 1}`,
                JSON.stringify(file)
            );
        });
    }, [files, id, categoryId, setValue, unregister]);

    return {
        files,
        setFiles,
        addFile,
        removeFile,
        onDropAccepted: dropFiles,
        onDropRejected,
        toastMessage,
    };
}

export type { FileObject };
export default useFileUpload;
