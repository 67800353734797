import { Icon, IconProps } from '@chakra-ui/react';

export default function HeartMonitorIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M4 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm16 2H4v6.25h2.434l1.332-4.66c.351-1.23 2.104-1.201 2.415.04l1.975 7.9 2.17-5.582c.416-1.07 1.933-1.06 2.336.014l.858 2.288H20V4zM4 16.5v-4.75h2.623a1.25 1.25 0 0 0 1.202-.907L8.952 6.9l1.94 7.758c.295 1.182 1.935 1.286 2.377.15l2.218-5.705.69 1.837c.182.488.648.811 1.17.811H20v4.75H4zM4 18v2h16v-2H4z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
