import { Icon, IconProps } from '@chakra-ui/react';

export default function FlowersIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M9.573 2H8.572l.001 1.001.004 2.425-.32-.554-.499-.868-.867.5-.503.29a3.912 3.912 0 0 0-1.432 5.344h.002a2.746 2.746 0 0 0 3.176 1.252l.547.946-.073-.014a1 1 0 0 0-1.157 1.255l1.314 4.6-.526 2.627A1 1 0 0 0 9.22 22h5.561a1 1 0 0 0 .98-1.196l-.5-2.5 2.004-5.341a1 1 0 0 0-1.253-1.3l-.677.226.29-.503a2.746 2.746 0 0 0 3.176-1.252h.002a3.912 3.912 0 0 0-1.432-5.343l-.503-.29L16 4l-.5.868-.347.604A5.023 5.023 0 0 0 10.376 2h-.803zm.413 10.597 1.473.295v-2.504a3.394 3.394 0 0 1-1.553-.665 2.733 2.733 0 0 1-.884 1.205l.964 1.67zm4.75-1.673-.832 1.442-1.445.48v-2.451a3.39 3.39 0 0 0 1.44-.556c.179.417.461.794.837 1.085zm-1.34-3.906a3.021 3.021 0 0 0-2.821-3.012l.004 3.141a1.41 1.41 0 0 0 2.814-.129h.003zm1.26 7.205-1.772.59a3 3 0 0 1-1.537.096l-1.523-.305L10.51 17h3.105l1.041-2.777zM10.64 19l-.2 1h3.12l-.2-1h-2.72zM16.71 6.774c.643.592.814 1.57.36 2.359l-.002-.001a.745.745 0 0 1-1.327-.675l.97-1.683zM6.687 9.137a1.912 1.912 0 0 1 .36-2.36l.969 1.684a.745.745 0 0 1-1.327.674l-.002.002z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
