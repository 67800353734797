import React, { Dispatch, SetStateAction } from 'react';
import { Button, LinkBox, LinkOverlay, Tooltip } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { LinkIntl, useSafeIntl } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { MessageDescriptor } from 'react-intl';

export type MenuItemProps = React.PropsWithChildren<unknown> & {
    to: string;
    label: string;
    id: number;
    isActive: boolean;
    handleKeyDown: (evt: React.KeyboardEvent<Element>) => void;
    setCurrentFocus: (idx: number) => void;
    setIsMenuExpanded: (value: boolean) => void;
    resetFilters: Dispatch<SetStateAction<unknown[]>>;
    intlMessages: {
        [key: string]: MessageDescriptor;
    };
};

export function MenuItem({
    children,
    to = '/',
    label,
    isActive,
    handleKeyDown,
    id,
    setCurrentFocus,
    setIsMenuExpanded,
    resetFilters,
    intlMessages,
}: MenuItemProps) {
    const router = useRouter();
    const { safeFormatMessage } = useSafeIntl();
    const { isSmallDevice } = useWindowBreakpoints();

    const handleOnClick = (): void => {
        if (to !== router.pathname) resetFilters([]);
    };

    return (
        <LinkBox justifySelf="center">
            <LinkOverlay
                tabIndex={-1}
                as={LinkIntl}
                href={to}
                onClick={handleOnClick}
                passHref>
                <Tooltip
                    label={safeFormatMessage(
                        intlMessages[`menu.${label}`],
                        undefined,
                        label
                    )}
                    placement="right"
                    bg="grey.50"
                    color="black"
                    fontSize="md"
                    fontWeight="semibold"
                    rounded="md"
                    {...(isSmallDevice && { display: 'none' })}>
                    <Button
                        h="100%"
                        variant="unstyled"
                        onKeyDown={handleKeyDown}
                        onClick={() => {
                            setIsMenuExpanded(false);
                            setCurrentFocus(id);
                        }}
                        fontWeight={isActive ? 'bold' : 'normal'}>
                        {children}
                    </Button>
                </Tooltip>
            </LinkOverlay>
        </LinkBox>
    );
}
