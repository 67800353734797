import { Icon, IconProps } from '@chakra-ui/react';

const ServicesIcon = (props: IconProps) => (
    <Icon
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        {...props}>
        <path
            d="M10.9966 14.8636V16.4922C10.9966 16.7713 11.0935 17.0068 11.2871 17.1988C11.4808 17.3908 11.7184 17.4868 12 17.4868C12.2815 17.4868 12.5192 17.3908 12.7128 17.1988C12.9065 17.0068 13.0033 16.7713 13.0033 16.4922V14.8636H14.6462C14.9278 14.8636 15.1654 14.7676 15.359 14.5756C15.5527 14.3836 15.6496 14.1481 15.6496 13.869C15.6496 13.5899 15.5527 13.3544 15.359 13.1624C15.1654 12.9704 14.9278 12.8744 14.6462 12.8744H13.0033V11.2458C13.0033 10.9667 12.9065 10.7312 12.7128 10.5392C12.5192 10.3472 12.2815 10.2512 12 10.2512C11.7184 10.2512 11.4808 10.3472 11.2871 10.5392C11.0935 10.7312 10.9966 10.9667 10.9966 11.2458V12.8744H9.35378C9.07222 12.8744 8.83461 12.9704 8.64093 13.1624C8.44726 13.3544 8.35042 13.5899 8.35042 13.869C8.35042 14.1481 8.44726 14.3836 8.64093 14.5756C8.83461 14.7676 9.07222 14.8636 9.35378 14.8636H10.9966ZM5.00671 21C4.45126 21 3.97794 20.8061 3.58675 20.4183C3.19558 20.0306 3 19.5614 3 19.0108V8.72724C3 8.17663 3.1956 7.70743 3.5868 7.31966C3.97797 6.9319 4.45128 6.73802 5.00673 6.73802H8.30826V4.98925C8.30826 4.43863 8.50385 3.96944 8.89503 3.58166C9.2862 3.19389 9.75952 3 10.315 3H13.6851C14.2405 3 14.7138 3.19389 15.105 3.58166C15.4962 3.96944 15.6918 4.43863 15.6918 4.98925V6.73802H18.9933C19.5487 6.73802 20.022 6.9319 20.4132 7.31966C20.8044 7.70743 21 8.17663 21 8.72724V19.0108C21 19.5614 20.8044 20.0306 20.4132 20.4183C20.022 20.8061 19.5487 21 18.9933 21L5.00671 21ZM5.00671 19.0108H18.9933V8.72724H5.00671V19.0108ZM10.3149 6.73802H13.685V4.98925H10.3149V6.73802Z"
            fill="currentColor"
        />
    </Icon>
);
export default ServicesIcon;
