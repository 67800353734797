import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams, UtilsContext } from 'core';
import React from 'react';
import { useCredentialsConfig } from 'lib/auth';
import { buildUrl } from './utilsTermsFooter';

export type UsePrivacyPolicy = {
    privacyPolicy: string;
};

const path = `/api/proxy/[microService]/privacy-policy`;

export async function fetchPrivacyPolicy({
    queryKey: [urn, isLoggedIn, client_id, _, microService],
}: FetchQueryParams) {
    try {
        const parsedUrn = buildUrl({
            urn,
            isLoggedIn: isLoggedIn === 'true',
            client_id,
            microService,
            endpoint: 'privacy-policy',
        });
        const response = await axios.get(parsedUrn);
        return microService === 'primo'
            ? { privacyPolicy: response.data.data.text }
            : (response.data.data as UsePrivacyPolicy);
    } catch (error) {
        throw { code: error.response?.status };
    }
}

export function usePrivacyPolicy(
    isLoggedIn: boolean = false,
    microService?: string
) {
    const {
        siteConfig: { client_id },
    } = React.useContext(UtilsContext);
    const { data: credentialsConfig } = useCredentialsConfig(isLoggedIn);
    const isEnabled =
        isLoggedIn ||
        credentialsConfig?.legals?.privacyPolicy ||
        microService === 'primo';

    return useQuery<UsePrivacyPolicy, APIError>({
        queryKey: [path, isLoggedIn, client_id, isEnabled, microService],
        queryFn: main.fetchPrivacyPolicy,
        enabled: isEnabled,
    });
}

const main = {
    useQuery,
    fetchPrivacyPolicy,
};
