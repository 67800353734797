import { Icon, IconProps } from '@chakra-ui/react';

export default function NotesIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M15 3.81952V12.5V12.5C15 13.8807 13.8807 15 12.5 15C11.1193 15 10 13.8807 10 12.5C10 11.1193 11.1193 10 12.5 10C12.6712 10 12.8384 10.0172 13 10.05V6.18044L9 6.84711V13.5C9 14.8807 7.88071 16 6.5 16C5.11929 16 4 14.8807 4 13.5C4 12.1193 5.11929 11 6.5 11C6.67123 11 6.83844 11.0172 7 11.05V5.15285L15 3.81952Z"
                fill="currentColor"
            />
            <path
                d="M16.6857 9.05067C17.0951 8.91517 17.5451 9.05728 17.8024 9.40327L17.8081 9.41082L17.826 9.43485L17.8962 9.52838C17.9574 9.60962 18.046 9.7267 18.1548 9.86893C18.3728 10.1538 18.6704 10.5376 18.9906 10.9356C19.3122 11.3352 19.6499 11.7407 19.9491 12.0722C20.0988 12.2381 20.2323 12.3781 20.3446 12.4864C20.4004 12.5403 20.447 12.5825 20.4844 12.614C20.5029 12.6296 20.5175 12.6412 20.5286 12.6496C20.5394 12.6579 20.545 12.6616 20.545 12.6616C21.008 12.9625 21.1394 13.5819 20.8384 14.045C20.5375 14.5081 19.9181 14.6394 19.455 14.3384C19.1372 14.1318 18.7721 13.7531 18.4644 13.4122C18.3136 13.2451 18.1569 13.0646 18 12.8789V18.5C18 19.8807 16.8807 21 15.5 21C14.1193 21 13 19.8807 13 18.5C13 17.1193 14.1193 16 15.5 16C15.6712 16 15.8384 16.0172 16 16.05V10C16 9.56882 16.2764 9.18618 16.6857 9.05067Z"
                fill="currentColor"
            />
        </Icon>
    );
}
