import { Icon, IconProps } from '@chakra-ui/react';

export default function UnarchiveIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M12 13a1 1 0 0 0 1-1V5.045l2.097 1.63a1 1 0 0 0 1.228-1.578L12.614 2.21a1 1 0 0 0-1.188-.03l-4.18 2.926a1 1 0 1 0 1.147 1.639L11 4.92V12a1 1 0 0 0 1 1z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M6.5 9a2 2 0 0 0-1.6.8l-2.694 3.592A.996.996 0 0 0 2 14v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6a.996.996 0 0 0-.206-.608L19.1 9.8a2 2 0 0 0-1.6-.8 1 1 0 1 0 0 2l1.5 2h-3c-.552 0-.984.46-1.164.982a3.001 3.001 0 0 1-5.672 0C8.984 13.46 8.552 13 8 13H5l1.5-2a1 1 0 1 0 0-2zm5.5 9a5.001 5.001 0 0 0 4.584-3H20v5H4v-5h3.416A5.001 5.001 0 0 0 12 18z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
