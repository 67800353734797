import { hasPatternMatches, Validations, regex } from 'core';

function isValid(value: string, validations: Validations) {
    const validatedObject = {};

    Object.keys(validations).forEach((validationKey: keyof Validations) => {
        switch (validationKey) {
            case 'numberOfRequiredNumbers': {
                validatedObject[validationKey] = hasPatternMatches(
                    value,
                    validations[validationKey],
                    regex.number
                );
                break;
            }
            case 'numberOfRequiredCapitalLetters': {
                validatedObject[validationKey] = hasPatternMatches(
                    value,
                    validations[validationKey],
                    regex.capitalLetter
                );
                break;
            }
            case 'numberOfRequiredSpecialCharacters': {
                validatedObject[validationKey] = hasPatternMatches(
                    value,
                    validations[validationKey],
                    regex.specialCharacters
                );
                break;
            }
            case 'numberOfRequiredLowercaseLetters': {
                validatedObject[validationKey] = hasPatternMatches(
                    value,
                    validations[validationKey],
                    regex.lowerLetter
                );
                break;
            }
            case 'lengthMin': {
                validatedObject[validationKey] =
                    value.length >= validations[validationKey];
                break;
            }
            case 'lengthMax': {
                validatedObject[validationKey] =
                    value.length <= validations[validationKey];
                break;
            }
        }
    });

    return validatedObject;
}

export { isValid };
