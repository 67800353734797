import { Icon, IconProps } from '@chakra-ui/react';

export default function OtherIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M12 10a1 1 0 0 1 1 1v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 1 1 0-2h1v-1a1 1 0 0 1 1-1z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M7 3c1.175 0 2.435.405 3.497 1.083A6.34 6.34 0 0 1 12 5.403a6.34 6.34 0 0 1 1.503-1.32C14.565 3.405 15.825 3 17 3a5 5 0 0 1 5 5c0 5-5 9-10 13C7 17 2 13 2 8a5 5 0 0 1 5-5zm10.375 10.552c-1.448 1.641-3.323 3.227-5.375 4.884-2.052-1.657-3.927-3.243-5.375-4.884C4.893 11.589 4 9.797 4 8a3 3 0 0 1 3-3c1.198 0 2.649.683 3.449 1.667L12 8.572l1.551-1.905C14.351 5.683 15.801 5 17 5a3 3 0 0 1 3 3c0 1.797-.893 3.589-2.625 5.552z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
