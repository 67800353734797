import { Icon, IconProps } from '@chakra-ui/react';

export default function PrintIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M5.75 10.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M17 3v3h3.1c1.05 0 1.9.85 1.9 1.9v7.2a1.9 1.9 0 0 1-1.9 1.9H18l.76 3.804A1 1 0 0 1 17.78 22H6.22a1 1 0 0 1-.98-1.196L6 17H3.9A1.9 1.9 0 0 1 2 15.1V7.9C2 6.85 2.85 6 3.9 6H7V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1zM9 6V4h6v2H9zm11 9h-2.4l-.44-2.196a1 1 0 0 0-.98-.804H7.82a1 1 0 0 0-.98.804L6.4 15H4V8h16v7zM8.64 14h6.72l1.2 6H7.44l1.2-6z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
