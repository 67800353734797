import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import {
    FormInputCardItem,
    TreatmentWidgetObject,
    FormGroup,
    useInputValidation,
    WidgetProps,
} from 'core';
import { MultipleTreatmentsAdd } from './Add';
import { useMultipleTreatments } from './useMultipleTreatments';

type TreatmentWidgetsProps = WidgetProps & {
    treatmentWidgets: TreatmentWidgetObject[];
};

export function MultipleTreatments({
    validations,
    treatmentWidgets,
    id,
    title,
    help,
    readonly,
    description,
}: TreatmentWidgetsProps) {
    const { add, remove, UITreatments, canAdd, shouldShowInput } =
        useMultipleTreatments(id, validations);
    const { registerValues } = useInputValidation(validations, id);

    return (
        <FormGroup
            label={title}
            name={id}
            isRequired={validations.required}
            isReadOnly={readonly}
            {...{ id, help, description }}>
            {shouldShowInput && <input type="hidden" {...registerValues} />}

            <SimpleGrid columns={[2, 3, 4]} gap="2">
                <MultipleTreatmentsAdd
                    onAdd={add}
                    widgets={treatmentWidgets[0]}
                    isDisabled={!canAdd}
                />

                {UITreatments?.map(
                    ({ key, subItems, badge, title }, position) => (
                        <FormInputCardItem
                            key={key}
                            color={badge.bg}
                            onDelete={() => remove(position)}
                            {...{ badge, subItems, title }}
                        />
                    )
                )}
            </SimpleGrid>
        </FormGroup>
    );
}
