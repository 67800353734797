import { CircularProgress, VStack, Text, BoxProps } from '@chakra-ui/react';

export function LoadingCard({
    children,
    ...props
}: React.PropsWithChildren<BoxProps>) {
    return (
        <VStack spacing={6} {...props}>
            <CircularProgress
                thickness="6"
                color="primary.main"
                size="40px"
                isIndeterminate
            />
            {children && <Text color="grey.900">{children}</Text>}
        </VStack>
    );
}
