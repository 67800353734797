import React from 'react';
import { v4 as uuid } from 'uuid';
import { capitalize } from 'lodash';
import { useIntl } from 'react-intl';
import { useSafeIntl, FormValues, BadgeProps, formats } from 'core';
import { Beneficiary, useBeneficiaries } from 'lib/contracts';

export type UITreatement = {
    key: string;
    subItems: string[];
    title: string;
    badge: BadgeProps;
};

function getBeneficiaryData(
    beneficiaries: Beneficiary[],
    treatment: FormValues[]
) {
    const beneficiaryValue = treatment.find(({ id }) =>
        id.includes('beneficiary')
    ).value;

    if (beneficiaryValue.includes('###')) {
        const [lastName, firstName, status] = beneficiaryValue.split('###');
        return { lastName, firstName, status };
    }

    return beneficiaries.find(
        (beneficiary) => beneficiary.familyPosition === beneficiaryValue
    );
}

export function useTransformUITreatments() {
    const intl = useIntl();
    const { safeFormatNumber } = useSafeIntl();
    const { data } = useBeneficiaries();
    const [beneficiaries, setBeneficiaries] = React.useState<Beneficiary[]>();

    React.useEffect(() => {
        if (data?.beneficiaries) {
            setBeneficiaries(data.beneficiaries);
        }
    }, [data?.beneficiaries]);

    const transformUITreatments = (treatment: FormValues[]): UITreatement => {
        const { lastName, firstName, status } = getBeneficiaryData(
            beneficiaries,
            treatment
        );
        const date = treatment.find(({ id }) => id.includes('date')).value;
        const amount = treatment.find(({ id }) => id.includes('amount')).value;
        const subItems = [
            intl.formatDate(date, { ...formats.date }),
            safeFormatNumber(
                +amount,
                {
                    currency: 'eur',
                    style: 'currency',
                },
                'transformUITreatments'
            ),
        ];

        return {
            key: uuid(),
            subItems,
            title: `${capitalize(firstName)} ${capitalize(lastName)}`,
            badge: {
                bg: `user.${status}`,
                label: `${firstName[0]}${lastName[0]}`,
            },
        };
    };

    return transformUITreatments;
}
