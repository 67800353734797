import React, { useEffect, useMemo, useState } from 'react';
import { FormErrorMessage } from '@chakra-ui/react';
import { WidgetProps } from '../types';
import {
    type extraLabel,
    formMessages,
    getExtraLabel,
    useInputValidation,
} from 'core/lib/forms';
import { FormGroup, useSafeIntl } from 'core/components';
import { SearchDropdown } from './SearchDropdown';
import { Checkboxes } from './Checkboxes';
import { omit } from 'lodash';
import { useController } from 'react-hook-form';

export type CheckboxProps = {
    label: string;
    id: string;
    isDefaultValue: boolean;
    isTranslatable: boolean;
    extra: extraLabel[];
    hidden?: boolean;
    subWidget?: WidgetProps;
};

type queryParameters = {
    key: string;
    value: string;
};

export type CheckboxListProps = WidgetProps & {
    options: CheckboxProps[];
    isSearchable?: boolean;
    autocompleteUri?: string | null;
    queryParameters?: queryParameters[] | null;
    allowAllCheck?: boolean;
    nbDisplayColumns?: number;
};

export const formatCheckboxesValues = (value) => {
    if (!value) {
        return [];
    }

    return Array.isArray(value)
        ? value.filter((item, idx) => value.indexOf(item) === idx)
        : [value];
};

export const translateLabel = (
    label: string,
    isTranslatable: boolean,
    safeFormatMessage
): string => {
    return isTranslatable
        ? safeFormatMessage(
              formMessages[label],
              { month: 'X' },
              `CheckboxList label: ${label}`
          )
        : label;
};

function CheckboxList({
    id,
    name,
    validations,
    links,
    title,
    description,
    options,
    help,
    isSearchable = false,
    autocompleteUri = null,
    queryParameters = null,
    allowAllCheck = false,
    nbDisplayColumns = 1,
}: CheckboxListProps) {
    const { safeFormatMessage } = useSafeIntl();
    const inputName = name ?? `${id}.value`;
    const { formState, register, registerValues, setValue } =
        useInputValidation(validations, inputName);
    const { field } = useController({ name: inputName });
    const [isRendered, setIsRendered] = useState(false);

    const hasExtraInfo = useMemo(
        () => options.some((option) => option.extra?.length > 0),
        [options]
    );

    useEffect(() => {
        if (!!autocompleteUri) {
            register(inputName, omit(validations, 'lengthMin'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocompleteUri, inputName, validations]);

    const prepareOption = (
        option: CheckboxProps
    ): { id: string; label: string; extraLabel: string } => {
        const label = translateLabel(
            option.label,
            option.isTranslatable,
            safeFormatMessage
        );

        const extraLabel = getExtraLabel(option.extra, safeFormatMessage);

        return {
            id: option.id,
            label,
            extraLabel,
        };
    };

    useEffect(() => {
        if (isRendered) {
            const defaultOptions = options
                ?.filter((option) => !!option.isDefaultValue)
                ?.map((option) => JSON.stringify(prepareOption(option)));
            setValue(inputName, formatCheckboxesValues(defaultOptions));
        }
    }, [inputName, options, setValue, isRendered]);

    useEffect(() => {
        setIsRendered(true);
    }, []);

    const widgetProps = {
        options,
        id,
        validations,
        autoCompleteUri: autocompleteUri,
        queryParameters,
        allowAllCheck,
        hasExtraInfo,
        help,
        onChange: registerValues.onChange,
        onBlur: registerValues.onBlur,
        name: inputName,
        value: field.value || [],
        setValue: (value) => setValue(inputName, value),
    };

    return (
        <FormGroup
            label={title}
            name={inputName}
            isInvalid={!!formState.errors[id]}
            {...{ id, description, help, links }}>
            {isSearchable ? (
                <SearchDropdown {...widgetProps} />
            ) : (
                <Checkboxes
                    {...widgetProps}
                    nbDisplayColumns={nbDisplayColumns}
                />
            )}
            {formState.errors?.[id]?.message && (
                <FormErrorMessage data-testid="error">
                    {formState.errors?.[id]?.message as string}
                </FormErrorMessage>
            )}
        </FormGroup>
    );
}

export default CheckboxList;
