import { Icon, IconProps } from '@chakra-ui/react';

export default function CalculatorIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 17C2 17.5523 2.44772 18 3 18L17 18C17.5523 18 18 17.5523 18 17V3C18 2.44772 17.5523 2 17 2L3 2C2.44772 2 2 2.44772 2 3L2 17ZM3 20C1.34315 20 0 18.6569 0 17V3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34314 20 3V17C20 18.6569 18.6569 20 17 20L3 20ZM9 14H4V13H9V14ZM6 9V7H4V6H6V4H7V6H9V7H7V9H6ZM12.7929 13.5L11.3787 14.9142L12.0858 15.6213L13.5001 14.2071L14.9143 15.6213L15.6214 14.9142L14.2072 13.5L15.6214 12.0858L14.9143 11.3787L13.5 12.7929L12.0858 11.3787L11.3787 12.0858L12.7929 13.5ZM14 4.5C14 4.77614 13.7761 5 13.5 5C13.2239 5 13 4.77614 13 4.5C13 4.22386 13.2239 4 13.5 4C13.7761 4 14 4.22386 14 4.5ZM11 7V6H16V7H11ZM14 8.5C14 8.77614 13.7761 9 13.5 9C13.2239 9 13 8.77614 13 8.5C13 8.22386 13.2239 8 13.5 8C13.7761 8 14 8.22386 14 8.5Z"
                fill="currentcolor"
            />
        </Icon>
    );
}
