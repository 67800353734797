import { useTheme } from '@chakra-ui/react';

export default function RegistrationPathsProvisionalIcon() {
    const theme = useTheme();
    const colors = theme.colors;

    return (
        <svg
            width="116"
            height="100"
            viewBox="0 0 116 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M66.1969 60.25L52.8594 64.2261L55.3029 72.4226L68.6403 68.4465L66.1969 60.25Z"
                    fill="url(#paint0_linear)"
                />
                <path
                    d="M65.9695 60.5385L53.084 64.3799L55.3646 72.0302L68.2502 68.1889L65.9695 60.5385Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M54.1854 65.9947L55.8252 65.5059L55.4635 64.2925L53.8237 64.7814L54.1854 65.9947Z"
                        fill="#6C63FF"
                    />
                    <path
                        opacity="0.6"
                        d="M56.2339 67.9536L62.5283 66.0771L62.411 65.6837L56.1166 67.5602L56.2339 67.9536Z"
                        fill="#6C63FF"
                    />
                    <path
                        opacity="0.6"
                        d="M58.1935 68.2974L62.7832 66.9292L62.705 66.6669L58.1153 68.0352L58.1935 68.2974Z"
                        fill="#6C63FF"
                    />
                </g>
                <path
                    d="M86.9816 40.8183L85.3467 49.2136L99.0076 51.874L100.642 43.4787L86.9816 40.8183Z"
                    fill="url(#paint1_linear)"
                />
                <path
                    d="M86.8937 41.1881L85.377 49.0115L98.5539 51.5662L100.071 43.7428L86.8937 41.1881Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M87.3205 43.011L89 43.3381L89.242 42.0954L87.5625 41.7683L87.3205 43.011Z"
                        fill="#6C63FF"
                    />
                    <path
                        opacity="0.6"
                        d="M88.2268 45.6966L94.6738 46.9521L94.7523 46.5492L88.3052 45.2937L88.2268 45.6966Z"
                        fill="#6C63FF"
                    />
                    <path
                        opacity="0.6"
                        d="M89.8038 46.9109L94.5049 47.8264L94.5572 47.5578L89.8561 46.6423L89.8038 46.9109Z"
                        fill="#6C63FF"
                    />
                </g>
                <path
                    d="M95.4775 57.3927L87.6309 60.7964L93.1693 73.5644L101.016 70.1608L95.4775 57.3927Z"
                    fill="url(#paint2_linear)"
                />
                <path
                    d="M95.351 57.6344L88.0273 60.8113L93.3781 73.1466L100.702 69.9698L95.351 57.6344Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M93.8591 58.916L94.54 60.4858L95.7015 59.982L95.0206 58.4122L93.8591 58.916Z"
                        fill="#47E6B1"
                    />
                    <path
                        opacity="0.6"
                        d="M92.1577 61.1838L94.7715 67.2095L95.1481 67.0461L92.5343 61.0204L92.1577 61.1838Z"
                        fill="#47E6B1"
                    />
                    <path
                        opacity="0.6"
                        d="M92.0491 63.1702L93.9551 67.564L94.2062 67.4551L92.3002 63.0613L92.0491 63.1702Z"
                        fill="#47E6B1"
                    />
                </g>
                <path
                    d="M57.3678 19.9309L44.0303 23.907L46.4738 32.1035L59.8112 28.1274L57.3678 19.9309Z"
                    fill="url(#paint3_linear)"
                />
                <path
                    d="M57.1404 20.2207L44.2549 24.062L46.5355 31.7123L59.4211 27.871L57.1404 20.2207Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M45.3495 25.6795L46.9893 25.1907L46.6276 23.9774L44.9878 24.4662L45.3495 25.6795Z"
                        fill="#F55F44"
                    />
                    <path
                        opacity="0.6"
                        d="M47.3989 27.6379L53.6934 25.7615L53.5761 25.3681L47.2816 27.2445L47.3989 27.6379Z"
                        fill="#F55F44"
                    />
                    <path
                        opacity="0.6"
                        d="M49.3576 27.9823L53.9473 26.614L53.8691 26.3517L49.2794 27.72L49.3576 27.9823Z"
                        fill="#F55F44"
                    />
                </g>
                <path
                    d="M78.1496 0.501216L76.5146 8.89648L90.1755 11.5568L91.8104 3.16157L78.1496 0.501216Z"
                    fill="url(#paint4_linear)"
                />
                <path
                    d="M78.277 0.743499L76.751 8.57935L89.949 11.1496L91.4749 3.3137L78.277 0.743499Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M78.4884 2.69513L80.168 3.02222L80.41 1.77949L78.7304 1.4524L78.4884 2.69513Z"
                        fill="#6C63FF"
                    />
                    <path
                        opacity="0.6"
                        d="M79.3947 5.38071L85.8418 6.63623L85.9203 6.23329L79.4732 4.97777L79.3947 5.38071Z"
                        fill="#6C63FF"
                    />
                    <path
                        opacity="0.6"
                        d="M80.9719 6.59379L85.6729 7.50928L85.7252 7.24065L81.0242 6.32517L80.9719 6.59379Z"
                        fill="#6C63FF"
                    />
                </g>
                <path
                    d="M86.6434 17.0768L78.7969 20.4805L84.3353 33.2485L92.1819 29.8449L86.6434 17.0768Z"
                    fill="url(#paint5_linear)"
                />
                <path
                    d="M86.518 17.318L79.1943 20.4949L84.5451 32.8302L91.8688 29.6534L86.518 17.318Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M85.0281 18.6001L85.709 20.1699L86.8705 19.6661L86.1895 18.0963L85.0281 18.6001Z"
                        fill="#47E6B1"
                    />
                    <path
                        opacity="0.6"
                        d="M83.3247 20.8679L85.9385 26.8936L86.3151 26.7302L83.7013 20.7045L83.3247 20.8679Z"
                        fill="#47E6B1"
                    />
                    <path
                        opacity="0.6"
                        d="M83.2172 22.8558L85.123 27.2495L85.3741 27.1406L83.4682 22.7469L83.2172 22.8558Z"
                        fill="#47E6B1"
                    />
                </g>
                <path
                    d="M19.8971 50.2732L6.55957 54.2493L9.00306 62.4458L22.3405 58.4697L19.8971 50.2732Z"
                    fill="url(#paint6_linear)"
                />
                <path
                    d="M19.6707 50.5627L6.78516 54.4041L9.06582 62.0544L21.9513 58.213L19.6707 50.5627Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M7.88558 56.0267L9.52539 55.5378L9.16369 54.3245L7.52387 54.8134L7.88558 56.0267Z"
                        fill="#6C63FF"
                    />
                    <path
                        opacity="0.6"
                        d="M9.93506 57.9851L16.2295 56.1086L16.1122 55.7152L9.81778 57.5917L9.93506 57.9851Z"
                        fill="#6C63FF"
                    />
                    <path
                        opacity="0.6"
                        d="M11.8947 58.329L16.4844 56.9607L16.4062 56.6984L11.8165 58.0667L11.8947 58.329Z"
                        fill="#6C63FF"
                    />
                </g>
                <path
                    d="M40.6828 30.8462L39.0479 39.2415L52.7087 41.9018L54.3437 33.5065L40.6828 30.8462Z"
                    fill="url(#paint7_linear)"
                />
                <path
                    d="M40.6642 31.1549L39.1475 38.9783L52.3244 41.533L53.8412 33.7096L40.6642 31.1549Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M41.0206 33.0384L42.7002 33.3655L42.9422 32.1227L41.2626 31.7957L41.0206 33.0384Z"
                        fill="#F55F44"
                    />
                    <path
                        opacity="0.6"
                        d="M41.9279 35.7242L48.375 36.9797L48.4535 36.5768L42.0064 35.3213L41.9279 35.7242Z"
                        fill="#F55F44"
                    />
                    <path
                        opacity="0.6"
                        d="M43.5051 36.9383L48.2061 37.8538L48.2584 37.5851L43.5574 36.6697L43.5051 36.9383Z"
                        fill="#F55F44"
                    />
                </g>
                <path
                    d="M49.1767 47.4208L41.3301 50.8245L46.8685 63.5925L54.7151 60.1889L49.1767 47.4208Z"
                    fill="url(#paint8_linear)"
                />
                <path
                    d="M49.0512 47.662L41.7275 50.8389L47.0783 63.1743L54.402 59.9974L49.0512 47.662Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M47.5593 48.9444L48.2402 50.5142L49.4017 50.0103L48.7208 48.4405L47.5593 48.9444Z"
                        fill="#47E6B1"
                    />
                    <path
                        opacity="0.6"
                        d="M45.8589 51.2108L48.4727 57.2366L48.8493 57.0732L46.2355 51.0475L45.8589 51.2108Z"
                        fill="#47E6B1"
                    />
                    <path
                        opacity="0.6"
                        d="M45.7504 53.1981L47.6562 57.5918L47.9073 57.4829L46.0014 53.0892L45.7504 53.1981Z"
                        fill="#47E6B1"
                    />
                </g>
                <path
                    d="M21.4664 26.1968L8.12891 30.1729L10.5724 38.3694L23.9099 34.3933L21.4664 26.1968Z"
                    fill="url(#paint9_linear)"
                />
                <path
                    d="M21.4784 26.3575L8.57031 30.2568L10.8854 37.9206L23.7935 34.0212L21.4784 26.3575Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M9.45101 31.9508L11.0908 31.4619L10.7291 30.2486L9.08931 30.7375L9.45101 31.9508Z"
                        fill="#6C63FF"
                    />
                    <path
                        opacity="0.6"
                        d="M11.4995 33.9097L17.7939 32.0332L17.6767 31.6398L11.3822 33.5162L11.4995 33.9097Z"
                        fill="#6C63FF"
                    />
                    <path
                        opacity="0.6"
                        d="M13.4591 34.2535L18.0488 32.8853L17.9706 32.623L13.3809 33.9912L13.4591 34.2535Z"
                        fill="#6C63FF"
                    />
                </g>
                <path
                    d="M42.1397 6.86906L40.5146 15.251L54.1538 17.8953L55.7789 9.51339L42.1397 6.86906Z"
                    fill="url(#paint10_linear)"
                />
                <path
                    d="M42.3775 7.01231L40.8516 14.8481L54.0495 17.4183L55.5755 9.58251L42.3775 7.01231Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M42.589 8.96344L44.2686 9.29053L44.5106 8.0478L42.831 7.72071L42.589 8.96344Z"
                        fill="#6C63FF"
                    />
                    <path
                        opacity="0.6"
                        d="M43.4953 11.6476L49.9424 12.9031L50.0209 12.5001L43.5738 11.2446L43.4953 11.6476Z"
                        fill="#6C63FF"
                    />
                    <path
                        opacity="0.6"
                        d="M45.0705 12.8619L49.7715 13.7773L49.8238 13.5087L45.1228 12.5932L45.0705 12.8619Z"
                        fill="#F55F44"
                    />
                </g>
                <path
                    d="M50.745 23.3456L42.8984 26.7493L48.4369 39.5173L56.2835 36.1137L50.745 23.3456Z"
                    fill="url(#paint11_linear)"
                />
                <path
                    d="M50.6177 23.5876L43.2939 26.7644L48.6447 39.0998L55.9685 35.9229L50.6177 23.5876Z"
                    fill="white"
                />
                <g opacity="0.6">
                    <path
                        opacity="0.6"
                        d="M49.1286 24.8689L49.8096 26.4387L50.9711 25.9349L50.2901 24.3651L49.1286 24.8689Z"
                        fill="#47E6B1"
                    />
                    <path
                        opacity="0.6"
                        d="M47.4263 27.1366L50.04 33.1624L50.4166 32.999L47.8029 26.9733L47.4263 27.1366Z"
                        fill="#47E6B1"
                    />
                    <path
                        opacity="0.6"
                        d="M47.3187 29.1214L49.2246 33.5151L49.4757 33.4062L47.5698 29.0125L47.3187 29.1214Z"
                        fill="#47E6B1"
                    />
                </g>
                <path
                    opacity="0.25"
                    d="M8.48939 8.86526C8.89074 8.86526 9.21609 8.53991 9.21609 8.13856C9.21609 7.73722 8.89074 7.41187 8.48939 7.41187C8.08805 7.41187 7.7627 7.73722 7.7627 8.13856C7.7627 8.53991 8.08805 8.86526 8.48939 8.86526Z"
                    fill="#535461"
                />
                <path
                    opacity="0.25"
                    d="M84.3556 72.5142C84.757 72.5142 85.0823 72.1888 85.0823 71.7875C85.0823 71.3861 84.757 71.0608 84.3556 71.0608C83.9543 71.0608 83.6289 71.3861 83.6289 71.7875C83.6289 72.1888 83.9543 72.5142 84.3556 72.5142Z"
                    fill="#535461"
                />
                <path
                    opacity="0.25"
                    d="M20.1652 68.9009C20.5665 68.9009 20.8919 68.5756 20.8919 68.1742C20.8919 67.7729 20.5665 67.4475 20.1652 67.4475C19.7638 67.4475 19.4385 67.7729 19.4385 68.1742C19.4385 68.5756 19.7638 68.9009 20.1652 68.9009Z"
                    fill="#535461"
                />
                <path
                    opacity="0.25"
                    d="M91.1115 58.7855C91.5128 58.7855 91.8382 58.4601 91.8382 58.0587C91.8382 57.6574 91.5128 57.332 91.1115 57.332C90.7101 57.332 90.3848 57.6574 90.3848 58.0587C90.3848 58.4601 90.7101 58.7855 91.1115 58.7855Z"
                    fill="#535461"
                />
                <path
                    opacity="0.25"
                    d="M97.9494 25.4505C98.3507 25.4505 98.676 25.1251 98.676 24.7238C98.676 24.3224 98.3507 23.9971 97.9494 23.9971C97.548 23.9971 97.2227 24.3224 97.2227 24.7238C97.2227 25.1251 97.548 25.4505 97.9494 25.4505Z"
                    fill="#535461"
                />
                <path
                    opacity="0.25"
                    d="M44.2979 0.646987H43.6522V0H43.3878V0.646987H42.7422V0.910033H43.3878V1.55569H43.6522V0.910033H44.2979V0.646987Z"
                    fill="#535461"
                />
                <path
                    opacity="0.25"
                    d="M19.4346 10.5485H18.7889V9.90283H18.5246V10.5485H17.8789V10.8129H18.5246V11.4585H18.7889V10.8129H19.4346V10.5485Z"
                    fill="#535461"
                />
                <path
                    opacity="0.25"
                    d="M9.32034 47.4452H8.67468V46.7996H8.41031V47.4452H7.76465V47.7096H8.41031V48.3553H8.67468V47.7096H9.32034V47.4452Z"
                    fill="#535461"
                />
                <path
                    opacity="0.25"
                    d="M7.86854 21.1328H7.22155V20.4858H6.95851V21.1328H6.31152V21.3959H6.95851V22.0429H7.22155V21.3959H7.86854V21.1328Z"
                    fill="#535461"
                />
                <path
                    opacity="0.25"
                    d="M1.55569 39.2136H0.910033V38.5667H0.646987V39.2136H0V39.4767H0.646987V40.1223H0.910033V39.4767H1.55569V39.2136Z"
                    fill="#535461"
                />
                <path
                    opacity="0.25"
                    d="M115.207 29.8903H114.561V29.2446H114.297V29.8903H113.651V30.1547H114.297V30.8003H114.561V30.1547H115.207V29.8903Z"
                    fill="#535461"
                />
                <path
                    opacity="0.25"
                    d="M92.6983 38.2957H92.0526V37.6487H91.7882V38.2957H91.1426V38.5587H91.7882V39.2057H92.0526V38.5587H92.6983V38.2957Z"
                    fill="#535461"
                />
                <path
                    opacity="0.25"
                    d="M73.4769 15.6259H72.83V14.9802H72.5669V15.6259H71.9199V15.8903H72.5669V16.5359H72.83V15.8903H73.4769V15.6259Z"
                    fill="#535461"
                />
                <path
                    d="M36.3879 52.5747C35.9229 54.0839 36.6204 55.8681 37.9821 56.6625C38.5521 56.9947 39.2097 57.1634 39.7637 57.5234C40.966 58.3059 41.4402 59.8071 41.8308 61.1875C42.2785 62.775 42.7289 64.453 42.3184 66.0498C42.0527 67.0675 41.4615 67.9682 41.1227 68.9646C40.8398 69.7988 40.7414 70.6845 40.8344 71.5605C40.8836 72.0255 41.0005 72.5091 41.318 72.8518C41.7777 73.3474 42.5137 73.4218 43.1779 73.5413C44.5848 73.8084 45.9083 74.4052 47.0398 75.2828C48.1714 76.1603 49.0788 77.2936 49.6877 78.5897"
                    stroke="url(#paint12_linear)"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <path
                    d="M36.5305 52.5747C36.0655 54.0839 36.763 55.8681 38.1247 56.6625C38.6946 56.9947 39.3523 57.1634 39.9062 57.5234C41.1086 58.3059 41.5828 59.8071 41.9734 61.1875C42.4211 62.775 42.8715 64.453 42.461 66.0498C42.1953 67.0675 41.6041 67.9682 41.2653 68.9646C40.9824 69.7988 40.884 70.6845 40.977 71.5605C41.0262 72.0255 41.1431 72.5091 41.4606 72.8518C41.9203 73.3474 42.6563 73.4218 43.3205 73.5413C44.7274 73.8084 46.0509 74.4052 47.1824 75.2828C48.314 76.1603 49.2214 77.2936 49.8303 78.5897"
                    stroke="#535461"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <path
                    d="M54.1294 41.2983C52.9829 42.8926 51.7925 44.6196 51.6942 46.5818C51.5441 49.6016 53.9527 52.0713 55.0381 54.8957C55.5049 56.1396 55.7161 57.4648 55.659 58.7922C55.602 60.1196 55.2778 61.4218 54.7059 62.621C54.1745 63.7091 53.4292 64.7891 53.5209 65.9941C53.5767 66.7301 53.9447 67.3983 54.241 68.0746C54.968 69.7691 55.2854 71.6112 55.1672 73.4514C55.049 75.2915 54.4986 77.0779 53.5608 78.6655"
                    stroke="url(#paint13_linear)"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <path
                    d="M54.271 41.2983C53.1245 42.8926 51.9341 44.6196 51.8358 46.5818C51.6857 49.6016 54.0943 52.0713 55.1797 54.8957C55.6467 56.1395 55.8581 57.4647 55.8013 58.792C55.7445 60.1194 55.4205 61.4217 54.8489 62.621C54.3175 63.7091 53.5722 64.7891 53.6638 65.9941C53.7196 66.7301 54.0876 67.3983 54.3839 68.0746C55.111 69.7691 55.4283 71.6112 55.3101 73.4514C55.1919 75.2915 54.6416 77.0779 53.7037 78.6655"
                    stroke="#535461"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <path
                    d="M70.2253 55.6807L70.7793 60.1976C70.8523 60.7981 70.9121 61.4531 70.5933 61.9645C70.2877 62.4494 69.7151 62.6806 69.2648 63.0393C67.8034 64.215 67.9362 66.4735 68.4358 68.2843C68.9353 70.0951 69.6686 72.04 68.9672 73.7817C68.6775 74.5004 68.17 75.1102 67.7834 75.7745C67.1355 76.9277 66.8787 78.2599 67.0514 79.5714"
                    stroke="url(#paint14_linear)"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <path
                    d="M70.3581 55.6807L70.912 60.1976C70.9851 60.7981 71.0449 61.4531 70.7261 61.9645C70.4205 62.4494 69.8479 62.6806 69.3975 63.0393C67.9362 64.215 68.069 66.4735 68.5686 68.2843C69.0681 70.0951 69.8014 72.04 69.1 73.7817C68.8103 74.5004 68.3028 75.1102 67.9162 75.7745C67.2684 76.9277 67.0115 78.2599 67.1842 79.5714"
                    stroke="#535461"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
                <g opacity="0.7">
                    <path
                        opacity="0.7"
                        d="M84.6026 37.0989C84.6009 34.9847 84.0992 32.901 83.1384 31.0178C82.1776 29.1345 80.785 27.5053 79.0743 26.2631C77.3637 25.0208 75.3834 24.2008 73.2953 23.87C71.2072 23.5392 69.0705 23.707 67.0596 24.3597C67.1704 23.6066 67.2277 22.8465 67.231 22.0853C67.2525 18.6015 65.9159 15.2463 63.5049 12.7314C61.0938 10.2166 57.7979 8.73993 54.3163 8.61463C50.8346 8.48934 47.4411 9.72528 44.8555 12.0603C42.2699 14.3953 40.6957 17.6458 40.4667 21.1221C38.4555 20.4682 36.3182 20.2994 34.2293 20.6295C32.1404 20.9596 30.1593 21.7793 28.4478 23.0215C26.7363 24.2637 25.343 25.8933 24.3817 27.777C23.4205 29.6607 22.9186 31.7451 22.917 33.8599C22.917 40.5822 27.867 50.1436 34.3223 51.8374L35.3253 52.4113L34.7806 54.2792H38.0089L37.3075 52.4087L38.3065 51.8374C43.2938 50.5288 47.3816 44.5239 48.9838 38.7595C49.8602 39.344 50.8251 39.7832 51.8414 40.0601L52.8445 40.634L52.2998 42.5019H55.5281L54.8266 40.6314L55.8257 40.0601C56.567 39.8607 57.2809 39.5706 57.9513 39.1966C58.8135 45.6598 63.3982 53.5525 69.2091 55.0763L70.2122 55.6503L69.6675 57.5182H72.8958L72.1943 55.6476L73.1934 55.0763C79.6579 53.3825 84.6026 43.8212 84.6026 37.0989Z"
                        fill="url(#paint15_linear)"
                    />
                </g>
                <path
                    d="M38.5179 53.5936H35.3945L35.9405 51.719H37.8151L38.5179 53.5936Z"
                    fill={colors.primary.main}
                />
                <path
                    d="M49.8424 33.836C49.8424 40.9954 44.0381 51.4853 36.8787 51.4853C29.7193 51.4853 23.915 40.99 23.915 33.836C23.915 30.3978 25.2808 27.1004 27.712 24.6693C30.1432 22.2381 33.4405 20.8723 36.8787 20.8723C40.3169 20.8723 43.6142 22.2381 46.0454 24.6693C48.4765 27.1004 49.8424 30.3978 49.8424 33.836Z"
                    fill={colors.primary.main}
                />
                <path
                    d="M37.8146 51.7975H35.94L34.8467 51.1731H38.908L37.8146 51.7975Z"
                    fill="#535461"
                />
                <path
                    opacity="0.1"
                    d="M32.1923 23.7617C32.1923 23.7617 26.7254 26.7296 25.9482 32.6628L32.1923 23.7617Z"
                    fill="white"
                />
                <path
                    d="M55.471 42.1975H52.3477L52.8937 40.323H54.7682L55.471 42.1975Z"
                    fill={colors.secondary.main}
                />
                <path
                    d="M66.7952 22.4385C66.7952 29.5979 60.9909 40.0878 53.8315 40.0878C46.6722 40.0878 40.8652 29.5992 40.8652 22.4385C40.8652 19.0003 42.231 15.703 44.6622 13.2718C47.0934 10.8407 50.3907 9.47485 53.8289 9.47485C57.2671 9.47485 60.5644 10.8407 62.9956 13.2718C65.4267 15.703 66.7925 19.0003 66.7925 22.4385H66.7952Z"
                    fill={colors.secondary.main}
                />
                <path
                    d="M54.7677 40.4014H52.8932L51.7998 39.7756H55.8611L54.7677 40.4014Z"
                    fill="#535461"
                />
                <path
                    opacity="0.1"
                    d="M49.1464 12.3645C49.1464 12.3645 43.6795 15.3324 42.9023 21.2656L49.1464 12.3645Z"
                    fill="white"
                />
                <path
                    d="M72.2819 56.7277H69.1572L69.7046 54.8545H71.5778L72.2819 56.7277Z"
                    fill={colors.tertiary.main}
                />
                <path
                    d="M83.606 36.97C83.606 44.1294 77.8017 54.6193 70.6424 54.6193C63.483 54.6193 57.6787 44.1241 57.6787 36.97C57.6787 33.5318 59.0445 30.2345 61.4757 27.8033C63.9068 25.3722 67.2042 24.0063 70.6424 24.0063C74.0805 24.0063 77.3779 25.3722 79.809 27.8033C82.2402 30.2345 83.606 33.5318 83.606 36.97Z"
                    fill={colors.tertiary.main}
                />
                <path
                    d="M71.5779 54.9315H69.7047L68.6113 54.3071H72.6713L71.5779 54.9315Z"
                    fill="#535461"
                />
                <path
                    opacity="0.1"
                    d="M65.9559 26.8958C65.9559 26.8958 60.4891 29.8637 59.7119 35.7968L65.9559 26.8958Z"
                    fill="white"
                />
                <path
                    d="M75.0827 76.3847L39.2314 78.1772L40.3331 100.211L76.1844 98.4181L75.0827 76.3847Z"
                    fill="url(#paint16_linear)"
                />
                <path
                    d="M74.3053 76.7489L39.7148 78.5193L40.7659 99.0552L75.3564 97.2847L74.3053 76.7489Z"
                    fill={colors.grey['100']}
                />
                <path
                    d="M41.4546 83.2675L45.8555 83.0422L45.6888 79.785L41.2878 80.0102L41.4546 83.2675Z"
                    fill={colors.tertiary.main}
                />
                <path
                    d="M45.388 89.4206L62.2871 88.5557L62.2331 87.4995L45.3339 88.3645L45.388 89.4206Z"
                    fill={colors.tertiary.main}
                />
                <path
                    d="M50.0802 91.475L62.4033 90.8442L62.3673 90.1397L50.0441 90.7704L50.0802 91.475Z"
                    fill={colors.tertiary.main}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="61.9716"
                    y1="70.4346"
                    x2="59.5281"
                    y2="62.238"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="700.228"
                    y1="4871.57"
                    x2="871.502"
                    y2="3992.08"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="-4913.21"
                    y1="4373.28"
                    x2="-4091.2"
                    y2="4016.71"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="923.316"
                    y1="2466.82"
                    x2="766.004"
                    y2="1939.12"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="440.457"
                    y1="736.485"
                    x2="611.731"
                    y2="-143.003"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="-5147.3"
                    y1="1642.26"
                    x2="-4325.3"
                    y2="1285.7"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear"
                    x1="-3169.32"
                    y1="4952.79"
                    x2="-3326.66"
                    y2="4425.02"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear"
                    x1="-2225.88"
                    y1="4191.47"
                    x2="-2054.6"
                    y2="3311.98"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear"
                    x1="-9292.96"
                    y1="4388.7"
                    x2="-8470.95"
                    y2="4032.13"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear"
                    x1="-2752.37"
                    y1="3283.43"
                    x2="-2909.68"
                    y2="2755.74"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear"
                    x1="-1932.51"
                    y1="1667.78"
                    x2="-1762.27"
                    y2="789.687"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear"
                    x1="-8762.43"
                    y1="2650.54"
                    x2="-7940.42"
                    y2="2293.98"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear"
                    x1="5927.46"
                    y1="14811.7"
                    x2="7314.57"
                    y2="14811.7"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear"
                    x1="2258.8"
                    y1="19663.5"
                    x2="2386.06"
                    y2="19663.5"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint14_linear"
                    x1="2637.39"
                    y1="13977"
                    x2="2755.99"
                    y2="13977"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear"
                    x1="35249.1"
                    y1="24787"
                    x2="35249.1"
                    y2="6808.38"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear"
                    x1="176.34"
                    y1="17409.1"
                    x2="-6.37294"
                    y2="13754.8"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#808080" stopOpacity="0.25" />
                    <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                    />
                    <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="115.208" height="100" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
