import React from 'react';
import { Box, chakra, useToken } from '@chakra-ui/react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { ListItemNode, ListNode } from '@lexical/list';
import { formMessages, useSafeIntl } from 'core';
import { IS_DEV_ENV } from 'core/consts';
import ToolbarPlugin from './RichTextareaPlugins/ToolbarPlugin';
import { MaxLengthPlugin } from './RichTextareaPlugins/MaxLengthPlugin';
import { LoadInitialContent } from './RichTextareaPlugins/LoadInitialContentPlugin';
import { CustomOnChangePlugin } from './RichTextareaPlugins/CustomOnChangePlugin';

const buildEditorStyle = (boxShadowColor: string) => {
    return {
        '&:focus-within': {
            borderColor: 'primary.main',
            boxShadow: `0px 0px 0px 1px ${boxShadowColor}`,
        },
        '.editor-text-bold': {
            fontWeight: 'bold',
        },
        '.editor-text-italic': {
            fontStyle: 'italic',
        },
        '.editor-text-underline': {
            textDecoration: 'underline',
        },
        '.editor-list-ul': {
            p: 0,
            m: 0,
            ml: 4,
        },
    };
};

const richTextareaTheme = {
    ltr: 'ltr',
    rtl: 'rtl',
    placeholder: 'editor-placeholder',
    paragraph: 'editor-paragraph',
    list: {
        ul: 'editor-list-ul',
        listitem: 'editor-listitem',
    },
    text: {
        bold: 'editor-text-bold',
        italic: 'editor-text-italic',
        underline: 'editor-text-underline',
    },
};

const ChakraContentEditable = chakra(ContentEditable);

type EditorProps = {
    placeholder: string;
    initialContent: string;
    maxLength: number | null;
    isInvalid: boolean;
    onChange: (htmlContent: string, contentSize: number) => void;
};

export default function Editor({
    placeholder,
    initialContent,
    maxLength,
    isInvalid,
    onChange,
}: EditorProps) {
    const [primaryColor] = useToken('colors', ['primary.main']);
    const { safeFormatMessage } = useSafeIntl();
    const initialConfig = {
        namespace: 'CoreRichText',
        theme: richTextareaTheme,
        // eslint-disable-next-line no-console
        onError: IS_DEV_ENV ? console.error : () => {},
        nodes: [ListNode, ListItemNode],
    };

    const _renderPlaceholder = (): JSX.Element => {
        return (
            <Box
                position="absolute"
                top={4}
                px={4}
                color={'gray.500'}
                display="inline-block"
                pointerEvents="none">
                {placeholder
                    ? safeFormatMessage(
                          formMessages[placeholder],
                          null,
                          `<RichTextarea /> - ${placeholder}`
                      )
                    : ''}
            </Box>
        );
    };

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <LoadInitialContent initialContent={initialContent} />
            <ToolbarPlugin />
            <ListPlugin />
            <Box position="relative">
                <RichTextPlugin
                    contentEditable={
                        <ChakraContentEditable
                            minH="150px"
                            p={4}
                            resize="vertical"
                            overflow="auto"
                            position="relative"
                            border="1px solid"
                            borderColor={
                                isInvalid ? 'red.500' : 'strokes.medium'
                            }
                            borderBottomRadius="md"
                            outline={0}
                            sx={buildEditorStyle(primaryColor)}
                        />
                    }
                    placeholder={_renderPlaceholder()}
                    ErrorBoundary={LexicalErrorBoundary}
                />
            </Box>
            <CustomOnChangePlugin onChange={onChange} />
            <HistoryPlugin />
            <AutoFocusPlugin />
            {maxLength !== null && <MaxLengthPlugin maxLength={maxLength} />}
        </LexicalComposer>
    );
}
