import React from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { Box, Tooltip } from '@chakra-ui/react';

import { useWindowBreakpoints } from 'design-system/hooks';
import { InfoIcon } from 'design-system/icons';

type FormHelperIconProps = {
    children: string | React.ReactElement;
    mb?: string;
    iconColor?: string;
};

const ICON_SIZE = 5;

const intlMessages = defineMessage({
    id: 'components.forms.additionals-informations',
    defaultMessage: 'Informations supplémentaires',
    description:
        "Label du bouton d'aide d'un champs de formulaire pour les screens reader.",
});

export function FormHelperIcon({
    children,
    mb = '2',
    iconColor = 'black',
}: FormHelperIconProps) {
    const { formatMessage } = useIntl();
    const { isMobile } = useWindowBreakpoints();
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <Tooltip
            label={children}
            placement={isMobile ? 'bottom-end' : 'auto'}
            aria-label={formatMessage(intlMessages)}
            isOpen={isOpen}
            fontSize="sm"
            color="black"
            bg="white"
            mx={isMobile ? 4 : 0}
            maxW={isMobile ? '290px' : '400px'}
            border="1px solid"
            borderColor="strokes.medium"
            rounded="md"
            p="4">
            <Box
                display="flex"
                alignItems="center"
                onMouseEnter={() => {
                    !isMobile && setIsOpen(true);
                }}
                onMouseLeave={() => {
                    !isMobile && setIsOpen(false);
                }}
                onFocus={() => {
                    !isMobile && setIsOpen(true);
                }}
                onBlur={() => {
                    !isMobile && setIsOpen(false);
                }}
                onClick={() => {
                    isMobile && setIsOpen((open) => !open);
                }}
                mb={mb}>
                <InfoIcon color={iconColor} w={ICON_SIZE} h={ICON_SIZE} />
            </Box>
        </Tooltip>
    );
}
