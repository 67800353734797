import { defineMessages } from 'react-intl';

export const refundCategoriesMessages = defineMessages({
    analyses: {
        id: 'refunds.category.analyses',
        defaultMessage: 'Analyses',
        description: 'Categorie de remboursement : Analyses',
    },
    appliances: {
        id: 'refunds.category.appliances',
        defaultMessage: 'Appareillage',
        description: 'Categorie de remboursement : Appareillage',
    },
    audio: {
        id: 'refunds.category.audio',
        defaultMessage: 'Audio',
        description: 'Categorie de remboursement : Audio',
    },
    'medical-auxiliaries': {
        id: 'refunds.category.medical-auxiliaries',
        defaultMessage: 'Auxiliaires médicaux',
        description: 'Categorie de remboursement : Auxiliaires médicaux',
    },
    generalist: {
        id: 'refunds.category.generalist',
        defaultMessage: 'Généraliste',
        description: 'Categorie de remboursement : Généraliste',
    },
    'alternative-medicine': {
        id: 'refunds.category.alternative-medicine',
        defaultMessage: 'Médecine douce',
        description: 'Categorie de remboursement : Médecine douce',
    },
    miscellaneous: {
        id: 'refunds.category.miscellaneous',
        defaultMessage: 'Divers',
        description: 'Categorie de remboursement : Divers',
    },
    specialist: {
        id: 'refunds.category.specialist',
        defaultMessage: 'Spécialiste',
        description: 'Categorie de remboursement : Spécialiste',
    },
    cure: {
        id: 'refunds.category.cure',
        defaultMessage: 'Cure',
        description: 'Categorie de remboursement : Cure',
    },
    dental: {
        id: 'refunds.category.dental',
        defaultMessage: 'Dentaire',
        description: 'Categorie de remboursement : Dentaire',
    },
    'care-abroad': {
        id: 'refunds.category.care-abroad',
        defaultMessage: "Soins à l'étranger",
        description: "Categorie de remboursement : Soins à l'étranger",
    },
    hospitalization: {
        id: 'refunds.category.hospitalization',
        defaultMessage: 'Hospitalisation',
        description: 'Categorie de remboursement : Hospitalisation',
    },
    surgical: {
        id: 'refunds.category.surgical',
        defaultMessage: 'Hospitalisation chirurgicale',
        description:
            'Categorie de remboursement : Hospitalisation chirurgicale',
    },
    maternity: {
        id: 'refunds.category.maternity',
        defaultMessage: 'Maternité',
        description: 'Categorie de remboursement : Maternité',
    },
    optical: {
        id: 'refunds.category.optical',
        defaultMessage: 'Optique',
        description: 'Categorie de remboursement : Optique',
    },
    pharmacy: {
        id: 'refunds.category.pharmacy',
        defaultMessage: 'Pharmacie',
        description: 'Categorie de remboursement : Pharmacie',
    },
    'technical-medical-acts': {
        id: 'refunds.category.technical-medical-acts',
        defaultMessage: 'Actes techniques médicaux',
        description: 'Categorie de remboursement : Actes techniques médicaux',
    },
    radiology: {
        id: 'refunds.category.radiology',
        defaultMessage: 'Radiologie',
        description: 'Categorie de remboursement : Radiologie',
    },
    transportation: {
        id: 'refunds.category.transportation',
        defaultMessage: 'Transport',
        description: 'Categorie de remboursement : Transport',
    },
    'city-medicine': {
        id: 'refunds.category.city-medicine',
        defaultMessage: 'Médecine de ville',
        description: 'Categorie de remboursement : Médecine de ville',
    },
    psychiatrist: {
        id: 'refunds.category.psychiatrist',
        defaultMessage: 'Psychiatrie',
        description: 'Categorie de remboursement : Psychiatrie',
    },
    'laboratory-radiology': {
        id: 'refunds.category.laboratory-radiology',
        defaultMessage: 'Laboratoire et radiologie',
        description: 'Categorie de remboursement : Laboratoire et radiologie',
    },
    'allowances-benefits': {
        id: 'refunds.category.allowances-benefits',
        defaultMessage: 'Primes et indemnités',
        description: 'Categorie de remboursement : Primes et indemnités',
    },
    'other-cares': {
        id: 'refunds.category.other-cares',
        defaultMessage: 'Autres soins',
        description: 'Categorie de remboursement : Autres soins',
    },
    'standard-care': {
        id: 'refunds.category.standard-care',
        defaultMessage: 'Soins courants',
        description: 'Categorie de remboursement : Soins courants',
    },
    orthopaedics: {
        id: 'refunds.category.orthopaedics',
        defaultMessage: 'Orthopédie',
        description: 'Categorie de remboursement : Orthopédie',
    },
    'category-undefined': {
        id: 'refunds.category.undefined',
        defaultMessage: 'Catégorie Inconnue',
    },
});
