import { Icon, IconProps } from '@chakra-ui/react';

export default function BellIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M13.981 4.268a2 2 0 1 0-3.962 0A7.403 7.403 0 0 0 4.6 11.4c0 .458.023.954.044 1.425l.022.502c.025.633.031 1.194-.02 1.665-.053.486-.156.745-.249.868-.045.06-.114.14-.397.14H3a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2h-1c-.349 0-.433-.1-.473-.151-.087-.112-.187-.357-.231-.84-.043-.467-.023-1.024.015-1.663l.022-.364c.032-.515.067-1.067.067-1.582 0-3.4-2.294-6.266-5.419-7.132zM12 6a5.4 5.4 0 0 0-5.4 5.4c0 .41.02.835.04 1.288.008.182.017.369.024.56.025.642.039 1.33-.03 1.96a5.28 5.28 0 0 1-.143.792H17.44a5.016 5.016 0 0 1-.135-.81c-.057-.632-.027-1.325.01-1.961l.025-.403c.032-.508.061-.98.061-1.426A5.4 5.4 0 0 0 12 6z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                d="M14 20a1 1 0 0 1-1-1h-2a3 3 0 1 0 6 0h-2a1 1 0 0 1-1 1z"
                fill="currentColor"
            />
        </Icon>
    );
}
