import { Icon, IconProps } from '@chakra-ui/react';

export default function IdCardIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M12 9.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75zM16 12.75a.75.75 0 0 0-.75-.75h-2.5a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 .75-.75z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M7.5 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0-1.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M4 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4zm16 2H4v7.67c.165-.206.355-.392.568-.556.808-.62 1.864-.864 2.932-.864 1.068 0 2.124.244 2.932.864.837.643 1.318 1.623 1.318 2.886H20V7zm-9.75 10c0-.846-.302-1.366-.732-1.696-.46-.353-1.153-.554-2.018-.554-.865 0-1.559.201-2.018.554-.43.33-.732.85-.732 1.696h5.5z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
