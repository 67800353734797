import React from 'react';

import { useRouter } from 'next/router';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { __DEV__ } from 'core';

type MatomoProps = React.PropsWithChildren<unknown>;

export default function MatomoProvider({ children }: MatomoProps) {
    const router = useRouter();
    const { trackPageView } = useMatomo();

    React.useEffect(() => {
        if (!__DEV__) {
            trackPageView({});
        }
    }, [router.route, trackPageView]);

    return <>{children}</>;
}
