import React from 'react';
import { useInputValidation } from 'core';
import InputBase from '../InputBase';
import { WidgetProps } from './types';
import getInputName from 'core/lib/forms/helpers/getInputName';

function InputText(
    props: Omit<WidgetProps, 'type'>,
    inputRef?: (ref: HTMLInputElement) => void
) {
    const { registerValues } = useInputValidation(
        props.validations,
        getInputName(props.id, props.name)
    );

    return (
        <InputBase
            type="text"
            {...registerValues}
            ref={(el) => {
                registerValues.ref(el);
                if (inputRef) inputRef(el);
            }}
            {...props}
        />
    );
}

export default React.forwardRef(InputText);
