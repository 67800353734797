import { formMessages, formatSiret } from 'core';

export type extraLabel = {
    id: string;
    text: string;
    isTranslatable: boolean;
};

export const getExtraLabel = (extra: extraLabel[], safeFormatMessage) => {
    if (!extra || extra.length === 0) return '';

    const firstExtra = extra[0];

    if (firstExtra.id === 'siret') {
        return formatSiret(firstExtra.text);
    }

    if (firstExtra.isTranslatable) {
        return safeFormatMessage(
            formMessages[firstExtra.text],
            null,
            `CheckboxList extra: ${firstExtra.text}`
        );
    }

    return firstExtra.text;
};
