import React from 'react';
import { CustomUseToastOptionsProps, useToast } from '../Toast';

export function ToastAlert(options: CustomUseToastOptionsProps) {
    const toastIdRef = React.useRef<string | number | undefined>();

    const toast = useToast(options);

    React.useEffect(() => {
        toastIdRef.current = toast();
        return () => toast.closeAll({ positions: ['bottom'] });
    }, [toast]);

    return null;
}
