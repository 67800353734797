import { Box, Flex, Stack, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useRouter } from 'next/router';
import axios from 'axios';
import {
    CapitalProcedureTypesEnum,
    FormNextStep,
    FormWorkflow,
    WorkflowTypesEnum,
    useRedirect,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { PeopleIcon, PersonIcon } from 'design-system/icons';
import React from 'react';
import { UrlObject } from 'url';

export enum CapitalTypesEnum {
    FULL = 'full-capital',
    DIVIDED = 'divided-capital',
}

const intlMessages = {
    [CapitalTypesEnum.FULL]: defineMessages({
        title: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.distribution-capital.full-capital-title',
            defaultMessage: 'Désigner un seul bénéficiaire',
        },
        description: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.distribution-capital.full-capital-description',
            defaultMessage: '100% du capital est versé à un seul bénéficiaire',
        },
    }),
    [CapitalTypesEnum.DIVIDED]: defineMessages({
        title: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.distribution-capital.divided-capital-title',
            defaultMessage: 'Désigner plusieurs bénéficiaires',
        },
        description: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.distribution-capital.divided-capital-description',
            defaultMessage: 'Le capital est versé à plusieurs bénéficiaires',
        },
    }),
};

export type handleNextStepFnType = (
    nextStep: FormNextStep,
    redirect: (path: string | UrlObject) => Promise<boolean>,
    additionalData?: any
) => void;

type CardBeneficiaryOptionProps = {
    project: WorkflowTypesEnum;
    type: CapitalTypesEnum;
    onError: (errorCode: number) => void;
    handleNextStep: handleNextStepFnType;
};

export default function CardBeneficiaryOption({
    project,
    type,
    onError,
    handleNextStep,
}: CardBeneficiaryOptionProps) {
    const router = useRouter();
    const workflow = router.query?.workflow as FormWorkflow;
    const { redirect } = useRedirect();
    const { isMobile } = useWindowBreakpoints();

    async function redirectToSubWorkflow() {
        if (project === WorkflowTypesEnum.PA) {
            const path = `/api/proxy/provident/beneficiaries-designation/${type}`;

            try {
                const response = await axios.post(path);
                handleNextStep(response?.data?.meta?.next_step, redirect, {
                    type,
                    workflow,
                });
            } catch (error) {
                onError(error?.response?.status);
            }
        } else {
            redirect({
                pathname: '/beneficiary-designations/form',
                query: {
                    workflow:
                        type === CapitalTypesEnum.FULL
                            ? CapitalProcedureTypesEnum.FULL
                            : CapitalProcedureTypesEnum.DIVIDED,
                },
            });
        }
    }
    return (
        <Box
            onClick={redirectToSubWorkflow}
            cursor="pointer"
            data-testid="card-beneficiary-option">
            <VStack
                spacing="5"
                border="1px"
                borderStyle="solid"
                borderColor="grey.300"
                px={isMobile ? '5' : '28'}
                py="8"
                borderRadius="lg"
                textAlign="center"
                gap="0"
                boxShadow="lg"
                height="100%">
                <Stack spacing="2">
                    <Text fontWeight="bold" lineHeight="1.3">
                        <FormattedMessage {...intlMessages[type].title} />
                    </Text>
                    <Text
                        fontSize="xs"
                        color="grey.700"
                        as="i"
                        lineHeight="1.3">
                        <FormattedMessage {...intlMessages[type].description} />
                    </Text>
                </Stack>
                <Flex
                    bg="grey.200"
                    p="2"
                    rounded="full"
                    color="black"
                    fontSize="2xl">
                    {type === 'full-capital' ? <PersonIcon /> : <PeopleIcon />}
                </Flex>
            </VStack>
        </Box>
    );
}
