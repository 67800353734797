import { BoxProps, Text, ContainerProps } from '@chakra-ui/react';
import { purifyHTML } from 'core';

type BlockTextProps = {
    wysiwyg: {
        value: string;
        format: string;
        processed?: string;
    };
    containerProps?: React.PropsWithChildren<
        BoxProps & {
            columns?: number | Record<string, number>;
            size?: ContainerProps['size'];
        }
    >;
};

export default function BlockText({ wysiwyg, containerProps }: BlockTextProps) {
    return (
        <Text
            w="full"
            as="div"
            fontSize="sm"
            className="contents"
            dangerouslySetInnerHTML={{ __html: purifyHTML(wysiwyg?.value) }}
            data-testid="block-text"
            {...containerProps}
        />
    );
}
