import React from 'react';
import { KeenSliderInstance, useKeenSlider } from 'keen-slider-latest/react';
import { Box, Flex, Stack, HStack, Button } from '@chakra-ui/react';
import { useWindowBreakpoints } from 'design-system/hooks';
import { CarouselButtons } from 'design-system/components';
import 'keen-slider-latest/keen-slider.min.css';

type CarouselSlideProps = React.PropsWithChildren<unknown>;
export type CarouselProps = React.PropsWithChildren<{
    bg?: string;
    slidesPerView: number;
    nbSlides: number;
    useAbsoluteArrows?: boolean;
    navigation?: boolean;
}>;

export function CarouselSlide({ children }: CarouselSlideProps) {
    return (
        <Box
            className="keen-slider__slide"
            data-group
            sx={{
                '> *, > * > *': {
                    h: 'full',
                },
            }}>
            {children}
        </Box>
    );
}

export function Carousel({
    children,
    bg = 'transparent',
    slidesPerView = 2,
    nbSlides,
    useAbsoluteArrows = false,
    navigation = false,
    ...boxProps
}: CarouselProps) {
    const bgSection = bg === 'transparent' ? undefined : `bg.color${bg}`;
    const color = bg === 'dark' ? 'white' : undefined;

    const [currentIdx, setCurrentIdx] = React.useState<number>(0);
    const [maxIdx, setMaxIdx] = React.useState<number>(0);
    const [sliderRef, slider] = useKeenSlider<HTMLDivElement>();

    const isLastSlide = maxIdx - 1 === currentIdx;
    const isFirstSlide = currentIdx === 0;

    React.useEffect(() => {
        if (!slider) return;

        const nbSlidesPerView =
            nbSlides > slidesPerView ? slidesPerView + 0.1 : slidesPerView;
        slider.current?.update({
            slides: {
                perView: nbSlidesPerView,
                spacing: 8,
            },
        });
    }, [nbSlides, slidesPerView, slider]);

    React.useEffect(() => {
        const updateIdxStates = () => {
            setCurrentIdx(slider.current?.track.details.abs);
            if (slider.current) {
                setMaxIdx(slider.current.track.details.maxIdx + 1);
            }
        };

        updateIdxStates();
        slider.current?.on('slideChanged', updateIdxStates);
    });

    return (
        <Stack
            spacing={!useAbsoluteArrows ? '4' : undefined}
            bg={bgSection}
            color={color}
            {...boxProps}>
            {nbSlides > slidesPerView && (
                <CarouselArrows
                    {...{
                        useAbsoluteArrows,
                        isFirstSlide,
                        isLastSlide,
                        slider: slider.current,
                    }}
                />
            )}

            <Box ref={sliderRef} className="keen-slider">
                {children}
                {isLastSlide ? (
                    <TouchArea side="left" onClick={slider.current?.prev} />
                ) : (
                    <TouchArea side="right" onClick={slider.current?.next} />
                )}
            </Box>
            {navigation && maxIdx > 1 && (
                <HStack justifyContent="center" mt="4">
                    {Array.from({ length: maxIdx }).map((_, idx) => {
                        return (
                            <Box
                                key={idx}
                                as={Button}
                                onClick={() => {
                                    slider.current?.moveToIdx(idx);
                                }}
                                w={currentIdx === idx ? '10' : '3'}
                                minW="3"
                                h="3"
                                p="0"
                                bg={
                                    currentIdx === idx
                                        ? 'secondary.dark'
                                        : 'bg.light'
                                }
                                _hover={{ bg: 'secondary.dark' }}
                            />
                        );
                    })}
                </HStack>
            )}
        </Stack>
    );
}

type CarouselArrowsProps = {
    useAbsoluteArrows: boolean;
    isFirstSlide: boolean;
    isLastSlide: boolean;
    slider: KeenSliderInstance | null;
};

function CarouselArrows({
    useAbsoluteArrows,
    isFirstSlide,
    isLastSlide,
    slider,
}: CarouselArrowsProps) {
    return (
        <Flex
            {...(useAbsoluteArrows && {
                position: 'absolute',
                right: 0,
                top: 1,
            })}
            justifyContent="flex-end">
            <CarouselButtons
                isLastItem={isLastSlide}
                isFirstItem={isFirstSlide}
                onPrev={slider?.prev}
                onNext={slider?.next}
            />
        </Flex>
    );
}

type TouchAreaProps = {
    side: 'left' | 'right';
    onClick?: () => void;
};

function TouchArea({ side, onClick }: TouchAreaProps) {
    const { isMobile } = useWindowBreakpoints();

    return (
        <Box
            position="absolute"
            h="100%"
            cursor="pointer"
            w={isMobile ? '10%' : '5%'}
            {...{ [side]: 0 }}
            onClick={onClick}
        />
    );
}
