import { Icon, IconProps } from '@chakra-ui/react';

export default function RockingChairIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#a)">
                <path
                    fillRule="evenodd"
                    d="M5.333 4.417a.998.998 0 1 1 1.878-.679L8.744 8H13.5a1 1 0 1 1 0 2H9.464l.72 2h5.662a1 1 0 0 1 .941.662l1.896 5.282c.309-.138.617-.287.925-.449.488-.258 1.104-.113 1.392.358.288.471.14 1.09-.347 1.352-6.764 3.631-13.814 1.762-17.303-.014-.492-.25-.638-.866-.35-1.338.288-.471.902-.618 1.397-.373.496.246 1.068.493 1.705.72l2.33-5.257-3.099-8.526zm11.473 14.218c-3.163.936-6.26.742-8.771.14L10.15 14h4.992l1.663 4.635z"
                    clipRule="evenodd"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path d="M0 0H24V24H0z" fill="currentColor" />
                </clipPath>
            </defs>
        </Icon>
    );
}
