import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { AdherentUser } from 'core/lib/auth';
import { APIError } from 'core';

export const selfcareCampaignPath = `/api/proxy/security/user/account/selfcare-campaigns`;

export async function fetchSelfcareCampaigns() {
    try {
        const response = await axios.get(selfcareCampaignPath);
        const { data } = response?.data as Api<{
            selfcareCampaigns: AdherentUser.SelfcareCampaign[];
        }>;

        return data?.selfcareCampaigns;
    } catch (error) {
        throw new Error(error);
    }
}

export function useSelfcareCampaigns() {
    return useQuery<AdherentUser.SelfcareCampaign[], APIError>({
        queryKey: [selfcareCampaignPath],
        queryFn: fetchSelfcareCampaigns,
    });
}
