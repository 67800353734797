import React from 'react';
import axios from 'axios';
import { QueryStatus } from '@tanstack/react-query';
import { ChallengeError, useRedirect, ChallengeStrategy } from 'core';

export function useRegistrationChallengeGet() {
    const { redirect } = useRedirect();
    const [status, setStatus] = React.useState<QueryStatus>('pending');
    const [strategies, setStrategies] = React.useState<
        {
            strategy: ChallengeStrategy;
            contact: string;
        }[]
    >();
    const [error, setError] = React.useState<ChallengeError>();

    React.useEffect(() => {
        async function getChannels() {
            try {
                setStatus('pending');
                const { data } = await axios.get(`/api/registration-challenge`);
                setStrategies(data);
                setStatus('success');
            } catch (err) {
                const { status } = err.response;
                setStatus('error');

                switch (status) {
                    case 401:
                        redirect('/register');
                        break;
                    case 403:
                        setError({
                            code: 'REGISTRATION_CHALLENGE_MISSING_CONTACT',
                        });
                        break;
                    default:
                        setError({
                            code: 'REGISTRATION_CHALLENGE_UNKNOWN_ERROR',
                        });
                }
            }
        }

        getChannels();

        // Nous savons que `useRedirect#redirect` ne peut pas changer
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { error, status, strategies };
}
