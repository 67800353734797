import React from 'react';
import { Box } from '@chakra-ui/react';
import { WidgetProps } from '../types';
import type { FileObject } from './useFileUpload';
import formatFileName from './utils/formatFileName';
import { FormInputCardItem, formatFileExtension } from 'core';
import useFormattedFileSize from './utils/useFormattedFileSize';

type FileUploadItemProps = Pick<WidgetProps, 'name'> & {
    index: number;
    onDelete: (params: { index: number; file: FileObject }) => void;
    file: FileObject;
};

function FileUploadItem({ index, file, onDelete }: FileUploadItemProps) {
    const fileSize = useFormattedFileSize(file.size);
    const { color: badgeColor, text: badgeLabel } = formatFileExtension(
        file.name
    );

    return (
        <Box
            position="relative"
            data-testid="file-upload-item"
            sx={{
                '> *': {
                    h: '100%',
                },
            }}>
            <FormInputCardItem
                title={formatFileName(file.name)}
                onDelete={() => onDelete({ index, file })}
                badge={{ bg: badgeColor, label: badgeLabel }}
                subItems={[fileSize]}
            />
        </Box>
    );
}

export default FileUploadItem;
