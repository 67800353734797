import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import type { FormObject, FetchQueryParams, APIError } from 'core';

export enum ServicesEnum {
    SELFCARE = 'selfcare',
    SECURITY = 'security',
    MEDICALIB = 'medicalib',
}

export const path = `/api/proxy/[service]/forms/schemas/`;

async function fetchForm({
    queryKey: [urn, formId, service],
}: FetchQueryParams) {
    try {
        const response = await axios.options(
            `${urn.replace('[service]', service)}${formId}`
        );
        return response.data.data as FormObject;
    } catch (err) {
        throw {
            code: err.response?.status,
        };
    }
}

export function useForm(
    formId: string,
    service: string = ServicesEnum.SELFCARE,
    enabled: boolean = true
) {
    return useQuery<FormObject, APIError>({
        queryKey: [path, formId, service],
        queryFn: fetchForm,
        enabled,
    });
}

export { path as formsPath };
