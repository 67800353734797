import {
    Badge,
    Button,
    FlexboxProps,
    HStack,
    ModalProps,
    Stack,
    Text,
    ThemeTypings,
} from '@chakra-ui/react';
import {
    CoreModal,
    CoreModalBody,
    CoreModalContent,
    CoreModalFooter,
    CoreModalHeader,
} from '..';
import React, { Dispatch, SetStateAction } from 'react';

type StepButtonProps = {
    text: string;
    onClick?: () => Promise<boolean> | Promise<void>;
    colorScheme: ThemeTypings['colorSchemes'];
    formId?: string;
};

type StepsModalProps = Omit<ModalProps, 'children'> & {
    title: string;
    withCloseButton?: boolean;
    components: StepComponent[];
    onStepChange: React.Dispatch<SetStateAction<number>>;
};

export function StepsModal({
    title,
    components,
    withCloseButton = true,
    onClose,
    isOpen,
    onStepChange,
    ...props
}: StepsModalProps) {
    const [currentStep, setCurrentStep] = React.useState<number>(0);

    React.useEffect(() => {
        onStepChange(currentStep);
    }, [currentStep]);

    const maxStep = components?.length;

    return (
        <CoreModal isOpen={isOpen} onClose={onClose} {...props}>
            <CoreModalContent>
                <CoreModalHeader withCloseButton={withCloseButton}>
                    {title}
                </CoreModalHeader>
                <CoreModalBody>
                    <StepsHeader currentStep={currentStep} maxStep={maxStep} />
                    <StepsBody {...components?.[currentStep]} />
                </CoreModalBody>
                <CoreModalFooter>
                    <StepsFooter
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        maxStep={maxStep}
                        onClose={onClose}
                        backButton={components?.[currentStep]?.backButton}
                        nextButton={components?.[currentStep]?.nextButton}
                        justifyContent={
                            components?.[currentStep]?.justifyContent
                        }
                    />
                </CoreModalFooter>
            </CoreModalContent>
        </CoreModal>
    );
}

type StepsHeaderProps = {
    currentStep: number;
    maxStep: number;
};

function StepsHeader({ currentStep, maxStep }: StepsHeaderProps) {
    return (
        <Badge
            bg={currentStep + 1 === maxStep ? 'primary.main' : 'grey.200'}
            borderRadius="full"
            py="1"
            px="3"
            mb="4">
            <Text
                fontSize="sm"
                color={currentStep + 1 === maxStep ? 'white' : 'black'}
                as="h5">
                {currentStep + 1}/{maxStep}
            </Text>
        </Badge>
    );
}

export type StepComponent = {
    title?: string;
    description?: string;
    component?: React.ReactElement;
    backButton?: StepButtonProps;
    nextButton?: StepButtonProps;
    justifyContent?: FlexboxProps['justifyContent'];
};

function StepsBody({ title, description, component }: StepComponent) {
    return (
        <Stack gap="4">
            <Stack gap="0" spacing="0">
                <Text fontWeight="bold" fontSize="lg">
                    {title}
                </Text>
                <Text color="gray.800" fontSize="sm">
                    {description}
                </Text>
            </Stack>
            {component}
        </Stack>
    );
}

type StepsFooterProps = {
    onClose: () => void;
    currentStep: number;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    maxStep: number;
    backButton: StepButtonProps;
    nextButton: StepButtonProps;
    justifyContent?: FlexboxProps['justifyContent'];
};

function StepsFooter({
    currentStep,
    setCurrentStep,
    backButton,
    nextButton,
    justifyContent,
    maxStep,
}: StepsFooterProps) {
    const [isLoading, setIsLoading] = React.useState(false);
    async function handleBackOnClick() {
        if (!backButton.onClick) return;

        const result = await backButton.onClick();
        if (result && currentStep >= 1) {
            setCurrentStep(currentStep - 1);
        }
    }

    async function handleNextOnClick() {
        if (!nextButton.onClick) return;
        setIsLoading(true);

        const result = await nextButton.onClick();
        if (result && currentStep < maxStep - 1) {
            setCurrentStep(currentStep + 1);
        }
        setIsLoading(false);
    }

    return (
        <HStack
            w="full"
            justifyContent={justifyContent ? justifyContent : 'space-between'}>
            {backButton && (
                <Button
                    colorScheme={backButton?.colorScheme}
                    variant="ghost"
                    onClick={handleBackOnClick}>
                    {backButton.text}
                </Button>
            )}
            {nextButton && (
                <Button
                    colorScheme={nextButton?.colorScheme}
                    onClick={handleNextOnClick}
                    isLoading={isLoading}>
                    {nextButton.text}
                </Button>
            )}
        </HStack>
    );
}
