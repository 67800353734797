import { defineMessages } from 'react-intl';

export const contractStatusInfos = defineMessages({
    portability: {
        id: 'contract.status.portability.description',
        defaultMessage:
            'Afin de maintenir la portabilité de vos droits, pensez à envoyer chaque mois votre justificatif via "Nouvelle demande".',
    },
    future: {
        id: 'contract.status.future.description',
        defaultMessage:
            'Votre contrat commence bientôt. Il prendra effet le {affiliationDate}.',
    },
    closed: {
        id: 'contract.status.closed.description',
        defaultMessage:
            'Votre contrat a pris fin le {deregistrationDate}{deregistrationReason, select, undefined {} null {} other { ({deregistrationReason})} }.',
    },
});
