import { useIntl } from 'react-intl';
import { Img, Box } from '@chakra-ui/react';
import { Site } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { defineMessage } from '@formatjs/intl';

const intlMessage = defineMessage({
    id: 'components.login.logo',
    defaultMessage: 'Logo principal',
    description: 'Logo principal du site',
});

type LoginLogoProps = {
    logo: Site.Config['client']['logo'];
};

export function LoginLogo({ logo }: LoginLogoProps) {
    const intl = useIntl();
    const { isMobile } = useWindowBreakpoints();

    return (
        <Box
            as="header"
            textAlign="center"
            mb={isMobile ? '4' : '0'}
            mt={isMobile ? '4' : '0'}>
            <Img
                alt={intl.formatMessage(intlMessage)}
                display="inline-block"
                htmlWidth={logo?.width}
                src={logo?.path}
                py={logo?.paddingVertical}
            />
        </Box>
    );
}
