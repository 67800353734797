import React from 'react';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
    Box,
} from '@chakra-ui/react';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ChevronBottomIcon } from 'design-system/icons';
import { LanguageContext } from 'core';

const localesLabels = {
    'fr-FR': 'FR',
    'en-GB': 'EN',
};

export default function LanguageSwitch() {
    const { isMobile } = useWindowBreakpoints();
    const { setLocale, showSwitch, locale } = React.useContext(LanguageContext);

    if (!showSwitch) return null;

    return (
        <Box
            position="absolute"
            top={isMobile ? '4' : '8'}
            right={isMobile ? '4' : '8'}
            zIndex={10}>
            <Menu matchWidth>
                <MenuButton
                    as={Button}
                    rightIcon={<ChevronBottomIcon />}
                    fontSize="sm"
                    height="auto"
                    py="2"
                    px="3">
                    {localesLabels[locale]}
                </MenuButton>
                <MenuList minW="0" fontSize="sm">
                    {Object.entries(localesLabels).map(([code, label]) => {
                        return (
                            <MenuItem
                                key={code}
                                onClick={() => setLocale(code)}>
                                {label}
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </Menu>
        </Box>
    );
}
