import { Box } from '@chakra-ui/react';
import { useLegendRowContext } from './LegendRow';

type LegendLabelProps = React.PropsWithChildren<{
    textColor?: string;
}>;

export function LegendLabel({ children, textColor }: LegendLabelProps) {
    const { color, selected } = useLegendRowContext();

    return (
        <Box
            textAlign="left"
            color={textColor ?? color}
            fontWeight={selected ? 'semibold' : 'normal'}
            wordBreak="break-word"
            w="100%"
            sx={{
                hyphens: 'auto',
            }}>
            {children}
        </Box>
    );
}
