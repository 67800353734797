import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
    getIntlPath,
    GetServerSidePropsContextWithAuth,
    Auth,
    getRedirectUrl,
} from 'core';
import routes from 'routes';
import { getPrimoAuthToken } from 'lib/primo/helpers';

function withPrimoAuth(handler: any) {
    return (context: GetServerSidePropsContextWithAuth) => {
        const token = getPrimoAuthToken(context.req);

        if (!token) {
            const destination = getIntlPath(routes, '/login', context.locale);

            return {
                redirect: {
                    destination: `${destination}?redirect=${encodeURIComponent(
                        JSON.stringify(
                            getRedirectUrl(context.resolvedUrl, context.query)
                        )
                    )}`,
                    permanent: false,
                },
            };
        }

        const decodedJwt = jwtDecode<JwtPayload & { payload: Auth }>(token);
        context.req.auth = { ...decodedJwt.payload, token };

        return handler(context);
    };
}

export { withPrimoAuth };
