import { coreErrors } from '../../../lib/shared/i18n';
import { formErrorMessages } from '../../forms/i18n/adherent';
import { defineMessage, defineMessages } from 'react-intl';

const beneficiariesDesignationMessages = defineMessages({
    specific: {
        id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.title',
        defaultMessage: 'Clause spécifique guidée',
    },
});

export const signatureMessages = {
    ...defineMessages({
        'signature-health-subscription': {
            id: 'workflow-signature.workflow-subscription.title',
            defaultMessage: 'Souscription',
        },
        signature_cotisation: {
            id: 'workflow-signature.workflow-contributions-rib.title',
            defaultMessage: 'Modification du RIB de cotisations',
        },
        signature_exemption: {
            id: 'workflow-signature.workflow-exemption.title',
            defaultMessage:
                "Demande de dispense d'affiliation au contrat de santé",
        },
        'signature-beneficiaries-designation.standard': {
            id: 'workflow-signature.workflow-standard-beneficiaries-designation.title',
            defaultMessage: 'Revenir à une clause standard',
        },
        beneficiariesDesignation: {
            id: 'page-title.beneficiaries-designation',
            defaultMessage: 'Choix des bénéficiaires en cas de décès',
        },
        documentPreviewAlert: {
            id: 'errors.workflow-signature.documents.alert',
            defaultMessage:
                "Merci de parcourir l'ensemble des pages du document avant de valider.",
        },
        'legal-documents': {
            id: 'workflow-signature.workflow-legal-documents.title',
            defaultMessage: 'Réception d’un document',
        },
        'verification-code-title': {
            id:"workflow-signature.signature.verification-code-block.title",
            defaultMessage:"Code de vérification"
        },
        'verification-code-description': {
            id:"workflow-signature.signature.verification-code-block.description",
            defaultMessage:"<p>Entrez le code reçu par sms {phoneNumber, select, null {} other {au <b>{phoneNumber}</b>}} pour valider légalement votre signature.</p>"
        },
        'success-message-new-verification-code': {
            id: 'success-message.new-verification-code',
            defaultMessage: 'Un nouveau code de vérification vous a été envoyé.',
        },
        'errors.workflow-signature.signature.400': {
            id: 'errors.workflow-signature.signature.400',
            defaultMessage: 'Code erroné ou expiré',
        },
        sign_and_send: {
            id: 'buttons.sign-and-send',
            defaultMessage: 'Signer et envoyer',
        }
    }),
    'signature-beneficiaries-designation.specific-guided':
        beneficiariesDesignationMessages.specific,
    'signature-beneficiaries-designation.specific-guided.full-capital':
        beneficiariesDesignationMessages.specific,
    'signature-beneficiaries-designation.specific-guided.divided-capital':
        beneficiariesDesignationMessages.specific,
    'error-title': coreErrors.serviceUnavailableDescription
};

export const signatureSubtitles = defineMessages({
    'exemption-reasons': {
        id: 'workflow-signature.workflow-exemption.reasons.title',
        defaultMessage: 'Motif de la demande',
        color: 'secondary.main',
    },
    'capital-repartition': {
        id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.distribution-capital.title',
        defaultMessage: 'Répartition du capital',
        color: 'secondary.100',
    },
    summary: {
        id: 'workflow-signature.workflow-subscription.overview.title',
        defaultMessage: 'Récapitulatif',
        color: 'secondary.200',
    },
    'add-beneficiaries': {
        id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiaries.title',
        defaultMessage: 'Ajout des bénéficiaires',
        color: 'secondary.200',
    },
    'document-preview': {
        id: 'workflow-signature.documents.title',
        defaultMessage: 'Document à signer',
        color: 'secondary.300',
    },
    'document-preview-reception': {
        id: 'workflow-signature.workflow-legal-documents.consent.title',
        defaultMessage: 'Document à réceptionner',
        color: 'secondary.300',
    },
    'send-verification-code': {
        id: 'workflow-signature.signature.verification-code-block.title',
        defaultMessage: 'Code de vérification',
        color: 'secondary.400',
    },
    'verification-code': {
        id: 'workflow-signature.signature.title',
        defaultMessage: 'Signature électronique',
        color: 'secondary.main',
    },
    confirmation: {
        id: 'workflow-signature.confirmation.title',
        defaultMessage: 'Demande envoyée !',
        color: 'secondary.main',
    },
    confirmationLegalDocument: {
        id: 'workflow-signature.workflow-legal-documents.confirmation.title',
        defaultMessage: 'Émargement terminé',
    },
});

export const PEWelcomeSteps = defineMessages({
    'identity-form': {
        id: 'workflow-signature.identity-form.title',
        defaultMessage: 'Informations personnelles',
        color: 'tertiary.100',
    },
    'beneficiary-addition': {
        id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiaries.title',
        defaultMessage: 'Ajout des bénéficiaires',
        color: 'tertiary.200',
    },
    'document-preview': {
        id: 'workflow-signature.documents.title',
        defaultMessage: 'Document à signer',
        color: 'tertiary.300',
    },
    'identity-check': {
        id: 'workflow-signature.identity-check.title',
        defaultMessage: "Vérification de la pièce d'identité",
        color: 'tertiary.400',
    },
    'send-verification-code': {
        id: 'workflow-signature.signature.verification-code-block.title',
        defaultMessage: 'Code de vérification',
        color: 'tertiary.500',
    },
    'verification-code': {
        id: 'workflow-signature.signature.title',
        defaultMessage: 'Signature électronique',
        color: 'tertiary.main',
    },
    confirmation: {
        id: 'workflow-signature.confirmation.title',
        defaultMessage: 'Demande envoyée !',
        color: 'primary.main',
    },
});

const defaultSignatureErrors = {
    '404-500': defineMessage({
        id: 'errors.unavailable-service.text',
        defaultMessage:
            'Ce service est momentanément indisponible. Veuillez réessayer plus tard.',
    }),
    '403-procedure': defineMessage({
        id: 'errors.workflow-signature.403-procedure',
        defaultMessage:
            'Une erreur est survenue. Veuillez renouveler votre demande.',
    }),
};

export const signatureErrorMessages = {
    default: defineMessage({
        id: 'errors.default',
        defaultMessage: 'Une erreur est survenue.',
    }),
    resume: {
        ...defineMessages({
            403: {
                id: 'errors.workflow-signature.workflow-subscription.overview.403-invalid-provider-data',
                defaultMessage:
                    'Nous ne parvenons pas à afficher vos coordonnées bancaires de prélèvement nécessaires dans le cadre de cette souscription. Veuillez vous rapprocher de votre centre de gestion au numéro indiqué sur votre carte de tiers payant.',
            },
        }),
        500: defaultSignatureErrors['404-500'],
    },
    resume_post: {
        403: defaultSignatureErrors['403-procedure'],
        404: defaultSignatureErrors['404-500'],
        500: defaultSignatureErrors['404-500'],
    },
    'change-rib': {
        ...defineMessages({
            403: {
                id: 'errors.workflow-signature.workflow-subscription.contributions-rib-form.403-invalid-provider-data',
                defaultMessage:
                    "La mise à jour de vos coordonnées bancaires n'est pas possible. Veuillez vous rapprocher de votre centre de gestion au numéro indiqué sur votre carte de tiers payant.",
            },
        }),
        500: defaultSignatureErrors['404-500'],
    },
    'document-viewer': {
        ...defineMessages({
            400: {
                id: 'errors.workflow-signature.documents.400',
                defaultMessage:
                    'Nous ne parvenons pas à afficher les documents à signer.',
            },
        }),
        403: defaultSignatureErrors['403-procedure'],
        404: defaultSignatureErrors['404-500'],
        500: defaultSignatureErrors['404-500'],
    },
    'send-verification-code': {
        404: defaultSignatureErrors['404-500'],
        500: defaultSignatureErrors['404-500'],
    },
    'verification-code': {
        ...defineMessages({
            409: {
                id: 'errors.workflow-signature.signature.409',
                defaultMessage:
                    "Nous venons d'essayer de mettre à jour vos nouvelles informations bancaires. Il s'avère que l'IBAN renseigné est identique à l'actuel.",
            },
            412: {
                id: 'errors.workflow-signature.signature.412',
                defaultMessage:
                    "Nous venons d'essayer de mettre à jour vos nouvelles informations bancaires. Il s'avère que l'IBAN renseigné est invalide.",
            },
        }),
        403: defaultSignatureErrors['403-procedure'],
        404: defaultSignatureErrors['404-500'],
        500: defaultSignatureErrors['404-500'],
    },
    'divided-capital-beneficiaries': {
        403: defaultSignatureErrors['403-procedure'],
        404: defaultSignatureErrors['404-500'],
        500: defaultSignatureErrors['404-500'],
    },
    'full-capital-beneficiaries': {
        403: defaultSignatureErrors['403-procedure'],
        404: defaultSignatureErrors['404-500'],
        500: defaultSignatureErrors['404-500'],
    },
    'capital-allocation-type-selection': {
        403: defineMessage({
            id: 'errors.workflow-signature.workflow-beneficiaries-designation.distribution-capital.403',
            defaultMessage:
                'Veuillez vérifier vos informations de contact (numéro de téléphone, adresse e-mail) en cliquant sur la rubrique "Profil". Une fois vos informations complétées, merci de renouveler votre demande.',
        }),
        404: defaultSignatureErrors['404-500'],
        500: defaultSignatureErrors['404-500'],
    },
    'add-beneficiary-form': {
        403: defineMessage({
            id: 'errors.workflow-signature.workflow-beneficiaries-designation.add-beneficiary.403',
            defaultMessage:
                "Le bénéficiaire n'a pas pu être ajouté car il existe déjà.",
        }),
        404: defaultSignatureErrors['404-500'],
        500: defaultSignatureErrors['404-500'],
    },
    'exemption-reasons': {
        400: defaultSignatureErrors['404-500'],
        404: defaultSignatureErrors['404-500'],
        500: defaultSignatureErrors['404-500'],
    },
};

export const signatureErrorCodeMessages = {
    ...defineMessages({
        'aaa6d5ca-e3f1-4526-9166-22728038636b': {
            id: 'errors.workflow-signature.verification-code.403-no-phone-number',
            defaultMessage:
                "Aucun numéro de téléphone n'est associé à votre compte. Veuillez en renseigner un en cliquant sur la rubrique “Profil” puis “Téléphones”. Une fois un numéro de téléphone ajouté, merci de renouveler votre demande.",
        },
        'a98cd0bf-10ad-4245-b550-887c81a41608': {
            id: 'errors.workflow-signature.identity-check.unreadable-document',
            defaultMessage:
                "Votre document n'est pas assez lisible. Veuillez réessayer avec une nouvelle copie ou avec une autre pièce d'identité.",
        },
        'd8e04169-002a-4288-bb28-24aa6158d7b4': {
            id: 'errors.workflow-signature.file-too-large',
            defaultMessage:
                "Un ou plusieurs fichiers n'ont pas pu être ajouté(s) car ils sont trop lourds.",
        },
        '272b3332-f23f-46ca-8c62-65fa213c236d': {
            id: 'errors.workflow-signature.identity-check.black-and-white-document',
            defaultMessage:
                'Votre document est en noir et blanc. Veuillez réessayer avec une copie couleur.',
        },
        'aad7b552-942b-4972-9f5d-8be08b72982a': {
            id: 'errors.workflow-signature.identity-check.invalid-mrz',
            defaultMessage:
                "La bande MRZ est invalide (Zone de Lecture Optique se trouvant au bas de votre document). Veuillez réessayer avec une nouvelle copie ou avec une autre pièce d'identité",
        },
        '13843c8b-3c61-4a3a-ad54-cf26dfa5bb3b': {
            id: 'errors.workflow-signature.identity-check.expired',
            defaultMessage:
                "Votre pièce d'identité est expirée. Veuillez essayer à nouveau avec une pièce d'identité en cours de validité.",
        },
        'd1c52b62-81d1-4d39-8afc-0b323a1bf7c1': {
            id: 'errors.workflow-signature.identity-check.incorrect-lastname',
            defaultMessage:
                "Le nom saisi à la première étape ne correspond pas à celui présent sur la pièce d'identité",
        },
        '696d060a-f37b-46be-b1e9-d94bf35bb8f0': {
            id: 'errors.workflow-signature.identity-check.incorrect-firstname',
            defaultMessage:
                "Le prénom saisi à la première étape ne correspond pas à celui présent sur la pièce d'identité",
        },
        '8030bd19-b937-4866-b700-4fb3075c595f': {
            id: 'errors.workflow-signature.identity-check.not-supported-resident-permit',
            defaultMessage:
                "La carte de séjour n'est pas un document accepté pour la vérification d'identité",
        },
        'ae327ca1-c61f-44dc-99af-650d70060d6c': {
            id: 'errors.workflow-signature.identity-check.invalid-phonenumber-or-email',
            defaultMessage:
                "Le numéro de téléphone et/ou l'adresse email saisis ne sont pas valides. Veuillez vérifier ces informations à l'étape de vos informations personnelles.",
        },
    }),
    'e4465b2d-3c0d-437c-91da-4540faa85590':
        defaultSignatureErrors['403-procedure'],
    '21d68c0e-a462-11ec-b909-0242ac120002':
        defaultSignatureErrors['403-procedure'],
    '16f81c5b-2ac4-4a16-a4d8-89dd6cd69927':
        formErrorMessages['744f00bc-4389-4c74-92de-9a43cde55534'],
};

export const signatureWelcomeMessages = {
    'signature-health-subscription': defineMessages({
        title: {
            id: 'workflow-signature.workflow-subscription.welcome.title',
            defaultMessage: 'Bienvenue dans le parcours de souscription',
        },
        description: {
            id: 'workflow-signature.workflow-subscription.welcome.description',
            defaultMessage:
                "Toute demande de souscription fera l'objet d'une vérification par le centre de gestion et ne sera effective qu'après validation.",
        },
        stepTitle: {
            id: 'workflow-signature.workflow-subscription.welcome.steps',
            defaultMessage: 'Les étapes de la souscription',
        },
        amountDescription: {
            id: 'workflow-signature.workflow-subscription.amount-description',
            defaultMessage:
                '<l>Le tarif affiché est celui en vigueur à ce jour.</l><l>Ce montant peut évoluer en fonction de la période de souscription et des accords passés avec votre entreprise.</l>',
        },
    }),
    'signature-beneficiaries-designation.standard': defineMessages({
        title: {
            id: 'workflow-signature.workflow-standard-beneficiaries-designation.welcome.title',
            defaultMessage:
                'Bienvenue dans le parcours pour revenir à une clause standard du contrat',
        },
        description: {
            id: 'workflow-signature.workflow-standard-beneficiaries-designation.welcome.description',
            defaultMessage:
                "Référez-vous à votre Notice d'information pour connaître le détail de votre clause standard.",
        },
        stepTitle: {
            id: 'workflow-signature.workflow-standard-beneficiaries-designation.welcome.steps',
            defaultMessage:
                'Les étapes pour revenir à une clause standard du contrat',
        },
    }),
    'signature-beneficiaries-designation.specific-guided': defineMessages({
        title: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.welcome.title',
            defaultMessage:
                'Bienvenue dans le parcours de clause spécifique guidée',
        },
        description: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.welcome.description',
            defaultMessage:
                'Nous vous proposons un outil de saisie guidée de vos bénéficiaires.',
        },
        stepTitle: {
            id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.welcome.steps',
            defaultMessage: 'Les étapes de la saisie guidée',
        },
    }),
};

export const signatureConfirmationMessages = defineMessages({
    downloadAdvice: {
        id: 'workflow-signature.confirmation.signed-documents-block.description',
        defaultMessage:
            'Nous vous conseillons de télécharger {nbDocuments, plural, =0 {ce document et de le} one {ce document et de le} other {ces documents et de les}} conserver en lieu sûr.',
    },
    myDocument: {
        id: 'workflow-signature.workflow-legal-documents.confirmation.document-title',
        defaultMessage: 'Mon document',
    },
});

export const signatureFormMessages = {
    'signature-beneficiaries-designation.standard': defineMessages({
        title: {
            id: 'workflow-signature-beneficiaries-designation.standard.title',
            defaultMessage: 'Clause standard du contrat',
        },
        subtitle: {
            id: 'workflow-signature-beneficiaries-designation.standard.subtitle',
            defaultMessage: 'Informations personnelles',
        },
        description: {
            id: 'workflow-signature-beneficiaries-designation.standard.description',
            defaultMessage: 'Description',
        },
    }),
    'signature-beneficiaries-designation.specific-guided.full-capital':
        defineMessages({
            title: {
                id: 'workflow-signature-beneficiaries-designation.specific-guided.full-capital.title',
                defaultMessage: 'Clause spécifique guidée',
            },
            subtitle: {
                id: 'workflow-signature-beneficiaries-designation.specific-guided.full-capital.subtitle',
                defaultMessage: 'Informations personnelles',
            },
            description: {
                id: 'workflow-signature-beneficiaries-designation.specific-guided.full-capital.description',
                defaultMessage: 'Description',
            },
        }),
    'signature-beneficiaries-designation.specific-guided.divided-capital':
        defineMessages({
            title: {
                id: 'workflow-signature-beneficiaries-designation.specific-guided.divided-capital.title',
                defaultMessage: 'Clause spécifique guidée',
            },
            subtitle: {
                id: 'workflow-signature-beneficiaries-designation.specific-guided.divided-capital.subtitle',
                defaultMessage: 'Informations personnelles',
            },
            description: {
                id: 'workflow-signature-beneficiaries-designation.specific-guided.divided-capital.description',
                defaultMessage: 'Description',
            },
        }),
};
