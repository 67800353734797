function isNative() {
    return !!window.localStorage.getItem('nativeOS');
}

function isNativeIOS() {
    return window.localStorage.getItem('nativeOS') == 'ios';
}

function isNativeAndroid() {
    return window.localStorage.getItem('nativeOS') == 'android';
}

export { isNative, isNativeIOS, isNativeAndroid };
