import React from 'react';

type DesignSystemContextType =
    | {
          LinkComponent: React.ElementType;
      }
    | null
    | undefined;

type InitialsProps = {
    children: React.ReactNode;
    LinkComponent: React.ElementType;
};

const DesignSystemContext = React.createContext<DesignSystemContextType | null>(
    null
);

function useDesignSystemContext() {
    const context = React.useContext(DesignSystemContext);
    if (!context) {
        throw new Error(
            `useDesignSystemContext must be used within a <DesignSystemProvider>`
        );
    }
    return context;
}

function DesignSystemProvider({ children, LinkComponent }: InitialsProps) {
    return (
        <DesignSystemContext.Provider value={{ LinkComponent }}>
            {children}
        </DesignSystemContext.Provider>
    );
}

export type { DesignSystemContextType };
export { DesignSystemContext, DesignSystemProvider, useDesignSystemContext };
