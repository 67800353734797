import { Icon, IconProps } from '@chakra-ui/react';

export default function HeartFillIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M12 21c5-4 10-8 10-13a5 5 0 0 0-5-5c-1.81 0-3.827.963-5 2.404C10.827 3.963 8.81 3 7 3a5 5 0 0 0-5 5c0 5 5 9 10 13z"
                fill="currentColor"
            />
        </Icon>
    );
}
