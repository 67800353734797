import React from 'react';
import { contextError } from 'core/utils';

type PermissionsContextType<T> =
    | {
          permissions?: T;
          setPermissions: (permissions: T) => void;
      }
    | null
    | undefined;

type InitialsProps = {
    children: React.ReactNode;
};

const PermissionsContext = React.createContext(null);

function usePermissionsContext<T>() {
    const context = React.useContext<T>(PermissionsContext);
    if (!context) {
        contextError('usePermissionsContext', 'PermissionsProvider');
    }
    return context;
}

function PermissionsProvider<T>({ children }: InitialsProps) {
    const [permissions, setPermissions] = React.useState<T>([] as T);

    return (
        <PermissionsContext.Provider value={{ permissions, setPermissions }}>
            {children}
        </PermissionsContext.Provider>
    );
}

export type { PermissionsContextType };
export { PermissionsContext, PermissionsProvider, usePermissionsContext };
