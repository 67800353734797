import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { FetchQueryParams, FormObject } from 'core';

const formTypes = {
    'employee-exemption': 'phone',
    selfcare: 'phone',
    'employee-beneficiary-designation': 'identity',
};

const path = `/api/proxy/[service]/signature/[procedureId]/[formType]`;

export async function fetchSignaturePhoneForm({
    queryKey: [urn, procedureId, service],
}: FetchQueryParams) {
    try {
        const response = await axios.options(
            urn
                .replace('[service]', service)
                .replace('[procedureId]', procedureId)
                .replace('[formType]', formTypes[service])
        );
        return {
            form: response.data.data as FormObject,
            statusCode: response.status,
        };
    } catch (error) {
        return {
            form: undefined,
            statusCode: error?.response?.status,
            code: error?.response?.data?.errors?.[0].code,
            redirectFormId: error?.response?.data?.meta?.redirect?.id,
        };
    }
}

export function useSignaturePhoneForm(
    procedureId: string,
    service: string,
    type: 'phone' | 'identity' = 'phone'
) {
    return useQuery<
        {
            form?: FormObject;
            statusCode: number;
        } & {
            code?: string;
            redirectFormId?: string;
        }
    >({
        queryKey: [path, procedureId, service, type],
        queryFn: fetchSignaturePhoneForm,
    });
}
