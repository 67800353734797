import { Icon, IconProps } from '@chakra-ui/react';

export default function PersonIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M17 9A5 5 0 1 1 7 9a5 5 0 0 1 10 0zm-2 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                d="M5 22c.552 0 .985-.452 1.09-.994.208-1.079.764-1.845 1.536-2.388C8.665 17.887 10.186 17.5 12 17.5c1.814 0 3.335.387 4.374 1.118.772.543 1.328 1.31 1.536 2.388.105.542.538.994 1.09.994s1.009-.45.935-.997c-.236-1.753-1.104-3.101-2.41-4.02C16.031 15.93 14.052 15.5 12 15.5c-2.052 0-4.03.43-5.525 1.483-1.306.919-2.174 2.267-2.41 4.02-.074.547.383.997.935.997z"
                fill="currentColor"
            />
        </Icon>
    );
}
