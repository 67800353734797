import { defineMessages } from 'react-intl';

export const dividedCapitalMessages = defineMessages({
    full: {
        id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiaries.distribution-info.full',
        defaultMessage: 'Vous avez réparti {allocatedCapital}% du capital.',
    },
    under: {
        id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiaries.distribution-info.under',
        defaultMessage:
            'Il vous reste {remainingCapital, select, undefined {} other {{remainingCapital}%}} du capital à répartir.',
    },
    over: {
        id: 'workflow-signature.workflow-specific-guided-beneficiaries-designation.adding-beneficiaries.distribution-info.over',
        defaultMessage: 'Vous avez réparti plus de 100% du capital.',
    },
});
