import { Flex } from '@chakra-ui/react';

export function LoadingOverlay({ isLoading }: { isLoading: boolean }) {
    if (!isLoading) return <></>;

    return (
        <Flex
            backgroundColor="rgba(0,0,0,0.3)"
            zIndex="10"
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="max(100%, 100vh)"
            alignItems="center"
            justifyContent="center"
        />
    );
}
