import React from 'react';
import { useRouter } from 'next/router';
import { Box } from '@chakra-ui/react';

export type LoadingProgressBarTypes = {
    height: string;
    bgColor: string;
};

export function LoadingProgressBar({
    height,
    bgColor,
}: LoadingProgressBarTypes) {
    const router = useRouter();
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        let timer: any;

        function start() {
            setProgress(1);
            increment();
        }

        function increment() {
            const timeout = Math.round(Math.random() * 300);

            setProgress((prog) => {
                const percent = Math.round(Math.random() * 10);
                const next = Math.min(prog + percent, 80);

                if (next < 80) {
                    timer = setTimeout(increment, timeout);
                    return next;
                }

                return 100;
            });
        }

        function complete() {
            clearTimeout(timer);
            setProgress(100);
        }

        router.events.on('routeChangeStart', start);
        router.events.on('routeChangeComplete', complete);
        router.events.on('routeChangeError', complete);

        return () => {
            router.events.off('routeChangeStart', start);
            router.events.off('routeChangeComplete', complete);
            router.events.off('routeChangeError', complete);
        };
    }, [router.events]);

    return (
        <Box
            height={`${height}px`}
            top={0}
            left={0}
            zIndex={100000}
            bgColor={bgColor}
            position="absolute"
            bottom={0}
            transition="all 0.1s ease-in, opacity 0.3s ease-in 0.2s;"
            opacity={progress > 0 && progress < 100 ? 1 : 0}
            width={`${progress}%`}
        />
    );
}
