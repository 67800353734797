import React, { useState } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import type { WidgetProps } from '../types';
import FileUploadButton from './FileUploadButton';
import FileUploadItem from './FileUploadItem';
import useFileUpload, { FileObject } from './useFileUpload';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { SystemProps } from '@chakra-ui/styled-system';
import {
    FileDownloadCard,
    FileFormat,
    FormGroup,
    coreSharedMessages,
    getFileType,
    useInputValidation,
} from 'core';
import { ToastError } from 'design-system/components';
import { useIntl } from 'react-intl';

type InputFileProps = Pick<WidgetProps, 'validations'> & {
    name: string;
    defaultValue?: string;
};

function InputFile({ validations, name }: InputFileProps) {
    const { registerValues } = useInputValidation(validations, name);

    return (
        <input
            multiple={validations.maxFiles > 1}
            type="hidden"
            {...registerValues}
        />
    );
}

type FileUploadProps = WidgetProps & {
    onFilesListChange?: (categoryId: string, files: FileObject[]) => void;
    categoryId?: string;
    availableFileSize?: number;
    labelFontSize?: SystemProps['fontSize'];
};

function FileUpload({
    validations,
    onFilesListChange,
    categoryId,
    links,
    availableFileSize = validations.totalMaxSize,
    labelFontSize = 'md',
    defaultValue,
    ...props
}: FileUploadProps) {
    const { maxFiles } = validations;
    const { trigger } = useFormContext();
    const { id, title, help, readonly, description } = props;
    const {
        toastMessage,
        files,
        addFile,
        removeFile,
        onDropAccepted,
        onDropRejected,
    } = useFileUpload(id, validations, categoryId, availableFileSize);
    const isMax = maxFiles ? maxFiles <= files.length : false;
    const isRequired = validations.required && files.length === 0;
    const defaultInputName = `${id}.file${categoryId ?? ''}1`;
    const [isDefaultValueShown, setIsDefaultValueShown] =
        useState(!!defaultValue);
    const { formatMessage } = useIntl();

    const { getRootProps, isDragActive } = useDropzone({
        onDropAccepted,
        onDropRejected,
    });

    const onRemoveDefaultValue = () => {
        setIsDefaultValueShown(false);
    };

    React.useEffect(() => {
        if (onFilesListChange) {
            onFilesListChange(categoryId, files);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId, files]);

    React.useEffect(() => {
        files?.map((_, fileIndex) => {
            trigger(`${id}.file${categoryId ?? ''}${fileIndex + 1}`);
        });
    }, [id, categoryId, files, trigger]);

    return (
        <FormGroup
            label={title}
            labelFontSize={labelFontSize}
            name={defaultInputName}
            isRequired={validations.required}
            isReadOnly={readonly}
            {...{ id, description, help, links }}>
            {isRequired && (
                <InputFile name={defaultInputName} validations={validations} />
            )}
            <SimpleGrid columns={isDefaultValueShown ? [1] : [2, 3, 4]} gap="2">
                {!isDefaultValueShown && (
                    <Box
                        {...getRootProps()}
                        tabIndex={undefined}
                        h="100%"
                        data-testid="file-upload-button">
                        <FileUploadButton
                            onClick={addFile}
                            isDragActive={isDragActive}
                            acceptedMimeTypes={validations.acceptedMimeTypes}
                            maxFileSize={validations.maxSize}
                            disabled={isMax}
                        />
                    </Box>
                )}

                {isDefaultValueShown && (
                    <FileDownloadCard
                        type={getFileType(defaultValue) as FileFormat}
                        name={defaultValue}
                        isDownloadable={false}
                        iconProps={{
                            name: 'crossIcon',
                            ariaLabel: formatMessage(coreSharedMessages.delete),
                            onClick: onRemoveDefaultValue,
                        }}
                    />
                )}

                {files.map((file, idx) => (
                    <React.Fragment key={`${file.name}.${file.size}`}>
                        <FileUploadItem
                            index={idx}
                            file={file}
                            onDelete={removeFile}
                        />

                        <InputFile
                            name={`${id}.file${categoryId ?? ''}${idx + 1}`}
                            validations={validations}
                        />
                    </React.Fragment>
                ))}
            </SimpleGrid>
            {toastMessage && (
                <ToastError
                    message={toastMessage.message}
                    errorValues={toastMessage.values}
                    id={id}
                />
            )}
        </FormGroup>
    );
}

export default FileUpload;
