import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { useWindowBreakpoints } from 'design-system/hooks';

type CalendarButtonProps = {
    children: string;
    onClick: () => void;
    disabled?: boolean;
    selected?: boolean;
    current?: boolean;
};

type CalendarRowProps = {
    children: React.ReactNode;
    col: number;
};

type CalendarLabelsProps = {
    children: React.ReactNode;
    col?: number;
};

type CalendarLabelProps = {
    children: React.ReactNode;
};

export const CalendarButton = React.memo(function CalendarButton({
    children,
    onClick,
    disabled = false,
    selected = false,
    current = false,
}: CalendarButtonProps) {
    const { isDesktop } = useWindowBreakpoints();

    return (
        <Button
            w="full"
            display="block"
            type="button"
            variant={selected ? 'solid' : current ? 'outline' : 'ghost'}
            colorScheme="primary"
            color={selected ? 'white' : 'black'}
            isDisabled={disabled}
            aria-selected={selected}
            aria-current={current}
            onClick={onClick}
            paddingInline="0"
            minWidth={isDesktop ? 10 : 8}
            height={isDesktop ? 10 : 8}>
            {children}
        </Button>
    );
});

export const CalendarRow = React.memo(function CalendarRow({
    children,
    col,
}: CalendarRowProps) {
    return (
        <Flex
            sx={{
                '& + &': {
                    mt: 2,
                },
                '> *': {
                    w: `calc(100% / ${col})`,
                },
            }}>
            {children}
        </Flex>
    );
});

export const CalendarLabels = React.memo(function CalendarLabels({
    children,
    col = 7,
}: CalendarLabelsProps) {
    return (
        <Flex
            sx={{
                '& + &': {
                    mt: 2,
                },
                '> *': {
                    w: `calc(100% / ${col})`,
                },
            }}>
            {children}
        </Flex>
    );
});

export const CalendarLabel = React.memo(function CalendarLabel({
    children,
}: CalendarLabelProps) {
    return (
        <Box
            textAlign="center"
            fontWeight="bold"
            fontSize="xs"
            color="texts.medium">
            {children}
        </Box>
    );
});
