import dayjs from 'dayjs';
import { useDate } from '../Date/context';
import { CalendarRow, CalendarButton } from '../Calendar';
import { YEARS_RANGE } from '../consts';
import { createRows, getDecade } from '../utils';
import { usePicker } from './context';

const YEAR_COLUMNS = 3;

export function PickerYear() {
    const { setPicker, options: pickerOptions } = usePicker();
    const { today, current, setCurrent, setSelected, selected, range } =
        useDate();
    const { year } = current;
    const decade = getDecade(year);
    const yearRows = createRows<number>(
        Array.from(Array(YEARS_RANGE)).map((_, idx) => decade + idx),
        4,
        YEAR_COLUMNS
    );

    function handleClick(value: number) {
        setCurrent({ type: 'SET_YEAR', value });
        setSelected({ type: 'SET_YEAR', value: { year: value } });

        if (pickerOptions.min !== 'year') {
            setPicker('month');
        }
    }

    return (
        <>
            {yearRows.map((row, idx) => (
                <CalendarRow key={idx} col={YEAR_COLUMNS}>
                    {row.map((value) => {
                        const formattedDate = dayjs(new Date(value, 0));
                        const isDisabled =
                            formattedDate.isBefore(range.min, 'year') ||
                            formattedDate.isAfter(range.max, 'year');

                        return (
                            <CalendarButton
                                key={value}
                                onClick={() => handleClick(value)}
                                disabled={isDisabled}
                                current={today.year === value}
                                selected={
                                    !isDisabled && value === selected.year
                                }>
                                {value.toString()}
                            </CalendarButton>
                        );
                    })}
                </CalendarRow>
            ))}
        </>
    );
}
