import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import Alert from 'design-system/components/Alert';

type TeleconsultationInfoMessageCardProps = {
    message: MessageDescriptor;
};

export function TeleconsultationInfoMessageCardV2({
    message,
}: TeleconsultationInfoMessageCardProps) {
    const { formatMessage } = useIntl();
    return (
        <Alert
            color="info"
            icon="InfoIcon"
            description={formatMessage(message)}
        />
    );
}
