import { Icon, IconProps } from '@chakra-ui/react';

export default function TaxiIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M17.5 15.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0-1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM8 14a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M9.65 1.5c-.606 0-1.02.082-1.322.342-.295.254-.363.608-.408.845l-.004.018-.234 1.176a3.407 3.407 0 0 0-2.046.7c-.584.442-1.044 1.083-1.136 1.852l-.44 3.698C2.859 10.676 2 11.922 2 13.405v4.619c0 .126.048.247.133.336.085.09.201.14.322.14H3v2.571c0 .811.642 1.429 1.364 1.429h2.272C7.358 22.5 8 21.882 8 21.071V18.5h8v2.571c0 .811.642 1.429 1.364 1.429h2.272c.722 0 1.364-.618 1.364-1.429V18.5h.546c.12 0 .236-.05.32-.14a.488.488 0 0 0 .134-.336v-4.62c0-1.482-.859-2.728-2.06-3.273l-.44-3.698c-.092-.77-.552-1.404-1.136-1.845a3.443 3.443 0 0 0-2.046-.707l-.234-1.176-.007-.033c-.044-.232-.11-.578-.398-.83-.303-.264-.715-.342-1.328-.342H9.649zm1.387 1.205h1.926c.521 0 .71.095.746.127.036.032.054.08.1.312l.191.737h-4l.192-.737c.043-.226.065-.277.106-.312.041-.035.227-.127.739-.127zM8.67 5.5h6.66c.524 0 1.084.205 1.512.528.428.323.707.747.76 1.19L18 9.53a3.196 3.196 0 0 0-.398-.03H6.398c-.136 0-.266.013-.398.03l.398-2.311c.053-.445.332-.874.76-1.198.428-.325.99-.522 1.512-.521zm-2.17 6h11c1.362 0 2.5 1.14 2.5 2.619V16.5H4v-2.381c0-1.48 1.138-2.619 2.5-2.619zm-1.5 7h1v1.571a.46.46 0 0 1-.455.477h-.09A.46.46 0 0 1 5 20.07V18.5zm13 0h1v1.571a.46.46 0 0 1-.454.477h-.091A.46.46 0 0 1 18 20.07V18.5z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
