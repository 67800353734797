import React, { ReactElement } from 'react';
import { defineMessage, FormattedMessage, MessageDescriptor } from 'react-intl';
import { Container, Stack, Text } from '@chakra-ui/react';
import {
    coreEmptyStates,
    coreErrors,
    coreSharedMessages,
    defaultLayoutBoundaryErrorMessages,
    EmptyIllustration,
    LayoutBoundaryType,
    LoadingCard,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ToastError } from 'design-system/components';
import { AlertError, Error } from 'core/components/Layouts/ErrorComponent';

const MESSAGES = {
    loading: coreSharedMessages.loading,
    error: defineMessage({
        id: 'components.layouts.error',
        defaultMessage: 'Une erreur est survenue.',
    }),
    empty: coreEmptyStates.default,
    maintenanceTitle: coreErrors.maintenanceTitle,
    maintenanceDescription: coreErrors.maintenanceDescription,
};

type ComponentProps = {
    message?: MessageDescriptor;
    messageComponent?: ReactElement;
};

export function Loading({ message = MESSAGES.loading }: ComponentProps) {
    return (
        <LoadingCard>
            <FormattedMessage {...message} />
        </LoadingCard>
    );
}

function Empty({ message = MESSAGES.empty, messageComponent }: ComponentProps) {
    const { isMobile } = useWindowBreakpoints();
    return (
        <Stack
            rounded="3xl"
            bg="primary.50"
            p={isMobile ? '4' : '9'}
            width="full"
            mt={isMobile ? '4' : '8'}>
            <EmptyIllustration p="inherit">
                {messageComponent ? (
                    messageComponent
                ) : (
                    <Text
                        fontWeight="semibold"
                        fontSize="md"
                        color="primary.600"
                        width={isMobile ? '100%' : ''}>
                        <FormattedMessage {...message} />
                    </Text>
                )}
            </EmptyIllustration>
        </Stack>
    );
}

export type LayoutBoundaryProps = LayoutBoundaryType &
    React.PropsWithChildren<{
        EmptyComponent?: React.ComponentType<ComponentProps>;
    }> & {
        messageComponent?: ReactElement;
        messageValues?: Record<string, any>;
        noPadding?: boolean;
        showButtonReloadPage?: boolean;
        isSmallDisplay?: boolean;
        containerRef?: React.RefObject<HTMLDivElement>;
    };

export default function LayoutBoundary({
    children,
    messageComponent,
    status = 'success',
    toastError,
    alertError,
    errorValues,
    ErrorComponent = Error,
    LoadingComponent = Loading,
    EmptyComponent = Empty,
    messages,
    errorCode,
    errorButtons,
    noPadding,
    showButtonReloadPage,
    isSmallDisplay = false,
    containerRef,
    ...boxProps
}: LayoutBoundaryProps) {
    return (
        <Container
            ref={containerRef}
            as="main"
            py={noPadding ? '0' : '10'}
            {...boxProps}>
            {alertError && (
                <AlertError
                    message={messages?.alertError}
                    errorValues={errorValues}
                />
            )}
            {status === 'pending' && (
                <LoadingComponent message={messages?.loading} />
            )}
            {status === 'error' && (
                <ErrorComponent
                    message={
                        messages?.error ||
                        defaultLayoutBoundaryErrorMessages[errorCode]?.error
                    }
                    description={
                        messages?.errorDescription ||
                        defaultLayoutBoundaryErrorMessages[errorCode]
                            ?.errorDescription
                    }
                    {...{
                        errorCode,
                        errorButtons,
                        showButtonReloadPage,
                        isSmallDisplay,
                    }}
                />
            )}
            {status === 'empty' && (
                <>
                    {children}
                    <EmptyComponent
                        message={messages?.empty}
                        messageComponent={messageComponent}
                    />
                </>
            )}
            {status === 'maintenance' && (
                <ErrorComponent
                    message={
                        messages?.maintenanceTitle || MESSAGES.maintenanceTitle
                    }
                    description={
                        messages?.maintenanceDescription ||
                        MESSAGES.maintenanceDescription
                    }
                />
            )}
            {status === 'success' && children}
            {toastError && (
                <ToastError
                    message={messages?.toastError}
                    title={messages?.toastTitleError}
                    errorValues={errorValues}
                />
            )}
        </Container>
    );
}
