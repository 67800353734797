import { Icon, IconProps } from '@chakra-ui/react';

export default function ContractIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M6.84591 21C6.05538 21 5.38343 20.7191 4.83007 20.1574C4.27669 19.5957 4 18.9136 4 18.1112V17.2186C4 16.6702 4.19096 16.2021 4.57287 15.8145C4.95478 15.4268 5.41588 15.233 5.95615 15.233H6.61733V5.00673C6.61733 4.45128 6.81001 3.97796 7.19537 3.58678C7.58074 3.19559 8.04703 3 8.59424 3H18.0231C18.5703 3 19.0366 3.19559 19.4219 3.58678C19.8073 3.97796 20 4.45128 20 5.00673V18.1112C20 18.9136 19.7233 19.5957 19.1699 20.1574C18.6166 20.7191 17.9446 21 17.1541 21L6.84591 21ZM17.1541 18.9933C17.4003 18.9933 17.6067 18.9087 17.7733 18.7397C17.9398 18.5706 18.0231 18.3611 18.0231 18.1112V5.00673H8.59424V15.233H14.3082C14.8554 15.233 15.3217 15.4286 15.7071 15.8197C16.0924 16.2109 16.2851 16.6842 16.2851 17.2397V18.1112C16.2851 18.3611 16.3684 18.5706 16.535 18.7397C16.7015 18.9087 16.9079 18.9933 17.1541 18.9933ZM10.3997 9.34325C10.1362 9.34325 9.91423 9.25168 9.73381 9.06855C9.55342 8.88543 9.46322 8.66012 9.46322 8.39262C9.46322 8.12512 9.55342 7.89981 9.73381 7.71669C9.91423 7.53356 10.1362 7.44199 10.3997 7.44199H16.2176C16.4811 7.44199 16.7031 7.53356 16.8835 7.71669C17.0639 7.89981 17.1541 8.12512 17.1541 8.39262C17.1541 8.66012 17.0639 8.88543 16.8835 9.06855C16.7031 9.25168 16.4811 9.34325 16.2176 9.34325H10.3997ZM10.3997 12.1266C10.1362 12.1266 9.91423 12.035 9.73381 11.8519C9.55342 11.6687 9.46322 11.4434 9.46322 11.1759C9.46322 10.9084 9.55342 10.6831 9.73381 10.5C9.91423 10.3169 10.1362 10.2253 10.3997 10.2253H16.2176C16.4811 10.2253 16.7031 10.3169 16.8835 10.5C17.0639 10.6831 17.1541 10.9084 17.1541 11.1759C17.1541 11.4434 17.0639 11.6687 16.8835 11.8519C16.7031 12.035 16.4811 12.1266 16.2176 12.1266H10.3997ZM6.84591 18.9933H14.3082V17.2397H5.97694V18.1112C5.97694 18.3611 6.06021 18.5706 6.22677 18.7397C6.39332 18.9087 6.5997 18.9933 6.84591 18.9933ZM6.84591 18.9933H5.97694H14.3082H6.84591Z"
                fill="currentColor"
            />
        </Icon>
    );
}
