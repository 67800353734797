const date: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
};

const time: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
};

const datetime: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
};

const dateWithWeekDays: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
};

export const formats = {
    date,
    time,
    datetime,
    dateWithWeekDays,
};
