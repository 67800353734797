import { Icon, IconProps } from '@chakra-ui/react';

export default function PeopleIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M7.163 12.47c2.007 0 3.626-1.656 3.626-3.686 0-2.031-1.619-3.686-3.626-3.686-2.007 0-3.625 1.655-3.625 3.686 0 2.03 1.618 3.685 3.625 3.685zm1.695-3.686c0 .96-.764 1.731-1.695 1.731s-1.695-.77-1.695-1.731c0-.962.764-1.732 1.695-1.732s1.695.77 1.695 1.732z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                d="M3.525 17.995a2.183 2.183 0 0 1 .904-1.44c.622-.445 1.565-.7 2.734-.7 1.17 0 2.112.255 2.734.7.457.328.782.784.904 1.44.087.468.472.907 1.018.907a.937.937 0 0 0 .18-.017c.06.011.12.017.182.017.546 0 .931-.439 1.018-.907a2.183 2.183 0 0 1 .903-1.44c.623-.445 1.565-.7 2.735-.7s2.112.255 2.734.7c.456.328.782.784.904 1.44.086.468.472.907 1.017.907.52 0 .995-.431.921-1.002-.165-1.275-.788-2.266-1.727-2.94-1.062-.76-2.448-1.06-3.85-1.06-1.4 0-2.786.3-3.849 1.06-.37.266-.69.58-.954.943a1.825 1.825 0 0 0-.254-.34c-.169-.179-.377-.326-.62-.498l-.146-.104c-1.063-.762-2.448-1.06-3.85-1.06-1.401 0-2.787.298-3.85 1.06-.938.673-1.56 1.664-1.726 2.94-.074.57.4 1.001.92 1.001.546 0 .932-.439 1.018-.907z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M16.837 12.47c2.007 0 3.625-1.656 3.625-3.686 0-2.031-1.618-3.686-3.625-3.686-2.008 0-3.626 1.655-3.626 3.686 0 2.03 1.618 3.685 3.626 3.685zm1.695-3.686c0 .96-.764 1.731-1.695 1.731s-1.695-.77-1.695-1.731c0-.962.764-1.732 1.695-1.732s1.695.77 1.695 1.732z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
