import { validationFormsMessages, FormatMessageFn, Validations } from 'core';

function validateRequired(
    required: Validations['required'],
    formatMessage: FormatMessageFn
) {
    if (!required) return undefined;
    return {
        value: required,
        message: formatMessage(validationFormsMessages.required),
    };
}

export { validateRequired };
