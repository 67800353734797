import React from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
    APIError,
    CredentialsConfig,
    FetchQueryParams,
    UtilsContext,
} from 'core';

const path = `/api/proxy/security/public/config/[clientId]`;

async function fetchCredentialsConfig({
    queryKey: [urn, client_id, isLoggedIn],
}: FetchQueryParams) {
    try {
        if (isLoggedIn) return null;

        const { data } = await axios.get(urn.replace('[clientId]', client_id));

        return data?.data;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useCredentialsConfig(isLoggedIn: boolean = false) {
    const {
        siteConfig: { client_id, features },
    } = React.useContext(UtilsContext);

    return useQuery<CredentialsConfig, APIError>({
        queryKey: [path, client_id, isLoggedIn],
        queryFn: features.oauth2login ? null : fetchCredentialsConfig,
    });
}
