import { Icon, IconProps } from '@chakra-ui/react';

export default function CodeIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="m8.264 10.441-.111 1.032 1.043-.296.108.864-.92.061.607.815-.762.404-.419-.829-.36.823-.797-.398.603-.815-.917-.073.132-.852 1.02.296-.115-1.032h.888zM12.445 10.441l-.111 1.032 1.043-.296.108.864-.92.061.607.815-.762.404-.419-.829-.36.823-.797-.398.604-.815-.918-.073.132-.852 1.02.296-.114-1.032h.887zM16.515 11.473l.112-1.032h-.888l.114 1.032-1.02-.296-.131.852.917.073-.604.815.797.398.36-.823.42.83.761-.405-.606-.815.92-.061-.109-.864-1.043.296z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M2 7a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7zm2 0h16v9H4V7z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
