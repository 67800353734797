export const getMagicScrollbarStyle = (
    width = '6',
    color = 'primary.main',
    bg = 'transparent'
) => {
    return {
        '::-webkit-scrollbar': {
            width,
            height: '6px',
            borderRadius: 'lg',
        },
        '::-webkit-scrollbar-thumb': {
            background: color,
            borderRadius: 'lg',
            height: '12',
            border: `6px solid ${bg === 'transparent' ? 'transparent' : color}`,
            backgroundClip: 'padding-box',
        },
        '::-webkit-scrollbar-track': {
            borderRadius: 'lg',
            width: '6',
            background: bg,
        },
    };
};
