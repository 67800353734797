import React from 'react';
import { Stack, StackProps } from '@chakra-ui/react';
import { useWindowBreakpoints } from 'design-system/hooks';

type BoxLayoutProps = StackProps & {
    children: React.ReactElement;
    maxWidth?: string;
};

export function BoxLayout({
    children,
    maxWidth = 'container.md',
    ...props
}: BoxLayoutProps) {
    const { isMobile } = useWindowBreakpoints();

    return (
        <Stack
            maxWidth={!isMobile && maxWidth}
            justifyContent="space-between"
            p={isMobile ? 5 : 6}
            rounded={!isMobile && 'unset'}
            borderTop={isMobile && '1px solid'}
            borderColor="strokes.medium"
            {...props}>
            {children}
        </Stack>
    );
}
