import { Icon, IconProps } from '@chakra-ui/react';

export default function WeightIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M6 11c0-2.761 2.462-5 5.5-5S17 8.239 17 11v1.5a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V11zm5.5-3C9.383 8 8 9.518 8 11v.5h2.864l-.696-1.044a.75.75 0 0 1 1.248-.832l1.25 1.876H15V11c0-1.482-1.383-3-3.5-3z"
                clipRule="evenodd"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                d="M4 2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h15a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm15 2H4v15h15V4z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
