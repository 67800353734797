import { Icon, IconProps } from '@chakra-ui/react';

export default function ArrowUpRightIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M15.492 9.922 8.464 16.95a1 1 0 1 1-1.414-1.414l7.028-7.028-4.545-.54a1 1 0 1 1 .235-1.986l6.592.782a1 1 0 0 1 .876.876l.782 6.592a1 1 0 0 1-1.986.236l-.54-4.546z"
                clipRule="evenodd"
                fill="currentColor"
            />
        </Icon>
    );
}
