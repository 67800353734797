import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { WidgetKeys, FetchQueryParams, APIError } from 'core';

type RefundsFilters = Api<{
    widgets: WidgetKeys[];
}>;

const path = '/api/proxy/selfcare/health/refunds';

export async function fetchRefundsFilters({
    queryKey: [urn],
}: FetchQueryParams) {
    try {
        const { data: response } = await axios.options(urn);
        const { data }: RefundsFilters = response;
        return data?.widgets;
    } catch (error) {
        throw { code: error.response?.status };
    }
}

export function useRefundsFilters() {
    return useQuery<WidgetKeys[], APIError>({
        queryKey: [path, 'options'],
        queryFn: fetchRefundsFilters,
    });
}
