import { defineMessage, defineMessages } from 'react-intl';

export const contractNames = {
    'casuality.carInsurance': defineMessage({
        id: 'contract.casuality.car-insurance.title',
        defaultMessage: 'Automobile',
    }),
    'casuality.legalProtection': defineMessage({
        id: 'contract.casuality.legal-protection.title',
        defaultMessage: 'Protection juridique',
    }),
    'casuality.homeInsurance': defineMessage({
        id: 'contract.casuality.home-insurance.title',
        defaultMessage: 'Habitation',
    }),
    'casuality.financialLost': defineMessage({
        id: 'contract.casuality.financial-lost.title',
        defaultMessage: 'Perte financière',
    }),
    'casuality.accident': defineMessage({
        id: 'contract.casuality.accident.title',
        defaultMessage: 'Accident',
    }),
    'casuality.civilLiability': defineMessage({
        id: 'contract.casuality.civil-liability.title',
        defaultMessage: 'Responsabilité civile',
    }),
    'casuality.realEstateSecurityPackageAlurLaw': defineMessage({
        id: 'contract.casuality.real-estate-security-package-alur-law.title',
        defaultMessage: 'Pack Sécurité immobilière, Loi Alur',
    }),
    'casuality.professionalMultiriskInsurance': defineMessage({
        id: 'contract.casuality.professional-multirisk-insurance.title',
        defaultMessage: 'Assurance Multi-professionnelle',
    }),
    'casuality.professionalLegalProtection': defineMessage({
        id: 'contract.casuality.professional-legal-protection.title',
        defaultMessage: 'Protection juridique professionnelle',
    }),
    honorary: defineMessage({
        id: 'contract.honorary',
        defaultMessage: 'Membre honoraire',
    }),
    'provident-educational-fund': defineMessage({
        id: 'contract.provident-educational-fund',
        defaultMessage: 'Rente éducation',
    }),
    'provident-guarantee': defineMessage({
        id: 'contract.provident-guarantee.label',
        defaultMessage: 'Garantie prévoyance',
    }),
};

export const contractNamesSubscription = {
    health: defineMessages({
        collective: {
            id: 'contract.health.collective-title',
            defaultMessage: 'Santé collective',
        },
        individual: {
            id: 'contract.health.individual-title',
            defaultMessage: 'Santé individuelle',
        },
        territorial: {
            id: 'contract.health.territorial-title',
            defaultMessage: 'Santé territoriale',
        },
    }),
    provident: defineMessages({
        collective: {
            id: 'contract.provident.collective-title',
            defaultMessage: 'Prévoyance collective',
        },
        individual: {
            id: 'contract.provident.individual-title',
            defaultMessage: 'Prévoyance individuelle',
        },
        territorial: {
            id: 'contract.provident.territorial-title',
            defaultMessage: 'Prévoyance territoriale',
        },
    }),
    'provident-guarantee': defineMessages({
        individual: {
            id: 'contract.provident-guarantee.individual-title',
            defaultMessage: 'Garantie prévoyance',
        },
    }),
};
