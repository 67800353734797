export default function formatFileName(filePath: string) {
    if (filePath.indexOf('\\') !== -1) {
        return filePath.split('\\')[filePath.split('\\').length - 1];
    }

    if (filePath.indexOf('/') !== -1) {
        return filePath.split('/')[filePath.split('/').length - 1];
    }

    return filePath;
}
