import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams, WorkflowTypesEnum } from 'core';
import { SignatureFormReason, serviceTypes } from '../../types';

const path = `/api/proxy/[service]/beneficiaries-designation/[type]/[procedureId]/beneficiaries/reasons`;

async function fetchBeneficiariesDesignationReasons({
    queryKey: [urn, service, type, procedureId],
}: FetchQueryParams) {
    try {
        const response = await axios.get(
            urn
                .replace('[service]', serviceTypes[service])
                .replace('[type]', type)
                .replace('[procedureId]', procedureId)
        );

        return response?.data?.data?.entries;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useBeneficiariesDesignationReasons(
    service: WorkflowTypesEnum,
    type: string,
    procedureId: string
) {
    return useQuery<SignatureFormReason[], APIError>({
        queryKey: [path, service, type, procedureId],
        queryFn: fetchBeneficiariesDesignationReasons,
    });
}
