import React, { useLayoutEffect, useState, useMemo } from 'react';
import { uniqueId } from 'lodash';
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    HStack,
    List,
    ListItem,
    Stack,
    Text,
    UnorderedList,
} from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import {
    Heading,
    useSessionStorage,
    useRedirect,
    useUserAgent,
    WorkflowKeysEnum,
    coreErrors,
    coreSharedMessages,
    UtilsContext,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { sharedMessages } from 'lib/shared';
import { FormattedMessage } from 'react-intl';
import { CheckCircleIcon } from 'design-system/icons';
import {
    ConsentModalType,
    consentModalMessages,
    ConsentModalPartnershipNameEnum,
} from 'lib/consent-modal';
import {
    TELECONSULTATION_DATA_STORAGE_KEY,
    useTeleconsultationExclusions,
} from 'lib/teleconsultation';
import { LayoutBoundary } from 'components/Layouts';
import {
    CoreModal,
    CoreModalBody,
    CoreModalContent,
    CoreModalFooter,
} from 'design-system/components';

const slideIn = keyframes`
    from {
        transform: translateY(100%);
        visibility: hidden;
    }
    to {
        visibility: visible;
        transform: translateY(0%);
    }
`;

type ConsentModalProps = ConsentModalType & {
    isOpen: boolean;
    onClose: () => void;
};

const errorMessages = {
    error: coreErrors.serviceUnavailableDescription,
    errorDescription: coreErrors.serviceUnavailable,
};

export default function ConsentModal({
    isOpen,
    title,
    description,
    benefits,
    partnershipName,
    partnershipIcon: PartnershipIcon,
    partnershipDesc,
    consents,
    workflowLink,
    onClose,
    displayExclusions = false,
    illustration: Illustration,
}: ConsentModalProps) {
    const { isSafari } = useUserAgent();
    const { isMobile } = useWindowBreakpoints();
    const { redirect } = useRedirect();
    const [consentsAccepted, setConsentsAccepted] = useState<
        Array<string | number>
    >([]);
    const {
        siteConfig: { homePagePath },
    } = React.useContext(UtilsContext);
    const { remove, save: sessionSave } = useSessionStorage();
    const {
        data: exclusions,
        status,
        error: exclusionsError,
    } = useTeleconsultationExclusions(displayExclusions);

    const hasUserCheckedAllConsents = useMemo(
        () => consentsAccepted.length === consents.length,
        [consentsAccepted, consents]
    );

    useLayoutEffect(() => {
        remove(TELECONSULTATION_DATA_STORAGE_KEY);
    }, [remove]);

    const handleSubmitOnClick = (): void => {
        if (!hasUserCheckedAllConsents) return;

        switch (partnershipName) {
            case ConsentModalPartnershipNameEnum.EAF:
                sessionSave(WorkflowKeysEnum.TELECONSULTATION, {
                    acceptShareMedicalData: true,
                    acceptRemoteMedicalConsultation: true,
                    currentStep: 0,
                });
                break;
            case ConsentModalPartnershipNameEnum.PREVENTION:
                sessionSave(WorkflowKeysEnum.PREVENTION, {
                    acceptShareMedicalData: true,
                    currentStep: 0,
                });
                break;
            case ConsentModalPartnershipNameEnum.MEDICALIB:
                sessionSave(WorkflowKeysEnum.HOME_CONSULTATION, {
                    acceptShareMedicalData: true,
                    currentStep: 0,
                });
                break;
        }

        redirect(workflowLink);
    };

    const _renderBenefits = (): JSX.Element => {
        return (
            <List spacing={isMobile ? '3' : '2'} fontWeight="bold">
                {benefits.map((benefit) => (
                    <ListItem
                        sx={{ '&:before': { display: 'none' } }}
                        key={benefit.id}>
                        <HStack gap="0" lineHeight="1.2">
                            <CheckCircleIcon
                                color="green.500"
                                mr="2"
                                fontSize="2xl"
                            />
                            <FormattedMessage {...benefit} />
                        </HStack>
                    </ListItem>
                ))}
            </List>
        );
    };

    const _renderConsentCheckboxes = (): JSX.Element => {
        return (
            <CheckboxGroup
                value={consentsAccepted}
                onChange={(value) => setConsentsAccepted(value)}>
                {consents.map((consent, index) => (
                    <HStack
                        key={uniqueId()}
                        gap="4"
                        pt="2"
                        fontSize="medium"
                        lineHeight="5">
                        <Checkbox
                            id={`consent-${index}`}
                            value={index.toString()}>
                            <FormattedMessage {...consent} />
                        </Checkbox>
                    </HStack>
                ))}
            </CheckboxGroup>
        );
    };

    const _renderExclusions = (): JSX.Element => {
        return (
            <LayoutBoundary
                size="md"
                p="0"
                margin="0"
                status={status}
                errorCode={exclusionsError?.code}
                showButtonReloadPage={false}
                messages={{
                    ...errorMessages,
                }}
                errorButtons={[
                    {
                        text: coreSharedMessages.buttonBackHome,
                        href: homePagePath,
                        onClick: onClose,
                    },
                ]}>
                <Box p={4} borderRadius={'md'} bg={'blue.50'}>
                    <Heading as="h5" textStyle="h5" fontWeight="bold">
                        <FormattedMessage
                            {...consentModalMessages.shared.exclusionsTitle}
                        />
                    </Heading>
                    {!!exclusions?.length && (
                        <UnorderedList pl={2} pt={3}>
                            {exclusions?.map((exclusion) => (
                                <ListItem
                                    key={uniqueId()}
                                    sx={{ '&:before': { margin: 0 } }}>
                                    {exclusion}
                                </ListItem>
                            ))}
                        </UnorderedList>
                    )}
                </Box>
            </LayoutBoundary>
        );
    };

    const _renderButtons = (): JSX.Element => {
        return (
            <HStack width="full">
                <Button
                    onClick={onClose}
                    colorScheme="gray"
                    size={isMobile ? 'lg' : 'md'}
                    w="full">
                    <FormattedMessage {...sharedMessages.cancel} />
                </Button>
                <Box w="full">
                    <Button
                        type="submit"
                        isDisabled={!hasUserCheckedAllConsents}
                        colorScheme="primary"
                        size={isMobile ? 'lg' : 'md'}
                        w="full"
                        onClick={handleSubmitOnClick}>
                        <FormattedMessage {...sharedMessages.validate} />
                    </Button>
                </Box>
            </HStack>
        );
    };

    const _renderContent = (): JSX.Element => (
        <>
            <CoreModalBody>
                <Stack p="0" margin="0">
                    <Stack
                        bg="bg.colorextralight"
                        borderRadius="md"
                        py="5"
                        px="6"
                        gap={isMobile ? '4' : '1'}>
                        <Stack gap="4">
                            <Heading as="h2" fontSize="2xl" lineHeight="1.3">
                                <FormattedMessage {...title} />
                            </Heading>
                            <Text>
                                <FormattedMessage {...description} />
                            </Text>
                        </Stack>
                        <HStack
                            justifyContent="space-between"
                            flexDir={isMobile ? 'column' : 'row'}
                            gap={isMobile ? '4' : '2'}>
                            {!!benefits.length && _renderBenefits()}
                            {!!Illustration && <Illustration />}
                        </HStack>
                    </Stack>
                    {displayExclusions && _renderExclusions()}

                    <HStack
                        bg="gray.100"
                        borderRadius="md"
                        fontWeight="bold"
                        py="4"
                        px="6"
                        gap="4">
                        {PartnershipIcon && <PartnershipIcon />}
                        <FormattedMessage {...partnershipDesc} />
                    </HStack>
                    <Heading as="h5" textStyle="h5" fontWeight="semibold">
                        <FormattedMessage
                            {...consentModalMessages.shared.consentLabel}
                        />
                    </Heading>
                    {!!consents.length && _renderConsentCheckboxes()}
                </Stack>
            </CoreModalBody>
            <CoreModalFooter>{_renderButtons()}</CoreModalFooter>
        </>
    );

    return (
        <CoreModal
            size={isMobile ? 'full' : '3xl'}
            isOpen={isOpen}
            onClose={onClose}
            scrollBehavior={'inside'}
            autoFocus={true}
            motionPreset={isSafari ? 'scale' : 'none'}>
            <CoreModalContent
                pt="2"
                {...(!isSafari && {
                    visibility: 'hidden',
                    animation: `${slideIn} 0.4s forwards`,
                })}>
                {displayExclusions &&
                (exclusionsError || status === 'pending') ? (
                    <CoreModalBody>{_renderExclusions()}</CoreModalBody>
                ) : (
                    _renderContent()
                )}
            </CoreModalContent>
        </CoreModal>
    );
}
