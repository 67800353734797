import React from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import { Badge, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { WorkflowTypesEnum, useSafeIntl } from 'core';
import { PenIcon, TrashIcon } from 'design-system/icons';
import {
    SignatureCapitalBeneficiary,
    serviceTypes,
    signatureCapitalPath,
} from 'core/lib/signature';
import { defineMessages, FormattedMessage } from 'react-intl';

const signatureSelectedBeneficiaryMessages = defineMessages({
    1: {
        id: 'components.main',
        defaultMessage: 'Principal',
    },
    2: {
        id: 'components.first',
        defaultMessage: '1er',
    },
    3: {
        id: 'components.second',
        defaultMessage: '2nd',
    },
});

type SignatureSelectedBeneficiaryProps = {
    service: WorkflowTypesEnum;
    beneficiary: SignatureCapitalBeneficiary;
    id: number;
    onEdit: (beneficiary: SignatureCapitalBeneficiary) => void;
    onError: (errorCode: number, type?: 'toast') => void;
    type: 'full-capital' | 'divided-capital';
};

export default function SignatureSelectedBeneficiary({
    service,
    beneficiary,
    id,
    onError,
    onEdit,
    type,
}: SignatureSelectedBeneficiaryProps) {
    const router = useRouter();
    const procedureId = router.query?.procedureId as string;
    const queryClient = useQueryClient();

    async function onDelete() {
        try {
            await axios.delete(
                `/api/proxy/${serviceTypes[service]}/beneficiaries-designation/${type}/${procedureId}/beneficiaries/${beneficiary?.id}`
            );
        } catch (err) {
            onError(err?.response?.status, 'toast');
        }
        await queryClient.invalidateQueries({
            queryKey: [signatureCapitalPath, service, procedureId, type],
            exact: true,
            refetchType: 'active',
        });
    }

    const { safeFormatNumber } = useSafeIntl();
    return (
        <Stack
            border="1px"
            borderStyle="solid"
            borderColor={id === 1 ? 'primary.main' : 'tertiary.main'}
            p="4"
            borderRadius="md"
            spacing="3">
            <HStack placeContent="space-between">
                <Badge
                    fontSize="md"
                    px="3"
                    py="1"
                    bg={id === 1 ? 'primary.main' : 'tertiary.main'}>
                    {type === 'full-capital' ? (
                        <FormattedMessage
                            {...signatureSelectedBeneficiaryMessages[id]}
                        />
                    ) : (
                        <>
                            {safeFormatNumber(
                                parseFloat(beneficiary?.capitalPercentage)
                            )}{' '}
                            %
                        </>
                    )}
                </Badge>
                <HStack>
                    <Flex
                        bg="gray.200"
                        w="8"
                        h="8"
                        justifyContent="center"
                        alignItems="center"
                        rounded="full"
                        cursor="pointer"
                        onClick={() => onEdit(beneficiary)}>
                        <PenIcon />
                    </Flex>
                    <Flex
                        bg="gray.200"
                        w="8"
                        h="8"
                        justifyContent="center"
                        alignItems="center"
                        rounded="full"
                        cursor="pointer"
                        onClick={onDelete}>
                        <TrashIcon />
                    </Flex>
                </HStack>
            </HStack>
            <Text
                color={id === 1 ? 'primary.main' : 'tertiary.main'}
                fontWeight="semibold">
                {beneficiary?.label}
            </Text>
        </Stack>
    );
}
