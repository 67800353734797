export type LastPage<Data> = Data & {
    pagination: Pagination;
    startPage?: number;
};

function getNextPageParam<Data = unknown>(lastPage: LastPage<Data>) {
    if (!lastPage.pagination) return lastPage?.startPage || 1;

    const { totalCount, start, count } = lastPage.pagination;
    const nextId = start + count;
    return nextId <= totalCount ? nextId : null;
}

export { getNextPageParam };
