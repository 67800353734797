import { defineMessages } from 'react-intl';

export const mfaErrorMessages = defineMessages({
    MFA_CODE_ALREADY_SENT: {
        id: 'api.auth.mfa.errors.code-already-sent',
        defaultMessage:
            'Un code de validation vous a déjà été envoyé. Veuillez vérifier vos e-mails ou SMS.',
    },
    MFA_MISSING_CONTACT: {
        id: 'api.auth.mfa.errors.no-contact',
        defaultMessage:
            'Aucun canal d’envoi trouvé. Veuillez contacter votre centre de gestion. Le numéro figure sur votre carte de tiers payant.',
    },
    MFA_HAS_EXPIRED: {
        id: 'api.auth.mfa.errors.has-expired',
        defaultMessage:
            'Le code de validation a expiré. Veuillez renouveler votre demande.',
    },
    MFA_BAD_CODE: {
        id: 'api.auth.mfa.errors.bad-code',
        defaultMessage: 'Veuillez vérifier le code de validation.',
    },
    MFA_INVALID_STRATEGY: {
        id: 'api.auth.mfa.errors.invalid-strategy',
        defaultMessage:
            'Ce service est momentanément indisponible, veuillez réessayer plus tard.',
    },
    MFA_UNKNOWN_ERROR: {
        id: 'api.auth.mfa.errors.invalid-strategy',
        defaultMessage:
            'Ce service est momentanément indisponible, veuillez réessayer plus tard.',
    },
    MFA_TOO_MANY_TRIES: {
        id: 'api.auth.mfa.errors.too-many-tries',
        defaultMessage:
            'Votre compte est bloqué pendant 60 minutes en raison d’un trop grand nombre de tentatives erronées. ',
    },
    MFA_SMS_ERROR: {
        id: 'api.auth.mfa.errors.sms',
        defaultMessage:
            'Un problème est survenu lors de l’envoi du SMS. Veuillez vous reconnecter et sélectionner votre adresse e-mail pour recevoir votre code de sécurité.',
    },
    MFA_EMAIL_ERROR: {
        id: 'api.auth.mfa.errors.email',
        defaultMessage:
            'Un problème est survenu lors de l’envoi de l’e-mail. Veuillez vous reconnecter et sélectionner un numéro de téléphone pour recevoir votre code de sécurité.',
    },
});
