const combineFCProps = (provider: React.FC, props = {}) => [provider, props];

const CombineProviders = ({ providers, children }) => {
    for (let i = providers.length - 1; i >= 0; --i) {
        const [Provider, props] = providers[i];
        children = <Provider {...props}>{children}</Provider>;
    }
    return children;
};

export { combineFCProps, CombineProviders };
