export default function EuropAssistanceLogo() {
    return (
        <svg
            width="74"
            height="60"
            viewBox="0 0 74 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9565 31.8839C23.1973 31.8839 22.5869 31.3645 22.5869 30.6975C22.5869 30.047 23.1973 29.4999 23.9565 29.4999C24.7259 29.4999 25.3364 30.047 25.3364 30.6975C25.3364 31.3645 24.7259 31.8839 23.9565 31.8839Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.9016 42.159C16.807 42.159 15.8221 41.8834 15.2742 41.5259L15.899 39.9778C15.899 39.9778 16.6141 40.3928 17.5364 40.4102C18.2084 40.4215 18.6536 40.0035 18.6536 39.4482C18.6536 38.1429 15.5851 38.4503 15.4887 35.7558C15.4374 34.259 16.6941 33.0778 18.4916 33.0481C20.0735 33.0204 21.0954 33.6156 21.0954 33.6156L20.5373 35.1012C20.2244 34.9495 19.7032 34.6955 19.0681 34.6832C18.3079 34.6688 17.9868 35.1073 18.035 35.6165C18.1376 36.7168 21.3139 36.6697 21.3139 39.3847C21.3139 40.9245 20.0304 42.159 17.9016 42.159Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.0305 42.159C27.9318 42.159 26.954 41.8834 26.399 41.5259L27.03 39.9778C27.03 39.9778 27.7348 40.3928 28.6571 40.4102C29.3311 40.4215 29.7754 40.0035 29.7754 39.4482C29.7754 38.1429 26.7171 38.4503 26.6206 35.7558C26.556 34.259 27.8148 33.0778 29.6092 33.0481C31.2035 33.0204 32.2191 33.6156 32.2191 33.6156L31.6559 35.1012C31.3471 34.9495 30.8239 34.6955 30.1919 34.6832C29.4286 34.6688 29.1188 35.1073 29.1649 35.6165C29.2593 36.7168 32.4325 36.6697 32.4325 39.3847C32.4325 40.9245 31.1583 42.159 29.0305 42.159Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4029 42.159C10.3133 42.159 9.32332 41.8834 8.77753 41.5259L9.40129 39.9778C9.40129 39.9778 10.1174 40.3928 11.0407 40.4102C11.7096 40.4215 12.1569 40.0035 12.1569 39.4482C12.1569 38.1429 9.09044 38.4503 8.99502 35.7558C8.9427 34.259 10.1964 33.0778 11.9897 33.0481C13.5748 33.0204 14.6038 33.6156 14.6038 33.6156L14.0416 35.1012C13.7194 34.9495 13.2054 34.6955 12.5735 34.6832C11.8071 34.6688 11.4901 35.1073 11.5352 35.6165C11.6317 36.7168 14.8151 36.6697 14.8151 39.3847C14.8151 40.9245 13.5327 42.159 11.4029 42.159Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.5982 31.3477L36.1148 30.6971V33.2123H37.625V34.8649H36.1148V39.4014C36.1148 40.0704 36.3559 40.4064 36.914 40.429C37.1254 40.4331 37.4147 40.3931 37.625 40.3296V41.9002C37.4147 41.9965 36.7899 42.144 36.2061 42.144C34.3841 42.144 33.5982 40.9566 33.5982 39.5981V31.3477Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.3166 42.1771C39.9015 42.1771 38.5227 41.2315 38.5227 39.4017C38.5227 37.1355 40.7058 36.4644 43.2101 36.2134V35.8579C43.2101 34.9522 42.7321 34.6889 41.8498 34.6889C40.8269 34.6889 39.5773 35.2514 39.5773 35.2514L39.1936 33.6921C39.1936 33.6921 40.4463 33.0487 42.3022 33.0487C44.1899 33.0487 45.7165 33.8765 45.7165 36.2636V41.3175C45.0281 41.7898 43.76 42.1771 42.3166 42.1771ZM43.2101 37.3926C41.7646 37.5402 41.0239 38.0647 41.0239 39.3023C41.0239 40.3289 41.623 40.7786 42.4345 40.7786C42.7936 40.7786 42.9967 40.7141 43.2101 40.5963V37.3926Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.836 42.015V35.8546C51.836 35.2368 51.5641 34.6385 50.5536 34.6385C50.3135 34.6385 49.9862 34.7061 49.7236 34.8147V42.015H47.2039V33.7953C48.2247 33.3404 49.4291 33.0485 50.6161 33.0485C53.3728 33.0485 54.3618 34.2451 54.3618 35.8413V42.015H51.836Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.0652 33.0485C63.6266 33.0485 62.1359 34.8311 62.1359 37.587C62.1359 40.3932 63.7364 42.1769 66.3289 42.1769C67.4933 42.1769 68.3254 41.9853 69.2764 41.5816V39.757C68.3161 40.2088 67.5364 40.429 66.6141 40.429C65.3768 40.429 64.9593 39.7938 64.7746 38.5081L69.6499 37.7489C69.7627 34.7809 68.5182 33.0485 66.0652 33.0485ZM64.6218 37.2633C64.5438 35.4468 64.9685 34.5565 65.9678 34.5565C66.8614 34.5565 67.3743 35.2378 67.3743 36.8094L64.6218 37.2633Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.564 42.1771C57.2618 42.1771 55.5783 40.543 55.5783 37.5883C55.5783 34.6193 57.3911 33.0487 59.5466 33.0487C60.6648 33.0487 61.4876 33.3571 61.9144 33.6849L61.3409 35.0906C61.0927 34.9123 60.7541 34.7494 60.1888 34.7494C58.9762 34.7494 58.1236 35.7555 58.1236 37.5566C58.1236 39.3392 59.0644 40.3627 60.1693 40.3627C60.6997 40.3627 61.0557 40.2346 61.3409 40.0041L61.9329 41.545C61.5215 41.9025 60.5407 42.1771 59.564 42.1771Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.32251 42.1771C1.90234 42.1771 0.522461 41.2335 0.522461 39.4017C0.522461 37.1355 2.7077 36.4644 5.21815 36.2134V35.8579C5.21815 34.9522 4.73391 34.6889 3.85879 34.6889C2.82978 34.6889 1.58122 35.2514 1.58122 35.2514L1.20471 33.6921C1.20471 33.6921 2.45326 33.0487 4.3061 33.0487C6.19689 33.0487 7.73168 33.8765 7.73168 36.2636V41.3175C7.032 41.7898 5.76395 42.1771 4.32251 42.1771ZM5.21815 37.3926C3.76441 37.5402 3.02779 38.0647 3.02779 39.3023C3.02779 40.3289 3.62488 40.7786 4.4446 40.7786C4.79649 40.7786 5.00065 40.7141 5.21815 40.5963V37.3926Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.7033 42.0146H25.2199V33.212H22.7033V42.0146Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.2544 17.4292C48.7039 17.4292 47.4584 18.3379 47.4584 20.514V26.6262H50.0889V20.3736C50.0889 19.7231 50.4367 19.2395 51.3211 19.1801C51.5057 19.1668 51.7078 19.175 51.8997 19.1914L52.2075 17.4896C51.8761 17.4476 51.5591 17.4292 51.2544 17.4292Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.1232 26.7933C39.5748 26.7933 38.3221 25.8416 38.3221 23.6635L38.3252 17.5912H40.9434V23.8479C40.9434 24.4995 41.2963 25.0322 42.1878 25.0322C42.6546 25.0322 42.9911 24.9666 43.2784 24.8181V17.5912H45.9037V25.9543C45.003 26.3948 43.4261 26.7933 42.1232 26.7933Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.2353 26.7933C53.6181 26.7933 52.2465 24.7986 52.2465 22.1021C52.2465 19.4046 53.6181 17.4293 56.2178 17.4293C58.8617 17.4293 60.2303 19.4046 60.2303 22.1021C60.2303 24.7986 58.8617 26.7933 56.2353 26.7933ZM56.2353 19.046C55.0821 19.046 54.8462 20.4557 54.8462 22.0683C54.8462 23.6983 55.0996 25.0148 56.2353 25.0148C57.3977 25.0148 57.6316 23.6983 57.6316 22.0683C57.6316 20.4557 57.3977 19.046 56.2353 19.046Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M65.4867 26.7439C64.9307 26.7439 64.4392 26.6763 64.1735 26.5933V29.5931H61.5543V18.095C62.3289 17.7108 63.3979 17.4291 65.0168 17.4291C67.7202 17.4291 69.4663 19.0109 69.4663 22.0527C69.4663 24.95 67.833 26.7439 65.4867 26.7439ZM64.9861 18.9617C64.6249 18.9617 64.39 19.0109 64.1735 19.097V25.0822C64.3418 25.1477 64.5716 25.2 64.8588 25.2C66.2541 25.2 66.8727 24.114 66.8727 22.0834C66.8727 20.0549 66.3731 18.9617 64.9861 18.9617Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.4189 17.4292C30.854 17.4292 29.3192 19.2467 29.3192 22.0856C29.3192 25.1643 30.9905 26.7932 33.6959 26.7932C34.888 26.7932 35.7662 26.5975 36.7429 26.1908V24.317C35.7559 24.7719 34.9372 25.0024 33.9883 25.0024C32.6925 25.0024 32.2185 24.3354 32.0359 23.0077L37.1471 22.2526C37.2702 19.1944 35.9714 17.4292 33.4189 17.4292ZM31.8769 21.7403C31.7907 19.8798 32.2637 18.9619 33.3194 18.9619C34.2468 18.9619 34.7741 19.6749 34.7741 21.2721L31.8769 21.7403Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.32574 5.09905C1.32341 6.48521 -0.751026 12.411 0.280035 18.055C1.16541 23.738 4.98085 28.0727 11.1364 28.5952C13.89 28.8882 16.4108 28.4149 17.5147 27.7172C17.5352 27.7038 18.0943 27.333 18.0943 26.8013C18.0943 26.8013 11.7069 27.7377 9.02815 24.8936C6.65415 22.3733 8.37874 19.2701 8.37874 19.2701C9.37697 17.1739 11.8987 14.7182 11.8987 14.7182C11.8987 14.7182 15.1601 11.5166 16.1768 9.68783C16.6837 8.77806 16.9504 7.82527 16.9658 7.00156C16.9976 5.28243 15.7501 4.59294 15.3735 4.40852C11.9726 2.73447 5.0619 4.05917 2.32574 5.09905Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.2231 23.851C24.2245 22.4648 26.2989 16.5391 25.2678 10.895C24.3825 5.21206 20.567 0.87736 14.4124 0.354859C11.6588 0.0618491 9.13813 0.535173 8.03423 1.23286C8.01371 1.24618 7.45355 1.61706 7.45355 2.14878C7.45355 2.14878 13.841 1.21237 16.5207 4.05642C18.8937 6.57671 17.1702 9.67996 17.1702 9.67996C16.1719 11.7761 13.6502 14.2308 13.6502 14.2308C13.6502 14.2308 10.3877 17.4335 9.37204 19.2622C8.86421 20.172 8.59849 21.1237 8.58208 21.9485C8.5513 23.6676 9.79781 24.3571 10.1743 24.5415C13.5763 26.2156 20.486 24.8909 23.2231 23.851Z"
                fill="#E4002B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46.1322 52.7444C46.0501 52.8161 45.9629 53.0395 45.8706 53.4083C45.7762 53.7781 45.7311 54.1009 45.7311 54.3744C45.7311 54.7299 45.9639 54.9082 46.4318 54.9082C46.8821 54.9082 47.4085 54.6449 48.0138 54.1214C48.6006 53.6112 49.0058 53.0825 49.2336 52.5395C49.2336 52.5395 49.6593 52.4186 49.5403 52.7721C49.2787 53.5528 48.8366 54.1746 48.2179 54.6828C47.6321 55.1694 47.0432 55.4143 46.4523 55.4143C46.0163 55.4143 45.688 55.2647 45.4633 54.9625C45.2458 54.6766 45.1504 54.2884 45.1781 53.7997C45.213 53.1143 45.5772 52.265 46.2686 51.2517C47.0094 50.1657 47.6834 49.6238 48.2928 49.6217C48.7935 49.6197 48.8868 50.2016 48.8868 50.3553C48.8868 50.8019 48.698 51.3163 48.3205 51.8961C47.8742 52.5938 47.3643 52.9165 46.7878 52.9422C46.3446 52.9647 46.1322 52.7444 46.1322 52.7444ZM48.2118 50.1022C48.0394 50.1432 47.8824 50.2477 47.7409 50.4167C46.796 51.5304 46.323 52.1789 46.323 52.3613C46.323 52.4115 46.4277 52.4432 46.637 52.4576C46.9919 52.475 47.3767 52.2209 47.7963 51.6912C48.2056 51.1738 48.4087 50.7149 48.4087 50.3081C48.4087 50.134 48.3431 50.0663 48.2118 50.1022Z"
                fill="#E4002B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.2275 52.7444C66.1455 52.8161 66.0572 53.0395 65.9649 53.4083C65.8715 53.7781 65.8243 54.1009 65.8243 54.3744C65.8243 54.7299 66.0593 54.9082 66.5271 54.9082C66.9765 54.9082 67.5038 54.6449 68.1081 54.1214C68.6949 53.6111 69.1012 53.0825 69.3289 52.5395C69.3289 52.5395 69.7537 52.4186 69.6357 52.7721C69.3751 53.5528 68.9319 54.1746 68.3143 54.6828C67.7275 55.1694 67.1375 55.4143 66.5476 55.4143C66.1116 55.4143 65.7833 55.2647 65.5586 54.9625C65.3411 54.6766 65.2447 54.2883 65.2734 53.7997C65.3093 53.1143 65.6725 52.2649 66.363 51.2517C67.1037 50.1657 67.7788 49.6217 68.3882 49.6217C68.5944 49.6217 68.7503 49.718 68.8601 49.9096C68.9401 50.051 68.9822 50.2016 68.9822 50.3553C68.9822 50.8019 68.7924 51.3162 68.4159 51.8961C67.9696 52.5938 67.4587 52.9165 66.8821 52.9421C66.4399 52.9647 66.2275 52.7444 66.2275 52.7444ZM68.3071 50.1022C68.1337 50.1432 67.9778 50.2477 67.8362 50.4167C66.8913 51.5304 66.4194 52.1789 66.4194 52.3612C66.4194 52.4114 66.523 52.4432 66.7323 52.4576C67.0873 52.475 67.472 52.2209 67.8916 51.6912C68.3009 51.1738 68.5051 50.7149 68.5051 50.3081C68.5051 50.134 68.4384 50.0663 68.3071 50.1022Z"
                fill="#E4002B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.0599 50.7113C55.9563 50.7225 55.826 50.6283 55.6721 50.4654C55.5162 50.3004 55.3623 50.2175 55.2074 50.2175C54.6493 50.2175 54.0778 50.7359 53.4972 51.7716C52.9821 52.6906 52.7257 53.4068 52.7257 53.9221C52.7257 54.4979 52.9668 54.7847 53.4489 54.7847C53.926 54.7847 54.399 54.629 54.8668 54.3124C55.2997 54.0194 55.6691 53.6342 55.9779 53.1558C56.0045 53.1056 56.0415 53.0707 56.0866 53.081C56.2713 53.1178 56.3246 53.3289 56.298 53.3689C55.4988 54.5911 54.834 54.9374 54.7447 54.9978C54.124 55.4076 53.7516 55.4035 53.5515 55.4015C53.0786 55.3964 52.7216 55.2304 52.4805 54.8872C52.2814 54.6054 52.1809 54.251 52.1809 53.8268C52.1809 53.1558 52.4805 52.3382 53.0817 51.3752C53.7721 50.2656 54.5036 49.7083 55.2751 49.7052C55.4075 49.7052 55.6003 49.7615 55.8558 49.8763C56.1872 50.0218 56.3595 50.2011 56.3739 50.4101C56.3831 50.5012 56.338 50.6805 56.0599 50.7113Z"
                fill="#E4002B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.218 51.6629C63.7802 50.2204 64.3845 49.4991 65.0318 49.4991C65.4576 49.4991 65.7069 49.6948 65.7807 50.0882C65.8218 50.3249 65.7438 50.4448 65.5489 50.4448C65.4843 50.4448 65.435 50.4192 65.4124 50.3618C65.3858 50.2993 65.3447 50.1159 65.3447 50.1159C65.3263 50.0227 65.2668 49.9786 65.1662 49.9786C64.9446 49.9786 64.6604 50.2163 64.3147 50.6907C63.9094 51.2521 63.6263 51.7633 63.4632 52.2254C63.2898 52.7182 63.2077 53.2837 63.218 53.922C63.218 53.9455 63.2518 54.3164 63.2652 55.0377C63.2693 55.2938 62.9553 55.3952 62.8692 55.3952C62.6157 55.3952 62.5039 55.1954 62.5357 54.8C62.6178 53.8759 62.6588 53.1105 62.6588 52.4979C62.6588 51.8678 62.6178 51.1527 62.5357 50.3495C62.5265 50.2573 62.6588 49.9571 62.9174 49.9223C63.0661 49.9049 63.1749 50.0001 63.2436 50.1487C63.2939 50.2604 63.3134 50.3618 63.299 50.4581C63.2395 50.9376 63.2118 51.3392 63.218 51.6629Z"
                fill="#E4002B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.1205 49.7149C44.0117 49.5315 43.8855 49.3255 43.5839 49.3051C43.3623 49.3081 43.2094 49.4628 43.1212 49.6247C43.0473 49.7589 43.0935 49.929 43.2279 50.0058C43.3551 50.0775 43.5152 50.0376 43.5942 49.9177C43.8619 50.3193 43.942 51.4104 43.942 51.8325C43.943 52.7351 43.7511 53.5506 43.47 54.1182C43.1889 54.6971 42.8268 54.9726 42.5836 54.9593C42.5354 54.9593 42.0009 54.9153 41.6531 54.193C41.4807 53.8365 41.4212 53.3457 41.4212 52.7853C41.4212 52.1194 41.5166 51.3541 41.6418 50.5754C41.7034 50.1954 41.2355 50.2589 41.2171 50.2589C41.0817 50.2589 40.9647 50.4207 40.8693 50.7455C40.7708 51.0979 40.6928 51.3705 40.6374 51.5661C40.4322 52.1563 40.1809 52.7597 39.8813 53.3754C39.4268 54.3149 39.0719 54.7852 38.8174 54.7852C38.7076 54.7852 38.6594 54.4881 38.7046 53.9256C38.7302 53.4492 38.9344 52.4155 39.1591 51.0734C39.2257 50.6636 39.0749 50.5468 38.8656 50.5468C38.6287 50.5468 38.4604 50.9135 38.3609 51.6502C38.2675 52.5097 38.2244 53.2218 38.1967 53.7104C38.1793 53.984 38.2111 54.2944 38.2932 54.6417C38.4142 55.1427 38.6133 55.3937 38.8851 55.3937C39.2668 55.3937 39.7284 54.8169 40.2701 53.6623C40.5246 53.0619 40.7287 52.5671 40.8898 52.1727C40.8139 53.2668 40.9329 53.9492 41.1463 54.4215C41.5618 55.3425 42.3507 55.5177 42.5836 55.5187C43.1879 55.5197 43.6424 55.0188 43.9686 54.3651C44.2938 53.7023 44.497 52.814 44.498 51.8325C44.498 51.3735 44.4036 50.2046 44.1205 49.7149Z"
                fill="#E4002B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61.4791 53.976C61.2986 53.9688 61.1857 54.0764 61.1375 54.271C61.0995 54.4288 60.8636 54.4534 60.7907 54.437C60.5866 54.3817 60.484 54.1204 60.484 53.6553C60.484 53.4002 60.5948 52.6851 60.8174 51.512C60.8728 51.2436 60.761 51.1074 60.484 51.1074C60.288 51.1074 60.2336 51.1565 60.167 51.3133C60.0921 51.4956 60.0172 52.012 59.9546 52.5314C59.3565 53.4381 58.8753 54.0518 58.507 54.4268C58.1377 54.812 57.8689 54.9226 57.8083 54.9114C57.6647 54.8858 57.6165 54.4411 57.6196 54.1655C57.6124 53.2558 57.9602 51.844 58.2782 51.2252C58.6476 50.5029 59.0754 50.3041 59.3975 50.3031C59.7494 50.3031 60.0192 50.551 60.05 50.8318C60.0685 50.9844 60.207 51.093 60.3598 51.0756C60.5117 51.0572 60.6214 50.9178 60.603 50.7652V50.7631C60.5291 50.1771 60.009 49.7448 59.3975 49.7448C58.822 49.7427 58.2033 50.1279 57.7817 50.9721C57.4113 51.721 57.0686 53.1287 57.0625 54.1655C57.0645 54.4831 57.0933 54.7659 57.1907 55.0066C57.2738 55.2372 57.4985 55.4707 57.795 55.4707C57.7991 55.4707 57.8032 55.4707 57.8083 55.4687C58.1674 55.4564 58.4865 55.2341 58.902 54.8181C59.1851 54.5292 59.5124 54.1307 59.8879 53.6082C59.9371 54.2751 60.1505 54.6778 60.5271 54.812C60.6317 54.853 61.3098 55.0681 61.6022 54.3397C61.6299 54.2669 61.7315 53.9903 61.4791 53.976Z"
                fill="#E4002B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.78362 53.9699C6.78362 53.2456 6.957 52.467 7.29453 51.6351C7.64129 50.8144 8.00447 50.2479 8.49384 50.3339C8.57592 50.17 8.76161 50.0461 9.01296 49.919C9.2951 49.7838 9.50336 49.711 9.67879 49.708C11.0105 49.6701 10.9663 51.547 10.9663 51.547C10.9787 52.1033 10.7006 52.9116 10.2092 53.8019C9.62032 54.8705 8.95962 55.4739 8.2035 55.5426C8.2035 55.5426 6.85543 55.6983 6.78362 53.9699ZM8.63234 51.2714C7.92343 52.2067 7.58179 53.0663 7.58282 53.8542C7.58076 54.1994 7.60744 54.7865 7.97985 54.7885C8.42613 54.7957 8.93089 54.3592 9.4377 53.387C9.88295 52.5479 10.0984 51.8799 10.0963 51.4025C10.1025 50.5225 9.81422 50.4231 9.67879 50.42C9.44796 50.419 9.18122 50.5429 8.87959 50.8267C8.85908 50.9599 8.7729 51.0716 8.63234 51.2714Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3572 54.093C11.3593 53.8133 11.4906 53.1566 11.7502 52.0676C11.901 51.4498 12.0405 50.9826 12.1708 50.6589C12.3113 50.3464 12.3955 50.1435 12.6376 50.1046C12.7587 50.079 13.2049 50.2286 13.1988 50.539C13.1967 50.6753 13.0203 51.0728 13.0203 51.0728C12.4447 52.6259 12.1605 53.7211 12.1626 54.3123C12.1575 54.5797 12.2108 54.7415 12.2313 54.7538C12.2949 54.7631 12.5637 54.5786 12.8879 54.1668C13.2521 53.715 13.6174 53.1341 13.9775 52.4231C14.3827 51.6301 14.5797 51.0256 14.5828 50.6455C14.5869 50.4745 14.6956 49.9724 15.1511 49.9274C15.5625 49.9356 15.6528 50.2562 15.6261 50.4027C15.6241 50.4806 15.5841 50.6804 15.504 51.0984C15.4251 51.5031 15.3881 51.8842 15.3881 52.2448C15.3871 52.9548 15.4435 53.4834 15.5471 53.8174C15.6569 54.1596 15.7821 54.2621 15.9154 54.2611C15.9657 54.2621 16.0519 54.2283 16.1627 54.1145C16.2284 54.05 16.3135 53.9855 16.4223 53.9127C16.4725 53.881 16.5372 53.8851 16.5813 53.924C16.688 54.0213 16.769 54.1002 16.7783 54.2375C16.7772 54.47 16.6182 54.6545 16.3781 54.7907C16.1853 54.9014 16.0221 54.9659 15.8672 54.9669C15.4599 54.97 15.1245 54.7282 14.908 54.3123C14.7233 53.9619 14.6002 53.4845 14.5294 52.8728C14.1601 53.6381 13.9159 54.1166 13.7846 54.3225C12.9567 55.6329 12.1216 55.515 12.1216 55.515C11.8651 55.515 11.3531 55.3603 11.3572 54.093Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.1384 48.7874H24.1435C24.3148 48.7874 24.4308 48.7433 24.5344 48.6347C24.5929 48.5722 24.8032 48.1808 24.5211 47.8735C24.2276 47.5999 23.8727 47.7792 23.7824 47.9196C23.5546 48.269 23.7024 48.5077 23.7855 48.607C23.8706 48.7177 23.9999 48.7874 24.1384 48.7874ZM24.2317 54.6691C24.0809 54.8422 24.0101 54.8535 24.0009 54.8535C24.0009 54.8535 23.7978 54.9436 23.6029 54.3843C23.5475 54.122 23.1679 53.1774 23.7085 51.2779C23.7085 51.2779 23.9055 50.7688 23.9086 50.612C23.9045 50.1756 23.5946 50.1202 23.4695 50.1213C23.2561 50.1264 23.1104 50.2924 23.0027 50.5188C22.6559 51.3281 22.5728 52.6252 22.5728 53.0422C22.5728 53.5011 22.6559 53.9704 22.819 54.4478C23.0468 55.1403 23.4182 55.5522 23.9332 55.5532C24.0707 55.5522 24.6626 55.4057 24.6524 54.9795C24.5795 54.541 24.3056 54.5851 24.2317 54.6691Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.7409 54.9094C29.3808 54.4278 29.4239 53.7138 29.428 53.6574C29.468 52.9321 29.8445 52.0684 30.5421 51.0429C31.2921 49.9477 31.9825 49.3616 32.6771 49.3545C32.9244 49.3504 33.1398 49.4856 33.2629 49.7089C33.3542 49.8677 33.4024 50.0419 33.4024 50.2212C33.4014 50.7068 33.2003 51.2426 32.8156 51.8328C32.3611 52.553 31.8051 52.9443 31.17 52.9433C30.9238 52.9433 30.7145 52.8972 30.5483 52.7978C30.5021 52.8962 30.4385 53.0704 30.3821 53.308C30.2908 53.6697 30.2467 53.9822 30.2467 54.2414C30.26 54.519 30.3708 54.6215 30.815 54.6399C31.2121 54.643 31.7179 54.4033 32.3098 53.8869C32.8843 53.3849 33.2783 52.8726 33.4948 52.3532C33.5153 52.304 33.5635 52.2723 33.6169 52.2723C33.6169 52.2723 34.217 52.1842 34.0498 52.6811C33.779 53.4709 33.3224 54.1287 32.6874 54.6502C32.0841 55.1532 31.4645 55.4144 30.8366 55.4144H30.8253C30.3647 55.4144 29.9851 55.2434 29.7409 54.9094ZM32.2052 51.5541C32.99 50.5716 32.7079 49.9866 32.7079 49.9866C32.592 50.004 32.434 50.0737 32.1693 50.3308C31.5352 50.9517 30.9792 51.7129 30.8202 52.1473C30.8202 52.1473 31.4193 52.5366 32.2052 51.5541Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.84438 52.011C5.7582 51.4076 5.51505 50.003 5.51505 50.003C5.47196 49.5419 5.2955 49.2151 4.94053 49.2018C4.50862 49.1823 4.40807 49.5409 4.41526 49.6844C4.4409 50.1536 4.45424 50.5829 4.45424 50.9742C4.45629 52.262 4.30856 53.1359 4.07875 53.6277C3.83355 54.1164 3.62837 54.225 3.24877 54.2434C2.79633 54.2363 2.48548 54.0385 2.22797 53.6615C1.97559 53.2855 1.8258 52.72 1.82683 52.1022C1.8258 51.382 2.02689 50.5931 2.43623 49.9405C2.55216 49.754 2.49574 49.5081 2.31107 49.3903C2.12435 49.2735 1.88018 49.3299 1.76322 49.5163V49.5153C1.26667 50.3134 1.03481 51.2375 1.03378 52.1022C1.03481 52.845 1.20409 53.5509 1.56727 54.1031C1.92429 54.6543 2.52139 55.0446 3.24877 55.0384C3.26108 55.0405 4.29625 55.0805 4.79895 53.9617C4.93335 53.6636 5.03492 53.3132 5.10673 52.9023C5.13854 53.2486 5.15495 53.5693 5.15495 53.8603C5.15495 54.979 4.9313 55.9923 4.48707 56.9031C3.96795 57.9563 3.31648 58.4419 2.49882 58.4449C2.44136 58.4449 1.93763 58.446 1.37234 58.031C0.861428 57.6561 0.718824 56.9522 0.692149 56.8529C0.643931 56.691 0.536208 56.5435 0.365903 56.5476C0.0293975 56.5547 0.00169732 56.8559 0.000671387 56.9696C0.00272325 57.4204 0.257154 57.8866 0.726005 58.3835C1.26565 58.9633 1.89659 59.2676 2.59525 59.2666H2.60243C3.78533 59.2666 4.70662 58.4952 5.32833 57.0496C5.78385 56.0025 6.01366 54.9503 6.01366 53.8951C6.01571 52.7599 5.86387 52.1473 5.84438 52.011Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.4003 49.9519C29.2289 49.8146 28.994 49.9478 28.8237 50.0666C28.5118 50.334 28.2133 50.7623 27.9117 51.3442C27.7413 51.6762 26.857 53.8973 26.857 53.8973C26.8354 53.8399 26.3656 52.549 26.0106 51.6864C25.8075 51.1905 25.8341 51.1547 25.6813 50.8975C25.3273 50.2756 25.0606 50.4601 24.9149 50.5574C24.6102 50.7602 24.7251 51.0256 24.7497 51.0809C25.8464 52.9465 26.0711 54.2005 26.1706 54.5263C26.2589 54.7896 26.342 54.9894 26.4302 55.1328C26.5184 55.2722 26.618 55.3818 26.7749 55.3869C26.9421 55.3695 27.0088 55.2599 27.1135 55.1124C27.2099 54.9597 27.3187 54.7415 27.4428 54.4516C27.7783 53.6555 28.1168 52.8625 28.4523 52.0675C28.718 51.501 28.953 51.0748 29.15 50.7971C29.2454 50.6557 29.3367 50.5594 29.4075 50.501C29.5367 50.3638 29.6167 50.1271 29.4003 49.9519Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.4269 55.1186C20.0155 54.6391 19.7631 53.7068 19.7487 52.0645C19.7487 50.6569 19.9734 48.7482 20.6197 46.2709V46.273C20.6772 46.0517 20.9019 45.9195 21.1204 45.9779C21.34 46.0363 21.4713 46.2607 21.4138 46.4799C20.7808 48.9019 20.5674 50.7439 20.5674 52.0645C20.5541 53.59 20.8916 54.4435 21.0301 54.5613C21.1204 54.6545 21.2753 54.7631 21.7216 54.5541C21.9483 54.3984 22.1946 54.4721 22.3166 54.6648C22.4377 54.8553 22.3813 55.1094 22.1905 55.2313C22.1135 55.2784 21.6385 55.5305 21.3194 55.5161C21.182 55.5079 20.828 55.5858 20.4269 55.1186Z"
                fill="#00239C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72.5155 48.8954L72.5473 50.1668H72.0743L72.1082 48.8954L70.8524 49.5531L70.6237 49.2099L71.9102 48.5973L70.6237 47.9846L70.8524 47.6404L72.1082 48.2991L72.0743 47.0277H72.5473L72.5155 48.2991L73.7702 47.6404L74 47.9846L72.7135 48.5973L74 49.2099L73.7702 49.5531L72.5155 48.8954Z"
                fill="#E4002B"
            />
        </svg>
    );
}
