import {
    PinInput,
    Text,
    PinInputField,
    SimpleGrid,
    Box,
    Button,
    Center,
} from '@chakra-ui/react';
import { min } from 'lodash';
import { useRouter } from 'next/router';
import {
    EnhancedQueryStatus,
    getChallengeType,
    mfaErrorMessages,
    ChallengeRouterQueries,
    useMFAPost,
    ChallengeWorkflow,
    useRegistrationChallengePost,
    ChallengeError,
    registrationChallengeErrorMessages,
    coreSharedMessages,
    UtilsContext,
    useSafeIntl,
} from 'core';
import {
    MFA_NUMBER_OF_PIN,
    REGISTRATION_CHALLENGE_NUMBER_OF_PIN,
} from 'core/lib/auth';
import { ChallengeErrorBlock, ChallengeValidationIcons } from '.';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import Alert from 'design-system/components/Alert';

function shouldDisplayErrorBlock(
    status: EnhancedQueryStatus,
    error: ChallengeError
) {
    return (
        status === 'error' &&
        error &&
        (error?.code?.includes('HAS_EXPIRED') ||
            error?.code?.includes('TOO_MANY_TRIES') ||
            error?.code?.includes('INVALID_STRATEGY') ||
            error?.code?.includes('UNKNOWN_ERROR') ||
            error?.code?.includes('NO_PENDING_VALIDATION'))
    );
}

const numberOfPins = {
    mfa: MFA_NUMBER_OF_PIN,
    registration: REGISTRATION_CHALLENGE_NUMBER_OF_PIN,
};

type ChallengeFormProps = {
    workflow: ChallengeWorkflow;
};

export default function ChallengeForm({ workflow }: ChallengeFormProps) {
    const { siteConfig } = React.useContext(UtilsContext);
    const { managementCenterPhoneNumber } = siteConfig;
    const router = useRouter();
    const { strategy, contact } = router.query as ChallengeRouterQueries;
    const type = getChallengeType(strategy);
    const mfaData = useMFAPost(workflow);
    const registrationChallengeData = useRegistrationChallengePost(workflow);
    const workflowData = [mfaData, registrationChallengeData].find(
        (data) => data.isEnabled
    );
    const { status, error, handleInput, handleSubmit, isCodeValid } =
        workflowData || {};
    const { safeFormatMessage } = useSafeIntl();
    if (shouldDisplayErrorBlock(status, error)) {
        return <ChallengeErrorBlock code={error.code} />;
    }

    return (
        <>
            {type && (
                <Center>
                    <ChallengeValidationIcons type={type} w="28" h="28" />
                </Center>
            )}
            {status === 'error' &&
                error &&
                error?.code?.includes('BAD_CODE') && (
                    <Alert
                        color="error"
                        icon="AlertIcon"
                        description={safeFormatMessage(
                            mfaErrorMessages[error.code],
                            managementCenterPhoneNumber ||
                                registrationChallengeErrorMessages[error.code],
                            `ChallengeForm alert description managementCenterPhoneNumber : ${managementCenterPhoneNumber}`
                        )}
                    />
                )}

            <Text>
                {type === 'paper' ? (
                    <FormattedMessage
                        id="security-code-workflow.code-entry.paper-description"
                        defaultMessage="Veuillez saisir le code de sécurité que vous avez reçu par courrier."
                    />
                ) : (
                    <FormattedMessage
                        id="security-code-workflow.code-entry.description"
                        defaultMessage="<l>Vous avez reçu un code de sécurité sur {type, select, email {votre adresse e-mail} other {votre numéro}} {contact}.</l><l>(Pour les e-mails, pensez à vérifier vos spams.)</l>"
                        values={{
                            type,
                            contact,
                        }}
                    />
                )}
            </Text>

            <Box as="form" textAlign="right" onSubmit={handleSubmit}>
                <SimpleGrid
                    columns={{
                        base: min([6, numberOfPins[workflow]]),
                        sm: min([8, numberOfPins[workflow]]),
                    }}
                    gap="2">
                    <PinInput
                        otp
                        onChange={handleInput}
                        isInvalid={isCodeValid === false}>
                        {Array.from(Array(numberOfPins[workflow]).keys()).map(
                            (pin) => (
                                <PinInputField key={pin} mt="2" />
                            )
                        )}
                    </PinInput>
                </SimpleGrid>

                <Button
                    colorScheme="primary"
                    mt="6"
                    type="submit"
                    isLoading={status === 'pending'}
                    width={{ base: '100%', sm: 'initial' }}>
                    <FormattedMessage {...coreSharedMessages.send} />
                </Button>
            </Box>
        </>
    );
}
