import React, { useState, useEffect } from 'react';
import {
    BaseIri,
    ViolationMessage,
    flattenViolations,
    useFormValidation,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { useEligibility } from 'lib/shared';
import { useDisclosure } from '@chakra-ui/react';
import { ServicesEnum, useForm } from 'lib/forms';
import { FormModal } from 'core/components/modals/FormModal';
import { useOnboarding } from 'lib/onboarding';
import { useUserContext } from 'lib/user';

const ConsentFormModal = () => {
    const [hasBeenOpened, setHasBeenOpened] = useState<boolean>(false);
    const { data: eligibility } = useEligibility();
    const service = ServicesEnum.SECURITY;
    const {
        data: form,
        status: formStatus,
        error: formError,
        isFetching,
    } = useForm(
        eligibility?.displayConsent?.formId,
        service,
        eligibility?.displayConsent?.login || false
    );
    const { displayOnboarding } = useOnboarding();
    const { isOnboardingCompleted } = useUserContext();
    const displayConsent = !displayOnboarding || isOnboardingCompleted;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { onSubmit, methods, isSubmitting } = useFormValidation(
        (form?.hostName || service) as BaseIri,
        form?.iri,
        form?.widgets
    );
    const [errors, setErrors] = useState<ViolationMessage[]>([]);
    const { isMobile } = useWindowBreakpoints();

    async function handleSubmitForm(data) {
        setErrors([]);
        const result = await onSubmit(data);

        if (result?.status === 200) {
            onClose();
            return;
        }

        if (result?.data?.data?.violations) {
            const violations = flattenViolations(result.data.data.violations);
            setErrors(violations);
        }
    }

    useEffect(() => {
        if (eligibility?.displayConsent?.login && !hasBeenOpened) {
            setHasBeenOpened(true);
            onOpen();
        }
    }, [eligibility, hasBeenOpened, onOpen]);

    if (!displayConsent) return null;

    return (
        <FormModal
            isOpen={isOpen}
            onClose={onClose}
            form={form}
            errors={errors}
            onSubmit={handleSubmitForm}
            methods={methods}
            isSubmitting={isSubmitting}
            formStatus={formStatus}
            isFetching={isFetching}
            formError={formError}
            submitErrorCode={0}
            withCancelButton={false}
            withCloseButton={false}
            modalProps={{
                closeOnEsc: false,
                closeOnOverlayClick: false,
            }}
            modalHeaderProps={{
                bg: 'gray.100',
                borderColor: 'gray.100',
                borderTopRadius: isMobile ? 'lg' : 'xl',
                fontWeight: 'bold',
            }}
        />
    );
};

export default ConsentFormModal;
