import { Icon, IconProps } from '@chakra-ui/react';

export default function WaitIcon(props: IconProps) {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M10.373 2.667a1 1 0 0 1-.657 1.253l-.004.001a1 1 0 1 1-.596-1.909l.005-.001a1 1 0 0 1 1.252.656zM6.114 4.622a1 1 0 0 1 .045 1.413l-.005.006a1 1 0 0 1-1.461-1.366l.007-.008a1 1 0 0 1 1.414-.045zM3.573 8.535a1 1 0 0 1 .788 1.174l-.001.008a1 1 0 1 1-1.963-.384l.002-.01a1 1 0 0 1 1.174-.788zM3.182 13.21a1 1 0 0 1 1.12.862l.001.01a1 1 0 1 1-1.983.259l-.001-.011a1 1 0 0 1 .863-1.12zM5.151 18.168a1 1 0 0 1 1.41-.098l.008.006a1 1 0 0 1-1.311 1.51l-.01-.007a1 1 0 0 1-.097-1.411zM9.785 20.894a1 1 0 0 1 1.125-.858l.01.001a1 1 0 1 1-.265 1.983l-.011-.002a1 1 0 0 1-.859-1.124zM16.858 19.485a1 1 0 0 1-.343 1.373l-.006.003a1 1 0 1 1-1.027-1.716l.004-.003a1 1 0 0 1 1.372.343zM20 12.028c0-4.143-2.689-7.396-6.004-7.946C13.45 3.992 13 3.552 13 3s.45-1.006.998-.94C18.585 2.617 22 6.968 22 12.028c0 2.14-.602 4.132-1.637 5.77-.295.468-.928.542-1.363.202-.435-.34-.506-.966-.223-1.44.768-1.284 1.223-2.842 1.223-4.532zM13 7a1 1 0 1 0-2 0v5a1 1 0 0 0 .293.707l1.793 1.793a1 1 0 0 0 1.414-1.414l-1.207-1.207a1 1 0 0 1-.293-.707V7z"
                fill="currentColor"
            />
        </Icon>
    );
}
