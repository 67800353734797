import { defineMessages } from 'react-intl';

export const quickAccessMessages = defineMessages({
    card: {
        id: 'components.common.quick-access.card',
        defaultMessage: 'Carte TP',
    },
    requests: {
        id: 'components.common.quick-access.requests',
        defaultMessage: 'Demandes',
    },
    guarantees: {
        id: 'components.common.quick-access.guarantees',
        defaultMessage:
            '{guaranteeEligible, select, true {Garanties} other {Contrat}}',
    },
    help: {
        id: 'components.common.quick-access.help',
        defaultMessage: 'Aide',
    },
    contact: {
        id: 'components.common.quick-access.contact',
        defaultMessage: 'Contact',
    },
    accessibility: {
        id: 'components.common.quick-access.accessibility',
        defaultMessage: 'Accessibilité',
    },
});

export const quickAccessMessagesV3 = defineMessages({
    card: {
        id: 'home.quick-access.tp-card',
        defaultMessage: 'Afficher ma carte de tiers payant',
    },
    requests: {
        id: 'home.quick-access.refund-request',
        defaultMessage: 'Demander un remboursement',
    },
    guarantees: {
        id: 'home.quick-access.guarantees-and-services',
        defaultMessage:
            '{guaranteeEligible, select, true {Consulter mes garanties & services} other {Consulter mes services}}',
    },
    hospi: {
        id: 'home.quick-access.hospistalization-preparation',
        defaultMessage: 'Préparer une hospitalisation',
    },
    contact: {
        id: 'components.common.quick-access.contact',
        defaultMessage: 'Contact',
    },
});
