import { ErrorIllustration } from 'core';
import ErrorAccordion from './ErrorAccordion';
import type { SentryErrorArgs } from './types';
import { BoxProps } from '@chakra-ui/react';

export function BlockError({ error, ...props }: SentryErrorArgs & BoxProps) {
    return (
        <ErrorIllustration
            p="5"
            sx={{
                '> * + *': {
                    mt: '10',
                },
            }}
            {...props}>
            <ErrorAccordion message={error?.message} />
        </ErrorIllustration>
    );
}
