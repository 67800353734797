import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';

async function fetchDocument({ queryKey: [urn] }: FetchQueryParams) {
    try {
        if (!urn) {
            throw {
                response: {
                    status: 400,
                },
            };
        }

        return await axios.get(
            urn.startsWith('/api') ? urn : `/api/file-proxy?path=${urn}`
        );
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useGetDocument(path: string) {
    return useQuery<any, APIError>({
        queryKey: [path],
        queryFn: fetchDocument,
    });
}
